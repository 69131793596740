import humanizeDuration from "humanize-duration";
import { Duration } from "luxon";

export const userLocale = navigator.languages.length ? navigator.languages[0] : navigator.language;

export const DATE_INPUT_FORMAT_STR = "yyyy-LL-dd'T'HH:mm:ss"

const humanize = (value: Duration) => {
    return humanizeDuration(value.toMillis(), { language: userLocale, fallbacks: [ "de" ], units: [ "mo" ], round: true, largest: 1 })
}

export const Format = {
    humanize: humanize
}
