import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Menu } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface CopyEventItemProps {
    eventUUID: UUID
}

export function CopyEventItem(props: CopyEventItemProps) {
    const { eventUUID } = props

    const { t } = useTranslation()

    return (
        <Menu.Item component={ Link } to={ generatePath(Routes.CLASSES_BY_ID_COPY, { uuid: eventUUID }) }
                   leftSection={ <IconCopy size={ 18 } /> } id="copy-event-menu-item" data-umami-event="copy-event-menu-item">
            { t("components:Events.CopyEvent.Copy event") }
        </Menu.Item>
    )
}
