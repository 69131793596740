export const Routes = {
    ROOT: "/",

    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    SIGN_UP_CONFIRM: "/sign-up/confirm/:code",
    SIGN_OUT: "/sign-out",
    RESET_PASSWORD: "/reset-password",
    RESET_PASSWORD_BY_ID: "/reset-password/:uuid",
    EMAIL_ADDRESS_UPDATE_BY_ID: "/email-address-update/:uuid",

    CANCEL_MEMBERSHIP: "/cancel-membership",
    CONTACT: "/contact",
    EVENTS: "/events",

    INFO: "/info",
    INFO_TERMS_OF_SERVICE: "/info/terms-of-service",
    INFO_DATA_PROTECTION: "/info/data-protection",

    CLASSES: "/classes",
    CLASSES_CREATE: "/classes/create",
    CLASSES_BY_ID: "/classes/:uuid",
    CLASSES_BY_ID_COPY: "/classes/:uuid/copy",
    CLASSES_BY_ID_UPDATE: "/classes/:uuid/update",

    MEMBERSHIPS: "/memberships",
    MEMBERSHIPS_CREATE: "/memberships/create",
    MEMBERSHIPS_CONFIGURATION: "/memberships/configuration",
    MEMBERSHIPS_BY_ID: "/memberships/:uuid",
    MEMBERSHIPS_BY_ID_UPDATE: "/memberships/:uuid/update",

    MEMBERSHIPS_APPROVAL_CONFIGURATIONS_CREATE: "/memberships/approval-configurations/create",
    MEMBERSHIPS_APPROVAL_CONFIGURATIONS_BY_ID: "/memberships/approval-configurations/:uuid",

    MEMBERSHIPS_PAYMENT_METHODS_CREATE: "/memberships/payment-methods/create",
    MEMBERSHIPS_PAYMENT_METHODS_BY_ID: "/memberships/payment-methods/:uuid",

    WORKOUTS: "/workouts",
    WORKOUTS_LIST: "/workouts/list",
    WORKOUTS_BY_ID: "/workouts/:uuid",
    WORKOUTS_BY_ID_COPY: "/workouts/:uuid/copy",
    WORKOUTS_BY_ID_UPDATE: "/workouts/:uuid/update",
    WORKOUTS_CREATE: "/workouts/create",
    WORKOUTS_CONFIGURATION: "/workouts/configuration",
    WORKOUTS_TYPE_CREATE: "/workouts/configuration/type/create",
    WORKOUTS_TYPE_BY_ID: "/workouts/configuration/type/:uuid",
    WORKOUTS_RESULT_CATEGORY_CREATE: "/workouts/configuration/result-category/create",
    WORKOUTS_RESULT_CATEGORY_BY_ID: "/workouts/configuration/result-category/:uuid",

    MY: "/my",
    MY_ACCOUNT: "/my/account",
    MY_MEMBERSHIPS: "/my/memberships",
    MY_MEMBERSHIPS_REQUEST_BY_ID: "/my/memberships/requests/:uuid",
    MY_MEMBERSHIPS_BY_ID: "/my/memberships/:uuid",
    MY_BENCHMARKS: "/my/benchmarks",
    MY_BENCHMARKS_CREATE: "/my/benchmarks/create",
    MY_BENCHMARKS_BY_ID: "/my/benchmarks/:uuid",
    MY_BENCHMARKS_BY_ID_UPDATE: "/my/benchmarks/:uuid/update",
    MY_NOTIFICATION_SETTINGS: "/my/notification-settings",

    ADMIN: "/administration",
    ADMIN_ORGANISATION_INFO_UPDATE: "/administration/organisation/info/update",
    ADMIN_MEMBERSHIPS: "/administration/memberships",
    ADMIN_MEMBERSHIPS_BY_ID: "/administration/memberships/:uuid",
    ADMIN_MEMBERSHIPS_REQUESTS: "/administration/membership-requests",
    ADMIN_MEMBERSHIPS_REQUESTS_BY_ID: "/administration/membership-requests/:uuid",
    ADMIN_MEMBERSHIPS_REQUESTS_BY_ID_UPDATE: "/administration/membership-requests/:uuid/update",
    ADMIN_MEMBERSHIPS_REPORTS: "/administration/memberships/reports",
    ADMIN_MEMBERS: "/administration/members",
    ADMIN_MEMBERS_REPORTS: "/administration/members/reports",
    ADMIN_MEMBERS_BY_ID: "/administration/members/:uuid",
    ADMIN_MEMBERS_BY_ID_PERSON: "/administration/members/:uuid/person",
    ADMIN_MEMBERS_BY_ID_MEMBERSHIPS: "/administration/members/:uuid/memberships",
    ADMIN_MEMBERS_BY_ID_PENALTY: "/administration/members/:uuid/penalty",
    ADMIN_MEMBERS_BY_ID_EMAILS: "/administration/members/:uuid/emails",
    ADMIN_MEMBERS_BY_ID_REGISTRATIONS: "/administration/members/:uuid/registrations",
    ADMIN_PENALTIES: "/administration/penalties",
    ADMIN_PERMISSIONS: "/administration/permissions",
    ADMIN_PERMISSIONS_ROLE_CREATE: "/administration/permissions/role/create",
    ADMIN_PERMISSIONS_ROLE_BY_ID: "/administration/permissions/role/:uuid",
    ADMIN_TERMS_OF_SERVICE: "/administration/terms-of-service",
    ADMIN_DATA_PROTECTION: "/administration/data-protection",
    ADMIN_IMAGES: "/administration/images",

}
