import { Fact } from "@/components/shared/Fact";
import { DateTime } from "luxon";


interface DateFactProps {
    title: string
    date?: string | null
}

export function DateFact(props: DateFactProps) {
    const { date, title } = props

    if (date) {
        return (
            <Fact title={ title }>
                { DateTime.fromISO(date).toFormat("dd.MM.yyyy") }
            </Fact>
        )
    } else {
        return (
            <Fact title={ title }>
                -
            </Fact>
        )
    }
}