import { UUID } from "@/common/Types";
import { ContentCard } from "@/components/shared";
import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission, RegistrationState } from "@/generated/graphql";
import { EventRegistrationMenu } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/EventRegistrationMenu";
import { Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export interface EventRegistrationType {
    uuid: UUID

    memberUUID: UUID | null
    name: string
    comment: string | null
    state: RegistrationState
    isVisitor: boolean
    isPenalty: boolean
}

interface EventRegistrationListProps {
    allRegistrations: readonly (EventRegistrationType | null)[]
    availableSpots: number | null
    registrationCount: number
}

export function EventRegistrationList(props: EventRegistrationListProps) {
    const { allRegistrations, availableSpots, registrationCount } = props

    const { t } = useTranslation()

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Group justify="space-between">
                    <Text fw={ 600 }>{ t("components:Events.EventRegistrationList.Registrations") }</Text>
                    <Text fw={ 600 }>{ `${ registrationCount } / ${ availableSpots ?? "∞" }` }</Text>
                </Group>
            </Card.Section>

            {
                allRegistrations.map((it, i) => {
                    if (it) {
                        return (
                            <Card.Section key={ it.uuid } withBorder inheritPadding py="sm">
                                <Row eventRegistration={ { ...it, number: i + 1 } } />
                            </Card.Section>
                        )
                    } else {
                        return (
                            <Card.Section key={ i } withBorder inheritPadding py="sm">
                                <EmptyRow number={ i + 1 } />
                            </Card.Section>
                        )
                    }
                })
            }

        </ContentCard>
    )
}

interface RowProps {
    eventRegistration: EventRegistrationType & { number: number }
}

function Row(props: RowProps) {
    const { eventRegistration } = props

    const { t } = useTranslation()

    const { hasPermission } = useAuthentication()

    return (
        <Group justify="space-between" wrap="nowrap">
            <Group align="flex-start" gap="xs" wrap="nowrap">
                <Text ta="right" fw={ 600 } style={ { minWidth: "1.25rem", fontVariantNumeric: "tabular-nums" } }>
                    { eventRegistration.number }
                </Text>
                <Stack gap={ 0 }>
                    <Group align="baseline" gap="xs" wrap="nowrap">
                        <Text>{ eventRegistration.name }

                            { eventRegistration.isVisitor && hasPermission(MemberPermission.EventRegistrationManager) && (
                                <Text component="span" c="dimmed" size="xs"> ({ t("components:Events.EventRegistrationList.Visitor") })</Text>
                            ) }
                        </Text>
                    </Group>

                    <Group gap="xs" wrap="nowrap">
                        <Comment eventRegistration={ eventRegistration } />
                    </Group>
                </Stack>
            </Group>
            <Group gap="xs" wrap="nowrap">
                <Text size="sm">{ t(`entities:EventRegistrationState.${ eventRegistration.state }`) }</Text>
                <EventRegistrationMenu eventRegistration={ eventRegistration } />
            </Group>
        </Group>
    )
}

interface CommentProps {
    eventRegistration: EventRegistrationType
}

export function Comment(props: CommentProps) {
    const { eventRegistration } = props

    const { hasPermission } = useAuthentication()

    if (eventRegistration.comment === null) {
        return null
    }

    if (eventRegistration.isVisitor) {
        if (hasPermission(MemberPermission.EventRegistrationManager)) {
            return (<Text c="dimmed" size="xs">{ eventRegistration.comment }</Text>)
        }
    } else {
        return (<Text c="dimmed" size="xs">{ eventRegistration.comment }</Text>)
    }
}


interface EmptyItemProps {
    number: number
}

function EmptyRow(props: EmptyItemProps) {
    const { number } = props

    return (
        <Group justify="space-between">
            <Text ta="right" fw={ 600 } style={ { minWidth: "1.25rem", fontVariantNumeric: "tabular-nums" } }>{ number }</Text>
        </Group>
    )
}
