import { UUID } from "@/common/Types";
import { AttendanceFactText } from "@/components/memberships/details/AttendanceFact";
import { ContentCard } from "@/components/shared";
import { AttendanceTimeUnit, AttendanceType, BillingCycle } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Button, Card, Center, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, Link as RouterLink } from "react-router-dom";
import classes from "./MembershipTemplatesCard.module.scss"

interface MembershipTemplatesCardProps {
    templates: readonly {
        uuid: UUID
        name: string
        attendanceRestriction: {
            type: AttendanceType
            count: number
            timeUnit: AttendanceTimeUnit
        }
        billing: {
            cycle: BillingCycle
            amount: number
        }
    }[]
}

export function MembershipTemplatesCard(props: MembershipTemplatesCardProps) {
    const { templates } = props;

    const { t } = useTranslation()

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Info.MembershipsCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <CardContent templates={ templates } />
            </Card.Section>
        </ContentCard>
    )
}

function CardContent(props: MembershipTemplatesCardProps) {
    const { templates } = props;

    const { t } = useTranslation()

    if (templates.length === 0) {
        return (
            <Center>
                <Text>{ t("components:Info.MembershipsCard.No Memberships") }</Text>
            </Center>
        )
    }

    return (
        <Stack>
            { templates.slice(0, 3).map(it => <MembershipItem key={ it.uuid } template={ it } />) }

            <Group justify="center">
                <Button component={ RouterLink } to={ Routes.MEMBERSHIPS }>
                    { t("components:Info.MembershipsCard.All Memberships") }
                </Button>
            </Group>
        </Stack>
    )
}

interface MembershipItemProps {
    template: {
        uuid: UUID
        name: string
        attendanceRestriction: {
            type: AttendanceType
            count: number
            timeUnit: AttendanceTimeUnit
        }
        billing: {
            cycle: BillingCycle
            amount: number
        }
    }
}

function MembershipItem(props: MembershipItemProps) {
    const { template } = props

    const { t } = useTranslation()

    const billingLine = (() => {
        if (template.billing.amount === 0) {
            return (
                <Text fw={ 600 } style={ { fontSize: 24, fontVariant: "tabular-nums" } }>
                    { t("components:Info.MembershipsCard.FreeBilling") }
                </Text>
            )
        } else {
            return (
                <Text fw={ 600 } style={ { fontSize: 24, fontVariant: "tabular-nums" } }>
                    { t("components:Info.MembershipsCard.BillingAmount", { count: template.billing.amount }) }
                </Text>
            )
        }
    })()

    return (
        <UnstyledButton component={ RouterLink } to={ generatePath(Routes.MEMBERSHIPS_BY_ID, { uuid: template.uuid }) }>
            <div className={ classes.membership }>
                <Group justify="space-between">
                    <Stack gap={ 0 } mih={ 45 }>
                        <Text fw={ 600 }>{ template.name }</Text>
                        <Text size="xs" c="dimmed">{ AttendanceFactText(template.attendanceRestriction) }</Text>
                    </Stack>

                    { billingLine }
                </Group>
            </div>
        </UnstyledButton>
    );
}
