import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { EventRegistrationForm } from "@/components/events/registrationForm/EventRegistrationForm";
import { UpdateEventRegistrationInput, useUpdateEventRegistrationMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";

interface UpdateEventRegistrationFormProps {
    eventRegistration: {
        uuid: UUID,
        comment: string | null
    }
    onSuccess: (uuid: string) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function UpdateEventRegistrationForm(props: UpdateEventRegistrationFormProps) {
    const { eventRegistration, onCancel, onError, onSuccess } = props

    const [ updateEventRegistration ] = useUpdateEventRegistrationMutation()

    const initialValues: UpdateEventRegistrationInput = {
        comment: eventRegistration.comment
    }

    const onSubmit = async (input: UpdateEventRegistrationInput) => {
        return await executeMutation({
                mutation: () => updateEventRegistration({
                    variables: { uuid: eventRegistration.uuid, input: input }
                }),
                onSuccess: data => {
                    onSuccess(data.updateEventRegistration.uuid)
                },
            onError: (error) => {
                    Logger.error("Failed to create VisitorRegistration", error)
                    onError(error)
                }
            }
        )
    }

    return <EventRegistrationForm initialValues={ initialValues }
                                  onSubmit={ onSubmit }
                                  onCancel={ onCancel } />
}
