/* eslint-disable @typescript-eslint/no-explicit-any */

import Logger from "@/common/Logger";
import { useForm, UseFormInput, UseFormReturnType } from "@mantine/form";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { unstable_usePrompt } from "react-router-dom";

interface SuccessFormSubmitResult<TSuccessData> {
    isSuccess: true
    data: TSuccessData
}

interface ErrorFormSubmitResult {
    isSuccess: false
    error: unknown
}

export type FormSubmitResult<TSuccessData> = SuccessFormSubmitResult<TSuccessData> | ErrorFormSubmitResult
type _TransformValues<Values> = (values: Values) => unknown;

type SubmitHandler<Values, TransformValues extends _TransformValues<Values>> = (values: ReturnType<TransformValues>, event: FormEvent<HTMLFormElement> | undefined) => Promise<FormSubmitResult<unknown>> | Promise<void>
type OnAsyncSubmit<Values, TransformValues extends _TransformValues<Values>> = (handleSubmit: SubmitHandler<Values, TransformValues>) => (event?: FormEvent<HTMLFormElement>) => void;

export type UseMyPowerHourFormReturnType<Values extends Record<string, any> = Record<string, any>, TransformValues extends _TransformValues<Values> = (values: Values) => Values> =
    Omit<UseFormReturnType<Values, TransformValues>, "onSubmit"> & {
    hasErrors: boolean,
    isSubmitting: boolean,
    onSubmit: OnAsyncSubmit<Values, TransformValues>
}

interface UseMyPowerHourFormInput<Values, TransformValues extends _TransformValues<Values> = (values: Values) => Values> extends UseFormInput<Values, TransformValues> {
    name?: string
    blockIfDirty?: boolean
}

export function useMyPowerHourForm<Values extends Record<string, any> = Record<string, any>, TransformValues extends _TransformValues<Values> = (values: Values) => Values>(
    input: UseMyPowerHourFormInput<Values, TransformValues> = {}
): UseMyPowerHourFormReturnType<Values, TransformValues> {
    const { onSubmit, ...other } = useForm(input)

    const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false)
    const { t } = useTranslation()

    // block navigation when form is dirty
    unstable_usePrompt({ when: input.blockIfDirty ? other.isDirty() : false, message: t("common:Forms.Form is dirty. Do you want to leave?") })

    const onSubmitNew: OnAsyncSubmit<Values, TransformValues> = useCallback((handleSubmit) => {
        const newHandleSubmit = async (values: ReturnType<TransformValues>, event: FormEvent<HTMLFormElement> | undefined) => {
            setIsSubmitting(true)
            try {
                await handleSubmit(values, event)
            } finally {
                setIsSubmitting(false)
            }
        }

        return onSubmit(newHandleSubmit, (e, v) => Logger.validationErrors(input.name ?? "Unnamed form", e, v))
    }, [ onSubmit ])

    // convenient property to check for errors
    const errors = other.errors;
    const hasErrors = useMemo(() => Object.keys(errors).length > 0, [ errors ]);

    return {
        onSubmit: onSubmitNew,
        hasErrors: hasErrors,
        isSubmitting,
        ...other
    }
}
