import { NavigationItem, useNavigationItems } from "@/components/navigation";
import { useAuthentication } from "@/contexts/authentication";
import { useDeviceSize } from "@/contexts/device-size";
import { useInAppNotificationsContext } from "@/contexts/in-app-notification";
import { Box, Flex, Indicator, RemoveScroll, Transition } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconDots } from "@tabler/icons-react";
import cx from "clsx";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import classes from "./BottomNavbar.module.scss"
import { BottomNavbarButton } from "./BottomNavbarButton"
import { BottomNavbarExtension } from "./BottomNavbarExtension"


export function BottomNavbar() {
    const { isAuthenticated } = useAuthentication()
    const { isMobile } = useDeviceSize()
    const navigation = useNavigationItems()

    if (!isMobile) {
        return null
    }

    if (isAuthenticated) {
        return <BottomNavbarInner items={ navigation.sets.bottomNavbar.authenticated } />
    } else {
        return <BottomNavbarInner items={ navigation.sets.bottomNavbar.anonymous } />
    }
}

interface BottomNavbarInnerProps {
    items: NavigationItem[]
}

function BottomNavbarInner(props: BottomNavbarInnerProps) {
    const { items } = props

    const location = useLocation()
    const [ opened, handlers ] = useDisclosure(false)

    const { hasUnseen } = useInAppNotificationsContext()

    // Close navigation on location change
    useEffect(() => {
        handlers.close()
    }, [ location ])

    const moreItem = {
        icon: IconDots,
        labelKey: "common:Navigation.More",
        onClick: handlers.toggle,
        to: ""
    }

    const overlayClassName = cx({
        [classes.navbarOverlay]: true,
        [classes.extensionOpen]: opened
    })

    return (
        <Box className={ overlayClassName } onClick={ handlers.close }>
            <Box className={ classes.navbarWrapper } onClick={ e => e.stopPropagation() }>
                <Transition transition={ "pop" } mounted={ opened }>
                    { (styles) => (
                        <Box className={ classes.bottomExtensionWrapper } style={ styles }>
                            <RemoveScroll>
                                <BottomNavbarExtension items={ items } />
                            </RemoveScroll>
                        </Box>
                    ) }
                </Transition>

                <nav className={ classes.navbar }>
                    <Flex align="center" justify="space-evenly" h="100%">
                        { items.slice(0, 3).map(it => <BottomNavbarButton key={ it.labelKey } item={ it } />) }

                        <Indicator disabled={ !hasUnseen } size={ 8 } offset={ 2 }>
                            <BottomNavbarButton item={ moreItem } data-expanded={ opened } />
                        </Indicator>
                    </Flex>
                </nav>
            </Box>
        </Box>
    )
}
