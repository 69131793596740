import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate"
import { useDeleteBenchmarkMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Routes } from "@/routing/Routes";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface DeleteBenchmarkItemProps {
    benchmarkUUID: UUID
}

export function DeleteBenchmarkItem(props: DeleteBenchmarkItemProps) {
    const { benchmarkUUID } = props

    const { t } = useTranslation()
    const { navigateBack } = useMyPowerHourNavigate()

    const [ deleteBenchmark ] = useDeleteBenchmarkMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("pages:BenchmarkPage.Delete Benchmark") }</Text>,
            children: t("pages:BenchmarkPage.Do you want to delete this Benchmark"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Delete") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => deleteBenchmark({ variables: { uuid: benchmarkUUID } }),
                    onSuccess: () => {
                        showSuccessNotification(t("common:Forms.Deleted"))
                        navigateBack(Routes.MY_BENCHMARKS)
                    },
                    onError: (error) => {
                        Logger.error("Failed to delete Benchmark", error)
                        showErrorNotification({ message: t("pages:BenchmarkPage.Failed to delete Benchmark") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } color="red" leftSection={ <IconTrash size={ 18 } /> }>
            { t("pages:BenchmarkPage.Delete Benchmark") }
        </Menu.Item>
    )
}
