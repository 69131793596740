import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { SwitchEventCard, SwitchEventCardProps } from "@/components/events/switch/SwitchEventCard";
import { useSwitchEventRegistrationMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Routes } from "@/routing/Routes";
import { Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";


type EventType = SwitchEventCardProps["events"][number]

export interface SwitchEventCardContainerProps {
    events: EventType[]
    myRegistration: { uuid: UUID } | null
}

export function SwitchEventCardContainer(props: SwitchEventCardContainerProps) {
    const { events, myRegistration } = props

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [ switchEventRegistration ] = useSwitchEventRegistrationMutation()

    if (myRegistration === null) {
        return null
    }

    const onClick = (event: EventType) => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.SwitchEvent.Switch event") }</Text>,
            children: (
                <Trans i18nKey={ "components:Events.SwitchEvent.Do you want to switch event" }
                       values={ { name: event.name, start: event.start.toFormat("HH:mm") } }
                       shouldUnescape={ true }>
                    Do you want to switch event
                </Trans>
            ),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Switch") },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => switchEventRegistration({ variables: { registrationUUID: myRegistration.uuid, eventId: event.uuid } }),
                    onSuccess: (data) => {
                        showSuccessNotification(t("components:Events.SwitchEvent.Event switched"))
                        navigate(generatePath(Routes.CLASSES_BY_ID, { uuid: data.switchEventRegistration.event.uuid }), { replace: true })
                    },
                    onError: (error) => {
                        Logger.error("Failed to switch Event", error)
                        showErrorNotification({ message: t("components:Events.SwitchEvent.Failed to switch event") })
                    }
                })
            }
        })
    }

    return (
        <SwitchEventCard events={ events } onClick={ onClick } />
    )
}
