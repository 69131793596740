import { UUID } from "@/common/Types"
import { MyBilledEventRegistrationsQuery, useMyBilledEventRegistrationsQuery } from "@/generated/graphql"
import { useEffect, useMemo, useState } from "react";
import { BilledEventRegistrationsBaseCard } from "./BilledEventRegistrationsBaseCard"


interface MyBilledEventRegistrationCardProps {
    membershipAgreementUUID: UUID
}

export function MyBilledEventRegistrationCard(props: MyBilledEventRegistrationCardProps) {
    const { membershipAgreementUUID } = props

    const limit = useMemo(() => 10, [])
    const [ offset, setOffset ] = useState<number>(0)
    const [ queryData, setQueryData ] = useState<MyBilledEventRegistrationsQuery | null>(null)

    const { data } = useMyBilledEventRegistrationsQuery({
        variables: {
            filter: { membershipAgreementUUID: membershipAgreementUUID },
            pagination: {
                limit: limit,
                offset: offset
            }
        }
    })

    useEffect(() => {
        if (data) {
            setQueryData(data)
        }
    }, [ data ])

    const onChangeToPage = (page: number) => {
        setOffset(Math.max(0, page - 1) * limit)
    }

    return (
        <BilledEventRegistrationsBaseCard billedEventRegistrations={ queryData?.myBilledEventRegistrations.results ?? [] }
                                          totalCount={ queryData?.myBilledEventRegistrations.totalCount ?? 0 }
                                          pagination={ { limit: limit, offset: offset } }
                                          onChangeToPage={ onChangeToPage } />
    )
}
