import { PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import { WorkoutConfigurationPageDocument, WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables } from "@/generated/graphql";
import { WorkoutTypeList } from "@/pages/workouts/configuration/_components/WorkoutTypeList";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { ActionIcon, Box, Group, Stack, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";
import { WorkoutResultCategoryList } from "./_components/WorkoutResultCategoryList";


export function WorkoutConfigurationPageLoader() {
    return GraphQLClient.query<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>({
        query: WorkoutConfigurationPageDocument,
    })
}

export function WorkoutConfigurationPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<WorkoutConfigurationPageQuery>

    const { t } = useTranslation()

    const types = queryResult.data.workoutTypes
    const resultCategories = queryResult.data.workoutResultCategories

    return (
        <>
            <MetaTitle title={ t("pages:WorkoutConfigurationPage.Title") } />

            <Stack>
                <PageTitleWithBack title={ t("pages:WorkoutConfigurationPage.Title") } />

                <Group justify="space-between" align="flex-start" wrap="nowrap">
                    <Box>
                        <Text fw={ 600 } size="lg">{ t("pages:WorkoutConfigurationPage.WorkoutTypes") }</Text>
                        <Text c="dimmed" size="sm">{ t("pages:WorkoutConfigurationPage.WorkoutTypes Description") }</Text>
                    </Box>

                    <ActionIcon component={ Link } to={ Routes.WORKOUTS_TYPE_CREATE } color="brand" variant="filled" size="sm">
                        <IconPlus />
                    </ActionIcon>
                </Group>

                <WorkoutTypeList workoutTypes={ types } />

                <Group justify="space-between" align="flex-start" wrap="nowrap">
                    <Box>
                        <Text fw={ 600 } size="lg">{ t("pages:WorkoutConfigurationPage.WorkoutResultCategories") }</Text>
                        <Text c="dimmed" size="sm">{ t("pages:WorkoutConfigurationPage.WorkoutResultCategories Description") }</Text>
                    </Box>

                    <ActionIcon component={ Link } to={ Routes.WORKOUTS_RESULT_CATEGORY_CREATE } color="brand" variant="filled" size="sm">
                        <IconPlus />
                    </ActionIcon>
                </Group>

                <WorkoutResultCategoryList workoutResultCategories={ resultCategories } />
            </Stack>
        </>
    )
}
