import { toDateTime } from "@/common/Luxon";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { CreateWorkoutResultForm } from "@/components/workouts/result/CreateWorkoutResultForm";
import { useAuthentication } from "@/contexts/authentication";
import { useWorkoutResultCategoriesQuery, WorkoutResultOrder, WorkoutResultType } from "@/generated/graphql";
import { Result, WorkoutResults } from "@/pages/workouts/[uuid]/_components/WorkoutResults";
import { Button, ScrollArea, Stack, Tabs, Text } from "@mantine/core"
import { closeModal, openModal } from "@mantine/modals";
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


interface ScoreBoardType {
    uuid: UUID
    name: string | null
    order: number
    results: readonly Result[]
    resultOrder: WorkoutResultOrder
    resultType: WorkoutResultType
    submissionEnd: string | null
}

interface ScoreBoardsProps {
    scoreBoards: readonly ScoreBoardType[]
}

export function ScoreBoards(props: ScoreBoardsProps) {
    const { scoreBoards } = props

    const { t } = useTranslation()

    const { data: resultCategories } = useWorkoutResultCategoriesQuery()

    const sortedScoreBoards = scoreBoards.slice().sort((a, b) => a.order - b.order);

    if (scoreBoards.length === 1) {
        return (
            <ScoreBoard scoreBoard={ scoreBoards[0] } resultCategories={ resultCategories?.workoutResultCategories ?? [] } />
        )
    }

    return (
        <Tabs defaultValue={ scoreBoards[0].uuid }>
            <ScrollArea scrollbarSize={ 8 } offsetScrollbars>
                <Tabs.List grow style={ { flexWrap: "nowrap" } }>
                    {
                        sortedScoreBoards.map((it, index) => (
                            <Tabs.Tab key={ it.uuid } value={ it.uuid }>{ it.name ?? t("pages:WorkoutPage.ScoreBoard X", { count: index + 1 }) }</Tabs.Tab>
                        ))
                    }
                </Tabs.List>
            </ScrollArea>
            {
                sortedScoreBoards.map((it) => (
                    <Tabs.Panel key={ it.uuid } value={ it.uuid } pt="xs">
                        <ScoreBoard scoreBoard={ it } resultCategories={ resultCategories?.workoutResultCategories ?? [] } />
                    </Tabs.Panel>
                ))
            }
        </Tabs>
    )
}

interface ScoreBoardProps {
    scoreBoard: ScoreBoardType
    resultCategories: readonly { uuid: UUID, name: string }[]
}

const ADD_RESULT_MODAL_ID = "create-workout-result"

function ScoreBoard(props: ScoreBoardProps) {
    const { scoreBoard, resultCategories } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const { isAuthenticated } = useAuthentication()

    const results = getSortedResults(scoreBoard)
    const submissionEnd = toDateTime(scoreBoard.submissionEnd);
    const isAddResultsDisabled = submissionEnd !== null && submissionEnd < DateTime.now()

    const onSuccess = () => {
        showSuccessNotification(t("pages:WorkoutPage.Result added"))
        revalidator.revalidate()
        closeModal(ADD_RESULT_MODAL_ID)
    }

    const onError = () => {
        showErrorNotification({ message: t("pages:WorkoutPage.Failed to add Result") })
    }

    const onAddResult = () => {
        openModal({
            modalId: ADD_RESULT_MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("pages:WorkoutPage.Add Result") }</Text>,
            children: <CreateWorkoutResultForm scoreBoardUUID={ scoreBoard.uuid }
                                               resultType={ scoreBoard.resultType }
                                               resultCategories={ resultCategories }
                                               onSuccess={ onSuccess }
                                               onError={ onError }
                                               onCancel={ () => closeModal(ADD_RESULT_MODAL_ID) } />,
            closeOnClickOutside: false
        })
    }

    return (
        <Stack>
            {
                isAuthenticated && (
                    <Button fullWidth onClick={ onAddResult } disabled={ isAddResultsDisabled }>
                        { t("pages:WorkoutPage.Add Result") }
                    </Button>
                )
            }

            <WorkoutResults results={ results }
                            resultCategories={ resultCategories }
                            resultType={ scoreBoard.resultType }
                            submissionEnd={ scoreBoard.submissionEnd } />
        </Stack>
    )
}

function getSortedResults(scoreBoard: ScoreBoardType) {
    if (scoreBoard.resultOrder === WorkoutResultOrder.Ascending) {
        if (scoreBoard.resultType === WorkoutResultType.Duration) {
            return scoreBoard.results.slice().sort((a, b) => Duration.fromISO(a.score).toMillis() - Duration.fromISO(b.score).toMillis())
        } else {
            return scoreBoard.results.slice().sort((a, b) => parseFloat(a.score) - parseFloat(b.score))
        }
    } else {
        if (scoreBoard.resultType === WorkoutResultType.Duration) {
            return scoreBoard.results.slice().sort((a, b) => Duration.fromISO(b.score).toMillis() - Duration.fromISO(a.score).toMillis())
        } else {
            return scoreBoard.results.slice().sort((a, b) => parseFloat(b.score) - parseFloat(a.score))
        }
    }
}
