import { DateTime, Interval } from "luxon";
import { CalendarEvent } from "./types"


interface EventsByDate {
    date: DateTime,
    events: CalendarEvent[]
}

export function groupEventsByDate(events: CalendarEvent[], startDate: DateTime): EventsByDate[] {
    const eventsByDate: EventsByDate[] = []

    for (let i = 0; i < 7; i++) {
        const date = startDate.plus({ day: i })

        const eventsOnDate = events.filter(event => {
            const interval = Interval.fromDateTimes(event.start.startOf("day"), event.end.endOf("day"))
            return interval.contains(date)
        })

        eventsByDate.push({ date: date, events: eventsOnDate })
    }

    return eventsByDate
}
