function toNullIfEmpty(value: number | null | string): number | null {
    if (value === null) {
        return null
    }
    if (typeof value === "string") {
        return null
    }

    return value
}

const N = {
    toNullIfEmpty: toNullIfEmpty
}

export default N
