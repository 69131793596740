import { Box, Group, ThemeIcon } from "@mantine/core";
import React, { PropsWithChildren } from "react";


interface InfoFactProps {
    icon: React.ReactNode
}

export function InfoFact(props: PropsWithChildren<InfoFactProps>) {
    const { icon, children } = props

    return (
        <Group align="start" wrap="nowrap">
            <ThemeIcon variant="light">
                { icon }
            </ThemeIcon>
            <Box style={ { wordBreak: "break-all" } }>
                { children }
            </Box>
        </Group>
    )
}
