import { NavigationItem } from "@/components/navigation";
import { Stack, Text, UnstyledButton } from "@mantine/core";
import cx from "clsx";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import classes from "./../NavbarStyles.module.scss"


interface BottomNavbarButtonProps {
    item: NavigationItem & { onClick?: () => void }
}

export function BottomNavbarButton(props: BottomNavbarButtonProps) {
    const { item, ...other } = props

    const { t } = useTranslation()

    const inner = (
        <Stack gap={ 4 } align="center">
            { item.icon && <item.icon className={ classes.linkIcon } stroke={ 1.5 } /> }
            <Text size="xs" lh={ 1 }>{ t(item.labelKey) }</Text>
        </Stack>
    )

    // Special case for "More" button
    if (item.onClick) {
        return (
            <UnstyledButton miw="65px" onClick={ item.onClick } className={ cx("mantine-active", "mantine-focus-auto", classes.link) } p={ 4 } { ...other }>
                { inner }
            </UnstyledButton>
        )
    }

    return (
        <UnstyledButton miw="65px" component={ NavLink } to={ item.to } end={ item.end } className={ cx("mantine-active", "mantine-focus-auto", classes.link) } p={ 4 }>
            { inner }
        </UnstyledButton>
    )
}
