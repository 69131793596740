import { UUID } from "@/common/Types";
import { ContentCard } from "@/components/shared";
import { EventState } from "@/generated/graphql";
import { CancellationFact } from "@/pages/classes/[uuid]/_components/detailsCard/CancellationFact";
import { DateFact } from "@/pages/classes/[uuid]/_components/detailsCard/DateFact";
import { DescriptionFact } from "@/pages/classes/[uuid]/_components/detailsCard/DescriptionFact";
import { FeeFact } from "@/pages/classes/[uuid]/_components/detailsCard/FeeFact";
import { RegistrationStartFact } from "@/pages/classes/[uuid]/_components/detailsCard/RegistrationStartFact";
import { StateFact } from "@/pages/classes/[uuid]/_components/detailsCard/StateFact"
import { Button, Card, Center, Collapse, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronsDown, IconChevronsUp } from "@tabler/icons-react";
import { DateTime, Duration } from "luxon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface EventType {
    eventUUID: string
    eventDetailsUUID: UUID

    end: DateTime
    description: string | null
    name: string
    start: DateTime
    state: EventState

    feeAmount: number

    availableSpots: number | null

    minimumNumberOfRegistrations: number | null
    minimumNumberOfRegistrationsPeriod: Duration | null

    lateCancellationPeriod: Duration | null

    registrationStartPeriod: Duration | null
}

export interface DetailsCardProps {
    event: EventType
    enableCollapse: boolean
}

export function DetailsCard(props: DetailsCardProps) {
    const { event, enableCollapse } = props

    // store initial state to avoid layout shift
    const isCollapseEnabled = useMemo(() => enableCollapse, [])
    const [ opened, { toggle } ] = useDisclosure(!isCollapseEnabled)

    return (
        <>
            <ContentCard>
                <Stack>
                    <DetailFacts event={ event } detailsOpened={ opened } />
                </Stack>

                { isCollapseEnabled && [ EventState.Pending, EventState.Confirmed, EventState.Started ].includes(event.state) &&
                    <Card.Section pt="sm">
                        <Button variant="subtle" radius={ 0 } onClick={ toggle } size="xs" fullWidth={ true }>
                            { opened ? <IconChevronsUp stroke={ 1 } /> : <IconChevronsDown stroke={ 1 } /> }
                        </Button>
                    </Card.Section>
                }
            </ContentCard>

            { event.description && (
                <ContentCard>
                    <DescriptionFact description={ event.description } />
                </ContentCard>
            ) }
        </>
    )
}

interface DetailFactsProps {
    event: EventType
    detailsOpened: boolean
}

function DetailFacts(props: DetailFactsProps) {
    const { event, detailsOpened } = props

    const { t } = useTranslation()

    switch (event.state) {
        case EventState.Pending:
            return (
                <>
                    <DateFact start={ event.start } end={ event.end } />

                    <StateFact event={ event } />

                    <Collapse in={ detailsOpened }>
                        <Stack>
                            <FeeFact feeAmount={ event.feeAmount } />

                            <RegistrationStartFact start={ event.start } registrationStartPeriod={ event.registrationStartPeriod } />

                            <CancellationFact start={ event.start } lateCancellationPeriod={ event.lateCancellationPeriod } />
                        </Stack>
                    </Collapse>
                </>
            )
        case EventState.Confirmed:
            return (
                <>
                    <DateFact start={ event.start } end={ event.end } />

                    <StateFact event={ event } />

                    <Collapse in={ detailsOpened }>
                        <Stack>
                            <FeeFact feeAmount={ event.feeAmount } />

                            <RegistrationStartFact start={ event.start } registrationStartPeriod={ event.registrationStartPeriod } />

                            <CancellationFact start={ event.start } lateCancellationPeriod={ event.lateCancellationPeriod } />
                        </Stack>
                    </Collapse>
                </>
            )
        case EventState.Cancelled:
            return (
                <>
                    <DateFact start={ event.start } end={ event.end } />

                    <Center>
                        <Text fw={ 600 }>{ t("pages:ClassesByIdPage.This event is cancelled") }</Text>
                    </Center>
                </>
            )
        case EventState.Started:
            return (
                <>
                    <DateFact start={ event.start } end={ event.end } />

                    <Center>
                        <Text fw={ 600 }>{ t("pages:ClassesByIdPage.This event is started") }</Text>
                    </Center>
                </>
            )
        case EventState.Finished:
            return (
                <>
                    <DateFact start={ event.start } end={ event.end } />

                    <Center>
                        <Text fw={ 600 }>{ t("pages:ClassesByIdPage.This event is finished") }</Text>
                    </Center>
                </>
            )
        case EventState.Deleted:
            return null
    }
}
