import { byDateTimeDesc } from "@/common/Sort"
import { HistoryCard } from "@/components/shared/history";
import { OrganisationLogEntry } from "@/generated/graphql"
import { DateTime } from "luxon";
import { OrganisationLogEntryItem } from "./OrganisationLogEntryItem"


interface OrganisationLogHistoryCardProps {
    entries: readonly OrganisationLogEntry[]
}

export function OrganisationLogHistoryCard(props: OrganisationLogHistoryCardProps) {
    const { entries } = props

    const mappedEntries = entries
        .map(it => ({
            uuid: it.uuid,
            date: DateTime.fromISO(it.created),
            item: <OrganisationLogEntryItem organisationLogEntry={ it } />
        }))
        .sort((a, b) => byDateTimeDesc(a.date, b.date))

    return (
        <HistoryCard entries={ mappedEntries } mah={ 320 } />
    )
}
