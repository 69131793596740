import { FullPageLoader } from "@/components/shared/FullPageLoader"
import { useSession } from "@/contexts/session";
import { Routes } from "@/routing/Routes"
import { useEffect } from "react";
import { Navigate } from "react-router-dom";


export function SignOutPage() {
    const { isSignedIn, signOut } = useSession()

    if (!isSignedIn) {
        return <Navigate to={ Routes.ROOT } replace={ true } />
    }

    useEffect(() => {
        void signOut()
    }, [])

    return (
        <FullPageLoader />
    )
}
