import { SignInForm } from "@/components/account";
import { ContentCard } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle"
import { useSession } from "@/contexts/session";
import { Routes } from "@/routing/Routes"
import { Anchor, Box, Center, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";

export function SignInPage() {
    const { t } = useTranslation()
    const { isSignedIn } = useSession()

    if (isSignedIn) {
        return <Navigate to={ Routes.MY } replace={ true } />
    }

    return (
        <>
            <MetaTitle title={ t("pages:SignInPage.SignIn") } />

            <Center>
                <Box w={ 600 }>
                    <Stack>
                        <Stack gap={ 0 }>
                            <Title order={ 2 } ta="center">
                                { t("pages:SignInPage.SignIn") }
                            </Title>

                            <Text c="dimmed" size="sm" ta="center" mt="xs">
                                { t("pages:SignInPage.No account?") }{ " " }
                                <Anchor component={ Link } to={ Routes.SIGN_UP } size="sm" fw={ 500 }>
                                    { t("common:Button.SignUp") }
                                </Anchor>
                            </Text>
                        </Stack>

                        <ContentCard>
                            <SignInForm autoFocus />
                        </ContentCard>
                    </Stack>
                </Box>
            </Center>
        </>
    );
}
