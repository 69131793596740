import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { CreateVisitorEventRegistrationForm } from "@/components/events/visitor";
import { Menu, Text } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { IconUserPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


interface CreateVisitorItemProps {
    eventUUID: UUID
}

export function CreateVisitorItem(props: CreateVisitorItemProps) {
    const { eventUUID } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const onSuccess = () => {
        showSuccessNotification(t("common:Forms.Saved"))
        revalidator.revalidate()
        closeModal("create-visitor")
    }

    const onError = () => {
        showErrorNotification({ message: t("common:Forms.Save failed") })
    }

    const onAddVisitor = () => {
        openModal({
            modalId: "create-visitor",
            title: <Text size="lg" fw={ 600 }>{ t("pages:ClassesByIdPage.Add visitor") }</Text>,
            children: <CreateVisitorEventRegistrationForm eventUUID={ eventUUID }
                                                          onSuccess={ onSuccess }
                                                          onError={ onError }
                                                          onCancel={ () => closeModal("create-visitor") } />,
            closeOnClickOutside: false
        })
    }

    return (
        <Menu.Item onClick={ onAddVisitor } leftSection={ <IconUserPlus size={ 18 } /> } id="create-visitor-menu-item" data-umami-event="create-visitor-menu-item">
            { t("pages:ClassesByIdPage.Add visitor") }
        </Menu.Item>
    )
}
