import Logger from "@/common/Logger"
import { showErrorNotification, showSuccessNotification } from "@/common/Notification"
import { FullPageLoader } from "@/components/shared/FullPageLoader"
import { useAuthentication } from "@/contexts/authentication";
import { useSession } from "@/contexts/session";
import { useConfirmEmailAddressUpdateMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Routes } from "@/routing/Routes"
import { GraphQLFormattedError } from "graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";


enum ErrorReason {
    NOT_FOUND = "NOT_FOUND",
    EXPIRED = "EXPIRED"
}

export function EmailAddressUpdatePage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { uuid } = useParams()

    const { isSignedIn } = useSession()
    const { refresh } = useAuthentication()

    const [ confirmEmailAddressUpdate ] = useConfirmEmailAddressUpdateMutation()

    if (!uuid) {
        return <Navigate to={ Routes.ROOT } replace={ true } />
    }

    if (!isSignedIn) {
        showSuccessNotification(t("pages:EmailAddressUpdatePage.Please login"))
        return <Navigate to={ Routes.SIGN_IN } replace={ true } />
    }

    const onSuccess = () => {
        showSuccessNotification(t("pages:EmailAddressUpdatePage.Email address confirmed"))
        navigate(Routes.MY_ACCOUNT)
        void refresh()
    }

    const onError = (errors: readonly GraphQLFormattedError[] | string) => {
        if (!(errors instanceof Array) || errors.length < 1) {
            Logger.error("Failed to confirm EmailAddressUpdate", errors)
            showErrorNotification({ message: t("common:Error.Something went wrong") })
            return
        }

        const error = errors[0]?.extensions?.reason as ErrorReason | undefined;
        switch (error) {
            case undefined:
                Logger.error("Failed to confirm EmailAddressUpdate", errors)
                showErrorNotification({ message: t("common:Error.Something went wrong") })
                break;
            case ErrorReason.NOT_FOUND:
            case ErrorReason.EXPIRED:
                showErrorNotification({ message: t("pages:EmailAddressUpdatePage.Link invalid") })
                navigate(Routes.MY_ACCOUNT)
                break;
            default:
                return error satisfies never;
        }
    }

    const confirm = async () => {
        const input = {
            uuid: uuid
        }

        await executeMutation({
            mutation: () => confirmEmailAddressUpdate({ variables: { input: input } }),
            onSuccess: onSuccess,
            onError: onError
        })
    }

    useEffect(() => {
        void confirm()
    }, []);

    return (
        <FullPageLoader />
    );
}
