import { byName } from "@/common/Sort";
import { ContentCard, PaperStack } from "@/components/shared";
import { Routes } from "@/routing/Routes";
import { Center, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface WorkoutTypeListProps {
    workoutTypes: readonly {
        uuid: string
        name: string
    }[]
}

export function WorkoutTypeList(props: WorkoutTypeListProps) {
    const { workoutTypes } = props

    const { t } = useTranslation()

    if (workoutTypes.length === 0) {
        return (
            <ContentCard>
                <Center>
                    <Text>{ t("pages:WorkoutConfigurationPage.No WorkoutTypes") }</Text>
                </Center>
            </ContentCard>
        )
    }

    return (
        <PaperStack>
            {
                workoutTypes
                    .slice()
                    .sort(byName)
                    .map((it) => (
                        <PaperStack.Row key={ it.uuid } component={ Link } to={ generatePath(Routes.WORKOUTS_TYPE_BY_ID, { uuid: it.uuid }) }>
                            <Text fw={ 500 }>{ it.name }</Text>
                        </PaperStack.Row>
                    ))
            }
        </PaperStack>
    )
}
