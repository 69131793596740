import Logger from "@/common/Logger"
import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { passwordValidator } from "@/components/account/Validation"
import { useSignUpMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Anchor, Button, Grid, PasswordInput, Stack, Text, TextInput } from "@mantine/core";
import { GraphQLFormattedError } from "graphql";
import { Trans, useTranslation } from "react-i18next";


enum ErrorReason {
    INVALID_PASSWORD
}

interface SignUpFormData {
    emailAddress: string
    password: string
}

interface SignUpEmailAddressFormProps {
    onSuccess: () => void
    autoFocus?: boolean
}

export function SignUpEmailAddressForm(props: SignUpEmailAddressFormProps) {
    const { autoFocus, onSuccess } = props

    const { t } = useTranslation()

    const [ signUp ] = useSignUpMutation()

    const form = useMyPowerHourForm<SignUpFormData>({
        name: "SignUpForm",
        initialValues: {
            emailAddress: "",
            password: ""
        },
        validate: {
            password: passwordValidator(t(`components:Account.Common.Password doesn't match guidelines`))
        }
    })

    const handleError = (errors: readonly GraphQLFormattedError[] | string) => {
        if (!(errors instanceof Array) || errors.length < 1) {
            Logger.error("Failed to sign up", errors)
            showErrorNotification({ message: t("common:Error.Something went wrong") })
            return
        }

        const error = errors[0]?.extensions?.reason as ErrorReason | undefined;
        switch (error) {
            case undefined:
                Logger.error("Failed to sign up", errors)
                showErrorNotification({ message: t("common:Error.Something went wrong") })
                break;
            case ErrorReason.INVALID_PASSWORD:
                form.setFieldError("password", t(`components:Account.Common.Password doesn't match guidelines`))
                break;
            default:
                return error satisfies never;
        }
    }

    const handleSubmit = async (data: SignUpFormData) => {
        const input = {
            emailAddress: data.emailAddress,
            password: data.password
        };

        await executeMutation({
            mutation: () => signUp({ variables: { input: input } }),
            onSuccess: onSuccess,
            onError: handleError
        })
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <TextInput label={ t("components:Account.Common.EmailAddress") }
                           name="emailAddress"
                           type="email"
                           autoComplete="email"
                           autoFocus={ autoFocus }
                           required
                           { ...form.getInputProps("emailAddress") } />

                <PasswordInput label={ t("components:Account.Common.Password") }
                               description={ t("components:Account.Common.Password description") }
                               name="password"
                               autoComplete="password"
                               required
                               { ...form.getInputProps("password") } />

                <Grid>
                    <Grid.Col span="auto">
                        <Text size="sm" c="dimmed" style={ { textWrap: "balance" } }>
                            <Trans i18nKey="components:Account.SignUpForm.Legal">
                                Mit der Registrierung akzeptierst du die
                                <Anchor href="https://mypowerhour.app/terms-of-service" target="_blank" fw={ 500 }>allgemeinen Geschäftsbedingungen</Anchor>
                                und die
                                <Anchor href="https://mypowerhour.app/data-protection" target="_blank" fw={ 500 }>Datenschutzbestimmungen</Anchor>
                                von myPowerHour.
                            </Trans>
                        </Text>
                    </Grid.Col>
                    <Grid.Col span="content">
                        <Button type="submit" title="submit" loading={ form.isSubmitting }>
                            { t("common:Button.SignUp") }
                        </Button>
                    </Grid.Col>
                </Grid>
            </Stack>
        </form>
    )
}
