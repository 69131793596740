import Person from "@/common/Person"
import { PersonProtected } from "@/common/Types"
import { MembershipAgreementState } from "@/generated/graphql";
import { Alert, List, Stack, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface SuspendedHintProps {
    membershipAgreement: {
        state: MembershipAgreementState
        suspensionDetails: readonly {
            creator: PersonProtected
            endDate: string | null
            message: string
            startDate: string
        } []
    }
}

export function SuspendedHint(props: SuspendedHintProps) {
    const { membershipAgreement } = props

    const { t } = useTranslation()

    if (membershipAgreement.state !== MembershipAgreementState.Suspended) {
        return null
    }

    return (
        <Stack>
            {
                membershipAgreement.suspensionDetails
                    .filter(it => it.endDate === null)
                    .map(it => {
                        return (
                            <Alert key={ it.startDate } variant="light" title={ t("components:Memberships.SuspendedHint.Suspended") } icon={ <IconInfoCircle /> }>
                                <List size="sm">
                                    <List.Item>
                                        <Text span fw={ 500 }>{ t("components:Memberships.SuspendedHint.Creator") } </Text>
                                        { Person.buildName(it.creator) }
                                    </List.Item>
                                    <List.Item>
                                        <Text span fw={ 500 }>{ t("components:Memberships.SuspendedHint.StartDate") } </Text>
                                        { DateTime.fromISO(it.startDate).toFormat("dd.MM.yyyy") }
                                    </List.Item>
                                    <List.Item>
                                        <Text span fw={ 500 }>{ t("components:Memberships.SuspendedHint.Message") } </Text>
                                        { it.message }
                                    </List.Item>
                                </List>
                            </Alert>
                        )
                    })
            }

        </Stack>
    )
}
