import { showErrorNotification, showSuccessNotification } from "@/common/Notification"
import { ResetPasswordForm } from "@/components/account";
import { ContentCard } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle"
import { useSession } from "@/contexts/session";
import { Routes } from "@/routing/Routes"
import { Box, Center, Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export function ResetPasswordPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { uuid } = useParams()

    const { isSignedIn } = useSession()

    if (!uuid) {
        return <Navigate to={ Routes.ROOT } replace={ true } />
    }

    if (isSignedIn) {
        return <Navigate to={ Routes.MY } replace={ true } />
    }

    const onSuccess = () => {
        showSuccessNotification(t("pages:ResetPasswordPage.New password set"))
        navigate(Routes.SIGN_IN)
    }

    const onError = () => {
        showErrorNotification({ message: t("pages:ResetPasswordPage.Link invalid") })
        navigate(Routes.RESET_PASSWORD)
    }

    return (
        <>
            <MetaTitle title={ t("pages:ResetPasswordPage.Title") } />

            <Center>
                <Box w={ 600 }>
                    <Stack>
                        <Title order={ 2 } ta="center">
                            { t("pages:ResetPasswordPage.Title") }
                        </Title>

                        <ContentCard>
                            <ResetPasswordForm uuid={ uuid } onSuccess={ onSuccess } onError={ onError } />
                        </ContentCard>
                    </Stack>
                </Box>
            </Center>
        </>
    );
}
