import { Routes } from "@/routing/Routes";
import { Group, Text, UnstyledButton } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { EventLineItem } from "./EventLineItem";
import { EventRegistrationStateIcon } from "./EventRegistrationStateIcon";
import { GetSpotsText } from "./Helper"
import { DateMode, Event } from "./types";


interface EventLineItemLinkedProps {
    dateMode: DateMode
    day?: DateTime
    event: Event
    withBorder?: boolean
}

export function EventLineItemLinked(props: EventLineItemLinkedProps) {
    const { dateMode, day, event, withBorder } = props

    const { from, to } = GetTimeText(day ?? DateTime.now(), event, dateMode)
    const spotsText = <GetSpotsText event={ event } />

    const rightSection = (
        <Group gap="xs" wrap="nowrap">
            <EventRegistrationStateIcon event={ event } />

            { spotsText && (
                <Text c="dimmed" miw={ 45 } ta="right" size="sm" style={ { whiteSpace: "nowrap", fontVariant: "tabular-nums" } }>
                    { spotsText }
                </Text>
            ) }
        </Group>
    )

    return (
        <UnstyledButton component={ Link } to={ generatePath(Routes.CLASSES_BY_ID, { uuid: event.uuid }) }>
            <EventLineItem color={ event.color }
                           from={ from }
                           to={ to }
                           miw={ dateMode === DateMode.ABSOLUTE ? 65 : null }
                           name={ event.name }
                           withBorder={ withBorder }
                           rightSection={ rightSection }
                           state={ event.state } />
        </UnstyledButton>
    )
}

function GetTimeText(day: DateTime, event: Event, dateMode: DateMode): { from: string, to: string | null } {
    const { t } = useTranslation()

    if (event.start.toISODate() < day.toISODate() && day.toISODate() < event.end.toISODate()) {
        switch (dateMode) {
            case DateMode.ABSOLUTE:
                return { from: event.start.toFormat("EEE dd.MM"), to: t("components:Events.EventCalendar.All day") }
            case DateMode.RELATIVE:
                return { from: t("components:Events.EventCalendar.All day"), to: null }
        }
    }

    if (event.start.toISODate() === day.toISODate() && day.toISODate() < event.end.toISODate()) {
        switch (dateMode) {
            case DateMode.ABSOLUTE:
                return { from: event.start.toFormat("EEE dd.MM"), to: event.start.toFormat("HH:mm") }
            case DateMode.RELATIVE:
                return { from: event.start.toFormat("HH:mm"), to: null }
        }
    }

    if (event.start.toISODate() < day.toISODate() && day.toISODate() === event.end.toISODate()) {
        return { from: t("components:Events.EventCalendar.Ends"), to: event.end.toFormat("HH:mm") }
    }

    switch (dateMode) {
        case DateMode.ABSOLUTE:
            return { from: event.start.toFormat("EEE dd.MM"), to: event.start.toFormat("HH:mm") }
        case DateMode.RELATIVE:
            return { from: event.start.toFormat("HH:mm"), to: event.end.toFormat("HH:mm") }
    }
}
