import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { useCreateWorkoutResultMutation, WorkoutResultInput, WorkoutResultType } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { WorkoutResultForm } from "./WorkoutResultForm";


interface CreateWorkoutResultFormProps {
    scoreBoardUUID: UUID

    resultCategories: readonly { uuid: UUID, name: string }[]
    resultType: WorkoutResultType

    onSuccess: (uuid: UUID) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function CreateWorkoutResultForm(props: CreateWorkoutResultFormProps) {
    const { scoreBoardUUID, resultType, resultCategories, onSuccess, onError, onCancel } = props

    const [ createWorkoutResult ] = useCreateWorkoutResultMutation()

    const onSubmit = async (input: WorkoutResultInput) => {
        return await executeMutation({
                mutation: () => createWorkoutResult({
                    variables: { scoreBoardUUID: scoreBoardUUID, input: input }
                }),
                onSuccess: data => {
                    onSuccess(data.createWorkoutResult.uuid);
                },
            onError: (error) => {
                    Logger.error("Failed to create WorkoutResult", error)
                    onError(error)
                }
            }
        )
    }

    const initialValues: WorkoutResultInput = {
        comment: null,
        score: "",
        workoutResultCategoryUUID: resultCategories.length > 0 ? resultCategories[0].uuid : null
    }

    return (
        <WorkoutResultForm initialValues={ initialValues }
                           resultCategories={ resultCategories }
                           resultType={ resultType }
                           onSubmit={ onSubmit }
                           onCancel={ onCancel } />
    )
}
