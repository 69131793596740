import { RegistrationState } from "@/generated/graphql";
import { ThemeIcon } from "@mantine/core";
import { IconCheck, IconClock, IconX } from "@tabler/icons-react";


interface EventRegistrationStateIconProps {
    event: {
        registrationState?: RegistrationState
    }
}

export function EventRegistrationStateIcon(props: EventRegistrationStateIconProps) {
    const { event } = props

    if (event.registrationState) {
        switch (event.registrationState) {
            case RegistrationState.Attended:
            case RegistrationState.Confirmed:
                return (
                    <ThemeIcon radius="sm" color="brand" variant="filled" size="sm">
                        <IconCheck size={ 16 } />
                    </ThemeIcon>
                )
            case RegistrationState.Cancelled:
            case RegistrationState.Missed:
                return (
                    <ThemeIcon radius="sm" color="red" variant="filled" size="sm">
                        <IconX size={ 16 } />
                    </ThemeIcon>
                )
            case RegistrationState.WaitList:
            case RegistrationState.WaitListPenalty:
                return (
                    <ThemeIcon radius="sm" color="blue" variant="filled" size="sm">
                        <IconClock size={ 16 } />
                    </ThemeIcon>
                )
        }
    }

    return null
}
