import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission, MembershipAgreementTemplateState } from "@/generated/graphql";
import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface MembershipTemplateStateBadgeProps {
    membershipTemplate: {
        state: MembershipAgreementTemplateState
    }
}

export function MembershipTemplateStateBadge(props: MembershipTemplateStateBadgeProps) {
    const { membershipTemplate } = props

    const { t } = useTranslation()
    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.MembershipAgreementManager)) {
        return null
    }

    switch (membershipTemplate.state) {
        case MembershipAgreementTemplateState.Deleted:
            return null
        case MembershipAgreementTemplateState.Draft:
            return <Badge color="orange" variant="filled">{ t(`entities:MembershipAgreementTemplateState.${ membershipTemplate.state }`) }</Badge>
        case MembershipAgreementTemplateState.Published:
            return <Badge color="green" variant="filled">{ t(`entities:MembershipAgreementTemplateState.${ membershipTemplate.state }`) }</Badge>
    }
}
