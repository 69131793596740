import { useOrganisation } from "@/contexts/organisation";
import { Routes } from "@/routing/Routes";
import { Anchor, Box, Container, SimpleGrid, Space, Stack, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./MainFooter.module.scss"

export function MainFooter() {
    const { t } = useTranslation()

    const { organisation } = useOrganisation();

    return (
        <footer className={ classes.footer }>
            <Container py="lg">
                <SimpleGrid cols={ { base: 1, sm: 2 } }>
                    <Box>
                        <Text fw={ 600 } size="lg">{ organisation.name }</Text>
                        <Stack gap={ 0 } align="flex-start">
                            <FooterLink to={ Routes.INFO }>{ t("common:Navigation.Info") }</FooterLink>
                            <FooterLink to={ Routes.CLASSES }>{ t("common:Navigation.Classes") }</FooterLink>
                            <FooterLink to={ Routes.EVENTS }>{ t("common:Navigation.Events") }</FooterLink>
                            <FooterLink to={ Routes.MEMBERSHIPS }>{ t("common:Navigation.Memberships") }</FooterLink>
                            <FooterLink to={ Routes.WORKOUTS }>{ t("common:Navigation.Workouts") }</FooterLink>
                            <Space h="lg" />
                            <FooterLink to={ Routes.INFO_TERMS_OF_SERVICE }>{ t("common:Navigation.TermsOfService") }</FooterLink>
                            <FooterLink to={ Routes.INFO_DATA_PROTECTION }>{ t("common:Navigation.DataProtection") }</FooterLink>
                            <FooterLink to={ Routes.CANCEL_MEMBERSHIP }>{ t("common:Navigation.Cancel Membership") }</FooterLink>
                        </Stack>
                    </Box>

                    <Box>
                        <Text fw={ 600 } size="lg">{ t("common:Misc.powered by") }</Text>
                        <Stack gap={ 0 } align="flex-start">
                            <Anchor href="https://mypowerhour.app" c="gray.0" size="sm">
                                mypowerhour.app <IconExternalLink size={ 11 } />
                            </Anchor>
                            <Anchor href="https://mypowerhour.app/terms-of-service" c="gray.0" size="sm">
                                { t("common:Navigation.TermsOfService") } <IconExternalLink size={ 11 } />
                            </Anchor>
                            <Anchor href="https://mypowerhour.app/data-protection" c="gray.0" size="sm">
                                { t("common:Navigation.DataProtection") } <IconExternalLink size={ 11 } />
                            </Anchor>
                            <FooterLink to={ Routes.CONTACT }>
                                { t("common:Navigation.Contact") }
                            </FooterLink>
                            <Space h="lg" />
                            <Text c="gray.0" size="sm">#{ import.meta.env.VITE_APP_GIT_SHA?.slice(0, 7) }</Text>
                        </Stack>
                    </Box>
                </SimpleGrid>
            </Container>
        </footer>
    )
}

interface FooterLinkProps {
    to: string
}

function FooterLink(props: PropsWithChildren<FooterLinkProps>) {
    const { to, children } = props

    return (
        <Anchor component={ Link } to={ to } c="gray.0" size="sm">{ children }</Anchor>
    )
}

