import { AddressLine1Input, AdressLevel2Input, BirthdayInput, FamilyNameInput, GivenNameInput, PhoneNumberInput, PostalCodeInput } from "@/components/shared/input";
import { PersonalInformationValue } from "@/generated/graphql";
import { Grid, Stack } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";


interface MembershipCheckoutFormSectionData {
    birthday: string
    city: string
    country: string
    firstname: string
    lastname: string
    phone: string
    postalCode: string
    streetAddress: string
}

interface MembershipCheckoutFormSectionProps {
    form: { getInputProps: UseFormReturnType<MembershipCheckoutFormSectionData>["getInputProps"] }
    requiredPersonalInformation: readonly PersonalInformationValue[]
}

export function MembershipCheckoutFormSection(props: MembershipCheckoutFormSectionProps) {
    const { form, requiredPersonalInformation } = props

    if (requiredPersonalInformation.length === 0) {
        return null
    }

    return (
        <Stack>
            <GivenNameInput required data-testid="firstname" { ...form.getInputProps("firstname") } />

            <FamilyNameInput required data-testid="lastname" { ...form.getInputProps("lastname") } />

            { requiredPersonalInformation.includes(PersonalInformationValue.Birthday) &&
                <BirthdayInput data-testid="birthday" required { ...form.getInputProps("birthday") } />
            }

            { requiredPersonalInformation.includes(PersonalInformationValue.Address) &&
                <>
                    <AddressLine1Input required data-testid="streetAddress" { ...form.getInputProps("streetAddress") } />

                    <Grid>
                        <Grid.Col span="content">
                            <PostalCodeInput w={ 100 } maw={ 100 }
                                             required data-testid="postalCode" { ...form.getInputProps("postalCode") } />
                        </Grid.Col>

                        <Grid.Col span="auto">
                            <AdressLevel2Input required data-testid="city" { ...form.getInputProps("city") } />
                        </Grid.Col>
                    </Grid>
                </>
            }

            { requiredPersonalInformation.includes(PersonalInformationValue.Phone) &&
                <PhoneNumberInput required data-testid="phone" { ...form.getInputProps("phone") } />
            }
        </Stack>
    )
}
