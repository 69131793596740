import { hasData } from "@/common/Object";
import { InfoFact } from "@/components/organisation/organisation";
import { ContentCard } from "@/components/shared";
import { UpdateOrganisationInfoButton } from "@/pages/info/_components/UpdateOrganisationInfoButton";
import { Anchor, Box, Card, Center, Group, Stack, Text } from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconMapPin, IconPhone, IconShoppingCart, IconWorld } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


interface ContactCardProps {
    data: {
        city: string | null
        facebookUrl: string | null
        instagramUrl: string | null
        name: string
        phone: string | null
        postalCode: string | null
        shopUrl: string | null
        streetAddress: string | null
        websiteUrl: string | null
        whatsAppNumber: string | null
    }
}

export function ContactCard(props: ContactCardProps) {
    const { t } = useTranslation()

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Group justify="space-between">
                    <Text fw={ 600 } size="lg">{ t("components:Info.ContactCard.Title") }</Text>
                    <UpdateOrganisationInfoButton />
                </Group>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <ContactCardContent { ...props } />
            </Card.Section>
        </ContentCard>
    )
}

function ContactCardContent(props: ContactCardProps) {
    const { data } = props

    const { t } = useTranslation()

    const { name, ...other } = data

    if (!hasData(other)) {
        return (
            <Center>
                <Text>{ t("components:Info.ContactCard.No Information") }</Text>
            </Center>
        )
    }

    return (
        <Stack>
            { data.streetAddress !== null && data.city !== null && data.postalCode !== null && <Box>
                <InfoFact icon={ <IconMapPin /> }>
                    <Text>{ data.name }</Text>
                    <Text>{ data.streetAddress }</Text>
                    <Text>{ data.postalCode } { data.city }</Text>
                </InfoFact>
            </Box> }

            { data.phone && (
                <Box>
                    <InfoFact icon={ <IconPhone /> }>
                        <Anchor href={ `tel:${ data.phone }` } data-umami-event="contact-phone">
                            { data.phone }
                        </Anchor>
                    </InfoFact>
                </Box>
            ) }

            { data.whatsAppNumber && (
                <Box>
                    <InfoFact icon={ <IconBrandWhatsapp /> }>
                        <Anchor href={ `https://wa.me/${ data.whatsAppNumber }` } target="_blank" data-umami-event="contact-whatsapp">
                            { t("components:Info.ContactCard.Write us on WhatsApp") }
                        </Anchor>
                    </InfoFact>
                </Box>
            ) }

            { data.websiteUrl && (
                <Box>
                    <InfoFact icon={ <IconWorld /> }>
                        <Anchor href={ data.websiteUrl } target="_blank" data-umami-event="contact-website">
                            { data.websiteUrl.replaceAll(RegExp("(http://|https://|www.)", "g"), "") }
                        </Anchor>
                    </InfoFact>
                </Box>
            ) }

            { data.facebookUrl && (
                <Box>
                    <InfoFact icon={ <IconBrandFacebook /> }>
                        <Anchor href={ data.facebookUrl } target="_blank" data-umami-event="contact-facebook">
                            { data.facebookUrl.replaceAll(RegExp("(http://|https://|www.)", "g"), "") }
                        </Anchor>
                    </InfoFact>
                </Box>
            ) }

            { data.instagramUrl && (
                <Box>
                    <InfoFact icon={ <IconBrandInstagram /> }>
                        <Anchor href={ data.instagramUrl } target="_blank" data-umami-event="contact-instagram">
                            { data.instagramUrl.replaceAll(RegExp("(http://|https://|www.)", "g"), "") }
                        </Anchor>
                    </InfoFact>
                </Box>
            ) }

            { data.shopUrl && (
                <Box>
                    <InfoFact icon={ <IconShoppingCart /> }>
                        <Anchor href={ data.shopUrl } target="_blank" data-umami-event="contact-shop">
                            { data.shopUrl.replaceAll(RegExp("(http://|https://|www.)", "g"), "") }
                        </Anchor>
                    </InfoFact>
                </Box>
            ) }
        </Stack>
    )
}
