import { Fact } from "@/components/shared/Fact";
import { AttendanceTimeUnit, AttendanceType } from "@/generated/graphql";
import { useTranslation } from "react-i18next";

interface AttendanceFactProps {
    count: number
    timeUnit: AttendanceTimeUnit
    type: AttendanceType
}

export function AttendanceFact(props: AttendanceFactProps) {
    const { t } = useTranslation()

    return (
        <Fact title={ t("components:Memberships.AttendanceFact.Attendance") }>
            { AttendanceFactText(props) }
        </Fact>
    )
}

export function AttendanceFactText(props: AttendanceFactProps) {
    const { count, timeUnit, type } = props

    const { t } = useTranslation()

    if (type === AttendanceType.Unlimited) {
        return t("components:Memberships.AttendanceFact.Unlimited")
    } else {
        return t("components:Memberships.AttendanceFact.CreditsPerTimeUnit", { count: count, timeUnit: timeUnit })
    }
}
