import { showSuccessNotification } from "@/common/Notification"
import { ContentCard } from "@/components/shared";
import { Card, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { UpdatePasswordForm } from "./UpdatePasswordForm"


export function UpdatePasswordCard() {
    const { t } = useTranslation()

    const onSuccess = () => {
        showSuccessNotification(t("components:Account.UpdatePasswordCard.Password updated"))
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Account.UpdatePasswordCard.Title") }</Text>
            </Card.Section>
            <Card.Section inheritPadding py="sm">
                <UpdatePasswordForm onSuccess={ onSuccess } />
            </Card.Section>
        </ContentCard>
    )
}
