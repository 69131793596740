import { useAuthentication } from "@/contexts/authentication";
import { useOrganisation } from "@/contexts/organisation";
import Router from "@/routing/Router";
import { Loader, Stack, Text } from "@mantine/core";
import React from "react";
import { RouterProvider } from "react-router-dom";


export function InitializationBoundary() {
    const { isInitialized: isOrganisationInitialized } = useOrganisation()
    const { isInitialized: isAuthenticationInitialized } = useAuthentication()

    if (isOrganisationInitialized && isAuthenticationInitialized) {
        return <RouterProvider router={ Router.get() } />
    }

    return (
        <Stack align="center" justify="center" style={ { height: "100vh" } }>
            <Loader variant="bars" size="xl" />
            <Text size="xl" fw={ 600 }>Loading...</Text>
        </Stack>
    )
}
