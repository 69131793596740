import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate";
import { useDeleteWorkoutMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Routes } from "@/routing/Routes";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


interface DeleteWorkoutItemProps {
    workoutUUID: UUID
}

export function DeleteWorkoutItem(props: DeleteWorkoutItemProps) {
    const { workoutUUID } = props

    const { t } = useTranslation()
    const { navigateBack } = useMyPowerHourNavigate()

    const [ deleteWorkout ] = useDeleteWorkoutMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("pages:WorkoutPage.Delete Workout") }</Text>,
            children: t("pages:WorkoutPage.Do you want to delete this Workout"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Delete") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => deleteWorkout({ variables: { uuid: workoutUUID } }),
                    onSuccess: () => {
                        showSuccessNotification(t("pages:WorkoutPage.Workout deleted"))
                        navigateBack(Routes.WORKOUTS)
                    },
                    onError: (error) => {
                        Logger.error("Failed to delete Workout", error)
                        showErrorNotification({ message: t("pages:WorkoutPage.Failed to delete Workout") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } color="red" leftSection={ <IconTrash size={ 18 } /> }
                   id="delete-workout-button" data-umami-event="delete-workout-button">
            { t("pages:WorkoutPage.Delete Workout") }
        </Menu.Item>
    )
}
