import App from "@/App";
import Logger from "@/common/Logger"
import "@/configuration/I18nConfiguration";
import { buildGraphQlClient } from "@/contexts/graphql/GraphqlContext"
import * as Sentry from "@sentry/react";
import fetchRetry from "fetch-retry"
import React from "react";
import { createRoot } from "react-dom/client"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { AppPropertiesType, OrganisationType } from "./configuration/AppProperties"


if (import.meta.env.MODE !== "development") {
    Sentry.init({
        environment: "production",
        release: import.meta.env.VITE_APP_GIT_SHA,
        tunnel: "https://sentry.mypowerhour.app",
        dsn: "https://e0770b30487f46fea1e1d539bd572525@o4504265136078848.ingest.sentry.io/4504265138700288",

        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation: useLocation,
                useNavigationType: useNavigationType,
                createRoutesFromChildren: createRoutesFromChildren,
                matchRoutes: matchRoutes
            }),
            Sentry.replayIntegration()
        ],

        tracesSampleRate: 0.5, // Sample rate for performance monitoring
    });
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
console.log(`mypowerhour - ${ import.meta.env.VITE_APP_GIT_SHA?.slice(0, 7) } - ${ __DATE__ }`)

const fetch = fetchRetry(window.fetch)
const fetchAppProperties = fetch("/application.json", { retryDelay: 250 }).then(response => response.json() as Promise<AppPropertiesType>)
const fetchOrganisation = fetch("/organisation.json", { retryDelay: 250 }).then(response => response.json() as Promise<OrganisationType>)

Promise
    .all([ fetchAppProperties, fetchOrganisation ])
    .then(([ appProperties, organisation ]) => {
        window.appProperties = appProperties
        window.organisation = organisation

        const client = buildGraphQlClient()

        const container = document.getElementById("root");
        const root = createRoot(container!);
        root.render(<App client={ client } />);
    })
    .catch(e => {
        Logger.exception("Couldn't load application", e)
    })
