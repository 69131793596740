import Events from "@/common/Events";
import { toDuration } from "@/common/Luxon";
import { byStartAndName } from "@/common/Sort";
import { MyInAppNotificationsCardContainer } from "@/components/core/in-app-notification";
import { MyUpcomingEventsCard } from "@/components/events/event";
import { MyRemarksAndPenaltiesCard } from "@/components/events/penalty";
import { useNavigationItems } from "@/components/navigation";
import { ContentCard, PageTitle, PWAInstallAlert } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useDeviceSize } from "@/contexts/device-size";
import { GraphQLClient } from "@/contexts/graphql";
import { useInAppNotificationsContext } from "@/contexts/in-app-notification";
import { MyDashboardPageDocument, MyDashboardPageQuery, MyDashboardPageQueryVariables } from "@/generated/graphql";
import { ApolloQueryResult } from "@apollo/client";
import { Box, Card, Divider, Grid, Group, SimpleGrid, Stack, Text, UnstyledButton } from "@mantine/core";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";


export function MyDashboardPageLoader() {
    return GraphQLClient.query<MyDashboardPageQuery, MyDashboardPageQueryVariables>({
        query: MyDashboardPageDocument,
        variables: {
            filter: {
                start: DateTime.now().toISO(),
                end: null,
                eventId: null
            }
        }
    })
}

export function MyDashboardPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<MyDashboardPageQuery>

    const { t } = useTranslation()
    const navigation = useNavigationItems()
    const { isMobile: showShortcutNavigation } = useDeviceSize()
    const { refetch } = useInAppNotificationsContext()

    // fetch InAppNotifications on initial render
    useEffect(() => {
        refetch()
    }, []);

    const events = queryResult.data.myEventRegistrations.results
        .map(it => ({
            uuid: it.event.uuid,
            availableSpots: it.event.eventDetails.registrationSettings.availableSpots,
            color: it.event.eventDetails.color,
            end: DateTime.fromISO(it.event.end),
            name: it.event.eventDetails.name,
            start: DateTime.fromISO(it.event.start),
            state: it.event.state,
            isRegistrable: it.event.eventDetails.registrationSettings.isRegistrable,
            registrationStartPeriod: toDuration(it.event.eventDetails.registrationSettings.registrationStartPeriod),
            registrationState: it.state,
            usedSpots: it.event.registrations?.filter(it => Events.CountableRegistrations.includes(it.state))?.length
        }))
        .sort(byStartAndName)

    const remarks = queryResult.data.myPenaltyRemarks.list
        .map(it => ({
            eventUUID: it.eventRegistration.event.uuid,
            eventName: it.eventRegistration.event.eventDetails.name,
            eventStart: DateTime.fromISO(it.eventRegistration.event.start),
                end: DateTime.fromISO(it.end)
            })
        )

    const penalties = queryResult.data.myPenalties.list
        .map(it => ({
                end: DateTime.fromISO(it.end)
            })
        )

    return (
        <>
            <MetaTitle title={ t("pages:MyDashboardPage.Title") } />

            <Stack>
                { !showShortcutNavigation && <PageTitle title={ t("pages:MyDashboardPage.Title") } /> }

                { showShortcutNavigation && (
                    <>
                        <SimpleGrid cols={ 2 }>
                            {
                                navigation.sets.my.dashboard.map(it => (
                                    <UnstyledButton key={ it.labelKey } component={ Link } to={ it.to }>
                                        <ContentCard shadow="none" withBorder>
                                            <Card.Section inheritPadding py="sm">
                                                <Group align="center" gap="xs" wrap="nowrap">
                                                    <Box lh={ 0 }>
                                                        { it.icon && <it.icon stroke={ 1.5 } /> }
                                                    </Box>
                                                    <Text size="sm" fw={ 500 } style={ { overflow: "hidden", textOverflow: "ellipsis" } }>
                                                        { t(it.labelKey) }
                                                    </Text>
                                                </Group>
                                            </Card.Section>
                                        </ContentCard>
                                    </UnstyledButton>
                                ))
                            }
                        </SimpleGrid>

                        <Divider />
                    </>
                ) }

                <PWAInstallAlert />

                <Grid>
                    <Grid.Col span={ { base: 12, md: 6 } } mih={ 150 }>
                        <MyUpcomingEventsCard events={ events } />
                    </Grid.Col>

                    <Grid.Col span={ { base: 12, md: 6 } } mih={ 150 }>
                        <MyInAppNotificationsCardContainer />
                    </Grid.Col>

                    { (remarks.length > 0 || penalties.length > 0) && (
                        <Grid.Col span={ { base: 12, md: 6 } } mih={ 150 }>
                            <MyRemarksAndPenaltiesCard remarks={ remarks } penalties={ penalties } />
                        </Grid.Col>
                    ) }
                </Grid>
            </Stack>
        </>
    )
}
