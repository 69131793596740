import { showSuccessNotification } from "@/common/Notification";
import { MyBilledEventRegistrationCard } from "@/components/memberships/billed-event-registrations";
import { MembershipDetails } from "@/components/memberships/details/MembershipDetails";
import {
    CreditUsageCard,
    MembershipStateBadge,
    RequestTerminationCard,
    RevokeTerminationCard,
    SuspendedHint,
    SwitchInfoCard,
    TerminationHint
} from "@/components/memberships/membership";
import { PaymentMethodList } from "@/components/memberships/PaymentMethodList";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import {
    MembershipAgreementAction,
    MyMembershipAgreementByIdPageDocument,
    MyMembershipAgreementByIdPageQuery,
    MyMembershipAgreementByIdPageQueryVariables,
} from "@/generated/graphql";
import { Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, useLoaderData, useRevalidator } from "react-router-dom";


interface MyMembershipAgreementByIdPageData {
    allowedMembershipAgreementActions: MyMembershipAgreementByIdPageQuery["allowedMembershipAgreementActions"]
    membershipAgreement: MyMembershipAgreementByIdPageQuery["membershipAgreement"]
}

export async function MyMembershipAgreementByIdPageLoader({ params }: LoaderFunctionArgs): Promise<MyMembershipAgreementByIdPageData> {
    const pageQuery = await GraphQLClient.query<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>({
        query: MyMembershipAgreementByIdPageDocument,
        variables: { uuid: params.uuid! },
    })

    return {
        allowedMembershipAgreementActions: pageQuery.data.allowedMembershipAgreementActions,
        membershipAgreement: pageQuery.data.membershipAgreement
    }
}

export function MyMembershipAgreementByIdPage() {
    const { allowedMembershipAgreementActions, membershipAgreement } = useLoaderData() as MyMembershipAgreementByIdPageData

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const onTerminationRequested = () => {
        showSuccessNotification(t("components:Memberships.RequestTerminationCard.Membership terminated"))
        revalidator.revalidate()
    }

    const onTerminationRevoked = () => {
        showSuccessNotification(t("components:Memberships.RevokeTerminationCard.Termination revoked"))
        revalidator.revalidate()
    }

    return (
        <>
            <MetaTitle title={ membershipAgreement.name } />

            <Stack>
                <PageTitleWithBack title={ membershipAgreement.name } />

                <SuspendedHint membershipAgreement={ membershipAgreement } />

                <TerminationHint membershipAgreement={ membershipAgreement } />

                <ContentCard>
                    <Card.Section withBorder inheritPadding py="sm">
                        <Group justify="space-between">
                            <Text fw={ 600 } size="lg">{ t("pages:MyMembershipAgreementByIdPage.MembershipFacts") }</Text>
                            <MembershipStateBadge membership={ membershipAgreement } />
                        </Group>
                    </Card.Section>

                    <Card.Section inheritPadding py="sm">
                        <MembershipDetails contractee={ membershipAgreement.contractor }
                                           membership={ membershipAgreement }
                                           organisation={ membershipAgreement.organisation } />
                    </Card.Section>
                </ContentCard>

                { membershipAgreement.paymentMethods.length > 0 &&
                    <ContentCard>
                        <Card.Section withBorder inheritPadding py="sm">
                            <Text fw={ 600 } size="lg">{ t("pages:MyMembershipAgreementByIdPage.PaymentMethods") }</Text>
                        </Card.Section>

                        <Card.Section inheritPadding py="md">
                            <PaymentMethodList paymentMethods={ membershipAgreement.paymentMethods } />
                        </Card.Section>
                    </ContentCard>
                }

                <CreditUsageCard membershipAgreement={ membershipAgreement } />

                <MyBilledEventRegistrationCard membershipAgreementUUID={ membershipAgreement.uuid } />

                {
                    allowedMembershipAgreementActions.includes(MembershipAgreementAction.Switch) &&
                    <SwitchInfoCard />
                }

                {
                    allowedMembershipAgreementActions.includes(MembershipAgreementAction.RequestTermination) &&
                    <RequestTerminationCard membership={ membershipAgreement }
                                            onTerminationRequested={ onTerminationRequested } />
                }

                {
                    allowedMembershipAgreementActions.includes(MembershipAgreementAction.RevokeTerminationRequest) &&
                    <RevokeTerminationCard membership={ membershipAgreement }
                                           onTerminationRevoked={ onTerminationRevoked } />
                }
            </Stack>
        </>
    )
}
