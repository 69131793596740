import { SessionData } from "./Types";

const KEY = "mph_session"

function save(response: SessionData) {
    if (window.localStorage) {
        window.localStorage.setItem(KEY, JSON.stringify(response))
    }
}

function find(): SessionData | null {
    if (window.localStorage) {
        const data = window.localStorage.getItem(KEY)
        if (data) {
            try {
                return JSON.parse(data) as SessionData
            } catch {
                window.localStorage.removeItem(KEY)
                return null
            }
        }
    }

    return null
}

function clear() {
    if (window.localStorage) {
        window.localStorage.removeItem(KEY)
    }
}

export const LocalSessionStorage = {
    clear: clear,
    find: find,
    save: save,
}
