import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Anchor, Box, Stack, Text } from "@mantine/core";
import { generatePath, Link } from "react-router-dom";


interface AddressProps {
    city: string | null
    country: string | null
    emailAddress: string | null
    name: string
    phone: string | null
    postalCode: string | null
    streetAddress: string | null

    memberUUID?: string | null
    title?: string | null
}

export function Address(props: AddressProps) {
    const { city, country, emailAddress, memberUUID, name, phone, postalCode, streetAddress, title } = props

    const { hasPermission } = useAuthentication()

    let nameLine
    if (hasPermission(MemberPermission.MembershipAgreementManager) && memberUUID) {
        nameLine = <Anchor component={ Link } to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID, { uuid: memberUUID }) } fw={ 600 }>{ name }</Anchor>
    } else {
        nameLine = <Text fw={ 600 }>{ name }</Text>
    }

    return (
        <Stack>
            <Stack gap={ 0 }>
                { title && <Box><Text c="dimmed" size="sm">{ title }</Text></Box> }

                <Box>{ nameLine }</Box>
                { streetAddress && <Box><Text>{ streetAddress }</Text></Box> }
                { postalCode && city && <Box><Text>{ postalCode } { city }</Text></Box> }
                { country && <Box><Text>{ country } </Text></Box> }
            </Stack>

            { (emailAddress || phone) && (
                <Stack gap={ 0 }>
                    { emailAddress && <Box><Anchor onClick={ () => window.location.assign(`mailto:${ emailAddress }`) }>{ emailAddress }</Anchor></Box> }
                    { phone && <Box><Anchor onClick={ () => window.location.assign(`tel:${ phone }`) }>{ phone }</Anchor></Box> }
                </Stack>
            ) }
        </Stack>
    )
}
