import { TablerIcon } from "@/common/Types"
import { ContentCard } from "@/components/shared";
import { InfoPageQuery, LocationEquipmentTypes } from "@/generated/graphql"
import { UpdateOrganisationInfoButton } from "@/pages/info/_components/UpdateOrganisationInfoButton"
import { Badge, Card, Flex, Group, px, Stack, Text } from "@mantine/core";
import { IconBadgeWc, IconBath, IconHanger, IconParking, IconPool, IconShoppingBag } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


interface EquipmentCardProps {
    equipment: InfoPageQuery["equipment"]
}

export function EquipmentCard(props: EquipmentCardProps) {
    const { equipment } = props

    const { t } = useTranslation()

    const locationIcons: { [key in LocationEquipmentTypes]: TablerIcon } = {
        [LocationEquipmentTypes.Restrooms]: IconBadgeWc,
        [LocationEquipmentTypes.Pool]: IconPool,
        [LocationEquipmentTypes.Shop]: IconShoppingBag,
        [LocationEquipmentTypes.Showers]: IconBath,
        [LocationEquipmentTypes.Cloakrooms]: IconHanger,
        [LocationEquipmentTypes.ParkingLot]: IconParking
    }

    if (equipment.location.length === 0) {
        return null
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Group justify="space-between">
                    <Text fw={ 600 } size="lg">{ t("components:Info.Equipment.Title") }</Text>
                    <UpdateOrganisationInfoButton />
                </Group>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <Stack gap={ 4 }>
                    <Group>
                        {
                            equipment.location.map(it => {
                                const icon = { icon: locationIcons[it] }
                                const iconEl = (
                                    <Flex>
                                        <icon.icon size={ px("1rem") } />
                                    </Flex>
                                )

                                return (
                                    <Badge key={ it } radius="xs" size="lg" variant="light" color="dark" leftSection={ iconEl }>
                                        { t(`entities:LocationEquipmentTypes.${ it }`) }
                                    </Badge>
                                )
                            })
                        }
                    </Group>
                </Stack>
            </Card.Section>
        </ContentCard>
    )
}
