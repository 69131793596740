import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { BenchmarkResultInput, BenchmarkResultType, useCreateBenchmarkResultMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { DateTime } from "luxon";
import { BenchmarkResultForm } from "./BenchmarkResultForm";

interface CreateBenchmarkResultFormProps {
    benchmarkUUID: UUID
    resultType: BenchmarkResultType
    onSuccess: (uuid: UUID) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function CreateBenchmarkResultForm(props: CreateBenchmarkResultFormProps) {
    const { benchmarkUUID, resultType, onSuccess, onError, onCancel } = props

    const [ createBenchmarkResult ] = useCreateBenchmarkResultMutation()

    const onSubmit = async (input: BenchmarkResultInput) => {
        return await executeMutation({
                mutation: () => createBenchmarkResult({
                    variables: { benchmarkUUID: benchmarkUUID, input: input }
                }),
                onSuccess: data => {
                    onSuccess(data.createBenchmarkResult.uuid)
                },
            onError: (error) => {
                    Logger.error("Failed to create BenchmarkResult", error)
                    onError(error)
                }
            }
        )
    }

    const initialValues = {
        date: DateTime.now().toISODate(),
        score: "",
        comment: null,
    }

    return (
        <BenchmarkResultForm initialValues={ initialValues }
                             resultType={ resultType }
                             onSubmit={ onSubmit }
                             onCancel={ onCancel } />
    )
}
