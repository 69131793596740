import { UUID } from "@/common/Types";
import { MembershipStateBadge } from "@/components/memberships/membership/MembershipStateBadge"
import { MembershipListItem } from "@/components/memberships/MembershipListItem"
import { PaperStack } from "@/components/shared";
import { MembershipAgreementState } from "@/generated/graphql";
import { generatePath } from "react-router-dom";


export interface MembershipListProps {
    memberships: readonly {
        uuid: UUID
        created: string
        name: string
        state: MembershipAgreementState
        subtitle: string | null
    }[]
    route: string,
}

export function MembershipList(props: MembershipListProps) {
    const { memberships, route } = props

    return (
        <PaperStack>
            {
                memberships
                    .slice()
                    .sort((a, b) => b.created.localeCompare(a.created))
                    .map(it => <MembershipListItem key={ it.uuid }
                                                   badge={ <MembershipStateBadge membership={ it } /> }
                                                   name={ it.name }
                                                   subtitle={ it.subtitle }
                                                   to={ generatePath(route, { uuid: it.uuid }) } />
                    )
            }
        </PaperStack>
    )
}
