import { byDateTime } from "@/common/Sort"
import { UUID } from "@/common/Types"
import classes from "@/components/events/penalty/MyRemarksAndPenalties.module.scss";
import { ContentCard } from "@/components/shared";
import { Routes } from "@/routing/Routes"
import { Alert, Card, Stack, Text, UnstyledButton } from "@mantine/core";
import { IconHandStop } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface RemarksType {
    eventUUID: UUID
    eventName: string
    eventStart: DateTime
    end: DateTime
}

interface PenaltyType {
    end: DateTime
}

interface MyRemarksAndPenaltiesCardProps {
    penalties: readonly PenaltyType[]
    remarks: readonly RemarksType[]
}

export function MyRemarksAndPenaltiesCard(props: MyRemarksAndPenaltiesCardProps) {
    const { penalties, remarks } = props

    const { t } = useTranslation()

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Events.MyRemarksAndPenaltiesCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <MyRemarksAndPenalties remarks={ remarks } penalties={ penalties } />
            </Card.Section>
        </ContentCard>
    )
}

export function MyRemarksAndPenalties(props: MyRemarksAndPenaltiesCardProps) {
    const { remarks, penalties } = props

    if (remarks.length === 0 && penalties.length === 0) {
        return null
    }

    return (
        <Stack>
            <MyPenalties penalties={ penalties } />
            <MyRemarks remarks={ remarks } />
        </Stack>
    )
}

interface MyRemarksProps {
    remarks: readonly RemarksType[]
}

function MyRemarks(props: MyRemarksProps) {
    const { remarks } = props

    const { t } = useTranslation()

    if (remarks.length === 0) {
        return null
    }

    return (
        <Stack gap={ 4 }>
            <Text fw={ 600 }>{ t("components:Events.MyRemarksAndPenaltiesCard.Remarks") }</Text>
            <Stack>
                {
                    remarks
                        .slice()
                        .sort((a, b) => byDateTime(a.end, b.end))
                        .map(it => (
                            <UnstyledButton key={ it.eventUUID } className={ classes.remark }
                                            component={ Link } to={ generatePath(Routes.CLASSES_BY_ID, { uuid: it.eventUUID }) }>
                                <Text>{ it.eventName } { t("components:Events.MyRemarksAndPenaltiesCard.At") } { it.eventStart.toFormat("dd.MM. HH:mm") }</Text>
                                <Text c="dimmed" size="sm">{ t("components:Events.MyRemarksAndPenaltiesCard.Valid until") } { it.end.toFormat("dd.MM. HH:mm") }</Text>
                            </UnstyledButton>
                        ))
                }
            </Stack>
        </Stack>
    )
}

interface MyPenaltiesProps {
    penalties: readonly PenaltyType[]
}

function MyPenalties(props: MyPenaltiesProps) {
    const { penalties } = props

    const { t } = useTranslation()

    if (penalties.length === 0) {
        return null
    }

    return (
        <Stack>
            {
                penalties
                    .slice()
                    .sort((a, b) => byDateTime(a.end, b.end))
                    .map(it => (
                        <Alert key={ it.end.toISO() } color="red" icon={ <IconHandStop /> }>
                            { t("components:Events.MyRemarksAndPenaltiesCard.You have an active penalty", { end: it.end.toFormat("EEEE dd.MM.") }) }
                        </Alert>
                    ))
            }
        </Stack>
    )
}

