import { MainLayout } from "@/layout/main/MainLayout"
import { PwaHandler } from "@/routing/PwaHandler"
import { useDidUpdate, useDocumentVisibility } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { ScrollRestoration, useRevalidator } from "react-router-dom";


export function Root() {
    useRevalidateOnVisible()

    return (
        <ModalsProvider modalProps={ { centered: true, xOffset: "2vw", size: "lg", zIndex: 501, overlayProps: { blur: 6 } } }>
            <PwaHandler />
            <MainLayout />
            <ScrollRestoration />
        </ModalsProvider>
    )
}

function useRevalidateOnVisible() {
    const revalidator = useRevalidator()
    const visibility = useDocumentVisibility()

    // useDidUpdate instead of useEffect to not run on mount
    useDidUpdate(() => {
        if (visibility == "visible" && ("connection" in navigator) && navigator.onLine) {
            revalidator.revalidate()
        }
    }, [ visibility ])
}
