import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import W from "@/common/Workout"
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { UpdateBenchmarkResultForm } from "@/components/workouts/benchmark-result/UpdateBenchmarkResultForm";
import { WeightCalculator } from "@/components/workouts/weight-calculator";
import { GraphQLClient } from "@/contexts/graphql";
import { BenchmarkDocument, BenchmarkQuery, BenchmarkQueryVariables, BenchmarkResultType } from "@/generated/graphql";
import { BenchmarkMenu } from "@/pages/my/benchmarks/[uuid]/_components/benchmarkMenu/BenchmarkMenu";
import { CreateResultButton } from "@/pages/my/benchmarks/[uuid]/_components/CreateResultButton";
import { Box, Center, Group, Stack, Table, Text } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, useLoaderData, useRevalidator } from "react-router-dom";

const UPDATE_RESULT_MODAL_ID = "update-benchmark-result"

export async function BenchmarkPageLoader({ params }: LoaderFunctionArgs) {
    const query = await GraphQLClient.query<BenchmarkQuery, BenchmarkQueryVariables>({
        query: BenchmarkDocument,
        variables: { uuid: params.uuid! },
    })

    return {
        benchmark: query.data.benchmark
    }
}

export function BenchmarkPage() {
    const { benchmark } = useLoaderData() as { benchmark: BenchmarkQuery["benchmark"] }

    const { t } = useTranslation()

    return (
        <>
            <MetaTitle title={ benchmark.name } />

            <Stack>
                <Group justify="space-between" align="center" wrap="nowrap">
                    <PageTitleWithBack title={ benchmark.name } />

                    <BenchmarkMenu benchmarkUUID={ benchmark.uuid } />
                </Group>

                { benchmark.description && (
                    <ContentCard>
                        <Text>
                            { benchmark.description }
                        </Text>
                    </ContentCard>
                ) }

                <Group justify="space-between" align="center" wrap="nowrap">
                    <Group gap="lg" ml="xs">
                        <Box mr={ 24 }>
                            <Text c="dimmed" size="sm">{ t(`entities:Workout.Benchmark.Fields.ResultType`) }</Text>
                            <Text fw={ 600 }>{ t(`entities:BenchmarkResultType.${ benchmark.resultType }`) }</Text>
                        </Box>
                        <Box>
                            <Text c="dimmed" size="sm">{ t(`entities:Workout.Benchmark.Fields.ResultOrder`) }</Text>
                            <Text fw={ 600 }>{ t(`entities:BenchmarkResultOrder.${ benchmark.resultOrder }`) }</Text>
                        </Box>
                    </Group>
                    <CreateResultButton benchmark={ benchmark } />
                </Group>

                <BestResultWeightCalculator resultType={ benchmark.resultType } results={ benchmark.results } />

                <ResultList results={ benchmark.results } resultType={ benchmark.resultType } />

            </Stack>
        </>
    )
}

interface ResultType {
    uuid: UUID
    date: string
    score: string
    comment: string | null
}

interface ResultListProps {
    results: readonly ResultType[]
    resultType: BenchmarkResultType
}

export function ResultList(props: ResultListProps) {
    const { results, resultType } = props

    const { t } = useTranslation()

    if (results.length === 0) {
        return (
            <ContentCard p={ 0 }>
                <Center p="sm">
                    <Text>{ t("pages:BenchmarkPage.No Results") }</Text>
                </Center>
            </ContentCard>
        )
    }

    return (
        <ContentCard p={ 0 }>
            <Table highlightOnHover>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th style={ { width: "1%" } }>{ t("entities:Workout.BenchmarkResult.Fields.Date") }</Table.Th>
                        <Table.Th style={ { width: "1%" } } align="right">{ t("entities:Workout.BenchmarkResult.Fields.Score") }</Table.Th>
                        <Table.Th>{ t("entities:Workout.BenchmarkResult.Fields.Comment") }</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                { results.map(it => <Result key={ it.uuid } result={ it } resultType={ resultType } />) }
                </Table.Tbody>
            </Table>
        </ContentCard>
    )
}


interface ResultProps {
    result: ResultType
    resultType: BenchmarkResultType
}

function Result(props: ResultProps) {
    const { result, resultType } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const onSubmitSuccess = () => {
        showSuccessNotification(t("common:Forms.Saved"))
        revalidator.revalidate()
        closeModal(UPDATE_RESULT_MODAL_ID)
    }

    const onSubmitError = () => {
        showErrorNotification({ message: t("pages:BenchmarkPage.Failed to update Result") })
    }

    const onDeleteSuccess = () => {
        showSuccessNotification(t("common:Forms.Deleted"))
        revalidator.revalidate()
        closeModal(UPDATE_RESULT_MODAL_ID)
    }

    const onDeleteError = () => {
        showErrorNotification({ message: t("pages:BenchmarkPage.Failed to delete Result") })
    }

    const onUpdateResult = () => {
        openModal({
            modalId: UPDATE_RESULT_MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("pages:BenchmarkPage.Update Result") }</Text>,
            children: <UpdateBenchmarkResultForm uuid={ result.uuid }
                                                 initialValues={ result }
                                                 resultType={ resultType }
                                                 onSubmitSuccess={ onSubmitSuccess }
                                                 onSubmitError={ onSubmitError }
                                                 onDeleteSuccess={ onDeleteSuccess }
                                                 onDeleteError={ onDeleteError } />,
            closeOnClickOutside: false
        })
    }

    return (
        <Table.Tr style={ { cursor: "pointer" } }>
            <Table.Td onClick={ onUpdateResult }>
                <Text style={ { fontVariantNumeric: "tabular-nums" } }>
                    { DateTime.fromISO(result.date).toFormat("dd.MM.yyyy") }
                </Text>
            </Table.Td>
            <Table.Td align="right" onClick={ onUpdateResult }>
                <Text style={ { fontVariantNumeric: "tabular-nums" } }>
                    { W.decodeScore(resultType, result.score) }
                </Text>
            </Table.Td>
            <Table.Td onClick={ onUpdateResult }>
                <Text>{ result.comment }</Text>
            </Table.Td>
        </Table.Tr>
    )
}

interface BestResultWeightCalculatorProps {
    resultType: BenchmarkResultType
    results: readonly ResultType[]
}

function BestResultWeightCalculator(props: BestResultWeightCalculatorProps) {
    const { resultType, results } = props

    if (resultType !== BenchmarkResultType.Weight) {
        return null
    }

    if (results.length === 0) {
        return null
    }

    const max = Math.max(...results.map(it => parseFloat(W.decodeScore(resultType, it.score))))

    return (
        <ContentCard>
            <Stack>
                <WeightCalculator key={ max } initialValue={ max } />
            </Stack>
        </ContentCard>
    )
}
