import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { DeleteEventFormContainer } from "@/components/events/deleteEventForm/DeleteEventFormContainer";
import { Menu, Text } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


const DELETE_EVENT_MODAL_ID = "delete-event"

interface DeleteEventItemProps {
    eventUUID: UUID
    onEventDeleted: () => void
}

export function DeleteEventItem(props: DeleteEventItemProps) {
    const { eventUUID, onEventDeleted } = props

    const { t } = useTranslation()

    const onSuccess = () => {
        showSuccessNotification(t("components:Events.DeleteEvent.Event deleted"))
        closeModal(DELETE_EVENT_MODAL_ID)
        onEventDeleted()
    }

    const onError = () => {
        showErrorNotification({ message: t("components:Events.DeleteEvent.Failed to delete event") })
    }

    const onClick = () => {
        openModal({
            modalId: "delete-event",
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.DeleteEvent.Delete event") }</Text>,
            children: <DeleteEventFormContainer eventUUID={ eventUUID }
                                                onSuccess={ onSuccess }
                                                onError={ onError }
                                                onCancel={ () => closeModal(DELETE_EVENT_MODAL_ID) } />,
        })
    }

    return (
        <Menu.Item onClick={ onClick } color="red" leftSection={ <IconTrash size={ 18 } /> } id="delete-event-menu-item" data-umami-event="delete-event-menu-item">
            { t("components:Events.DeleteEvent.Delete event") }
        </Menu.Item>
    )
}
