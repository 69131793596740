import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { UpdateEventRegistrationForm } from "@/components/events/registrationForm/UpdateEventRegistrationForm";
import { Menu, Text } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";

interface UpdateItemProps {
    eventRegistration: {
        uuid: UUID,
        comment: string | null
    }
}

const MODAL_ID = "update-event-registration";

export function UpdateItem(props: UpdateItemProps) {
    const { eventRegistration } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const onSuccess = () => {
        showSuccessNotification(t("common:Forms.Saved"))
        revalidator.revalidate()
        closeModal(MODAL_ID)
    }

    const onError = () => {
        showErrorNotification({ message: t("common:Forms.Save failed") })
    }

    const onClick = () => {
        openModal({
            modalId: MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.UpdateEventRegistration.Update registration") }</Text>,
            children: <UpdateEventRegistrationForm eventRegistration={ eventRegistration }
                                                   onSuccess={ onSuccess }
                                                   onError={ onError }
                                                   onCancel={ () => closeModal(MODAL_ID) } />,
            closeOnClickOutside: false
        })
    }

    return (
        <Menu.Item onClick={ onClick }>
            { t("components:Events.UpdateEventRegistration.Update registration") }
        </Menu.Item>
    )
}
