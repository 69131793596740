import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Card, List, Text, ThemeIcon } from "@mantine/core";
import { IconClock, IconMapPin, IconUser } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface SpecialEventCardProps {
    event: {
        uuid: UUID
        availableSpots: number | null
        end: DateTime
        location: string | null
        name: string
        start: DateTime
    }
}

export function SpecialEventCard(props: SpecialEventCardProps) {
    const { event } = props

    const { t } = useTranslation()

    function formatSchedule() {
        if (event.start.toISODate() === event.end.toISODate()) {
            return `${ event.start.toFormat("EEE dd.MM.yy HH:mm") } - ${ event.end.toFormat("HH:mm") }`;
        } else {
            return `${ event.start.toFormat("EEE dd.MM.yy HH:mm") } - ${ event.end.toFormat("EEE dd.MM.yy HH:mm") }`;
        }
    }

    return (
        <Card component={ Link } to={ generatePath(Routes.CLASSES_BY_ID, { uuid: event.uuid }) }
              shadow="sm" style={ t => ({ "&:hover": { backgroundColor: t.colors.gray[0] } }) }>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ event.name }</Text>
            </Card.Section>
            <Card.Section inheritPadding py="sm">
                <List center spacing="xs">
                    <List.Item icon={ <ThemeIcon variant="light" size={ 24 }> <IconClock size={ 16 } /> </ThemeIcon> }>
                        { formatSchedule() }
                    </List.Item>

                    {
                        event.location &&
                        <List.Item icon={ <ThemeIcon variant="light" size={ 24 }> <IconMapPin size={ 16 } /> </ThemeIcon> }>
                            { event.location }
                        </List.Item>
                    }

                    {
                        event.availableSpots &&
                        <List.Item icon={ <ThemeIcon variant="light" size={ 24 }> <IconUser size={ 16 } /> </ThemeIcon> }>
                            { t("components:Events.SpecialEventCard.Available Spots", { count: event.availableSpots }) }
                        </List.Item>
                    }

                    {
                        !event.availableSpots &&
                        <List.Item icon={ <ThemeIcon variant="light" size={ 24 }> <IconUser size={ 16 } /> </ThemeIcon> }>
                            { t("components:Events.SpecialEventCard.Unlimited Spots") }
                        </List.Item>
                    }
                </List>
            </Card.Section>
        </Card>
    )
}
