import { FormErrors } from "@mantine/form";
import * as Sentry from "@sentry/react";

function debug(msg: string, ...optionalParams: unknown[]) {
    console.debug(msg, ...optionalParams)
}

function info(msg: string, ...optionalParams: unknown[]) {
    console.log(msg, ...optionalParams)
}

function warn(msg: string, ...optionalParams: unknown[]) {
    console.warn(msg, ...optionalParams)
    Sentry.captureMessage(msg, { level: "warning", extra: { params: optionalParams } })
}

function error(msg: string, ...optionalParams: unknown[]) {
    console.error(msg, ...optionalParams)
}

function exception(msg: string, e: unknown) {
    console.error(msg, e)
    Sentry.captureException(e)
}

function validationErrors<Values>(formName: string, errors: FormErrors, values: Values) {
    console.info("Form validation failed", errors)
    Sentry.captureMessage(`Validation of form "${ formName }" failed.`, { level: "info", extra: { errors: errors, values: values } })
}

const Logger = {
    debug: debug,
    info: info,
    warn: warn,
    error: error,
    exception: exception,
    validationErrors: validationErrors
}

export default Logger
