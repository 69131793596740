import { Cloudinary as UrlGen } from "@cloudinary/url-gen";

export const Cloudinary = new UrlGen({
    cloud: {
        cloudName: "mypowerhour"
    },
    url: {
        secure: true
    }
});
