import { Box } from "@mantine/core";
import { PropsWithChildren } from "react";
import classes from "./Fact.module.scss"

interface FactProps {
    title: string
}

export function Fact(props: PropsWithChildren<FactProps>) {
    const { title, children } = props

    return (
        <Box className={ classes.fact }>
            <div className={ classes.title }>
                { title }
            </div>
            <div className={ classes.description }>
                { children }
            </div>
        </Box>
    )
}
