import { Fact } from "@/components/shared/Fact";
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";


interface RegistrationStartFactProps {
    start: DateTime
    registrationStartPeriod: Duration | null
}

export function RegistrationStartFact(props: RegistrationStartFactProps) {
    const { start, registrationStartPeriod } = props

    const { t } = useTranslation()

    if (!registrationStartPeriod) {
        return null
    }

    const date = start.minus(registrationStartPeriod)

    return (
        <Fact title={ t("pages:ClassesByIdPage.RegistrationStart") }>
            { t("pages:ClassesByIdPage.Registration are possible from DATE", { date: date.toFormat("EEEE dd.MM. HH:mm") }) }
        </Fact>
    )
}
