import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ActionIcon } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Link } from "react-router-dom";


export function UpdateTermsOfServiceButton() {
    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.OrganisationManager)) {
        return null
    }

    return (
        <ActionIcon component={ Link } to={ Routes.ADMIN_TERMS_OF_SERVICE } variant="default" size="lg">
            <IconEdit />
        </ActionIcon>
    )
}
