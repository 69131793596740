import { useAuthentication } from "@/contexts/authentication";
import { Routes } from "@/routing/Routes";

export function useRootLink() {
    const { isAuthenticated } = useAuthentication()

    if (isAuthenticated) {
        return Routes.CLASSES
    } else {
        return Routes.INFO
    }
}
