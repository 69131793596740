import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { UpdateWorkoutMutation, useUpdateWorkoutMutation, WorkoutInput } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { WorkoutForm } from "./WorkoutForm";


interface UpdateWorkoutFormProps {
    uuid: UUID
    initialValues: WorkoutInput

    scoreBoardsWithResult: readonly { uuid: UUID, resultCount: number }[]
    workoutTypes: readonly { uuid: UUID, name: string }[]

    onSuccess: (uuid: UUID) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function UpdateWorkoutForm(props: UpdateWorkoutFormProps) {
    const { uuid, initialValues, scoreBoardsWithResult, workoutTypes, onSuccess, onError, onCancel } = props

    const [ updateWorkout ] = useUpdateWorkoutMutation()

    const onSubmit = async (input: WorkoutInput) => {
        return await executeMutation({
            mutation: () => updateWorkout({ variables: { uuid: uuid, input: input } })
        })
    }

    const handleSuccess = async (data: UpdateWorkoutMutation) => {
        onSuccess(data.updateWorkout.uuid)
    }

    const handleError = async (error: any) => {
        Logger.error("Failed to update Workout", error)
        onError(error)
    }

    return (
        <WorkoutForm initialValues={ initialValues }
                     scoreBoardsWithResultCount={ scoreBoardsWithResult }
                     workoutTypes={ workoutTypes }
                     onSubmit={ onSubmit }
                     onSuccess={ handleSuccess }
                     onError={ handleError }
                     onCancel={ onCancel } />
    )
}
