import { byDateTime } from "@/common/Sort";
import { SpecialEventList } from "@/components/events/special";
import { PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import { EventsFilterInput, EventsPageDataDocument, EventsPageDataQuery, EventsPageDataQueryVariables, EventState } from "@/generated/graphql";
import { ApolloQueryResult } from "@apollo/client";
import { Stack, Title } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

export function EventsPageLoader() {
    const filter: EventsFilterInput = {
        startDate: DateTime.now().minus({ year: 2 }).toISODate(),
        endDate: DateTime.now().plus({ year: 2 }).toISODate(),
        isSpecial: true,
        states: null
    };

    return GraphQLClient.query<EventsPageDataQuery, EventsPageDataQueryVariables>({
        query: EventsPageDataDocument,
        variables: { filter: filter },
    })
}

export function EventsPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<EventsPageDataQuery>

    const { t } = useTranslation()

    const now = DateTime.now()
    const events = queryResult.data.events
        .filter(it => it.state !== EventState.Cancelled)
        .map(it => ({
            uuid: it.uuid,
            availableSpots: it.eventDetails.registrationSettings.availableSpots,
            end: DateTime.fromISO(it.end),
            location: it.eventDetails.location,
            name: it.eventDetails.name,
            start: DateTime.fromISO(it.start)
        }))

    const upcomingEvents = events.filter(it => it.start > now).sort((a, b) => byDateTime(a.start, b.start))
    const pastEvents = events.filter(it => it.end < now).sort((a, b) => byDateTime(b.start, a.start))

    return (
        <>
            <MetaTitle title={ t("pages:EventsPage.Title") } />

            <Stack>
                <PageTitle title={ t("pages:EventsPage.Title") } />

                <Title order={ 3 }>{ t("pages:EventsPage.Upcoming Events") }</Title>
                <SpecialEventList events={ upcomingEvents } />

                <Title order={ 3 }>{ t("pages:EventsPage.Past Events") }</Title>
                <SpecialEventList events={ pastEvents } />
            </Stack>
        </>
    )
}




