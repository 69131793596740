import { ContentCard } from "@/components/shared";
import { Button, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface CancellationCardProps {
    isLateCancellation: boolean
    onCancel: () => void
}

export function CancelRegistrationCard(props: CancellationCardProps) {
    const { isLateCancellation, onCancel } = props

    const { t } = useTranslation()

    let text: string | null = null
    if (isLateCancellation) {
        text = t("components:Events.CancelRegistration.You can cancel your registration late.")
    }

    return (
        <ContentCard>
            <Stack>
                { text && <Text>{ text }</Text> }
                <Button onClick={ onCancel } variant="outline">
                    { isLateCancellation ? t("common:Button.Cancel Registration Late") : t("common:Button.Cancel Registration") }
                </Button>
            </Stack>
        </ContentCard>
    )
}
