import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { SignInErrorReason } from "@/components/authentication";
import { useSession } from "@/contexts/session";
import { Routes } from "@/routing/Routes"
import { Anchor, Button, Group, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


interface SignInFormData {
    emailAddress: string
    password: string
}

interface SignInFormProps {
    autoFocus?: boolean
    onSuccess?: () => void
}

export function SignInForm(props: SignInFormProps) {
    const { autoFocus, onSuccess } = props

    const { t } = useTranslation()

    const { signIn } = useSession()

    const form = useMyPowerHourForm<SignInFormData>({
        name: "SignInForm",
        initialValues: {
            emailAddress: "",
            password: ""
        },
    })

    const handleSubmit = async (data: SignInFormData) => {
        const response = await signIn(data)

        if (response.isSuccess) {
            onSuccess?.()
            return
        }

        switch (response.errorReason) {
            case SignInErrorReason.UNCONFIRMED:
                showErrorNotification({ message: t("components:Account.SignInForm.Account unconfirmed") })
                break;
            case SignInErrorReason.FAILED:
                showErrorNotification({ message: t("components:Account.SignInForm.Sign in failed") })
                break;
            case SignInErrorReason.FETCH_ERROR:
                showErrorNotification({ message: t("common:Error.Something went wrong") })
                break;
            default:
                return response.errorReason satisfies never;
        }
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <TextInput label={ t("components:Account.Common.EmailAddress") }
                           name="emailAddress"
                           autoComplete="email"
                           type="email"
                           autoFocus={ autoFocus }
                           required
                           { ...form.getInputProps("emailAddress") } />

                <PasswordInput label={ t("components:Account.Common.Password") }
                               name="password"
                               autoComplete="password"
                               required
                               { ...form.getInputProps("password") } />

                <Group justify="space-between">
                    <Anchor component={ Link } to={ Routes.RESET_PASSWORD } size="sm">
                        { t("components:Account.SignInForm.Reset password?") }
                    </Anchor>
                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.SignIn") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
