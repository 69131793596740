import { GraphQLClient } from "@/contexts/graphql";
import {
    ActiveMemberCountDocument,
    ActiveMemberCountQuery,
    ActiveMemberCountQueryVariables,
    MemberPermission,
    MembershipRequestCountDocument,
    MembershipRequestCountQuery,
    MembershipRequestCountQueryVariables,
} from "@/generated/graphql";
import { AdministrationNavbar } from "@/layout/administration/AdministrationNavbar";
import RoutingStorage from "@/routing/RoutingStorage";
import { ApolloQueryResult } from "@apollo/client";
import { Container } from "@mantine/core";
import { Suspense } from "react";
import { Outlet, useLoaderData } from "react-router-dom";


interface AdministrationLayoutLoaderData {
    activeMemberCount: ApolloQueryResult<ActiveMemberCountQuery> | null
    MembershipRequestCount: ApolloQueryResult<MembershipRequestCountQuery> | null
}

export async function AdministrationLayoutLoader(): Promise<AdministrationLayoutLoaderData> {
    let MembershipRequestCount = null
    if (RoutingStorage.hasPermission(MemberPermission.MembershipAgreementManager)) {
        MembershipRequestCount = await GraphQLClient.query<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>({
            query: MembershipRequestCountDocument,
        })
    }

    let activeMemberCount = null
    if (RoutingStorage.hasPermission(MemberPermission.MemberManager)) {
        activeMemberCount = await GraphQLClient.query<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>({
            query: ActiveMemberCountDocument,
        })
    }

    return {
        activeMemberCount: activeMemberCount,
        MembershipRequestCount: MembershipRequestCount
    }
}

export function AdministrationLayout() {
    const loaderData = useLoaderData() as AdministrationLayoutLoaderData

    const data = {
        activeMemberCount: loaderData.activeMemberCount?.data.activeMemberCount ?? null,
        membershipRequestCount: loaderData.MembershipRequestCount?.data.membershipRequestCount ?? null,
    }

    return (
        <>
            <AdministrationNavbar data={ data } />

            <Container mt="md">
                <Suspense>
                    <Outlet />
                </Suspense>
            </Container>
        </>
    )
}
