import { Fact } from "@/components/shared/Fact";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface DescriptionFactProps {
    description: string | null
}

export function DescriptionFact(props: DescriptionFactProps) {
    const { description } = props

    const { t } = useTranslation()

    if (!description) {
        return null
    }

    return (
        <Fact title={ t("pages:ClassesByIdPage.Description") }>
            <Text fw={ 600 }>
                { description }
            </Text>
        </Fact>
    )
}
