import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { usePublishMembershipAgreementTemplateMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconEye } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";

interface PublishMembershipTemplateItemProps {
    membershipTemplate: {
        uuid: UUID
    }
}

export function PublishMembershipTemplateItem(props: PublishMembershipTemplateItemProps) {
    const { membershipTemplate } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const [ publishTemplate ] = usePublishMembershipAgreementTemplateMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("pages:MembershipsByIdPage.Publish Template") }</Text>,
            children: t("pages:MembershipsByIdPage.Do you want to publish this Template"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Publish") },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => publishTemplate({ variables: { uuid: membershipTemplate.uuid } }),
                    onSuccess: () => {
                        showSuccessNotification(t("pages:MembershipsByIdPage.Template published"))
                        revalidator.revalidate()
                    },
                    onError: (error) => {
                        Logger.error("Failed to publish Template", error)
                        showErrorNotification({ message: t("pages:MembershipsByIdPage.Failed to publish Template") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } leftSection={ <IconEye size={ 18 } /> }>
            { t("pages:MembershipsByIdPage.Publish Template") }
        </Menu.Item>
    )
}
