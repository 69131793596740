import { toDuration } from "@/common/Luxon";
import { byStartAndName } from "@/common/Sort";
import { UUID } from "@/common/Types";
import { RegistrationLogContainer } from "@/components/events/registrationLog/RegistrationLogContainer";
import { SwitchEventCardContainer } from "@/components/events/switch";
import { SwitchEventCardContainerProps } from "@/components/events/switch/SwitchEventCardContainer"
import { WorkoutsCard, WorkoutsCardProps } from "@/components/events/workoutsCard/WorkoutsCard"
import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate";
import { PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useDeviceSize } from "@/contexts/device-size";
import {
    ClassByIdPageMemberQuery,
    EventRegistrationDenyReason,
    EventsForSwitchQuery,
    EventState,
    IsEventRegistrationAllowedResponse,
    IsEventRegistrationCancellationAllowedResponse,
    RegistrationState,
    WorkoutsQuery
} from "@/generated/graphql";
import { CancelRegistrationCardContainer } from "@/pages/classes/[uuid]/_components/cancelRegistrationCard/CancelRegistrationCardContainer";
import { CreateRegistrationCardContainer } from "@/pages/classes/[uuid]/_components/createRegistrationCard/CreateRegistrationCardContainer";
import { DetailsCard, DetailsCardProps } from "@/pages/classes/[uuid]/_components/detailsCard/DetailsCard";
import { EventMenu } from "@/pages/classes/[uuid]/_components/eventMenu/EventMenu";
import { EventRegistrationListContainer, EventRegistrationListContainerProps } from "@/pages/classes/[uuid]/_components/eventRegistrationList/EventRegistrationListContainer";
import { Routes } from "@/routing/Routes";
import { Grid, Group, Stack } from "@mantine/core";
import { DateTime } from "luxon";
import React from "react";


interface ClassByIdPageMemberProps {
    pageQuery: ClassByIdPageMemberQuery
    eventsForSwitchQuery: EventsForSwitchQuery | null
    workoutsQuery: WorkoutsQuery
}

export function ClassByIdPageMember(props: ClassByIdPageMemberProps) {
    const queryResult = props

    const { navigateBack } = useMyPowerHourNavigate()
    const { isMobile } = useDeviceSize()

    const eventData = queryResult.pageQuery.event
    const eventForSwitchData = queryResult.eventsForSwitchQuery?.eventsForSwitch
    const workouts = queryResult.workoutsQuery.workouts.results.map(it => (
        {
            uuid: it.uuid,
            name: it.workoutType?.name ?? it.title ?? "Workout",
        }
    ))

    const event: DetailsCardProps["event"] = {
        eventUUID: eventData.uuid,
        eventDetailsUUID: eventData.eventDetails.uuid,

        end: DateTime.fromISO(eventData.end),
        description: eventData.eventDetails.description,
        name: eventData.eventDetails.name,
        start: DateTime.fromISO(eventData.start),
        state: eventData.state,

        feeAmount: eventData.eventDetails.registrationSettings.feeAmount,

        availableSpots: eventData.eventDetails.registrationSettings.availableSpots,

        minimumNumberOfRegistrations: eventData.eventDetails.registrationSettings.minimumNumberOfRegistrations,
        minimumNumberOfRegistrationsPeriod: toDuration(eventData.eventDetails.registrationSettings.minimumNumberOfRegistrationsPeriod),

        lateCancellationPeriod: toDuration(eventData.eventDetails.registrationSettings.lateCancellationPeriod),

        registrationStartPeriod: toDuration(eventData.eventDetails.registrationSettings.registrationStartPeriod)
    }

    const eventRegistrations = eventData.registrations
        ?.map(it => ({
                uuid: it.uuid,
                comment: it.comment,
                isPenalty: it.state === RegistrationState.WaitListPenalty,
                isVisitor: !!it.visitor,
            memberUUID: it.member?.uuid ?? null,
            name: it.member?.person.username ?? it.visitor?.name ?? "",
                registrationDate: it.registrationDate,
                state: it.state
            })
        )
        ?.sort((a, b) => a.registrationDate.localeCompare(b.registrationDate)) ?? []

    const eventsForSwitch = eventForSwitchData
        ?.map(it => ({
                uuid: it.uuid,
                color: it.eventDetails.color,
                end: DateTime.fromISO(it.end),
                name: it.eventDetails.name,
                start: DateTime.fromISO(it.start),
            })
        )
        ?.sort(byStartAndName) ?? []

    const myRegistration = queryResult.pageQuery.myEventRegistrations.results.firstOrNull()
    const isRegistrationAllowed = queryResult.pageQuery.isEventRegistrationAllowed
    const isCancellationAllowed = queryResult.pageQuery.isEventRegistrationCancellationAllowed

    let content: React.ReactNode
    if (!isMobile
        && [ EventState.Pending, EventState.Confirmed, EventState.Started ].includes(event.state)
        && isRegistrationAllowed.denyReason !== EventRegistrationDenyReason.EventNotRegistrable
    ) {
        content = <ClassByIdPageColumned event={ event }
                                         eventRegistrations={ eventRegistrations }
                                         eventsForSwitch={ eventsForSwitch }
                                         isRegistrationAllowed={ isRegistrationAllowed }
                                         isCancellationAllowed={ isCancellationAllowed }
                                         myRegistration={ myRegistration }
                                         workouts={ workouts } />
    } else {
        content = <ClassByIdPageStacked event={ event }
                                        eventRegistrations={ eventRegistrations }
                                        eventsForSwitch={ eventsForSwitch }
                                        isRegistrationAllowed={ isRegistrationAllowed }
                                        isCancellationAllowed={ isCancellationAllowed }
                                        myRegistration={ myRegistration }
                                        workouts={ workouts } />
    }

    const onEventDeleted = () => {
        navigateBack(Routes.CLASSES)
    }

    return (
        <>
            <MetaTitle title={ event.name } />

            <Stack>
                <Group justify="space-between" wrap="nowrap">
                    <PageTitleWithBack title={ event.name } />

                    <EventMenu event={ event } onEventDeleted={ onEventDeleted } />
                </Group>

                { content }
            </Stack>
        </>
    )
}

interface ClassByIdPageColumnedProps {
    event: DetailsCardProps["event"]
    eventRegistrations: EventRegistrationListContainerProps["eventRegistrations"]
    eventsForSwitch: SwitchEventCardContainerProps["events"]
    isRegistrationAllowed: IsEventRegistrationAllowedResponse
    isCancellationAllowed: IsEventRegistrationCancellationAllowedResponse
    myRegistration: { uuid: UUID } | null
    workouts: WorkoutsCardProps["workouts"]
}

function ClassByIdPageColumned(props: ClassByIdPageColumnedProps) {
    const { event, eventRegistrations, eventsForSwitch, isCancellationAllowed, isRegistrationAllowed, myRegistration, workouts } = props;

    return (
        <Grid>
            <Grid.Col span={ 8 }>
                <Stack>
                    <DetailsCard event={ event } enableCollapse={ false } />

                    { event.availableSpots !== null && (
                        <>
                            <EventRegistrationListContainer availableSpots={ event.availableSpots }
                                                            eventRegistrations={ eventRegistrations } />

                            <RegistrationLogContainer eventId={ event.eventUUID } />
                        </>
                    ) }
                </Stack>
            </Grid.Col>
            <Grid.Col span={ 4 }>
                <Stack>
                    <CancelRegistrationCardContainer event={ event }
                                                     myRegistration={ myRegistration }
                                                     isCancellationAllowed={ isCancellationAllowed } />

                    <CreateRegistrationCardContainer event={ event }
                                                     isRegistrationAllowed={ isRegistrationAllowed } />

                    <SwitchEventCardContainer myRegistration={ myRegistration }
                                              events={ eventsForSwitch } />

                    <WorkoutsCard workouts={ workouts } />
                </Stack>
            </Grid.Col>
        </Grid>
    )
}

interface ClassByIdPageStackedProps {
    event: DetailsCardProps["event"]
    eventRegistrations: EventRegistrationListContainerProps["eventRegistrations"]
    eventsForSwitch: SwitchEventCardContainerProps["events"]
    isRegistrationAllowed: IsEventRegistrationAllowedResponse
    isCancellationAllowed: IsEventRegistrationCancellationAllowedResponse
    myRegistration: { uuid: UUID } | null
    workouts: WorkoutsCardProps["workouts"]
}

function ClassByIdPageStacked(props: ClassByIdPageStackedProps) {
    const { event, eventRegistrations, eventsForSwitch, isCancellationAllowed, isRegistrationAllowed, myRegistration, workouts } = props;

    return (
        <Stack>
            <DetailsCard event={ event } enableCollapse={ myRegistration !== null } />

            <CancelRegistrationCardContainer event={ event }
                                             myRegistration={ myRegistration }
                                             isCancellationAllowed={ isCancellationAllowed } />

            <CreateRegistrationCardContainer event={ event }
                                             isRegistrationAllowed={ isRegistrationAllowed } />

            <SwitchEventCardContainer myRegistration={ myRegistration }
                                      events={ eventsForSwitch } />

            <WorkoutsCard workouts={ workouts } />

            { event.availableSpots !== null && (
                <>
                    <EventRegistrationListContainer availableSpots={ event.availableSpots }
                                                    eventRegistrations={ eventRegistrations } />

                    <RegistrationLogContainer eventId={ event.eventUUID } />
                </>
            ) }
        </Stack>
    )
}
