import { Fact } from "@/components/shared/Fact";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";


interface DateFactProps {
    start: DateTime
    end: DateTime
}

export function DateFact(props: DateFactProps) {
    const { end, start } = props

    const { t } = useTranslation()

    return (
        <Fact title={ t("pages:ClassesByIdPage.Date") }>
            { start.toFormat("EEEE, dd. MMMM HH:mm") } - { end.toFormat("HH:mm") }
        </Fact>
    )
}
