import Logger from "@/common/Logger";
import { showErrorNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { ContentCard } from "@/components/shared";
import { MembershipAgreementState, useRevokeMembershipAgreementTerminationRequestMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Box, Button, Card, Grid, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";

interface RevokeTerminationCardProps {
    membership: {
        uuid: UUID
        state: MembershipAgreementState
    }
    onTerminationRevoked: () => void
}

export function RevokeTerminationCard(props: RevokeTerminationCardProps) {
    const { membership } = props

    const { t } = useTranslation()

    if (membership.state !== MembershipAgreementState.TerminationRequested) {
        return null
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Memberships.RevokeTerminationCard.Termination") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <RevokeTerminationCardSection { ...props } />
            </Card.Section>
        </ContentCard>
    )
}

function RevokeTerminationCardSection(props: RevokeTerminationCardProps) {
    const { membership, onTerminationRevoked } = props

    const { t } = useTranslation()

    const [ revokeTermination ] = useRevokeMembershipAgreementTerminationRequestMutation()

    const onRevoke = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Memberships.RevokeTerminationCard.Revoke termination") }</Text>,
            children: t("components:Memberships.RevokeTerminationCard.Do you want to revoke the termination?"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Revoke") },
            confirmProps: { color: "orange" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => revokeTermination({ variables: { uuid: membership.uuid } }),
                    onSuccess: onTerminationRevoked,
                    onError: (error) => {
                        Logger.error("Failed to revoke termination of MembershipAgreement", error)
                        showErrorNotification({ error })
                    }
                })
            }
        })
    }

    return (
        <Grid>
            <Grid.Col span={ { base: 12, sm: "auto" } }>
                <Text>
                    { t("components:Memberships.RevokeTerminationCard.You can revoke your termination") }
                </Text>
            </Grid.Col>
            <Grid.Col span={ { base: 12, sm: "content" } }>
                <Box style={ { display: "flex", alignItems: "center", justifyContent: "end", height: "100%" } }>
                    <Button fullWidth color="orange" onClick={ onRevoke }>{ t("components:Memberships.RevokeTerminationCard.Revoke termination") }</Button>
                </Box>
            </Grid.Col>
        </Grid>
    )
}
