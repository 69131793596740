import { useIcalQueryQuery } from "@/generated/graphql";
import { ActionIcon, Flex, Stack, Text, TextInput } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { openModal } from "@mantine/modals";
import { IconCalendarShare, IconCheck, IconClipboard } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


const ICAL_LINK_MODAL_ID = "ical-link"

export function IcalLinkButton() {
    const { t } = useTranslation()

    const { data } = useIcalQueryQuery()
    const onOpenIcalLink = () => {
        openModal({
            modalId: ICAL_LINK_MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.IcalLinkButton.Title") }</Text>,
            children: (
                <Stack>
                    <Text>{ t("components:Events.IcalLinkButton.Description") }</Text>

                    <details>
                        <summary>
                            <Text component="span" size="sm" fw={ 500 }>{ t("components:Events.IcalLinkButton.Hint") }</Text>
                        </summary>
                        <Text size="sm" style={ { textAlign: "justify" } }>{ t("components:Events.IcalLinkButton.HintText") }</Text>
                    </details>

                    <Flex gap="md">
                        <TextInput value={ data?.ical.link } onClick={ e => e.currentTarget.select() } w="100%" readOnly
                                   rightSection={ <CopyIcon data={ data?.ical.link } /> }
                        />
                    </Flex>
                </Stack>
            ),
        })
    }

    return (
        <ActionIcon variant="default" onClick={ onOpenIcalLink } aria-label="iCal link">
            <IconCalendarShare />
        </ActionIcon>
    )
}

interface CopyIconProps {
    data?: string
}

function CopyIcon(props: CopyIconProps) {
    const { data } = props

    const clipboard = useClipboard();

    const onCopyLink = () => {
        clipboard.copy(data);
    }

    if (clipboard.copied) {
        return (
            <ActionIcon color="brand" variant="light" onClick={ onCopyLink }>
                <IconCheck />
            </ActionIcon>
        )
    }

    return (
        <ActionIcon color="brand" variant="light" onClick={ onCopyLink }>
            <IconClipboard size={ 22 } />
        </ActionIcon>
    )
}
