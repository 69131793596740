import { MembershipAgreementState } from "@/generated/graphql";
import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface MembershipStateBadgeProps {
    membership: {
        state: MembershipAgreementState
    }
}

export function MembershipStateBadge(props: MembershipStateBadgeProps) {
    const { membership } = props

    const { t } = useTranslation()

    switch (membership.state) {
        case MembershipAgreementState.Active:
            return <Badge color="green" variant="filled">{ t(`entities:MembershipAgreementState.${ membership.state }`) }</Badge>
        case MembershipAgreementState.Followed:
            return <Badge color="blue" variant="filled">{ t(`entities:MembershipAgreementState.${ membership.state }`) }</Badge>
        case MembershipAgreementState.Suspended:
            return <Badge color="blue" variant="filled">{ t(`entities:MembershipAgreementState.${ membership.state }`) }</Badge>
        case MembershipAgreementState.Terminated:
            return <Badge color="red" variant="filled">{ t(`entities:MembershipAgreementState.${ membership.state }`) }</Badge>
        case MembershipAgreementState.TerminationForced:
            return <Badge color="orange" variant="filled">{ t(`entities:MembershipAgreementState.${ membership.state }`) }</Badge>
        case MembershipAgreementState.TerminationRequested:
            return <Badge color="orange" variant="filled">{ t(`entities:MembershipAgreementState.${ membership.state }`) }</Badge>
    }
}
