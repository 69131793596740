import { MantineColor, Text, UnstyledButton } from "@mantine/core";
import cx from "clsx";
import { Link } from "react-router-dom";
import classes from "./ColoredDashboardTile.module.scss"


interface ColoredDashboardTileProps {
    color: MantineColor
    number: number
    text: string
    to: string
}

export function ColoredDashboardTile(props: ColoredDashboardTileProps) {
    const { color, number, text, to } = props

    let colorClass: string | undefined
    switch (color) {
        case "blue":
            colorClass = classes.blue
            break;
        case "green":
            colorClass = classes.green
            break;
        default:
            colorClass = undefined
            break;
    }

    return (
        <UnstyledButton component={ Link } to={ to } className={ cx("mantine-active", classes.link) }>
            <div className={ cx(classes.paper, colorClass) }>
                <Text className={ classes.number }>{ number }</Text>
                <Text className={ classes.text }>{ text }</Text>
            </div>
        </UnstyledButton>
    )
}
