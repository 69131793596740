import Logger from "@/common/Logger";
import { ContentCard } from "@/components/shared";
import { EventRegistrationDenyReason } from "@/generated/graphql";
import { Button, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface CreateRegistrationNotAllowedCardProps {
    denyReason: EventRegistrationDenyReason | null
}

export function CreateRegistrationNotAllowedCard(props: CreateRegistrationNotAllowedCardProps) {
    const { denyReason } = props

    const { t } = useTranslation()

    let text: string | null = null
    switch (denyReason) {
        case EventRegistrationDenyReason.HasActiveAgreementNoCredits:
            text = t(`components:Events.CreateRegistration.Not enough credits.`)
            break;
        case EventRegistrationDenyReason.RegistrationPeriodNotStarted:
            text = t(`components:Events.CreateRegistration.Registration period not started.`)
            break;
        case EventRegistrationDenyReason.MemberInactive:
        case EventRegistrationDenyReason.NoActiveAgreement:
            text = t(`components:Events.CreateRegistration.No active Membership.`)
            break;
        case EventRegistrationDenyReason.NotConfirmedAfterLateCancellation:
        case EventRegistrationDenyReason.EventNotRegistrable:
            text = t(`components:Events.CreateRegistration.Registration not allowed.`)
            break;
        case EventRegistrationDenyReason.IsRegistered:
            Logger.warn("RegistrationNotAllowed rendered while already registered")
            break;
    }

    return (
        <ContentCard>
            <Stack>
                { text && <Text>{ text }</Text> }

                <Button disabled>
                    { t("common:Button.Create Registration") }
                </Button>
            </Stack>
        </ContentCard>
    )
}
