import { ContentCard, MMarkdown, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import { useOrganisation } from "@/contexts/organisation";
import { DataProtectionPageDocument, DataProtectionPageQuery, DataProtectionPageQueryVariables } from "@/generated/graphql";
import { UpdateDataProtectionButton } from "@/pages/info/_components/UpdateDataProtectionButton";
import { ApolloQueryResult } from "@apollo/client";
import { Alert, Group, Stack } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";


export function DataProtectionPageLoader() {
    return GraphQLClient.query<DataProtectionPageQuery, DataProtectionPageQueryVariables>({
        query: DataProtectionPageDocument,
    })
}

export function DataProtectionPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<DataProtectionPageQuery>

    const { t } = useTranslation()
    const { organisation } = useOrganisation()

    return (
        <>
            <MetaTitle title={ t("pages:DataProtectionPage.Title") } />

            <Stack>
                <Group justify="space-between">
                    <PageTitleWithBack title={ t("pages:DataProtectionPage.Title") } />

                    <UpdateDataProtectionButton />
                </Group>

                <ContentCard>
                    <Alert icon={ <IconInfoCircle /> }>
                        { t("pages:DataProtectionPage.These are the data protection of Organisation", { name: organisation.name }) }
                    </Alert>

                    <MMarkdown text={ queryResult.data.organisation.dataProtectionHtml ?? "" } textAlign="justify" />
                </ContentCard>
            </Stack>
        </>
    )
}
