import { BenchmarkResultType, WorkoutResultType } from "@/generated/graphql";
import { Duration } from "luxon";

function decodeScore(resultType: BenchmarkResultType | WorkoutResultType, score: string) {
    if (resultType === BenchmarkResultType.Duration || resultType === WorkoutResultType.Duration) {
        return Duration.fromISO(score).shiftTo("minutes", "second").toFormat("mm:ss")
    } else {
        return score
    }
}

const W = {
    decodeScore: decodeScore
}

export default W
