/**
 * SignIn
 */

export interface ConfirmationInput {
    code: string
}

export interface ConfirmationSuccessResponseDTO {
    isSuccess: true
    expiresIn: string
    expiresAt: string
}

export interface ConfirmationErrorResponseDTO {
    isSuccess: false
    errorReason: ConfirmationErrorReason
}

export enum ConfirmationErrorReason {
    EXPIRED = "EXPIRED",
    NOT_FOUND = "NOT_FOUND",
    FETCH_ERROR = "FETCH_ERROR"
}

export type ConfirmationResponseDTO = ConfirmationSuccessResponseDTO | ConfirmationErrorResponseDTO


/**
 * SignIn
 */

export interface SignInInput {
    emailAddress: string
    password: string
}

export interface SignInSuccessResponseDTO {
    isSuccess: true
    expiresIn: string
    expiresAt: string
}

export interface SignInErrorResponseDTO {
    isSuccess: false
    errorReason: SignInErrorReason
}

export enum SignInErrorReason {
    UNCONFIRMED = "UNCONFIRMED",
    FAILED = "FAILED",
    FETCH_ERROR = "FETCH_ERROR"
}

export type SignInResponseDTO = SignInSuccessResponseDTO | SignInErrorResponseDTO

/**
 * Touch
 */

export interface TouchSuccessResponseDTO {
    isSuccess: true
    expiresIn: string
    expiresAt: string
}

export interface TouchErrorResponseDTO {
    isSuccess: false
    errorReason: TouchErrorReason
}

export enum TouchErrorReason {
    INVALID = "INVALID",
    FETCH_ERROR = "FETCH_ERROR"
}

export type TouchResponseDTO = TouchSuccessResponseDTO | TouchErrorResponseDTO
