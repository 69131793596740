import { MemberPermission } from "@/generated/graphql"
import { Routes } from "@/routing/Routes";
import {
    Icon123,
    IconBarbell,
    IconCalendarEvent,
    IconInfoCircle,
    IconLayoutDashboard,
    IconLicense,
    IconLogin,
    IconLogout,
    IconMessage,
    IconSettings,
    IconStar,
    IconUserCircle,
    IconUserPlus
} from "@tabler/icons-react";
import { SharedNavigation } from "./Types";


interface BuildNavigationItemsProps {
    hasPermission: (permission: MemberPermission) => boolean,
}

export function buildNavigationConfiguration(props: BuildNavigationItemsProps): SharedNavigation {
    const { hasPermission } = props

    const canSeeAdmin = hasPermission(MemberPermission.OrganisationManager)
        || hasPermission(MemberPermission.MembershipAgreementManager)
        || hasPermission(MemberPermission.MemberManager)

    return {
        sets: {
            bottomNavbar: {
                anonymous: [ INFO, CLASSES, MEMBERSHIPS, EVENTS, WORKOUTS ],
                authenticated: [ CLASSES, WORKOUTS, MY, INFO, EVENTS, MEMBERSHIPS, ...(canSeeAdmin ? [ ADMIN ] : []) ],
            },
            organisation: {
                navbar: [ INFO, CLASSES, EVENTS, MEMBERSHIPS, WORKOUTS, ...(canSeeAdmin ? [ ADMIN ] : []) ],
                navigation: [ INFO, CLASSES, EVENTS, MEMBERSHIPS, WORKOUTS, ...(canSeeAdmin ? [ ADMIN ] : []) ],
            },
            my: {
                dashboard: [ MY_BENCHMARKS, MY_MEMBERSHIPS, MY_ACCOUNT, MY_NOTIFICATION_SETTINGS ],
                navbar: [ MY_DESKTOP, MY_ACCOUNT, MY_BENCHMARKS, MY_MEMBERSHIPS, MY_NOTIFICATION_SETTINGS ],
            }
        },
        items: {
            login: LOGIN,
            logout: LOGOUT,
            register: REGISTER
        }
    }
}

const LOGIN = {
    icon: IconLogin,
    labelKey: "common:Navigation.Login",
    to: Routes.SIGN_IN
};
const LOGOUT = {
    icon: IconLogout,
    labelKey: "common:Navigation.Logout",
    to: Routes.SIGN_OUT
};
const REGISTER = {
    icon: IconUserPlus,
    labelKey: "common:Navigation.Register",
    to: Routes.SIGN_UP
};

const INFO = {
    icon: IconInfoCircle,
    labelKey: "common:Navigation.Info",
    to: Routes.INFO,
    end: false
};
const MEMBERSHIPS = {
    icon: IconLicense,
    labelKey: "common:Navigation.Memberships",
    to: Routes.MEMBERSHIPS,
    end: false
};
const CLASSES = {
    icon: IconCalendarEvent,
    labelKey: "common:Navigation.Classes",
    to: Routes.CLASSES,
    end: false
};
const EVENTS = {
    icon: IconStar,
    labelKey: "common:Navigation.Events",
    to: Routes.EVENTS,
    end: false
};
const WORKOUTS = {
    icon: IconBarbell,
    labelKey: "common:Navigation.Workouts",
    to: Routes.WORKOUTS,
    end: false
};
const ADMIN = {
    icon: IconSettings,
    labelKey: "common:Navigation.Admin",
    to: Routes.ADMIN,
    end: false
}

const MY = {
    icon: IconUserCircle,
    labelKey: "common:Navigation.My.Profile",
    to: Routes.MY,
    end: false
};

const MY_DESKTOP = {
    icon: IconLayoutDashboard,
    labelKey: "common:Navigation.My.Dashboard",
    to: Routes.MY,
    end: true
};
const MY_BENCHMARKS = {
    icon: Icon123,
    labelKey: "common:Navigation.My.Benchmarks",
    to: Routes.MY_BENCHMARKS,
    end: false
};
const MY_MEMBERSHIPS = {
    icon: IconLicense,
    labelKey: "common:Navigation.My.Memberships",
    to: Routes.MY_MEMBERSHIPS,
    end: false
};
const MY_ACCOUNT = {
    icon: IconUserCircle,
    labelKey: "common:Navigation.My.Account",
    to: Routes.MY_ACCOUNT,
    end: false
};
const MY_NOTIFICATION_SETTINGS = {
    icon: IconMessage,
    labelKey: "common:Navigation.My.Notification Settings",
    to: Routes.MY_NOTIFICATION_SETTINGS,
    end: false
};
