import Parse from "@/common/Parsing";
import { byName } from "@/common/Sort";
import { PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import { MyBenchmarksDocument, MyBenchmarksQuery, MyBenchmarksQueryVariables, PaginationInput } from "@/generated/graphql";
import { BenchmarkList } from "@/pages/my/benchmarks/_components/BenchmarkList";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { ActionIcon, Group, Stack } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link, LoaderFunctionArgs, useLoaderData } from "react-router-dom";


const LIMIT = 999
const SP_PAGE = "page";

export function MyBenchmarksPageLoader({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const page = Parse.intOrDefault(1, url.searchParams.get(SP_PAGE))

    const pagination: PaginationInput = {
        limit: LIMIT,
        offset: LIMIT * (page - 1)
    }

    return GraphQLClient.query<MyBenchmarksQuery, MyBenchmarksQueryVariables>({
        query: MyBenchmarksDocument,
        variables: { filter: { resultType: null }, pagination: pagination },
    })
}

export function MyBenchmarksPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<MyBenchmarksQuery>

    const { t } = useTranslation()

    const benchmarks = queryResult.data.myBenchmarks.results.map(it => it).sort(byName)

    return (
        <>
            <MetaTitle title={ t("pages:MyBenchmarksPage.Title") } />

            <Stack>
                <Group justify="space-between">
                    <PageTitleWithBack title={ t("pages:MyBenchmarksPage.Title") } />

                    <ActionIcon component={ Link } to={ Routes.MY_BENCHMARKS_CREATE } color="brand" variant="filled" size="lg">
                        <IconPlus />
                    </ActionIcon>
                </Group>

                <BenchmarkList benchmarks={ benchmarks } />
            </Stack>
        </>
    )
}
