import { showSuccessNotification } from "@/common/Notification"
import { CreatePasswordResetForm } from "@/components/account";
import { ContentCard } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle"
import { useSession } from "@/contexts/session";
import { Routes } from "@/routing/Routes"
import { Box, Center, Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

export function CreatePasswordResetPage() {
    const { t } = useTranslation()

    const { isSignedIn } = useSession()

    if (isSignedIn) {
        return <Navigate to={ Routes.MY } replace={ true } />
    }

    const onSuccess = () => {
        showSuccessNotification(t("pages:CreatePasswordResetPage.Email sent"), 5000)
    }

    return (
        <>
            <MetaTitle title={ t("pages:CreatePasswordResetPage.Title") } />

            <Center>
                <Box w={ 600 }>
                    <Stack>
                        <Title order={ 2 } ta="center">
                            { t("pages:CreatePasswordResetPage.Title") }
                        </Title>

                        <ContentCard>
                            <CreatePasswordResetForm onSuccess={ onSuccess } />
                        </ContentCard>
                    </Stack>
                </Box>
            </Center>
        </>
    );
}
