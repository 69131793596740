import Logger from "@/common/Logger"
import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { UUID } from "@/common/Types"
import { passwordValidator } from "@/components/account/Validation"
import { useResetPasswordMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Button, Group, PasswordInput, Stack } from "@mantine/core";
import { GraphQLFormattedError } from "graphql";
import { useTranslation } from "react-i18next";


enum ErrorReason {
    NOT_FOUND = "NOT_FOUND",
    INVALID_PASSWORD = "INVALID_PASSWORD",
    EXPIRED = "EXPIRED"
}

interface ResetPasswordFormProps {
    uuid: UUID
    onSuccess?: () => void
    onError?: () => void
}

interface ResetPasswordFormData {
    newPassword: string
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
    const { uuid, onSuccess, onError } = props

    const { t } = useTranslation()

    const [ resetPassword ] = useResetPasswordMutation()

    const form = useMyPowerHourForm<ResetPasswordFormData>({
        name: "ResetPasswordForm",
        initialValues: {
            newPassword: ""
        },
        validate: {
            newPassword: passwordValidator(t(`components:Account.Common.Password doesn't match guidelines`))
        }
    })

    const handleError = (errors: readonly GraphQLFormattedError[] | string) => {
        if (!(errors instanceof Array) || errors.length < 1) {
            Logger.error("Failed to reset password", errors)
            showErrorNotification({ message: t("common:Error.Something went wrong") })
            return
        }

        const errorReason = errors[0]?.extensions?.reason as ErrorReason | undefined;
        switch (errorReason) {
            case undefined:
                Logger.error("Failed to reset password", errors)
                showErrorNotification({ message: t("common:Error.Something went wrong") })
                break;
            case ErrorReason.NOT_FOUND:
            case ErrorReason.EXPIRED:
                onError?.()
                break;
            case ErrorReason.INVALID_PASSWORD:
                form.setFieldError("newPassword", t(`components:Account.Common.Password doesn't match guidelines`))
                break;
            default:
                return errorReason satisfies never;
        }
    }

    const handleSubmit = async (data: ResetPasswordFormData) => {
        const input = {
            uuid: uuid,
            newPassword: data.newPassword
        }

        await executeMutation({
            mutation: () => resetPassword({ variables: { input: input } }),
            onSuccess: onSuccess,
            onError: handleError
        })
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <PasswordInput label={ t("components:Account.Common.New password") }
                               description={ t("components:Account.Common.Password description") }
                               name="newPassword"
                               autoComplete="password"
                               required
                               { ...form.getInputProps("newPassword") } />

                <Group justify="flex-end">
                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
