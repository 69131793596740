import { ContentCard, MMarkdown } from "@/components/shared";
import { UpdateOrganisationInfoButton } from "@/pages/info/_components/UpdateOrganisationInfoButton";
import { Card, Center, Group, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface AboutUsCardProps {
    text: string | null
}

export function AboutUsCard(props: AboutUsCardProps) {
    const { t } = useTranslation()

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Group justify="space-between">
                    <Text fw={ 600 } size="lg">{ t("components:Info.AboutUsCard.Title") }</Text>
                    <UpdateOrganisationInfoButton />
                </Group>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <AboutUsCardContent { ...props } />
            </Card.Section>
        </ContentCard>
    );
}

function AboutUsCardContent(props: AboutUsCardProps) {
    const { text } = props

    const { t } = useTranslation()

    if (!text) {
        return (
            <Center>
                <Text>{ t("components:Info.AboutUsCard.No Text") }</Text>
            </Center>
        )
    }

    return (
        <MMarkdown text={ text } textAlign="justify" />
    )
}
