import { Format } from "@/common/Format"
import { UUID } from "@/common/Types";
import { AttendanceFactText } from "@/components/memberships/details/AttendanceFact";
import { ContentCard } from "@/components/shared";
import { AttendanceTimeUnit, AttendanceType, BillingCycle, MembershipAgreementCommitment, MembershipAgreementTemplateState } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Button, Center, Group, Stack, Text, ThemeIcon, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { Duration } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface MembershipCardProps {
    template: {
        uuid: UUID
        name: string
        attendanceRestriction: {
            count: number
            timeUnit: AttendanceTimeUnit
            type: AttendanceType
        }
        billing: {
            amount: number
            cycle: BillingCycle
        }
        commitment: MembershipAgreementCommitment
        runtime: string | null
        subtitle: string | null
        state: MembershipAgreementTemplateState
    }
}

export function MembershipCard(props: MembershipCardProps) {
    const { template } = props

    const { t } = useTranslation()

    return (
        <ContentCard p="lg">
            <Stack style={ { height: "100%" } }>
                <Stack align="center" gap={ 0 } style={ { flex: 1 } }>
                    <Title order={ 3 } fw={ 600 }>{ template.name }</Title>
                    <Text ta="center" c="dimmed" size="sm" style={ { minHeight: `var(--mantine-line-height)` } }>
                        { template.subtitle }
                    </Text>
                </Stack>

                <Stack align="center" gap={ 0 }>
                    <Text fw={ 600 } style={ { fontSize: 42, fontVariant: "tabular-nums" } }>
                        { template.billing.amount } €
                    </Text>

                    <Text style={ { minHeight: `var(--mantine-line-height)` } }>
                        { t(`entities:BillingCycle.${ template.billing.cycle }`) }
                    </Text>
                </Stack>

                <Stack gap="xs">
                    <Group wrap="nowrap">
                        <ThemeIcon size={ 24 } variant="light" radius="xl">
                            <IconCheck size={ 16 } strokeWidth={ 4 } />
                        </ThemeIcon>
                        <Text>
                            { AttendanceFactText(template.attendanceRestriction) }
                        </Text>
                    </Group>

                    <Group style={ { minHeight: 28 } } wrap="nowrap">
                        {
                            template.commitment === MembershipAgreementCommitment.LongTerm && template.runtime && (
                                <>
                                    <ThemeIcon size={ 24 } variant="light" radius="xl">
                                        <IconCheck size={ 16 } strokeWidth={ 4 } />
                                    </ThemeIcon>
                                    <Text>
                                        { `${ t("pages:MembershipsPage.Runtime", { duration: Format.humanize(Duration.fromISO(template.runtime)) }) }` }
                                    </Text>
                                </>
                            )
                        }
                    </Group>

                </Stack>

                <Center>
                    <Button fullWidth component={ Link } to={ generatePath(Routes.MEMBERSHIPS_BY_ID, { uuid: template.uuid }) }>
                        { t("common:Button.Select") }
                    </Button>
                </Center>
            </Stack>
        </ContentCard>
    )
}
