import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { toNullIfEmpty } from "@/common/String";
import { UpdateEventRegistrationInput } from "@/generated/graphql";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";


export interface EventRegistrationFormData {
    comment: string
}

interface EventRegistrationFormProps<TSuccessData> {
    initialValues: UpdateEventRegistrationInput
    onSubmit: (values: UpdateEventRegistrationInput) => Promise<FormSubmitResult<TSuccessData>>
    onCancel: () => void
}

export function EventRegistrationForm<TSuccessData>(props: EventRegistrationFormProps<TSuccessData>) {
    const { initialValues, onSubmit, onCancel } = props

    const { t } = useTranslation()

    const form = useMyPowerHourForm<EventRegistrationFormData, (values: EventRegistrationFormData) => UpdateEventRegistrationInput>({
        initialValues: toFormData(initialValues),
        transformValues: toInput
    })

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <TextInput label={ t("entities:Event.Registration.Comment") }
                           autoComplete="off"
                           { ...form.getInputProps("comment") } />

                <Group justify="space-between">
                    <Button variant="default" onClick={ onCancel }>
                        { t("common:Button.Cancel") }
                    </Button>

                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(initialValues: UpdateEventRegistrationInput): EventRegistrationFormData {
    return {
        comment: initialValues.comment ?? ""
    }
}

function toInput(values: EventRegistrationFormData): UpdateEventRegistrationInput {
    return {
        comment: toNullIfEmpty(values.comment)
    }
}
