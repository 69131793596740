import { showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { MembershipDetails } from "@/components/memberships/details";
import { PaymentMethodList } from "@/components/memberships/PaymentMethodList";
import { FollowUpHint, MemberAcceptanceCard, MembershipRequestMemberMenu, OwnerApprovalHint } from "@/components/memberships/request";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { GraphQLClient } from "@/contexts/graphql";
import { MyMembershipRequestByIdPageDocument, MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, LoaderFunctionArgs, useLoaderData, useNavigate, useRevalidator } from "react-router-dom";


interface MyMembershipRequestByIdPage {
    allowedMembershipRequestApprovalActions: MyMembershipRequestByIdPageQuery["allowedMembershipRequestApprovalActions"]
    membershipRequest: MyMembershipRequestByIdPageQuery["membershipRequest"]
}

export async function MyMembershipRequestByIdPageLoader({ params }: LoaderFunctionArgs): Promise<MyMembershipRequestByIdPage> {
    const myMembershipRequestByIdPageQuery = await GraphQLClient.query<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>({
        query: MyMembershipRequestByIdPageDocument,
        variables: { uuid: params.uuid! },
    })

    return {
        allowedMembershipRequestApprovalActions: myMembershipRequestByIdPageQuery.data.allowedMembershipRequestApprovalActions,
        membershipRequest: myMembershipRequestByIdPageQuery.data.membershipRequest
    }
}

export function MyMembershipRequestByIdPage() {
    const { allowedMembershipRequestApprovalActions, membershipRequest } = useLoaderData() as MyMembershipRequestByIdPage

    const { t } = useTranslation()
    const navigate = useNavigate()
    const revalidator = useRevalidator()

    const { refresh } = useAuthentication()


    const onAcceptSuccess = async (isActivated: boolean, membershipUUID?: UUID) => {
        if (isActivated && membershipUUID) {
            showSuccessNotification(t("pages:MyMembershipRequestByIdPage.MembershipRequest accepted and activated"))
            await refresh()
            navigate(generatePath(Routes.MY_MEMBERSHIPS_BY_ID, { uuid: membershipUUID }))
        } else {
            showSuccessNotification(t("pages:MyMembershipRequestByIdPage.MembershipRequest accepted"))
            revalidator.revalidate()
        }
    }

    const onRejectSuccess = () => {
        showSuccessNotification(t("pages:MyMembershipRequestByIdPage.MembershipRequest rejected"))
        navigate(Routes.MY_MEMBERSHIPS, { replace: true })
    }

    const onWithdrawSuccess = () => {
        showSuccessNotification(t("pages:MyMembershipRequestByIdPage.MembershipRequest withdrawn"))
        navigate(Routes.MY_MEMBERSHIPS, { replace: true })
    }

    return (
        <>
            <MetaTitle title={ membershipRequest.name } />

            <Stack>
                <Group justify="space-between" wrap="nowrap">
                    <PageTitleWithBack title={ membershipRequest.name } onBack={ () => navigate(Routes.MY_MEMBERSHIPS) } />

                    <MembershipRequestMemberMenu allowedMembershipRequestActions={ allowedMembershipRequestApprovalActions }
                                                 membershipRequest={ membershipRequest }
                                                 onWithdrawSuccess={ onWithdrawSuccess } />
                </Group>

                <FollowUpHint membershipRequest={ membershipRequest } />

                <MemberAcceptanceCard membershipRequest={ membershipRequest }
                                      onAcceptSuccess={ onAcceptSuccess }
                                      onRejectSuccess={ onRejectSuccess } />

                <OwnerApprovalHint approvalConfiguration={ membershipRequest.membershipAgreementTemplate.approvalConfiguration }
                                   membershipRequest={ membershipRequest } />

                <ContentCard>
                    <Card.Section withBorder inheritPadding py="sm">
                        <Text fw={ 600 } size="lg">{ t("pages:MyMembershipRequestByIdPage.MembershipFacts") }</Text>
                    </Card.Section>

                    <Card.Section inheritPadding py="sm">
                        <MembershipDetails contractee={ membershipRequest.creator }
                                           membership={ membershipRequest }
                                           organisation={ membershipRequest.membershipAgreementTemplate.organisation } />
                    </Card.Section>
                </ContentCard>

                { membershipRequest.paymentMethods.length > 0 &&
                    <ContentCard>
                        <Card.Section withBorder inheritPadding py="sm">
                            <Text fw={ 600 } size="lg">{ t("pages:MyMembershipRequestByIdPage.PaymentMethods") }</Text>
                        </Card.Section>

                        <Card.Section inheritPadding py="md">
                            <PaymentMethodList paymentMethods={ membershipRequest.paymentMethods } />
                        </Card.Section>
                    </ContentCard>
                }
            </Stack>
        </>
    )
}
