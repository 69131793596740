import { NavigationItem } from "@/components/navigation";
import { InAppNotificationsSection } from "@/layout/shared/bottom-navbar/InAppNotificationsSection"
import { Box, SimpleGrid, Stack } from "@mantine/core";
import classes from "./BottomNavbar.module.scss"
import { BottomNavbarButton } from "./BottomNavbarButton"
import { UserSection } from "./UserSection"


interface BottomNavbarExtensionProps {
    items: NavigationItem[]
}

export function BottomNavbarExtension(props: BottomNavbarExtensionProps) {
    const { items } = props

    return (
        <Stack gap={ 4 }>
            <InAppNotificationsSection />

            <Box className={ classes.bottomExtension } p={ 4 }>
                <Stack gap={ 4 }>
                    <UserSection />

                    <SimpleGrid cols={ 2 } spacing={ 4 }>
                        { items.slice(3, 999).map(it => <BottomNavbarButton key={ it.labelKey } item={ it } />) }
                    </SimpleGrid>
                </Stack>
            </Box>
        </Stack>
    )
}
