import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { DEFAULT_UUID } from "@/common/Object";
import { toNullIfEmpty } from "@/common/String";
import { UUID } from "@/common/Types";
import {
    PersonalInformationValue,
    PersonInput,
    useCreateMembershipRequestFromTemplateForFollowUpMutation,
    useCreateMembershipRequestFromTemplateMutation,
    useUpdatePersonMutation
} from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Routes } from "@/routing/Routes";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { MembershipCheckoutForm, MembershipCheckoutFormData } from "./MembershipCheckoutForm";


interface MembershipCheckoutFormContainerProps {
    myMemberships: readonly {
        uuid: UUID
        name: string
        isFollowUpAllowed: boolean
    }[]
    organisation: {
        dataProtectionHtml: string | null
        name: string
        termsOfServiceHtml: string | null
    }
    person: {
        uuid: string
        birthday: string | null
        city: string | null
        country: string | null
        firstname: string | null
        lastname: string | null
        phone: string | null
        postalCode: string | null
        streetAddress: string | null
        username: string
    }
    template: {
        uuid: UUID
        isAllowedForFollowUp: boolean
        requiredPersonalInformationValues: readonly PersonalInformationValue[]
    }
}

export function MembershipCheckoutFormContainer(props: MembershipCheckoutFormContainerProps) {
    const { myMemberships, organisation, person, template } = props

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [ updatePersonMutation ] = useUpdatePersonMutation()
    const [ createFromTemplate ] = useCreateMembershipRequestFromTemplateMutation()
    const [ createFromTemplateForFollowUp ] = useCreateMembershipRequestFromTemplateForFollowUpMutation()

    async function createNewMembership() {
        return await executeMutation({
            mutation: () => createFromTemplate({
                variables: { membershipAgreementTemplateUUID: template.uuid }
            }),
            onSuccess: (data) => {
                if (data) {
                    if (data.createMembershipRequestFromTemplate.membershipAgreement === null) {
                        showSuccessNotification(t("pages:MembershipsByIdPage.Request created"))
                        navigate(generatePath(Routes.MY_MEMBERSHIPS_REQUEST_BY_ID, { uuid: data.createMembershipRequestFromTemplate.membershipRequest.uuid }))
                    } else {
                        showSuccessNotification(t("pages:MembershipsByIdPage.Booking successful"))
                        navigate(generatePath(Routes.MY_MEMBERSHIPS_BY_ID, { uuid: data.createMembershipRequestFromTemplate.membershipAgreement.uuid }))
                    }
                }
            },
            onError: (error) => {
                Logger.error("Failed to create MembershipRequestFromTemplate", error)
                showErrorNotification({ error })
            }
        });
    }

    async function createFollowUpMembership(membershipAgreementUUID: string) {
        return await executeMutation({
            mutation: () => createFromTemplateForFollowUp({
                variables: { membershipAgreementTemplateUUID: template.uuid, membershipAgreementUUID: membershipAgreementUUID }
            }),
            onSuccess: (data) => {
                if (data) {
                    showSuccessNotification(t("pages:MembershipsByIdPage.Request created"))
                    navigate(generatePath(Routes.MY_MEMBERSHIPS_REQUEST_BY_ID, { uuid: data.createMembershipRequestFromTemplateForFollowUp.uuid }))
                }
            },
            onError: (error) => {
                Logger.error("Failed to create MembershipRequestFromTemplate", error)
                showErrorNotification({ error })
            }
        });
    }

    const onSubmit = async (formData: MembershipCheckoutFormData) => {
        const personInput: PersonInput = {
            birthday: toNullIfEmpty(formData.birthday.trim()),
            city: toNullIfEmpty(formData.city.trim()),
            country: toNullIfEmpty(formData.country.trim()),
            firstname: toNullIfEmpty(formData.firstname.trim()),
            lastname: toNullIfEmpty(formData.lastname.trim()),
            phone: toNullIfEmpty(formData.phone.trim()),
            postalCode: toNullIfEmpty(formData.postalCode.trim()),
            streetAddress: toNullIfEmpty(formData.streetAddress.trim()),
            username: person.username
        }

        const updatePersonResult = await executeMutation({
            mutation: () => updatePersonMutation({ variables: { personInput: personInput } }),
            onError: (error) => {
                Logger.error("Failed to update Person", error)
                showErrorNotification({ error })
            }
        })

        if (!updatePersonResult.isSuccess) {
            return
        }

        const checkoutMode = formData.checkoutMode;
        if (checkoutMode === null) {
            Logger.error("CheckoutMode not set")
            showErrorNotification({ message: t("common:Error.Something went wrong") })
            return
        }

        if (checkoutMode === DEFAULT_UUID) {
            await createNewMembership()
        } else {
            await createFollowUpMembership(checkoutMode)
        }
    }

    return (
        <MembershipCheckoutForm myMemberships={ myMemberships }
                                onSubmit={ onSubmit }
                                organisation={ organisation }
                                person={ person }
                                template={ template } />
    )
}
