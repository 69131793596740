import Logger from "@/common/Logger";
import { showErrorNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useWithdrawMembershipRequestMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


interface WithdrawMembershipRequestItemProps {
    membershipRequest: {
        uuid: UUID
    }
    onWithdrawSuccess: () => void
}

export function WithdrawMembershipRequestItem(props: WithdrawMembershipRequestItemProps) {
    const { membershipRequest, onWithdrawSuccess } = props

    const { t } = useTranslation()

    const [ withdrawRequest ] = useWithdrawMembershipRequestMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Memberships.WithdrawMembershipRequestItem.Withdraw MembershipRequest") }</Text>,
            children: t("components:Memberships.WithdrawMembershipRequestItem.Do you want to withdraw this MembershipRequest?"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Withdraw") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => withdrawRequest({ variables: { uuid: membershipRequest.uuid } }),
                    onSuccess: () => onWithdrawSuccess(),
                    onError: (error) => {
                        Logger.error("Failed to withdraw MembershipRequest", error)
                        showErrorNotification({ error })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } color="red" leftSection={ <IconTrash size={ 18 } /> }
                   id="withdraw-membership-request-button" data-umami-event="withdraw-membership-request-button">
            { t("common:Button.Withdraw") }
        </Menu.Item>
    )
}
