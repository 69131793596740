import { UUID } from "@/common/Types";
import { MembershipRequestMenu } from "@/components/memberships/request/menu/MembershipRequestMenu";
import { WithdrawMembershipRequestItem } from "@/components/memberships/request/menu/WithdrawMembershipRequestItem";
import { MembershipRequestAction } from "@/generated/graphql";
import React from "react";


interface MembershipRequestMemberMenuProps {
    allowedMembershipRequestActions: readonly MembershipRequestAction[]
    membershipRequest: {
        uuid: UUID
    }
    onWithdrawSuccess: () => void
}

export function MembershipRequestMemberMenu(props: MembershipRequestMemberMenuProps) {
    const { allowedMembershipRequestActions, membershipRequest, onWithdrawSuccess } = props

    const items: JSX.Element[] = []
    if (allowedMembershipRequestActions.includes(MembershipRequestAction.Withdraw)) {
        items.push(<WithdrawMembershipRequestItem membershipRequest={ membershipRequest } onWithdrawSuccess={ onWithdrawSuccess } />)
    }

    if (items.length === 0) {
        return null
    }

    return (
        <MembershipRequestMenu>
            { items.map((it, i) => (
                <React.Fragment key={ i }>
                    { it }
                </React.Fragment>
            )) }
        </MembershipRequestMenu>
    )
}
