import { To, useNavigate } from "react-router-dom";
import { NavigateFunction } from "react-router/dist/lib/hooks";

interface UseMyPowerHourNavigate {
    navigate: NavigateFunction
    navigateBack: (fallbackTo: To) => void
}

export function useMyPowerHourNavigate(): UseMyPowerHourNavigate {
    const navigate = useNavigate()

    const onBack = (to: To) => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1)
        } else {
            navigate(to)
        }
    }

    return {
        navigate: navigate,
        navigateBack: onBack
    }
}
