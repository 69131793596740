import { InfoFact } from "@/components/organisation/organisation";
import { ContentCard } from "@/components/shared";
import { Routes } from "@/routing/Routes";
import { Anchor, Card, SimpleGrid, Text } from "@mantine/core";
import { IconSectionSign, IconShieldCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


export function LegalCard() {
    const { t } = useTranslation()

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Info.LegalCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <SimpleGrid cols={ { base: 1, sm: 2 } }>
                    <InfoFact icon={ <IconSectionSign /> }>
                        <Anchor component={ Link } to={ Routes.INFO_TERMS_OF_SERVICE }>{ t("common:Misc.TermsOfService") } </Anchor>
                    </InfoFact>
                    <InfoFact icon={ <IconShieldCheck /> }>
                        <Anchor component={ Link } to={ Routes.INFO_DATA_PROTECTION }>{ t("common:Misc.DataProtection") } </Anchor>
                    </InfoFact>
                </SimpleGrid>
            </Card.Section>
        </ContentCard>
    )
}
