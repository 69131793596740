import { useOrganisation } from "@/contexts/organisation";
import { Title } from "react-head";
import { useTranslation } from "react-i18next";


interface MetaTitleProps {
    title?: string | null
}

export function MetaTitle(props: MetaTitleProps) {
    const { title } = props

    const { t } = useTranslation()

    const { organisation } = useOrganisation()

    return (
        <Title>{ title && title + " - " } { organisation.name } - { t("common:Misc.AppName") }</Title>
    )
}
