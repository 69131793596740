/**
 * (?=.*[a-zA-Z]) must contain chars
 * (?=.*\d)       must contain numbers
 * .{8,}          must have at least length 8
 */
const regex = new RegExp("^(?=.*[a-zA-Z])(?=.*\\d).{8,}$")

export const passwordValidator = (errorMessage: string) => {
    return (value: string) => {
        if (regex.test(value)) {
            return null
        } else {
            return errorMessage
        }
    }
}
