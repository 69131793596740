import Events from "@/common/Events";
import { toDateTime, toDuration } from "@/common/Luxon";
import { byStartAndName } from "@/common/Sort";
import { UUID } from "@/common/Types";
import { EventCalendar } from "@/components/events/calendar";
import { PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useDeviceSize } from "@/contexts/device-size";
import { GraphQLClient } from "@/contexts/graphql";
import {
    ClassesAuthenticatedPageDocument,
    ClassesAuthenticatedPageQuery,
    ClassesAuthenticatedPageQueryVariables,
    ClassesVisitorPageDocument,
    ClassesVisitorPageQuery,
    ClassesVisitorPageQueryVariables,
    RegistrationState
} from "@/generated/graphql";
import { CreateClassButton } from "@/pages/classes/_components/CreateClassButton";
import { SpecialEventsSection } from "@/pages/classes/_components/SpecialEventsSection";
import RoutingStorage from "@/routing/RoutingStorage";
import { Divider, Group, Stack } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, useLoaderData, useSearchParams } from "react-router-dom";


export const SP_FROM = "from"

interface ClassesPageLoaderData {
    classes: ClassesAuthenticatedPageQuery["classes"]
    events: ClassesAuthenticatedPageQuery["events"]
    classRegistrations: ClassesAuthenticatedPageQuery["classRegistrations"]
    eventRegistrations: ClassesAuthenticatedPageQuery["eventRegistrations"]
    startDate: DateTime
}

export async function ClassesPageLoader({ request }: LoaderFunctionArgs): Promise<ClassesPageLoaderData> {
    const url = new URL(request.url);
    const fromParam = url.searchParams.get(SP_FROM)
    const startDate = toDateTime(fromParam) ?? (RoutingStorage.isMobile() ? DateTime.now().startOf("day") : DateTime.now().startOf("week"))
    const endDate = startDate.plus({ day: 6 });

    if (RoutingStorage.isAuthenticated()) {
        return ClassesPageAuthenticatedLoader(startDate, endDate)
    } else {
        return ClassesPageVisitorLoader(startDate, endDate)
    }
}

export async function ClassesPageAuthenticatedLoader(startDate: DateTime, endDate: DateTime): Promise<ClassesPageLoaderData> {
    const pageData = await GraphQLClient.query<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>({
        query: ClassesAuthenticatedPageDocument,
        variables: {
            classesFilter: {
                endDate: endDate.toISODate(),
                startDate: startDate.toISODate(),
                isSpecial: null,
                states: null,
            },
            eventsFilter: {
                endDate: DateTime.now().plus({ day: 6 }).toISODate(),
                startDate: DateTime.now().toISODate(),
                isSpecial: true,
                states: null,
            },
            classRegistrationsFilter: {
                end: endDate.endOf("day").toISO(),
                start: startDate.toISO(),
                eventId: null,
            },
            eventRegistrationsFilter: {
                end: DateTime.now().plus({ day: 6 }).toISO(),
                start: DateTime.now().toISO(),
                eventId: null,
            },
        },
    })

    return {
        classes: pageData.data.classes,
        events: pageData.data.events,
        classRegistrations: pageData.data.classRegistrations,
        eventRegistrations: pageData.data.eventRegistrations,
        startDate: startDate
    }
}

export async function ClassesPageVisitorLoader(startDate: DateTime, endDate: DateTime): Promise<ClassesPageLoaderData> {
    const pageData = await GraphQLClient.query<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>({
        query: ClassesVisitorPageDocument,
        variables: {
            classesFilter: {
                endDate: endDate.toISODate(),
                startDate: startDate.toISODate(),
                isSpecial: null,
                states: null,
            }
        },
    })

    return {
        classes: pageData.data.classes,
        events: [],
        classRegistrations: { __typename: "MyEventRegistrationsResponse", results: [] },
        eventRegistrations: { __typename: "MyEventRegistrationsResponse", results: [] },
        startDate: startDate
    }
}

export function ClassesPage() {
    const pageData = useLoaderData() as ClassesPageLoaderData

    const { t } = useTranslation()
    const { isMobile } = useDeviceSize()

    const [ searchParams, setSearchParam ] = useSearchParams();

    const classRegistrations = pageData.classRegistrations.results.reduce((prev: Record<UUID, RegistrationState>, cur: {
        state: RegistrationState,
        event: { uuid: UUID }
    }) => {
        prev[cur.event.uuid] = cur.state
        return prev
    }, {}) ?? {}

    const classes = pageData.classes
        .map(it => ({
            uuid: it.uuid,
            availableSpots: it.eventDetails.registrationSettings.availableSpots,
            color: it.eventDetails.color,
            end: DateTime.fromISO(it.end),
            isRegistrable: it.eventDetails.registrationSettings.isRegistrable,
            name: it.eventDetails.name,
            start: DateTime.fromISO(it.start),
            state: it.state,
            registrationStartPeriod: toDuration(it.eventDetails.registrationSettings.registrationStartPeriod),
            registrationState: classRegistrations[it.uuid],
            usedSpots: it.registrations?.filter(it => Events.CountableRegistrations.includes(it.state))?.length
        }))
        .sort(byStartAndName)

    const eventRegistrations = pageData.eventRegistrations.results.reduce((prev: Record<UUID, RegistrationState>, cur: {
        state: RegistrationState,
        event: { uuid: UUID }
    }) => {
        prev[cur.event.uuid] = cur.state
        return prev
    }, {}) ?? {}

    const events = pageData.events
        .map(it => ({
            uuid: it.uuid,
            availableSpots: it.eventDetails.registrationSettings.availableSpots,
            color: it.eventDetails.color,
            end: DateTime.fromISO(it.end),
            isRegistrable: it.eventDetails.registrationSettings.isRegistrable,
            name: it.eventDetails.name,
            start: DateTime.fromISO(it.start),
            state: it.state,
            registrationStartPeriod: toDuration(it.eventDetails.registrationSettings.registrationStartPeriod),
            registrationState: eventRegistrations[it.uuid],
            usedSpots: it.registrations?.filter(it => Events.CountableRegistrations.includes(it.state))?.length
        }))
        .sort(byStartAndName)

    const onFromChanged = (start: DateTime) => {
        searchParams.set(SP_FROM, start.toISODate())
        setSearchParam(searchParams, { replace: true })
    }

    return (
        <>
            <MetaTitle title={ t("pages:ClassesPage.Title") } />

            <Stack>
                <Group justify="space-between" align="start" wrap="nowrap">
                    <PageTitle title={ t("pages:ClassesPage.Title") } />

                    <CreateClassButton />
                </Group>

                { isMobile && events.length > 0 && (
                    <>
                        <SpecialEventsSection events={ events } />

                        { events.length > 0 && <Divider /> }
                    </>
                ) }

                <EventCalendar events={ classes }
                               from={ pageData.startDate }
                               onFromChanged={ onFromChanged } />
            </Stack>
        </>
    )
}
