import { IcalLinkButton } from "@/components/events/IcalLinkButton";
import { ContentCard } from "@/components/shared";
import { Routes } from "@/routing/Routes";
import { Button, Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EventLineItemLinked } from "./EventLineItemLinked"
import { DateMode, Event } from "./types"


interface MyUpcomingEventsCardProps {
    events: readonly Event[]
}

export function MyUpcomingEventsCard(props: MyUpcomingEventsCardProps) {
    const { events } = props

    const { t } = useTranslation()

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Group justify="space-between">
                    <Text fw={ 600 } size="lg">{ t("components:Events.MyUpcomingEventsCard.My upcoming Events") }</Text>
                    <IcalLinkButton />
                </Group>
            </Card.Section>

            <Card.Section inheritPadding py="sm" h="100%">
                <MyUpcomingEventsList events={ events } />
            </Card.Section>
        </ContentCard>
    )
}

function MyUpcomingEventsList(props: MyUpcomingEventsCardProps) {
    const { events } = props

    const { t } = useTranslation()

    if (events.length === 0) {
        return (
            <Stack h="100%" align="center" justify="center">
                <Text>
                    { t("components:Events.MyUpcomingEventsCard.No upcoming Events") }
                </Text>
                <Button component={ Link } to={ Routes.CLASSES }>
                    { t("components:Events.MyUpcomingEventsCard.All Classes") }
                </Button>
            </Stack>
        )
    }

    return (
        <Stack>
            {
                events.slice(0, 3).map(it => (
                    <EventLineItemLinked key={ it.uuid }
                                         dateMode={ DateMode.ABSOLUTE }
                                         event={ it }
                                         withBorder />
                ))
            }

            <Group justify="center">
                <Button component={ Link } to={ Routes.CLASSES }>
                    { t("components:Events.MyUpcomingEventsCard.All Classes") }
                </Button>
            </Group>
        </Stack>
    )
}
