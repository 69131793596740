import Logger from "@/common/Logger"
import { ContentCard } from "@/components/shared";
import { useCreateEventRegistrationMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Button, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";


interface CreateRegistrationCardProps {
    event: { eventUUID: string }
    text: string | null
    onSuccess: () => void
    onError: () => void
}

export function CreateRegistrationCard(props: CreateRegistrationCardProps) {
    const { event, onError, onSuccess, text } = props

    return (
        <ContentCard>
            <Stack>
                { text && <Text>{ text }</Text> }

                <CreateRegistrationButton event={ event }
                                          onSuccess={ onSuccess }
                                          onError={ onError } />
            </Stack>
        </ContentCard>
    )
}

interface CreateRegistrationButtonProps {
    event: { eventUUID: string }
    onSuccess: () => void
    onError: () => void
}

export function CreateRegistrationButton(props: CreateRegistrationButtonProps) {
    const { event, onError, onSuccess } = props

    const { t } = useTranslation()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ createEventRegistration ] = useCreateEventRegistrationMutation()

    const onRegister = async () => {
        setLoading(true)

        await executeMutation({
                mutation: () => createEventRegistration({
                    variables: {
                        eventId: event.eventUUID,
                        input: { comment: null }
                    }
                }),
                onSuccess: () => {
                    onSuccess()
                },
                onError: (error) => {
                    Logger.error("Failed to create EventRegistration", error)
                    onError()
                }
            }
        )

        setLoading(false)
    }

    return (
        <Button onClick={ onRegister } loading={ loading }>
            { t("common:Button.Create Registration") }
        </Button>
    )
}
