import Logger from "@/common/Logger";
import { FormSubmitResult } from "@/common/MyPowerHourForm";
import { PersonalInformationForm } from "@/components/core/person/PersonalInformationForm";
import { PersonalInformationValue, PersonInput, UpdatePersonMutation, useIsUsernameAvailableLazyQuery, useUpdatePersonMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { useCallback } from "react";


interface UpdatePersonalInformationFormProps {
    initialValues: PersonInput

    requiredPersonalInformation: readonly PersonalInformationValue[]

    onSuccess: () => void
    onError: (error: any) => void
}

export function UpdatePersonalInformationForm(props: UpdatePersonalInformationFormProps) {
    const { initialValues, onError, onSuccess, requiredPersonalInformation } = props

    const [ updatePerson ] = useUpdatePersonMutation()
    const [ isUsernameAvailableQuery ] = useIsUsernameAvailableLazyQuery()

    const checkUsername = useCallback(async (username: string) => {
        try {
            const response = await isUsernameAvailableQuery({ variables: { name: username } })
            if (response.error) {
                Logger.error(`Username check returned no response. Error: ${ response.error }`)
                return false
            }

            if (!response.data) {
                Logger.error(`Username check returned no response. Error: ${ response.error }`)
                return false
            }

            return response.data.isUsernameAvailable;
        } catch (e) {
            Logger.exception("Failed to check username", e)
            return false
        }
    }, [ isUsernameAvailableQuery ])

    const onSubmit = async (input: PersonInput): Promise<FormSubmitResult<UpdatePersonMutation>> => {
        if (initialValues.username !== input.username) {
            const isUsernameAvailable = checkUsername(input.username);
            if (!await isUsernameAvailable) {
                return { isSuccess: false, error: "Username unavailable" }
            }
        }

        return await executeMutation({
            mutation: () => updatePerson({ variables: { personInput: input } }),
            onSuccess: onSuccess,
            onError: (error) => {
                Logger.error("Failed to update Person", error)
                onError(error)
            }
        })
    }

    return (
        <PersonalInformationForm initialValues={ initialValues }
                                 requiredPersonalInformation={ requiredPersonalInformation }
                                 onCheckUsername={ checkUsername }
                                 onSubmit={ onSubmit } />
    )
}
