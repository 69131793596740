import { OrganisationLogHistoryCard } from "@/components/organisation/log";
import { ColoredDashboardTile, PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import {
    ActiveMemberCountDocument,
    ActiveMemberCountQuery,
    ActiveMemberCountQueryVariables,
    LatestOrganisationLogEntriesDocument,
    LatestOrganisationLogEntriesQuery,
    LatestOrganisationLogEntriesQueryVariables,
    MemberPermission,
    MembershipRequestCountDocument,
    MembershipRequestCountQuery,
    MembershipRequestCountQueryVariables,
} from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import RoutingStorage from "@/routing/RoutingStorage";
import { ApolloQueryResult } from "@apollo/client";
import { Divider, SimpleGrid, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";


interface AdministrationPageLoaderData {
    activeMemberCount: ApolloQueryResult<ActiveMemberCountQuery> | null
    MembershipRequestCount: ApolloQueryResult<MembershipRequestCountQuery> | null
    latestOrganisationLogEntries: ApolloQueryResult<LatestOrganisationLogEntriesQuery> | null
}

export async function AdministrationPageLoader(): Promise<AdministrationPageLoaderData> {
    let MembershipRequestCount = Promise.resolve<ApolloQueryResult<MembershipRequestCountQuery> | null>(null)
    if (RoutingStorage.hasPermission(MemberPermission.MembershipAgreementManager)) {
        MembershipRequestCount = GraphQLClient.query<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>({
            query: MembershipRequestCountDocument,
        })
    }

    let activeMemberCount = Promise.resolve<ApolloQueryResult<ActiveMemberCountQuery> | null>(null)
    if (RoutingStorage.hasPermission(MemberPermission.MemberManager)) {
        activeMemberCount = GraphQLClient.query<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>({
            query: ActiveMemberCountDocument,
        })
    }

    let latestOrganisationLogEntries = Promise.resolve<ApolloQueryResult<LatestOrganisationLogEntriesQuery> | null>(null)
    if (RoutingStorage.hasPermission(MemberPermission.MemberManager) || RoutingStorage.hasPermission(MemberPermission.MembershipAgreementManager)) {
        latestOrganisationLogEntries = GraphQLClient.query<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>({
            query: LatestOrganisationLogEntriesDocument,
        })
    }

    return {
        activeMemberCount: await activeMemberCount,
        latestOrganisationLogEntries: await latestOrganisationLogEntries,
        MembershipRequestCount: await MembershipRequestCount
    }
}

export function AdministrationPage() {
    const loaderData = useLoaderData() as AdministrationPageLoaderData

    const { t } = useTranslation()

    const data = {
        activeMemberCount: loaderData.activeMemberCount?.data.activeMemberCount ?? null,
        latestOrganisationLogEntries: loaderData.latestOrganisationLogEntries?.data.latestOrganisationLogEntries.results ?? null,
        MembershipRequestCount: loaderData.MembershipRequestCount?.data.membershipRequestCount ?? null,
    }

    return (
        <>
            <MetaTitle title={ t("pages:AdministrationPage.Title") } />

            <Stack>
                <PageTitle title={ t("pages:AdministrationPage.Title") } />

                <SimpleGrid cols={ { base: 1, sm: 2, lg: 3 } }>
                    {
                        data.MembershipRequestCount && (
                            <ColoredDashboardTile color="blue"
                                                  number={ data.MembershipRequestCount }
                                                  text={ t("components:Administration.Dashboard.pending MembershipRequests") }
                                                  to={ Routes.ADMIN_MEMBERSHIPS_REQUESTS } />
                        )
                    }

                    {
                        data.activeMemberCount && (
                            <ColoredDashboardTile color="green"
                                                  number={ data.activeMemberCount }
                                                  text={ t("components:Administration.Dashboard.active Members") }
                                                  to={ Routes.ADMIN_MEMBERS } />
                        )
                    }
                </SimpleGrid>

                <Divider />

                { data.latestOrganisationLogEntries && (
                    <OrganisationLogHistoryCard entries={ data.latestOrganisationLogEntries } />
                ) }
            </Stack>
        </>
    )
}
