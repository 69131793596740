import { SignInForm, SignUpForm } from "@/components/account";
import { ContentCard } from "@/components/shared";
import { Card, SegmentedControl, Stack } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


type SignUpOrSignIn = "SignUp" | "SignIn"

interface SignInOrSignUpCardProps {
    onSignUpSuccess?: () => void
    signInFirst?: boolean
}

export function SignInOrSignUpCard(props: SignInOrSignUpCardProps) {
    const { onSignUpSuccess, signInFirst } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const [ signUpOrSignIn, setSignUpOrSignIn ] = useState<SignUpOrSignIn>(signInFirst ? "SignIn" : "SignUp")

    const data: { value: SignUpOrSignIn, label: string }[] = [
        { value: "SignUp", label: t("common:Button.SignUp") },
        { value: "SignIn", label: t("common:Button.SignIn") }
    ];

    const onModeChanged = (v: string) => {
        if (v === "SignUp" || v === "SignIn") {
            setSignUpOrSignIn(v)
        } else {
            setSignUpOrSignIn("SignUp")
        }
    }

    return (
        <ContentCard style={ { overflow: "unset" } }>
            <Card.Section inheritPadding py="sm">
                <Stack>
                    <SegmentedControl data={ data }
                                      color="brand"
                                      value={ signUpOrSignIn }
                                      onChange={ onModeChanged } />

                    { signUpOrSignIn === "SignUp" && <SignUpForm onSuccess={ onSignUpSuccess } /> }
                    { signUpOrSignIn === "SignIn" && <SignInForm onSuccess={ revalidator.revalidate } /> }
                </Stack>
            </Card.Section>
        </ContentCard>
    )
}
