import Logger from "@/common/Logger"
import { GraphQLClient } from "@/contexts/graphql";
import { useSession } from "@/contexts/session";
import { MeDocument, MemberPermission, MeQuery, MeQueryVariables } from "@/generated/graphql";
import RoutingStorage from "@/routing/RoutingStorage";
import { useDocumentVisibility } from "@mantine/hooks";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContextType, Me } from "./Types";


const AuthContextDefaultValues: AuthenticationContextType = {
    isInitialized: false,
    isAuthenticated: false,
    isMember: false,

    hasAnyPermission: false,
    hasPermission: () => false,

    me: null,

    refresh: () => Promise.resolve(),
}

export const AuthenticationContext = React.createContext<AuthenticationContextType>(AuthContextDefaultValues)

export function AuthenticationContextProvider(props: PropsWithChildren) {
    const { children } = props

    const { isSignedIn, isInitialized: isSessionInitialized, signOut } = useSession()

    const visibility = useDocumentVisibility()

    const [ isInitialized, setIsInitialized ] = useState(false)
    const [ me, setMe ] = useState<Me | null>(null)

    const refresh = useCallback(async () => {
        try {
            const meResponse = await GraphQLClient.query<MeQuery, MeQueryVariables>({ query: MeDocument })
            if (meResponse.error) {
                Logger.error("Failed to load Me", meResponse.error)
            } else if (meResponse.data) {
                setMe(meResponse.data.me)
            }
        } catch (e: unknown) {
            Logger.error("Failed to load Me", e)
            await signOut()
        }
    }, [ signOut ])

    useEffect(() => {
        if (!isSessionInitialized) {
            Logger.debug(`[AuthenticationContext] loading`)
            return
        }

        if (isSignedIn) {
            void refresh().finally(() => setIsInitialized(true))
        } else {
            setMe(null)
            setIsInitialized(true)
        }
    }, [ isSignedIn, isSessionInitialized, refresh ])

    useEffect(() => {
        if (isSignedIn && visibility == "visible" && ("connection" in navigator) && navigator.onLine) {
            refresh().catch(e => Logger.warn("Failed to refresh AuthenticationContext after visible", e))
        }
    }, [ visibility, refresh ])

    const hasPermission = useCallback((permission: MemberPermission) => {
        if (!me) {
            return false
        }

        return me.memberPermissions.includes(permission)
    }, [ me ])

    const hasAnyPermission = useCallback(() => {
        if (!me) {
            return false
        }

        return me.memberPermissions.length > 0
    }, [ me ])

    const context: AuthenticationContextType = useMemo(() => ({
        isInitialized: isInitialized,
        isAuthenticated: !!me,
        isMember: (!!me) && !!(me.memberUUID),

        hasAnyPermission: hasAnyPermission(),
        hasPermission: hasPermission,

        me: me,

        refresh: refresh,
    }), [ isInitialized, me, hasAnyPermission, hasPermission, refresh ])

    RoutingStorage.setIsMember(context.isMember)
    RoutingStorage.setPermission(me?.memberPermissions ?? [])

    return (
        <AuthenticationContext.Provider value={ context }>
            { children }
        </AuthenticationContext.Provider>
    )
}

export const useAuthentication = () => useContext(AuthenticationContext);
