import { TypographyStylesProvider } from "@mantine/core"
import { MantineStyleProps } from "@mantine/core/lib/core/Box/style-props/style-props.types";
import Markdown from "markdown-to-jsx"

interface MMarkdownProps {
    fs?: MantineStyleProps["fs"],
    text: string
    textAlign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start"
}

export function MMarkdown(props: MMarkdownProps) {
    const { fs, text, textAlign } = props

    return (
        <TypographyStylesProvider style={ { textAlign: textAlign } } fs={ fs } fz="inherit" p={ 0 } m={ 0 }>
            <Markdown>
                { text }
            </Markdown>
        </TypographyStylesProvider>
    )
}
