import Logger from "@/common/Logger"
import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { useCreatePasswordResetMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface CreatePasswordResetFormProps {
    onSuccess: () => void
}

interface CreatePasswordResetFormData {
    emailAddress: string
}

export function CreatePasswordResetForm(props: CreatePasswordResetFormProps) {
    const { onSuccess } = props

    const { t } = useTranslation()

    const [ createPasswordReset ] = useCreatePasswordResetMutation()

    const form = useMyPowerHourForm<CreatePasswordResetFormData>({
        name: "CreatePasswordResetForm",
        initialValues: {
            emailAddress: "",
        },
    })

    const handleSubmit = async (data: CreatePasswordResetFormData) => {
        const input = {
            emailAddress: data.emailAddress
        }

        await executeMutation({
            mutation: () => createPasswordReset({ variables: { input: input } }),
            onSuccess: onSuccess,
            onError: (error) => {
                Logger.error("Failed to create password reset", error)
                showErrorNotification({ message: t("common:Error.Something went wrong") })
            }
        })
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <TextInput label={ t("components:Account.Common.EmailAddress") }
                           name="emailAddress"
                           type="email"
                           autoComplete="email"
                           autoFocus
                           required
                           { ...form.getInputProps("emailAddress") } />

                <Group justify="flex-end">
                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Request") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
