import Logger from "@/common/Logger";
import { AppProperties } from "@/configuration/AppProperties";
import { MphGraphQlError } from "@/graphql/MphGraphQlError"
import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";


/**
 * Hack: Global variable with the GraphQL client to use it in react-router loaders.
 */
export var GraphQLClient = new ApolloClient<unknown>({ cache: new InMemoryCache() })

export function buildGraphQlClient() {
    const headerLink = setContext((_, { headers }) => {
        let organisation = undefined
        if (window.organisation) {
            organisation = { "X-Organisation-UUID": window.organisation.uuid }
        }

        return {
            credentials: "include",
            headers: {
                ...headers,
                ...organisation,
                "X-UI-Commit-Hash": import.meta.env.VITE_APP_GIT_SHA,
            }
        }
    })

    const httpLink = new HttpLink({ uri: `${ AppProperties().backendUrl ?? "http://localhost" }/graphql`, })

    const retryLink = new RetryLink({ delay: { initial: 100 } })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(error => {
                if (error.extensions && error.extensions["reason"] === MphGraphQlError.NOT_FOUND) {
                    Logger.warn(`[GraphQL error] ${ error.message }`)
                } else {
                    Logger.error(`[GraphQL error] ${ error.message }`)
                }
            });
        }

        if (networkError) {
            Logger.error(`[Network error] ${ networkError.message }`, networkError);
        }
    })

    GraphQLClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([ errorLink, headerLink, retryLink, httpLink ]),
        defaultOptions: {
            query: { fetchPolicy: "network-only" }
        }
    });

    return GraphQLClient
}