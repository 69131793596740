import Logger from "@/common/Logger"
import { ErrorBoundary } from "@/components/error";
import { MemberPermission } from "@/generated/graphql";
import { AdministrationLayout, AdministrationLayoutLoader } from "@/layout/administration/AdministrationLayout";
import { MyLayout } from "@/layout/my/MyLayout";
import { OrganisationLayout } from "@/layout/organisation/OrganisationLayout";
import { AdministrationPage, AdministrationPageLoader } from "@/pages/administration/AdministrationPage";
import { MemberByIdPage, MemberByIdPageLoader } from "@/pages/administration/members/[uuid]/MemberByIdPage";
import { CancelMembershipPage } from "@/pages/cancel-membership/CancelMembershipPage"
import { ClassByIdPage, ClassByIdPageLoader } from "@/pages/classes/[uuid]/ClassByIdPage"
import { ClassesPage, ClassesPageLoader } from "@/pages/classes/ClassesPage";
import { EmailAddressUpdatePage } from "@/pages/email-address-update/[uuid]/EmailAddressUpdatePage"
import { EventsPage, EventsPageLoader } from "@/pages/events/EventsPage";
import { DataProtectionPage, DataProtectionPageLoader } from "@/pages/info/data-protection/DataProtectionPage";
import { InfoPage, InfoPageLoader } from "@/pages/info/InfoPage";
import { TermsOfServicePage, TermsOfServicePageLoader } from "@/pages/info/terms-of-service/TermsOfServicePage";
import { MembershipsByIdPage, MembershipsByIdPageLoader } from "@/pages/memberships/[uuid]/MembershipsByIdPage";
import { MembershipsPage, MembershipsPageLoader } from "@/pages/memberships/MembershipsPage";
import { MyAccountPage, MyAccountPageLoader } from "@/pages/my/account/MyAccountPage";
import { BenchmarkPage, BenchmarkPageLoader } from "@/pages/my/benchmarks/[uuid]/BenchmarkPage";
import { UpdateBenchmarkPage, UpdateBenchmarkPageLoader } from "@/pages/my/benchmarks/[uuid]/update/UpdateBenchmarkPage";
import { CreateBenchmarkPage } from "@/pages/my/benchmarks/create/CreateBenchmarkPage";
import { MyBenchmarksPage, MyBenchmarksPageLoader } from "@/pages/my/benchmarks/MyBenchmarksPage";
import { MyMembershipAgreementByIdPage, MyMembershipAgreementByIdPageLoader } from "@/pages/my/memberships/[uuid]/MyMembershipAgreementByIdPage";
import { MyMembershipsPage, MyMembershipsPageLoader } from "@/pages/my/memberships/MyMembershipsPage";
import { MyMembershipRequestByIdPage, MyMembershipRequestByIdPageLoader } from "@/pages/my/memberships/requests/[uuid]/MyMembershipRequestByIdPage";
import { MyDashboardPage, MyDashboardPageLoader } from "@/pages/my/MyDashboardPage"
import { ResetPasswordPage } from "@/pages/reset-password/[uuid]/ResetPasswordPage"
import { CreatePasswordResetPage } from "@/pages/reset-password/CreatePasswordResetPage"
import { SignInPage } from "@/pages/sign-in/SignInPage";
import { SignOutPage } from "@/pages/sign-out/SignOutPage"
import { SignUpConfirmPage } from "@/pages/sign-up/confirm/SignUpConfirmPage"
import { SignUpPage } from "@/pages/sign-up/SignUpPage";
import { CopyWorkoutPage, CopyWorkoutPageLoader } from "@/pages/workouts/[uuid]/copy/CopyWorkoutPage";
import { UpdateWorkoutPage, UpdateWorkoutPageLoader } from "@/pages/workouts/[uuid]/update/UpdateWorkoutPage";
import { WorkoutPage, WorkoutPageLoader } from "@/pages/workouts/[uuid]/WorkoutPage";
import { WorkoutConfigurationPage, WorkoutConfigurationPageLoader } from "@/pages/workouts/configuration/WorkoutConfigurationPage";
import { CreateWorkoutPage, CreateWorkoutPageLoader } from "@/pages/workouts/create/CreateWorkoutPage";
import { WorkoutListPage, WorkoutListPageLoader } from "@/pages/workouts/list/WorkoutListPage"
import { WorkoutsPage, WorkoutsPageLoader } from "@/pages/workouts/WorkoutsPage";
import { ProtectedRoute } from "@/routing/ProtectedRoute";
import { Root } from "@/routing/Root"
import { Routes } from "@/routing/Routes";
import RoutingStorage from "@/routing/RoutingStorage";
import type { Router as RemixRouter } from "@remix-run/router/dist/router";
import * as Sentry from "@sentry/react";
import React from "react";
import { createBrowserRouter, Navigate, redirect, RouteObject, useLocation } from "react-router-dom";

/**
 * Lazy Router.
 * Building the router multiple times (e.g. on re-render) fucks up the history.
 * This keeps the router for the lifetime of the application.
 */
const Router = (() => {
    let router: RemixRouter | null = null

    return {
        get: () => {
            if (router === null) {
                router = buildRouter()
            }

            return router
        }
    }
})()

export default Router

function buildRouter() {
    return Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
        {
            path: Routes.ROOT,
            element: <Root />,
            children: [
                {
                    errorElement: <ErrorBoundary />,
                    children: [
                        {
                            element: <OrganisationLayout />,
                            children: [ ...OrganisationRoutes() ]
                        },
                        {
                            path: Routes.MY,
                            element: <MyLayout />,
                            children: [ ...MyRoutes(), ]
                        },
                        {
                            path: Routes.ADMIN,
                            loader: () => AdministrationLayoutLoader(),
                            element: <AdministrationLayout />,
                            children: [ ...AdministrationRoutes() ]
                        }
                    ]
                }
            ],
        },
        {
            path: "*",
            element: <RouteNotFound />
        }
    ]);
}

function OrganisationRoutes() {
    return [
        {
            index: true,
            element: <Navigate to={ RoutingStorage.isAuthenticated() ? Routes.CLASSES : Routes.INFO } replace={ true } />
        },
        {
            path: Routes.SIGN_UP,
            element: <SignUpPage />,
        },
        {
            path: Routes.SIGN_UP_CONFIRM,
            element: <SignUpConfirmPage />,
        },
        {
            path: Routes.SIGN_IN,
            element: <SignInPage />,
        },
        {
            path: Routes.RESET_PASSWORD,
            element: <CreatePasswordResetPage />,
        },
        {
            path: Routes.RESET_PASSWORD_BY_ID,
            element: <ResetPasswordPage />,
        },
        {
            path: Routes.EMAIL_ADDRESS_UPDATE_BY_ID,
            element: <EmailAddressUpdatePage />,
        },
        {
            path: Routes.SIGN_OUT,
            element: <SignOutPage />,
        },
        {
            path: Routes.CANCEL_MEMBERSHIP,
            element: <CancelMembershipPage />,
        },
        {
            path: Routes.CONTACT,
            lazy: () => import("@/pages/contact/ContactPage")
        },

        ...InfoRoutes(),
        ...ClassesRoutes(),
        ...EventsRoutes(),
        ...MembershipRoutes(),
        ...WorkoutRoutes(),
    ]
}

function InfoRoutes(): RouteObject[] {
    return [
        {
            path: Routes.INFO,
            loader: InfoPageLoader,
            element: <InfoPage />
        },
        {
            path: Routes.INFO_DATA_PROTECTION,
            loader: DataProtectionPageLoader,
            element: <DataProtectionPage />
        },
        {
            path: Routes.INFO_TERMS_OF_SERVICE,
            loader: TermsOfServicePageLoader,
            element: <TermsOfServicePage />
        },
    ]
}

function ClassesRoutes(): RouteObject[] {
    return [
        {
            path: Routes.CLASSES,
            loader: ClassesPageLoader,
            element: <ClassesPage />
        },
        {
            path: Routes.CLASSES_BY_ID,
            loader: ClassByIdPageLoader,
            element: <ClassByIdPage />,
        },
        {
            element: <ProtectedRoute permission={ MemberPermission.EventManager } />,
            loader: ProtectedRouteLoader(),
            children: [
                {
                    path: Routes.CLASSES_CREATE,
                    lazy: () => import("@/pages/classes/create/CreateClassPage")
                },
                {
                    path: Routes.CLASSES_BY_ID_UPDATE,
                    lazy: () => import("@/pages/classes/[uuid]/update/UpdateClassPage")
                },
                {
                    path: Routes.CLASSES_BY_ID_COPY,
                    lazy: () => import("@/pages/classes/[uuid]/copy/CopyClassPage")
                }
            ]
        }
    ]
}

function EventsRoutes(): RouteObject[] {
    return [
        {
            path: Routes.EVENTS,
            loader: EventsPageLoader,
            element: <EventsPage />
        }
    ]
}

function MembershipRoutes(): RouteObject[] {
    return [
        {
            path: Routes.MEMBERSHIPS,
            loader: MembershipsPageLoader,
            element: <MembershipsPage />
        },
        {
            path: Routes.MEMBERSHIPS_BY_ID,
            loader: MembershipsByIdPageLoader,
            element: <MembershipsByIdPage />
        },
        {
            element: <ProtectedRoute permission={ MemberPermission.MembershipAgreementManager } />,
            loader: ProtectedRouteLoader(),
            children: [
                {
                    path: Routes.MEMBERSHIPS_CREATE,
                    lazy: () => import("@/pages/memberships/create/CreateMembershipAgreementTemplatePage")
                },
                {
                    path: Routes.MEMBERSHIPS_CONFIGURATION,
                    lazy: () => import("@/pages/memberships/configuration/MembershipConfigurationPage")
                },
                {
                    path: Routes.MEMBERSHIPS_APPROVAL_CONFIGURATIONS_CREATE,
                    lazy: () => import("@/pages/memberships/approvalConfigurations/create/CreateApprovalConfigurationPage")
                },
                {
                    path: Routes.MEMBERSHIPS_APPROVAL_CONFIGURATIONS_BY_ID,
                    lazy: () => import("@/pages/memberships/approvalConfigurations/[uuid]/UpdateApprovalConfigurationPage")
                },
                {
                    path: Routes.MEMBERSHIPS_PAYMENT_METHODS_CREATE,
                    lazy: () => import("@/pages/memberships/paymentMethods/create/CreatePaymentMethodPage")
                },
                {
                    path: Routes.MEMBERSHIPS_PAYMENT_METHODS_BY_ID,
                    lazy: () => import("@/pages/memberships/paymentMethods/[uuid]/UpdatePaymentMethodPage")
                },
                {
                    path: Routes.MEMBERSHIPS_BY_ID_UPDATE,
                    lazy: () => import("@/pages/memberships/[uuid]/update/UpdateMembershipAgreementTemplatePage")
                }
            ]
        }
    ]
}

function WorkoutRoutes(): RouteObject[] {
    return [
        {
            path: Routes.WORKOUTS,
            loader: (args) => WorkoutsPageLoader(args),
            element: <WorkoutsPage />,
        },
        {
            path: Routes.WORKOUTS_LIST,
            loader: WorkoutListPageLoader,
            element: <WorkoutListPage />,
        },
        {
            path: Routes.WORKOUTS_BY_ID,
            loader: WorkoutPageLoader,
            element: <WorkoutPage />
        },
        {
            element: <ProtectedRoute permission={ MemberPermission.WorkoutManager } />,
            loader: ProtectedRouteLoader(),
            children: [
                {
                    path: Routes.WORKOUTS_CREATE,
                    loader: CreateWorkoutPageLoader,
                    element: <CreateWorkoutPage />
                },
                {
                    path: Routes.WORKOUTS_BY_ID_COPY,
                    loader: CopyWorkoutPageLoader,
                    element: <CopyWorkoutPage />
                },
                {
                    path: Routes.WORKOUTS_BY_ID_UPDATE,
                    loader: UpdateWorkoutPageLoader,
                    element: <UpdateWorkoutPage />
                },
                {
                    path: Routes.WORKOUTS_CONFIGURATION,
                    loader: WorkoutConfigurationPageLoader,
                    element: <WorkoutConfigurationPage />,
                },
                {
                    path: Routes.WORKOUTS_TYPE_CREATE,
                    lazy: () => import("@/pages/workouts/configuration/type/create/CreateWorkoutTypePage")
                },
                {
                    path: Routes.WORKOUTS_TYPE_BY_ID,
                    lazy: () => import("@/pages/workouts/configuration/type/[uuid]/UpdateWorkoutTypePage")
                },
                {
                    path: Routes.WORKOUTS_RESULT_CATEGORY_CREATE,
                    lazy: () => import("@/pages/workouts/configuration/result-category/create/CreateWorkoutResultCategoryPage")
                },
                {
                    path: Routes.WORKOUTS_RESULT_CATEGORY_BY_ID,
                    lazy: () => import("@/pages/workouts/configuration/result-category/[uuid]/UpdateWorkoutResultCategoryPage")
                },
            ]
        }
    ]
}

function MyRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute isAuthenticated />,
            loader: ProtectedRouteLoader(),
            children: [
                {
                    index: true,
                    loader: MyDashboardPageLoader,
                    element: <MyDashboardPage />
                },
                {
                    path: Routes.MY_ACCOUNT,
                    loader: MyAccountPageLoader,
                    element: <MyAccountPage />
                },
                {
                    path: Routes.MY_MEMBERSHIPS,
                    loader: MyMembershipsPageLoader,
                    element: <MyMembershipsPage />
                },
                {
                    path: Routes.MY_MEMBERSHIPS_REQUEST_BY_ID,
                    loader: MyMembershipRequestByIdPageLoader,
                    element: <MyMembershipRequestByIdPage />
                },
                {
                    path: Routes.MY_MEMBERSHIPS_BY_ID,
                    loader: MyMembershipAgreementByIdPageLoader,
                    element: <MyMembershipAgreementByIdPage />
                },
                {
                    path: Routes.MY_BENCHMARKS_CREATE,
                    element: <CreateBenchmarkPage />,
                },
                {
                    path: Routes.MY_BENCHMARKS,
                    loader: MyBenchmarksPageLoader,
                    element: <MyBenchmarksPage />,
                },
                {
                    path: Routes.MY_BENCHMARKS_BY_ID,
                    loader: BenchmarkPageLoader,
                    element: <BenchmarkPage />,
                },
                {
                    path: Routes.MY_BENCHMARKS_BY_ID_UPDATE,
                    loader: UpdateBenchmarkPageLoader,
                    element: <UpdateBenchmarkPage />,
                },
                {
                    path: Routes.MY_NOTIFICATION_SETTINGS,
                    lazy: () => import("@/pages/my/notification-settings/NotificationSettingsPage")
                },
            ]
        }
    ]
}

function AdministrationRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute hasAnyPermission />,
            loader: ProtectedRouteLoader(),
            children: [
                {
                    index: true,
                    loader: () => AdministrationPageLoader(),
                    element: <AdministrationPage />,
                },
                ...AdministrationOrganisationRoutes(),
                ...AdministrationMembershipRoutes(),
                ...AdministrationMemberRoutes(),
                ...AdministrationPenaltyRoutes(),
                ...AdministrationPermissionRoutes(),
                ...AdministrationTermsOfServiceRoutes(),
            ]
        }
    ]
}

function AdministrationOrganisationRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute permission={ MemberPermission.OrganisationManager } />,
            children: [
                {
                    path: Routes.ADMIN_ORGANISATION_INFO_UPDATE,
                    lazy: () => import("@/pages/administration/organisation/info/update/UpdateOrganisationInfoPage")
                },
                {
                    path: Routes.ADMIN_IMAGES,
                    lazy: () => import("@/pages/administration/images/OrganisationImagesPage")
                },
            ]
        }
    ]
}

function AdministrationMembershipRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute permission={ MemberPermission.MembershipAgreementManager } />,
            children: [
                {
                    path: Routes.ADMIN_MEMBERSHIPS_REQUESTS,
                    lazy: () => import("@/pages/administration/memberships/requests/MembershipRequestsAdminPage")
                },
                {
                    path: Routes.ADMIN_MEMBERSHIPS_REQUESTS_BY_ID,
                    lazy: () => import("@/pages/administration/memberships/requests/[uuid]/MembershipRequestByIdPage")
                },
                {
                    path: Routes.ADMIN_MEMBERSHIPS_REQUESTS_BY_ID_UPDATE,
                    lazy: () => import("@/pages/administration/memberships/requests/[uuid]/update/UpdateMembershipRequestPage")
                },
                {
                    path: Routes.ADMIN_MEMBERSHIPS_REPORTS,
                    lazy: () => import("@/pages/administration/memberships/reports/MembershipsReportsPage")
                },
                {
                    path: Routes.ADMIN_MEMBERSHIPS_BY_ID,
                    lazy: () => import("@/pages/administration/memberships/[uuid]/MembershipAgreementByIdPage")
                },
                {
                    path: Routes.ADMIN_MEMBERSHIPS,
                    lazy: () => import("@/pages/administration/memberships/MembershipsAdminPage")
                },
            ]
        }
    ]
}

function AdministrationMemberRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute permission={ MemberPermission.MemberManager } />,
            children: [
                {
                    path: Routes.ADMIN_MEMBERS,
                    lazy: () => import("@/pages/administration/members/MembersPage")
                },
                {
                    path: Routes.ADMIN_MEMBERS_REPORTS,
                    lazy: () => import("@/pages/administration/members/reports/MembersReportsPage")
                },
                {
                    path: Routes.ADMIN_MEMBERS_BY_ID,
                    loader: MemberByIdPageLoader,
                    element: <MemberByIdPage />,
                    children: [
                        {
                            index: true,
                            lazy: () => import("@/pages/administration/members/[uuid]/person/MemberPersonPage")
                        },
                        {
                            path: Routes.ADMIN_MEMBERS_BY_ID_PERSON,
                            lazy: () => import("@/pages/administration/members/[uuid]/person/MemberPersonPage")
                        },
                        {
                            path: Routes.ADMIN_MEMBERS_BY_ID_MEMBERSHIPS,
                            lazy: () => import("@/pages/administration/members/[uuid]/memberships/MemberMembershipsPage")
                        },
                        {
                            path: Routes.ADMIN_MEMBERS_BY_ID_PENALTY,
                            lazy: () => import("@/pages/administration/members/[uuid]/penalties/MemberPenaltyPage")
                        },
                        {
                            path: Routes.ADMIN_MEMBERS_BY_ID_EMAILS,
                            lazy: () => import("@/pages/administration/members/[uuid]/emails/MemberEmailsPage")
                        }
                    ]
                }
            ]
        }
    ]
}

function AdministrationPenaltyRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute permission={ MemberPermission.MemberManager } />,
            children: [
                {
                    path: Routes.ADMIN_PENALTIES,
                    lazy: () => import("@/pages/administration/penalties/PenaltiesPage")
                }
            ]
        }
    ]
}

function AdministrationPermissionRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute permission={ MemberPermission.MemberManager } />,
            children: [
                {
                    path: Routes.ADMIN_PERMISSIONS,
                    lazy: () => import("@/pages/administration/permissions/PermissionPage")
                },
                {
                    path: Routes.ADMIN_PERMISSIONS_ROLE_CREATE,
                    lazy: () => import("@/pages/administration/permissions/role/create/CreateMemberRolePage")
                },
                {
                    path: Routes.ADMIN_PERMISSIONS_ROLE_BY_ID,
                    lazy: () => import("@/pages/administration/permissions/role/[uuid]/UpdateMemberRolePage")
                }
            ]
        }
    ]
}

function AdministrationTermsOfServiceRoutes(): RouteObject[] {
    return [
        {
            element: <ProtectedRoute permission={ MemberPermission.OrganisationManager } />,
            children: [
                {
                    path: Routes.ADMIN_TERMS_OF_SERVICE,
                    lazy: () => import("@/pages/administration/terms-of-service/UpdateTermsOfServicePage")
                },
                {
                    path: Routes.ADMIN_DATA_PROTECTION,
                    lazy: () => import("@/pages/administration/data-protection/UpdateDataProtectionPage")
                }
            ]
        }
    ]
}

function ProtectedRouteLoader() {
    return () => {
        if (!RoutingStorage.isAuthenticated()) {
            return redirect(Routes.ROOT)
        }

        return null
    }
}

function RouteNotFound() {
    const location = useLocation()

    Logger.warn(`Route not found: ${ location.pathname }`)

    return (
        <Navigate to={ Routes.ROOT } replace={ true } />
    )
}
