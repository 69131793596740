import { ContentCard } from "@/components/shared";
import { SP_ALLOWED_FOR_FOLLOWUP } from "@/pages/memberships/MembershipsPage";
import { Routes } from "@/routing/Routes";
import { Box, Button, Card, Grid, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

export function SwitchInfoCard() {
    const { t } = useTranslation()

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Memberships.SwitchInfoCard.Switch Membership") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <Grid align="start">
                    <Grid.Col span={ { base: 12, sm: "auto" } }>
                        <Text>
                            { t("components:Memberships.SwitchInfoCard.Switch Membership Instructions") }
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={ { base: 12, sm: "content" } }>
                        <Box style={ { display: "flex", alignItems: "center", justifyContent: "end", height: "100%" } }>
                            <Button fullWidth component={ Link }
                                    to={ { pathname: Routes.MEMBERSHIPS, search: createSearchParams({ [SP_ALLOWED_FOR_FOLLOWUP]: "true" }).toString() } }>
                                { t("components:Memberships.SwitchInfoCard.All Memberships") }
                            </Button>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Card.Section>
        </ContentCard>
    )
}
