import { CloudinaryFile } from "@/generated/graphql";

export interface AppPropertiesType {
    backendUrl: string
    contactUrl: string
    contactKey: string
    googleMapsEmbedApiKey: string
}

export interface OrganisationType {
    uuid: string
    name: string
    nameSlug: string
    shortDescription?: string | null
    logoSquare?: CloudinaryFile | null
}

export const AppProperties = () => window.appProperties
