import { TextInput, TextInputProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

export function PhoneNumberInput(props: TextInputProps) {
    const { t } = useTranslation()

    return (
        <TextInput label={ t("components:Shared.Input.PhoneNumber") }
                   type="tel"
                   autoComplete="tel"
                   autoCorrect="off"
                   spellCheck={ false }
                   { ...props } />
    )
}