import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  LocalDate: { input: string; output: string; }
  PeriodDuration: { input: string; output: string; }
  ProtectedString: { input: string; output: string; }
  UUID: { input: string; output: string; }
  ZonedDateTime: { input: string; output: string; }
};

export type AcceptMembershipRequestResponse = {
  readonly __typename?: 'AcceptMembershipRequestResponse';
  readonly isActivated: Scalars['Boolean']['output'];
  readonly membershipAgreement: Maybe<MembershipAgreement>;
  readonly membershipRequest: MembershipRequest;
};

export type ActiveMembershipAgreementsReport = {
  readonly __typename?: 'ActiveMembershipAgreementsReport';
  readonly count: Scalars['Int']['output'];
  readonly templateName: Scalars['String']['output'];
};

export type ActiveMembershipAgreementsReportResponse = {
  readonly __typename?: 'ActiveMembershipAgreementsReportResponse';
  readonly results: ReadonlyArray<ActiveMembershipAgreementsReport>;
};

export type AddMemberRoleToMembersInput = {
  readonly memberRoleUUID: Scalars['UUID']['input'];
  readonly memberUUIDs: ReadonlyArray<Scalars['UUID']['input']>;
};

export type ApprovalConfiguration = {
  readonly __typename?: 'ApprovalConfiguration';
  readonly approvalCount: Scalars['Int']['output'];
  readonly description: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly requiresApproval: Scalars['Boolean']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type ApprovalConfigurationInput = {
  readonly approvalCount: Scalars['Int']['input'];
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly requiresApproval: Scalars['Boolean']['input'];
};

export type ApprovalConfigurationsResponse = {
  readonly __typename?: 'ApprovalConfigurationsResponse';
  readonly results: ReadonlyArray<ApprovalConfiguration>;
  readonly totalCount: Scalars['Int']['output'];
};

export type ApprovalFact = {
  readonly __typename?: 'ApprovalFact';
  readonly creator: PersonProtected;
  readonly date: Scalars['ZonedDateTime']['output'];
  readonly member: MemberFull;
  readonly uuid: Scalars['UUID']['output'];
};

export type ApproveMembershipRequestResponse = {
  readonly __typename?: 'ApproveMembershipRequestResponse';
  readonly isActivated: Scalars['Boolean']['output'];
  readonly membershipAgreement: Maybe<MembershipAgreement>;
  readonly membershipRequest: MembershipRequest;
};

export enum AttendanceTimeUnit {
  Day = 'DAY',
  Month = 'MONTH',
  None = 'NONE',
  Week = 'WEEK'
}

export enum AttendanceType {
  Credits = 'CREDITS',
  Unlimited = 'UNLIMITED'
}

export type Benchmark = {
  readonly __typename?: 'Benchmark';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly latestResult: Maybe<BenchmarkResult>;
  readonly name: Scalars['String']['output'];
  readonly resultOrder: BenchmarkResultOrder;
  readonly resultType: BenchmarkResultType;
  readonly results: ReadonlyArray<BenchmarkResult>;
  readonly uuid: Scalars['UUID']['output'];
};

export type BenchmarkInput = {
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly resultOrder: BenchmarkResultOrder;
  readonly resultType: BenchmarkResultType;
};

export type BenchmarkResult = {
  readonly __typename?: 'BenchmarkResult';
  readonly comment: Maybe<Scalars['String']['output']>;
  readonly date: Scalars['ZonedDateTime']['output'];
  readonly score: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type BenchmarkResultInput = {
  readonly comment: InputMaybe<Scalars['String']['input']>;
  readonly date: Scalars['ZonedDateTime']['input'];
  readonly score: Scalars['String']['input'];
};

export enum BenchmarkResultOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum BenchmarkResultType {
  Distance = 'DISTANCE',
  Duration = 'DURATION',
  Repetition = 'REPETITION',
  Weight = 'WEIGHT'
}

export type BilledEventRegistration = {
  readonly __typename?: 'BilledEventRegistration';
  readonly event: Event;
  readonly feeAmount: Scalars['Float']['output'];
  readonly isSettled: Scalars['Boolean']['output'];
  readonly isSplit: Scalars['Boolean']['output'];
  readonly membershipAgreement: MembershipAgreement;
};

export type BilledEventRegistrationsFilterInput = {
  readonly membershipAgreementUUID: Scalars['UUID']['input'];
};

export type BilledEventRegistrationsResponse = {
  readonly __typename?: 'BilledEventRegistrationsResponse';
  readonly hasNext: Scalars['Boolean']['output'];
  readonly results: ReadonlyArray<BilledEventRegistration>;
  readonly totalCount: Scalars['Int']['output'];
};

export enum BillingCycle {
  Monthly = 'MONTHLY',
  Onetime = 'ONETIME'
}

export type CloudinaryFile = {
  readonly __typename?: 'CloudinaryFile';
  readonly publicId: Scalars['String']['output'];
  readonly version: Scalars['String']['output'];
};

export type ConfirmEmailAddressUpdateInput = {
  readonly uuid: Scalars['UUID']['input'];
};

export type CreateEmailAddressUpdateInput = {
  readonly newEmailAddress: Scalars['String']['input'];
};

export type CreateEventRegistrationInput = {
  readonly comment: InputMaybe<Scalars['String']['input']>;
};

export type CreateMembershipRequestFromTemplateResponse = {
  readonly __typename?: 'CreateMembershipRequestFromTemplateResponse';
  readonly isActivated: Scalars['Boolean']['output'];
  readonly membershipAgreement: Maybe<MembershipAgreement>;
  readonly membershipRequest: MembershipRequest;
};

export type CreateOrganisationFeedPostCommentInput = {
  readonly message: Scalars['String']['input'];
};

export type CreateOrganisationFeedPostInput = {
  readonly message: Scalars['String']['input'];
};

export type CreateOrganisationFeedPostReactionInput = {
  readonly type: PostReactionType;
};

export type CreatePasswordResetInput = {
  readonly emailAddress: Scalars['String']['input'];
};

export type CreateVisitorEventRegistrationInput = {
  readonly comment: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
};

export type DataProtectionInput = {
  readonly text: InputMaybe<Scalars['String']['input']>;
};

export type EmailPreference = {
  readonly __typename?: 'EmailPreference';
  readonly isEnabled: Scalars['Boolean']['output'];
  readonly notificationScope: NotificationScope;
};

export type EmailPreferenceEntryInput = {
  readonly isEnabled: Scalars['Boolean']['input'];
  readonly notificationScope: NotificationScope;
};

export type EmailPreferencesInput = {
  readonly entries: ReadonlyArray<EmailPreferenceEntryInput>;
};

export type Equipment = {
  readonly __typename?: 'Equipment';
  readonly location: ReadonlyArray<LocationEquipmentTypes>;
};

export type Event = {
  readonly __typename?: 'Event';
  readonly end: Scalars['ZonedDateTime']['output'];
  readonly eventDetails: EventDetails;
  readonly isGenerated: Scalars['Boolean']['output'];
  readonly registrations: Maybe<ReadonlyArray<Registration>>;
  readonly start: Scalars['ZonedDateTime']['output'];
  readonly state: EventState;
  readonly uuid: Scalars['String']['output'];
};

export type EventDetails = {
  readonly __typename?: 'EventDetails';
  readonly additionalPaymentDescription: Maybe<Scalars['String']['output']>;
  readonly color: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly isSpecial: Scalars['Boolean']['output'];
  readonly location: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly organisation: Organisation;
  readonly registrationSettings: EventRegistrationSettings;
  readonly schedule: EventSchedule;
  readonly uuid: Scalars['UUID']['output'];
  readonly visibility: EventVisibility;
  readonly visibilityPreview: Maybe<Scalars['PeriodDuration']['output']>;
};

export type EventDetailsInput = {
  readonly additionalPaymentDescription: InputMaybe<Scalars['String']['input']>;
  readonly color: InputMaybe<Scalars['String']['input']>;
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly isSpecial: Scalars['Boolean']['input'];
  readonly location: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly registrationSettings: EventRegistrationSettingsInput;
  readonly schedule: EventScheduleInput;
  readonly visibility: EventVisibility;
  readonly visibilityPreview: InputMaybe<Scalars['PeriodDuration']['input']>;
};

export enum EventRegistrationCancellationDenyReason {
  InvalidState = 'INVALID_STATE',
  NotRegistered = 'NOT_REGISTERED'
}

export enum EventRegistrationDenyReason {
  EventNotRegistrable = 'EVENT_NOT_REGISTRABLE',
  HasActiveAgreementNoCredits = 'HAS_ACTIVE_AGREEMENT_NO_CREDITS',
  IsRegistered = 'IS_REGISTERED',
  MemberInactive = 'MEMBER_INACTIVE',
  NotConfirmedAfterLateCancellation = 'NOT_CONFIRMED_AFTER_LATE_CANCELLATION',
  NoActiveAgreement = 'NO_ACTIVE_AGREEMENT',
  RegistrationPeriodNotStarted = 'REGISTRATION_PERIOD_NOT_STARTED'
}

export type EventRegistrationSettings = {
  readonly __typename?: 'EventRegistrationSettings';
  readonly availableSpots: Maybe<Scalars['Int']['output']>;
  readonly feeAmount: Scalars['Float']['output'];
  readonly isAutomaticRegistrationConfirmationEnabled: Scalars['Boolean']['output'];
  readonly isRegistrable: Scalars['Boolean']['output'];
  readonly isSwitchToOtherEventAllowed: Scalars['Boolean']['output'];
  readonly lateCancellationPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly minimumNumberOfRegistrations: Maybe<Scalars['Int']['output']>;
  readonly minimumNumberOfRegistrationsPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly registrationStartPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly resolveWaitListPeriod: Maybe<Scalars['PeriodDuration']['output']>;
};

export type EventRegistrationSettingsInput = {
  readonly availableSpots: InputMaybe<Scalars['Int']['input']>;
  readonly feeAmount: Scalars['Float']['input'];
  readonly isAutomaticRegistrationConfirmationEnabled: Scalars['Boolean']['input'];
  readonly isRegistrable: Scalars['Boolean']['input'];
  readonly isSwitchToOtherEventAllowed: Scalars['Boolean']['input'];
  readonly lateCancellationPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly minimumNumberOfRegistrations: InputMaybe<Scalars['Int']['input']>;
  readonly minimumNumberOfRegistrationsPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly registrationStartPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly resolveWaitListPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
};

export type EventSchedule = {
  readonly __typename?: 'EventSchedule';
  readonly firstEnd: Scalars['ZonedDateTime']['output'];
  readonly firstStart: Scalars['ZonedDateTime']['output'];
  readonly recurrence: RecurrenceType;
  readonly recurrenceEnd: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type EventScheduleInput = {
  readonly firstEnd: Scalars['ZonedDateTime']['input'];
  readonly firstStart: Scalars['ZonedDateTime']['input'];
  readonly recurrence: RecurrenceType;
  readonly recurrenceEnd: InputMaybe<Scalars['LocalDate']['input']>;
};

export enum EventState {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Deleted = 'DELETED',
  Finished = 'FINISHED',
  Pending = 'PENDING',
  Started = 'STARTED'
}

export enum EventVisibility {
  Everyone = 'EVERYONE',
  Members = 'MEMBERS'
}

export type EventsFilterInput = {
  readonly endDate: Scalars['LocalDate']['input'];
  readonly isSpecial: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['LocalDate']['input'];
  readonly states: InputMaybe<ReadonlyArray<EventState>>;
};

export type GenerateMemberEmailPreviewInput = {
  readonly message: Scalars['String']['input'];
  readonly recipientMemberUUID: Scalars['UUID']['input'];
  readonly subject: Scalars['String']['input'];
};

export type GenerateMemberEmailPreviewResponse = {
  readonly __typename?: 'GenerateMemberEmailPreviewResponse';
  readonly contentHtml: Scalars['String']['output'];
};

export type GoogleMapsSettings = {
  readonly __typename?: 'GoogleMapsSettings';
  readonly placeId: Maybe<Scalars['String']['output']>;
};

export type HasUnseenOrganisationFeedActivitiesResponse = {
  readonly __typename?: 'HasUnseenOrganisationFeedActivitiesResponse';
  readonly result: Scalars['Boolean']['output'];
};

export type HistoricMemberCount = {
  readonly __typename?: 'HistoricMemberCount';
  readonly count: Scalars['Int']['output'];
  readonly date: Scalars['ZonedDateTime']['output'];
};

export type HistoricMemberCountResponse = {
  readonly __typename?: 'HistoricMemberCountResponse';
  readonly results: ReadonlyArray<HistoricMemberCount>;
};

export type IcalResponse = {
  readonly __typename?: 'IcalResponse';
  readonly link: Scalars['String']['output'];
};

export type InAppNotification = {
  readonly __typename?: 'InAppNotification';
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly message: Maybe<Scalars['String']['output']>;
  readonly seen: Scalars['Boolean']['output'];
  readonly type: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type IsEventRegistrationAllowedResponse = {
  readonly __typename?: 'IsEventRegistrationAllowedResponse';
  readonly denyReason: Maybe<EventRegistrationDenyReason>;
  readonly expectedRegistrationState: Maybe<RegistrationState>;
  readonly isAllowed: Scalars['Boolean']['output'];
};

export type IsEventRegistrationCancellationAllowedResponse = {
  readonly __typename?: 'IsEventRegistrationCancellationAllowedResponse';
  readonly denyReason: Maybe<EventRegistrationCancellationDenyReason>;
  readonly isAllowed: Scalars['Boolean']['output'];
  readonly isLateCancellation: Maybe<Scalars['Boolean']['output']>;
};

export type IsPersonDeactivatable = {
  readonly __typename?: 'IsPersonDeactivatable';
  readonly denyReason: Maybe<IsPersonDeactivatableDenyReason>;
  readonly isDeactivatable: Scalars['Boolean']['output'];
};

export enum IsPersonDeactivatableDenyReason {
  HasActiveAgreements = 'HAS_ACTIVE_AGREEMENTS',
  HasPendingAgreementsRequests = 'HAS_PENDING_AGREEMENTS_REQUESTS',
  HasPermissions = 'HAS_PERMISSIONS',
  IsCurrentPerson = 'IS_CURRENT_PERSON',
  IsOfDifferentUser = 'IS_OF_DIFFERENT_USER',
  IsOrganisationOwner = 'IS_ORGANISATION_OWNER'
}

export enum IsWebPushEndpointSubscribed {
  False = 'FALSE',
  OtherUser = 'OTHER_USER',
  ThisUser = 'THIS_USER'
}

export type LatestInAppNotificationsResponse = {
  readonly __typename?: 'LatestInAppNotificationsResponse';
  readonly results: ReadonlyArray<InAppNotification>;
};

export type LatestOrganisationLogEntriesResponse = {
  readonly __typename?: 'LatestOrganisationLogEntriesResponse';
  readonly results: ReadonlyArray<OrganisationLogEntry>;
};

export enum LocationEquipmentTypes {
  Cloakrooms = 'CLOAKROOMS',
  ParkingLot = 'PARKING_LOT',
  Pool = 'POOL',
  Restrooms = 'RESTROOMS',
  Shop = 'SHOP',
  Showers = 'SHOWERS'
}

export type Me = {
  readonly __typename?: 'Me';
  readonly birthday: Maybe<Scalars['LocalDate']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly cityLine: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly emailAddress: Scalars['String']['output'];
  readonly firstname: Maybe<Scalars['String']['output']>;
  readonly isDeactivatable: IsPersonDeactivatable;
  readonly lastname: Maybe<Scalars['String']['output']>;
  readonly memberPermissions: ReadonlyArray<MemberPermission>;
  readonly memberUUID: Maybe<Scalars['UUID']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly streetAddress: Maybe<Scalars['String']['output']>;
  readonly username: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type MemberEmail = {
  readonly __typename?: 'MemberEmail';
  readonly contentHtml: Scalars['String']['output'];
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly sender: Maybe<PersonProtected>;
  readonly sentDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly state: MemberEmailState;
  readonly subject: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export enum MemberEmailState {
  Queued = 'QUEUED',
  Sent = 'SENT'
}

export type MemberEmailsFilterInput = {
  readonly memberUUID: Scalars['UUID']['input'];
};

export type MemberFull = {
  readonly __typename?: 'MemberFull';
  readonly birthday: Maybe<Scalars['LocalDate']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly cityLine: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly emailAddress: Maybe<Scalars['String']['output']>;
  readonly firstname: Maybe<Scalars['String']['output']>;
  readonly lastname: Maybe<Scalars['String']['output']>;
  readonly organisation: Organisation;
  readonly personUUID: Scalars['UUID']['output'];
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly state: MemberState;
  readonly streetAddress: Maybe<Scalars['String']['output']>;
  readonly username: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export enum MemberPermission {
  EventManager = 'EVENT_MANAGER',
  EventRegistrationManager = 'EVENT_REGISTRATION_MANAGER',
  MembershipAgreementManager = 'MEMBERSHIP_AGREEMENT_MANAGER',
  MemberManager = 'MEMBER_MANAGER',
  OrganisationManager = 'ORGANISATION_MANAGER',
  WorkoutManager = 'WORKOUT_MANAGER'
}

export type MemberProtected = {
  readonly __typename?: 'MemberProtected';
  readonly person: PersonProtected;
  readonly uuid: Scalars['UUID']['output'];
};

export type MemberPublic = {
  readonly __typename?: 'MemberPublic';
  readonly person: PersonPublic;
  readonly uuid: Scalars['UUID']['output'];
};

export type MemberRole = {
  readonly __typename?: 'MemberRole';
  readonly memberPermissions: ReadonlyArray<MemberPermission>;
  readonly members: ReadonlyArray<MemberFull>;
  readonly name: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type MemberRoleInput = {
  readonly memberPermissions: ReadonlyArray<MemberPermission>;
  readonly name: Scalars['String']['input'];
};

export type MemberRolesResponse = {
  readonly __typename?: 'MemberRolesResponse';
  readonly results: ReadonlyArray<MemberRole>;
};

export enum MemberState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type MemberWithoutEventRegistrations = {
  readonly __typename?: 'MemberWithoutEventRegistrations';
  readonly lastRegistrationDate: Scalars['ZonedDateTime']['output'];
  readonly member: MemberFull;
};

export type MembersFilterInput = {
  readonly state: InputMaybe<MemberState>;
};

export type MembersResponse = {
  readonly __typename?: 'MembersResponse';
  readonly results: ReadonlyArray<MemberFull>;
};

export type MembersWithoutEventRegistrationsInput = {
  readonly days: Scalars['Int']['input'];
};

export type MembersWithoutEventRegistrationsResponse = {
  readonly __typename?: 'MembersWithoutEventRegistrationsResponse';
  readonly result: ReadonlyArray<MemberWithoutEventRegistrations>;
};

export type MembershipAgreement = {
  readonly __typename?: 'MembershipAgreement';
  readonly actionLogEntries: Maybe<ReadonlyArray<MembershipAgreementActionLogEntry>>;
  readonly activationDate: Scalars['ZonedDateTime']['output'];
  readonly attendanceRestriction: MembershipAgreementAttendance;
  readonly billing: MembershipAgreementBilling;
  readonly blockedCredits: Scalars['Float']['output'];
  readonly commitment: MembershipAgreementCommitment;
  readonly contractor: MemberFull;
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly description: Maybe<Scalars['String']['output']>;
  readonly endDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly isCustomized: Scalars['Boolean']['output'];
  readonly isFollowUpAllowed: Scalars['Boolean']['output'];
  readonly membershipRequest: MembershipRequest;
  readonly name: Scalars['String']['output'];
  readonly nextEndDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly nextMembershipAgreement: Maybe<MembershipAgreement>;
  readonly nextPaymentDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly nextTerminationDeadline: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly organisation: Organisation;
  readonly paymentMethods: ReadonlyArray<SelectedMembershipAgreementPaymentMethod>;
  readonly previousMembershipAgreement: Maybe<MembershipAgreement>;
  readonly reference: Scalars['String']['output'];
  readonly renewalPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly runtime: Maybe<Scalars['PeriodDuration']['output']>;
  readonly specialTerminationUntil: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly startDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly state: MembershipAgreementState;
  readonly subtitle: Maybe<Scalars['String']['output']>;
  readonly suspensionDetails: ReadonlyArray<SuspensionDetails>;
  readonly terminateAfterUse: Scalars['Boolean']['output'];
  readonly terminationPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly usedCredits: Scalars['Float']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export enum MembershipAgreementAction {
  ForceTermination = 'FORCE_TERMINATION',
  RequestTermination = 'REQUEST_TERMINATION',
  Resume = 'RESUME',
  RevokeTerminationRequest = 'REVOKE_TERMINATION_REQUEST',
  Suspend = 'SUSPEND',
  Switch = 'SWITCH'
}

export type MembershipAgreementActionLogEntry = {
  readonly __typename?: 'MembershipAgreementActionLogEntry';
  readonly creator: Maybe<PersonProtected>;
  readonly date: Scalars['ZonedDateTime']['output'];
  readonly type: MembershipAgreementActionLogEntryType;
  readonly uuid: Scalars['UUID']['output'];
};

export enum MembershipAgreementActionLogEntryType {
  Accepted = 'ACCEPTED',
  Activated = 'ACTIVATED',
  Approved = 'APPROVED',
  ChangeRequested = 'CHANGE_REQUESTED',
  Created = 'CREATED',
  Refused = 'REFUSED',
  Rejected = 'REJECTED',
  Resumed = 'RESUMED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  TerminationForced = 'TERMINATION_FORCED',
  TerminationRequested = 'TERMINATION_REQUESTED',
  TerminationRevoked = 'TERMINATION_REVOKED',
  Updated = 'UPDATED',
  Withdrawn = 'WITHDRAWN'
}

export type MembershipAgreementAttendance = {
  readonly __typename?: 'MembershipAgreementAttendance';
  readonly count: Scalars['Float']['output'];
  readonly timeUnit: AttendanceTimeUnit;
  readonly type: AttendanceType;
};

export type MembershipAgreementBilling = {
  readonly __typename?: 'MembershipAgreementBilling';
  readonly amount: Scalars['Float']['output'];
  readonly cycle: BillingCycle;
};

export enum MembershipAgreementCommitment {
  Fixed = 'FIXED',
  LongTerm = 'LONG_TERM'
}

export type MembershipAgreementFiltersResponse = {
  readonly __typename?: 'MembershipAgreementFiltersResponse';
  readonly membershipAgreementNames: ReadonlyArray<Scalars['String']['output']>;
  readonly membershipAgreementTemplates: ReadonlyArray<MembershipAgreementTemplate>;
  readonly references: ReadonlyArray<Scalars['String']['output']>;
};

export type MembershipAgreementPaymentMethod = {
  readonly __typename?: 'MembershipAgreementPaymentMethod';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly type: MembershipAgreementPaymentMethodType;
  readonly uuid: Scalars['UUID']['output'];
};

export type MembershipAgreementPaymentMethodInput = {
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly type: MembershipAgreementPaymentMethodType;
};

export enum MembershipAgreementPaymentMethodType {
  Cash = 'CASH',
  PaypalIndividual = 'PAYPAL_INDIVIDUAL',
  Transfer = 'TRANSFER'
}

export type MembershipAgreementPaymentMethodsResponse = {
  readonly __typename?: 'MembershipAgreementPaymentMethodsResponse';
  readonly results: ReadonlyArray<MembershipAgreementPaymentMethod>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MembershipAgreementStartsByMonthReport = {
  readonly __typename?: 'MembershipAgreementStartsByMonthReport';
  readonly count: Scalars['Int']['output'];
  readonly month: Scalars['Int']['output'];
  readonly templateName: Scalars['String']['output'];
};

export type MembershipAgreementStartsByMonthReportInput = {
  readonly year: Scalars['Int']['input'];
};

export type MembershipAgreementStartsByMonthReportResponse = {
  readonly __typename?: 'MembershipAgreementStartsByMonthReportResponse';
  readonly results: ReadonlyArray<MembershipAgreementStartsByMonthReport>;
};

export enum MembershipAgreementState {
  Active = 'ACTIVE',
  Followed = 'FOLLOWED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  TerminationForced = 'TERMINATION_FORCED',
  TerminationRequested = 'TERMINATION_REQUESTED'
}

export type MembershipAgreementTemplate = {
  readonly __typename?: 'MembershipAgreementTemplate';
  readonly approvalConfiguration: ApprovalConfiguration;
  readonly attendanceRestriction: MembershipAgreementTemplateAttendanceRestriction;
  readonly billing: MembershipAgreementTemplateBilling;
  readonly commitment: MembershipAgreementCommitment;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly isAllowedForFollowUp: Scalars['Boolean']['output'];
  readonly isFollowUpAllowed: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly organisation: Organisation;
  readonly paymentMethods: ReadonlyArray<SelectedMembershipAgreementPaymentMethod>;
  readonly reference: Scalars['String']['output'];
  readonly renewalPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly requiredPersonalInformationValues: ReadonlyArray<PersonalInformationValue>;
  readonly runtime: Maybe<Scalars['PeriodDuration']['output']>;
  readonly sortPosition: Maybe<Scalars['Int']['output']>;
  readonly specialTerminationPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly state: MembershipAgreementTemplateState;
  readonly subtitle: Maybe<Scalars['String']['output']>;
  readonly terminateAfterUse: Scalars['Boolean']['output'];
  readonly terminationPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly uuid: Scalars['UUID']['output'];
};

export type MembershipAgreementTemplateAttendanceRestriction = {
  readonly __typename?: 'MembershipAgreementTemplateAttendanceRestriction';
  readonly count: Scalars['Float']['output'];
  readonly timeUnit: AttendanceTimeUnit;
  readonly type: AttendanceType;
};

export type MembershipAgreementTemplateAttendanceRestrictionInput = {
  readonly count: Scalars['Float']['input'];
  readonly timeUnit: AttendanceTimeUnit;
  readonly type: AttendanceType;
};

export type MembershipAgreementTemplateBilling = {
  readonly __typename?: 'MembershipAgreementTemplateBilling';
  readonly amount: Scalars['Float']['output'];
  readonly cycle: BillingCycle;
};

export type MembershipAgreementTemplateBillingInput = {
  readonly amount: Scalars['Float']['input'];
  readonly cycle: BillingCycle;
};

export type MembershipAgreementTemplateInput = {
  readonly approvalConfigurationUUID: Scalars['UUID']['input'];
  readonly attendanceRestriction: MembershipAgreementTemplateAttendanceRestrictionInput;
  readonly billing: MembershipAgreementTemplateBillingInput;
  readonly commitment: MembershipAgreementCommitment;
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly isAllowedForFollowUp: Scalars['Boolean']['input'];
  readonly isFollowUpAllowed: Scalars['Boolean']['input'];
  readonly name: Scalars['String']['input'];
  readonly paymentMethods: ReadonlyArray<SelectedMembershipAgreementPaymentMethodInput>;
  readonly reference: Scalars['String']['input'];
  readonly renewalPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly requiredPersonalInformationValues: ReadonlyArray<PersonalInformationValue>;
  readonly runtime: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly specialTerminationPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly subtitle: InputMaybe<Scalars['String']['input']>;
  readonly terminateAfterUse: Scalars['Boolean']['input'];
  readonly terminationPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
};

export enum MembershipAgreementTemplateState {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type MembershipAgreementsFilterInput = {
  readonly memberUUID: InputMaybe<Scalars['UUID']['input']>;
  readonly membershipAgreementTemplateUUID: InputMaybe<Scalars['UUID']['input']>;
  readonly personName: InputMaybe<Scalars['String']['input']>;
  readonly reference: InputMaybe<Scalars['String']['input']>;
  readonly states: InputMaybe<ReadonlyArray<MembershipAgreementState>>;
};

export type MembershipRequest = {
  readonly __typename?: 'MembershipRequest';
  readonly actionLogEntries: ReadonlyArray<MembershipAgreementActionLogEntry>;
  readonly approvalCount: Scalars['Int']['output'];
  readonly approvalFacts: ReadonlyArray<ApprovalFact>;
  readonly attendanceRestriction: MembershipRequestAttendanceRestriction;
  readonly billing: MembershipRequestBilling;
  readonly commitment: MembershipAgreementCommitment;
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly creator: MemberFull;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly endDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly isCustomized: Scalars['Boolean']['output'];
  readonly isFollowUp: Scalars['Boolean']['output'];
  readonly isFollowUpAllowed: Scalars['Boolean']['output'];
  readonly membershipAgreementTemplate: MembershipAgreementTemplate;
  readonly modified: Scalars['ZonedDateTime']['output'];
  readonly name: Scalars['String']['output'];
  readonly paymentMethods: ReadonlyArray<SelectedMembershipAgreementPaymentMethod>;
  readonly previousMembershipAgreement: Maybe<MembershipAgreement>;
  readonly reference: Scalars['String']['output'];
  readonly renewalPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly runtime: Maybe<Scalars['PeriodDuration']['output']>;
  readonly specialTerminationPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly startDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly state: MembershipRequestState;
  readonly subtitle: Maybe<Scalars['String']['output']>;
  readonly terminateAfterUse: Scalars['Boolean']['output'];
  readonly terminationPeriod: Maybe<Scalars['PeriodDuration']['output']>;
  readonly uuid: Scalars['UUID']['output'];
};

export enum MembershipRequestAction {
  Accept = 'ACCEPT',
  Approve = 'APPROVE',
  Delete = 'DELETE',
  Refuse = 'REFUSE',
  Reject = 'REJECT',
  RequestChange = 'REQUEST_CHANGE',
  Update = 'UPDATE',
  Withdraw = 'WITHDRAW'
}

export type MembershipRequestAttendanceRestriction = {
  readonly __typename?: 'MembershipRequestAttendanceRestriction';
  readonly count: Scalars['Float']['output'];
  readonly timeUnit: AttendanceTimeUnit;
  readonly type: AttendanceType;
};

export type MembershipRequestAttendanceRestrictionInput = {
  readonly count: Scalars['Float']['input'];
  readonly timeUnit: AttendanceTimeUnit;
  readonly type: AttendanceType;
};

export type MembershipRequestBilling = {
  readonly __typename?: 'MembershipRequestBilling';
  readonly amount: Scalars['Float']['output'];
  readonly cycle: BillingCycle;
};

export type MembershipRequestBillingInput = {
  readonly amount: Scalars['Float']['input'];
  readonly cycle: BillingCycle;
};

export type MembershipRequestInput = {
  readonly attendanceRestriction: MembershipRequestAttendanceRestrictionInput;
  readonly billing: MembershipRequestBillingInput;
  readonly commitment: MembershipAgreementCommitment;
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly endDate: InputMaybe<Scalars['LocalDate']['input']>;
  readonly isFollowUpAllowed: Scalars['Boolean']['input'];
  readonly name: Scalars['String']['input'];
  readonly reference: Scalars['String']['input'];
  readonly renewalPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly runtime: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly specialTerminationPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
  readonly startDate: InputMaybe<Scalars['LocalDate']['input']>;
  readonly subtitle: InputMaybe<Scalars['String']['input']>;
  readonly terminateAfterUse: Scalars['Boolean']['input'];
  readonly terminationPeriod: InputMaybe<Scalars['PeriodDuration']['input']>;
};

export enum MembershipRequestState {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  MemberAcceptancePending = 'MEMBER_ACCEPTANCE_PENDING',
  OwnerApprovalPending = 'OWNER_APPROVAL_PENDING',
  Refused = 'REFUSED',
  Rejected = 'REJECTED',
  Withdrawn = 'WITHDRAWN'
}

export type MembershipRequestsFilterInput = {
  readonly states: InputMaybe<ReadonlyArray<MembershipRequestState>>;
};

export type MembershipRequestsResponse = {
  readonly __typename?: 'MembershipRequestsResponse';
  readonly results: ReadonlyArray<MembershipRequest>;
  readonly totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly acceptMembershipAgreementRequest: AcceptMembershipRequestResponse;
  readonly acceptMembershipRequest: AcceptMembershipRequestResponse;
  readonly addMemberRoleToMembers: MemberRole;
  readonly approveMembershipAgreementRequest: ApproveMembershipRequestResponse;
  readonly approveMembershipRequest: ApproveMembershipRequestResponse;
  readonly cancelEvent: Event;
  readonly cancelEventRegistration: Scalars['Boolean']['output'];
  readonly confirmEmailAddressUpdate: Scalars['Boolean']['output'];
  readonly confirmEvent: Event;
  readonly confirmEventRegistration: Scalars['Boolean']['output'];
  readonly createApprovalConfiguration: ApprovalConfiguration;
  readonly createBenchmark: Benchmark;
  readonly createBenchmarkResult: BenchmarkResult;
  readonly createEmailAddressUpdate: Scalars['Boolean']['output'];
  readonly createEventDetails: EventDetails;
  readonly createEventRegistration: Registration;
  readonly createMemberRole: MemberRole;
  readonly createMembershipAgreementPaymentMethod: MembershipAgreementPaymentMethod;
  readonly createMembershipAgreementRequestFromTemplate: CreateMembershipRequestFromTemplateResponse;
  readonly createMembershipAgreementRequestFromTemplateForFollowUp: MembershipRequest;
  readonly createMembershipAgreementTemplate: MembershipAgreementTemplate;
  readonly createMembershipRequestFromTemplate: CreateMembershipRequestFromTemplateResponse;
  readonly createMembershipRequestFromTemplateForFollowUp: MembershipRequest;
  readonly createOrganisationFeedPost: OrganisationFeedPost;
  readonly createOrganisationFeedPostComment: OrganisationFeedPost;
  readonly createOrganisationFeedPostReaction: OrganisationFeedPost;
  readonly createPasswordReset: Scalars['Boolean']['output'];
  readonly createVisitorEventRegistration: Registration;
  readonly createWebPushSubscription: WebPushSubscription;
  readonly createWorkout: Workout;
  readonly createWorkoutResult: WorkoutResult;
  readonly createWorkoutResultCategory: WorkoutResultCategory;
  readonly createWorkoutResultReaction: WorkoutResultReaction;
  readonly createWorkoutType: WorkoutType;
  readonly deleteBenchmark: Scalars['Boolean']['output'];
  readonly deleteBenchmarkResult: Scalars['Boolean']['output'];
  readonly deleteEvent: Scalars['Boolean']['output'];
  readonly deleteEventAndEndRecurrence: Scalars['Boolean']['output'];
  readonly deleteEventRegistration: Scalars['Boolean']['output'];
  readonly deleteMemberRole: Scalars['Boolean']['output'];
  readonly deleteMembershipAgreementRequest: Scalars['Boolean']['output'];
  readonly deleteMembershipAgreementTemplate: Scalars['Boolean']['output'];
  readonly deleteMembershipRequest: Scalars['Boolean']['output'];
  readonly deleteOrganisationFeedPost: Scalars['Boolean']['output'];
  readonly deleteOrganisationFeedPostComment: OrganisationFeedPost;
  readonly deleteOrganisationFeedPostReaction: OrganisationFeedPost;
  readonly deleteWebPushSubscription: Scalars['Boolean']['output'];
  readonly deleteWorkout: Scalars['Boolean']['output'];
  readonly deleteWorkoutResult: Scalars['Boolean']['output'];
  readonly deleteWorkoutResultReaction: Scalars['Boolean']['output'];
  readonly endPenalty: Penalty;
  readonly forceMembershipAgreementTermination: MembershipAgreement;
  readonly markAllInAppNotificationAsSeen: Scalars['Boolean']['output'];
  readonly markInAppNotificationAsSeen: Scalars['Boolean']['output'];
  readonly publishMembershipAgreementTemplate: MembershipAgreementTemplate;
  readonly refuseMembershipAgreementRequest: MembershipRequest;
  readonly refuseMembershipRequest: MembershipRequest;
  readonly rejectMembershipAgreementRequest: MembershipRequest;
  readonly rejectMembershipRequest: MembershipRequest;
  readonly removeMemberRoleFromMember: MemberRole;
  readonly requestMembershipAgreementRequestChange: MembershipRequest;
  readonly requestMembershipAgreementTermination: MembershipAgreement;
  readonly requestMembershipRequestChange: MembershipRequest;
  readonly resetPassword: Scalars['Boolean']['output'];
  readonly resumeMembershipAgreement: MembershipAgreement;
  readonly revokeMembershipAgreementTerminationRequest: MembershipAgreement;
  readonly sendMemberEmail: Scalars['Boolean']['output'];
  readonly signUp: Scalars['Boolean']['output'];
  readonly suspendMembershipAgreement: MembershipAgreement;
  readonly switchEventRegistration: Registration;
  readonly unpublishMembershipAgreementTemplate: MembershipAgreementTemplate;
  readonly updateApprovalConfiguration: ApprovalConfiguration;
  readonly updateBenchmark: Benchmark;
  readonly updateBenchmarkResult: BenchmarkResult;
  readonly updateDataProtection: Organisation;
  readonly updateEmailPreferences: Scalars['Boolean']['output'];
  readonly updateEquipment: Equipment;
  readonly updateEvent: Event;
  readonly updateEventDetails: Event;
  readonly updateEventRegistration: Registration;
  readonly updateEventRegistrationToAttended: Scalars['Boolean']['output'];
  readonly updateEventRegistrationToMissed: Scalars['Boolean']['output'];
  readonly updateGoogleMapsSettings: GoogleMapsSettings;
  readonly updateMemberRole: MemberRole;
  readonly updateMembershipAgreementPaymentMethod: MembershipAgreementPaymentMethod;
  readonly updateMembershipAgreementRequest: MembershipRequest;
  readonly updateMembershipAgreementTemplate: MembershipAgreementTemplate;
  readonly updateMembershipRequest: MembershipRequest;
  readonly updateOrganisationFeedPost: OrganisationFeedPost;
  readonly updateOrganisationFeedPostComment: OrganisationFeedPost;
  readonly updateOrganisationInfo: Organisation;
  readonly updatePassword: Scalars['Boolean']['output'];
  readonly updatePerson: Person;
  readonly updateTermsOfService: Organisation;
  readonly updateUnseenOrganisationFeedActivities: Scalars['Boolean']['output'];
  readonly updateVisitorEventRegistration: Registration;
  readonly updateVisitorEventRegistrationNew: Registration;
  readonly updateWorkout: Workout;
  readonly updateWorkoutResult: WorkoutResult;
  readonly updateWorkoutResultCategory: WorkoutResultCategory;
  readonly updateWorkoutType: WorkoutType;
  readonly withdrawMembershipAgreementRequest: MembershipRequest;
  readonly withdrawMembershipRequest: MembershipRequest;
};


export type MutationAcceptMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationAcceptMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationAddMemberRoleToMembersArgs = {
  input: AddMemberRoleToMembersInput;
};


export type MutationApproveMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationApproveMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationCancelEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationCancelEventRegistrationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationConfirmEmailAddressUpdateArgs = {
  input: ConfirmEmailAddressUpdateInput;
};


export type MutationConfirmEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationConfirmEventRegistrationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationCreateApprovalConfigurationArgs = {
  input: ApprovalConfigurationInput;
};


export type MutationCreateBenchmarkArgs = {
  input: BenchmarkInput;
};


export type MutationCreateBenchmarkResultArgs = {
  benchmarkUUID: Scalars['UUID']['input'];
  input: BenchmarkResultInput;
};


export type MutationCreateEmailAddressUpdateArgs = {
  input: CreateEmailAddressUpdateInput;
};


export type MutationCreateEventDetailsArgs = {
  input: EventDetailsInput;
};


export type MutationCreateEventRegistrationArgs = {
  eventId: Scalars['String']['input'];
  input: CreateEventRegistrationInput;
};


export type MutationCreateMemberRoleArgs = {
  input: MemberRoleInput;
};


export type MutationCreateMembershipAgreementPaymentMethodArgs = {
  input: MembershipAgreementPaymentMethodInput;
};


export type MutationCreateMembershipAgreementRequestFromTemplateArgs = {
  membershipAgreementTemplateUUID: Scalars['UUID']['input'];
};


export type MutationCreateMembershipAgreementRequestFromTemplateForFollowUpArgs = {
  membershipAgreementTemplateUUID: Scalars['UUID']['input'];
  membershipAgreementUUID: Scalars['UUID']['input'];
};


export type MutationCreateMembershipAgreementTemplateArgs = {
  input: MembershipAgreementTemplateInput;
};


export type MutationCreateMembershipRequestFromTemplateArgs = {
  membershipAgreementTemplateUUID: Scalars['UUID']['input'];
};


export type MutationCreateMembershipRequestFromTemplateForFollowUpArgs = {
  membershipAgreementTemplateUUID: Scalars['UUID']['input'];
  membershipAgreementUUID: Scalars['UUID']['input'];
};


export type MutationCreateOrganisationFeedPostArgs = {
  input: CreateOrganisationFeedPostInput;
};


export type MutationCreateOrganisationFeedPostCommentArgs = {
  input: CreateOrganisationFeedPostCommentInput;
  postUUID: Scalars['UUID']['input'];
};


export type MutationCreateOrganisationFeedPostReactionArgs = {
  postUUID: Scalars['ID']['input'];
  reactionInput: CreateOrganisationFeedPostReactionInput;
};


export type MutationCreatePasswordResetArgs = {
  input: CreatePasswordResetInput;
};


export type MutationCreateVisitorEventRegistrationArgs = {
  eventId: Scalars['String']['input'];
  input: CreateVisitorEventRegistrationInput;
};


export type MutationCreateWebPushSubscriptionArgs = {
  input: WebPushSubscriptionInput;
};


export type MutationCreateWorkoutArgs = {
  input: WorkoutInput;
};


export type MutationCreateWorkoutResultArgs = {
  input: WorkoutResultInput;
  workoutScoreBoardUUID: Scalars['UUID']['input'];
};


export type MutationCreateWorkoutResultCategoryArgs = {
  input: WorkoutResultCategoryInput;
};


export type MutationCreateWorkoutResultReactionArgs = {
  input: WorkoutResultReactionInput;
  workoutResultUUID: Scalars['UUID']['input'];
};


export type MutationCreateWorkoutTypeArgs = {
  input: WorkoutTypeInput;
};


export type MutationDeleteBenchmarkArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteBenchmarkResultArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationDeleteEventAndEndRecurrenceArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationDeleteEventRegistrationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteMemberRoleArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteMembershipAgreementTemplateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteOrganisationFeedPostArgs = {
  postUUID: Scalars['ID']['input'];
};


export type MutationDeleteOrganisationFeedPostCommentArgs = {
  commentUUID: Scalars['ID']['input'];
};


export type MutationDeleteOrganisationFeedPostReactionArgs = {
  reactionUUID: Scalars['ID']['input'];
};


export type MutationDeleteWebPushSubscriptionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteWorkoutArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteWorkoutResultArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteWorkoutResultReactionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationEndPenaltyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationForceMembershipAgreementTerminationArgs = {
  endDate: InputMaybe<Scalars['LocalDate']['input']>;
  uuid: Scalars['UUID']['input'];
};


export type MutationMarkInAppNotificationAsSeenArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationPublishMembershipAgreementTemplateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRefuseMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRefuseMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRejectMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRejectMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveMemberRoleFromMemberArgs = {
  input: RemoveMemberRoleFromMemberInput;
};


export type MutationRequestMembershipAgreementRequestChangeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRequestMembershipAgreementTerminationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRequestMembershipRequestChangeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResumeMembershipAgreementArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRevokeMembershipAgreementTerminationRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationSendMemberEmailArgs = {
  input: SendMemberEmailInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSuspendMembershipAgreementArgs = {
  input: SuspensionDetailsInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationSwitchEventRegistrationArgs = {
  eventId: Scalars['String']['input'];
  registrationUUID: Scalars['UUID']['input'];
};


export type MutationUnpublishMembershipAgreementTemplateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateApprovalConfigurationArgs = {
  input: ApprovalConfigurationInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateBenchmarkArgs = {
  input: BenchmarkInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateBenchmarkResultArgs = {
  input: BenchmarkResultInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateDataProtectionArgs = {
  input: DataProtectionInput;
};


export type MutationUpdateEmailPreferencesArgs = {
  input: EmailPreferencesInput;
};


export type MutationUpdateEquipmentArgs = {
  input: UpdateEquipmentInput;
};


export type MutationUpdateEventArgs = {
  eventId: Scalars['String']['input'];
  input: UpdateEventInput;
};


export type MutationUpdateEventDetailsArgs = {
  eventId: Scalars['String']['input'];
  input: EventDetailsInput;
};


export type MutationUpdateEventRegistrationArgs = {
  input: UpdateEventRegistrationInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateEventRegistrationToAttendedArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateEventRegistrationToMissedArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateGoogleMapsSettingsArgs = {
  input: UpdateGoogleMapsSettingsInput;
};


export type MutationUpdateMemberRoleArgs = {
  input: MemberRoleInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateMembershipAgreementPaymentMethodArgs = {
  input: MembershipAgreementPaymentMethodInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateMembershipAgreementRequestArgs = {
  input: MembershipRequestInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateMembershipAgreementTemplateArgs = {
  input: MembershipAgreementTemplateInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateMembershipRequestArgs = {
  input: MembershipRequestInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateOrganisationFeedPostArgs = {
  postInput: UpdateOrganisationFeedPostInput;
  postUUID: Scalars['ID']['input'];
};


export type MutationUpdateOrganisationFeedPostCommentArgs = {
  commentInput: UpdateOrganisationFeedPostCommentInput;
  commentUUID: Scalars['ID']['input'];
};


export type MutationUpdateOrganisationInfoArgs = {
  input: OrganisationInfoInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdatePersonArgs = {
  personInput: PersonInput;
};


export type MutationUpdateTermsOfServiceArgs = {
  input: TermsOfServiceInput;
};


export type MutationUpdateVisitorEventRegistrationArgs = {
  input: UpdateEventVisitorRegistrationInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateVisitorEventRegistrationNewArgs = {
  input: UpdateEventVisitorRegistrationInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateWorkoutArgs = {
  input: WorkoutInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateWorkoutResultArgs = {
  input: WorkoutResultInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateWorkoutResultCategoryArgs = {
  input: WorkoutResultCategoryInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateWorkoutTypeArgs = {
  input: WorkoutTypeInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationWithdrawMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationWithdrawMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MyBenchmarksFilterInput = {
  readonly resultType: InputMaybe<BenchmarkResultType>;
};

export type MyBenchmarksResponse = {
  readonly __typename?: 'MyBenchmarksResponse';
  readonly results: ReadonlyArray<Benchmark>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MyBilledEventRegistrationsFilterInput = {
  readonly membershipAgreementUUID: InputMaybe<Scalars['UUID']['input']>;
};

export type MyBilledEventRegistrationsResponse = {
  readonly __typename?: 'MyBilledEventRegistrationsResponse';
  readonly hasNext: Scalars['Boolean']['output'];
  readonly results: ReadonlyArray<BilledEventRegistration>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MyEmailPreferencesResponse = {
  readonly __typename?: 'MyEmailPreferencesResponse';
  readonly entries: ReadonlyArray<EmailPreference>;
};

export type MyEventRegistration = {
  readonly __typename?: 'MyEventRegistration';
  readonly comment: Maybe<Scalars['String']['output']>;
  readonly event: Event;
  readonly state: RegistrationState;
  readonly uuid: Scalars['UUID']['output'];
};

export type MyEventRegistrationsFilterInput = {
  readonly end: InputMaybe<Scalars['ZonedDateTime']['input']>;
  readonly eventId: InputMaybe<Scalars['String']['input']>;
  readonly start: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type MyEventRegistrationsResponse = {
  readonly __typename?: 'MyEventRegistrationsResponse';
  readonly results: ReadonlyArray<MyEventRegistration>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MyMembershipAgreementsFilterInput = {
  readonly states: InputMaybe<ReadonlyArray<MembershipAgreementState>>;
};

export type MyMembershipAgreementsResponse = {
  readonly __typename?: 'MyMembershipAgreementsResponse';
  readonly results: ReadonlyArray<MembershipAgreement>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MyMembershipRequestsFilterInput = {
  readonly states: InputMaybe<ReadonlyArray<MembershipRequestState>>;
};

export type MyMembershipRequestsResponse = {
  readonly __typename?: 'MyMembershipRequestsResponse';
  readonly results: ReadonlyArray<MembershipRequest>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MyPenaltiesFilterInput = {
  readonly active: InputMaybe<Scalars['Boolean']['input']>;
};

export type MyPenaltiesResponse = {
  readonly __typename?: 'MyPenaltiesResponse';
  readonly list: ReadonlyArray<Penalty>;
};

export type MyPenaltyRemarksResponse = {
  readonly __typename?: 'MyPenaltyRemarksResponse';
  readonly list: ReadonlyArray<PenaltyRemark>;
};

export enum NotificationScope {
  Agreement = 'AGREEMENT',
  AgreementManager = 'AGREEMENT_MANAGER',
  Event = 'EVENT',
  EventManager = 'EVENT_MANAGER',
  EventRegistration = 'EVENT_REGISTRATION'
}

export type Organisation = {
  readonly __typename?: 'Organisation';
  readonly about: Maybe<Scalars['String']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly cityLine: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly dataProtectionHtml: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly facebookUrl: Maybe<Scalars['String']['output']>;
  readonly instagramUrl: Maybe<Scalars['String']['output']>;
  readonly mapsUrl: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly nameSlug: Scalars['String']['output'];
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly shopDescription: Maybe<Scalars['String']['output']>;
  readonly shopUrl: Maybe<Scalars['String']['output']>;
  readonly shortDescription: Maybe<Scalars['String']['output']>;
  readonly streetAddress: Maybe<Scalars['String']['output']>;
  readonly termsOfServiceHtml: Maybe<Scalars['String']['output']>;
  readonly timezone: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
  readonly websiteUrl: Maybe<Scalars['String']['output']>;
  readonly whatsAppNumber: Maybe<Scalars['String']['output']>;
};

export type OrganisationByNameSlugResponse = {
  readonly __typename?: 'OrganisationByNameSlugResponse';
  readonly logoSquare: Maybe<CloudinaryFile>;
  readonly logoSquareNew: Maybe<CloudinaryFile>;
  readonly name: Scalars['String']['output'];
  readonly nameSlug: Scalars['String']['output'];
  readonly shortDescription: Maybe<Scalars['String']['output']>;
  readonly uuid: Scalars['UUID']['output'];
};

export type OrganisationFeedPost = {
  readonly __typename?: 'OrganisationFeedPost';
  readonly comments: ReadonlyArray<OrganisationFeedPostComment>;
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly creator: PersonPublic;
  readonly message: Scalars['String']['output'];
  readonly organisation: Organisation;
  readonly reactions: ReadonlyArray<OrganisationFeedPostReaction>;
  readonly sortDate: Scalars['ZonedDateTime']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type OrganisationFeedPostComment = {
  readonly __typename?: 'OrganisationFeedPostComment';
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly creator: PersonPublic;
  readonly message: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type OrganisationFeedPostReaction = {
  readonly __typename?: 'OrganisationFeedPostReaction';
  readonly creator: PersonPublic;
  readonly sortDate: Scalars['ZonedDateTime']['output'];
  readonly type: PostReactionType;
  readonly uuid: Scalars['UUID']['output'];
};

export type OrganisationImagesResponse = {
  readonly __typename?: 'OrganisationImagesResponse';
  readonly emailHeader: Maybe<CloudinaryFile>;
  readonly logoSquare: Maybe<CloudinaryFile>;
  readonly shopBanner: Maybe<CloudinaryFile>;
};

export type OrganisationInfoInput = {
  readonly about: InputMaybe<Scalars['String']['input']>;
  readonly city: InputMaybe<Scalars['String']['input']>;
  readonly country: InputMaybe<Scalars['String']['input']>;
  readonly email: InputMaybe<Scalars['String']['input']>;
  readonly facebookUrl: InputMaybe<Scalars['String']['input']>;
  readonly instagramUrl: InputMaybe<Scalars['String']['input']>;
  readonly mapsUrl: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly phone: InputMaybe<Scalars['String']['input']>;
  readonly postalCode: InputMaybe<Scalars['String']['input']>;
  readonly shopUrl: InputMaybe<Scalars['String']['input']>;
  readonly shortDescription: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress: InputMaybe<Scalars['String']['input']>;
  readonly websiteUrl: InputMaybe<Scalars['String']['input']>;
  readonly whatsAppNumber: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationLogEntry = {
  readonly __typename?: 'OrganisationLogEntry';
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly message: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type PaginationInput = {
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
};

export type PenaltiesFilterInput = {
  readonly active: InputMaybe<Scalars['Boolean']['input']>;
  readonly memberUUID: InputMaybe<Scalars['UUID']['input']>;
};

export type PenaltiesResponse = {
  readonly __typename?: 'PenaltiesResponse';
  readonly results: ReadonlyArray<Penalty>;
  readonly totalCount: Scalars['Int']['output'];
};

export type Penalty = {
  readonly __typename?: 'Penalty';
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly end: Scalars['ZonedDateTime']['output'];
  readonly member: MemberProtected;
  readonly uuid: Scalars['UUID']['output'];
};

export type PenaltyRemark = {
  readonly __typename?: 'PenaltyRemark';
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly end: Scalars['ZonedDateTime']['output'];
  readonly eventRegistration: Registration;
  readonly member: MemberProtected;
};

export type PenaltyRemarksFilterInput = {
  readonly memberUUID: InputMaybe<Scalars['UUID']['input']>;
};

export type PenaltyRemarksResponse = {
  readonly __typename?: 'PenaltyRemarksResponse';
  readonly result: ReadonlyArray<PenaltyRemark>;
};

export type Person = {
  readonly __typename?: 'Person';
  readonly birthday: Maybe<Scalars['LocalDate']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly cityLine: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly firstname: Maybe<Scalars['String']['output']>;
  readonly lastname: Maybe<Scalars['String']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly streetAddress: Maybe<Scalars['String']['output']>;
  readonly username: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type PersonInput = {
  readonly birthday: InputMaybe<Scalars['LocalDate']['input']>;
  readonly city: InputMaybe<Scalars['String']['input']>;
  readonly country: InputMaybe<Scalars['String']['input']>;
  readonly firstname: InputMaybe<Scalars['String']['input']>;
  readonly lastname: InputMaybe<Scalars['String']['input']>;
  readonly phone: InputMaybe<Scalars['String']['input']>;
  readonly postalCode: InputMaybe<Scalars['String']['input']>;
  readonly streetAddress: InputMaybe<Scalars['String']['input']>;
  readonly username: Scalars['String']['input'];
};

export type PersonProtected = {
  readonly __typename?: 'PersonProtected';
  readonly firstname: Maybe<Scalars['String']['output']>;
  readonly lastname: Maybe<Scalars['String']['output']>;
  readonly username: Scalars['String']['output'];
};

export type PersonPublic = {
  readonly __typename?: 'PersonPublic';
  readonly username: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export enum PersonalInformationValue {
  Address = 'ADDRESS',
  Birthday = 'BIRTHDAY',
  Phone = 'PHONE'
}

export enum PostReactionType {
  Like = 'LIKE'
}

export type Query = {
  readonly __typename?: 'Query';
  readonly activeMemberCount: Scalars['Int']['output'];
  readonly activeMembershipAgreementsReport: ActiveMembershipAgreementsReportResponse;
  readonly allowedEventRegistrationStateActionsForManager: ReadonlyArray<RegistrationStateManagerAction>;
  readonly allowedMembershipAgreementActions: ReadonlyArray<MembershipAgreementAction>;
  readonly allowedMembershipAgreementRequestApprovalActions: ReadonlyArray<MembershipRequestAction>;
  readonly allowedMembershipRequestApprovalActions: ReadonlyArray<MembershipRequestAction>;
  readonly approvalConfiguration: ApprovalConfiguration;
  readonly approvalConfigurations: ApprovalConfigurationsResponse;
  readonly benchmark: Benchmark;
  readonly billedEventRegistrations: BilledEventRegistrationsResponse;
  readonly equipment: Equipment;
  readonly event: Event;
  readonly eventRegistrationLogEntries: ReadonlyArray<RegistrationLogEntry>;
  readonly events: ReadonlyArray<Event>;
  readonly eventsForSwitch: ReadonlyArray<Event>;
  readonly generateMemberEmailPreview: GenerateMemberEmailPreviewResponse;
  readonly googleMapsSettings: GoogleMapsSettings;
  readonly hasUnseenOrganisationFeedActivities: HasUnseenOrganisationFeedActivitiesResponse;
  readonly historicMemberCount: HistoricMemberCountResponse;
  readonly ical: IcalResponse;
  readonly isEventRegistrationAllowed: IsEventRegistrationAllowedResponse;
  readonly isEventRegistrationCancellationAllowed: IsEventRegistrationCancellationAllowedResponse;
  readonly isUsernameAvailable: Scalars['Boolean']['output'];
  readonly isWebPushEndpointSubscribed: IsWebPushEndpointSubscribed;
  readonly latestInAppNotifications: LatestInAppNotificationsResponse;
  readonly latestOrganisationLogEntries: LatestOrganisationLogEntriesResponse;
  readonly me: Me;
  readonly member: MemberFull;
  readonly memberEmails: ReadonlyArray<MemberEmail>;
  readonly memberRole: MemberRole;
  readonly memberRoles: MemberRolesResponse;
  readonly members: MembersResponse;
  readonly membersWithoutEventRegistrations: MembersWithoutEventRegistrationsResponse;
  readonly membershipAgreement: MembershipAgreement;
  readonly membershipAgreementFilters: MembershipAgreementFiltersResponse;
  readonly membershipAgreementPaymentMethod: MembershipAgreementPaymentMethod;
  readonly membershipAgreementPaymentMethods: MembershipAgreementPaymentMethodsResponse;
  readonly membershipAgreementRequest: MembershipRequest;
  readonly membershipAgreementRequestCount: Scalars['Int']['output'];
  readonly membershipAgreementRequests: MembershipRequestsResponse;
  readonly membershipAgreementStartsByMonthReport: MembershipAgreementStartsByMonthReportResponse;
  readonly membershipAgreementTemplate: MembershipAgreementTemplate;
  readonly membershipAgreementTemplates: ReadonlyArray<MembershipAgreementTemplate>;
  readonly membershipAgreements: ReadonlyArray<MembershipAgreement>;
  readonly membershipRequest: MembershipRequest;
  readonly membershipRequestCount: Scalars['Int']['output'];
  readonly membershipRequests: MembershipRequestsResponse;
  readonly myBenchmarks: MyBenchmarksResponse;
  readonly myBilledEventRegistrations: MyBilledEventRegistrationsResponse;
  readonly myEmailPreferences: MyEmailPreferencesResponse;
  readonly myEventRegistrations: MyEventRegistrationsResponse;
  readonly myMembershipAgreements: MyMembershipAgreementsResponse;
  readonly myMembershipRequests: MyMembershipRequestsResponse;
  readonly myPenalties: MyPenaltiesResponse;
  readonly myPenaltyRemarks: MyPenaltyRemarksResponse;
  readonly myWebPushSubscriptions: ReadonlyArray<WebPushSubscription>;
  readonly organisation: Organisation;
  readonly organisationByNameSlug: Maybe<OrganisationByNameSlugResponse>;
  readonly organisationFeedPost: OrganisationFeedPost;
  readonly organisationFeedPosts: ReadonlyArray<OrganisationFeedPost>;
  readonly organisationImages: OrganisationImagesResponse;
  readonly penalties: PenaltiesResponse;
  readonly penaltyRemarks: PenaltyRemarksResponse;
  readonly requiredPersonalInformation: ReadonlyArray<PersonalInformationValue>;
  readonly unpublishedWorkouts: UnpublishedWorkoutsResponse;
  readonly webPushKeys: WebPushKeys;
  readonly workout: Workout;
  readonly workoutResultCategories: ReadonlyArray<WorkoutResultCategory>;
  readonly workoutResultCategory: WorkoutResultCategory;
  readonly workoutType: WorkoutType;
  readonly workoutTypes: ReadonlyArray<WorkoutType>;
  readonly workouts: WorkoutsResponse;
};


export type QueryAllowedEventRegistrationStateActionsForManagerArgs = {
  registrationUUID: Scalars['UUID']['input'];
};


export type QueryAllowedMembershipAgreementActionsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryAllowedMembershipAgreementRequestApprovalActionsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryAllowedMembershipRequestApprovalActionsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryApprovalConfigurationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryApprovalConfigurationsArgs = {
  pagination: PaginationInput;
};


export type QueryBenchmarkArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryBilledEventRegistrationsArgs = {
  filter: BilledEventRegistrationsFilterInput;
  pagination: PaginationInput;
};


export type QueryEventArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryEventRegistrationLogEntriesArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  filter: EventsFilterInput;
};


export type QueryEventsForSwitchArgs = {
  registrationUUID: Scalars['UUID']['input'];
};


export type QueryGenerateMemberEmailPreviewArgs = {
  input: GenerateMemberEmailPreviewInput;
};


export type QueryIsEventRegistrationAllowedArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryIsEventRegistrationCancellationAllowedArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryIsUsernameAvailableArgs = {
  name: Scalars['String']['input'];
};


export type QueryIsWebPushEndpointSubscribedArgs = {
  endpoint: Scalars['String']['input'];
};


export type QueryMemberArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMemberEmailsArgs = {
  filter: MemberEmailsFilterInput;
  pagination: PaginationInput;
};


export type QueryMemberRoleArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMembersArgs = {
  filter: MembersFilterInput;
};


export type QueryMembersWithoutEventRegistrationsArgs = {
  input: MembersWithoutEventRegistrationsInput;
};


export type QueryMembershipAgreementArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMembershipAgreementPaymentMethodArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMembershipAgreementPaymentMethodsArgs = {
  pagination: PaginationInput;
};


export type QueryMembershipAgreementRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMembershipAgreementRequestCountArgs = {
  states: InputMaybe<ReadonlyArray<MembershipRequestState>>;
};


export type QueryMembershipAgreementRequestsArgs = {
  filter: MembershipRequestsFilterInput;
  pagination: PaginationInput;
};


export type QueryMembershipAgreementStartsByMonthReportArgs = {
  input: MembershipAgreementStartsByMonthReportInput;
};


export type QueryMembershipAgreementTemplateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMembershipAgreementsArgs = {
  filter: MembershipAgreementsFilterInput;
};


export type QueryMembershipRequestArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryMembershipRequestCountArgs = {
  states: InputMaybe<ReadonlyArray<MembershipRequestState>>;
};


export type QueryMembershipRequestsArgs = {
  filter: MembershipRequestsFilterInput;
  pagination: PaginationInput;
};


export type QueryMyBenchmarksArgs = {
  filter: InputMaybe<MyBenchmarksFilterInput>;
  pagination: PaginationInput;
};


export type QueryMyBilledEventRegistrationsArgs = {
  filter: MyBilledEventRegistrationsFilterInput;
  pagination: PaginationInput;
};


export type QueryMyEventRegistrationsArgs = {
  filter: MyEventRegistrationsFilterInput;
  pagination: PaginationInput;
};


export type QueryMyMembershipAgreementsArgs = {
  filter: MyMembershipAgreementsFilterInput;
};


export type QueryMyMembershipRequestsArgs = {
  filter: MyMembershipRequestsFilterInput;
  pagination: PaginationInput;
};


export type QueryMyPenaltiesArgs = {
  filter: MyPenaltiesFilterInput;
};


export type QueryOrganisationByNameSlugArgs = {
  nameSlug: Scalars['String']['input'];
};


export type QueryOrganisationFeedPostArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryOrganisationFeedPostsArgs = {
  pagination: PaginationInput;
};


export type QueryPenaltiesArgs = {
  filter: PenaltiesFilterInput;
  pagination: PaginationInput;
};


export type QueryPenaltyRemarksArgs = {
  filter: PenaltyRemarksFilterInput;
  paginationInput: PaginationInput;
};


export type QueryWorkoutArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryWorkoutResultCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryWorkoutTypeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryWorkoutsArgs = {
  filter: WorkoutsFilterInput;
  pagination: PaginationInput;
};

export enum RecurrenceType {
  Daily = 'DAILY',
  None = 'NONE',
  Weekly = 'WEEKLY'
}

export type Registration = {
  readonly __typename?: 'Registration';
  readonly comment: Maybe<Scalars['String']['output']>;
  readonly event: Event;
  readonly member: Maybe<MemberPublic>;
  readonly registrationDate: Scalars['ZonedDateTime']['output'];
  readonly state: RegistrationState;
  readonly uuid: Scalars['UUID']['output'];
  readonly visitor: Maybe<Visitor>;
};

export enum RegistrationActionLogEntryType {
  MemberAttended = 'MEMBER_ATTENDED',
  MemberAttendedBy = 'MEMBER_ATTENDED_BY',
  MemberCancelled = 'MEMBER_CANCELLED',
  MemberCancelledLate = 'MEMBER_CANCELLED_LATE',
  MemberConfirmed = 'MEMBER_CONFIRMED',
  MemberConfirmedBy = 'MEMBER_CONFIRMED_BY',
  MemberCreated = 'MEMBER_CREATED',
  MemberDeletedBy = 'MEMBER_DELETED_BY',
  MemberMissedBy = 'MEMBER_MISSED_BY',
  MemberSwitchedFrom = 'MEMBER_SWITCHED_FROM',
  MemberSwitchedTo = 'MEMBER_SWITCHED_TO',
  VisitorAttended = 'VISITOR_ATTENDED',
  VisitorAttendedBy = 'VISITOR_ATTENDED_BY',
  VisitorConfirmed = 'VISITOR_CONFIRMED',
  VisitorConfirmedBy = 'VISITOR_CONFIRMED_BY',
  VisitorCreatedBy = 'VISITOR_CREATED_BY',
  VisitorDeletedBy = 'VISITOR_DELETED_BY',
  VisitorMissedBy = 'VISITOR_MISSED_BY'
}

export type RegistrationLogEntry = {
  readonly __typename?: 'RegistrationLogEntry';
  readonly date: Scalars['ZonedDateTime']['output'];
  readonly executedByMemberName: Maybe<Scalars['String']['output']>;
  readonly memberName: Maybe<Scalars['String']['output']>;
  readonly registrationState: Maybe<RegistrationState>;
  readonly switchedEventDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly switchedEventName: Maybe<Scalars['String']['output']>;
  readonly type: RegistrationActionLogEntryType;
  readonly uuid: Scalars['UUID']['output'];
  readonly visitorName: Maybe<Scalars['String']['output']>;
};

export enum RegistrationState {
  Attended = 'ATTENDED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Missed = 'MISSED',
  WaitList = 'WAIT_LIST',
  WaitListPenalty = 'WAIT_LIST_PENALTY'
}

export enum RegistrationStateManagerAction {
  Attended = 'ATTENDED',
  Confirm = 'CONFIRM',
  Delete = 'DELETE',
  Missed = 'MISSED'
}

export type RemoveMemberRoleFromMemberInput = {
  readonly memberRoleUUID: Scalars['UUID']['input'];
  readonly memberUUID: Scalars['UUID']['input'];
};

export type ResetPasswordInput = {
  readonly newPassword: Scalars['ProtectedString']['input'];
  readonly uuid: Scalars['UUID']['input'];
};

export type SelectedMembershipAgreementPaymentMethod = {
  readonly __typename?: 'SelectedMembershipAgreementPaymentMethod';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly isPreferred: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly type: MembershipAgreementPaymentMethodType;
  readonly uuid: Scalars['UUID']['output'];
};

export type SelectedMembershipAgreementPaymentMethodInput = {
  readonly isPreferred: Scalars['Boolean']['input'];
  readonly uuid: Scalars['UUID']['input'];
};

export type SendMemberEmailInput = {
  readonly message: Scalars['String']['input'];
  readonly recipientMemberUUIDs: ReadonlyArray<Scalars['UUID']['input']>;
  readonly subject: Scalars['String']['input'];
};

export type SignUpInput = {
  readonly emailAddress: Scalars['String']['input'];
  readonly password: Scalars['ProtectedString']['input'];
};

export type SuspensionDetails = {
  readonly __typename?: 'SuspensionDetails';
  readonly creator: PersonProtected;
  readonly endDate: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly message: Scalars['String']['output'];
  readonly startDate: Scalars['ZonedDateTime']['output'];
};

export type SuspensionDetailsInput = {
  readonly message: Scalars['String']['input'];
};

export type TermsOfServiceInput = {
  readonly text: InputMaybe<Scalars['String']['input']>;
};

export type UnpublishedWorkoutsResponse = {
  readonly __typename?: 'UnpublishedWorkoutsResponse';
  readonly results: ReadonlyArray<Workout>;
  readonly totalCount: Scalars['Int']['output'];
};

export type UpdateEquipmentInput = {
  readonly location: ReadonlyArray<LocationEquipmentTypes>;
};

export type UpdateEventInput = {
  readonly additionalPaymentDescription: InputMaybe<Scalars['String']['input']>;
  readonly color: InputMaybe<Scalars['String']['input']>;
  readonly description: InputMaybe<Scalars['String']['input']>;
  readonly end: Scalars['ZonedDateTime']['input'];
  readonly isSpecial: Scalars['Boolean']['input'];
  readonly location: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly registrationSettings: EventRegistrationSettingsInput;
  readonly start: Scalars['ZonedDateTime']['input'];
  readonly visibility: EventVisibility;
  readonly visibilityPreview: InputMaybe<Scalars['PeriodDuration']['input']>;
};

export type UpdateEventRegistrationInput = {
  readonly comment: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventVisitorRegistrationInput = {
  readonly name: Scalars['String']['input'];
};

export type UpdateGoogleMapsSettingsInput = {
  readonly placeId: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganisationFeedPostCommentInput = {
  readonly message: Scalars['String']['input'];
};

export type UpdateOrganisationFeedPostInput = {
  readonly message: Scalars['String']['input'];
};

export type UpdatePasswordInput = {
  readonly currentPassword: Scalars['ProtectedString']['input'];
  readonly newPassword: Scalars['ProtectedString']['input'];
};

export type Visitor = {
  readonly __typename?: 'Visitor';
  readonly name: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type WebPushKeys = {
  readonly __typename?: 'WebPushKeys';
  readonly publicKey: Scalars['String']['output'];
};

export type WebPushSubscription = {
  readonly __typename?: 'WebPushSubscription';
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly endpoint: Scalars['String']['output'];
  readonly userAgent: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type WebPushSubscriptionInput = {
  readonly auth: Scalars['String']['input'];
  readonly endpoint: Scalars['String']['input'];
  readonly key: Scalars['String']['input'];
  readonly userAgent: Scalars['String']['input'];
};

export type Workout = {
  readonly __typename?: 'Workout';
  readonly date: Scalars['ZonedDateTime']['output'];
  readonly description: Scalars['String']['output'];
  readonly publishingDate: Scalars['ZonedDateTime']['output'];
  readonly scoreBoards: ReadonlyArray<WorkoutScoreBoard>;
  readonly title: Maybe<Scalars['String']['output']>;
  readonly trainerNotes: Maybe<Scalars['String']['output']>;
  readonly uuid: Scalars['UUID']['output'];
  readonly visibility: WorkoutVisibility;
  readonly workoutType: Maybe<WorkoutType>;
};

export type WorkoutInput = {
  readonly date: Scalars['ZonedDateTime']['input'];
  readonly description: Scalars['String']['input'];
  readonly publishingDate: Scalars['ZonedDateTime']['input'];
  readonly scoreBoards: ReadonlyArray<WorkoutScoreBoardGraphQlInput>;
  readonly title: InputMaybe<Scalars['String']['input']>;
  readonly trainerNotes: InputMaybe<Scalars['String']['input']>;
  readonly visibility: WorkoutVisibility;
  readonly workoutTypeUUID: InputMaybe<Scalars['UUID']['input']>;
};

export type WorkoutResult = {
  readonly __typename?: 'WorkoutResult';
  readonly category: Maybe<WorkoutResultCategory>;
  readonly comment: Maybe<Scalars['String']['output']>;
  readonly created: Scalars['ZonedDateTime']['output'];
  readonly creator: PersonPublic;
  readonly reactions: ReadonlyArray<WorkoutResultReaction>;
  readonly score: Scalars['String']['output'];
  readonly scoreBoard: WorkoutScoreBoard;
  readonly uuid: Scalars['UUID']['output'];
};

export type WorkoutResultCategory = {
  readonly __typename?: 'WorkoutResultCategory';
  readonly name: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type WorkoutResultCategoryInput = {
  readonly name: Scalars['String']['input'];
};

export type WorkoutResultInput = {
  readonly comment: InputMaybe<Scalars['String']['input']>;
  readonly score: Scalars['String']['input'];
  readonly workoutResultCategoryUUID: InputMaybe<Scalars['UUID']['input']>;
};

export enum WorkoutResultOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type WorkoutResultReaction = {
  readonly __typename?: 'WorkoutResultReaction';
  readonly creator: PersonPublic;
  readonly result: WorkoutScoreBoard;
  readonly type: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type WorkoutResultReactionInput = {
  readonly type: Scalars['String']['input'];
};

export enum WorkoutResultType {
  Distance = 'DISTANCE',
  Duration = 'DURATION',
  None = 'NONE',
  Repetition = 'REPETITION',
  Weight = 'WEIGHT'
}

export type WorkoutScoreBoard = {
  readonly __typename?: 'WorkoutScoreBoard';
  readonly isWhiteboardResultMode: Scalars['Boolean']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly order: Scalars['Int']['output'];
  readonly resultOrder: WorkoutResultOrder;
  readonly resultType: WorkoutResultType;
  readonly results: ReadonlyArray<WorkoutResult>;
  readonly submissionEnd: Maybe<Scalars['ZonedDateTime']['output']>;
  readonly uuid: Scalars['UUID']['output'];
};

export type WorkoutScoreBoardGraphQlInput = {
  readonly isWhiteboardResultMode: Scalars['Boolean']['input'];
  readonly name: InputMaybe<Scalars['String']['input']>;
  readonly order: Scalars['Int']['input'];
  readonly resultOrder: WorkoutResultOrder;
  readonly resultType: WorkoutResultType;
  readonly submissionEnd: InputMaybe<Scalars['ZonedDateTime']['input']>;
  readonly uuid: InputMaybe<Scalars['UUID']['input']>;
};

export type WorkoutType = {
  readonly __typename?: 'WorkoutType';
  readonly name: Scalars['String']['output'];
  readonly uuid: Scalars['UUID']['output'];
};

export type WorkoutTypeInput = {
  readonly name: Scalars['String']['input'];
};

export enum WorkoutVisibility {
  Everyone = 'EVERYONE',
  Member = 'MEMBER',
  Registered = 'REGISTERED'
}

export type WorkoutsFilterInput = {
  readonly date: InputMaybe<Scalars['ZonedDateTime']['input']>;
  readonly workoutTypeUUID: InputMaybe<Scalars['UUID']['input']>;
};

export type WorkoutsResponse = {
  readonly __typename?: 'WorkoutsResponse';
  readonly results: ReadonlyArray<Workout>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MembershipAgreementActionLogEntryFragment = {
  readonly __typename?: 'MembershipAgreementActionLogEntry',
  readonly uuid: string,
  readonly date: string,
  readonly type: MembershipAgreementActionLogEntryType,
  readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string } | null
};

export type MembershipRequestFragment = {
  readonly __typename?: 'MembershipRequest',
  readonly uuid: string,
  readonly approvalCount: number,
  readonly commitment: MembershipAgreementCommitment,
  readonly description: string | null,
  readonly endDate: string | null,
  readonly isFollowUpAllowed: boolean,
  readonly name: string,
  readonly reference: string,
  readonly renewalPeriod: string | null,
  readonly runtime: string | null,
  readonly specialTerminationPeriod: string | null,
  readonly startDate: string | null,
  readonly state: MembershipRequestState,
  readonly subtitle: string | null,
  readonly terminateAfterUse: boolean,
  readonly terminationPeriod: string | null,
  readonly attendanceRestriction: {
    readonly __typename?: 'MembershipRequestAttendanceRestriction',
    readonly count: number,
    readonly timeUnit: AttendanceTimeUnit,
    readonly type: AttendanceType
  },
  readonly billing: { readonly __typename?: 'MembershipRequestBilling', readonly amount: number, readonly cycle: BillingCycle },
  readonly creator: {
    readonly __typename?: 'MemberFull',
    readonly uuid: string,
    readonly city: string | null,
    readonly country: string | null,
    readonly emailAddress: string | null,
    readonly firstname: string | null,
    readonly lastname: string | null,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly streetAddress: string | null,
    readonly username: string
  },
  readonly membershipAgreementTemplate: {
    readonly __typename?: 'MembershipAgreementTemplate',
    readonly approvalConfiguration: {
      readonly __typename?: 'ApprovalConfiguration',
      readonly description: string | null,
      readonly name: string,
      readonly requiresApproval: boolean
    },
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    }
  },
  readonly paymentMethods: ReadonlyArray<{
    readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
    readonly uuid: string,
    readonly isPreferred: boolean,
    readonly description: string | null,
    readonly name: string,
    readonly type: MembershipAgreementPaymentMethodType
  }>,
  readonly previousMembershipAgreement: {
    readonly __typename?: 'MembershipAgreement',
    readonly uuid: string,
    readonly name: string,
    readonly nextPaymentDate: string | null
  } | null
};

export type WorkoutFormFragmentFragment = {
  readonly __typename?: 'Workout',
  readonly uuid: string,
  readonly date: string,
  readonly description: string,
  readonly publishingDate: string,
  readonly title: string | null,
  readonly trainerNotes: string | null,
  readonly visibility: WorkoutVisibility,
  readonly scoreBoards: ReadonlyArray<{
    readonly __typename?: 'WorkoutScoreBoard',
    readonly uuid: string,
    readonly isWhiteboardResultMode: boolean,
    readonly name: string | null,
    readonly order: number,
    readonly resultOrder: WorkoutResultOrder,
    readonly resultType: WorkoutResultType,
    readonly submissionEnd: string | null,
    readonly results: ReadonlyArray<{ readonly __typename?: 'WorkoutResult', readonly uuid: string }>
  }>,
  readonly workoutType: { readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string } | null
};

export type AcceptMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type AcceptMembershipRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly acceptMembershipRequest: {
    readonly __typename?: 'AcceptMembershipRequestResponse',
    readonly isActivated: boolean,
    readonly membershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string } | null
  }
};

export type AddMemberRoleToMembersMutationVariables = Exact<{
  input: AddMemberRoleToMembersInput;
}>;


export type AddMemberRoleToMembersMutation = { readonly __typename?: 'Mutation', readonly addMemberRoleToMembers: { readonly __typename?: 'MemberRole', readonly uuid: string } };

export type ApproveMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type ApproveMembershipRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly approveMembershipRequest: {
    readonly __typename?: 'ApproveMembershipRequestResponse',
    readonly isActivated: boolean,
    readonly membershipRequest: { readonly __typename?: 'MembershipRequest', readonly uuid: string },
    readonly membershipAgreement: {
      readonly __typename?: 'MembershipAgreement',
      readonly uuid: string,
      readonly contractor: { readonly __typename?: 'MemberFull', readonly uuid: string }
    } | null
  }
};

export type AttendedEventRegistrationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type AttendedEventRegistrationMutation = { readonly __typename?: 'Mutation', readonly updateEventRegistrationToAttended: boolean };

export type CancelEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type CancelEventMutation = { readonly __typename?: 'Mutation', readonly cancelEvent: { readonly __typename?: 'Event', readonly uuid: string } };

export type CancelEventRegistrationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type CancelEventRegistrationMutation = { readonly __typename?: 'Mutation', readonly cancelEventRegistration: boolean };

export type ConfirmEmailAddressUpdateMutationVariables = Exact<{
  input: ConfirmEmailAddressUpdateInput;
}>;


export type ConfirmEmailAddressUpdateMutation = { readonly __typename?: 'Mutation', readonly confirmEmailAddressUpdate: boolean };

export type ConfirmEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type ConfirmEventMutation = { readonly __typename?: 'Mutation', readonly confirmEvent: { readonly __typename?: 'Event', readonly uuid: string } };

export type ConfirmEventRegistrationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type ConfirmEventRegistrationMutation = { readonly __typename?: 'Mutation', readonly confirmEventRegistration: boolean };

export type CreateApprovalConfigurationMutationVariables = Exact<{
  input: ApprovalConfigurationInput;
}>;


export type CreateApprovalConfigurationMutation = {
  readonly __typename?: 'Mutation',
  readonly createApprovalConfiguration: { readonly __typename?: 'ApprovalConfiguration', readonly uuid: string }
};

export type CreateBenchmarkMutationVariables = Exact<{
  input: BenchmarkInput;
}>;


export type CreateBenchmarkMutation = { readonly __typename?: 'Mutation', readonly createBenchmark: { readonly __typename?: 'Benchmark', readonly uuid: string } };

export type CreateBenchmarkResultMutationVariables = Exact<{
  benchmarkUUID: Scalars['UUID']['input'];
  input: BenchmarkResultInput;
}>;


export type CreateBenchmarkResultMutation = {
  readonly __typename?: 'Mutation',
  readonly createBenchmarkResult: { readonly __typename?: 'BenchmarkResult', readonly uuid: string }
};

export type CreateEmailAddressUpdateMutationVariables = Exact<{
  input: CreateEmailAddressUpdateInput;
}>;


export type CreateEmailAddressUpdateMutation = { readonly __typename?: 'Mutation', readonly createEmailAddressUpdate: boolean };

export type CreateEventDetailsMutationVariables = Exact<{
  input: EventDetailsInput;
}>;


export type CreateEventDetailsMutation = {
  readonly __typename?: 'Mutation',
  readonly createEventDetails: {
    readonly __typename?: 'EventDetails',
    readonly uuid: string,
    readonly schedule: { readonly __typename?: 'EventSchedule', readonly firstStart: string }
  }
};

export type CreateEventRegistrationMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  input: CreateEventRegistrationInput;
}>;


export type CreateEventRegistrationMutation = {
  readonly __typename?: 'Mutation',
  readonly createEventRegistration: { readonly __typename?: 'Registration', readonly uuid: string }
};

export type CreateMemberRoleMutationVariables = Exact<{
  input: MemberRoleInput;
}>;


export type CreateMemberRoleMutation = { readonly __typename?: 'Mutation', readonly createMemberRole: { readonly __typename?: 'MemberRole', readonly uuid: string } };

export type CreateMembershipRequestFromTemplateMutationVariables = Exact<{
  membershipAgreementTemplateUUID: Scalars['UUID']['input'];
}>;


export type CreateMembershipRequestFromTemplateMutation = {
  readonly __typename?: 'Mutation',
  readonly createMembershipRequestFromTemplate: {
    readonly __typename?: 'CreateMembershipRequestFromTemplateResponse',
    readonly isActivated: boolean,
    readonly membershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string } | null,
    readonly membershipRequest: { readonly __typename?: 'MembershipRequest', readonly uuid: string }
  }
};

export type CreateMembershipRequestFromTemplateForFollowUpMutationVariables = Exact<{
  membershipAgreementTemplateUUID: Scalars['UUID']['input'];
  membershipAgreementUUID: Scalars['UUID']['input'];
}>;


export type CreateMembershipRequestFromTemplateForFollowUpMutation = {
  readonly __typename?: 'Mutation',
  readonly createMembershipRequestFromTemplateForFollowUp: { readonly __typename?: 'MembershipRequest', readonly uuid: string }
};

export type CreateMembershipTemplateMutationVariables = Exact<{
  input: MembershipAgreementTemplateInput;
}>;


export type CreateMembershipTemplateMutation = {
  readonly __typename?: 'Mutation',
  readonly createMembershipAgreementTemplate: { readonly __typename?: 'MembershipAgreementTemplate', readonly uuid: string }
};

export type CreatePasswordResetMutationVariables = Exact<{
  input: CreatePasswordResetInput;
}>;


export type CreatePasswordResetMutation = { readonly __typename?: 'Mutation', readonly createPasswordReset: boolean };

export type CreatePaymentMethodMutationVariables = Exact<{
  membershipAgreementPaymentMethodInput: MembershipAgreementPaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = {
  readonly __typename?: 'Mutation',
  readonly createMembershipAgreementPaymentMethod: { readonly __typename?: 'MembershipAgreementPaymentMethod', readonly uuid: string }
};

export type CreateVisitorEventRegistrationMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  input: CreateVisitorEventRegistrationInput;
}>;


export type CreateVisitorEventRegistrationMutation = {
  readonly __typename?: 'Mutation',
  readonly createVisitorEventRegistration: { readonly __typename?: 'Registration', readonly uuid: string }
};

export type CreateWebPushSubscriptionMutationVariables = Exact<{
  input: WebPushSubscriptionInput;
}>;


export type CreateWebPushSubscriptionMutation = {
  readonly __typename?: 'Mutation',
  readonly createWebPushSubscription: { readonly __typename?: 'WebPushSubscription', readonly uuid: string }
};

export type CreateWorkoutMutationVariables = Exact<{
  input: WorkoutInput;
}>;


export type CreateWorkoutMutation = { readonly __typename?: 'Mutation', readonly createWorkout: { readonly __typename?: 'Workout', readonly uuid: string } };

export type CreateWorkoutResultMutationVariables = Exact<{
  scoreBoardUUID: Scalars['UUID']['input'];
  input: WorkoutResultInput;
}>;


export type CreateWorkoutResultMutation = { readonly __typename?: 'Mutation', readonly createWorkoutResult: { readonly __typename?: 'WorkoutResult', readonly uuid: string } };

export type CreateWorkoutResultCategoryMutationVariables = Exact<{
  input: WorkoutResultCategoryInput;
}>;


export type CreateWorkoutResultCategoryMutation = {
  readonly __typename?: 'Mutation',
  readonly createWorkoutResultCategory: { readonly __typename?: 'WorkoutResultCategory', readonly uuid: string }
};

export type CreateWorkoutResultReactionMutationVariables = Exact<{
  workoutResultUUID: Scalars['UUID']['input'];
  input: WorkoutResultReactionInput;
}>;


export type CreateWorkoutResultReactionMutation = {
  readonly __typename?: 'Mutation',
  readonly createWorkoutResultReaction: { readonly __typename?: 'WorkoutResultReaction', readonly uuid: string }
};

export type CreateWorkoutTypeMutationVariables = Exact<{
  input: WorkoutTypeInput;
}>;


export type CreateWorkoutTypeMutation = { readonly __typename?: 'Mutation', readonly createWorkoutType: { readonly __typename?: 'WorkoutType', readonly uuid: string } };

export type UpdateWorkoutTypeMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: WorkoutTypeInput;
}>;


export type UpdateWorkoutTypeMutation = { readonly __typename?: 'Mutation', readonly updateWorkoutType: { readonly __typename?: 'WorkoutType', readonly uuid: string } };

export type DeleteBenchmarkMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteBenchmarkMutation = { readonly __typename?: 'Mutation', readonly deleteBenchmark: boolean };

export type DeleteBenchmarkResultMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteBenchmarkResultMutation = { readonly __typename?: 'Mutation', readonly deleteBenchmarkResult: boolean };

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type DeleteEventMutation = { readonly __typename?: 'Mutation', readonly deleteEvent: boolean };

export type DeleteEventAndEndRecurrenceMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type DeleteEventAndEndRecurrenceMutation = { readonly __typename?: 'Mutation', readonly deleteEventAndEndRecurrence: boolean };

export type DeleteEventRegistrationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteEventRegistrationMutation = { readonly __typename?: 'Mutation', readonly deleteEventRegistration: boolean };

export type DeleteMemberRoleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteMemberRoleMutation = { readonly __typename?: 'Mutation', readonly deleteMemberRole: boolean };

export type DeleteMembershipAgreementTemplateMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteMembershipAgreementTemplateMutation = { readonly __typename?: 'Mutation', readonly deleteMembershipAgreementTemplate: boolean };

export type DeleteMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteMembershipRequestMutation = { readonly __typename?: 'Mutation', readonly deleteMembershipRequest: boolean };

export type EndPenaltyMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type EndPenaltyMutation = { readonly __typename?: 'Mutation', readonly endPenalty: { readonly __typename?: 'Penalty', readonly uuid: string } };

export type DeleteWebPushSubscriptionMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteWebPushSubscriptionMutation = { readonly __typename?: 'Mutation', readonly deleteWebPushSubscription: boolean };

export type DeleteWorkoutMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteWorkoutMutation = { readonly __typename?: 'Mutation', readonly deleteWorkout: boolean };

export type DeleteWorkoutResultMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteWorkoutResultMutation = { readonly __typename?: 'Mutation', readonly deleteWorkoutResult: boolean };

export type DeleteWorkoutResultReactionMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeleteWorkoutResultReactionMutation = { readonly __typename?: 'Mutation', readonly deleteWorkoutResultReaction: boolean };

export type ForceMembershipAgreementTerminationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  endDate: InputMaybe<Scalars['LocalDate']['input']>;
}>;


export type ForceMembershipAgreementTerminationMutation = {
  readonly __typename?: 'Mutation',
  readonly forceMembershipAgreementTermination: { readonly __typename?: 'MembershipAgreement', readonly uuid: string }
};

export type MarkAllInAppNotificationAsSeenMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllInAppNotificationAsSeenMutation = { readonly __typename?: 'Mutation', readonly markAllInAppNotificationAsSeen: boolean };

export type MarkInAppNotificationAsSeenMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MarkInAppNotificationAsSeenMutation = { readonly __typename?: 'Mutation', readonly markInAppNotificationAsSeen: boolean };

export type MissedEventRegistrationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MissedEventRegistrationMutation = { readonly __typename?: 'Mutation', readonly updateEventRegistrationToMissed: boolean };

export type PublishMembershipAgreementTemplateMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type PublishMembershipAgreementTemplateMutation = {
  readonly __typename?: 'Mutation',
  readonly publishMembershipAgreementTemplate: { readonly __typename?: 'MembershipAgreementTemplate', readonly uuid: string }
};

export type RefuseMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RefuseMembershipRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly refuseMembershipRequest: { readonly __typename?: 'MembershipRequest', readonly uuid: string }
};

export type RejectMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RejectMembershipRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly rejectMembershipRequest: { readonly __typename?: 'MembershipRequest', readonly uuid: string }
};

export type RemoveMemberRoleFromMemberMutationVariables = Exact<{
  input: RemoveMemberRoleFromMemberInput;
}>;


export type RemoveMemberRoleFromMemberMutation = {
  readonly __typename?: 'Mutation',
  readonly removeMemberRoleFromMember: { readonly __typename?: 'MemberRole', readonly uuid: string }
};

export type RequestMembershipAgreementTerminationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RequestMembershipAgreementTerminationMutation = {
  readonly __typename?: 'Mutation',
  readonly requestMembershipAgreementTermination: { readonly __typename?: 'MembershipAgreement', readonly uuid: string }
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { readonly __typename?: 'Mutation', readonly resetPassword: boolean };

export type ResumeMembershipAgreementMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type ResumeMembershipAgreementMutation = {
  readonly __typename?: 'Mutation',
  readonly resumeMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string }
};

export type RevokeMembershipAgreementTerminationRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RevokeMembershipAgreementTerminationRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly revokeMembershipAgreementTerminationRequest: { readonly __typename?: 'MembershipAgreement', readonly uuid: string }
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { readonly __typename?: 'Mutation', readonly signUp: boolean };

export type SuspendMembershipAgreementMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: SuspensionDetailsInput;
}>;


export type SuspendMembershipAgreementMutation = {
  readonly __typename?: 'Mutation',
  readonly suspendMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string }
};

export type SwitchEventRegistrationMutationVariables = Exact<{
  registrationUUID: Scalars['UUID']['input'];
  eventId: Scalars['String']['input'];
}>;


export type SwitchEventRegistrationMutation = {
  readonly __typename?: 'Mutation',
  readonly switchEventRegistration: { readonly __typename?: 'Registration', readonly event: { readonly __typename?: 'Event', readonly uuid: string } }
};

export type UnpublishMembershipAgreementTemplateMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type UnpublishMembershipAgreementTemplateMutation = {
  readonly __typename?: 'Mutation',
  readonly unpublishMembershipAgreementTemplate: { readonly __typename?: 'MembershipAgreementTemplate', readonly uuid: string }
};

export type UpdateApprovalConfigurationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ApprovalConfigurationInput;
}>;


export type UpdateApprovalConfigurationMutation = {
  readonly __typename?: 'Mutation',
  readonly updateApprovalConfiguration: { readonly __typename?: 'ApprovalConfiguration', readonly uuid: string }
};

export type UpdateBenchmarkMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: BenchmarkInput;
}>;


export type UpdateBenchmarkMutation = { readonly __typename?: 'Mutation', readonly updateBenchmark: { readonly __typename?: 'Benchmark', readonly uuid: string } };

export type UpdateBenchmarkResultMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: BenchmarkResultInput;
}>;


export type UpdateBenchmarkResultMutation = {
  readonly __typename?: 'Mutation',
  readonly updateBenchmarkResult: { readonly __typename?: 'BenchmarkResult', readonly uuid: string }
};

export type UpdateDataProtectionMutationVariables = Exact<{
  input: DataProtectionInput;
}>;


export type UpdateDataProtectionMutation = { readonly __typename?: 'Mutation', readonly updateDataProtection: { readonly __typename?: 'Organisation', readonly uuid: string } };

export type UpdateEmailPreferencesMutationVariables = Exact<{
  input: EmailPreferencesInput;
}>;


export type UpdateEmailPreferencesMutation = { readonly __typename?: 'Mutation', readonly updateEmailPreferences: boolean };

export type UpdateEquipmentMutationVariables = Exact<{
  input: UpdateEquipmentInput;
}>;


export type UpdateEquipmentMutation = {
  readonly __typename?: 'Mutation',
  readonly updateEquipment: { readonly __typename?: 'Equipment', readonly location: ReadonlyArray<LocationEquipmentTypes> }
};

export type UpdateEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = { readonly __typename?: 'Mutation', readonly updateEvent: { readonly __typename?: 'Event', readonly uuid: string } };

export type UpdateEventDetailsMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  input: EventDetailsInput;
}>;


export type UpdateEventDetailsMutation = { readonly __typename?: 'Mutation', readonly updateEventDetails: { readonly __typename?: 'Event', readonly uuid: string } };

export type UpdateEventRegistrationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateEventRegistrationInput;
}>;


export type UpdateEventRegistrationMutation = {
  readonly __typename?: 'Mutation',
  readonly updateEventRegistration: { readonly __typename?: 'Registration', readonly uuid: string }
};

export type UpdateGoogleMapsSettingsMutationVariables = Exact<{
  input: UpdateGoogleMapsSettingsInput;
}>;


export type UpdateGoogleMapsSettingsMutation = {
  readonly __typename?: 'Mutation',
  readonly updateGoogleMapsSettings: { readonly __typename?: 'GoogleMapsSettings', readonly placeId: string | null }
};

export type UpdateMemberRoleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: MemberRoleInput;
}>;


export type UpdateMemberRoleMutation = { readonly __typename?: 'Mutation', readonly updateMemberRole: { readonly __typename?: 'MemberRole', readonly uuid: string } };

export type UpdateMembershipAgreementTemplateMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: MembershipAgreementTemplateInput;
}>;


export type UpdateMembershipAgreementTemplateMutation = {
  readonly __typename?: 'Mutation',
  readonly updateMembershipAgreementTemplate: { readonly __typename?: 'MembershipAgreementTemplate', readonly uuid: string }
};

export type UpdateMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: MembershipRequestInput;
}>;


export type UpdateMembershipRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly updateMembershipRequest: { readonly __typename?: 'MembershipRequest', readonly uuid: string }
};

export type UpdateOrganisationInfoMutationVariables = Exact<{
  input: OrganisationInfoInput;
}>;


export type UpdateOrganisationInfoMutation = { readonly __typename?: 'Mutation', readonly updateOrganisationInfo: { readonly __typename?: 'Organisation', readonly uuid: string } };

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { readonly __typename?: 'Mutation', readonly updatePassword: boolean };

export type UpdatePaymentMethodMutationVariables = Exact<{
  paymentMethodUUID: Scalars['UUID']['input'];
  paymentMethodInput: MembershipAgreementPaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = {
  readonly __typename?: 'Mutation',
  readonly updateMembershipAgreementPaymentMethod: { readonly __typename?: 'MembershipAgreementPaymentMethod', readonly uuid: string }
};

export type UpdatePersonMutationVariables = Exact<{
  personInput: PersonInput;
}>;


export type UpdatePersonMutation = { readonly __typename?: 'Mutation', readonly updatePerson: { readonly __typename?: 'Person', readonly uuid: string } };

export type UpdateTermsOfServiceMutationVariables = Exact<{
  input: TermsOfServiceInput;
}>;


export type UpdateTermsOfServiceMutation = { readonly __typename?: 'Mutation', readonly updateTermsOfService: { readonly __typename?: 'Organisation', readonly uuid: string } };

export type UpdateWorkoutMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: WorkoutInput;
}>;


export type UpdateWorkoutMutation = { readonly __typename?: 'Mutation', readonly updateWorkout: { readonly __typename?: 'Workout', readonly uuid: string } };

export type UpdateWorkoutResultMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: WorkoutResultInput;
}>;


export type UpdateWorkoutResultMutation = { readonly __typename?: 'Mutation', readonly updateWorkoutResult: { readonly __typename?: 'WorkoutResult', readonly uuid: string } };

export type UpdateWorkoutResultCategoryMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: WorkoutResultCategoryInput;
}>;


export type UpdateWorkoutResultCategoryMutation = {
  readonly __typename?: 'Mutation',
  readonly updateWorkoutResultCategory: { readonly __typename?: 'WorkoutResultCategory', readonly uuid: string }
};

export type WithdrawMembershipRequestMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type WithdrawMembershipRequestMutation = {
  readonly __typename?: 'Mutation',
  readonly withdrawMembershipRequest: { readonly __typename?: 'MembershipRequest', readonly uuid: string }
};

export type ActiveMemberCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveMemberCountQuery = { readonly __typename?: 'Query', readonly activeMemberCount: number };

export type ActiveMembershipAgreementsReportQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveMembershipAgreementsReportQuery = {
  readonly __typename?: 'Query',
  readonly activeMembershipAgreementsReport: {
    readonly __typename?: 'ActiveMembershipAgreementsReportResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'ActiveMembershipAgreementsReport', readonly count: number, readonly templateName: string }>
  }
};

export type AllowedEventRegistrationStateActionsForManagerQueryVariables = Exact<{
  registrationUUID: Scalars['UUID']['input'];
}>;


export type AllowedEventRegistrationStateActionsForManagerQuery = {
  readonly __typename?: 'Query',
  readonly allowedEventRegistrationStateActionsForManager: ReadonlyArray<RegistrationStateManagerAction>
};

export type ApprovalConfigurationQueryVariables = Exact<{
  approvalConfigurationUUID: Scalars['UUID']['input'];
}>;


export type ApprovalConfigurationQuery = {
  readonly __typename?: 'Query',
  readonly approvalConfiguration: {
    readonly __typename?: 'ApprovalConfiguration',
    readonly uuid: string,
    readonly approvalCount: number,
    readonly description: string | null,
    readonly name: string,
    readonly requiresApproval: boolean
  }
};

export type ApprovalConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApprovalConfigurationsQuery = {
  readonly __typename?: 'Query',
  readonly approvalConfigurations: {
    readonly __typename?: 'ApprovalConfigurationsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'ApprovalConfiguration',
      readonly uuid: string,
      readonly approvalCount: number,
      readonly description: string | null,
      readonly name: string,
      readonly requiresApproval: boolean
    }>
  }
};

export type BenchmarkQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type BenchmarkQuery = {
  readonly __typename?: 'Query',
  readonly benchmark: {
    readonly __typename?: 'Benchmark',
    readonly uuid: string,
    readonly description: string | null,
    readonly name: string,
    readonly resultOrder: BenchmarkResultOrder,
    readonly resultType: BenchmarkResultType,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'BenchmarkResult',
      readonly uuid: string,
      readonly comment: string | null,
      readonly date: string,
      readonly score: string
    }>
  }
};

export type BilledEventRegistrationsQueryVariables = Exact<{
  filter: BilledEventRegistrationsFilterInput;
  pagination: PaginationInput;
}>;


export type BilledEventRegistrationsQuery = {
  readonly __typename?: 'Query',
  readonly billedEventRegistrations: {
    readonly __typename?: 'BilledEventRegistrationsResponse',
    readonly hasNext: boolean,
    readonly totalCount: number,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'BilledEventRegistration',
      readonly feeAmount: number,
      readonly isSettled: boolean,
      readonly isSplit: boolean,
      readonly event: {
        readonly __typename?: 'Event',
        readonly uuid: string,
        readonly start: string,
        readonly state: EventState,
        readonly eventDetails: { readonly __typename?: 'EventDetails', readonly name: string, readonly color: string | null }
      }
    }>
  }
};

export type EventRegistrationLogQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type EventRegistrationLogQuery = {
  readonly __typename?: 'Query',
  readonly eventRegistrationLogEntries: ReadonlyArray<{
    readonly __typename?: 'RegistrationLogEntry',
    readonly uuid: string,
    readonly date: string,
    readonly executedByMemberName: string | null,
    readonly memberName: string | null,
    readonly registrationState: RegistrationState | null,
    readonly switchedEventDate: string | null,
    readonly switchedEventName: string | null,
    readonly type: RegistrationActionLogEntryType,
    readonly visitorName: string | null
  }>
};

export type EventsForSwitchQueryVariables = Exact<{
  registrationUUID: Scalars['UUID']['input'];
}>;


export type EventsForSwitchQuery = {
  readonly __typename?: 'Query',
  readonly eventsForSwitch: ReadonlyArray<{
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly eventDetails: { readonly __typename?: 'EventDetails', readonly color: string | null, readonly name: string }
  }>
};

export type HistoricMemberCountQueryVariables = Exact<{ [key: string]: never; }>;


export type HistoricMemberCountQuery = {
  readonly __typename?: 'Query',
  readonly historicMemberCount: {
    readonly __typename?: 'HistoricMemberCountResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'HistoricMemberCount', readonly count: number, readonly date: string }>
  }
};

export type IcalQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type IcalQueryQuery = { readonly __typename?: 'Query', readonly ical: { readonly __typename?: 'IcalResponse', readonly link: string } };

export type IsUsernameAvailableQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type IsUsernameAvailableQuery = { readonly __typename?: 'Query', readonly isUsernameAvailable: boolean };

export type LatestInAppNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestInAppNotificationsQuery = {
  readonly __typename?: 'Query',
  readonly latestInAppNotifications: {
    readonly __typename?: 'LatestInAppNotificationsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'InAppNotification',
      readonly uuid: string,
      readonly created: string,
      readonly message: string | null,
      readonly seen: boolean,
      readonly type: string
    }>
  }
};

export type LatestOrganisationLogEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestOrganisationLogEntriesQuery = {
  readonly __typename?: 'Query',
  readonly latestOrganisationLogEntries: {
    readonly __typename?: 'LatestOrganisationLogEntriesResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'OrganisationLogEntry',
      readonly uuid: string,
      readonly created: string,
      readonly message: string,
      readonly type: string
    }>
  }
};

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = {
  readonly __typename?: 'Query',
  readonly me: {
    readonly __typename?: 'Me',
    readonly uuid: string,
    readonly birthday: string | null,
    readonly city: string | null,
    readonly country: string | null,
    readonly emailAddress: string,
    readonly firstname: string | null,
    readonly lastname: string | null,
    readonly memberPermissions: ReadonlyArray<MemberPermission>,
    readonly memberUUID: string | null,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly streetAddress: string | null,
    readonly username: string
  }
};

export type MemberQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MemberQuery = {
  readonly __typename?: 'Query',
  readonly member: {
    readonly __typename?: 'MemberFull',
    readonly uuid: string,
    readonly personUUID: string,
    readonly city: string | null,
    readonly country: string | null,
    readonly emailAddress: string | null,
    readonly firstname: string | null,
    readonly lastname: string | null,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly streetAddress: string | null,
    readonly username: string
  }
};

export type MemberEmailsQueryVariables = Exact<{
  filter: MemberEmailsFilterInput;
}>;


export type MemberEmailsQuery = {
  readonly __typename?: 'Query',
  readonly memberEmails: ReadonlyArray<{
    readonly __typename?: 'MemberEmail',
    readonly uuid: string,
    readonly contentHtml: string,
    readonly created: string,
    readonly sentDate: string | null,
    readonly state: MemberEmailState,
    readonly subject: string,
    readonly sender: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string } | null
  }>
};

export type MemberRoleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MemberRoleQuery = {
  readonly __typename?: 'Query',
  readonly memberRole: { readonly __typename?: 'MemberRole', readonly uuid: string, readonly name: string, readonly memberPermissions: ReadonlyArray<MemberPermission> }
};

export type MemberRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type MemberRolesQuery = {
  readonly __typename?: 'Query',
  readonly memberRoles: {
    readonly __typename?: 'MemberRolesResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MemberRole',
      readonly uuid: string,
      readonly name: string,
      readonly memberPermissions: ReadonlyArray<MemberPermission>,
      readonly members: ReadonlyArray<{
        readonly __typename?: 'MemberFull',
        readonly uuid: string,
        readonly firstname: string | null,
        readonly lastname: string | null,
        readonly username: string
      }>
    }>
  }
};

export type MembersQueryVariables = Exact<{
  filter: MembersFilterInput;
}>;


export type MembersQuery = {
  readonly __typename?: 'Query',
  readonly members: {
    readonly __typename?: 'MembersResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MemberFull',
      readonly uuid: string,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly state: MemberState,
      readonly username: string
    }>
  }
};

export type MembershipAgreementQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MembershipAgreementQuery = {
  readonly __typename?: 'Query', readonly membershipAgreement: {
    readonly __typename?: 'MembershipAgreement',
    readonly uuid: string,
    readonly blockedCredits: number,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly nextEndDate: string | null,
    readonly nextTerminationDeadline: string | null,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationUntil: string | null,
    readonly startDate: string | null,
    readonly state: MembershipAgreementState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly usedCredits: number,
    readonly actionLogEntries: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementActionLogEntry',
      readonly uuid: string,
      readonly date: string,
      readonly type: MembershipAgreementActionLogEntryType,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string } | null
    }> | null,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementAttendance',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly contractor: {
      readonly __typename?: 'MemberFull',
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly streetAddress: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly username: string
    },
    readonly nextMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string } | null,
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>,
    readonly previousMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string } | null,
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    },
    readonly suspensionDetails: ReadonlyArray<{
      readonly __typename?: 'SuspensionDetails',
      readonly message: string,
      readonly startDate: string,
      readonly endDate: string | null,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
    }>
  }
};

export type MembershipAgreementPaymentMethodQueryVariables = Exact<{
  paymentMethodUUID: Scalars['UUID']['input'];
}>;


export type MembershipAgreementPaymentMethodQuery = {
  readonly __typename?: 'Query',
  readonly membershipAgreementPaymentMethod: {
    readonly __typename?: 'MembershipAgreementPaymentMethod',
    readonly uuid: string,
    readonly description: string | null,
    readonly name: string,
    readonly type: MembershipAgreementPaymentMethodType
  }
};

export type MembershipAgreementPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipAgreementPaymentMethodsQuery = {
  readonly __typename?: 'Query',
  readonly membershipAgreementPaymentMethods: {
    readonly __typename?: 'MembershipAgreementPaymentMethodsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>
  }
};

export type MembershipAgreementStartsByMonthReportQueryVariables = Exact<{
  input: MembershipAgreementStartsByMonthReportInput;
}>;


export type MembershipAgreementStartsByMonthReportQuery = {
  readonly __typename?: 'Query',
  readonly membershipAgreementStartsByMonthReport: {
    readonly __typename?: 'MembershipAgreementStartsByMonthReportResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementStartsByMonthReport',
      readonly count: number,
      readonly month: number,
      readonly templateName: string
    }>
  }
};

export type MembershipAgreementTemplateFragment = {
  readonly __typename?: 'MembershipAgreementTemplate',
  readonly uuid: string,
  readonly commitment: MembershipAgreementCommitment,
  readonly description: string | null,
  readonly isAllowedForFollowUp: boolean,
  readonly isFollowUpAllowed: boolean,
  readonly name: string,
  readonly reference: string,
  readonly renewalPeriod: string | null,
  readonly requiredPersonalInformationValues: ReadonlyArray<PersonalInformationValue>,
  readonly runtime: string | null,
  readonly sortPosition: number | null,
  readonly specialTerminationPeriod: string | null,
  readonly state: MembershipAgreementTemplateState,
  readonly subtitle: string | null,
  readonly terminateAfterUse: boolean,
  readonly terminationPeriod: string | null,
  readonly approvalConfiguration: {
    readonly __typename?: 'ApprovalConfiguration',
    readonly uuid: string,
    readonly approvalCount: number,
    readonly description: string | null,
    readonly name: string,
    readonly requiresApproval: boolean
  },
  readonly attendanceRestriction: {
    readonly __typename?: 'MembershipAgreementTemplateAttendanceRestriction',
    readonly count: number,
    readonly timeUnit: AttendanceTimeUnit,
    readonly type: AttendanceType
  },
  readonly billing: { readonly __typename?: 'MembershipAgreementTemplateBilling', readonly amount: number, readonly cycle: BillingCycle },
  readonly organisation: {
    readonly __typename?: 'Organisation',
    readonly city: string | null,
    readonly country: string | null,
    readonly email: string | null,
    readonly name: string,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly streetAddress: string | null
  },
  readonly paymentMethods: ReadonlyArray<{
    readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
    readonly uuid: string,
    readonly isPreferred: boolean,
    readonly description: string | null,
    readonly name: string,
    readonly type: MembershipAgreementPaymentMethodType
  }>
};

export type MembershipAgreementTemplateQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MembershipAgreementTemplateQuery = {
  readonly __typename?: 'Query', readonly membershipAgreementTemplate: {
    readonly __typename?: 'MembershipAgreementTemplate',
    readonly uuid: string,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly isAllowedForFollowUp: boolean,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly requiredPersonalInformationValues: ReadonlyArray<PersonalInformationValue>,
    readonly runtime: string | null,
    readonly sortPosition: number | null,
    readonly specialTerminationPeriod: string | null,
    readonly state: MembershipAgreementTemplateState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly approvalConfiguration: {
      readonly __typename?: 'ApprovalConfiguration',
      readonly uuid: string,
      readonly approvalCount: number,
      readonly description: string | null,
      readonly name: string,
      readonly requiresApproval: boolean
    },
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementTemplateAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementTemplateBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>
  }
};

export type MembershipAgreementTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipAgreementTemplatesQuery = {
  readonly __typename?: 'Query', readonly membershipAgreementTemplates: ReadonlyArray<{
    readonly __typename?: 'MembershipAgreementTemplate',
    readonly uuid: string,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly isAllowedForFollowUp: boolean,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly requiredPersonalInformationValues: ReadonlyArray<PersonalInformationValue>,
    readonly runtime: string | null,
    readonly sortPosition: number | null,
    readonly specialTerminationPeriod: string | null,
    readonly state: MembershipAgreementTemplateState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly approvalConfiguration: {
      readonly __typename?: 'ApprovalConfiguration',
      readonly uuid: string,
      readonly approvalCount: number,
      readonly description: string | null,
      readonly name: string,
      readonly requiresApproval: boolean
    },
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementTemplateAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementTemplateBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>
  }>
};

export type MembershipAgreementsQueryVariables = Exact<{
  filter: MembershipAgreementsFilterInput;
}>;


export type MembershipAgreementsQuery = {
  readonly __typename?: 'Query', readonly membershipAgreements: ReadonlyArray<{
    readonly __typename?: 'MembershipAgreement',
    readonly uuid: string,
    readonly commitment: MembershipAgreementCommitment,
    readonly created: string,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationUntil: string | null,
    readonly startDate: string | null,
    readonly state: MembershipAgreementState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly usedCredits: number,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementAttendance',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly contractor: {
      readonly __typename?: 'MemberFull',
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null,
      readonly username: string
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>
  }>
};

export type MembershipRequestQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MembershipRequestQuery = {
  readonly __typename?: 'Query', readonly allowedMembershipRequestApprovalActions: ReadonlyArray<MembershipRequestAction>, readonly membershipRequest: {
    readonly __typename?: 'MembershipRequest',
    readonly uuid: string,
    readonly approvalCount: number,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationPeriod: string | null,
    readonly startDate: string | null,
    readonly state: MembershipRequestState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipRequestAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipRequestBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly creator: {
      readonly __typename?: 'MemberFull',
      readonly uuid: string,
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null,
      readonly username: string
    },
    readonly membershipAgreementTemplate: {
      readonly __typename?: 'MembershipAgreementTemplate',
      readonly approvalConfiguration: {
        readonly __typename?: 'ApprovalConfiguration',
        readonly description: string | null,
        readonly name: string,
        readonly requiresApproval: boolean
      },
      readonly organisation: {
        readonly __typename?: 'Organisation',
        readonly city: string | null,
        readonly country: string | null,
        readonly email: string | null,
        readonly name: string,
        readonly phone: string | null,
        readonly postalCode: string | null,
        readonly streetAddress: string | null
      }
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>,
    readonly previousMembershipAgreement: {
      readonly __typename?: 'MembershipAgreement',
      readonly uuid: string,
      readonly name: string,
      readonly nextPaymentDate: string | null
    } | null
  }
};

export type MembershipRequestCountQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipRequestCountQuery = { readonly __typename?: 'Query', readonly membershipRequestCount: number };

export type MyBenchmarksQueryVariables = Exact<{
  filter: MyBenchmarksFilterInput;
  pagination: PaginationInput;
}>;


export type MyBenchmarksQuery = {
  readonly __typename?: 'Query',
  readonly myBenchmarks: {
    readonly __typename?: 'MyBenchmarksResponse',
    readonly totalCount: number,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'Benchmark',
      readonly uuid: string,
      readonly name: string,
      readonly resultType: BenchmarkResultType,
      readonly resultOrder: BenchmarkResultOrder,
      readonly results: ReadonlyArray<{ readonly __typename?: 'BenchmarkResult', readonly uuid: string, readonly score: string, readonly date: string }>
    }>
  }
};

export type MyBilledEventRegistrationsQueryVariables = Exact<{
  filter: MyBilledEventRegistrationsFilterInput;
  pagination: PaginationInput;
}>;


export type MyBilledEventRegistrationsQuery = {
  readonly __typename?: 'Query',
  readonly myBilledEventRegistrations: {
    readonly __typename?: 'MyBilledEventRegistrationsResponse',
    readonly hasNext: boolean,
    readonly totalCount: number,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'BilledEventRegistration',
      readonly feeAmount: number,
      readonly isSettled: boolean,
      readonly isSplit: boolean,
      readonly event: {
        readonly __typename?: 'Event',
        readonly uuid: string,
        readonly start: string,
        readonly state: EventState,
        readonly eventDetails: { readonly __typename?: 'EventDetails', readonly name: string, readonly color: string | null }
      }
    }>
  }
};

export type OrganisationQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationQuery = {
  readonly __typename?: 'Query',
  readonly organisation: {
    readonly __typename?: 'Organisation',
    readonly about: string | null,
    readonly city: string | null,
    readonly country: string | null,
    readonly email: string | null,
    readonly facebookUrl: string | null,
    readonly instagramUrl: string | null,
    readonly mapsUrl: string | null,
    readonly name: string,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly shortDescription: string | null,
    readonly streetAddress: string | null,
    readonly websiteUrl: string | null,
    readonly whatsAppNumber: string | null
  }
};

export type OrganisationImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationImagesQuery = {
  readonly __typename?: 'Query',
  readonly organisationImages: {
    readonly __typename?: 'OrganisationImagesResponse',
    readonly emailHeader: { readonly __typename?: 'CloudinaryFile', readonly publicId: string, readonly version: string } | null,
    readonly logoSquare: { readonly __typename?: 'CloudinaryFile', readonly publicId: string, readonly version: string } | null,
    readonly shopBanner: { readonly __typename?: 'CloudinaryFile', readonly publicId: string, readonly version: string } | null
  }
};

export type UnpublishedWorkoutsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnpublishedWorkoutsQuery = {
  readonly __typename?: 'Query',
  readonly unpublishedWorkouts: {
    readonly __typename?: 'UnpublishedWorkoutsResponse',
    readonly totalCount: number,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'Workout',
      readonly uuid: string,
      readonly date: string,
      readonly publishingDate: string,
      readonly title: string | null,
      readonly workoutType: { readonly __typename?: 'WorkoutType', readonly name: string } | null
    }>
  }
};

export type WebPushKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type WebPushKeysQuery = { readonly __typename?: 'Query', readonly webPushKeys: { readonly __typename?: 'WebPushKeys', readonly publicKey: string } };

export type WorkoutQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type WorkoutQuery = {
  readonly __typename?: 'Query', readonly workout: {
    readonly __typename?: 'Workout',
    readonly uuid: string,
    readonly date: string,
    readonly description: string,
    readonly publishingDate: string,
    readonly title: string | null,
    readonly trainerNotes: string | null,
    readonly visibility: WorkoutVisibility,
    readonly scoreBoards: ReadonlyArray<{
      readonly __typename?: 'WorkoutScoreBoard',
      readonly uuid: string,
      readonly isWhiteboardResultMode: boolean,
      readonly name: string | null,
      readonly order: number,
      readonly resultOrder: WorkoutResultOrder,
      readonly resultType: WorkoutResultType,
      readonly submissionEnd: string | null,
      readonly results: ReadonlyArray<{
        readonly __typename?: 'WorkoutResult',
        readonly uuid: string,
        readonly comment: string | null,
        readonly created: string,
        readonly score: string,
        readonly category: { readonly __typename?: 'WorkoutResultCategory', readonly uuid: string, readonly name: string } | null,
        readonly creator: { readonly __typename?: 'PersonPublic', readonly uuid: string, readonly username: string },
        readonly reactions: ReadonlyArray<{
          readonly __typename?: 'WorkoutResultReaction',
          readonly uuid: string,
          readonly type: string,
          readonly creator: { readonly __typename?: 'PersonPublic', readonly uuid: string, readonly username: string }
        }>
      }>
    }>,
    readonly workoutType: { readonly __typename?: 'WorkoutType', readonly name: string } | null
  }
};

export type WorkoutResultCategoryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type WorkoutResultCategoryQuery = {
  readonly __typename?: 'Query',
  readonly workoutResultCategory: { readonly __typename?: 'WorkoutResultCategory', readonly uuid: string, readonly name: string }
};

export type WorkoutResultCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkoutResultCategoriesQuery = {
  readonly __typename?: 'Query',
  readonly workoutResultCategories: ReadonlyArray<{ readonly __typename?: 'WorkoutResultCategory', readonly uuid: string, readonly name: string }>
};

export type WorkoutTypeQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type WorkoutTypeQuery = { readonly __typename?: 'Query', readonly workoutType: { readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string } };

export type WorkoutTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkoutTypesQuery = {
  readonly __typename?: 'Query',
  readonly workoutTypes: ReadonlyArray<{ readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string }>
};

export type WorkoutsQueryVariables = Exact<{
  filter: WorkoutsFilterInput;
  pagination: PaginationInput;
}>;


export type WorkoutsQuery = {
  readonly __typename?: 'Query',
  readonly workouts: {
    readonly __typename?: 'WorkoutsResponse',
    readonly totalCount: number,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'Workout',
      readonly uuid: string,
      readonly date: string,
      readonly publishingDate: string,
      readonly title: string | null,
      readonly workoutType: { readonly __typename?: 'WorkoutType', readonly name: string } | null,
      readonly scoreBoards: ReadonlyArray<{
        readonly __typename?: 'WorkoutScoreBoard',
        readonly results: ReadonlyArray<{ readonly __typename?: 'WorkoutResult', readonly uuid: string }>
      }>
    }>
  }
};

export type UpdateDataProtectionPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateDataProtectionPageQuery = {
  readonly __typename?: 'Query',
  readonly organisation: { readonly __typename?: 'Organisation', readonly dataProtectionHtml: string | null }
};

export type MemberPenaltyPageDataQueryVariables = Exact<{
  penaltyFilter: PenaltiesFilterInput;
  remarksFilter: PenaltyRemarksFilterInput;
}>;


export type MemberPenaltyPageDataQuery = {
  readonly __typename?: 'Query',
  readonly penaltyRemarks: {
    readonly __typename?: 'PenaltyRemarksResponse',
    readonly result: ReadonlyArray<{
      readonly __typename?: 'PenaltyRemark',
      readonly created: string,
      readonly end: string,
      readonly eventRegistration: {
        readonly __typename?: 'Registration',
        readonly uuid: string,
        readonly state: RegistrationState,
        readonly event: {
          readonly __typename?: 'Event',
          readonly uuid: string,
          readonly start: string,
          readonly eventDetails: { readonly __typename?: 'EventDetails', readonly name: string }
        }
      }
    }>
  },
  readonly penalties: {
    readonly __typename?: 'PenaltiesResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'Penalty', readonly uuid: string, readonly created: string, readonly end: string }>
  }
};

export type MembershipsAdminPageDataQueryVariables = Exact<{
  filter: MembershipAgreementsFilterInput;
}>;


export type MembershipsAdminPageDataQuery = {
  readonly __typename?: 'Query',
  readonly membershipAgreementFilters: {
    readonly __typename?: 'MembershipAgreementFiltersResponse',
    readonly membershipAgreementNames: ReadonlyArray<string>,
    readonly references: ReadonlyArray<string>,
    readonly membershipAgreementTemplates: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementTemplate',
      readonly uuid: string,
      readonly name: string,
      readonly state: MembershipAgreementTemplateState
    }>
  },
  readonly membershipAgreements: ReadonlyArray<{
    readonly __typename?: 'MembershipAgreement',
    readonly uuid: string,
    readonly commitment: MembershipAgreementCommitment,
    readonly name: string,
    readonly state: MembershipAgreementState,
    readonly usedCredits: number,
    readonly attendanceRestriction: { readonly __typename?: 'MembershipAgreementAttendance', readonly count: number, readonly type: AttendanceType },
    readonly billing: { readonly __typename?: 'MembershipAgreementBilling', readonly amount: number },
    readonly contractor: { readonly __typename?: 'MemberFull', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
  }>
};

export type MembershipAgreementByIdPageQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MembershipAgreementByIdPageQuery = {
  readonly __typename?: 'Query', readonly allowedMembershipAgreementActions: ReadonlyArray<MembershipAgreementAction>, readonly membershipAgreement: {
    readonly __typename?: 'MembershipAgreement',
    readonly uuid: string,
    readonly blockedCredits: number,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly nextEndDate: string | null,
    readonly nextTerminationDeadline: string | null,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationUntil: string | null,
    readonly startDate: string | null,
    readonly state: MembershipAgreementState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly usedCredits: number,
    readonly actionLogEntries: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementActionLogEntry',
      readonly uuid: string,
      readonly date: string,
      readonly type: MembershipAgreementActionLogEntryType,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string } | null
    }> | null,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementAttendance',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly contractor: {
      readonly __typename?: 'MemberFull',
      readonly uuid: string,
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null,
      readonly username: string
    },
    readonly nextMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string } | null,
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>,
    readonly previousMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string } | null,
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    },
    readonly suspensionDetails: ReadonlyArray<{
      readonly __typename?: 'SuspensionDetails',
      readonly message: string,
      readonly startDate: string,
      readonly endDate: string | null,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
    }>
  }
};

export type MembershipRequestsPageDataQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipRequestsPageDataQuery = {
  readonly __typename?: 'Query',
  readonly membershipRequests: {
    readonly __typename?: 'MembershipRequestsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MembershipRequest',
      readonly uuid: string,
      readonly modified: string,
      readonly name: string,
      readonly state: MembershipRequestState,
      readonly creator: { readonly __typename?: 'MemberFull', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
    }>
  }
};

export type MembershipRequestByIdPageDataQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MembershipRequestByIdPageDataQuery = {
  readonly __typename?: 'Query', readonly allowedMembershipRequestApprovalActions: ReadonlyArray<MembershipRequestAction>, readonly membershipRequest: {
    readonly __typename?: 'MembershipRequest',
    readonly uuid: string,
    readonly approvalCount: number,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly name: string,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationPeriod: string | null,
    readonly startDate: string | null,
    readonly state: MembershipRequestState,
    readonly terminationPeriod: string | null,
    readonly actionLogEntries: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementActionLogEntry',
      readonly uuid: string,
      readonly date: string,
      readonly type: MembershipAgreementActionLogEntryType,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string } | null
    }>,
    readonly approvalFacts: ReadonlyArray<{
      readonly __typename?: 'ApprovalFact',
      readonly uuid: string,
      readonly date: string,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
    }>,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipRequestAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipRequestBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly creator: {
      readonly __typename?: 'MemberFull',
      readonly uuid: string,
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null,
      readonly username: string
    },
    readonly membershipAgreementTemplate: {
      readonly __typename?: 'MembershipAgreementTemplate',
      readonly approvalConfiguration: {
        readonly __typename?: 'ApprovalConfiguration',
        readonly description: string | null,
        readonly name: string,
        readonly requiresApproval: boolean
      }
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>,
    readonly previousMembershipAgreement: {
      readonly __typename?: 'MembershipAgreement',
      readonly uuid: string,
      readonly name: string,
      readonly nextPaymentDate: string | null
    } | null
  }
};

export type UpdateOrganisationInfoPageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateOrganisationInfoPageQuery = {
  readonly __typename?: 'Query',
  readonly organisation: {
    readonly __typename?: 'Organisation',
    readonly about: string | null,
    readonly city: string | null,
    readonly country: string | null,
    readonly email: string | null,
    readonly facebookUrl: string | null,
    readonly instagramUrl: string | null,
    readonly mapsUrl: string | null,
    readonly name: string,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly shopUrl: string | null,
    readonly shortDescription: string | null,
    readonly streetAddress: string | null,
    readonly websiteUrl: string | null,
    readonly whatsAppNumber: string | null
  },
  readonly googleMapsSettings: { readonly __typename?: 'GoogleMapsSettings', readonly placeId: string | null },
  readonly equipment: { readonly __typename?: 'Equipment', readonly location: ReadonlyArray<LocationEquipmentTypes> }
};

export type PenaltiesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PenaltiesPageQuery = {
  readonly __typename?: 'Query',
  readonly penaltyRemarks: {
    readonly __typename?: 'PenaltyRemarksResponse',
    readonly result: ReadonlyArray<{
      readonly __typename?: 'PenaltyRemark',
      readonly created: string,
      readonly end: string,
      readonly eventRegistration: {
        readonly __typename?: 'Registration',
        readonly uuid: string,
        readonly state: RegistrationState,
        readonly event: {
          readonly __typename?: 'Event',
          readonly uuid: string,
          readonly start: string,
          readonly eventDetails: { readonly __typename?: 'EventDetails', readonly name: string }
        }
      },
      readonly member: {
        readonly __typename?: 'MemberProtected',
        readonly uuid: string,
        readonly person: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
      }
    }>
  },
  readonly penalties: {
    readonly __typename?: 'PenaltiesResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'Penalty',
      readonly uuid: string,
      readonly created: string,
      readonly end: string,
      readonly member: {
        readonly __typename?: 'MemberProtected',
        readonly uuid: string,
        readonly person: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
      }
    }>
  }
};

export type UpdateTermsOfServicePageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateTermsOfServicePageQuery = {
  readonly __typename?: 'Query',
  readonly organisation: { readonly __typename?: 'Organisation', readonly termsOfServiceHtml: string | null }
};

export type ClassesPageEventFragment = {
  readonly __typename?: 'Event',
  readonly uuid: string,
  readonly end: string,
  readonly start: string,
  readonly state: EventState,
  readonly eventDetails: {
    readonly __typename?: 'EventDetails',
    readonly uuid: string,
    readonly color: string | null,
    readonly name: string,
    readonly registrationSettings: {
      readonly __typename?: 'EventRegistrationSettings',
      readonly availableSpots: number | null,
      readonly isRegistrable: boolean,
      readonly registrationStartPeriod: string | null
    }
  },
  readonly registrations: ReadonlyArray<{ readonly __typename?: 'Registration', readonly state: RegistrationState }> | null
};

export type ClassesAuthenticatedPageQueryVariables = Exact<{
  classesFilter: EventsFilterInput;
  eventsFilter: EventsFilterInput;
  classRegistrationsFilter: MyEventRegistrationsFilterInput;
  eventRegistrationsFilter: MyEventRegistrationsFilterInput;
}>;


export type ClassesAuthenticatedPageQuery = {
  readonly __typename?: 'Query',
  readonly classes: ReadonlyArray<{
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly color: string | null,
      readonly name: string,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly isRegistrable: boolean,
        readonly registrationStartPeriod: string | null
      }
    },
    readonly registrations: ReadonlyArray<{ readonly __typename?: 'Registration', readonly state: RegistrationState }> | null
  }>,
  readonly events: ReadonlyArray<{
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly color: string | null,
      readonly name: string,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly isRegistrable: boolean,
        readonly registrationStartPeriod: string | null
      }
    },
    readonly registrations: ReadonlyArray<{ readonly __typename?: 'Registration', readonly state: RegistrationState }> | null
  }>,
  readonly classRegistrations: {
    readonly __typename?: 'MyEventRegistrationsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MyEventRegistration',
      readonly uuid: string,
      readonly state: RegistrationState,
      readonly event: { readonly __typename?: 'Event', readonly uuid: string }
    }>
  },
  readonly eventRegistrations: {
    readonly __typename?: 'MyEventRegistrationsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MyEventRegistration',
      readonly uuid: string,
      readonly state: RegistrationState,
      readonly event: { readonly __typename?: 'Event', readonly uuid: string }
    }>
  }
};

export type ClassesVisitorPageQueryVariables = Exact<{
  classesFilter: EventsFilterInput;
}>;


export type ClassesVisitorPageQuery = {
  readonly __typename?: 'Query',
  readonly classes: ReadonlyArray<{
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly color: string | null,
      readonly name: string,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly isRegistrable: boolean,
        readonly registrationStartPeriod: string | null
      }
    },
    readonly registrations: ReadonlyArray<{ readonly __typename?: 'Registration', readonly state: RegistrationState }> | null
  }>
};

export type ClassByIdPageVisitorQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type ClassByIdPageVisitorQuery = {
  readonly __typename?: 'Query',
  readonly event: {
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly color: string | null,
      readonly description: string | null,
      readonly name: string,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly feeAmount: number,
        readonly isRegistrable: boolean,
        readonly isSwitchToOtherEventAllowed: boolean,
        readonly lateCancellationPeriod: string | null,
        readonly minimumNumberOfRegistrations: number | null,
        readonly minimumNumberOfRegistrationsPeriod: string | null,
        readonly registrationStartPeriod: string | null,
        readonly resolveWaitListPeriod: string | null
      }
    }
  }
};

export type ClassByIdPageMemberQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type ClassByIdPageMemberQuery = {
  readonly __typename?: 'Query',
  readonly event: {
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly color: string | null,
      readonly description: string | null,
      readonly name: string,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly feeAmount: number,
        readonly isRegistrable: boolean,
        readonly isSwitchToOtherEventAllowed: boolean,
        readonly lateCancellationPeriod: string | null,
        readonly minimumNumberOfRegistrations: number | null,
        readonly minimumNumberOfRegistrationsPeriod: string | null,
        readonly registrationStartPeriod: string | null,
        readonly resolveWaitListPeriod: string | null
      }
    },
    readonly registrations: ReadonlyArray<{
      readonly __typename?: 'Registration',
      readonly uuid: string,
      readonly state: RegistrationState,
      readonly comment: string | null,
      readonly registrationDate: string,
      readonly member: {
        readonly __typename?: 'MemberPublic',
        readonly uuid: string,
        readonly person: { readonly __typename?: 'PersonPublic', readonly uuid: string, readonly username: string }
      } | null,
      readonly visitor: { readonly __typename?: 'Visitor', readonly uuid: string, readonly name: string } | null
    }> | null
  },
  readonly myEventRegistrations: {
    readonly __typename?: 'MyEventRegistrationsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'MyEventRegistration', readonly uuid: string }>
  },
  readonly isEventRegistrationAllowed: {
    readonly __typename?: 'IsEventRegistrationAllowedResponse',
    readonly isAllowed: boolean,
    readonly expectedRegistrationState: RegistrationState | null,
    readonly denyReason: EventRegistrationDenyReason | null
  },
  readonly isEventRegistrationCancellationAllowed: {
    readonly __typename?: 'IsEventRegistrationCancellationAllowedResponse',
    readonly denyReason: EventRegistrationCancellationDenyReason | null,
    readonly isAllowed: boolean,
    readonly isLateCancellation: boolean | null
  }
};

export type CopyClassPageDataQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type CopyClassPageDataQuery = {
  readonly __typename?: 'Query', readonly event: {
    readonly __typename?: 'Event', readonly uuid: string, readonly start: string, readonly end: string, readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly additionalPaymentDescription: string | null,
      readonly color: string | null,
      readonly description: string | null,
      readonly isSpecial: boolean,
      readonly location: string | null,
      readonly name: string,
      readonly visibility: EventVisibility,
      readonly visibilityPreview: string | null,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly feeAmount: number,
        readonly isAutomaticRegistrationConfirmationEnabled: boolean,
        readonly isRegistrable: boolean,
        readonly isSwitchToOtherEventAllowed: boolean,
        readonly lateCancellationPeriod: string | null,
        readonly minimumNumberOfRegistrations: number | null,
        readonly minimumNumberOfRegistrationsPeriod: string | null,
        readonly registrationStartPeriod: string | null,
        readonly resolveWaitListPeriod: string | null
      },
      readonly schedule: {
        readonly __typename?: 'EventSchedule',
        readonly firstStart: string,
        readonly firstEnd: string,
        readonly recurrence: RecurrenceType,
        readonly recurrenceEnd: string | null
      }
    }
  }
};

export type UpdateClassPageDataQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type UpdateClassPageDataQuery = {
  readonly __typename?: 'Query', readonly event: {
    readonly __typename?: 'Event', readonly uuid: string, readonly start: string, readonly end: string, readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly additionalPaymentDescription: string | null,
      readonly color: string | null,
      readonly description: string | null,
      readonly isSpecial: boolean,
      readonly location: string | null,
      readonly name: string,
      readonly visibility: EventVisibility,
      readonly visibilityPreview: string | null,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly feeAmount: number,
        readonly isAutomaticRegistrationConfirmationEnabled: boolean,
        readonly isRegistrable: boolean,
        readonly isSwitchToOtherEventAllowed: boolean,
        readonly lateCancellationPeriod: string | null,
        readonly minimumNumberOfRegistrations: number | null,
        readonly minimumNumberOfRegistrationsPeriod: string | null,
        readonly registrationStartPeriod: string | null,
        readonly resolveWaitListPeriod: string | null
      },
      readonly schedule: {
        readonly __typename?: 'EventSchedule',
        readonly firstStart: string,
        readonly firstEnd: string,
        readonly recurrence: RecurrenceType,
        readonly recurrenceEnd: string | null
      }
    }
  }
};

export type EventsPageDataQueryVariables = Exact<{
  filter: EventsFilterInput;
}>;


export type EventsPageDataQuery = {
  readonly __typename?: 'Query',
  readonly events: ReadonlyArray<{
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly name: string,
      readonly location: string | null,
      readonly registrationSettings: { readonly __typename?: 'EventRegistrationSettings', readonly availableSpots: number | null }
    }
  }>
};

export type InfoPageQueryVariables = Exact<{
  eventFilter: EventsFilterInput;
}>;


export type InfoPageQuery = {
  readonly __typename?: 'Query',
  readonly organisation: {
    readonly __typename?: 'Organisation',
    readonly about: string | null,
    readonly city: string | null,
    readonly facebookUrl: string | null,
    readonly instagramUrl: string | null,
    readonly name: string,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly shopUrl: string | null,
    readonly streetAddress: string | null,
    readonly websiteUrl: string | null,
    readonly whatsAppNumber: string | null
  },
  readonly organisationImages: {
    readonly __typename?: 'OrganisationImagesResponse',
    readonly shopBanner: { readonly __typename?: 'CloudinaryFile', readonly publicId: string, readonly version: string } | null
  },
  readonly googleMapsSettings: { readonly __typename?: 'GoogleMapsSettings', readonly placeId: string | null },
  readonly equipment: { readonly __typename?: 'Equipment', readonly location: ReadonlyArray<LocationEquipmentTypes> },
  readonly membershipAgreementTemplates: ReadonlyArray<{
    readonly __typename?: 'MembershipAgreementTemplate',
    readonly uuid: string,
    readonly name: string,
    readonly sortPosition: number | null,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementTemplateAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementTemplateBilling', readonly amount: number, readonly cycle: BillingCycle }
  }>,
  readonly events: ReadonlyArray<{
    readonly __typename?: 'Event',
    readonly uuid: string,
    readonly end: string,
    readonly start: string,
    readonly state: EventState,
    readonly eventDetails: {
      readonly __typename?: 'EventDetails',
      readonly uuid: string,
      readonly color: string | null,
      readonly name: string,
      readonly registrationSettings: {
        readonly __typename?: 'EventRegistrationSettings',
        readonly availableSpots: number | null,
        readonly isRegistrable: boolean,
        readonly registrationStartPeriod: string | null
      }
    },
    readonly registrations: ReadonlyArray<{ readonly __typename?: 'Registration', readonly state: RegistrationState }> | null
  }>
};

export type DataProtectionPageQueryVariables = Exact<{ [key: string]: never; }>;


export type DataProtectionPageQuery = {
  readonly __typename?: 'Query',
  readonly organisation: { readonly __typename?: 'Organisation', readonly dataProtectionHtml: string | null }
};

export type TermsOfServicePageQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsOfServicePageQuery = {
  readonly __typename?: 'Query',
  readonly organisation: { readonly __typename?: 'Organisation', readonly termsOfServiceHtml: string | null }
};

export type MembershipsPageAuthenticatedDataQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipsPageAuthenticatedDataQuery = {
  readonly __typename?: 'Query',
  readonly myMembershipAgreements: {
    readonly __typename?: 'MyMembershipAgreementsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'MembershipAgreement', readonly uuid: string }>
  }
};

export type MembershipsByIdPageDataAuthenticatedQueryVariables = Exact<{
  filter: MyMembershipAgreementsFilterInput;
}>;


export type MembershipsByIdPageDataAuthenticatedQuery = {
  readonly __typename?: 'Query',
  readonly me: {
    readonly __typename?: 'Me',
    readonly uuid: string,
    readonly birthday: string | null,
    readonly city: string | null,
    readonly country: string | null,
    readonly firstname: string | null,
    readonly lastname: string | null,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly streetAddress: string | null,
    readonly username: string
  },
  readonly myMembershipAgreements: {
    readonly __typename?: 'MyMembershipAgreementsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string, readonly isFollowUpAllowed: boolean }>
  },
  readonly organisation: { readonly __typename?: 'Organisation', readonly dataProtectionHtml: string | null, readonly name: string, readonly termsOfServiceHtml: string | null }
};

export type UpdateMembershipAgreementTemplatePageDataQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type UpdateMembershipAgreementTemplatePageDataQuery = {
  readonly __typename?: 'Query',
  readonly membershipAgreementTemplate: {
    readonly __typename?: 'MembershipAgreementTemplate',
    readonly uuid: string,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly isAllowedForFollowUp: boolean,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly reference: string,
    readonly renewalPeriod: string | null,
    readonly requiredPersonalInformationValues: ReadonlyArray<PersonalInformationValue>,
    readonly runtime: string | null,
    readonly sortPosition: number | null,
    readonly specialTerminationPeriod: string | null,
    readonly state: MembershipAgreementTemplateState,
    readonly subtitle: string | null,
    readonly terminateAfterUse: boolean,
    readonly terminationPeriod: string | null,
    readonly approvalConfiguration: {
      readonly __typename?: 'ApprovalConfiguration',
      readonly uuid: string,
      readonly approvalCount: number,
      readonly description: string | null,
      readonly name: string,
      readonly requiresApproval: boolean
    },
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementTemplateAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementTemplateBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>
  },
  readonly approvalConfigurations: {
    readonly __typename?: 'ApprovalConfigurationsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'ApprovalConfiguration', readonly uuid: string, readonly name: string }>
  },
  readonly membershipAgreementPaymentMethods: {
    readonly __typename?: 'MembershipAgreementPaymentMethodsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'MembershipAgreementPaymentMethod', readonly uuid: string, readonly name: string }>
  }
};

export type MembershipConfigurationPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipConfigurationPageQuery = {
  readonly __typename?: 'Query',
  readonly membershipAgreementPaymentMethods: {
    readonly __typename?: 'MembershipAgreementPaymentMethodsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>
  },
  readonly approvalConfigurations: {
    readonly __typename?: 'ApprovalConfigurationsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'ApprovalConfiguration',
      readonly uuid: string,
      readonly approvalCount: number,
      readonly description: string | null,
      readonly name: string,
      readonly requiresApproval: boolean
    }>
  }
};

export type CreateMembershipAgreementTemplatePageQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateMembershipAgreementTemplatePageQuery = {
  readonly __typename?: 'Query',
  readonly approvalConfigurations: {
    readonly __typename?: 'ApprovalConfigurationsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'ApprovalConfiguration', readonly uuid: string, readonly name: string }>
  },
  readonly membershipAgreementPaymentMethods: {
    readonly __typename?: 'MembershipAgreementPaymentMethodsResponse',
    readonly results: ReadonlyArray<{ readonly __typename?: 'MembershipAgreementPaymentMethod', readonly uuid: string, readonly name: string }>
  }
};

export type MyDashboardPageQueryVariables = Exact<{
  filter: MyEventRegistrationsFilterInput;
}>;


export type MyDashboardPageQuery = {
  readonly __typename?: 'Query',
  readonly myEventRegistrations: {
    readonly __typename?: 'MyEventRegistrationsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MyEventRegistration',
      readonly state: RegistrationState,
      readonly uuid: string,
      readonly event: {
        readonly __typename?: 'Event',
        readonly uuid: string,
        readonly end: string,
        readonly start: string,
        readonly state: EventState,
        readonly eventDetails: {
          readonly __typename?: 'EventDetails',
          readonly uuid: string,
          readonly color: string | null,
          readonly name: string,
          readonly registrationSettings: {
            readonly __typename?: 'EventRegistrationSettings',
            readonly availableSpots: number | null,
            readonly isRegistrable: boolean,
            readonly registrationStartPeriod: string | null
          }
        },
        readonly registrations: ReadonlyArray<{ readonly __typename?: 'Registration', readonly state: RegistrationState }> | null
      }
    }>
  },
  readonly myPenaltyRemarks: {
    readonly __typename?: 'MyPenaltyRemarksResponse',
    readonly list: ReadonlyArray<{
      readonly __typename?: 'PenaltyRemark',
      readonly created: string,
      readonly end: string,
      readonly eventRegistration: {
        readonly __typename?: 'Registration',
        readonly event: {
          readonly __typename?: 'Event',
          readonly uuid: string,
          readonly start: string,
          readonly eventDetails: { readonly __typename?: 'EventDetails', readonly name: string }
        }
      }
    }>
  },
  readonly myPenalties: { readonly __typename?: 'MyPenaltiesResponse', readonly list: ReadonlyArray<{ readonly __typename?: 'Penalty', readonly end: string }> }
};

export type MyAccountPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAccountPageQuery = {
  readonly __typename?: 'Query',
  readonly requiredPersonalInformation: ReadonlyArray<PersonalInformationValue>,
  readonly me: {
    readonly __typename?: 'Me',
    readonly uuid: string,
    readonly birthday: string | null,
    readonly city: string | null,
    readonly country: string | null,
    readonly firstname: string | null,
    readonly lastname: string | null,
    readonly phone: string | null,
    readonly postalCode: string | null,
    readonly streetAddress: string | null,
    readonly username: string
  }
};

export type MyMembershipsPageDataQueryVariables = Exact<{ [key: string]: never; }>;


export type MyMembershipsPageDataQuery = {
  readonly __typename?: 'Query',
  readonly myMembershipRequests: {
    readonly __typename?: 'MyMembershipRequestsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MembershipRequest',
      readonly uuid: string,
      readonly created: string,
      readonly name: string,
      readonly state: MembershipRequestState,
      readonly subtitle: string | null
    }>
  },
  readonly myMembershipAgreements: {
    readonly __typename?: 'MyMembershipAgreementsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'MembershipAgreement',
      readonly uuid: string,
      readonly created: string,
      readonly name: string,
      readonly state: MembershipAgreementState,
      readonly subtitle: string | null
    }>
  }
};

export type MyMembershipAgreementByIdPageQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MyMembershipAgreementByIdPageQuery = {
  readonly __typename?: 'Query', readonly allowedMembershipAgreementActions: ReadonlyArray<MembershipAgreementAction>, readonly membershipAgreement: {
    readonly __typename?: 'MembershipAgreement',
    readonly uuid: string,
    readonly blockedCredits: number,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly isFollowUpAllowed: boolean,
    readonly name: string,
    readonly nextEndDate: string | null,
    readonly nextTerminationDeadline: string | null,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationUntil: string | null,
    readonly startDate: string | null,
    readonly state: MembershipAgreementState,
    readonly subtitle: string | null,
    readonly terminationPeriod: string | null,
    readonly usedCredits: number,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipAgreementAttendance',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipAgreementBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly contractor: {
      readonly __typename?: 'MemberFull',
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null,
      readonly username: string
    },
    readonly nextMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string } | null,
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>,
    readonly previousMembershipAgreement: { readonly __typename?: 'MembershipAgreement', readonly uuid: string, readonly name: string } | null,
    readonly organisation: {
      readonly __typename?: 'Organisation',
      readonly city: string | null,
      readonly country: string | null,
      readonly email: string | null,
      readonly name: string,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null
    },
    readonly suspensionDetails: ReadonlyArray<{
      readonly __typename?: 'SuspensionDetails',
      readonly message: string,
      readonly startDate: string,
      readonly endDate: string | null,
      readonly creator: { readonly __typename?: 'PersonProtected', readonly firstname: string | null, readonly lastname: string | null, readonly username: string }
    }>
  }
};

export type MyMembershipRequestByIdPageQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type MyMembershipRequestByIdPageQuery = {
  readonly __typename?: 'Query', readonly allowedMembershipRequestApprovalActions: ReadonlyArray<MembershipRequestAction>, readonly membershipRequest: {
    readonly __typename?: 'MembershipRequest',
    readonly uuid: string,
    readonly commitment: MembershipAgreementCommitment,
    readonly description: string | null,
    readonly endDate: string | null,
    readonly name: string,
    readonly renewalPeriod: string | null,
    readonly runtime: string | null,
    readonly specialTerminationPeriod: string | null,
    readonly startDate: string | null,
    readonly state: MembershipRequestState,
    readonly terminationPeriod: string | null,
    readonly attendanceRestriction: {
      readonly __typename?: 'MembershipRequestAttendanceRestriction',
      readonly count: number,
      readonly timeUnit: AttendanceTimeUnit,
      readonly type: AttendanceType
    },
    readonly billing: { readonly __typename?: 'MembershipRequestBilling', readonly amount: number, readonly cycle: BillingCycle },
    readonly creator: {
      readonly __typename?: 'MemberFull',
      readonly city: string | null,
      readonly country: string | null,
      readonly emailAddress: string | null,
      readonly firstname: string | null,
      readonly lastname: string | null,
      readonly phone: string | null,
      readonly postalCode: string | null,
      readonly streetAddress: string | null,
      readonly username: string
    },
    readonly membershipAgreementTemplate: {
      readonly __typename?: 'MembershipAgreementTemplate',
      readonly approvalConfiguration: {
        readonly __typename?: 'ApprovalConfiguration',
        readonly description: string | null,
        readonly name: string,
        readonly requiresApproval: boolean
      },
      readonly organisation: {
        readonly __typename?: 'Organisation',
        readonly city: string | null,
        readonly country: string | null,
        readonly email: string | null,
        readonly name: string,
        readonly phone: string | null,
        readonly postalCode: string | null,
        readonly streetAddress: string | null
      }
    },
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'SelectedMembershipAgreementPaymentMethod',
      readonly uuid: string,
      readonly isPreferred: boolean,
      readonly description: string | null,
      readonly name: string,
      readonly type: MembershipAgreementPaymentMethodType
    }>,
    readonly previousMembershipAgreement: {
      readonly __typename?: 'MembershipAgreement',
      readonly uuid: string,
      readonly name: string,
      readonly nextPaymentDate: string | null
    } | null
  }
};

export type NotificationSettingsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationSettingsPageQuery = {
  readonly __typename?: 'Query',
  readonly myEmailPreferences: {
    readonly __typename?: 'MyEmailPreferencesResponse',
    readonly entries: ReadonlyArray<{ readonly __typename?: 'EmailPreference', readonly isEnabled: boolean, readonly notificationScope: NotificationScope }>
  },
  readonly myWebPushSubscriptions: ReadonlyArray<{
    readonly __typename?: 'WebPushSubscription',
    readonly uuid: string,
    readonly created: string,
    readonly endpoint: string,
    readonly userAgent: string
  }>
};

export type CopyWorkoutPageQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type CopyWorkoutPageQuery = {
  readonly __typename?: 'Query',
  readonly workout: {
    readonly __typename?: 'Workout',
    readonly uuid: string,
    readonly date: string,
    readonly description: string,
    readonly publishingDate: string,
    readonly title: string | null,
    readonly trainerNotes: string | null,
    readonly visibility: WorkoutVisibility,
    readonly scoreBoards: ReadonlyArray<{
      readonly __typename?: 'WorkoutScoreBoard',
      readonly uuid: string,
      readonly isWhiteboardResultMode: boolean,
      readonly name: string | null,
      readonly order: number,
      readonly resultOrder: WorkoutResultOrder,
      readonly resultType: WorkoutResultType,
      readonly submissionEnd: string | null,
      readonly results: ReadonlyArray<{ readonly __typename?: 'WorkoutResult', readonly uuid: string }>
    }>,
    readonly workoutType: { readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string } | null
  },
  readonly workoutTypes: ReadonlyArray<{ readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string }>
};

export type UpdateWorkoutPageQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type UpdateWorkoutPageQuery = {
  readonly __typename?: 'Query',
  readonly workout: {
    readonly __typename?: 'Workout',
    readonly uuid: string,
    readonly date: string,
    readonly description: string,
    readonly publishingDate: string,
    readonly title: string | null,
    readonly trainerNotes: string | null,
    readonly visibility: WorkoutVisibility,
    readonly scoreBoards: ReadonlyArray<{
      readonly __typename?: 'WorkoutScoreBoard',
      readonly uuid: string,
      readonly isWhiteboardResultMode: boolean,
      readonly name: string | null,
      readonly order: number,
      readonly resultOrder: WorkoutResultOrder,
      readonly resultType: WorkoutResultType,
      readonly submissionEnd: string | null,
      readonly results: ReadonlyArray<{ readonly __typename?: 'WorkoutResult', readonly uuid: string }>
    }>,
    readonly workoutType: { readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string } | null
  },
  readonly workoutTypes: ReadonlyArray<{ readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string }>
};

export type WorkoutConfigurationPageQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkoutConfigurationPageQuery = {
  readonly __typename?: 'Query',
  readonly workoutTypes: ReadonlyArray<{ readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string }>,
  readonly workoutResultCategories: ReadonlyArray<{ readonly __typename?: 'WorkoutResultCategory', readonly uuid: string, readonly name: string }>
};

export type CreateWorkoutPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateWorkoutPageQuery = {
  readonly __typename?: 'Query',
  readonly workoutTypes: ReadonlyArray<{ readonly __typename?: 'WorkoutType', readonly uuid: string, readonly name: string }>
};

export type WorkoutListPageWorkoutFragment = {
  readonly __typename?: 'Workout',
  readonly uuid: string,
  readonly date: string,
  readonly publishingDate: string,
  readonly title: string | null,
  readonly description: string,
  readonly workoutType: { readonly __typename?: 'WorkoutType', readonly name: string } | null
};

export type WorkoutListPageQueryVariables = Exact<{
  filter: WorkoutsFilterInput;
  pagination: PaginationInput;
}>;


export type WorkoutListPageQuery = {
  readonly __typename?: 'Query',
  readonly unpublishedWorkouts: {
    readonly __typename?: 'UnpublishedWorkoutsResponse',
    readonly results: ReadonlyArray<{
      readonly __typename?: 'Workout',
      readonly uuid: string,
      readonly date: string,
      readonly publishingDate: string,
      readonly title: string | null,
      readonly description: string,
      readonly workoutType: { readonly __typename?: 'WorkoutType', readonly name: string } | null
    }>
  },
  readonly workouts: {
    readonly __typename?: 'WorkoutsResponse',
    readonly totalCount: number,
    readonly results: ReadonlyArray<{
      readonly __typename?: 'Workout',
      readonly uuid: string,
      readonly date: string,
      readonly publishingDate: string,
      readonly title: string | null,
      readonly description: string,
      readonly workoutType: { readonly __typename?: 'WorkoutType', readonly name: string } | null
    }>
  }
};

export const MembershipAgreementActionLogEntryFragmentDoc = gql`
    fragment MembershipAgreementActionLogEntry on MembershipAgreementActionLogEntry {
      uuid
      creator {
        firstname
        lastname
        username
  }
      date
      type
    }
`;
export const MembershipRequestFragmentDoc = gql`
    fragment MembershipRequest on MembershipRequest {
  uuid
  approvalCount
  attendanceRestriction {
    count
    timeUnit
    type
  }
  billing {
    amount
    cycle
  }
  commitment
  creator {
    uuid
    city
    country
    emailAddress
    firstname
    lastname
    phone
    postalCode
    streetAddress
    username
  }
  description
  endDate
  isFollowUpAllowed
  membershipAgreementTemplate {
    approvalConfiguration {
      description
      name
      requiresApproval
    }
    organisation {
      city
      country
      email
      name
      phone
      postalCode
      streetAddress
    }
  }
  name
  paymentMethods {
    uuid
    isPreferred
    description
    name
    type
  }
  previousMembershipAgreement {
    uuid
    name
    nextPaymentDate
  }
  reference
  renewalPeriod
  runtime
  specialTerminationPeriod
  startDate
  state
  subtitle
  terminateAfterUse
  terminationPeriod
}
    `;
export const WorkoutFormFragmentFragmentDoc = gql`
    fragment WorkoutFormFragment on Workout {
  uuid
  date
  description
  publishingDate
  title
  trainerNotes
  visibility
  scoreBoards {
    uuid
    isWhiteboardResultMode
    name
    order
    resultOrder
    resultType
    submissionEnd
    results {
      uuid
    }
  }
  workoutType {
    uuid
    name
  }
}
    `;
export const MembershipAgreementTemplateFragmentDoc = gql`
    fragment MembershipAgreementTemplate on MembershipAgreementTemplate {
  uuid
  approvalConfiguration {
    uuid
    approvalCount
    description
    name
    requiresApproval
  }
  attendanceRestriction {
    count
    timeUnit
    type
  }
  billing {
    amount
    cycle
  }
  commitment
  description
  isAllowedForFollowUp
  isFollowUpAllowed
  name
  organisation {
    city
    country
    email
    name
    phone
    postalCode
    streetAddress
  }
  paymentMethods {
    uuid
    isPreferred
    description
    name
    type
  }
  reference
  renewalPeriod
  requiredPersonalInformationValues
  runtime
  sortPosition
  specialTerminationPeriod
  state
  subtitle
  terminateAfterUse
  terminationPeriod
}
    `;
export const ClassesPageEventFragmentDoc = gql`
    fragment ClassesPageEvent on Event {
      uuid
      end
      start
      state
      eventDetails {
        uuid
        color
        name
        registrationSettings {
          availableSpots
          isRegistrable
          registrationStartPeriod
        }
      }
      registrations {
        state
      }
    }
`;
export const WorkoutListPageWorkoutFragmentDoc = gql`
    fragment WorkoutListPageWorkout on Workout {
  uuid
  date
  publishingDate
  title
  description
  workoutType {
    name
  }
}
    `;
export const AcceptMembershipRequestDocument = gql`
    mutation AcceptMembershipRequest($uuid: UUID!) {
      acceptMembershipRequest(uuid: $uuid) {
    isActivated
    membershipAgreement {
      uuid
    }
  }
}
    `;
export type AcceptMembershipRequestMutationFn = Apollo.MutationFunction<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>;

/**
 * __useAcceptMembershipRequestMutation__
 *
 * To run a mutation, you first call `useAcceptMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMembershipRequestMutation, { data, loading, error }] = useAcceptMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAcceptMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>(AcceptMembershipRequestDocument, options);
      }
export type AcceptMembershipRequestMutationHookResult = ReturnType<typeof useAcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationResult = Apollo.MutationResult<AcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationOptions = Apollo.BaseMutationOptions<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>;
export const AddMemberRoleToMembersDocument = gql`
    mutation AddMemberRoleToMembers($input: AddMemberRoleToMembersInput!) {
  addMemberRoleToMembers(input: $input) {
    uuid
  }
}
    `;
export type AddMemberRoleToMembersMutationFn = Apollo.MutationFunction<AddMemberRoleToMembersMutation, AddMemberRoleToMembersMutationVariables>;

/**
 * __useAddMemberRoleToMembersMutation__
 *
 * To run a mutation, you first call `useAddMemberRoleToMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberRoleToMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberRoleToMembersMutation, { data, loading, error }] = useAddMemberRoleToMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberRoleToMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberRoleToMembersMutation, AddMemberRoleToMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberRoleToMembersMutation, AddMemberRoleToMembersMutationVariables>(AddMemberRoleToMembersDocument, options);
      }
export type AddMemberRoleToMembersMutationHookResult = ReturnType<typeof useAddMemberRoleToMembersMutation>;
export type AddMemberRoleToMembersMutationResult = Apollo.MutationResult<AddMemberRoleToMembersMutation>;
export type AddMemberRoleToMembersMutationOptions = Apollo.BaseMutationOptions<AddMemberRoleToMembersMutation, AddMemberRoleToMembersMutationVariables>;
export const ApproveMembershipRequestDocument = gql`
    mutation ApproveMembershipRequest($uuid: UUID!) {
      approveMembershipRequest(uuid: $uuid) {
    isActivated
        membershipRequest {
      uuid
    }
    membershipAgreement {
      uuid
      contractor {
        uuid
      }
    }
  }
}
    `;
export type ApproveMembershipRequestMutationFn = Apollo.MutationFunction<ApproveMembershipRequestMutation, ApproveMembershipRequestMutationVariables>;

/**
 * __useApproveMembershipRequestMutation__
 *
 * To run a mutation, you first call `useApproveMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMembershipRequestMutation, { data, loading, error }] = useApproveMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useApproveMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveMembershipRequestMutation, ApproveMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ApproveMembershipRequestMutation, ApproveMembershipRequestMutationVariables>(ApproveMembershipRequestDocument, options);
      }
export type ApproveMembershipRequestMutationHookResult = ReturnType<typeof useApproveMembershipRequestMutation>;
export type ApproveMembershipRequestMutationResult = Apollo.MutationResult<ApproveMembershipRequestMutation>;
export type ApproveMembershipRequestMutationOptions = Apollo.BaseMutationOptions<ApproveMembershipRequestMutation, ApproveMembershipRequestMutationVariables>;
export const AttendedEventRegistrationDocument = gql`
    mutation AttendedEventRegistration($uuid: UUID!) {
  updateEventRegistrationToAttended(uuid: $uuid)
}
    `;
export type AttendedEventRegistrationMutationFn = Apollo.MutationFunction<AttendedEventRegistrationMutation, AttendedEventRegistrationMutationVariables>;

/**
 * __useAttendedEventRegistrationMutation__
 *
 * To run a mutation, you first call `useAttendedEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendedEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendedEventRegistrationMutation, { data, loading, error }] = useAttendedEventRegistrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAttendedEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<AttendedEventRegistrationMutation, AttendedEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttendedEventRegistrationMutation, AttendedEventRegistrationMutationVariables>(AttendedEventRegistrationDocument, options);
      }
export type AttendedEventRegistrationMutationHookResult = ReturnType<typeof useAttendedEventRegistrationMutation>;
export type AttendedEventRegistrationMutationResult = Apollo.MutationResult<AttendedEventRegistrationMutation>;
export type AttendedEventRegistrationMutationOptions = Apollo.BaseMutationOptions<AttendedEventRegistrationMutation, AttendedEventRegistrationMutationVariables>;
export const CancelEventDocument = gql`
    mutation cancelEvent($eventId: String!) {
  cancelEvent(eventId: $eventId) {
    uuid
  }
}
    `;
export type CancelEventMutationFn = Apollo.MutationFunction<CancelEventMutation, CancelEventMutationVariables>;

/**
 * __useCancelEventMutation__
 *
 * To run a mutation, you first call `useCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventMutation, { data, loading, error }] = useCancelEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCancelEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelEventMutation, CancelEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelEventMutation, CancelEventMutationVariables>(CancelEventDocument, options);
      }
export type CancelEventMutationHookResult = ReturnType<typeof useCancelEventMutation>;
export type CancelEventMutationResult = Apollo.MutationResult<CancelEventMutation>;
export type CancelEventMutationOptions = Apollo.BaseMutationOptions<CancelEventMutation, CancelEventMutationVariables>;
export const CancelEventRegistrationDocument = gql`
    mutation CancelEventRegistration($uuid: UUID!) {
  cancelEventRegistration(uuid: $uuid)
}
    `;
export type CancelEventRegistrationMutationFn = Apollo.MutationFunction<CancelEventRegistrationMutation, CancelEventRegistrationMutationVariables>;

/**
 * __useCancelEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCancelEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventRegistrationMutation, { data, loading, error }] = useCancelEventRegistrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCancelEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CancelEventRegistrationMutation, CancelEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelEventRegistrationMutation, CancelEventRegistrationMutationVariables>(CancelEventRegistrationDocument, options);
      }
export type CancelEventRegistrationMutationHookResult = ReturnType<typeof useCancelEventRegistrationMutation>;
export type CancelEventRegistrationMutationResult = Apollo.MutationResult<CancelEventRegistrationMutation>;
export type CancelEventRegistrationMutationOptions = Apollo.BaseMutationOptions<CancelEventRegistrationMutation, CancelEventRegistrationMutationVariables>;
export const ConfirmEmailAddressUpdateDocument = gql`
    mutation ConfirmEmailAddressUpdate($input: ConfirmEmailAddressUpdateInput!) {
  confirmEmailAddressUpdate(input: $input)
}
    `;
export type ConfirmEmailAddressUpdateMutationFn = Apollo.MutationFunction<ConfirmEmailAddressUpdateMutation, ConfirmEmailAddressUpdateMutationVariables>;

/**
 * __useConfirmEmailAddressUpdateMutation__
 *
 * To run a mutation, you first call `useConfirmEmailAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailAddressUpdateMutation, { data, loading, error }] = useConfirmEmailAddressUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailAddressUpdateMutation, ConfirmEmailAddressUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ConfirmEmailAddressUpdateMutation, ConfirmEmailAddressUpdateMutationVariables>(ConfirmEmailAddressUpdateDocument, options);
}
export type ConfirmEmailAddressUpdateMutationHookResult = ReturnType<typeof useConfirmEmailAddressUpdateMutation>;
export type ConfirmEmailAddressUpdateMutationResult = Apollo.MutationResult<ConfirmEmailAddressUpdateMutation>;
export type ConfirmEmailAddressUpdateMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailAddressUpdateMutation, ConfirmEmailAddressUpdateMutationVariables>;
export const ConfirmEventDocument = gql`
    mutation ConfirmEvent($eventId: String!) {
  confirmEvent(eventId: $eventId) {
    uuid
  }
}
    `;
export type ConfirmEventMutationFn = Apollo.MutationFunction<ConfirmEventMutation, ConfirmEventMutationVariables>;

/**
 * __useConfirmEventMutation__
 *
 * To run a mutation, you first call `useConfirmEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEventMutation, { data, loading, error }] = useConfirmEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useConfirmEventMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEventMutation, ConfirmEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEventMutation, ConfirmEventMutationVariables>(ConfirmEventDocument, options);
      }
export type ConfirmEventMutationHookResult = ReturnType<typeof useConfirmEventMutation>;
export type ConfirmEventMutationResult = Apollo.MutationResult<ConfirmEventMutation>;
export type ConfirmEventMutationOptions = Apollo.BaseMutationOptions<ConfirmEventMutation, ConfirmEventMutationVariables>;
export const ConfirmEventRegistrationDocument = gql`
    mutation ConfirmEventRegistration($uuid: UUID!) {
  confirmEventRegistration(uuid: $uuid)
}
    `;
export type ConfirmEventRegistrationMutationFn = Apollo.MutationFunction<ConfirmEventRegistrationMutation, ConfirmEventRegistrationMutationVariables>;

/**
 * __useConfirmEventRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEventRegistrationMutation, { data, loading, error }] = useConfirmEventRegistrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useConfirmEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEventRegistrationMutation, ConfirmEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEventRegistrationMutation, ConfirmEventRegistrationMutationVariables>(ConfirmEventRegistrationDocument, options);
      }
export type ConfirmEventRegistrationMutationHookResult = ReturnType<typeof useConfirmEventRegistrationMutation>;
export type ConfirmEventRegistrationMutationResult = Apollo.MutationResult<ConfirmEventRegistrationMutation>;
export type ConfirmEventRegistrationMutationOptions = Apollo.BaseMutationOptions<ConfirmEventRegistrationMutation, ConfirmEventRegistrationMutationVariables>;
export const CreateApprovalConfigurationDocument = gql`
    mutation CreateApprovalConfiguration($input: ApprovalConfigurationInput!) {
  createApprovalConfiguration(input: $input) {
    uuid
  }
}
    `;
export type CreateApprovalConfigurationMutationFn = Apollo.MutationFunction<CreateApprovalConfigurationMutation, CreateApprovalConfigurationMutationVariables>;

/**
 * __useCreateApprovalConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateApprovalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalConfigurationMutation, { data, loading, error }] = useCreateApprovalConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateApprovalConfigurationMutation, CreateApprovalConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApprovalConfigurationMutation, CreateApprovalConfigurationMutationVariables>(CreateApprovalConfigurationDocument, options);
      }
export type CreateApprovalConfigurationMutationHookResult = ReturnType<typeof useCreateApprovalConfigurationMutation>;
export type CreateApprovalConfigurationMutationResult = Apollo.MutationResult<CreateApprovalConfigurationMutation>;
export type CreateApprovalConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateApprovalConfigurationMutation, CreateApprovalConfigurationMutationVariables>;
export const CreateBenchmarkDocument = gql`
    mutation CreateBenchmark($input: BenchmarkInput!) {
  createBenchmark(input: $input) {
    uuid
  }
}
    `;
export type CreateBenchmarkMutationFn = Apollo.MutationFunction<CreateBenchmarkMutation, CreateBenchmarkMutationVariables>;

/**
 * __useCreateBenchmarkMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkMutation, { data, loading, error }] = useCreateBenchmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenchmarkMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenchmarkMutation, CreateBenchmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenchmarkMutation, CreateBenchmarkMutationVariables>(CreateBenchmarkDocument, options);
      }
export type CreateBenchmarkMutationHookResult = ReturnType<typeof useCreateBenchmarkMutation>;
export type CreateBenchmarkMutationResult = Apollo.MutationResult<CreateBenchmarkMutation>;
export type CreateBenchmarkMutationOptions = Apollo.BaseMutationOptions<CreateBenchmarkMutation, CreateBenchmarkMutationVariables>;
export const CreateBenchmarkResultDocument = gql`
    mutation CreateBenchmarkResult($benchmarkUUID: UUID!, $input: BenchmarkResultInput!) {
  createBenchmarkResult(benchmarkUUID: $benchmarkUUID, input: $input) {
    uuid
  }
}
    `;
export type CreateBenchmarkResultMutationFn = Apollo.MutationFunction<CreateBenchmarkResultMutation, CreateBenchmarkResultMutationVariables>;

/**
 * __useCreateBenchmarkResultMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkResultMutation, { data, loading, error }] = useCreateBenchmarkResultMutation({
 *   variables: {
 *      benchmarkUUID: // value for 'benchmarkUUID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenchmarkResultMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenchmarkResultMutation, CreateBenchmarkResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenchmarkResultMutation, CreateBenchmarkResultMutationVariables>(CreateBenchmarkResultDocument, options);
      }
export type CreateBenchmarkResultMutationHookResult = ReturnType<typeof useCreateBenchmarkResultMutation>;
export type CreateBenchmarkResultMutationResult = Apollo.MutationResult<CreateBenchmarkResultMutation>;
export type CreateBenchmarkResultMutationOptions = Apollo.BaseMutationOptions<CreateBenchmarkResultMutation, CreateBenchmarkResultMutationVariables>;
export const CreateEmailAddressUpdateDocument = gql`
    mutation CreateEmailAddressUpdate($input: CreateEmailAddressUpdateInput!) {
  createEmailAddressUpdate(input: $input)
}
    `;
export type CreateEmailAddressUpdateMutationFn = Apollo.MutationFunction<CreateEmailAddressUpdateMutation, CreateEmailAddressUpdateMutationVariables>;

/**
 * __useCreateEmailAddressUpdateMutation__
 *
 * To run a mutation, you first call `useCreateEmailAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailAddressUpdateMutation, { data, loading, error }] = useCreateEmailAddressUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailAddressUpdateMutation, CreateEmailAddressUpdateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateEmailAddressUpdateMutation, CreateEmailAddressUpdateMutationVariables>(CreateEmailAddressUpdateDocument, options);
}
export type CreateEmailAddressUpdateMutationHookResult = ReturnType<typeof useCreateEmailAddressUpdateMutation>;
export type CreateEmailAddressUpdateMutationResult = Apollo.MutationResult<CreateEmailAddressUpdateMutation>;
export type CreateEmailAddressUpdateMutationOptions = Apollo.BaseMutationOptions<CreateEmailAddressUpdateMutation, CreateEmailAddressUpdateMutationVariables>;
export const CreateEventDetailsDocument = gql`
    mutation CreateEventDetails($input: EventDetailsInput!) {
  createEventDetails(input: $input) {
    uuid
    schedule {
      firstStart
    }
  }
}
    `;
export type CreateEventDetailsMutationFn = Apollo.MutationFunction<CreateEventDetailsMutation, CreateEventDetailsMutationVariables>;

/**
 * __useCreateEventDetailsMutation__
 *
 * To run a mutation, you first call `useCreateEventDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventDetailsMutation, { data, loading, error }] = useCreateEventDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventDetailsMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventDetailsMutation, CreateEventDetailsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateEventDetailsMutation, CreateEventDetailsMutationVariables>(CreateEventDetailsDocument, options);
}
export type CreateEventDetailsMutationHookResult = ReturnType<typeof useCreateEventDetailsMutation>;
export type CreateEventDetailsMutationResult = Apollo.MutationResult<CreateEventDetailsMutation>;
export type CreateEventDetailsMutationOptions = Apollo.BaseMutationOptions<CreateEventDetailsMutation, CreateEventDetailsMutationVariables>;
export const CreateEventRegistrationDocument = gql`
    mutation CreateEventRegistration($eventId: String!, $input: CreateEventRegistrationInput!) {
  createEventRegistration(eventId: $eventId, input: $input) {
    uuid
  }
}
    `;
export type CreateEventRegistrationMutationFn = Apollo.MutationFunction<CreateEventRegistrationMutation, CreateEventRegistrationMutationVariables>;

/**
 * __useCreateEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventRegistrationMutation, { data, loading, error }] = useCreateEventRegistrationMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventRegistrationMutation, CreateEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventRegistrationMutation, CreateEventRegistrationMutationVariables>(CreateEventRegistrationDocument, options);
      }
export type CreateEventRegistrationMutationHookResult = ReturnType<typeof useCreateEventRegistrationMutation>;
export type CreateEventRegistrationMutationResult = Apollo.MutationResult<CreateEventRegistrationMutation>;
export type CreateEventRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateEventRegistrationMutation, CreateEventRegistrationMutationVariables>;
export const CreateMemberRoleDocument = gql`
    mutation CreateMemberRole($input: MemberRoleInput!) {
  createMemberRole(input: $input) {
    uuid
  }
}
    `;
export type CreateMemberRoleMutationFn = Apollo.MutationFunction<CreateMemberRoleMutation, CreateMemberRoleMutationVariables>;

/**
 * __useCreateMemberRoleMutation__
 *
 * To run a mutation, you first call `useCreateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberRoleMutation, { data, loading, error }] = useCreateMemberRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberRoleMutation, CreateMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberRoleMutation, CreateMemberRoleMutationVariables>(CreateMemberRoleDocument, options);
      }
export type CreateMemberRoleMutationHookResult = ReturnType<typeof useCreateMemberRoleMutation>;
export type CreateMemberRoleMutationResult = Apollo.MutationResult<CreateMemberRoleMutation>;
export type CreateMemberRoleMutationOptions = Apollo.BaseMutationOptions<CreateMemberRoleMutation, CreateMemberRoleMutationVariables>;
export const CreateMembershipRequestFromTemplateDocument = gql`
    mutation CreateMembershipRequestFromTemplate($membershipAgreementTemplateUUID: UUID!) {
      createMembershipRequestFromTemplate(
    membershipAgreementTemplateUUID: $membershipAgreementTemplateUUID
  ) {
    isActivated
    membershipAgreement {
      uuid
    }
        membershipRequest {
      uuid
    }
  }
}
    `;
export type CreateMembershipRequestFromTemplateMutationFn = Apollo.MutationFunction<CreateMembershipRequestFromTemplateMutation, CreateMembershipRequestFromTemplateMutationVariables>;

/**
 * __useCreateMembershipRequestFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMembershipRequestFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipRequestFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipRequestFromTemplateMutation, { data, loading, error }] = useCreateMembershipRequestFromTemplateMutation({
 *   variables: {
 *      membershipAgreementTemplateUUID: // value for 'membershipAgreementTemplateUUID'
 *   },
 * });
 */
export function useCreateMembershipRequestFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipRequestFromTemplateMutation, CreateMembershipRequestFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateMembershipRequestFromTemplateMutation, CreateMembershipRequestFromTemplateMutationVariables>(CreateMembershipRequestFromTemplateDocument, options);
      }
export type CreateMembershipRequestFromTemplateMutationHookResult = ReturnType<typeof useCreateMembershipRequestFromTemplateMutation>;
export type CreateMembershipRequestFromTemplateMutationResult = Apollo.MutationResult<CreateMembershipRequestFromTemplateMutation>;
export type CreateMembershipRequestFromTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMembershipRequestFromTemplateMutation, CreateMembershipRequestFromTemplateMutationVariables>;
export const CreateMembershipRequestFromTemplateForFollowUpDocument = gql`
    mutation CreateMembershipRequestFromTemplateForFollowUp($membershipAgreementTemplateUUID: UUID!, $membershipAgreementUUID: UUID!) {
      createMembershipRequestFromTemplateForFollowUp(
    membershipAgreementTemplateUUID: $membershipAgreementTemplateUUID
    membershipAgreementUUID: $membershipAgreementUUID
  ) {
    uuid
  }
}
    `;
export type CreateMembershipRequestFromTemplateForFollowUpMutationFn = Apollo.MutationFunction<CreateMembershipRequestFromTemplateForFollowUpMutation, CreateMembershipRequestFromTemplateForFollowUpMutationVariables>;

/**
 * __useCreateMembershipRequestFromTemplateForFollowUpMutation__
 *
 * To run a mutation, you first call `useCreateMembershipRequestFromTemplateForFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipRequestFromTemplateForFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipRequestFromTemplateForFollowUpMutation, { data, loading, error }] = useCreateMembershipRequestFromTemplateForFollowUpMutation({
 *   variables: {
 *      membershipAgreementTemplateUUID: // value for 'membershipAgreementTemplateUUID'
 *      membershipAgreementUUID: // value for 'membershipAgreementUUID'
 *   },
 * });
 */
export function useCreateMembershipRequestFromTemplateForFollowUpMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipRequestFromTemplateForFollowUpMutation, CreateMembershipRequestFromTemplateForFollowUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateMembershipRequestFromTemplateForFollowUpMutation, CreateMembershipRequestFromTemplateForFollowUpMutationVariables>(CreateMembershipRequestFromTemplateForFollowUpDocument, options);
      }
export type CreateMembershipRequestFromTemplateForFollowUpMutationHookResult = ReturnType<typeof useCreateMembershipRequestFromTemplateForFollowUpMutation>;
export type CreateMembershipRequestFromTemplateForFollowUpMutationResult = Apollo.MutationResult<CreateMembershipRequestFromTemplateForFollowUpMutation>;
export type CreateMembershipRequestFromTemplateForFollowUpMutationOptions = Apollo.BaseMutationOptions<CreateMembershipRequestFromTemplateForFollowUpMutation, CreateMembershipRequestFromTemplateForFollowUpMutationVariables>;
export const CreateMembershipTemplateDocument = gql`
    mutation CreateMembershipTemplate($input: MembershipAgreementTemplateInput!) {
  createMembershipAgreementTemplate(input: $input) {
    uuid
  }
}
    `;
export type CreateMembershipTemplateMutationFn = Apollo.MutationFunction<CreateMembershipTemplateMutation, CreateMembershipTemplateMutationVariables>;

/**
 * __useCreateMembershipTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMembershipTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembershipTemplateMutation, { data, loading, error }] = useCreateMembershipTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMembershipTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipTemplateMutation, CreateMembershipTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMembershipTemplateMutation, CreateMembershipTemplateMutationVariables>(CreateMembershipTemplateDocument, options);
      }
export type CreateMembershipTemplateMutationHookResult = ReturnType<typeof useCreateMembershipTemplateMutation>;
export type CreateMembershipTemplateMutationResult = Apollo.MutationResult<CreateMembershipTemplateMutation>;
export type CreateMembershipTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMembershipTemplateMutation, CreateMembershipTemplateMutationVariables>;
export const CreatePasswordResetDocument = gql`
    mutation CreatePasswordReset($input: CreatePasswordResetInput!) {
  createPasswordReset(input: $input)
}
    `;
export type CreatePasswordResetMutationFn = Apollo.MutationFunction<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>;

/**
 * __useCreatePasswordResetMutation__
 *
 * To run a mutation, you first call `useCreatePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordResetMutation, { data, loading, error }] = useCreatePasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>(CreatePasswordResetDocument, options);
}
export type CreatePasswordResetMutationHookResult = ReturnType<typeof useCreatePasswordResetMutation>;
export type CreatePasswordResetMutationResult = Apollo.MutationResult<CreatePasswordResetMutation>;
export type CreatePasswordResetMutationOptions = Apollo.BaseMutationOptions<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation CreatePaymentMethod($membershipAgreementPaymentMethodInput: MembershipAgreementPaymentMethodInput!) {
  createMembershipAgreementPaymentMethod(
    input: $membershipAgreementPaymentMethodInput
  ) {
    uuid
  }
}
    `;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      membershipAgreementPaymentMethodInput: // value for 'membershipAgreementPaymentMethodInput'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const CreateVisitorEventRegistrationDocument = gql`
    mutation CreateVisitorEventRegistration($eventId: String!, $input: CreateVisitorEventRegistrationInput!) {
  createVisitorEventRegistration(eventId: $eventId, input: $input) {
    uuid
  }
}
    `;
export type CreateVisitorEventRegistrationMutationFn = Apollo.MutationFunction<CreateVisitorEventRegistrationMutation, CreateVisitorEventRegistrationMutationVariables>;

/**
 * __useCreateVisitorEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateVisitorEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitorEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitorEventRegistrationMutation, { data, loading, error }] = useCreateVisitorEventRegistrationMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisitorEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateVisitorEventRegistrationMutation, CreateVisitorEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateVisitorEventRegistrationMutation, CreateVisitorEventRegistrationMutationVariables>(CreateVisitorEventRegistrationDocument, options);
      }
export type CreateVisitorEventRegistrationMutationHookResult = ReturnType<typeof useCreateVisitorEventRegistrationMutation>;
export type CreateVisitorEventRegistrationMutationResult = Apollo.MutationResult<CreateVisitorEventRegistrationMutation>;
export type CreateVisitorEventRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateVisitorEventRegistrationMutation, CreateVisitorEventRegistrationMutationVariables>;
export const CreateWebPushSubscriptionDocument = gql`
    mutation CreateWebPushSubscription($input: WebPushSubscriptionInput!) {
  createWebPushSubscription(input: $input) {
    uuid
  }
}
    `;
export type CreateWebPushSubscriptionMutationFn = Apollo.MutationFunction<CreateWebPushSubscriptionMutation, CreateWebPushSubscriptionMutationVariables>;

/**
 * __useCreateWebPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateWebPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebPushSubscriptionMutation, { data, loading, error }] = useCreateWebPushSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebPushSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebPushSubscriptionMutation, CreateWebPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebPushSubscriptionMutation, CreateWebPushSubscriptionMutationVariables>(CreateWebPushSubscriptionDocument, options);
      }
export type CreateWebPushSubscriptionMutationHookResult = ReturnType<typeof useCreateWebPushSubscriptionMutation>;
export type CreateWebPushSubscriptionMutationResult = Apollo.MutationResult<CreateWebPushSubscriptionMutation>;
export type CreateWebPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateWebPushSubscriptionMutation, CreateWebPushSubscriptionMutationVariables>;
export const CreateWorkoutDocument = gql`
    mutation CreateWorkout($input: WorkoutInput!) {
  createWorkout(input: $input) {
    uuid
  }
}
    `;
export type CreateWorkoutMutationFn = Apollo.MutationFunction<CreateWorkoutMutation, CreateWorkoutMutationVariables>;

/**
 * __useCreateWorkoutMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutMutation, { data, loading, error }] = useCreateWorkoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkoutMutation, CreateWorkoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkoutMutation, CreateWorkoutMutationVariables>(CreateWorkoutDocument, options);
      }
export type CreateWorkoutMutationHookResult = ReturnType<typeof useCreateWorkoutMutation>;
export type CreateWorkoutMutationResult = Apollo.MutationResult<CreateWorkoutMutation>;
export type CreateWorkoutMutationOptions = Apollo.BaseMutationOptions<CreateWorkoutMutation, CreateWorkoutMutationVariables>;
export const CreateWorkoutResultDocument = gql`
    mutation CreateWorkoutResult($scoreBoardUUID: UUID!, $input: WorkoutResultInput!) {
  createWorkoutResult(workoutScoreBoardUUID: $scoreBoardUUID, input: $input) {
    uuid
  }
}
    `;
export type CreateWorkoutResultMutationFn = Apollo.MutationFunction<CreateWorkoutResultMutation, CreateWorkoutResultMutationVariables>;

/**
 * __useCreateWorkoutResultMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutResultMutation, { data, loading, error }] = useCreateWorkoutResultMutation({
 *   variables: {
 *      scoreBoardUUID: // value for 'scoreBoardUUID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkoutResultMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkoutResultMutation, CreateWorkoutResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkoutResultMutation, CreateWorkoutResultMutationVariables>(CreateWorkoutResultDocument, options);
      }
export type CreateWorkoutResultMutationHookResult = ReturnType<typeof useCreateWorkoutResultMutation>;
export type CreateWorkoutResultMutationResult = Apollo.MutationResult<CreateWorkoutResultMutation>;
export type CreateWorkoutResultMutationOptions = Apollo.BaseMutationOptions<CreateWorkoutResultMutation, CreateWorkoutResultMutationVariables>;
export const CreateWorkoutResultCategoryDocument = gql`
    mutation CreateWorkoutResultCategory($input: WorkoutResultCategoryInput!) {
  createWorkoutResultCategory(input: $input) {
    uuid
  }
}
    `;
export type CreateWorkoutResultCategoryMutationFn = Apollo.MutationFunction<CreateWorkoutResultCategoryMutation, CreateWorkoutResultCategoryMutationVariables>;

/**
 * __useCreateWorkoutResultCategoryMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutResultCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutResultCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutResultCategoryMutation, { data, loading, error }] = useCreateWorkoutResultCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkoutResultCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkoutResultCategoryMutation, CreateWorkoutResultCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkoutResultCategoryMutation, CreateWorkoutResultCategoryMutationVariables>(CreateWorkoutResultCategoryDocument, options);
      }
export type CreateWorkoutResultCategoryMutationHookResult = ReturnType<typeof useCreateWorkoutResultCategoryMutation>;
export type CreateWorkoutResultCategoryMutationResult = Apollo.MutationResult<CreateWorkoutResultCategoryMutation>;
export type CreateWorkoutResultCategoryMutationOptions = Apollo.BaseMutationOptions<CreateWorkoutResultCategoryMutation, CreateWorkoutResultCategoryMutationVariables>;
export const CreateWorkoutResultReactionDocument = gql`
    mutation CreateWorkoutResultReaction($workoutResultUUID: UUID!, $input: WorkoutResultReactionInput!) {
  createWorkoutResultReaction(
    workoutResultUUID: $workoutResultUUID
    input: $input
  ) {
    uuid
  }
}
    `;
export type CreateWorkoutResultReactionMutationFn = Apollo.MutationFunction<CreateWorkoutResultReactionMutation, CreateWorkoutResultReactionMutationVariables>;

/**
 * __useCreateWorkoutResultReactionMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutResultReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutResultReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutResultReactionMutation, { data, loading, error }] = useCreateWorkoutResultReactionMutation({
 *   variables: {
 *      workoutResultUUID: // value for 'workoutResultUUID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkoutResultReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkoutResultReactionMutation, CreateWorkoutResultReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkoutResultReactionMutation, CreateWorkoutResultReactionMutationVariables>(CreateWorkoutResultReactionDocument, options);
      }
export type CreateWorkoutResultReactionMutationHookResult = ReturnType<typeof useCreateWorkoutResultReactionMutation>;
export type CreateWorkoutResultReactionMutationResult = Apollo.MutationResult<CreateWorkoutResultReactionMutation>;
export type CreateWorkoutResultReactionMutationOptions = Apollo.BaseMutationOptions<CreateWorkoutResultReactionMutation, CreateWorkoutResultReactionMutationVariables>;
export const CreateWorkoutTypeDocument = gql`
    mutation CreateWorkoutType($input: WorkoutTypeInput!) {
  createWorkoutType(input: $input) {
    uuid
  }
}
    `;
export type CreateWorkoutTypeMutationFn = Apollo.MutationFunction<CreateWorkoutTypeMutation, CreateWorkoutTypeMutationVariables>;

/**
 * __useCreateWorkoutTypeMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutTypeMutation, { data, loading, error }] = useCreateWorkoutTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkoutTypeMutation, CreateWorkoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkoutTypeMutation, CreateWorkoutTypeMutationVariables>(CreateWorkoutTypeDocument, options);
      }
export type CreateWorkoutTypeMutationHookResult = ReturnType<typeof useCreateWorkoutTypeMutation>;
export type CreateWorkoutTypeMutationResult = Apollo.MutationResult<CreateWorkoutTypeMutation>;
export type CreateWorkoutTypeMutationOptions = Apollo.BaseMutationOptions<CreateWorkoutTypeMutation, CreateWorkoutTypeMutationVariables>;
export const UpdateWorkoutTypeDocument = gql`
    mutation UpdateWorkoutType($uuid: UUID!, $input: WorkoutTypeInput!) {
  updateWorkoutType(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateWorkoutTypeMutationFn = Apollo.MutationFunction<UpdateWorkoutTypeMutation, UpdateWorkoutTypeMutationVariables>;

/**
 * __useUpdateWorkoutTypeMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutTypeMutation, { data, loading, error }] = useUpdateWorkoutTypeMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutTypeMutation, UpdateWorkoutTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkoutTypeMutation, UpdateWorkoutTypeMutationVariables>(UpdateWorkoutTypeDocument, options);
      }
export type UpdateWorkoutTypeMutationHookResult = ReturnType<typeof useUpdateWorkoutTypeMutation>;
export type UpdateWorkoutTypeMutationResult = Apollo.MutationResult<UpdateWorkoutTypeMutation>;
export type UpdateWorkoutTypeMutationOptions = Apollo.BaseMutationOptions<UpdateWorkoutTypeMutation, UpdateWorkoutTypeMutationVariables>;
export const DeleteBenchmarkDocument = gql`
    mutation DeleteBenchmark($uuid: UUID!) {
  deleteBenchmark(uuid: $uuid)
}
    `;
export type DeleteBenchmarkMutationFn = Apollo.MutationFunction<DeleteBenchmarkMutation, DeleteBenchmarkMutationVariables>;

/**
 * __useDeleteBenchmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBenchmarkMutation, { data, loading, error }] = useDeleteBenchmarkMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteBenchmarkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBenchmarkMutation, DeleteBenchmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBenchmarkMutation, DeleteBenchmarkMutationVariables>(DeleteBenchmarkDocument, options);
      }
export type DeleteBenchmarkMutationHookResult = ReturnType<typeof useDeleteBenchmarkMutation>;
export type DeleteBenchmarkMutationResult = Apollo.MutationResult<DeleteBenchmarkMutation>;
export type DeleteBenchmarkMutationOptions = Apollo.BaseMutationOptions<DeleteBenchmarkMutation, DeleteBenchmarkMutationVariables>;
export const DeleteBenchmarkResultDocument = gql`
    mutation DeleteBenchmarkResult($uuid: UUID!) {
  deleteBenchmarkResult(uuid: $uuid)
}
    `;
export type DeleteBenchmarkResultMutationFn = Apollo.MutationFunction<DeleteBenchmarkResultMutation, DeleteBenchmarkResultMutationVariables>;

/**
 * __useDeleteBenchmarkResultMutation__
 *
 * To run a mutation, you first call `useDeleteBenchmarkResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBenchmarkResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBenchmarkResultMutation, { data, loading, error }] = useDeleteBenchmarkResultMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteBenchmarkResultMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBenchmarkResultMutation, DeleteBenchmarkResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBenchmarkResultMutation, DeleteBenchmarkResultMutationVariables>(DeleteBenchmarkResultDocument, options);
      }
export type DeleteBenchmarkResultMutationHookResult = ReturnType<typeof useDeleteBenchmarkResultMutation>;
export type DeleteBenchmarkResultMutationResult = Apollo.MutationResult<DeleteBenchmarkResultMutation>;
export type DeleteBenchmarkResultMutationOptions = Apollo.BaseMutationOptions<DeleteBenchmarkResultMutation, DeleteBenchmarkResultMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($eventId: String!) {
  deleteEvent(eventId: $eventId)
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteEventAndEndRecurrenceDocument = gql`
    mutation DeleteEventAndEndRecurrence($eventId: String!) {
  deleteEventAndEndRecurrence(eventId: $eventId)
}
    `;
export type DeleteEventAndEndRecurrenceMutationFn = Apollo.MutationFunction<DeleteEventAndEndRecurrenceMutation, DeleteEventAndEndRecurrenceMutationVariables>;

/**
 * __useDeleteEventAndEndRecurrenceMutation__
 *
 * To run a mutation, you first call `useDeleteEventAndEndRecurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventAndEndRecurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventAndEndRecurrenceMutation, { data, loading, error }] = useDeleteEventAndEndRecurrenceMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteEventAndEndRecurrenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventAndEndRecurrenceMutation, DeleteEventAndEndRecurrenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventAndEndRecurrenceMutation, DeleteEventAndEndRecurrenceMutationVariables>(DeleteEventAndEndRecurrenceDocument, options);
      }
export type DeleteEventAndEndRecurrenceMutationHookResult = ReturnType<typeof useDeleteEventAndEndRecurrenceMutation>;
export type DeleteEventAndEndRecurrenceMutationResult = Apollo.MutationResult<DeleteEventAndEndRecurrenceMutation>;
export type DeleteEventAndEndRecurrenceMutationOptions = Apollo.BaseMutationOptions<DeleteEventAndEndRecurrenceMutation, DeleteEventAndEndRecurrenceMutationVariables>;
export const DeleteEventRegistrationDocument = gql`
    mutation DeleteEventRegistration($uuid: UUID!) {
  deleteEventRegistration(uuid: $uuid)
}
    `;
export type DeleteEventRegistrationMutationFn = Apollo.MutationFunction<DeleteEventRegistrationMutation, DeleteEventRegistrationMutationVariables>;

/**
 * __useDeleteEventRegistrationMutation__
 *
 * To run a mutation, you first call `useDeleteEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventRegistrationMutation, { data, loading, error }] = useDeleteEventRegistrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventRegistrationMutation, DeleteEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventRegistrationMutation, DeleteEventRegistrationMutationVariables>(DeleteEventRegistrationDocument, options);
      }
export type DeleteEventRegistrationMutationHookResult = ReturnType<typeof useDeleteEventRegistrationMutation>;
export type DeleteEventRegistrationMutationResult = Apollo.MutationResult<DeleteEventRegistrationMutation>;
export type DeleteEventRegistrationMutationOptions = Apollo.BaseMutationOptions<DeleteEventRegistrationMutation, DeleteEventRegistrationMutationVariables>;
export const DeleteMemberRoleDocument = gql`
    mutation DeleteMemberRole($uuid: UUID!) {
  deleteMemberRole(uuid: $uuid)
}
    `;
export type DeleteMemberRoleMutationFn = Apollo.MutationFunction<DeleteMemberRoleMutation, DeleteMemberRoleMutationVariables>;

/**
 * __useDeleteMemberRoleMutation__
 *
 * To run a mutation, you first call `useDeleteMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberRoleMutation, { data, loading, error }] = useDeleteMemberRoleMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberRoleMutation, DeleteMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberRoleMutation, DeleteMemberRoleMutationVariables>(DeleteMemberRoleDocument, options);
      }
export type DeleteMemberRoleMutationHookResult = ReturnType<typeof useDeleteMemberRoleMutation>;
export type DeleteMemberRoleMutationResult = Apollo.MutationResult<DeleteMemberRoleMutation>;
export type DeleteMemberRoleMutationOptions = Apollo.BaseMutationOptions<DeleteMemberRoleMutation, DeleteMemberRoleMutationVariables>;
export const DeleteMembershipAgreementTemplateDocument = gql`
    mutation DeleteMembershipAgreementTemplate($uuid: UUID!) {
  deleteMembershipAgreementTemplate(uuid: $uuid)
}
    `;
export type DeleteMembershipAgreementTemplateMutationFn = Apollo.MutationFunction<DeleteMembershipAgreementTemplateMutation, DeleteMembershipAgreementTemplateMutationVariables>;

/**
 * __useDeleteMembershipAgreementTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipAgreementTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipAgreementTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipAgreementTemplateMutation, { data, loading, error }] = useDeleteMembershipAgreementTemplateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteMembershipAgreementTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipAgreementTemplateMutation, DeleteMembershipAgreementTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembershipAgreementTemplateMutation, DeleteMembershipAgreementTemplateMutationVariables>(DeleteMembershipAgreementTemplateDocument, options);
      }
export type DeleteMembershipAgreementTemplateMutationHookResult = ReturnType<typeof useDeleteMembershipAgreementTemplateMutation>;
export type DeleteMembershipAgreementTemplateMutationResult = Apollo.MutationResult<DeleteMembershipAgreementTemplateMutation>;
export type DeleteMembershipAgreementTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteMembershipAgreementTemplateMutation, DeleteMembershipAgreementTemplateMutationVariables>;
export const DeleteMembershipRequestDocument = gql`
    mutation DeleteMembershipRequest($uuid: UUID!) {
      deleteMembershipRequest(uuid: $uuid)
    }
`;
export type DeleteMembershipRequestMutationFn = Apollo.MutationFunction<DeleteMembershipRequestMutation, DeleteMembershipRequestMutationVariables>;

/**
 * __useDeleteMembershipRequestMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipRequestMutation, { data, loading, error }] = useDeleteMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipRequestMutation, DeleteMembershipRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteMembershipRequestMutation, DeleteMembershipRequestMutationVariables>(DeleteMembershipRequestDocument, options);
}
export type DeleteMembershipRequestMutationHookResult = ReturnType<typeof useDeleteMembershipRequestMutation>;
export type DeleteMembershipRequestMutationResult = Apollo.MutationResult<DeleteMembershipRequestMutation>;
export type DeleteMembershipRequestMutationOptions = Apollo.BaseMutationOptions<DeleteMembershipRequestMutation, DeleteMembershipRequestMutationVariables>;
export const EndPenaltyDocument = gql`
    mutation EndPenalty($uuid: UUID!) {
  endPenalty(uuid: $uuid) {
    uuid
  }
}
    `;
export type EndPenaltyMutationFn = Apollo.MutationFunction<EndPenaltyMutation, EndPenaltyMutationVariables>;

/**
 * __useEndPenaltyMutation__
 *
 * To run a mutation, you first call `useEndPenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndPenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endPenaltyMutation, { data, loading, error }] = useEndPenaltyMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEndPenaltyMutation(baseOptions?: Apollo.MutationHookOptions<EndPenaltyMutation, EndPenaltyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndPenaltyMutation, EndPenaltyMutationVariables>(EndPenaltyDocument, options);
      }
export type EndPenaltyMutationHookResult = ReturnType<typeof useEndPenaltyMutation>;
export type EndPenaltyMutationResult = Apollo.MutationResult<EndPenaltyMutation>;
export type EndPenaltyMutationOptions = Apollo.BaseMutationOptions<EndPenaltyMutation, EndPenaltyMutationVariables>;
export const DeleteWebPushSubscriptionDocument = gql`
    mutation deleteWebPushSubscription($uuid: UUID!) {
  deleteWebPushSubscription(uuid: $uuid)
}
    `;
export type DeleteWebPushSubscriptionMutationFn = Apollo.MutationFunction<DeleteWebPushSubscriptionMutation, DeleteWebPushSubscriptionMutationVariables>;

/**
 * __useDeleteWebPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteWebPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebPushSubscriptionMutation, { data, loading, error }] = useDeleteWebPushSubscriptionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteWebPushSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebPushSubscriptionMutation, DeleteWebPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWebPushSubscriptionMutation, DeleteWebPushSubscriptionMutationVariables>(DeleteWebPushSubscriptionDocument, options);
      }
export type DeleteWebPushSubscriptionMutationHookResult = ReturnType<typeof useDeleteWebPushSubscriptionMutation>;
export type DeleteWebPushSubscriptionMutationResult = Apollo.MutationResult<DeleteWebPushSubscriptionMutation>;
export type DeleteWebPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteWebPushSubscriptionMutation, DeleteWebPushSubscriptionMutationVariables>;
export const DeleteWorkoutDocument = gql`
    mutation DeleteWorkout($uuid: UUID!) {
  deleteWorkout(uuid: $uuid)
}
    `;
export type DeleteWorkoutMutationFn = Apollo.MutationFunction<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>;

/**
 * __useDeleteWorkoutMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutMutation, { data, loading, error }] = useDeleteWorkoutMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteWorkoutMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>(DeleteWorkoutDocument, options);
      }
export type DeleteWorkoutMutationHookResult = ReturnType<typeof useDeleteWorkoutMutation>;
export type DeleteWorkoutMutationResult = Apollo.MutationResult<DeleteWorkoutMutation>;
export type DeleteWorkoutMutationOptions = Apollo.BaseMutationOptions<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>;
export const DeleteWorkoutResultDocument = gql`
    mutation DeleteWorkoutResult($uuid: UUID!) {
  deleteWorkoutResult(uuid: $uuid)
}
    `;
export type DeleteWorkoutResultMutationFn = Apollo.MutationFunction<DeleteWorkoutResultMutation, DeleteWorkoutResultMutationVariables>;

/**
 * __useDeleteWorkoutResultMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutResultMutation, { data, loading, error }] = useDeleteWorkoutResultMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteWorkoutResultMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkoutResultMutation, DeleteWorkoutResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkoutResultMutation, DeleteWorkoutResultMutationVariables>(DeleteWorkoutResultDocument, options);
      }
export type DeleteWorkoutResultMutationHookResult = ReturnType<typeof useDeleteWorkoutResultMutation>;
export type DeleteWorkoutResultMutationResult = Apollo.MutationResult<DeleteWorkoutResultMutation>;
export type DeleteWorkoutResultMutationOptions = Apollo.BaseMutationOptions<DeleteWorkoutResultMutation, DeleteWorkoutResultMutationVariables>;
export const DeleteWorkoutResultReactionDocument = gql`
    mutation DeleteWorkoutResultReaction($uuid: UUID!) {
  deleteWorkoutResultReaction(uuid: $uuid)
}
    `;
export type DeleteWorkoutResultReactionMutationFn = Apollo.MutationFunction<DeleteWorkoutResultReactionMutation, DeleteWorkoutResultReactionMutationVariables>;

/**
 * __useDeleteWorkoutResultReactionMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutResultReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutResultReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutResultReactionMutation, { data, loading, error }] = useDeleteWorkoutResultReactionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteWorkoutResultReactionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkoutResultReactionMutation, DeleteWorkoutResultReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkoutResultReactionMutation, DeleteWorkoutResultReactionMutationVariables>(DeleteWorkoutResultReactionDocument, options);
      }
export type DeleteWorkoutResultReactionMutationHookResult = ReturnType<typeof useDeleteWorkoutResultReactionMutation>;
export type DeleteWorkoutResultReactionMutationResult = Apollo.MutationResult<DeleteWorkoutResultReactionMutation>;
export type DeleteWorkoutResultReactionMutationOptions = Apollo.BaseMutationOptions<DeleteWorkoutResultReactionMutation, DeleteWorkoutResultReactionMutationVariables>;
export const ForceMembershipAgreementTerminationDocument = gql`
    mutation ForceMembershipAgreementTermination($uuid: UUID!, $endDate: LocalDate) {
  forceMembershipAgreementTermination(uuid: $uuid, endDate: $endDate) {
    uuid
  }
}
    `;
export type ForceMembershipAgreementTerminationMutationFn = Apollo.MutationFunction<ForceMembershipAgreementTerminationMutation, ForceMembershipAgreementTerminationMutationVariables>;

/**
 * __useForceMembershipAgreementTerminationMutation__
 *
 * To run a mutation, you first call `useForceMembershipAgreementTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceMembershipAgreementTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceMembershipAgreementTerminationMutation, { data, loading, error }] = useForceMembershipAgreementTerminationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useForceMembershipAgreementTerminationMutation(baseOptions?: Apollo.MutationHookOptions<ForceMembershipAgreementTerminationMutation, ForceMembershipAgreementTerminationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceMembershipAgreementTerminationMutation, ForceMembershipAgreementTerminationMutationVariables>(ForceMembershipAgreementTerminationDocument, options);
      }
export type ForceMembershipAgreementTerminationMutationHookResult = ReturnType<typeof useForceMembershipAgreementTerminationMutation>;
export type ForceMembershipAgreementTerminationMutationResult = Apollo.MutationResult<ForceMembershipAgreementTerminationMutation>;
export type ForceMembershipAgreementTerminationMutationOptions = Apollo.BaseMutationOptions<ForceMembershipAgreementTerminationMutation, ForceMembershipAgreementTerminationMutationVariables>;
export const MarkAllInAppNotificationAsSeenDocument = gql`
    mutation MarkAllInAppNotificationAsSeen {
  markAllInAppNotificationAsSeen
}
    `;
export type MarkAllInAppNotificationAsSeenMutationFn = Apollo.MutationFunction<MarkAllInAppNotificationAsSeenMutation, MarkAllInAppNotificationAsSeenMutationVariables>;

/**
 * __useMarkAllInAppNotificationAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAllInAppNotificationAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllInAppNotificationAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllInAppNotificationAsSeenMutation, { data, loading, error }] = useMarkAllInAppNotificationAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllInAppNotificationAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllInAppNotificationAsSeenMutation, MarkAllInAppNotificationAsSeenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkAllInAppNotificationAsSeenMutation, MarkAllInAppNotificationAsSeenMutationVariables>(MarkAllInAppNotificationAsSeenDocument, options);
}
export type MarkAllInAppNotificationAsSeenMutationHookResult = ReturnType<typeof useMarkAllInAppNotificationAsSeenMutation>;
export type MarkAllInAppNotificationAsSeenMutationResult = Apollo.MutationResult<MarkAllInAppNotificationAsSeenMutation>;
export type MarkAllInAppNotificationAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkAllInAppNotificationAsSeenMutation, MarkAllInAppNotificationAsSeenMutationVariables>;
export const MarkInAppNotificationAsSeenDocument = gql`
    mutation MarkInAppNotificationAsSeen($uuid: UUID!) {
  markInAppNotificationAsSeen(uuid: $uuid)
}
    `;
export type MarkInAppNotificationAsSeenMutationFn = Apollo.MutationFunction<MarkInAppNotificationAsSeenMutation, MarkInAppNotificationAsSeenMutationVariables>;

/**
 * __useMarkInAppNotificationAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkInAppNotificationAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInAppNotificationAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInAppNotificationAsSeenMutation, { data, loading, error }] = useMarkInAppNotificationAsSeenMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMarkInAppNotificationAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkInAppNotificationAsSeenMutation, MarkInAppNotificationAsSeenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkInAppNotificationAsSeenMutation, MarkInAppNotificationAsSeenMutationVariables>(MarkInAppNotificationAsSeenDocument, options);
}
export type MarkInAppNotificationAsSeenMutationHookResult = ReturnType<typeof useMarkInAppNotificationAsSeenMutation>;
export type MarkInAppNotificationAsSeenMutationResult = Apollo.MutationResult<MarkInAppNotificationAsSeenMutation>;
export type MarkInAppNotificationAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkInAppNotificationAsSeenMutation, MarkInAppNotificationAsSeenMutationVariables>;
export const MissedEventRegistrationDocument = gql`
    mutation MissedEventRegistration($uuid: UUID!) {
  updateEventRegistrationToMissed(uuid: $uuid)
}
    `;
export type MissedEventRegistrationMutationFn = Apollo.MutationFunction<MissedEventRegistrationMutation, MissedEventRegistrationMutationVariables>;

/**
 * __useMissedEventRegistrationMutation__
 *
 * To run a mutation, you first call `useMissedEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMissedEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [missedEventRegistrationMutation, { data, loading, error }] = useMissedEventRegistrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMissedEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<MissedEventRegistrationMutation, MissedEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MissedEventRegistrationMutation, MissedEventRegistrationMutationVariables>(MissedEventRegistrationDocument, options);
      }
export type MissedEventRegistrationMutationHookResult = ReturnType<typeof useMissedEventRegistrationMutation>;
export type MissedEventRegistrationMutationResult = Apollo.MutationResult<MissedEventRegistrationMutation>;
export type MissedEventRegistrationMutationOptions = Apollo.BaseMutationOptions<MissedEventRegistrationMutation, MissedEventRegistrationMutationVariables>;
export const PublishMembershipAgreementTemplateDocument = gql`
    mutation PublishMembershipAgreementTemplate($uuid: UUID!) {
  publishMembershipAgreementTemplate(uuid: $uuid) {
    uuid
  }
}
    `;
export type PublishMembershipAgreementTemplateMutationFn = Apollo.MutationFunction<PublishMembershipAgreementTemplateMutation, PublishMembershipAgreementTemplateMutationVariables>;

/**
 * __usePublishMembershipAgreementTemplateMutation__
 *
 * To run a mutation, you first call `usePublishMembershipAgreementTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMembershipAgreementTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMembershipAgreementTemplateMutation, { data, loading, error }] = usePublishMembershipAgreementTemplateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishMembershipAgreementTemplateMutation(baseOptions?: Apollo.MutationHookOptions<PublishMembershipAgreementTemplateMutation, PublishMembershipAgreementTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishMembershipAgreementTemplateMutation, PublishMembershipAgreementTemplateMutationVariables>(PublishMembershipAgreementTemplateDocument, options);
      }
export type PublishMembershipAgreementTemplateMutationHookResult = ReturnType<typeof usePublishMembershipAgreementTemplateMutation>;
export type PublishMembershipAgreementTemplateMutationResult = Apollo.MutationResult<PublishMembershipAgreementTemplateMutation>;
export type PublishMembershipAgreementTemplateMutationOptions = Apollo.BaseMutationOptions<PublishMembershipAgreementTemplateMutation, PublishMembershipAgreementTemplateMutationVariables>;
export const RefuseMembershipRequestDocument = gql`
    mutation RefuseMembershipRequest($uuid: UUID!) {
      refuseMembershipRequest(uuid: $uuid) {
    uuid
  }
}
    `;
export type RefuseMembershipRequestMutationFn = Apollo.MutationFunction<RefuseMembershipRequestMutation, RefuseMembershipRequestMutationVariables>;

/**
 * __useRefuseMembershipRequestMutation__
 *
 * To run a mutation, you first call `useRefuseMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefuseMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refuseMembershipRequestMutation, { data, loading, error }] = useRefuseMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRefuseMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<RefuseMembershipRequestMutation, RefuseMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<RefuseMembershipRequestMutation, RefuseMembershipRequestMutationVariables>(RefuseMembershipRequestDocument, options);
      }
export type RefuseMembershipRequestMutationHookResult = ReturnType<typeof useRefuseMembershipRequestMutation>;
export type RefuseMembershipRequestMutationResult = Apollo.MutationResult<RefuseMembershipRequestMutation>;
export type RefuseMembershipRequestMutationOptions = Apollo.BaseMutationOptions<RefuseMembershipRequestMutation, RefuseMembershipRequestMutationVariables>;
export const RejectMembershipRequestDocument = gql`
    mutation RejectMembershipRequest($uuid: UUID!) {
      rejectMembershipRequest(uuid: $uuid) {
    uuid
  }
}
    `;
export type RejectMembershipRequestMutationFn = Apollo.MutationFunction<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>;

/**
 * __useRejectMembershipRequestMutation__
 *
 * To run a mutation, you first call `useRejectMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMembershipRequestMutation, { data, loading, error }] = useRejectMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRejectMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>(RejectMembershipRequestDocument, options);
}
export type RejectMembershipRequestMutationHookResult = ReturnType<typeof useRejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationResult = Apollo.MutationResult<RejectMembershipRequestMutation>;
export type RejectMembershipRequestMutationOptions = Apollo.BaseMutationOptions<RejectMembershipRequestMutation, RejectMembershipRequestMutationVariables>;
export const RemoveMemberRoleFromMemberDocument = gql`
    mutation RemoveMemberRoleFromMember($input: RemoveMemberRoleFromMemberInput!) {
  removeMemberRoleFromMember(input: $input) {
    uuid
  }
}
    `;
export type RemoveMemberRoleFromMemberMutationFn = Apollo.MutationFunction<RemoveMemberRoleFromMemberMutation, RemoveMemberRoleFromMemberMutationVariables>;

/**
 * __useRemoveMemberRoleFromMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberRoleFromMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberRoleFromMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberRoleFromMemberMutation, { data, loading, error }] = useRemoveMemberRoleFromMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberRoleFromMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberRoleFromMemberMutation, RemoveMemberRoleFromMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberRoleFromMemberMutation, RemoveMemberRoleFromMemberMutationVariables>(RemoveMemberRoleFromMemberDocument, options);
      }
export type RemoveMemberRoleFromMemberMutationHookResult = ReturnType<typeof useRemoveMemberRoleFromMemberMutation>;
export type RemoveMemberRoleFromMemberMutationResult = Apollo.MutationResult<RemoveMemberRoleFromMemberMutation>;
export type RemoveMemberRoleFromMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberRoleFromMemberMutation, RemoveMemberRoleFromMemberMutationVariables>;
export const RequestMembershipAgreementTerminationDocument = gql`
    mutation RequestMembershipAgreementTermination($uuid: UUID!) {
  requestMembershipAgreementTermination(uuid: $uuid) {
    uuid
  }
}
    `;
export type RequestMembershipAgreementTerminationMutationFn = Apollo.MutationFunction<RequestMembershipAgreementTerminationMutation, RequestMembershipAgreementTerminationMutationVariables>;

/**
 * __useRequestMembershipAgreementTerminationMutation__
 *
 * To run a mutation, you first call `useRequestMembershipAgreementTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMembershipAgreementTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMembershipAgreementTerminationMutation, { data, loading, error }] = useRequestMembershipAgreementTerminationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRequestMembershipAgreementTerminationMutation(baseOptions?: Apollo.MutationHookOptions<RequestMembershipAgreementTerminationMutation, RequestMembershipAgreementTerminationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestMembershipAgreementTerminationMutation, RequestMembershipAgreementTerminationMutationVariables>(RequestMembershipAgreementTerminationDocument, options);
      }
export type RequestMembershipAgreementTerminationMutationHookResult = ReturnType<typeof useRequestMembershipAgreementTerminationMutation>;
export type RequestMembershipAgreementTerminationMutationResult = Apollo.MutationResult<RequestMembershipAgreementTerminationMutation>;
export type RequestMembershipAgreementTerminationMutationOptions = Apollo.BaseMutationOptions<RequestMembershipAgreementTerminationMutation, RequestMembershipAgreementTerminationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResumeMembershipAgreementDocument = gql`
    mutation ResumeMembershipAgreement($uuid: UUID!) {
  resumeMembershipAgreement(uuid: $uuid) {
    uuid
  }
}
    `;
export type ResumeMembershipAgreementMutationFn = Apollo.MutationFunction<ResumeMembershipAgreementMutation, ResumeMembershipAgreementMutationVariables>;

/**
 * __useResumeMembershipAgreementMutation__
 *
 * To run a mutation, you first call `useResumeMembershipAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeMembershipAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeMembershipAgreementMutation, { data, loading, error }] = useResumeMembershipAgreementMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useResumeMembershipAgreementMutation(baseOptions?: Apollo.MutationHookOptions<ResumeMembershipAgreementMutation, ResumeMembershipAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeMembershipAgreementMutation, ResumeMembershipAgreementMutationVariables>(ResumeMembershipAgreementDocument, options);
      }
export type ResumeMembershipAgreementMutationHookResult = ReturnType<typeof useResumeMembershipAgreementMutation>;
export type ResumeMembershipAgreementMutationResult = Apollo.MutationResult<ResumeMembershipAgreementMutation>;
export type ResumeMembershipAgreementMutationOptions = Apollo.BaseMutationOptions<ResumeMembershipAgreementMutation, ResumeMembershipAgreementMutationVariables>;
export const RevokeMembershipAgreementTerminationRequestDocument = gql`
    mutation RevokeMembershipAgreementTerminationRequest($uuid: UUID!) {
  revokeMembershipAgreementTerminationRequest(uuid: $uuid) {
    uuid
  }
}
    `;
export type RevokeMembershipAgreementTerminationRequestMutationFn = Apollo.MutationFunction<RevokeMembershipAgreementTerminationRequestMutation, RevokeMembershipAgreementTerminationRequestMutationVariables>;

/**
 * __useRevokeMembershipAgreementTerminationRequestMutation__
 *
 * To run a mutation, you first call `useRevokeMembershipAgreementTerminationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeMembershipAgreementTerminationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeMembershipAgreementTerminationRequestMutation, { data, loading, error }] = useRevokeMembershipAgreementTerminationRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRevokeMembershipAgreementTerminationRequestMutation(baseOptions?: Apollo.MutationHookOptions<RevokeMembershipAgreementTerminationRequestMutation, RevokeMembershipAgreementTerminationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeMembershipAgreementTerminationRequestMutation, RevokeMembershipAgreementTerminationRequestMutationVariables>(RevokeMembershipAgreementTerminationRequestDocument, options);
      }
export type RevokeMembershipAgreementTerminationRequestMutationHookResult = ReturnType<typeof useRevokeMembershipAgreementTerminationRequestMutation>;
export type RevokeMembershipAgreementTerminationRequestMutationResult = Apollo.MutationResult<RevokeMembershipAgreementTerminationRequestMutation>;
export type RevokeMembershipAgreementTerminationRequestMutationOptions = Apollo.BaseMutationOptions<RevokeMembershipAgreementTerminationRequestMutation, RevokeMembershipAgreementTerminationRequestMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SuspendMembershipAgreementDocument = gql`
    mutation SuspendMembershipAgreement($uuid: UUID!, $input: SuspensionDetailsInput!) {
  suspendMembershipAgreement(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type SuspendMembershipAgreementMutationFn = Apollo.MutationFunction<SuspendMembershipAgreementMutation, SuspendMembershipAgreementMutationVariables>;

/**
 * __useSuspendMembershipAgreementMutation__
 *
 * To run a mutation, you first call `useSuspendMembershipAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendMembershipAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendMembershipAgreementMutation, { data, loading, error }] = useSuspendMembershipAgreementMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuspendMembershipAgreementMutation(baseOptions?: Apollo.MutationHookOptions<SuspendMembershipAgreementMutation, SuspendMembershipAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuspendMembershipAgreementMutation, SuspendMembershipAgreementMutationVariables>(SuspendMembershipAgreementDocument, options);
      }
export type SuspendMembershipAgreementMutationHookResult = ReturnType<typeof useSuspendMembershipAgreementMutation>;
export type SuspendMembershipAgreementMutationResult = Apollo.MutationResult<SuspendMembershipAgreementMutation>;
export type SuspendMembershipAgreementMutationOptions = Apollo.BaseMutationOptions<SuspendMembershipAgreementMutation, SuspendMembershipAgreementMutationVariables>;
export const SwitchEventRegistrationDocument = gql`
    mutation SwitchEventRegistration($registrationUUID: UUID!, $eventId: String!) {
  switchEventRegistration(registrationUUID: $registrationUUID, eventId: $eventId) {
    event {
      uuid
    }
  }
}
    `;
export type SwitchEventRegistrationMutationFn = Apollo.MutationFunction<SwitchEventRegistrationMutation, SwitchEventRegistrationMutationVariables>;

/**
 * __useSwitchEventRegistrationMutation__
 *
 * To run a mutation, you first call `useSwitchEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchEventRegistrationMutation, { data, loading, error }] = useSwitchEventRegistrationMutation({
 *   variables: {
 *      registrationUUID: // value for 'registrationUUID'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSwitchEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<SwitchEventRegistrationMutation, SwitchEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchEventRegistrationMutation, SwitchEventRegistrationMutationVariables>(SwitchEventRegistrationDocument, options);
      }
export type SwitchEventRegistrationMutationHookResult = ReturnType<typeof useSwitchEventRegistrationMutation>;
export type SwitchEventRegistrationMutationResult = Apollo.MutationResult<SwitchEventRegistrationMutation>;
export type SwitchEventRegistrationMutationOptions = Apollo.BaseMutationOptions<SwitchEventRegistrationMutation, SwitchEventRegistrationMutationVariables>;
export const UnpublishMembershipAgreementTemplateDocument = gql`
    mutation UnpublishMembershipAgreementTemplate($uuid: UUID!) {
  unpublishMembershipAgreementTemplate(uuid: $uuid) {
    uuid
  }
}
    `;
export type UnpublishMembershipAgreementTemplateMutationFn = Apollo.MutationFunction<UnpublishMembershipAgreementTemplateMutation, UnpublishMembershipAgreementTemplateMutationVariables>;

/**
 * __useUnpublishMembershipAgreementTemplateMutation__
 *
 * To run a mutation, you first call `useUnpublishMembershipAgreementTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishMembershipAgreementTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishMembershipAgreementTemplateMutation, { data, loading, error }] = useUnpublishMembershipAgreementTemplateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnpublishMembershipAgreementTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishMembershipAgreementTemplateMutation, UnpublishMembershipAgreementTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishMembershipAgreementTemplateMutation, UnpublishMembershipAgreementTemplateMutationVariables>(UnpublishMembershipAgreementTemplateDocument, options);
      }
export type UnpublishMembershipAgreementTemplateMutationHookResult = ReturnType<typeof useUnpublishMembershipAgreementTemplateMutation>;
export type UnpublishMembershipAgreementTemplateMutationResult = Apollo.MutationResult<UnpublishMembershipAgreementTemplateMutation>;
export type UnpublishMembershipAgreementTemplateMutationOptions = Apollo.BaseMutationOptions<UnpublishMembershipAgreementTemplateMutation, UnpublishMembershipAgreementTemplateMutationVariables>;
export const UpdateApprovalConfigurationDocument = gql`
    mutation UpdateApprovalConfiguration($uuid: UUID!, $input: ApprovalConfigurationInput!) {
  updateApprovalConfiguration(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateApprovalConfigurationMutationFn = Apollo.MutationFunction<UpdateApprovalConfigurationMutation, UpdateApprovalConfigurationMutationVariables>;

/**
 * __useUpdateApprovalConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalConfigurationMutation, { data, loading, error }] = useUpdateApprovalConfigurationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovalConfigurationMutation, UpdateApprovalConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovalConfigurationMutation, UpdateApprovalConfigurationMutationVariables>(UpdateApprovalConfigurationDocument, options);
      }
export type UpdateApprovalConfigurationMutationHookResult = ReturnType<typeof useUpdateApprovalConfigurationMutation>;
export type UpdateApprovalConfigurationMutationResult = Apollo.MutationResult<UpdateApprovalConfigurationMutation>;
export type UpdateApprovalConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateApprovalConfigurationMutation, UpdateApprovalConfigurationMutationVariables>;
export const UpdateBenchmarkDocument = gql`
    mutation UpdateBenchmark($uuid: UUID!, $input: BenchmarkInput!) {
  updateBenchmark(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateBenchmarkMutationFn = Apollo.MutationFunction<UpdateBenchmarkMutation, UpdateBenchmarkMutationVariables>;

/**
 * __useUpdateBenchmarkMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkMutation, { data, loading, error }] = useUpdateBenchmarkMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenchmarkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenchmarkMutation, UpdateBenchmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenchmarkMutation, UpdateBenchmarkMutationVariables>(UpdateBenchmarkDocument, options);
      }
export type UpdateBenchmarkMutationHookResult = ReturnType<typeof useUpdateBenchmarkMutation>;
export type UpdateBenchmarkMutationResult = Apollo.MutationResult<UpdateBenchmarkMutation>;
export type UpdateBenchmarkMutationOptions = Apollo.BaseMutationOptions<UpdateBenchmarkMutation, UpdateBenchmarkMutationVariables>;
export const UpdateBenchmarkResultDocument = gql`
    mutation UpdateBenchmarkResult($uuid: UUID!, $input: BenchmarkResultInput!) {
  updateBenchmarkResult(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateBenchmarkResultMutationFn = Apollo.MutationFunction<UpdateBenchmarkResultMutation, UpdateBenchmarkResultMutationVariables>;

/**
 * __useUpdateBenchmarkResultMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkResultMutation, { data, loading, error }] = useUpdateBenchmarkResultMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenchmarkResultMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenchmarkResultMutation, UpdateBenchmarkResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenchmarkResultMutation, UpdateBenchmarkResultMutationVariables>(UpdateBenchmarkResultDocument, options);
      }
export type UpdateBenchmarkResultMutationHookResult = ReturnType<typeof useUpdateBenchmarkResultMutation>;
export type UpdateBenchmarkResultMutationResult = Apollo.MutationResult<UpdateBenchmarkResultMutation>;
export type UpdateBenchmarkResultMutationOptions = Apollo.BaseMutationOptions<UpdateBenchmarkResultMutation, UpdateBenchmarkResultMutationVariables>;
export const UpdateDataProtectionDocument = gql`
    mutation UpdateDataProtection($input: DataProtectionInput!) {
  updateDataProtection(input: $input) {
    uuid
  }
}
    `;
export type UpdateDataProtectionMutationFn = Apollo.MutationFunction<UpdateDataProtectionMutation, UpdateDataProtectionMutationVariables>;

/**
 * __useUpdateDataProtectionMutation__
 *
 * To run a mutation, you first call `useUpdateDataProtectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataProtectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataProtectionMutation, { data, loading, error }] = useUpdateDataProtectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataProtectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataProtectionMutation, UpdateDataProtectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataProtectionMutation, UpdateDataProtectionMutationVariables>(UpdateDataProtectionDocument, options);
      }
export type UpdateDataProtectionMutationHookResult = ReturnType<typeof useUpdateDataProtectionMutation>;
export type UpdateDataProtectionMutationResult = Apollo.MutationResult<UpdateDataProtectionMutation>;
export type UpdateDataProtectionMutationOptions = Apollo.BaseMutationOptions<UpdateDataProtectionMutation, UpdateDataProtectionMutationVariables>;
export const UpdateEmailPreferencesDocument = gql`
    mutation UpdateEmailPreferences($input: EmailPreferencesInput!) {
  updateEmailPreferences(input: $input)
}
    `;
export type UpdateEmailPreferencesMutationFn = Apollo.MutationFunction<UpdateEmailPreferencesMutation, UpdateEmailPreferencesMutationVariables>;

/**
 * __useUpdateEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailPreferencesMutation, { data, loading, error }] = useUpdateEmailPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailPreferencesMutation, UpdateEmailPreferencesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateEmailPreferencesMutation, UpdateEmailPreferencesMutationVariables>(UpdateEmailPreferencesDocument, options);
}
export type UpdateEmailPreferencesMutationHookResult = ReturnType<typeof useUpdateEmailPreferencesMutation>;
export type UpdateEmailPreferencesMutationResult = Apollo.MutationResult<UpdateEmailPreferencesMutation>;
export type UpdateEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateEmailPreferencesMutation, UpdateEmailPreferencesMutationVariables>;
export const UpdateEquipmentDocument = gql`
    mutation UpdateEquipment($input: UpdateEquipmentInput!) {
  updateEquipment(input: $input) {
    location
  }
}
    `;
export type UpdateEquipmentMutationFn = Apollo.MutationFunction<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>;

/**
 * __useUpdateEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentMutation, { data, loading, error }] = useUpdateEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>(UpdateEquipmentDocument, options);
}
export type UpdateEquipmentMutationHookResult = ReturnType<typeof useUpdateEquipmentMutation>;
export type UpdateEquipmentMutationResult = Apollo.MutationResult<UpdateEquipmentMutation>;
export type UpdateEquipmentMutationOptions = Apollo.BaseMutationOptions<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($eventId: String!, $input: UpdateEventInput!) {
  updateEvent(eventId: $eventId, input: $input) {
    uuid
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateEventDetailsDocument = gql`
    mutation UpdateEventDetails($eventId: String!, $input: EventDetailsInput!) {
  updateEventDetails(eventId: $eventId, input: $input) {
    uuid
  }
}
    `;
export type UpdateEventDetailsMutationFn = Apollo.MutationFunction<UpdateEventDetailsMutation, UpdateEventDetailsMutationVariables>;

/**
 * __useUpdateEventDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateEventDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDetailsMutation, { data, loading, error }] = useUpdateEventDetailsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDetailsMutation, UpdateEventDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDetailsMutation, UpdateEventDetailsMutationVariables>(UpdateEventDetailsDocument, options);
      }
export type UpdateEventDetailsMutationHookResult = ReturnType<typeof useUpdateEventDetailsMutation>;
export type UpdateEventDetailsMutationResult = Apollo.MutationResult<UpdateEventDetailsMutation>;
export type UpdateEventDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateEventDetailsMutation, UpdateEventDetailsMutationVariables>;
export const UpdateEventRegistrationDocument = gql`
    mutation UpdateEventRegistration($uuid: UUID!, $input: UpdateEventRegistrationInput!) {
  updateEventRegistration(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateEventRegistrationMutationFn = Apollo.MutationFunction<UpdateEventRegistrationMutation, UpdateEventRegistrationMutationVariables>;

/**
 * __useUpdateEventRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventRegistrationMutation, { data, loading, error }] = useUpdateEventRegistrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventRegistrationMutation, UpdateEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventRegistrationMutation, UpdateEventRegistrationMutationVariables>(UpdateEventRegistrationDocument, options);
      }
export type UpdateEventRegistrationMutationHookResult = ReturnType<typeof useUpdateEventRegistrationMutation>;
export type UpdateEventRegistrationMutationResult = Apollo.MutationResult<UpdateEventRegistrationMutation>;
export type UpdateEventRegistrationMutationOptions = Apollo.BaseMutationOptions<UpdateEventRegistrationMutation, UpdateEventRegistrationMutationVariables>;
export const UpdateGoogleMapsSettingsDocument = gql`
    mutation UpdateGoogleMapsSettings($input: UpdateGoogleMapsSettingsInput!) {
  updateGoogleMapsSettings(input: $input) {
    placeId
  }
}
    `;
export type UpdateGoogleMapsSettingsMutationFn = Apollo.MutationFunction<UpdateGoogleMapsSettingsMutation, UpdateGoogleMapsSettingsMutationVariables>;

/**
 * __useUpdateGoogleMapsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateGoogleMapsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoogleMapsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoogleMapsSettingsMutation, { data, loading, error }] = useUpdateGoogleMapsSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoogleMapsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoogleMapsSettingsMutation, UpdateGoogleMapsSettingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateGoogleMapsSettingsMutation, UpdateGoogleMapsSettingsMutationVariables>(UpdateGoogleMapsSettingsDocument, options);
}
export type UpdateGoogleMapsSettingsMutationHookResult = ReturnType<typeof useUpdateGoogleMapsSettingsMutation>;
export type UpdateGoogleMapsSettingsMutationResult = Apollo.MutationResult<UpdateGoogleMapsSettingsMutation>;
export type UpdateGoogleMapsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateGoogleMapsSettingsMutation, UpdateGoogleMapsSettingsMutationVariables>;
export const UpdateMemberRoleDocument = gql`
    mutation UpdateMemberRole($uuid: UUID!, $input: MemberRoleInput!) {
  updateMemberRole(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>(UpdateMemberRoleDocument, options);
      }
export type UpdateMemberRoleMutationHookResult = ReturnType<typeof useUpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationResult = Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;
export const UpdateMembershipAgreementTemplateDocument = gql`
    mutation UpdateMembershipAgreementTemplate($uuid: UUID!, $input: MembershipAgreementTemplateInput!) {
      updateMembershipAgreementTemplate(uuid: $uuid, input: $input) {
        uuid
      }
    }
`;
export type UpdateMembershipAgreementTemplateMutationFn = Apollo.MutationFunction<UpdateMembershipAgreementTemplateMutation, UpdateMembershipAgreementTemplateMutationVariables>;

/**
 * __useUpdateMembershipAgreementTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipAgreementTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipAgreementTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipAgreementTemplateMutation, { data, loading, error }] = useUpdateMembershipAgreementTemplateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipAgreementTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipAgreementTemplateMutation, UpdateMembershipAgreementTemplateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMembershipAgreementTemplateMutation, UpdateMembershipAgreementTemplateMutationVariables>(UpdateMembershipAgreementTemplateDocument, options);
}
export type UpdateMembershipAgreementTemplateMutationHookResult = ReturnType<typeof useUpdateMembershipAgreementTemplateMutation>;
export type UpdateMembershipAgreementTemplateMutationResult = Apollo.MutationResult<UpdateMembershipAgreementTemplateMutation>;
export type UpdateMembershipAgreementTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipAgreementTemplateMutation, UpdateMembershipAgreementTemplateMutationVariables>;
export const UpdateMembershipRequestDocument = gql`
    mutation UpdateMembershipRequest($uuid: UUID!, $input: MembershipRequestInput!) {
      updateMembershipRequest(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateMembershipRequestMutationFn = Apollo.MutationFunction<UpdateMembershipRequestMutation, UpdateMembershipRequestMutationVariables>;

/**
 * __useUpdateMembershipRequestMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipRequestMutation, { data, loading, error }] = useUpdateMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipRequestMutation, UpdateMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateMembershipRequestMutation, UpdateMembershipRequestMutationVariables>(UpdateMembershipRequestDocument, options);
      }
export type UpdateMembershipRequestMutationHookResult = ReturnType<typeof useUpdateMembershipRequestMutation>;
export type UpdateMembershipRequestMutationResult = Apollo.MutationResult<UpdateMembershipRequestMutation>;
export type UpdateMembershipRequestMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipRequestMutation, UpdateMembershipRequestMutationVariables>;
export const UpdateOrganisationInfoDocument = gql`
    mutation UpdateOrganisationInfo($input: OrganisationInfoInput!) {
  updateOrganisationInfo(input: $input) {
    uuid
  }
}
    `;
export type UpdateOrganisationInfoMutationFn = Apollo.MutationFunction<UpdateOrganisationInfoMutation, UpdateOrganisationInfoMutationVariables>;

/**
 * __useUpdateOrganisationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationInfoMutation, { data, loading, error }] = useUpdateOrganisationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationInfoMutation, UpdateOrganisationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationInfoMutation, UpdateOrganisationInfoMutationVariables>(UpdateOrganisationInfoDocument, options);
      }
export type UpdateOrganisationInfoMutationHookResult = ReturnType<typeof useUpdateOrganisationInfoMutation>;
export type UpdateOrganisationInfoMutationResult = Apollo.MutationResult<UpdateOrganisationInfoMutation>;
export type UpdateOrganisationInfoMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationInfoMutation, UpdateOrganisationInfoMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation UpdatePaymentMethod($paymentMethodUUID: UUID!, $paymentMethodInput: MembershipAgreementPaymentMethodInput!) {
  updateMembershipAgreementPaymentMethod(
    uuid: $paymentMethodUUID
    input: $paymentMethodInput
  ) {
    uuid
  }
}
    `;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      paymentMethodUUID: // value for 'paymentMethodUUID'
 *      paymentMethodInput: // value for 'paymentMethodInput'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, options);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const UpdatePersonDocument = gql`
    mutation UpdatePerson($personInput: PersonInput!) {
  updatePerson(personInput: $personInput) {
    uuid
  }
}
    `;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      personInput: // value for 'personInput'
 *   },
 * });
 */
export function useUpdatePersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options);
      }
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>;
export const UpdateTermsOfServiceDocument = gql`
    mutation UpdateTermsOfService($input: TermsOfServiceInput!) {
  updateTermsOfService(input: $input) {
    uuid
  }
}
    `;
export type UpdateTermsOfServiceMutationFn = Apollo.MutationFunction<UpdateTermsOfServiceMutation, UpdateTermsOfServiceMutationVariables>;

/**
 * __useUpdateTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useUpdateTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermsOfServiceMutation, { data, loading, error }] = useUpdateTermsOfServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTermsOfServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTermsOfServiceMutation, UpdateTermsOfServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTermsOfServiceMutation, UpdateTermsOfServiceMutationVariables>(UpdateTermsOfServiceDocument, options);
      }
export type UpdateTermsOfServiceMutationHookResult = ReturnType<typeof useUpdateTermsOfServiceMutation>;
export type UpdateTermsOfServiceMutationResult = Apollo.MutationResult<UpdateTermsOfServiceMutation>;
export type UpdateTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<UpdateTermsOfServiceMutation, UpdateTermsOfServiceMutationVariables>;
export const UpdateWorkoutDocument = gql`
    mutation UpdateWorkout($uuid: UUID!, $input: WorkoutInput!) {
  updateWorkout(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateWorkoutMutationFn = Apollo.MutationFunction<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>;

/**
 * __useUpdateWorkoutMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutMutation, { data, loading, error }] = useUpdateWorkoutMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>(UpdateWorkoutDocument, options);
      }
export type UpdateWorkoutMutationHookResult = ReturnType<typeof useUpdateWorkoutMutation>;
export type UpdateWorkoutMutationResult = Apollo.MutationResult<UpdateWorkoutMutation>;
export type UpdateWorkoutMutationOptions = Apollo.BaseMutationOptions<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>;
export const UpdateWorkoutResultDocument = gql`
    mutation UpdateWorkoutResult($uuid: UUID!, $input: WorkoutResultInput!) {
  updateWorkoutResult(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateWorkoutResultMutationFn = Apollo.MutationFunction<UpdateWorkoutResultMutation, UpdateWorkoutResultMutationVariables>;

/**
 * __useUpdateWorkoutResultMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutResultMutation, { data, loading, error }] = useUpdateWorkoutResultMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutResultMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutResultMutation, UpdateWorkoutResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkoutResultMutation, UpdateWorkoutResultMutationVariables>(UpdateWorkoutResultDocument, options);
      }
export type UpdateWorkoutResultMutationHookResult = ReturnType<typeof useUpdateWorkoutResultMutation>;
export type UpdateWorkoutResultMutationResult = Apollo.MutationResult<UpdateWorkoutResultMutation>;
export type UpdateWorkoutResultMutationOptions = Apollo.BaseMutationOptions<UpdateWorkoutResultMutation, UpdateWorkoutResultMutationVariables>;
export const UpdateWorkoutResultCategoryDocument = gql`
    mutation UpdateWorkoutResultCategory($uuid: UUID!, $input: WorkoutResultCategoryInput!) {
  updateWorkoutResultCategory(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateWorkoutResultCategoryMutationFn = Apollo.MutationFunction<UpdateWorkoutResultCategoryMutation, UpdateWorkoutResultCategoryMutationVariables>;

/**
 * __useUpdateWorkoutResultCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutResultCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutResultCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutResultCategoryMutation, { data, loading, error }] = useUpdateWorkoutResultCategoryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkoutResultCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutResultCategoryMutation, UpdateWorkoutResultCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkoutResultCategoryMutation, UpdateWorkoutResultCategoryMutationVariables>(UpdateWorkoutResultCategoryDocument, options);
      }
export type UpdateWorkoutResultCategoryMutationHookResult = ReturnType<typeof useUpdateWorkoutResultCategoryMutation>;
export type UpdateWorkoutResultCategoryMutationResult = Apollo.MutationResult<UpdateWorkoutResultCategoryMutation>;
export type UpdateWorkoutResultCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateWorkoutResultCategoryMutation, UpdateWorkoutResultCategoryMutationVariables>;
export const WithdrawMembershipRequestDocument = gql`
    mutation WithdrawMembershipRequest($uuid: UUID!) {
      withdrawMembershipRequest(uuid: $uuid) {
    uuid
  }
}
    `;
export type WithdrawMembershipRequestMutationFn = Apollo.MutationFunction<WithdrawMembershipRequestMutation, WithdrawMembershipRequestMutationVariables>;

/**
 * __useWithdrawMembershipRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMembershipRequestMutation, { data, loading, error }] = useWithdrawMembershipRequestMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useWithdrawMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawMembershipRequestMutation, WithdrawMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<WithdrawMembershipRequestMutation, WithdrawMembershipRequestMutationVariables>(WithdrawMembershipRequestDocument, options);
      }
export type WithdrawMembershipRequestMutationHookResult = ReturnType<typeof useWithdrawMembershipRequestMutation>;
export type WithdrawMembershipRequestMutationResult = Apollo.MutationResult<WithdrawMembershipRequestMutation>;
export type WithdrawMembershipRequestMutationOptions = Apollo.BaseMutationOptions<WithdrawMembershipRequestMutation, WithdrawMembershipRequestMutationVariables>;
export const ActiveMemberCountDocument = gql`
    query ActiveMemberCount {
  activeMemberCount
}
    `;

/**
 * __useActiveMemberCountQuery__
 *
 * To run a query within a React component, call `useActiveMemberCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMemberCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMemberCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveMemberCountQuery(baseOptions?: Apollo.QueryHookOptions<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>(ActiveMemberCountDocument, options);
}
export function useActiveMemberCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>(ActiveMemberCountDocument, options);
}
export function useActiveMemberCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>(ActiveMemberCountDocument, options);
}
export type ActiveMemberCountQueryHookResult = ReturnType<typeof useActiveMemberCountQuery>;
export type ActiveMemberCountLazyQueryHookResult = ReturnType<typeof useActiveMemberCountLazyQuery>;
export type ActiveMemberCountSuspenseQueryHookResult = ReturnType<typeof useActiveMemberCountSuspenseQuery>;
export type ActiveMemberCountQueryResult = Apollo.QueryResult<ActiveMemberCountQuery, ActiveMemberCountQueryVariables>;
export const ActiveMembershipAgreementsReportDocument = gql`
    query ActiveMembershipAgreementsReport {
  activeMembershipAgreementsReport {
    results {
      count
      templateName
    }
  }
}
    `;

/**
 * __useActiveMembershipAgreementsReportQuery__
 *
 * To run a query within a React component, call `useActiveMembershipAgreementsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMembershipAgreementsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMembershipAgreementsReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveMembershipAgreementsReportQuery(baseOptions?: Apollo.QueryHookOptions<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>(ActiveMembershipAgreementsReportDocument, options);
}
export function useActiveMembershipAgreementsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>(ActiveMembershipAgreementsReportDocument, options);
}
export function useActiveMembershipAgreementsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>(ActiveMembershipAgreementsReportDocument, options);
}
export type ActiveMembershipAgreementsReportQueryHookResult = ReturnType<typeof useActiveMembershipAgreementsReportQuery>;
export type ActiveMembershipAgreementsReportLazyQueryHookResult = ReturnType<typeof useActiveMembershipAgreementsReportLazyQuery>;
export type ActiveMembershipAgreementsReportSuspenseQueryHookResult = ReturnType<typeof useActiveMembershipAgreementsReportSuspenseQuery>;
export type ActiveMembershipAgreementsReportQueryResult = Apollo.QueryResult<ActiveMembershipAgreementsReportQuery, ActiveMembershipAgreementsReportQueryVariables>;
export const AllowedEventRegistrationStateActionsForManagerDocument = gql`
    query AllowedEventRegistrationStateActionsForManager($registrationUUID: UUID!) {
  allowedEventRegistrationStateActionsForManager(
    registrationUUID: $registrationUUID
  )
}
    `;

/**
 * __useAllowedEventRegistrationStateActionsForManagerQuery__
 *
 * To run a query within a React component, call `useAllowedEventRegistrationStateActionsForManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedEventRegistrationStateActionsForManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedEventRegistrationStateActionsForManagerQuery({
 *   variables: {
 *      registrationUUID: // value for 'registrationUUID'
 *   },
 * });
 */
export function useAllowedEventRegistrationStateActionsForManagerQuery(baseOptions: Apollo.QueryHookOptions<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables> & ({
  variables: AllowedEventRegistrationStateActionsForManagerQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables>(AllowedEventRegistrationStateActionsForManagerDocument, options);
      }
export function useAllowedEventRegistrationStateActionsForManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables>(AllowedEventRegistrationStateActionsForManagerDocument, options);
        }
export function useAllowedEventRegistrationStateActionsForManagerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables>(AllowedEventRegistrationStateActionsForManagerDocument, options);
}
export type AllowedEventRegistrationStateActionsForManagerQueryHookResult = ReturnType<typeof useAllowedEventRegistrationStateActionsForManagerQuery>;
export type AllowedEventRegistrationStateActionsForManagerLazyQueryHookResult = ReturnType<typeof useAllowedEventRegistrationStateActionsForManagerLazyQuery>;
export type AllowedEventRegistrationStateActionsForManagerSuspenseQueryHookResult = ReturnType<typeof useAllowedEventRegistrationStateActionsForManagerSuspenseQuery>;
export type AllowedEventRegistrationStateActionsForManagerQueryResult = Apollo.QueryResult<AllowedEventRegistrationStateActionsForManagerQuery, AllowedEventRegistrationStateActionsForManagerQueryVariables>;
export const ApprovalConfigurationDocument = gql`
    query ApprovalConfiguration($approvalConfigurationUUID: UUID!) {
  approvalConfiguration(uuid: $approvalConfigurationUUID) {
    uuid
    approvalCount
    description
    name
    requiresApproval
  }
}
    `;

/**
 * __useApprovalConfigurationQuery__
 *
 * To run a query within a React component, call `useApprovalConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalConfigurationQuery({
 *   variables: {
 *      approvalConfigurationUUID: // value for 'approvalConfigurationUUID'
 *   },
 * });
 */
export function useApprovalConfigurationQuery(baseOptions: Apollo.QueryHookOptions<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables> & ({
  variables: ApprovalConfigurationQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables>(ApprovalConfigurationDocument, options);
      }
export function useApprovalConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables>(ApprovalConfigurationDocument, options);
        }
export function useApprovalConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables>(ApprovalConfigurationDocument, options);
}
export type ApprovalConfigurationQueryHookResult = ReturnType<typeof useApprovalConfigurationQuery>;
export type ApprovalConfigurationLazyQueryHookResult = ReturnType<typeof useApprovalConfigurationLazyQuery>;
export type ApprovalConfigurationSuspenseQueryHookResult = ReturnType<typeof useApprovalConfigurationSuspenseQuery>;
export type ApprovalConfigurationQueryResult = Apollo.QueryResult<ApprovalConfigurationQuery, ApprovalConfigurationQueryVariables>;
export const ApprovalConfigurationsDocument = gql`
    query ApprovalConfigurations {
  approvalConfigurations(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      approvalCount
      description
      name
      requiresApproval
    }
  }
}
    `;

/**
 * __useApprovalConfigurationsQuery__
 *
 * To run a query within a React component, call `useApprovalConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApprovalConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>(ApprovalConfigurationsDocument, options);
      }
export function useApprovalConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>(ApprovalConfigurationsDocument, options);
        }
export function useApprovalConfigurationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>(ApprovalConfigurationsDocument, options);
}
export type ApprovalConfigurationsQueryHookResult = ReturnType<typeof useApprovalConfigurationsQuery>;
export type ApprovalConfigurationsLazyQueryHookResult = ReturnType<typeof useApprovalConfigurationsLazyQuery>;
export type ApprovalConfigurationsSuspenseQueryHookResult = ReturnType<typeof useApprovalConfigurationsSuspenseQuery>;
export type ApprovalConfigurationsQueryResult = Apollo.QueryResult<ApprovalConfigurationsQuery, ApprovalConfigurationsQueryVariables>;
export const BenchmarkDocument = gql`
    query Benchmark($uuid: UUID!) {
  benchmark(uuid: $uuid) {
    uuid
    description
    name
    resultOrder
    resultType
    results {
      uuid
      comment
      date
      score
    }
  }
}
    `;

/**
 * __useBenchmarkQuery__
 *
 * To run a query within a React component, call `useBenchmarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBenchmarkQuery(baseOptions: Apollo.QueryHookOptions<BenchmarkQuery, BenchmarkQueryVariables> & ({ variables: BenchmarkQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenchmarkQuery, BenchmarkQueryVariables>(BenchmarkDocument, options);
      }
export function useBenchmarkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenchmarkQuery, BenchmarkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenchmarkQuery, BenchmarkQueryVariables>(BenchmarkDocument, options);
        }
export function useBenchmarkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BenchmarkQuery, BenchmarkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BenchmarkQuery, BenchmarkQueryVariables>(BenchmarkDocument, options);
}
export type BenchmarkQueryHookResult = ReturnType<typeof useBenchmarkQuery>;
export type BenchmarkLazyQueryHookResult = ReturnType<typeof useBenchmarkLazyQuery>;
export type BenchmarkSuspenseQueryHookResult = ReturnType<typeof useBenchmarkSuspenseQuery>;
export type BenchmarkQueryResult = Apollo.QueryResult<BenchmarkQuery, BenchmarkQueryVariables>;
export const BilledEventRegistrationsDocument = gql`
    query BilledEventRegistrations($filter: BilledEventRegistrationsFilterInput!, $pagination: PaginationInput!) {
  billedEventRegistrations(filter: $filter, pagination: $pagination) {
    results {
      event {
        uuid
        start
        state
        eventDetails {
          name
          color
        }
      }
      feeAmount
      isSettled
      isSplit
    }
    hasNext
    totalCount
  }
}
    `;

/**
 * __useBilledEventRegistrationsQuery__
 *
 * To run a query within a React component, call `useBilledEventRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBilledEventRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBilledEventRegistrationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBilledEventRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables> & ({
  variables: BilledEventRegistrationsQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables>(BilledEventRegistrationsDocument, options);
      }
export function useBilledEventRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables>(BilledEventRegistrationsDocument, options);
        }
export function useBilledEventRegistrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables>(BilledEventRegistrationsDocument, options);
}
export type BilledEventRegistrationsQueryHookResult = ReturnType<typeof useBilledEventRegistrationsQuery>;
export type BilledEventRegistrationsLazyQueryHookResult = ReturnType<typeof useBilledEventRegistrationsLazyQuery>;
export type BilledEventRegistrationsSuspenseQueryHookResult = ReturnType<typeof useBilledEventRegistrationsSuspenseQuery>;
export type BilledEventRegistrationsQueryResult = Apollo.QueryResult<BilledEventRegistrationsQuery, BilledEventRegistrationsQueryVariables>;
export const EventRegistrationLogDocument = gql`
    query EventRegistrationLog($eventId: String!) {
  eventRegistrationLogEntries(eventId: $eventId) {
    uuid
    date
    executedByMemberName
    memberName
    registrationState
    switchedEventDate
    switchedEventName
    type
    visitorName
  }
}
    `;

/**
 * __useEventRegistrationLogQuery__
 *
 * To run a query within a React component, call `useEventRegistrationLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventRegistrationLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventRegistrationLogQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventRegistrationLogQuery(baseOptions: Apollo.QueryHookOptions<EventRegistrationLogQuery, EventRegistrationLogQueryVariables> & ({
  variables: EventRegistrationLogQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventRegistrationLogQuery, EventRegistrationLogQueryVariables>(EventRegistrationLogDocument, options);
      }
export function useEventRegistrationLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventRegistrationLogQuery, EventRegistrationLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventRegistrationLogQuery, EventRegistrationLogQueryVariables>(EventRegistrationLogDocument, options);
        }
export function useEventRegistrationLogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventRegistrationLogQuery, EventRegistrationLogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<EventRegistrationLogQuery, EventRegistrationLogQueryVariables>(EventRegistrationLogDocument, options);
}
export type EventRegistrationLogQueryHookResult = ReturnType<typeof useEventRegistrationLogQuery>;
export type EventRegistrationLogLazyQueryHookResult = ReturnType<typeof useEventRegistrationLogLazyQuery>;
export type EventRegistrationLogSuspenseQueryHookResult = ReturnType<typeof useEventRegistrationLogSuspenseQuery>;
export type EventRegistrationLogQueryResult = Apollo.QueryResult<EventRegistrationLogQuery, EventRegistrationLogQueryVariables>;
export const EventsForSwitchDocument = gql`
    query EventsForSwitch($registrationUUID: UUID!) {
  eventsForSwitch(registrationUUID: $registrationUUID) {
    uuid
    end
    start
    eventDetails {
      color
      name
    }
  }
}
    `;

/**
 * __useEventsForSwitchQuery__
 *
 * To run a query within a React component, call `useEventsForSwitchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsForSwitchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsForSwitchQuery({
 *   variables: {
 *      registrationUUID: // value for 'registrationUUID'
 *   },
 * });
 */
export function useEventsForSwitchQuery(baseOptions: Apollo.QueryHookOptions<EventsForSwitchQuery, EventsForSwitchQueryVariables> & ({
  variables: EventsForSwitchQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsForSwitchQuery, EventsForSwitchQueryVariables>(EventsForSwitchDocument, options);
      }
export function useEventsForSwitchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsForSwitchQuery, EventsForSwitchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsForSwitchQuery, EventsForSwitchQueryVariables>(EventsForSwitchDocument, options);
        }
export function useEventsForSwitchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventsForSwitchQuery, EventsForSwitchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<EventsForSwitchQuery, EventsForSwitchQueryVariables>(EventsForSwitchDocument, options);
}
export type EventsForSwitchQueryHookResult = ReturnType<typeof useEventsForSwitchQuery>;
export type EventsForSwitchLazyQueryHookResult = ReturnType<typeof useEventsForSwitchLazyQuery>;
export type EventsForSwitchSuspenseQueryHookResult = ReturnType<typeof useEventsForSwitchSuspenseQuery>;
export type EventsForSwitchQueryResult = Apollo.QueryResult<EventsForSwitchQuery, EventsForSwitchQueryVariables>;
export const HistoricMemberCountDocument = gql`
    query HistoricMemberCount {
  historicMemberCount {
    results {
      count
      date
    }
  }
}
    `;

/**
 * __useHistoricMemberCountQuery__
 *
 * To run a query within a React component, call `useHistoricMemberCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricMemberCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricMemberCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useHistoricMemberCountQuery(baseOptions?: Apollo.QueryHookOptions<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>(HistoricMemberCountDocument, options);
}
export function useHistoricMemberCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>(HistoricMemberCountDocument, options);
}
export function useHistoricMemberCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>(HistoricMemberCountDocument, options);
}
export type HistoricMemberCountQueryHookResult = ReturnType<typeof useHistoricMemberCountQuery>;
export type HistoricMemberCountLazyQueryHookResult = ReturnType<typeof useHistoricMemberCountLazyQuery>;
export type HistoricMemberCountSuspenseQueryHookResult = ReturnType<typeof useHistoricMemberCountSuspenseQuery>;
export type HistoricMemberCountQueryResult = Apollo.QueryResult<HistoricMemberCountQuery, HistoricMemberCountQueryVariables>;
export const IcalQueryDocument = gql`
    query IcalQuery {
  ical {
    link
  }
}
    `;

/**
 * __useIcalQueryQuery__
 *
 * To run a query within a React component, call `useIcalQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcalQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcalQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useIcalQueryQuery(baseOptions?: Apollo.QueryHookOptions<IcalQueryQuery, IcalQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IcalQueryQuery, IcalQueryQueryVariables>(IcalQueryDocument, options);
      }
export function useIcalQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IcalQueryQuery, IcalQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IcalQueryQuery, IcalQueryQueryVariables>(IcalQueryDocument, options);
        }
export function useIcalQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IcalQueryQuery, IcalQueryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IcalQueryQuery, IcalQueryQueryVariables>(IcalQueryDocument, options);
}
export type IcalQueryQueryHookResult = ReturnType<typeof useIcalQueryQuery>;
export type IcalQueryLazyQueryHookResult = ReturnType<typeof useIcalQueryLazyQuery>;
export type IcalQuerySuspenseQueryHookResult = ReturnType<typeof useIcalQuerySuspenseQuery>;
export type IcalQueryQueryResult = Apollo.QueryResult<IcalQueryQuery, IcalQueryQueryVariables>;
export const IsUsernameAvailableDocument = gql`
    query IsUsernameAvailable($name: String!) {
  isUsernameAvailable(name: $name)
}
    `;

/**
 * __useIsUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useIsUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUsernameAvailableQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useIsUsernameAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables> & ({
  variables: IsUsernameAvailableQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>(IsUsernameAvailableDocument, options);
      }
export function useIsUsernameAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>(IsUsernameAvailableDocument, options);
        }
export function useIsUsernameAvailableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>(IsUsernameAvailableDocument, options);
}
export type IsUsernameAvailableQueryHookResult = ReturnType<typeof useIsUsernameAvailableQuery>;
export type IsUsernameAvailableLazyQueryHookResult = ReturnType<typeof useIsUsernameAvailableLazyQuery>;
export type IsUsernameAvailableSuspenseQueryHookResult = ReturnType<typeof useIsUsernameAvailableSuspenseQuery>;
export type IsUsernameAvailableQueryResult = Apollo.QueryResult<IsUsernameAvailableQuery, IsUsernameAvailableQueryVariables>;
export const LatestInAppNotificationsDocument = gql`
    query LatestInAppNotifications {
  latestInAppNotifications {
    results {
      uuid
      created
      message
      seen
      type
    }
  }
}
    `;

/**
 * __useLatestInAppNotificationsQuery__
 *
 * To run a query within a React component, call `useLatestInAppNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestInAppNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestInAppNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestInAppNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>(LatestInAppNotificationsDocument, options);
}
export function useLatestInAppNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>(LatestInAppNotificationsDocument, options);
}
export function useLatestInAppNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>(LatestInAppNotificationsDocument, options);
}
export type LatestInAppNotificationsQueryHookResult = ReturnType<typeof useLatestInAppNotificationsQuery>;
export type LatestInAppNotificationsLazyQueryHookResult = ReturnType<typeof useLatestInAppNotificationsLazyQuery>;
export type LatestInAppNotificationsSuspenseQueryHookResult = ReturnType<typeof useLatestInAppNotificationsSuspenseQuery>;
export type LatestInAppNotificationsQueryResult = Apollo.QueryResult<LatestInAppNotificationsQuery, LatestInAppNotificationsQueryVariables>;
export const LatestOrganisationLogEntriesDocument = gql`
    query LatestOrganisationLogEntries {
  latestOrganisationLogEntries {
    results {
      uuid
      created
      message
      type
    }
  }
}
    `;

/**
 * __useLatestOrganisationLogEntriesQuery__
 *
 * To run a query within a React component, call `useLatestOrganisationLogEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOrganisationLogEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOrganisationLogEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestOrganisationLogEntriesQuery(baseOptions?: Apollo.QueryHookOptions<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>(LatestOrganisationLogEntriesDocument, options);
}
export function useLatestOrganisationLogEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>(LatestOrganisationLogEntriesDocument, options);
}
export function useLatestOrganisationLogEntriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>(LatestOrganisationLogEntriesDocument, options);
}
export type LatestOrganisationLogEntriesQueryHookResult = ReturnType<typeof useLatestOrganisationLogEntriesQuery>;
export type LatestOrganisationLogEntriesLazyQueryHookResult = ReturnType<typeof useLatestOrganisationLogEntriesLazyQuery>;
export type LatestOrganisationLogEntriesSuspenseQueryHookResult = ReturnType<typeof useLatestOrganisationLogEntriesSuspenseQuery>;
export type LatestOrganisationLogEntriesQueryResult = Apollo.QueryResult<LatestOrganisationLogEntriesQuery, LatestOrganisationLogEntriesQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    uuid
    birthday
    city
    country
    emailAddress
    firstname
    lastname
    memberPermissions
    memberUUID
    phone
    postalCode
    streetAddress
    username
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MemberDocument = gql`
    query Member($uuid: UUID!) {
  member(uuid: $uuid) {
    uuid
    personUUID
    city
    country
    emailAddress
    firstname
    lastname
    phone
    postalCode
    streetAddress
    username
  }
}
    `;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables> & ({ variables: MemberQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
      }
export function useMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
        }
export function useMemberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberQuery, MemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
}
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberSuspenseQueryHookResult = ReturnType<typeof useMemberSuspenseQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export const MemberEmailsDocument = gql`
    query MemberEmails($filter: MemberEmailsFilterInput!) {
  memberEmails(filter: $filter, pagination: {limit: 20, offset: 0}) {
    uuid
    contentHtml
    created
    sender {
      firstname
      lastname
      username
    }
    sentDate
    state
    subject
  }
}
    `;

/**
 * __useMemberEmailsQuery__
 *
 * To run a query within a React component, call `useMemberEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberEmailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberEmailsQuery(baseOptions: Apollo.QueryHookOptions<MemberEmailsQuery, MemberEmailsQueryVariables> & ({
  variables: MemberEmailsQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberEmailsQuery, MemberEmailsQueryVariables>(MemberEmailsDocument, options);
      }
export function useMemberEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberEmailsQuery, MemberEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberEmailsQuery, MemberEmailsQueryVariables>(MemberEmailsDocument, options);
        }
export function useMemberEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberEmailsQuery, MemberEmailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberEmailsQuery, MemberEmailsQueryVariables>(MemberEmailsDocument, options);
}
export type MemberEmailsQueryHookResult = ReturnType<typeof useMemberEmailsQuery>;
export type MemberEmailsLazyQueryHookResult = ReturnType<typeof useMemberEmailsLazyQuery>;
export type MemberEmailsSuspenseQueryHookResult = ReturnType<typeof useMemberEmailsSuspenseQuery>;
export type MemberEmailsQueryResult = Apollo.QueryResult<MemberEmailsQuery, MemberEmailsQueryVariables>;
export const MemberRoleDocument = gql`
    query MemberRole($uuid: UUID!) {
  memberRole(uuid: $uuid) {
    uuid
    name
    memberPermissions
  }
}
    `;

/**
 * __useMemberRoleQuery__
 *
 * To run a query within a React component, call `useMemberRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRoleQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMemberRoleQuery(baseOptions: Apollo.QueryHookOptions<MemberRoleQuery, MemberRoleQueryVariables> & ({ variables: MemberRoleQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberRoleQuery, MemberRoleQueryVariables>(MemberRoleDocument, options);
      }
export function useMemberRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberRoleQuery, MemberRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberRoleQuery, MemberRoleQueryVariables>(MemberRoleDocument, options);
        }
export function useMemberRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberRoleQuery, MemberRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberRoleQuery, MemberRoleQueryVariables>(MemberRoleDocument, options);
}
export type MemberRoleQueryHookResult = ReturnType<typeof useMemberRoleQuery>;
export type MemberRoleLazyQueryHookResult = ReturnType<typeof useMemberRoleLazyQuery>;
export type MemberRoleSuspenseQueryHookResult = ReturnType<typeof useMemberRoleSuspenseQuery>;
export type MemberRoleQueryResult = Apollo.QueryResult<MemberRoleQuery, MemberRoleQueryVariables>;
export const MemberRolesDocument = gql`
    query MemberRoles {
  memberRoles {
    results {
      uuid
      name
      memberPermissions
      members {
        uuid
        firstname
        lastname
        username
      }
    }
  }
}
    `;

/**
 * __useMemberRolesQuery__
 *
 * To run a query within a React component, call `useMemberRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberRolesQuery(baseOptions?: Apollo.QueryHookOptions<MemberRolesQuery, MemberRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberRolesQuery, MemberRolesQueryVariables>(MemberRolesDocument, options);
      }
export function useMemberRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberRolesQuery, MemberRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberRolesQuery, MemberRolesQueryVariables>(MemberRolesDocument, options);
        }
export function useMemberRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberRolesQuery, MemberRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberRolesQuery, MemberRolesQueryVariables>(MemberRolesDocument, options);
}
export type MemberRolesQueryHookResult = ReturnType<typeof useMemberRolesQuery>;
export type MemberRolesLazyQueryHookResult = ReturnType<typeof useMemberRolesLazyQuery>;
export type MemberRolesSuspenseQueryHookResult = ReturnType<typeof useMemberRolesSuspenseQuery>;
export type MemberRolesQueryResult = Apollo.QueryResult<MemberRolesQuery, MemberRolesQueryVariables>;
export const MembersDocument = gql`
    query Members($filter: MembersFilterInput!) {
  members(filter: $filter) {
    results {
      uuid
      firstname
      lastname
      state
      username
    }
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembersQuery(baseOptions: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables> & ({ variables: MembersQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export function useMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembersQuery, MembersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
}
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersSuspenseQueryHookResult = ReturnType<typeof useMembersSuspenseQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const MembershipAgreementDocument = gql`
    query MembershipAgreement($uuid: UUID!) {
  membershipAgreement(uuid: $uuid) {
    uuid
    actionLogEntries {
      ...MembershipAgreementActionLogEntry
    }
    attendanceRestriction {
      count
      timeUnit
      type
    }
    billing {
      amount
      cycle
    }
    blockedCredits
    commitment
    contractor {
      city
      country
      emailAddress
      firstname
      lastname
      streetAddress
      phone
      postalCode
      username
    }
    description
    endDate
    isFollowUpAllowed
    name
    nextEndDate
    nextMembershipAgreement {
      uuid
      name
    }
    nextTerminationDeadline
    paymentMethods {
      uuid
      isPreferred
      description
      name
      type
    }
    previousMembershipAgreement {
      uuid
      name
    }
    organisation {
      city
      country
      email
      name
      phone
      postalCode
      streetAddress
    }
    reference
    suspensionDetails {
      creator {
        firstname
        lastname
        username
      }
      message
      startDate
      endDate
    }
    renewalPeriod
    runtime
    specialTerminationUntil
    startDate
    state
    subtitle
    terminateAfterUse
    terminationPeriod
    usedCredits
  }
    }
${MembershipAgreementActionLogEntryFragmentDoc}`;

/**
 * __useMembershipAgreementQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMembershipAgreementQuery(baseOptions: Apollo.QueryHookOptions<MembershipAgreementQuery, MembershipAgreementQueryVariables> & ({
  variables: MembershipAgreementQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipAgreementQuery, MembershipAgreementQueryVariables>(MembershipAgreementDocument, options);
      }
export function useMembershipAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementQuery, MembershipAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipAgreementQuery, MembershipAgreementQueryVariables>(MembershipAgreementDocument, options);
        }
export function useMembershipAgreementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementQuery, MembershipAgreementQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementQuery, MembershipAgreementQueryVariables>(MembershipAgreementDocument, options);
}
export type MembershipAgreementQueryHookResult = ReturnType<typeof useMembershipAgreementQuery>;
export type MembershipAgreementLazyQueryHookResult = ReturnType<typeof useMembershipAgreementLazyQuery>;
export type MembershipAgreementSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementSuspenseQuery>;
export type MembershipAgreementQueryResult = Apollo.QueryResult<MembershipAgreementQuery, MembershipAgreementQueryVariables>;
export const MembershipAgreementPaymentMethodDocument = gql`
    query MembershipAgreementPaymentMethod($paymentMethodUUID: UUID!) {
  membershipAgreementPaymentMethod(uuid: $paymentMethodUUID) {
    uuid
    description
    name
    type
  }
}
    `;

/**
 * __useMembershipAgreementPaymentMethodQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementPaymentMethodQuery({
 *   variables: {
 *      paymentMethodUUID: // value for 'paymentMethodUUID'
 *   },
 * });
 */
export function useMembershipAgreementPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables> & ({
  variables: MembershipAgreementPaymentMethodQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables>(MembershipAgreementPaymentMethodDocument, options);
      }
export function useMembershipAgreementPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables>(MembershipAgreementPaymentMethodDocument, options);
        }
export function useMembershipAgreementPaymentMethodSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables>(MembershipAgreementPaymentMethodDocument, options);
}
export type MembershipAgreementPaymentMethodQueryHookResult = ReturnType<typeof useMembershipAgreementPaymentMethodQuery>;
export type MembershipAgreementPaymentMethodLazyQueryHookResult = ReturnType<typeof useMembershipAgreementPaymentMethodLazyQuery>;
export type MembershipAgreementPaymentMethodSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementPaymentMethodSuspenseQuery>;
export type MembershipAgreementPaymentMethodQueryResult = Apollo.QueryResult<MembershipAgreementPaymentMethodQuery, MembershipAgreementPaymentMethodQueryVariables>;
export const MembershipAgreementPaymentMethodsDocument = gql`
    query MembershipAgreementPaymentMethods {
  membershipAgreementPaymentMethods(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      description
      name
      type
    }
  }
}
    `;

/**
 * __useMembershipAgreementPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipAgreementPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>(MembershipAgreementPaymentMethodsDocument, options);
      }
export function useMembershipAgreementPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>(MembershipAgreementPaymentMethodsDocument, options);
        }
export function useMembershipAgreementPaymentMethodsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>(MembershipAgreementPaymentMethodsDocument, options);
}
export type MembershipAgreementPaymentMethodsQueryHookResult = ReturnType<typeof useMembershipAgreementPaymentMethodsQuery>;
export type MembershipAgreementPaymentMethodsLazyQueryHookResult = ReturnType<typeof useMembershipAgreementPaymentMethodsLazyQuery>;
export type MembershipAgreementPaymentMethodsSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementPaymentMethodsSuspenseQuery>;
export type MembershipAgreementPaymentMethodsQueryResult = Apollo.QueryResult<MembershipAgreementPaymentMethodsQuery, MembershipAgreementPaymentMethodsQueryVariables>;
export const MembershipAgreementStartsByMonthReportDocument = gql`
    query MembershipAgreementStartsByMonthReport($input: MembershipAgreementStartsByMonthReportInput!) {
  membershipAgreementStartsByMonthReport(input: $input) {
    results {
      count
      month
      templateName
    }
  }
}
    `;

/**
 * __useMembershipAgreementStartsByMonthReportQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementStartsByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementStartsByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementStartsByMonthReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembershipAgreementStartsByMonthReportQuery(baseOptions: Apollo.QueryHookOptions<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables> & ({
  variables: MembershipAgreementStartsByMonthReportQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables>(MembershipAgreementStartsByMonthReportDocument, options);
}
export function useMembershipAgreementStartsByMonthReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables>(MembershipAgreementStartsByMonthReportDocument, options);
}
export function useMembershipAgreementStartsByMonthReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables>(MembershipAgreementStartsByMonthReportDocument, options);
}
export type MembershipAgreementStartsByMonthReportQueryHookResult = ReturnType<typeof useMembershipAgreementStartsByMonthReportQuery>;
export type MembershipAgreementStartsByMonthReportLazyQueryHookResult = ReturnType<typeof useMembershipAgreementStartsByMonthReportLazyQuery>;
export type MembershipAgreementStartsByMonthReportSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementStartsByMonthReportSuspenseQuery>;
export type MembershipAgreementStartsByMonthReportQueryResult = Apollo.QueryResult<MembershipAgreementStartsByMonthReportQuery, MembershipAgreementStartsByMonthReportQueryVariables>;
export const MembershipAgreementTemplateDocument = gql`
    query MembershipAgreementTemplate($uuid: UUID!) {
  membershipAgreementTemplate(uuid: $uuid) {
    ...MembershipAgreementTemplate
  }
}
    ${MembershipAgreementTemplateFragmentDoc}`;

/**
 * __useMembershipAgreementTemplateQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementTemplateQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMembershipAgreementTemplateQuery(baseOptions: Apollo.QueryHookOptions<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables> & ({
  variables: MembershipAgreementTemplateQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>(MembershipAgreementTemplateDocument, options);
      }
export function useMembershipAgreementTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>(MembershipAgreementTemplateDocument, options);
        }
export function useMembershipAgreementTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>(MembershipAgreementTemplateDocument, options);
}
export type MembershipAgreementTemplateQueryHookResult = ReturnType<typeof useMembershipAgreementTemplateQuery>;
export type MembershipAgreementTemplateLazyQueryHookResult = ReturnType<typeof useMembershipAgreementTemplateLazyQuery>;
export type MembershipAgreementTemplateSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementTemplateSuspenseQuery>;
export type MembershipAgreementTemplateQueryResult = Apollo.QueryResult<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>;
export const MembershipAgreementTemplatesDocument = gql`
    query MembershipAgreementTemplates {
  membershipAgreementTemplates {
    ...MembershipAgreementTemplate
  }
}
    ${MembershipAgreementTemplateFragmentDoc}`;

/**
 * __useMembershipAgreementTemplatesQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipAgreementTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>(MembershipAgreementTemplatesDocument, options);
      }
export function useMembershipAgreementTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>(MembershipAgreementTemplatesDocument, options);
        }
export function useMembershipAgreementTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>(MembershipAgreementTemplatesDocument, options);
}
export type MembershipAgreementTemplatesQueryHookResult = ReturnType<typeof useMembershipAgreementTemplatesQuery>;
export type MembershipAgreementTemplatesLazyQueryHookResult = ReturnType<typeof useMembershipAgreementTemplatesLazyQuery>;
export type MembershipAgreementTemplatesSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementTemplatesSuspenseQuery>;
export type MembershipAgreementTemplatesQueryResult = Apollo.QueryResult<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>;
export const MembershipAgreementsDocument = gql`
    query MembershipAgreements($filter: MembershipAgreementsFilterInput!) {
  membershipAgreements(filter: $filter) {
    uuid
    attendanceRestriction {
      count
      timeUnit
      type
    }
    billing {
      amount
      cycle
    }
    commitment
    contractor {
      city
      country
      emailAddress
      firstname
      lastname
      phone
      postalCode
      streetAddress
      username
    }
    created
    description
    endDate
    isFollowUpAllowed
    name
    paymentMethods {
      uuid
      isPreferred
      description
      name
      type
    }
    reference
    renewalPeriod
    runtime
    specialTerminationUntil
    startDate
    state
    subtitle
    terminateAfterUse
    terminationPeriod
    usedCredits
  }
}
    `;

/**
 * __useMembershipAgreementsQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembershipAgreementsQuery(baseOptions: Apollo.QueryHookOptions<MembershipAgreementsQuery, MembershipAgreementsQueryVariables> & ({
  variables: MembershipAgreementsQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipAgreementsQuery, MembershipAgreementsQueryVariables>(MembershipAgreementsDocument, options);
      }
export function useMembershipAgreementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementsQuery, MembershipAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipAgreementsQuery, MembershipAgreementsQueryVariables>(MembershipAgreementsDocument, options);
        }
export function useMembershipAgreementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementsQuery, MembershipAgreementsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementsQuery, MembershipAgreementsQueryVariables>(MembershipAgreementsDocument, options);
}
export type MembershipAgreementsQueryHookResult = ReturnType<typeof useMembershipAgreementsQuery>;
export type MembershipAgreementsLazyQueryHookResult = ReturnType<typeof useMembershipAgreementsLazyQuery>;
export type MembershipAgreementsSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementsSuspenseQuery>;
export type MembershipAgreementsQueryResult = Apollo.QueryResult<MembershipAgreementsQuery, MembershipAgreementsQueryVariables>;
export const MembershipRequestDocument = gql`
    query MembershipRequest($uuid: UUID!) {
      membershipRequest(uuid: $uuid) {
        ...MembershipRequest
      }
      allowedMembershipRequestApprovalActions(uuid: $uuid)
    }
${MembershipRequestFragmentDoc}`;

/**
 * __useMembershipRequestQuery__
 *
 * To run a query within a React component, call `useMembershipRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRequestQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMembershipRequestQuery(baseOptions: Apollo.QueryHookOptions<MembershipRequestQuery, MembershipRequestQueryVariables> & ({
  variables: MembershipRequestQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembershipRequestQuery, MembershipRequestQueryVariables>(MembershipRequestDocument, options);
}
export function useMembershipRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRequestQuery, MembershipRequestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembershipRequestQuery, MembershipRequestQueryVariables>(MembershipRequestDocument, options);
}
export function useMembershipRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipRequestQuery, MembershipRequestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipRequestQuery, MembershipRequestQueryVariables>(MembershipRequestDocument, options);
}
export type MembershipRequestQueryHookResult = ReturnType<typeof useMembershipRequestQuery>;
export type MembershipRequestLazyQueryHookResult = ReturnType<typeof useMembershipRequestLazyQuery>;
export type MembershipRequestSuspenseQueryHookResult = ReturnType<typeof useMembershipRequestSuspenseQuery>;
export type MembershipRequestQueryResult = Apollo.QueryResult<MembershipRequestQuery, MembershipRequestQueryVariables>;
export const MembershipRequestCountDocument = gql`
    query MembershipRequestCount {
      membershipRequestCount(
        states: [OWNER_APPROVAL_PENDING, MEMBER_ACCEPTANCE_PENDING]
      )
    }
`;

/**
 * __useMembershipRequestCountQuery__
 *
 * To run a query within a React component, call `useMembershipRequestCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRequestCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipRequestCountQuery(baseOptions?: Apollo.QueryHookOptions<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>(MembershipRequestCountDocument, options);
}
export function useMembershipRequestCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>(MembershipRequestCountDocument, options);
}
export function useMembershipRequestCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>(MembershipRequestCountDocument, options);
}
export type MembershipRequestCountQueryHookResult = ReturnType<typeof useMembershipRequestCountQuery>;
export type MembershipRequestCountLazyQueryHookResult = ReturnType<typeof useMembershipRequestCountLazyQuery>;
export type MembershipRequestCountSuspenseQueryHookResult = ReturnType<typeof useMembershipRequestCountSuspenseQuery>;
export type MembershipRequestCountQueryResult = Apollo.QueryResult<MembershipRequestCountQuery, MembershipRequestCountQueryVariables>;
export const MyBenchmarksDocument = gql`
    query MyBenchmarks($filter: MyBenchmarksFilterInput!, $pagination: PaginationInput!) {
  myBenchmarks(filter: $filter, pagination: $pagination) {
    results {
      uuid
      name
      resultType
      resultOrder
      results {
        uuid
        score
        date
      }
    }
    totalCount
  }
}
    `;

/**
 * __useMyBenchmarksQuery__
 *
 * To run a query within a React component, call `useMyBenchmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBenchmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBenchmarksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMyBenchmarksQuery(baseOptions: Apollo.QueryHookOptions<MyBenchmarksQuery, MyBenchmarksQueryVariables> & ({
  variables: MyBenchmarksQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBenchmarksQuery, MyBenchmarksQueryVariables>(MyBenchmarksDocument, options);
      }
export function useMyBenchmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBenchmarksQuery, MyBenchmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBenchmarksQuery, MyBenchmarksQueryVariables>(MyBenchmarksDocument, options);
        }
export function useMyBenchmarksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyBenchmarksQuery, MyBenchmarksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyBenchmarksQuery, MyBenchmarksQueryVariables>(MyBenchmarksDocument, options);
}
export type MyBenchmarksQueryHookResult = ReturnType<typeof useMyBenchmarksQuery>;
export type MyBenchmarksLazyQueryHookResult = ReturnType<typeof useMyBenchmarksLazyQuery>;
export type MyBenchmarksSuspenseQueryHookResult = ReturnType<typeof useMyBenchmarksSuspenseQuery>;
export type MyBenchmarksQueryResult = Apollo.QueryResult<MyBenchmarksQuery, MyBenchmarksQueryVariables>;
export const MyBilledEventRegistrationsDocument = gql`
    query MyBilledEventRegistrations($filter: MyBilledEventRegistrationsFilterInput!, $pagination: PaginationInput!) {
  myBilledEventRegistrations(filter: $filter, pagination: $pagination) {
    results {
      event {
        uuid
        start
        state
        eventDetails {
          name
          color
        }
      }
      feeAmount
      isSettled
      isSplit
    }
    hasNext
    totalCount
  }
}
    `;

/**
 * __useMyBilledEventRegistrationsQuery__
 *
 * To run a query within a React component, call `useMyBilledEventRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBilledEventRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBilledEventRegistrationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMyBilledEventRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables> & ({
  variables: MyBilledEventRegistrationsQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables>(MyBilledEventRegistrationsDocument, options);
      }
export function useMyBilledEventRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables>(MyBilledEventRegistrationsDocument, options);
        }
export function useMyBilledEventRegistrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables>(MyBilledEventRegistrationsDocument, options);
}
export type MyBilledEventRegistrationsQueryHookResult = ReturnType<typeof useMyBilledEventRegistrationsQuery>;
export type MyBilledEventRegistrationsLazyQueryHookResult = ReturnType<typeof useMyBilledEventRegistrationsLazyQuery>;
export type MyBilledEventRegistrationsSuspenseQueryHookResult = ReturnType<typeof useMyBilledEventRegistrationsSuspenseQuery>;
export type MyBilledEventRegistrationsQueryResult = Apollo.QueryResult<MyBilledEventRegistrationsQuery, MyBilledEventRegistrationsQueryVariables>;
export const OrganisationDocument = gql`
    query Organisation {
  organisation {
    about
    city
    country
    email
    facebookUrl
    instagramUrl
    mapsUrl
    name
    phone
    postalCode
    shortDescription
    streetAddress
    websiteUrl
    whatsAppNumber
  }
}
    `;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
      }
export function useOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
        }
export function useOrganisationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
}
export type OrganisationQueryHookResult = ReturnType<typeof useOrganisationQuery>;
export type OrganisationLazyQueryHookResult = ReturnType<typeof useOrganisationLazyQuery>;
export type OrganisationSuspenseQueryHookResult = ReturnType<typeof useOrganisationSuspenseQuery>;
export type OrganisationQueryResult = Apollo.QueryResult<OrganisationQuery, OrganisationQueryVariables>;
export const OrganisationImagesDocument = gql`
    query OrganisationImages {
  organisationImages {
    emailHeader {
      publicId
      version
    }
    logoSquare {
      publicId
      version
    }
    shopBanner {
      publicId
      version
    }
  }
}
    `;

/**
 * __useOrganisationImagesQuery__
 *
 * To run a query within a React component, call `useOrganisationImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationImagesQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationImagesQuery, OrganisationImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationImagesQuery, OrganisationImagesQueryVariables>(OrganisationImagesDocument, options);
      }
export function useOrganisationImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationImagesQuery, OrganisationImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationImagesQuery, OrganisationImagesQueryVariables>(OrganisationImagesDocument, options);
        }
export function useOrganisationImagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationImagesQuery, OrganisationImagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OrganisationImagesQuery, OrganisationImagesQueryVariables>(OrganisationImagesDocument, options);
}
export type OrganisationImagesQueryHookResult = ReturnType<typeof useOrganisationImagesQuery>;
export type OrganisationImagesLazyQueryHookResult = ReturnType<typeof useOrganisationImagesLazyQuery>;
export type OrganisationImagesSuspenseQueryHookResult = ReturnType<typeof useOrganisationImagesSuspenseQuery>;
export type OrganisationImagesQueryResult = Apollo.QueryResult<OrganisationImagesQuery, OrganisationImagesQueryVariables>;
export const UnpublishedWorkoutsDocument = gql`
    query UnpublishedWorkouts {
  unpublishedWorkouts {
    results {
      uuid
      date
      publishingDate
      title
      workoutType {
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useUnpublishedWorkoutsQuery__
 *
 * To run a query within a React component, call `useUnpublishedWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnpublishedWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnpublishedWorkoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnpublishedWorkoutsQuery(baseOptions?: Apollo.QueryHookOptions<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>(UnpublishedWorkoutsDocument, options);
}
export function useUnpublishedWorkoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>(UnpublishedWorkoutsDocument, options);
}
export function useUnpublishedWorkoutsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>(UnpublishedWorkoutsDocument, options);
}
export type UnpublishedWorkoutsQueryHookResult = ReturnType<typeof useUnpublishedWorkoutsQuery>;
export type UnpublishedWorkoutsLazyQueryHookResult = ReturnType<typeof useUnpublishedWorkoutsLazyQuery>;
export type UnpublishedWorkoutsSuspenseQueryHookResult = ReturnType<typeof useUnpublishedWorkoutsSuspenseQuery>;
export type UnpublishedWorkoutsQueryResult = Apollo.QueryResult<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>;
export const WebPushKeysDocument = gql`
    query WebPushKeys {
  webPushKeys {
    publicKey
  }
}
    `;

/**
 * __useWebPushKeysQuery__
 *
 * To run a query within a React component, call `useWebPushKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebPushKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebPushKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebPushKeysQuery(baseOptions?: Apollo.QueryHookOptions<WebPushKeysQuery, WebPushKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebPushKeysQuery, WebPushKeysQueryVariables>(WebPushKeysDocument, options);
      }
export function useWebPushKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebPushKeysQuery, WebPushKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebPushKeysQuery, WebPushKeysQueryVariables>(WebPushKeysDocument, options);
        }
export function useWebPushKeysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebPushKeysQuery, WebPushKeysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WebPushKeysQuery, WebPushKeysQueryVariables>(WebPushKeysDocument, options);
}
export type WebPushKeysQueryHookResult = ReturnType<typeof useWebPushKeysQuery>;
export type WebPushKeysLazyQueryHookResult = ReturnType<typeof useWebPushKeysLazyQuery>;
export type WebPushKeysSuspenseQueryHookResult = ReturnType<typeof useWebPushKeysSuspenseQuery>;
export type WebPushKeysQueryResult = Apollo.QueryResult<WebPushKeysQuery, WebPushKeysQueryVariables>;
export const WorkoutDocument = gql`
    query Workout($uuid: UUID!) {
  workout(uuid: $uuid) {
    uuid
    date
    description
    publishingDate
    scoreBoards {
      uuid
      isWhiteboardResultMode
      name
      order
      resultOrder
      results {
        uuid
        category {
          uuid
          name
        }
        comment
        created
        creator {
          uuid
          username
        }
        reactions {
          uuid
          creator {
            uuid
            username
          }
          type
        }
        score
      }
      resultType
      submissionEnd
    }
    title
    trainerNotes
    visibility
    workoutType {
      name
    }
  }
}
    `;

/**
 * __useWorkoutQuery__
 *
 * To run a query within a React component, call `useWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useWorkoutQuery(baseOptions: Apollo.QueryHookOptions<WorkoutQuery, WorkoutQueryVariables> & ({ variables: WorkoutQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutQuery, WorkoutQueryVariables>(WorkoutDocument, options);
      }
export function useWorkoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutQuery, WorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutQuery, WorkoutQueryVariables>(WorkoutDocument, options);
        }
export function useWorkoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutQuery, WorkoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutQuery, WorkoutQueryVariables>(WorkoutDocument, options);
}
export type WorkoutQueryHookResult = ReturnType<typeof useWorkoutQuery>;
export type WorkoutLazyQueryHookResult = ReturnType<typeof useWorkoutLazyQuery>;
export type WorkoutSuspenseQueryHookResult = ReturnType<typeof useWorkoutSuspenseQuery>;
export type WorkoutQueryResult = Apollo.QueryResult<WorkoutQuery, WorkoutQueryVariables>;
export const WorkoutResultCategoryDocument = gql`
    query WorkoutResultCategory($uuid: UUID!) {
  workoutResultCategory(uuid: $uuid) {
    uuid
    name
  }
}
    `;

/**
 * __useWorkoutResultCategoryQuery__
 *
 * To run a query within a React component, call `useWorkoutResultCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutResultCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutResultCategoryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useWorkoutResultCategoryQuery(baseOptions: Apollo.QueryHookOptions<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables> & ({
  variables: WorkoutResultCategoryQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables>(WorkoutResultCategoryDocument, options);
      }
export function useWorkoutResultCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables>(WorkoutResultCategoryDocument, options);
        }
export function useWorkoutResultCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables>(WorkoutResultCategoryDocument, options);
}
export type WorkoutResultCategoryQueryHookResult = ReturnType<typeof useWorkoutResultCategoryQuery>;
export type WorkoutResultCategoryLazyQueryHookResult = ReturnType<typeof useWorkoutResultCategoryLazyQuery>;
export type WorkoutResultCategorySuspenseQueryHookResult = ReturnType<typeof useWorkoutResultCategorySuspenseQuery>;
export type WorkoutResultCategoryQueryResult = Apollo.QueryResult<WorkoutResultCategoryQuery, WorkoutResultCategoryQueryVariables>;
export const WorkoutResultCategoriesDocument = gql`
    query WorkoutResultCategories {
  workoutResultCategories {
    uuid
    name
  }
}
    `;

/**
 * __useWorkoutResultCategoriesQuery__
 *
 * To run a query within a React component, call `useWorkoutResultCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutResultCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutResultCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkoutResultCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>(WorkoutResultCategoriesDocument, options);
      }
export function useWorkoutResultCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>(WorkoutResultCategoriesDocument, options);
        }
export function useWorkoutResultCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>(WorkoutResultCategoriesDocument, options);
}
export type WorkoutResultCategoriesQueryHookResult = ReturnType<typeof useWorkoutResultCategoriesQuery>;
export type WorkoutResultCategoriesLazyQueryHookResult = ReturnType<typeof useWorkoutResultCategoriesLazyQuery>;
export type WorkoutResultCategoriesSuspenseQueryHookResult = ReturnType<typeof useWorkoutResultCategoriesSuspenseQuery>;
export type WorkoutResultCategoriesQueryResult = Apollo.QueryResult<WorkoutResultCategoriesQuery, WorkoutResultCategoriesQueryVariables>;
export const WorkoutTypeDocument = gql`
    query WorkoutType($uuid: UUID!) {
  workoutType(uuid: $uuid) {
    uuid
    name
  }
}
    `;

/**
 * __useWorkoutTypeQuery__
 *
 * To run a query within a React component, call `useWorkoutTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutTypeQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useWorkoutTypeQuery(baseOptions: Apollo.QueryHookOptions<WorkoutTypeQuery, WorkoutTypeQueryVariables> & ({
  variables: WorkoutTypeQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutTypeQuery, WorkoutTypeQueryVariables>(WorkoutTypeDocument, options);
      }
export function useWorkoutTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutTypeQuery, WorkoutTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutTypeQuery, WorkoutTypeQueryVariables>(WorkoutTypeDocument, options);
        }
export function useWorkoutTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutTypeQuery, WorkoutTypeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutTypeQuery, WorkoutTypeQueryVariables>(WorkoutTypeDocument, options);
}
export type WorkoutTypeQueryHookResult = ReturnType<typeof useWorkoutTypeQuery>;
export type WorkoutTypeLazyQueryHookResult = ReturnType<typeof useWorkoutTypeLazyQuery>;
export type WorkoutTypeSuspenseQueryHookResult = ReturnType<typeof useWorkoutTypeSuspenseQuery>;
export type WorkoutTypeQueryResult = Apollo.QueryResult<WorkoutTypeQuery, WorkoutTypeQueryVariables>;
export const WorkoutTypesDocument = gql`
    query WorkoutTypes {
  workoutTypes {
    uuid
    name
  }
}
    `;

/**
 * __useWorkoutTypesQuery__
 *
 * To run a query within a React component, call `useWorkoutTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkoutTypesQuery(baseOptions?: Apollo.QueryHookOptions<WorkoutTypesQuery, WorkoutTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutTypesQuery, WorkoutTypesQueryVariables>(WorkoutTypesDocument, options);
      }
export function useWorkoutTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutTypesQuery, WorkoutTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutTypesQuery, WorkoutTypesQueryVariables>(WorkoutTypesDocument, options);
        }
export function useWorkoutTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutTypesQuery, WorkoutTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutTypesQuery, WorkoutTypesQueryVariables>(WorkoutTypesDocument, options);
}
export type WorkoutTypesQueryHookResult = ReturnType<typeof useWorkoutTypesQuery>;
export type WorkoutTypesLazyQueryHookResult = ReturnType<typeof useWorkoutTypesLazyQuery>;
export type WorkoutTypesSuspenseQueryHookResult = ReturnType<typeof useWorkoutTypesSuspenseQuery>;
export type WorkoutTypesQueryResult = Apollo.QueryResult<WorkoutTypesQuery, WorkoutTypesQueryVariables>;
export const WorkoutsDocument = gql`
    query Workouts($filter: WorkoutsFilterInput!, $pagination: PaginationInput!) {
  workouts(filter: $filter, pagination: $pagination) {
    results {
      uuid
      date
      publishingDate
      title
      workoutType {
        name
      }
      scoreBoards {
        results {
          uuid
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useWorkoutsQuery__
 *
 * To run a query within a React component, call `useWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useWorkoutsQuery(baseOptions: Apollo.QueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables> & ({ variables: WorkoutsQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, options);
      }
export function useWorkoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, options);
        }
export function useWorkoutsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, options);
}
export type WorkoutsQueryHookResult = ReturnType<typeof useWorkoutsQuery>;
export type WorkoutsLazyQueryHookResult = ReturnType<typeof useWorkoutsLazyQuery>;
export type WorkoutsSuspenseQueryHookResult = ReturnType<typeof useWorkoutsSuspenseQuery>;
export type WorkoutsQueryResult = Apollo.QueryResult<WorkoutsQuery, WorkoutsQueryVariables>;
export const UpdateDataProtectionPageDocument = gql`
    query UpdateDataProtectionPage {
  organisation {
    dataProtectionHtml
  }
}
    `;

/**
 * __useUpdateDataProtectionPageQuery__
 *
 * To run a query within a React component, call `useUpdateDataProtectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataProtectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateDataProtectionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateDataProtectionPageQuery(baseOptions?: Apollo.QueryHookOptions<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>(UpdateDataProtectionPageDocument, options);
      }
export function useUpdateDataProtectionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>(UpdateDataProtectionPageDocument, options);
        }
export function useUpdateDataProtectionPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>(UpdateDataProtectionPageDocument, options);
}
export type UpdateDataProtectionPageQueryHookResult = ReturnType<typeof useUpdateDataProtectionPageQuery>;
export type UpdateDataProtectionPageLazyQueryHookResult = ReturnType<typeof useUpdateDataProtectionPageLazyQuery>;
export type UpdateDataProtectionPageSuspenseQueryHookResult = ReturnType<typeof useUpdateDataProtectionPageSuspenseQuery>;
export type UpdateDataProtectionPageQueryResult = Apollo.QueryResult<UpdateDataProtectionPageQuery, UpdateDataProtectionPageQueryVariables>;
export const MemberPenaltyPageDataDocument = gql`
    query MemberPenaltyPageData($penaltyFilter: PenaltiesFilterInput!, $remarksFilter: PenaltyRemarksFilterInput!) {
  penaltyRemarks(filter: $remarksFilter, paginationInput: {limit: 10, offset: 0}) {
    result {
      created
      end
      eventRegistration {
        uuid
        event {
          uuid
          eventDetails {
            name
          }
          start
        }
        state
      }
    }
  }
  penalties(filter: $penaltyFilter, pagination: {limit: 10, offset: 0}) {
    results {
      uuid
      created
      end
    }
  }
}
    `;

/**
 * __useMemberPenaltyPageDataQuery__
 *
 * To run a query within a React component, call `useMemberPenaltyPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPenaltyPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPenaltyPageDataQuery({
 *   variables: {
 *      penaltyFilter: // value for 'penaltyFilter'
 *      remarksFilter: // value for 'remarksFilter'
 *   },
 * });
 */
export function useMemberPenaltyPageDataQuery(baseOptions: Apollo.QueryHookOptions<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables> & ({
  variables: MemberPenaltyPageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables>(MemberPenaltyPageDataDocument, options);
      }
export function useMemberPenaltyPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables>(MemberPenaltyPageDataDocument, options);
        }
export function useMemberPenaltyPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables>(MemberPenaltyPageDataDocument, options);
}
export type MemberPenaltyPageDataQueryHookResult = ReturnType<typeof useMemberPenaltyPageDataQuery>;
export type MemberPenaltyPageDataLazyQueryHookResult = ReturnType<typeof useMemberPenaltyPageDataLazyQuery>;
export type MemberPenaltyPageDataSuspenseQueryHookResult = ReturnType<typeof useMemberPenaltyPageDataSuspenseQuery>;
export type MemberPenaltyPageDataQueryResult = Apollo.QueryResult<MemberPenaltyPageDataQuery, MemberPenaltyPageDataQueryVariables>;
export const MembershipsAdminPageDataDocument = gql`
    query MembershipsAdminPageData($filter: MembershipAgreementsFilterInput!) {
  membershipAgreementFilters {
    membershipAgreementNames
    membershipAgreementTemplates {
      uuid
      name
      state
    }
    references
  }
  membershipAgreements(filter: $filter) {
    uuid
    attendanceRestriction {
      count
      type
    }
    billing {
      amount
    }
    commitment
    contractor {
      firstname
      lastname
      username
    }
    name
    state
    usedCredits
  }
}
    `;

/**
 * __useMembershipsAdminPageDataQuery__
 *
 * To run a query within a React component, call `useMembershipsAdminPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipsAdminPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipsAdminPageDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembershipsAdminPageDataQuery(baseOptions: Apollo.QueryHookOptions<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables> & ({
  variables: MembershipsAdminPageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables>(MembershipsAdminPageDataDocument, options);
}
export function useMembershipsAdminPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables>(MembershipsAdminPageDataDocument, options);
}
export function useMembershipsAdminPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables>(MembershipsAdminPageDataDocument, options);
}
export type MembershipsAdminPageDataQueryHookResult = ReturnType<typeof useMembershipsAdminPageDataQuery>;
export type MembershipsAdminPageDataLazyQueryHookResult = ReturnType<typeof useMembershipsAdminPageDataLazyQuery>;
export type MembershipsAdminPageDataSuspenseQueryHookResult = ReturnType<typeof useMembershipsAdminPageDataSuspenseQuery>;
export type MembershipsAdminPageDataQueryResult = Apollo.QueryResult<MembershipsAdminPageDataQuery, MembershipsAdminPageDataQueryVariables>;
export const MembershipAgreementByIdPageDocument = gql`
    query MembershipAgreementByIdPage($uuid: UUID!) {
      membershipAgreement(uuid: $uuid) {
        uuid
        actionLogEntries {
          ...MembershipAgreementActionLogEntry
        }
        attendanceRestriction {
          count
          timeUnit
          type
        }
        billing {
          amount
          cycle
        }
        blockedCredits
        commitment
        contractor {
          uuid
          city
          country
          emailAddress
          firstname
          lastname
          phone
          postalCode
          streetAddress
          username
        }
        description
        endDate
        isFollowUpAllowed
        name
        nextEndDate
        nextMembershipAgreement {
          uuid
          name
        }
        nextTerminationDeadline
        paymentMethods {
          uuid
          isPreferred
          description
          name
          type
        }
        previousMembershipAgreement {
          uuid
          name
        }
        organisation {
          city
          country
          email
          name
          phone
          postalCode
          streetAddress
        }
        reference
        suspensionDetails {
          creator {
            firstname
            lastname
            username
          }
          message
          startDate
          endDate
        }
        renewalPeriod
        runtime
        specialTerminationUntil
        startDate
        state
        subtitle
        terminateAfterUse
        terminationPeriod
        usedCredits
      }
      allowedMembershipAgreementActions(uuid: $uuid)
    }
${MembershipAgreementActionLogEntryFragmentDoc}`;

/**
 * __useMembershipAgreementByIdPageQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementByIdPageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMembershipAgreementByIdPageQuery(baseOptions: Apollo.QueryHookOptions<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables> & ({
  variables: MembershipAgreementByIdPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables>(MembershipAgreementByIdPageDocument, options);
}
export function useMembershipAgreementByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables>(MembershipAgreementByIdPageDocument, options);
}
export function useMembershipAgreementByIdPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables>(MembershipAgreementByIdPageDocument, options);
}
export type MembershipAgreementByIdPageQueryHookResult = ReturnType<typeof useMembershipAgreementByIdPageQuery>;
export type MembershipAgreementByIdPageLazyQueryHookResult = ReturnType<typeof useMembershipAgreementByIdPageLazyQuery>;
export type MembershipAgreementByIdPageSuspenseQueryHookResult = ReturnType<typeof useMembershipAgreementByIdPageSuspenseQuery>;
export type MembershipAgreementByIdPageQueryResult = Apollo.QueryResult<MembershipAgreementByIdPageQuery, MembershipAgreementByIdPageQueryVariables>;
export const MembershipRequestsPageDataDocument = gql`
    query MembershipRequestsPageData {
      membershipRequests(
    filter: {states: [OWNER_APPROVAL_PENDING, MEMBER_ACCEPTANCE_PENDING]}
    pagination: {limit: 999, offset: 0}
  ) {
    results {
      uuid
      creator {
        firstname
        lastname
        username
      }
      modified
      name
      state
    }
  }
}
    `;

/**
 * __useMembershipRequestsPageDataQuery__
 *
 * To run a query within a React component, call `useMembershipRequestsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRequestsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipRequestsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>(MembershipRequestsPageDataDocument, options);
      }
export function useMembershipRequestsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>(MembershipRequestsPageDataDocument, options);
        }
export function useMembershipRequestsPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>(MembershipRequestsPageDataDocument, options);
}
export type MembershipRequestsPageDataQueryHookResult = ReturnType<typeof useMembershipRequestsPageDataQuery>;
export type MembershipRequestsPageDataLazyQueryHookResult = ReturnType<typeof useMembershipRequestsPageDataLazyQuery>;
export type MembershipRequestsPageDataSuspenseQueryHookResult = ReturnType<typeof useMembershipRequestsPageDataSuspenseQuery>;
export type MembershipRequestsPageDataQueryResult = Apollo.QueryResult<MembershipRequestsPageDataQuery, MembershipRequestsPageDataQueryVariables>;
export const MembershipRequestByIdPageDataDocument = gql`
    query MembershipRequestByIdPageData($uuid: UUID!) {
      membershipRequest(uuid: $uuid) {
        uuid
    actionLogEntries {
      ...MembershipAgreementActionLogEntry
    }
        approvalCount
    approvalFacts {
      uuid
      creator {
        firstname
        lastname
        username
      }
      date
    }
        attendanceRestriction {
          count
          timeUnit
          type
        }
        billing {
          amount
          cycle
        }
        commitment
        creator {
          uuid
          city
          country
          emailAddress
          firstname
          lastname
          phone
          postalCode
          streetAddress
          username
        }
        description
        endDate
        membershipAgreementTemplate {
          approvalConfiguration {
            description
            name
            requiresApproval
          }
        }
        name
        paymentMethods {
          uuid
          isPreferred
          description
          name
          type
        }
    previousMembershipAgreement {
      uuid
      name
      nextPaymentDate
    }
        reference
        renewalPeriod
        runtime
        specialTerminationPeriod
        startDate
        state
        terminationPeriod
  }
      allowedMembershipRequestApprovalActions(uuid: $uuid)
}
${MembershipAgreementActionLogEntryFragmentDoc}`;

/**
 * __useMembershipRequestByIdPageDataQuery__
 *
 * To run a query within a React component, call `useMembershipRequestByIdPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestByIdPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRequestByIdPageDataQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMembershipRequestByIdPageDataQuery(baseOptions: Apollo.QueryHookOptions<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables> & ({
  variables: MembershipRequestByIdPageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables>(MembershipRequestByIdPageDataDocument, options);
      }
export function useMembershipRequestByIdPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables>(MembershipRequestByIdPageDataDocument, options);
        }
export function useMembershipRequestByIdPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables>(MembershipRequestByIdPageDataDocument, options);
}
export type MembershipRequestByIdPageDataQueryHookResult = ReturnType<typeof useMembershipRequestByIdPageDataQuery>;
export type MembershipRequestByIdPageDataLazyQueryHookResult = ReturnType<typeof useMembershipRequestByIdPageDataLazyQuery>;
export type MembershipRequestByIdPageDataSuspenseQueryHookResult = ReturnType<typeof useMembershipRequestByIdPageDataSuspenseQuery>;
export type MembershipRequestByIdPageDataQueryResult = Apollo.QueryResult<MembershipRequestByIdPageDataQuery, MembershipRequestByIdPageDataQueryVariables>;
export const UpdateOrganisationInfoPageDocument = gql`
    query UpdateOrganisationInfoPage {
  organisation {
    about
    city
    country
    email
    facebookUrl
    instagramUrl
    mapsUrl
    name
    phone
    postalCode
    shopUrl
    shortDescription
    streetAddress
    websiteUrl
    whatsAppNumber
  }
  googleMapsSettings {
    placeId
  }
  equipment {
    location
  }
}
    `;

/**
 * __useUpdateOrganisationInfoPageQuery__
 *
 * To run a query within a React component, call `useUpdateOrganisationInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateOrganisationInfoPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateOrganisationInfoPageQuery(baseOptions?: Apollo.QueryHookOptions<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>(UpdateOrganisationInfoPageDocument, options);
}
export function useUpdateOrganisationInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>(UpdateOrganisationInfoPageDocument, options);
}
export function useUpdateOrganisationInfoPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>(UpdateOrganisationInfoPageDocument, options);
}
export type UpdateOrganisationInfoPageQueryHookResult = ReturnType<typeof useUpdateOrganisationInfoPageQuery>;
export type UpdateOrganisationInfoPageLazyQueryHookResult = ReturnType<typeof useUpdateOrganisationInfoPageLazyQuery>;
export type UpdateOrganisationInfoPageSuspenseQueryHookResult = ReturnType<typeof useUpdateOrganisationInfoPageSuspenseQuery>;
export type UpdateOrganisationInfoPageQueryResult = Apollo.QueryResult<UpdateOrganisationInfoPageQuery, UpdateOrganisationInfoPageQueryVariables>;
export const PenaltiesPageDocument = gql`
    query PenaltiesPage {
  penaltyRemarks(filter: {}, paginationInput: {limit: 999, offset: 0}) {
    result {
      created
      end
      eventRegistration {
        uuid
        event {
          uuid
          eventDetails {
            name
          }
          start
        }
        state
      }
      member {
        uuid
        person {
          firstname
          lastname
          username
        }
      }
    }
  }
  penalties(filter: {active: true}, pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      created
      end
      member {
        uuid
        person {
          firstname
          lastname
          username
        }
      }
    }
  }
}
    `;

/**
 * __usePenaltiesPageQuery__
 *
 * To run a query within a React component, call `usePenaltiesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePenaltiesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePenaltiesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePenaltiesPageQuery(baseOptions?: Apollo.QueryHookOptions<PenaltiesPageQuery, PenaltiesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PenaltiesPageQuery, PenaltiesPageQueryVariables>(PenaltiesPageDocument, options);
      }
export function usePenaltiesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PenaltiesPageQuery, PenaltiesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PenaltiesPageQuery, PenaltiesPageQueryVariables>(PenaltiesPageDocument, options);
        }
export function usePenaltiesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PenaltiesPageQuery, PenaltiesPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PenaltiesPageQuery, PenaltiesPageQueryVariables>(PenaltiesPageDocument, options);
}
export type PenaltiesPageQueryHookResult = ReturnType<typeof usePenaltiesPageQuery>;
export type PenaltiesPageLazyQueryHookResult = ReturnType<typeof usePenaltiesPageLazyQuery>;
export type PenaltiesPageSuspenseQueryHookResult = ReturnType<typeof usePenaltiesPageSuspenseQuery>;
export type PenaltiesPageQueryResult = Apollo.QueryResult<PenaltiesPageQuery, PenaltiesPageQueryVariables>;
export const UpdateTermsOfServicePageDocument = gql`
    query UpdateTermsOfServicePage {
  organisation {
    termsOfServiceHtml
  }
}
    `;

/**
 * __useUpdateTermsOfServicePageQuery__
 *
 * To run a query within a React component, call `useUpdateTermsOfServicePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermsOfServicePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateTermsOfServicePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateTermsOfServicePageQuery(baseOptions?: Apollo.QueryHookOptions<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>(UpdateTermsOfServicePageDocument, options);
      }
export function useUpdateTermsOfServicePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>(UpdateTermsOfServicePageDocument, options);
        }
export function useUpdateTermsOfServicePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>(UpdateTermsOfServicePageDocument, options);
}
export type UpdateTermsOfServicePageQueryHookResult = ReturnType<typeof useUpdateTermsOfServicePageQuery>;
export type UpdateTermsOfServicePageLazyQueryHookResult = ReturnType<typeof useUpdateTermsOfServicePageLazyQuery>;
export type UpdateTermsOfServicePageSuspenseQueryHookResult = ReturnType<typeof useUpdateTermsOfServicePageSuspenseQuery>;
export type UpdateTermsOfServicePageQueryResult = Apollo.QueryResult<UpdateTermsOfServicePageQuery, UpdateTermsOfServicePageQueryVariables>;
export const ClassesAuthenticatedPageDocument = gql`
  query ClassesAuthenticatedPage($classesFilter: EventsFilterInput!, $eventsFilter: EventsFilterInput!, $classRegistrationsFilter: MyEventRegistrationsFilterInput!, $eventRegistrationsFilter: MyEventRegistrationsFilterInput!) {
    classes: events(filter: $classesFilter) {
      ...ClassesPageEvent
    }
    events: events(filter: $eventsFilter) {
      ...ClassesPageEvent
    }
    classRegistrations: myEventRegistrations(
      filter: $classRegistrationsFilter
      pagination: {limit: 999, offset: 0}
    ) {
      results {
        uuid
        state
        event {
          uuid
        }
      }
    }
    eventRegistrations: myEventRegistrations(
      filter: $eventRegistrationsFilter
      pagination: {limit: 999, offset: 0}
    ) {
      results {
        uuid
        state
        event {
          uuid
      }
    }
    }
  }
${ClassesPageEventFragmentDoc}`;

/**
 * __useClassesAuthenticatedPageQuery__
 *
 * To run a query within a React component, call `useClassesAuthenticatedPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesAuthenticatedPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesAuthenticatedPageQuery({
 *   variables: {
 *      classesFilter: // value for 'classesFilter'
 *      eventsFilter: // value for 'eventsFilter'
 *      classRegistrationsFilter: // value for 'classRegistrationsFilter'
 *      eventRegistrationsFilter: // value for 'eventRegistrationsFilter'
 *   },
 * });
 */
export function useClassesAuthenticatedPageQuery(baseOptions: Apollo.QueryHookOptions<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables> & ({
  variables: ClassesAuthenticatedPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>(ClassesAuthenticatedPageDocument, options);
      }
export function useClassesAuthenticatedPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>(ClassesAuthenticatedPageDocument, options);
        }
export function useClassesAuthenticatedPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>(ClassesAuthenticatedPageDocument, options);
}
export type ClassesAuthenticatedPageQueryHookResult = ReturnType<typeof useClassesAuthenticatedPageQuery>;
export type ClassesAuthenticatedPageLazyQueryHookResult = ReturnType<typeof useClassesAuthenticatedPageLazyQuery>;
export type ClassesAuthenticatedPageSuspenseQueryHookResult = ReturnType<typeof useClassesAuthenticatedPageSuspenseQuery>;
export type ClassesAuthenticatedPageQueryResult = Apollo.QueryResult<ClassesAuthenticatedPageQuery, ClassesAuthenticatedPageQueryVariables>;
export const ClassesVisitorPageDocument = gql`
    query ClassesVisitorPage($classesFilter: EventsFilterInput!) {
      classes: events(filter: $classesFilter) {
        ...ClassesPageEvent
      }
    }
${ClassesPageEventFragmentDoc}`;

/**
 * __useClassesVisitorPageQuery__
 *
 * To run a query within a React component, call `useClassesVisitorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesVisitorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesVisitorPageQuery({
 *   variables: {
 *      classesFilter: // value for 'classesFilter'
 *   },
 * });
 */
export function useClassesVisitorPageQuery(baseOptions: Apollo.QueryHookOptions<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables> & ({
  variables: ClassesVisitorPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>(ClassesVisitorPageDocument, options);
      }
export function useClassesVisitorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>(ClassesVisitorPageDocument, options);
        }
export function useClassesVisitorPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>(ClassesVisitorPageDocument, options);
}
export type ClassesVisitorPageQueryHookResult = ReturnType<typeof useClassesVisitorPageQuery>;
export type ClassesVisitorPageLazyQueryHookResult = ReturnType<typeof useClassesVisitorPageLazyQuery>;
export type ClassesVisitorPageSuspenseQueryHookResult = ReturnType<typeof useClassesVisitorPageSuspenseQuery>;
export type ClassesVisitorPageQueryResult = Apollo.QueryResult<ClassesVisitorPageQuery, ClassesVisitorPageQueryVariables>;
export const ClassByIdPageVisitorDocument = gql`
    query ClassByIdPageVisitor($eventId: String!) {
  event(eventId: $eventId) {
    uuid
    end
    start
    state
    eventDetails {
      uuid
      color
      description
      name
      registrationSettings {
        availableSpots
        feeAmount
        isRegistrable
        isSwitchToOtherEventAllowed
        lateCancellationPeriod
        minimumNumberOfRegistrations
        minimumNumberOfRegistrationsPeriod
        registrationStartPeriod
        resolveWaitListPeriod
      }
    }
  }
}
    `;

/**
 * __useClassByIdPageVisitorQuery__
 *
 * To run a query within a React component, call `useClassByIdPageVisitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassByIdPageVisitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassByIdPageVisitorQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useClassByIdPageVisitorQuery(baseOptions: Apollo.QueryHookOptions<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables> & ({
  variables: ClassByIdPageVisitorQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>(ClassByIdPageVisitorDocument, options);
      }
export function useClassByIdPageVisitorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>(ClassByIdPageVisitorDocument, options);
        }
export function useClassByIdPageVisitorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>(ClassByIdPageVisitorDocument, options);
}
export type ClassByIdPageVisitorQueryHookResult = ReturnType<typeof useClassByIdPageVisitorQuery>;
export type ClassByIdPageVisitorLazyQueryHookResult = ReturnType<typeof useClassByIdPageVisitorLazyQuery>;
export type ClassByIdPageVisitorSuspenseQueryHookResult = ReturnType<typeof useClassByIdPageVisitorSuspenseQuery>;
export type ClassByIdPageVisitorQueryResult = Apollo.QueryResult<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>;
export const ClassByIdPageMemberDocument = gql`
    query ClassByIdPageMember($eventId: String!) {
  event(eventId: $eventId) {
    uuid
    end
    start
    state
    eventDetails {
      uuid
      color
      description
      name
      registrationSettings {
        availableSpots
        feeAmount
        isRegistrable
        isSwitchToOtherEventAllowed
        lateCancellationPeriod
        minimumNumberOfRegistrations
        minimumNumberOfRegistrationsPeriod
        registrationStartPeriod
        resolveWaitListPeriod
      }
    }
    registrations {
      uuid
      state
      comment
      member {
        uuid
        person {
          uuid
          username
        }
      }
      registrationDate
      visitor {
        uuid
        name
      }
    }
  }
  myEventRegistrations(
    filter: {eventId: $eventId}
    pagination: {limit: 999, offset: 0}
  ) {
    results {
      uuid
    }
  }
  isEventRegistrationAllowed(eventId: $eventId) {
    isAllowed
    expectedRegistrationState
    denyReason
  }
  isEventRegistrationCancellationAllowed(eventId: $eventId) {
    denyReason
    isAllowed
    isLateCancellation
  }
}
    `;

/**
 * __useClassByIdPageMemberQuery__
 *
 * To run a query within a React component, call `useClassByIdPageMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassByIdPageMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassByIdPageMemberQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useClassByIdPageMemberQuery(baseOptions: Apollo.QueryHookOptions<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables> & ({
  variables: ClassByIdPageMemberQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>(ClassByIdPageMemberDocument, options);
      }
export function useClassByIdPageMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>(ClassByIdPageMemberDocument, options);
        }
export function useClassByIdPageMemberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>(ClassByIdPageMemberDocument, options);
}
export type ClassByIdPageMemberQueryHookResult = ReturnType<typeof useClassByIdPageMemberQuery>;
export type ClassByIdPageMemberLazyQueryHookResult = ReturnType<typeof useClassByIdPageMemberLazyQuery>;
export type ClassByIdPageMemberSuspenseQueryHookResult = ReturnType<typeof useClassByIdPageMemberSuspenseQuery>;
export type ClassByIdPageMemberQueryResult = Apollo.QueryResult<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>;
export const CopyClassPageDataDocument = gql`
    query CopyClassPageData($eventId: String!) {
  event(eventId: $eventId) {
    uuid
    start
    end
    eventDetails {
      uuid
      additionalPaymentDescription
      color
      description
      isSpecial
      location
      name
      visibility
      visibilityPreview
      registrationSettings {
        availableSpots
        feeAmount
        isAutomaticRegistrationConfirmationEnabled
        isRegistrable
        isSwitchToOtherEventAllowed
        lateCancellationPeriod
        minimumNumberOfRegistrations
        minimumNumberOfRegistrationsPeriod
        registrationStartPeriod
        resolveWaitListPeriod
      }
      schedule {
        firstStart
        firstEnd
        recurrence
        recurrenceEnd
      }
    }
  }
}
    `;

/**
 * __useCopyClassPageDataQuery__
 *
 * To run a query within a React component, call `useCopyClassPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyClassPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyClassPageDataQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCopyClassPageDataQuery(baseOptions: Apollo.QueryHookOptions<CopyClassPageDataQuery, CopyClassPageDataQueryVariables> & ({
  variables: CopyClassPageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyClassPageDataQuery, CopyClassPageDataQueryVariables>(CopyClassPageDataDocument, options);
      }
export function useCopyClassPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyClassPageDataQuery, CopyClassPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyClassPageDataQuery, CopyClassPageDataQueryVariables>(CopyClassPageDataDocument, options);
        }
export function useCopyClassPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CopyClassPageDataQuery, CopyClassPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CopyClassPageDataQuery, CopyClassPageDataQueryVariables>(CopyClassPageDataDocument, options);
}
export type CopyClassPageDataQueryHookResult = ReturnType<typeof useCopyClassPageDataQuery>;
export type CopyClassPageDataLazyQueryHookResult = ReturnType<typeof useCopyClassPageDataLazyQuery>;
export type CopyClassPageDataSuspenseQueryHookResult = ReturnType<typeof useCopyClassPageDataSuspenseQuery>;
export type CopyClassPageDataQueryResult = Apollo.QueryResult<CopyClassPageDataQuery, CopyClassPageDataQueryVariables>;
export const UpdateClassPageDataDocument = gql`
    query UpdateClassPageData($eventId: String!) {
  event(eventId: $eventId) {
    uuid
    start
    end
    eventDetails {
      uuid
      additionalPaymentDescription
      color
      description
      isSpecial
      location
      name
      visibility
      visibilityPreview
      registrationSettings {
        availableSpots
        feeAmount
        isAutomaticRegistrationConfirmationEnabled
        isRegistrable
        isSwitchToOtherEventAllowed
        lateCancellationPeriod
        minimumNumberOfRegistrations
        minimumNumberOfRegistrationsPeriod
        registrationStartPeriod
        resolveWaitListPeriod
      }
      schedule {
        firstStart
        firstEnd
        recurrence
        recurrenceEnd
      }
    }
  }
}
    `;

/**
 * __useUpdateClassPageDataQuery__
 *
 * To run a query within a React component, call `useUpdateClassPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateClassPageDataQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUpdateClassPageDataQuery(baseOptions: Apollo.QueryHookOptions<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables> & ({
  variables: UpdateClassPageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables>(UpdateClassPageDataDocument, options);
      }
export function useUpdateClassPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables>(UpdateClassPageDataDocument, options);
        }
export function useUpdateClassPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables>(UpdateClassPageDataDocument, options);
}
export type UpdateClassPageDataQueryHookResult = ReturnType<typeof useUpdateClassPageDataQuery>;
export type UpdateClassPageDataLazyQueryHookResult = ReturnType<typeof useUpdateClassPageDataLazyQuery>;
export type UpdateClassPageDataSuspenseQueryHookResult = ReturnType<typeof useUpdateClassPageDataSuspenseQuery>;
export type UpdateClassPageDataQueryResult = Apollo.QueryResult<UpdateClassPageDataQuery, UpdateClassPageDataQueryVariables>;
export const EventsPageDataDocument = gql`
    query EventsPageData($filter: EventsFilterInput!) {
  events(filter: $filter) {
    uuid
    end
    eventDetails {
      uuid
      name
      location
      registrationSettings {
        availableSpots
      }
    }
    start
    state
  }
}
    `;

/**
 * __useEventsPageDataQuery__
 *
 * To run a query within a React component, call `useEventsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsPageDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsPageDataQuery(baseOptions: Apollo.QueryHookOptions<EventsPageDataQuery, EventsPageDataQueryVariables> & ({
  variables: EventsPageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsPageDataQuery, EventsPageDataQueryVariables>(EventsPageDataDocument, options);
      }
export function useEventsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsPageDataQuery, EventsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsPageDataQuery, EventsPageDataQueryVariables>(EventsPageDataDocument, options);
        }
export function useEventsPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventsPageDataQuery, EventsPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<EventsPageDataQuery, EventsPageDataQueryVariables>(EventsPageDataDocument, options);
}
export type EventsPageDataQueryHookResult = ReturnType<typeof useEventsPageDataQuery>;
export type EventsPageDataLazyQueryHookResult = ReturnType<typeof useEventsPageDataLazyQuery>;
export type EventsPageDataSuspenseQueryHookResult = ReturnType<typeof useEventsPageDataSuspenseQuery>;
export type EventsPageDataQueryResult = Apollo.QueryResult<EventsPageDataQuery, EventsPageDataQueryVariables>;
export const InfoPageDocument = gql`
    query InfoPage($eventFilter: EventsFilterInput!) {
  organisation {
    about
    city
    facebookUrl
    instagramUrl
    name
    phone
    postalCode
    shopUrl
    streetAddress
    websiteUrl
    whatsAppNumber
  }
  organisationImages {
    shopBanner {
      publicId
      version
    }
  }
  googleMapsSettings {
    placeId
  }
  equipment {
    location
  }
  membershipAgreementTemplates {
    uuid
    attendanceRestriction {
      count
      timeUnit
      type
    }
    billing {
      amount
      cycle
    }
    name
    sortPosition
  }
  events(filter: $eventFilter) {
    uuid
    end
    start
    state
    eventDetails {
      uuid
      color
      name
      registrationSettings {
        availableSpots
        isRegistrable
        registrationStartPeriod
      }
    }
    registrations {
      state
    }
  }
}
    `;

/**
 * __useInfoPageQuery__
 *
 * To run a query within a React component, call `useInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoPageQuery({
 *   variables: {
 *      eventFilter: // value for 'eventFilter'
 *   },
 * });
 */
export function useInfoPageQuery(baseOptions: Apollo.QueryHookOptions<InfoPageQuery, InfoPageQueryVariables> & ({ variables: InfoPageQueryVariables; skip?: boolean; } | {
  skip: boolean;
})) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfoPageQuery, InfoPageQueryVariables>(InfoPageDocument, options);
      }
export function useInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoPageQuery, InfoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfoPageQuery, InfoPageQueryVariables>(InfoPageDocument, options);
        }
export function useInfoPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InfoPageQuery, InfoPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InfoPageQuery, InfoPageQueryVariables>(InfoPageDocument, options);
}
export type InfoPageQueryHookResult = ReturnType<typeof useInfoPageQuery>;
export type InfoPageLazyQueryHookResult = ReturnType<typeof useInfoPageLazyQuery>;
export type InfoPageSuspenseQueryHookResult = ReturnType<typeof useInfoPageSuspenseQuery>;
export type InfoPageQueryResult = Apollo.QueryResult<InfoPageQuery, InfoPageQueryVariables>;
export const DataProtectionPageDocument = gql`
    query DataProtectionPage {
  organisation {
    dataProtectionHtml
  }
}
    `;

/**
 * __useDataProtectionPageQuery__
 *
 * To run a query within a React component, call `useDataProtectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataProtectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataProtectionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataProtectionPageQuery(baseOptions?: Apollo.QueryHookOptions<DataProtectionPageQuery, DataProtectionPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataProtectionPageQuery, DataProtectionPageQueryVariables>(DataProtectionPageDocument, options);
      }
export function useDataProtectionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataProtectionPageQuery, DataProtectionPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataProtectionPageQuery, DataProtectionPageQueryVariables>(DataProtectionPageDocument, options);
        }
export function useDataProtectionPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DataProtectionPageQuery, DataProtectionPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DataProtectionPageQuery, DataProtectionPageQueryVariables>(DataProtectionPageDocument, options);
}
export type DataProtectionPageQueryHookResult = ReturnType<typeof useDataProtectionPageQuery>;
export type DataProtectionPageLazyQueryHookResult = ReturnType<typeof useDataProtectionPageLazyQuery>;
export type DataProtectionPageSuspenseQueryHookResult = ReturnType<typeof useDataProtectionPageSuspenseQuery>;
export type DataProtectionPageQueryResult = Apollo.QueryResult<DataProtectionPageQuery, DataProtectionPageQueryVariables>;
export const TermsOfServicePageDocument = gql`
    query TermsOfServicePage {
  organisation {
    termsOfServiceHtml
  }
}
    `;

/**
 * __useTermsOfServicePageQuery__
 *
 * To run a query within a React component, call `useTermsOfServicePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsOfServicePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsOfServicePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsOfServicePageQuery(baseOptions?: Apollo.QueryHookOptions<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>(TermsOfServicePageDocument, options);
      }
export function useTermsOfServicePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>(TermsOfServicePageDocument, options);
        }
export function useTermsOfServicePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>(TermsOfServicePageDocument, options);
}
export type TermsOfServicePageQueryHookResult = ReturnType<typeof useTermsOfServicePageQuery>;
export type TermsOfServicePageLazyQueryHookResult = ReturnType<typeof useTermsOfServicePageLazyQuery>;
export type TermsOfServicePageSuspenseQueryHookResult = ReturnType<typeof useTermsOfServicePageSuspenseQuery>;
export type TermsOfServicePageQueryResult = Apollo.QueryResult<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>;
export const MembershipsPageAuthenticatedDataDocument = gql`
    query MembershipsPageAuthenticatedData {
  myMembershipAgreements(filter: {states: [ACTIVE]}) {
    results {
      uuid
    }
  }
}
    `;

/**
 * __useMembershipsPageAuthenticatedDataQuery__
 *
 * To run a query within a React component, call `useMembershipsPageAuthenticatedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipsPageAuthenticatedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipsPageAuthenticatedDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipsPageAuthenticatedDataQuery(baseOptions?: Apollo.QueryHookOptions<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>(MembershipsPageAuthenticatedDataDocument, options);
      }
export function useMembershipsPageAuthenticatedDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>(MembershipsPageAuthenticatedDataDocument, options);
        }
export function useMembershipsPageAuthenticatedDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>(MembershipsPageAuthenticatedDataDocument, options);
}
export type MembershipsPageAuthenticatedDataQueryHookResult = ReturnType<typeof useMembershipsPageAuthenticatedDataQuery>;
export type MembershipsPageAuthenticatedDataLazyQueryHookResult = ReturnType<typeof useMembershipsPageAuthenticatedDataLazyQuery>;
export type MembershipsPageAuthenticatedDataSuspenseQueryHookResult = ReturnType<typeof useMembershipsPageAuthenticatedDataSuspenseQuery>;
export type MembershipsPageAuthenticatedDataQueryResult = Apollo.QueryResult<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>;
export const MembershipsByIdPageDataAuthenticatedDocument = gql`
    query MembershipsByIdPageDataAuthenticated($filter: MyMembershipAgreementsFilterInput!) {
  me {
    uuid
    birthday
    city
    country
    firstname
    lastname
    phone
    postalCode
    streetAddress
    username
  }
  myMembershipAgreements(filter: $filter) {
    results {
      uuid
      name
      isFollowUpAllowed
    }
  }
  organisation {
    dataProtectionHtml
    name
    termsOfServiceHtml
  }
}
    `;

/**
 * __useMembershipsByIdPageDataAuthenticatedQuery__
 *
 * To run a query within a React component, call `useMembershipsByIdPageDataAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipsByIdPageDataAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipsByIdPageDataAuthenticatedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembershipsByIdPageDataAuthenticatedQuery(baseOptions: Apollo.QueryHookOptions<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables> & ({
  variables: MembershipsByIdPageDataAuthenticatedQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>(MembershipsByIdPageDataAuthenticatedDocument, options);
      }
export function useMembershipsByIdPageDataAuthenticatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>(MembershipsByIdPageDataAuthenticatedDocument, options);
        }
export function useMembershipsByIdPageDataAuthenticatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>(MembershipsByIdPageDataAuthenticatedDocument, options);
}
export type MembershipsByIdPageDataAuthenticatedQueryHookResult = ReturnType<typeof useMembershipsByIdPageDataAuthenticatedQuery>;
export type MembershipsByIdPageDataAuthenticatedLazyQueryHookResult = ReturnType<typeof useMembershipsByIdPageDataAuthenticatedLazyQuery>;
export type MembershipsByIdPageDataAuthenticatedSuspenseQueryHookResult = ReturnType<typeof useMembershipsByIdPageDataAuthenticatedSuspenseQuery>;
export type MembershipsByIdPageDataAuthenticatedQueryResult = Apollo.QueryResult<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>;
export const UpdateMembershipAgreementTemplatePageDataDocument = gql`
    query UpdateMembershipAgreementTemplatePageData($uuid: UUID!) {
  membershipAgreementTemplate(uuid: $uuid) {
    ...MembershipAgreementTemplate
  }
  approvalConfigurations(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      name
    }
  }
  membershipAgreementPaymentMethods(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      name
    }
  }
}
    ${MembershipAgreementTemplateFragmentDoc}`;

/**
 * __useUpdateMembershipAgreementTemplatePageDataQuery__
 *
 * To run a query within a React component, call `useUpdateMembershipAgreementTemplatePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipAgreementTemplatePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateMembershipAgreementTemplatePageDataQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateMembershipAgreementTemplatePageDataQuery(baseOptions: Apollo.QueryHookOptions<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables> & ({
  variables: UpdateMembershipAgreementTemplatePageDataQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables>(UpdateMembershipAgreementTemplatePageDataDocument, options);
      }
export function useUpdateMembershipAgreementTemplatePageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables>(UpdateMembershipAgreementTemplatePageDataDocument, options);
        }
export function useUpdateMembershipAgreementTemplatePageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables>(UpdateMembershipAgreementTemplatePageDataDocument, options);
}
export type UpdateMembershipAgreementTemplatePageDataQueryHookResult = ReturnType<typeof useUpdateMembershipAgreementTemplatePageDataQuery>;
export type UpdateMembershipAgreementTemplatePageDataLazyQueryHookResult = ReturnType<typeof useUpdateMembershipAgreementTemplatePageDataLazyQuery>;
export type UpdateMembershipAgreementTemplatePageDataSuspenseQueryHookResult = ReturnType<typeof useUpdateMembershipAgreementTemplatePageDataSuspenseQuery>;
export type UpdateMembershipAgreementTemplatePageDataQueryResult = Apollo.QueryResult<UpdateMembershipAgreementTemplatePageDataQuery, UpdateMembershipAgreementTemplatePageDataQueryVariables>;
export const MembershipConfigurationPageDocument = gql`
    query MembershipConfigurationPage {
  membershipAgreementPaymentMethods(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      description
      name
      type
    }
  }
  approvalConfigurations(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      approvalCount
      description
      name
      requiresApproval
    }
  }
}
    `;

/**
 * __useMembershipConfigurationPageQuery__
 *
 * To run a query within a React component, call `useMembershipConfigurationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipConfigurationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipConfigurationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipConfigurationPageQuery(baseOptions?: Apollo.QueryHookOptions<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>(MembershipConfigurationPageDocument, options);
      }
export function useMembershipConfigurationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>(MembershipConfigurationPageDocument, options);
        }
export function useMembershipConfigurationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>(MembershipConfigurationPageDocument, options);
}
export type MembershipConfigurationPageQueryHookResult = ReturnType<typeof useMembershipConfigurationPageQuery>;
export type MembershipConfigurationPageLazyQueryHookResult = ReturnType<typeof useMembershipConfigurationPageLazyQuery>;
export type MembershipConfigurationPageSuspenseQueryHookResult = ReturnType<typeof useMembershipConfigurationPageSuspenseQuery>;
export type MembershipConfigurationPageQueryResult = Apollo.QueryResult<MembershipConfigurationPageQuery, MembershipConfigurationPageQueryVariables>;
export const CreateMembershipAgreementTemplatePageDocument = gql`
    query CreateMembershipAgreementTemplatePage {
  approvalConfigurations(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      name
    }
  }
  membershipAgreementPaymentMethods(pagination: {limit: 999, offset: 0}) {
    results {
      uuid
      name
    }
  }
}
    `;

/**
 * __useCreateMembershipAgreementTemplatePageQuery__
 *
 * To run a query within a React component, call `useCreateMembershipAgreementTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMembershipAgreementTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMembershipAgreementTemplatePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateMembershipAgreementTemplatePageQuery(baseOptions?: Apollo.QueryHookOptions<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>(CreateMembershipAgreementTemplatePageDocument, options);
      }
export function useCreateMembershipAgreementTemplatePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>(CreateMembershipAgreementTemplatePageDocument, options);
        }
export function useCreateMembershipAgreementTemplatePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>(CreateMembershipAgreementTemplatePageDocument, options);
}
export type CreateMembershipAgreementTemplatePageQueryHookResult = ReturnType<typeof useCreateMembershipAgreementTemplatePageQuery>;
export type CreateMembershipAgreementTemplatePageLazyQueryHookResult = ReturnType<typeof useCreateMembershipAgreementTemplatePageLazyQuery>;
export type CreateMembershipAgreementTemplatePageSuspenseQueryHookResult = ReturnType<typeof useCreateMembershipAgreementTemplatePageSuspenseQuery>;
export type CreateMembershipAgreementTemplatePageQueryResult = Apollo.QueryResult<CreateMembershipAgreementTemplatePageQuery, CreateMembershipAgreementTemplatePageQueryVariables>;
export const MyDashboardPageDocument = gql`
    query MyDashboardPage($filter: MyEventRegistrationsFilterInput!) {
  myEventRegistrations(filter: $filter, pagination: {limit: 3, offset: 0}) {
    results {
      state
      uuid
      event {
        uuid
        end
        start
        state
        eventDetails {
          uuid
          color
          name
          registrationSettings {
            availableSpots
            isRegistrable
            registrationStartPeriod
          }
        }
        registrations {
          state
        }
      }
    }
  }
  myPenaltyRemarks {
    list {
      created
      end
      eventRegistration {
        event {
          uuid
          start
          eventDetails {
            name
          }
        }
      }
    }
  }
  myPenalties(filter: {active: true}) {
    list {
      end
    }
  }
}
    `;

/**
 * __useMyDashboardPageQuery__
 *
 * To run a query within a React component, call `useMyDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDashboardPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMyDashboardPageQuery(baseOptions: Apollo.QueryHookOptions<MyDashboardPageQuery, MyDashboardPageQueryVariables> & ({
  variables: MyDashboardPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<MyDashboardPageQuery, MyDashboardPageQueryVariables>(MyDashboardPageDocument, options);
      }
export function useMyDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyDashboardPageQuery, MyDashboardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<MyDashboardPageQuery, MyDashboardPageQueryVariables>(MyDashboardPageDocument, options);
        }
export function useMyDashboardPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyDashboardPageQuery, MyDashboardPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyDashboardPageQuery, MyDashboardPageQueryVariables>(MyDashboardPageDocument, options);
}
export type MyDashboardPageQueryHookResult = ReturnType<typeof useMyDashboardPageQuery>;
export type MyDashboardPageLazyQueryHookResult = ReturnType<typeof useMyDashboardPageLazyQuery>;
export type MyDashboardPageSuspenseQueryHookResult = ReturnType<typeof useMyDashboardPageSuspenseQuery>;
export type MyDashboardPageQueryResult = Apollo.QueryResult<MyDashboardPageQuery, MyDashboardPageQueryVariables>;
export const MyAccountPageDocument = gql`
    query MyAccountPage {
  requiredPersonalInformation
  me {
    uuid
    birthday
    city
    country
    firstname
    lastname
    phone
    postalCode
    streetAddress
    username
  }
}
    `;

/**
 * __useMyAccountPageQuery__
 *
 * To run a query within a React component, call `useMyAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAccountPageQuery(baseOptions?: Apollo.QueryHookOptions<MyAccountPageQuery, MyAccountPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<MyAccountPageQuery, MyAccountPageQueryVariables>(MyAccountPageDocument, options);
      }
export function useMyAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAccountPageQuery, MyAccountPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<MyAccountPageQuery, MyAccountPageQueryVariables>(MyAccountPageDocument, options);
        }
export function useMyAccountPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyAccountPageQuery, MyAccountPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyAccountPageQuery, MyAccountPageQueryVariables>(MyAccountPageDocument, options);
}
export type MyAccountPageQueryHookResult = ReturnType<typeof useMyAccountPageQuery>;
export type MyAccountPageLazyQueryHookResult = ReturnType<typeof useMyAccountPageLazyQuery>;
export type MyAccountPageSuspenseQueryHookResult = ReturnType<typeof useMyAccountPageSuspenseQuery>;
export type MyAccountPageQueryResult = Apollo.QueryResult<MyAccountPageQuery, MyAccountPageQueryVariables>;
export const MyMembershipsPageDataDocument = gql`
    query MyMembershipsPageData {
      myMembershipRequests(
    filter: {states: [OWNER_APPROVAL_PENDING, MEMBER_ACCEPTANCE_PENDING]}
    pagination: {limit: 999, offset: 0}
  ) {
    results {
      uuid
      created
      name
      state
      subtitle
    }
  }
  myMembershipAgreements(filter: {states: null}) {
    results {
      uuid
      created
      name
      state
      subtitle
    }
  }
}
    `;

/**
 * __useMyMembershipsPageDataQuery__
 *
 * To run a query within a React component, call `useMyMembershipsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMembershipsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMembershipsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyMembershipsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>(MyMembershipsPageDataDocument, options);
      }
export function useMyMembershipsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>(MyMembershipsPageDataDocument, options);
        }
export function useMyMembershipsPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>(MyMembershipsPageDataDocument, options);
}
export type MyMembershipsPageDataQueryHookResult = ReturnType<typeof useMyMembershipsPageDataQuery>;
export type MyMembershipsPageDataLazyQueryHookResult = ReturnType<typeof useMyMembershipsPageDataLazyQuery>;
export type MyMembershipsPageDataSuspenseQueryHookResult = ReturnType<typeof useMyMembershipsPageDataSuspenseQuery>;
export type MyMembershipsPageDataQueryResult = Apollo.QueryResult<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>;
export const MyMembershipAgreementByIdPageDocument = gql`
    query MyMembershipAgreementByIdPage($uuid: UUID!) {
      membershipAgreement(uuid: $uuid) {
        uuid
        attendanceRestriction {
          count
          timeUnit
          type
        }
        billing {
          amount
          cycle
        }
        blockedCredits
        commitment
        contractor {
          city
          country
          emailAddress
          firstname
          lastname
          phone
          postalCode
          streetAddress
          username
        }
        description
        endDate
        isFollowUpAllowed
        name
        nextEndDate
        nextMembershipAgreement {
          uuid
          name
        }
        nextTerminationDeadline
        paymentMethods {
          uuid
          isPreferred
          description
          name
          type
        }
        previousMembershipAgreement {
          uuid
          name
        }
        organisation {
          city
          country
          email
          name
          phone
          postalCode
          streetAddress
        }
        renewalPeriod
        runtime
        specialTerminationUntil
        startDate
        state
        suspensionDetails {
          creator {
            firstname
            lastname
            username
          }
          message
          startDate
          endDate
        }
        subtitle
        terminationPeriod
        usedCredits
      }
      allowedMembershipAgreementActions(uuid: $uuid)
    }
`;

/**
 * __useMyMembershipAgreementByIdPageQuery__
 *
 * To run a query within a React component, call `useMyMembershipAgreementByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMembershipAgreementByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMembershipAgreementByIdPageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMyMembershipAgreementByIdPageQuery(baseOptions: Apollo.QueryHookOptions<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables> & ({
  variables: MyMembershipAgreementByIdPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>(MyMembershipAgreementByIdPageDocument, options);
}
export function useMyMembershipAgreementByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>(MyMembershipAgreementByIdPageDocument, options);
}
export function useMyMembershipAgreementByIdPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>(MyMembershipAgreementByIdPageDocument, options);
}
export type MyMembershipAgreementByIdPageQueryHookResult = ReturnType<typeof useMyMembershipAgreementByIdPageQuery>;
export type MyMembershipAgreementByIdPageLazyQueryHookResult = ReturnType<typeof useMyMembershipAgreementByIdPageLazyQuery>;
export type MyMembershipAgreementByIdPageSuspenseQueryHookResult = ReturnType<typeof useMyMembershipAgreementByIdPageSuspenseQuery>;
export type MyMembershipAgreementByIdPageQueryResult = Apollo.QueryResult<MyMembershipAgreementByIdPageQuery, MyMembershipAgreementByIdPageQueryVariables>;
export const MyMembershipRequestByIdPageDocument = gql`
    query MyMembershipRequestByIdPage($uuid: UUID!) {
      membershipRequest(uuid: $uuid) {
        uuid
        attendanceRestriction {
          count
          timeUnit
          type
        }
        billing {
          amount
          cycle
        }
        commitment
        creator {
          city
          country
          emailAddress
          firstname
          lastname
          phone
          postalCode
          streetAddress
          username
        }
        description
        endDate
        membershipAgreementTemplate {
          approvalConfiguration {
            description
            name
            requiresApproval
          }
          organisation {
            city
            country
            email
            name
            phone
            postalCode
            streetAddress
          }
        }
        name
        paymentMethods {
          uuid
          isPreferred
          description
          name
          type
        }
        previousMembershipAgreement {
          uuid
          name
          nextPaymentDate
        }
        renewalPeriod
        runtime
        specialTerminationPeriod
        startDate
        state
        terminationPeriod
      }
      allowedMembershipRequestApprovalActions(uuid: $uuid)
    }
`;

/**
 * __useMyMembershipRequestByIdPageQuery__
 *
 * To run a query within a React component, call `useMyMembershipRequestByIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMembershipRequestByIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMembershipRequestByIdPageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMyMembershipRequestByIdPageQuery(baseOptions: Apollo.QueryHookOptions<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables> & ({
  variables: MyMembershipRequestByIdPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>(MyMembershipRequestByIdPageDocument, options);
}
export function useMyMembershipRequestByIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>(MyMembershipRequestByIdPageDocument, options);
}
export function useMyMembershipRequestByIdPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>(MyMembershipRequestByIdPageDocument, options);
}
export type MyMembershipRequestByIdPageQueryHookResult = ReturnType<typeof useMyMembershipRequestByIdPageQuery>;
export type MyMembershipRequestByIdPageLazyQueryHookResult = ReturnType<typeof useMyMembershipRequestByIdPageLazyQuery>;
export type MyMembershipRequestByIdPageSuspenseQueryHookResult = ReturnType<typeof useMyMembershipRequestByIdPageSuspenseQuery>;
export type MyMembershipRequestByIdPageQueryResult = Apollo.QueryResult<MyMembershipRequestByIdPageQuery, MyMembershipRequestByIdPageQueryVariables>;
export const NotificationSettingsPageDocument = gql`
    query NotificationSettingsPage {
  myEmailPreferences {
    entries {
      isEnabled
      notificationScope
    }
  }
  myWebPushSubscriptions {
    uuid
    created
    endpoint
    userAgent
  }
}
    `;

/**
 * __useNotificationSettingsPageQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSettingsPageQuery(baseOptions?: Apollo.QueryHookOptions<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>(NotificationSettingsPageDocument, options);
}
export function useNotificationSettingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>(NotificationSettingsPageDocument, options);
}
export function useNotificationSettingsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>(NotificationSettingsPageDocument, options);
}
export type NotificationSettingsPageQueryHookResult = ReturnType<typeof useNotificationSettingsPageQuery>;
export type NotificationSettingsPageLazyQueryHookResult = ReturnType<typeof useNotificationSettingsPageLazyQuery>;
export type NotificationSettingsPageSuspenseQueryHookResult = ReturnType<typeof useNotificationSettingsPageSuspenseQuery>;
export type NotificationSettingsPageQueryResult = Apollo.QueryResult<NotificationSettingsPageQuery, NotificationSettingsPageQueryVariables>;
export const CopyWorkoutPageDocument = gql`
    query CopyWorkoutPage($uuid: UUID!) {
  workout(uuid: $uuid) {
    ...WorkoutFormFragment
  }
  workoutTypes {
    uuid
    name
  }
}
    ${WorkoutFormFragmentFragmentDoc}`;

/**
 * __useCopyWorkoutPageQuery__
 *
 * To run a query within a React component, call `useCopyWorkoutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyWorkoutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyWorkoutPageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCopyWorkoutPageQuery(baseOptions: Apollo.QueryHookOptions<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables> & ({
  variables: CopyWorkoutPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>(CopyWorkoutPageDocument, options);
      }
export function useCopyWorkoutPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>(CopyWorkoutPageDocument, options);
        }
export function useCopyWorkoutPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>(CopyWorkoutPageDocument, options);
}
export type CopyWorkoutPageQueryHookResult = ReturnType<typeof useCopyWorkoutPageQuery>;
export type CopyWorkoutPageLazyQueryHookResult = ReturnType<typeof useCopyWorkoutPageLazyQuery>;
export type CopyWorkoutPageSuspenseQueryHookResult = ReturnType<typeof useCopyWorkoutPageSuspenseQuery>;
export type CopyWorkoutPageQueryResult = Apollo.QueryResult<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>;
export const UpdateWorkoutPageDocument = gql`
    query UpdateWorkoutPage($uuid: UUID!) {
  workout(uuid: $uuid) {
    ...WorkoutFormFragment
  }
  workoutTypes {
    uuid
    name
  }
}
    ${WorkoutFormFragmentFragmentDoc}`;

/**
 * __useUpdateWorkoutPageQuery__
 *
 * To run a query within a React component, call `useUpdateWorkoutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateWorkoutPageQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateWorkoutPageQuery(baseOptions: Apollo.QueryHookOptions<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables> & ({
  variables: UpdateWorkoutPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>(UpdateWorkoutPageDocument, options);
      }
export function useUpdateWorkoutPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>(UpdateWorkoutPageDocument, options);
        }
export function useUpdateWorkoutPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>(UpdateWorkoutPageDocument, options);
}
export type UpdateWorkoutPageQueryHookResult = ReturnType<typeof useUpdateWorkoutPageQuery>;
export type UpdateWorkoutPageLazyQueryHookResult = ReturnType<typeof useUpdateWorkoutPageLazyQuery>;
export type UpdateWorkoutPageSuspenseQueryHookResult = ReturnType<typeof useUpdateWorkoutPageSuspenseQuery>;
export type UpdateWorkoutPageQueryResult = Apollo.QueryResult<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>;
export const WorkoutConfigurationPageDocument = gql`
    query WorkoutConfigurationPage {
  workoutTypes {
    uuid
    name
  }
  workoutResultCategories {
    uuid
    name
  }
}
    `;

/**
 * __useWorkoutConfigurationPageQuery__
 *
 * To run a query within a React component, call `useWorkoutConfigurationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutConfigurationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutConfigurationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkoutConfigurationPageQuery(baseOptions?: Apollo.QueryHookOptions<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>(WorkoutConfigurationPageDocument, options);
      }
export function useWorkoutConfigurationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>(WorkoutConfigurationPageDocument, options);
        }
export function useWorkoutConfigurationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>(WorkoutConfigurationPageDocument, options);
}
export type WorkoutConfigurationPageQueryHookResult = ReturnType<typeof useWorkoutConfigurationPageQuery>;
export type WorkoutConfigurationPageLazyQueryHookResult = ReturnType<typeof useWorkoutConfigurationPageLazyQuery>;
export type WorkoutConfigurationPageSuspenseQueryHookResult = ReturnType<typeof useWorkoutConfigurationPageSuspenseQuery>;
export type WorkoutConfigurationPageQueryResult = Apollo.QueryResult<WorkoutConfigurationPageQuery, WorkoutConfigurationPageQueryVariables>;
export const CreateWorkoutPageDocument = gql`
    query CreateWorkoutPage {
  workoutTypes {
    uuid
    name
  }
}
    `;

/**
 * __useCreateWorkoutPageQuery__
 *
 * To run a query within a React component, call `useCreateWorkoutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateWorkoutPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateWorkoutPageQuery(baseOptions?: Apollo.QueryHookOptions<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>(CreateWorkoutPageDocument, options);
      }
export function useCreateWorkoutPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>(CreateWorkoutPageDocument, options);
        }
export function useCreateWorkoutPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>(CreateWorkoutPageDocument, options);
}
export type CreateWorkoutPageQueryHookResult = ReturnType<typeof useCreateWorkoutPageQuery>;
export type CreateWorkoutPageLazyQueryHookResult = ReturnType<typeof useCreateWorkoutPageLazyQuery>;
export type CreateWorkoutPageSuspenseQueryHookResult = ReturnType<typeof useCreateWorkoutPageSuspenseQuery>;
export type CreateWorkoutPageQueryResult = Apollo.QueryResult<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>;
export const WorkoutListPageDocument = gql`
    query WorkoutListPage($filter: WorkoutsFilterInput!, $pagination: PaginationInput!) {
  unpublishedWorkouts {
    results {
      ...WorkoutListPageWorkout
    }
  }
  workouts(filter: $filter, pagination: $pagination) {
    results {
      ...WorkoutListPageWorkout
    }
    totalCount
  }
}
${WorkoutListPageWorkoutFragmentDoc}`;

/**
 * __useWorkoutListPageQuery__
 *
 * To run a query within a React component, call `useWorkoutListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutListPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useWorkoutListPageQuery(baseOptions: Apollo.QueryHookOptions<WorkoutListPageQuery, WorkoutListPageQueryVariables> & ({
  variables: WorkoutListPageQueryVariables;
  skip?: boolean;
} | { skip: boolean; })) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WorkoutListPageQuery, WorkoutListPageQueryVariables>(WorkoutListPageDocument, options);
}
export function useWorkoutListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutListPageQuery, WorkoutListPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WorkoutListPageQuery, WorkoutListPageQueryVariables>(WorkoutListPageDocument, options);
}
export function useWorkoutListPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkoutListPageQuery, WorkoutListPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkoutListPageQuery, WorkoutListPageQueryVariables>(WorkoutListPageDocument, options);
}
export type WorkoutListPageQueryHookResult = ReturnType<typeof useWorkoutListPageQuery>;
export type WorkoutListPageLazyQueryHookResult = ReturnType<typeof useWorkoutListPageLazyQuery>;
export type WorkoutListPageSuspenseQueryHookResult = ReturnType<typeof useWorkoutListPageSuspenseQuery>;
export type WorkoutListPageQueryResult = Apollo.QueryResult<WorkoutListPageQuery, WorkoutListPageQueryVariables>;