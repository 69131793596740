import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { DEFAULT_UUID } from "@/common/Object";
import { UUID } from "@/common/Types";
import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { UpdateWorkoutForm } from "@/components/workouts/workout";
import { GraphQLClient } from "@/contexts/graphql";
import { UpdateWorkoutPageDocument, UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables, WorkoutInput, } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { Group, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, LoaderFunctionArgs, useLoaderData } from "react-router-dom";


export function UpdateWorkoutPageLoader({ params }: LoaderFunctionArgs) {
    return GraphQLClient.query<UpdateWorkoutPageQuery, UpdateWorkoutPageQueryVariables>({
        query: UpdateWorkoutPageDocument,
        variables: { uuid: params.uuid! },
    })
}

export function UpdateWorkoutPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<UpdateWorkoutPageQuery>

    const { t } = useTranslation()
    const { navigateBack } = useMyPowerHourNavigate()

    const workout = queryResult.data.workout
    const types = queryResult.data.workoutTypes
    const scoreBoardsWithResult = queryResult.data.workout.scoreBoards.filter(it => it.results.length > 0).map(it => ({ uuid: it.uuid, resultCount: it.results.length }))

    const onSuccess = (uuid: UUID) => {
        showSuccessNotification(t("common:Forms.Saved"))
        navigateBack(generatePath(Routes.WORKOUTS_BY_ID, { uuid: uuid }))
    }

    const onError = (error: unknown) => {
        showErrorNotification({ error })
    }

    const onCancel = () => {
        navigateBack(Routes.WORKOUTS)
    }

    const initialValues: WorkoutInput = {
        ...workout,
        workoutTypeUUID: workout.workoutType?.uuid ?? DEFAULT_UUID
    }

    return (
        <>
            <MetaTitle title={ workout.workoutType?.name ?? workout.title ?? "Workout" } />

            <Stack>
                <Group justify="space-between">
                    <PageTitleWithBack title={ workout.workoutType?.name ?? workout.title ?? "Workout" } />
                </Group>

                <ContentCard>
                    <UpdateWorkoutForm uuid={ workout.uuid }
                                       initialValues={ initialValues }
                                       scoreBoardsWithResult={ scoreBoardsWithResult }
                                       workoutTypes={ types }
                                       onSuccess={ onSuccess }
                                       onError={ onError }
                                       onCancel={ onCancel } />
                </ContentCard>
            </Stack>
        </>
    )
}
