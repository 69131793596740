import { UUID } from "@/common/Types";
import { EventState, RegistrationState } from "@/generated/graphql";
import { DateTime, Duration } from "luxon";


export interface Event {
    uuid: UUID
    availableSpots: number | null
    color: string | null
    end: DateTime
    isRegistrable: boolean
    name: string
    registrationStartPeriod: Duration | null
    registrationState?: RegistrationState
    start: DateTime
    state: EventState
    usedSpots?: number
}

export enum DateMode {
    ABSOLUTE = "Absolute",
    RELATIVE = "Relative"
}
