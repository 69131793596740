import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { toNullIfEmpty } from "@/common/String";
import { BenchmarkInput, BenchmarkResultOrder, BenchmarkResultType } from "@/generated/graphql";
import { Button, Group, NativeSelect, SimpleGrid, Stack, Textarea, TextInput } from "@mantine/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";


export interface BenchmarkFormData {
    name: string,
    description: string,
    resultType: BenchmarkResultType,
    resultOrder: BenchmarkResultOrder
}

interface BenchmarkFormProps<TSuccessData> {
    input: BenchmarkInput

    hasResults: boolean
    onSubmit: (input: BenchmarkInput) => Promise<FormSubmitResult<TSuccessData>>
    onCancel: () => void
}

export function BenchmarkForm<TSuccessData>(props: BenchmarkFormProps<TSuccessData>) {
    const { input, hasResults, onCancel, onSubmit } = props

    const { t } = useTranslation()

    const form = useMyPowerHourForm({
        initialValues: toFormData(input),
        transformValues: toInput
    });

    const resultOrders = useMemo(() => ([
        { value: BenchmarkResultOrder.Descending, label: t(`entities:BenchmarkResultOrder.${ BenchmarkResultOrder.Descending }`) },
        { value: BenchmarkResultOrder.Ascending, label: t(`entities:BenchmarkResultOrder.${ BenchmarkResultOrder.Ascending }`) },
    ]), [ t ])

    const resultTypes = useMemo(() => ([
        { value: BenchmarkResultType.Repetition, label: t(`entities:BenchmarkResultType.${ BenchmarkResultType.Repetition }`) },
        { value: BenchmarkResultType.Duration, label: t(`entities:BenchmarkResultType.${ BenchmarkResultType.Duration }`) },
        { value: BenchmarkResultType.Weight, label: t(`entities:BenchmarkResultType.${ BenchmarkResultType.Weight }`) },
    ]), [ t ])

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <TextInput label={ t("entities:Workout.Benchmark.Fields.Name") }
                           autoComplete="benchmark-name"
                           required
                           { ...form.getInputProps("name") } />

                <Textarea label={ t("entities:Workout.Benchmark.Fields.Description") }
                          autoComplete="off"
                          minRows={ 5 }
                          autosize
                          { ...form.getInputProps("description") } />


                <SimpleGrid cols={ { base: 1, sm: 2 } } style={ { alignItems: "flex-end" } }>
                    <NativeSelect label={ t("entities:Workout.Benchmark.Fields.ResultType") }
                                  description={ hasResults && t("components:Workouts.BenchmarkForm.Can't update result type") }
                                  data={ resultTypes }
                                  disabled={ hasResults }
                                  required
                                  { ...form.getInputProps(`resultType`) } />

                    <NativeSelect label={ t("entities:Workout.Benchmark.Fields.ResultOrder") }
                                  data={ resultOrders }
                                  required
                                  { ...form.getInputProps(`resultOrder`) } />
                </SimpleGrid>

                <Group justify="space-between">
                    <Button variant="default" onClick={ onCancel }>
                        { t("common:Button.Cancel") }
                    </Button>

                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(initialValues: BenchmarkInput): BenchmarkFormData {
    return {
        description: initialValues.description ?? "",
        name: initialValues.name,
        resultOrder: initialValues.resultOrder,
        resultType: initialValues.resultType
    }
}

function toInput(values: BenchmarkFormData): BenchmarkInput {
    return {
        description: toNullIfEmpty(values.description),
        name: values.name,
        resultOrder: values.resultOrder,
        resultType: values.resultType
    }
}
