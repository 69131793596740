import { OrganisationNavbar } from "@/layout/organisation/OrganisationNavbar";
import { Container } from "@mantine/core";
import { Outlet } from "react-router-dom";


export function OrganisationLayout() {
    return (
        <>
            <OrganisationNavbar />

            <Container mt="md">
                <Outlet />
            </Container>
        </>
    )
}
