import { ContentCard, PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { GraphQLClient } from "@/contexts/graphql";
import {
    MemberPermission,
    MembershipAgreementTemplatesDocument,
    MembershipAgreementTemplatesQuery,
    MembershipAgreementTemplatesQueryVariables,
    MembershipAgreementTemplateState,
    MembershipsPageAuthenticatedDataDocument,
    MembershipsPageAuthenticatedDataQuery,
    MembershipsPageAuthenticatedDataQueryVariables
} from "@/generated/graphql";
import { CreateMembershipTemplateButton } from "@/pages/memberships/_components/CreateMembershipTemplateButton";
import { MembershipCard } from "@/pages/memberships/_components/MembershipCard";
import { MembershipTemplateConfigurationButton } from "@/pages/memberships/_components/MembershipTemplateConfigurationButton";
import { Routes } from "@/routing/Routes";
import RoutingStorage from "@/routing/RoutingStorage";
import { ApolloQueryResult } from "@apollo/client";
import { Alert, Anchor, Group, SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData, useSearchParams } from "react-router-dom";


export const SP_ALLOWED_FOR_FOLLOWUP = "allowedForFollowup"

interface LoaderData {
    templates: ApolloQueryResult<MembershipAgreementTemplatesQuery>,
    authenticatedData: ApolloQueryResult<MembershipsPageAuthenticatedDataQuery> | null
}

export async function MembershipsPageLoader() {
    const templates = await GraphQLClient.query<MembershipAgreementTemplatesQuery, MembershipAgreementTemplatesQueryVariables>({
        query: MembershipAgreementTemplatesDocument,
    })

    const authenticatedData = RoutingStorage.isAuthenticated()
        ? await GraphQLClient.query<MembershipsPageAuthenticatedDataQuery, MembershipsPageAuthenticatedDataQueryVariables>({ query: MembershipsPageAuthenticatedDataDocument })
        : null

    return {
        templates: templates,
        authenticatedData: authenticatedData
    }
}

export function MembershipsPage() {
    const queryResult = useLoaderData() as LoaderData

    const { t } = useTranslation()
    const [ searchParams ] = useSearchParams()

    const existingAgreements = queryResult.authenticatedData?.data.myMembershipAgreements

    const templates = queryResult.templates.data.membershipAgreementTemplates
        .slice()
        .sort((a, b) => (a.sortPosition ?? 999) - (b.sortPosition ?? 999))
        .filter(it => (searchParams.has(SP_ALLOWED_FOR_FOLLOWUP) && it.isAllowedForFollowUp) || !searchParams.has(SP_ALLOWED_FOR_FOLLOWUP))

    const templatesByState = templates.reduce((acc: { [key in MembershipAgreementTemplateState]: typeof templates }, value) => {
            const list = [ ...acc[value.state], value ]
            return { ...acc, [value.state]: list }
        },
        {
            [MembershipAgreementTemplateState.Draft]: [],
            [MembershipAgreementTemplateState.Published]: [],
            [MembershipAgreementTemplateState.Deleted]: []
        })

    return (
        <>
            <MetaTitle title={ t("pages:MembershipsPage.Title") } />

            <Stack>
                <Group justify="space-between" align="start" wrap="nowrap">
                    <PageTitle title={ t("pages:MembershipsPage.Title") } />

                    <Group wrap="nowrap">
                        <CreateMembershipTemplateButton />
                        <MembershipTemplateConfigurationButton />
                    </Group>
                </Group>

                <Stack>
                    {
                        !searchParams.has(SP_ALLOWED_FOR_FOLLOWUP) && existingAgreements && existingAgreements.results.length > 0 && (
                            <Alert icon={ <IconInfoCircle /> }>
                                <Trans i18nKey="pages:MembershipsPage.You already have active agreements">
                                    You already have active agreements. Visit <Anchor component={ Link } to={ Routes.MY_MEMBERSHIPS } fw="500">My Area</Anchor>
                                </Trans>
                            </Alert>
                        )
                    }

                    {
                        searchParams.has(SP_ALLOWED_FOR_FOLLOWUP) && (
                            <Alert icon={ <IconInfoCircle /> }>
                                <Text>{ t("pages:MembershipsPage.Only Memberships allowed for followup are shown") }</Text>
                                <Anchor component={ Link } to={ Routes.MEMBERSHIPS }>{ t("pages:MembershipsPage.Show all Memberships") }</Anchor>
                            </Alert>
                        )
                    }

                    <MembershipAgreementTemplatePublishedSection templates={ templatesByState[MembershipAgreementTemplateState.Published] } />

                    <MembershipAgreementTemplateDraftSection templates={ templatesByState[MembershipAgreementTemplateState.Draft] } />
                </Stack>
            </Stack>
        </>
    )
}

type MembershipAgreementTemplates = MembershipAgreementTemplatesQuery["membershipAgreementTemplates"]

interface MembershipAgreementTemplatePublishedSectionProps {
    templates: MembershipAgreementTemplates
}

export function MembershipAgreementTemplatePublishedSection(props: MembershipAgreementTemplatePublishedSectionProps) {
    const { templates } = props

    const { t } = useTranslation()

    if (templates.length === 0) {
        return (
            <ContentCard>
                <Stack align="center">
                    <Text>{ t("pages:MembershipsPage.No Memberships") }</Text>
                </Stack>
            </ContentCard>
        )
    }

    return (
        <>
            <SimpleGrid cols={ { base: 1, sm: 2, lg: 3 } } spacing="lg">
                { templates.map(it => (<MembershipCard template={ it } key={ it.uuid } />)) }
            </SimpleGrid>
        </>
    )
}


interface MembershipAgreementTemplateDraftSectionProps {
    templates: MembershipAgreementTemplates
}

export function MembershipAgreementTemplateDraftSection(props: MembershipAgreementTemplateDraftSectionProps) {
    const { templates } = props

    const { t } = useTranslation()
    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.MembershipAgreementManager)) {
        return null
    }

    if (templates.length === 0) {
        return null
    }

    return (
        <>
            <Title order={ 4 }>{ t("pages:MembershipsPage.Drafts") }</Title>
            <SimpleGrid cols={ { base: 1, sm: 2, lg: 3 } } spacing="xl">
                { templates.map(it => (<MembershipCard template={ it } key={ it.uuid } />)) }
            </SimpleGrid>
        </>
    )
}

