import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { ConfirmationErrorReason } from "@/components/authentication";
import { useSession } from "@/contexts/session";
import { Alert, Button, Group, Stack, Text, TextInput } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


interface SignUpCodeFormProps {
    initialValues: SignUpCodeFormData
    onSuccess?: () => void
    onExpired?: () => void
}

interface SignUpCodeFormData {
    code: string
}

export function SignUpCodeForm(props: SignUpCodeFormProps) {
    const { initialValues, onSuccess, onExpired } = props

    const { t } = useTranslation()
    const { confirm } = useSession()

    const form = useMyPowerHourForm<SignUpCodeFormData>({
        name: "SignUpCodeForm",
        initialValues: initialValues,
    })

    const handleSubmit = async (data: SignUpCodeFormData) => {
        const response = await confirm({ code: data.code })

        if (response.isSuccess) {
            onSuccess?.()
            return
        }

        switch (response.errorReason) {
            case ConfirmationErrorReason.EXPIRED:
                showErrorNotification({ message: t("components:Account.SignUpForm.Code expired") })
                onExpired?.()
                break;
            case ConfirmationErrorReason.NOT_FOUND:
                showErrorNotification({ message: t("components:Account.SignUpForm.Code not found") })
                break;
            case ConfirmationErrorReason.FETCH_ERROR:
                showErrorNotification({ message: t("common:Error.Something went wrong") })
                break;
            default:
                return response.errorReason satisfies never;
        }
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                { initialValues.code === "" && (
                    <Alert icon={ <IconInfoCircle /> } color="blue">
                        <Text>
                            { t("components:Account.SignUpForm.You received an email") }
                        </Text>
                    </Alert>
                ) }

                <TextInput label={ t("components:Account.Common.Code") }
                           name="code"
                           type="number"
                           inputMode="numeric"
                           autoComplete="off"
                           autoFocus
                           required
                           { ...form.getInputProps("code") } />

                <Group justify="flex-end">
                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Verify") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
