import { Fact } from "@/components/shared/Fact";
import { BillingCycle } from "@/generated/graphql";
import { useTranslation } from "react-i18next";

interface BillingFactProps {
    amount: number
    cycle: BillingCycle
}

export function BillingFact(props: BillingFactProps) {
    const { t } = useTranslation()

    return (
        <Fact title={ t("components:Memberships.BillingFact.Costs") }>
            { BillingFactText(props) }
        </Fact>
    )
}

export function BillingFactText(props: BillingFactProps) {
    const { amount, cycle } = props

    const { t } = useTranslation()

    return t("components:Memberships.BillingFact.BillingPerCycle", { count: amount, billingCycle: cycle })
}
