import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { CreateBenchmarkResultForm } from "@/components/workouts/benchmark-result/CreateBenchmarkResultForm";
import { BenchmarkResultType } from "@/generated/graphql";
import { ActionIcon, Text } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";

const CREATE_RESULT_MODAL_ID = "create-benchmark-result"

interface CreateResultButtonProps {
    benchmark: {
        uuid: UUID
        resultType: BenchmarkResultType
    }
}

export function CreateResultButton(props: CreateResultButtonProps) {
    const { benchmark } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const onSuccess = () => {
        showSuccessNotification(t("common:Forms.Saved"))
        revalidator.revalidate()
        closeModal(CREATE_RESULT_MODAL_ID)
    }

    const onError = () => {
        showErrorNotification({ message: t("pages:BenchmarkPage.Failed to create Result") })
    }

    const onClick = () => {
        openModal({
            modalId: CREATE_RESULT_MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("pages:BenchmarkPage.Create Result") }</Text>,
            children: <CreateBenchmarkResultForm benchmarkUUID={ benchmark.uuid }
                                                 resultType={ benchmark.resultType }
                                                 onSuccess={ onSuccess }
                                                 onError={ onError }
                                                 onCancel={ () => closeModal(CREATE_RESULT_MODAL_ID) } />,
            closeOnClickOutside: false
        })
    }

    return (
        <ActionIcon color="brand" variant="filled" onClick={ onClick }>
            <IconPlus />
        </ActionIcon>
    )
}
