import { GraphQLClient } from "@/contexts/graphql";
import {
    ClassByIdPageMemberDocument,
    ClassByIdPageMemberQuery,
    ClassByIdPageMemberQueryVariables,
    ClassByIdPageVisitorDocument,
    ClassByIdPageVisitorQuery,
    ClassByIdPageVisitorQueryVariables,
    EventsForSwitchDocument,
    EventsForSwitchQuery,
    EventsForSwitchQueryVariables,
    WorkoutsDocument,
    WorkoutsQuery,
    WorkoutsQueryVariables
} from "@/generated/graphql";
import { ClassByIdPageMember } from "@/pages/classes/[uuid]/ClassByIdPageMember"
import { ClassByIdPageVisitor } from "@/pages/classes/[uuid]/ClassByIdPageVisitor"
import { Routes } from "@/routing/Routes"
import RoutingStorage from "@/routing/RoutingStorage";
import { ApolloQueryResult } from "@apollo/client";
import { generatePath, LoaderFunctionArgs, redirect, useLoaderData } from "react-router-dom";


interface ClassByIdPageMemberLoaderData {
    isMember: true,
    pageQuery: ClassByIdPageMemberQuery
    eventsForSwitchQuery: EventsForSwitchQuery | null
    workoutsQuery: WorkoutsQuery
}

interface ClassByIdPageVisitorLoaderData {
    isMember: false,
    pageQuery: ClassByIdPageVisitorQuery
}

type ClassByIdPageLoaderData = ClassByIdPageVisitorLoaderData | ClassByIdPageMemberLoaderData

export async function ClassByIdPageLoader(args: LoaderFunctionArgs): Promise<ClassByIdPageLoaderData | Response> {
    if (RoutingStorage.isMember()) {
        return ClassByIdPageMemberLoader(args)
    } else {
        return ClassByIdPageVisitorLoader(args)
    }
}

async function ClassByIdPageMemberLoader({ params }: LoaderFunctionArgs): Promise<ClassByIdPageMemberLoaderData | Response> {
    const event = await GraphQLClient.query<ClassByIdPageMemberQuery, ClassByIdPageMemberQueryVariables>({
        query: ClassByIdPageMemberDocument,
        variables: { eventId: params.uuid! },
    })

    if (params.uuid! !== event.data.event.uuid) {
        return redirect(generatePath(Routes.CLASSES_BY_ID, { uuid: event.data.event.uuid }))
    }

    const workouts = GraphQLClient.query<WorkoutsQuery, WorkoutsQueryVariables>({
        query: WorkoutsDocument,
        variables: { filter: { date: event.data.event.start, workoutTypeUUID: null }, pagination: { limit: 10, offset: 0 } }
    })

    let eventsForSwitch: Promise<ApolloQueryResult<EventsForSwitchQuery> | null> = Promise.resolve(null)
    const myRegistration = event.data.myEventRegistrations.results.firstOrNull();
    if (myRegistration) {
        eventsForSwitch = GraphQLClient.query<EventsForSwitchQuery, EventsForSwitchQueryVariables>({
            query: EventsForSwitchDocument,
            variables: { registrationUUID: myRegistration.uuid },
        })
    }

    return {
        isMember: true,
        pageQuery: event.data,
        eventsForSwitchQuery: (await eventsForSwitch)?.data ?? null,
        workoutsQuery: (await workouts).data
    }
}

async function ClassByIdPageVisitorLoader({ params }: LoaderFunctionArgs): Promise<ClassByIdPageVisitorLoaderData | Response> {
    const event = await GraphQLClient.query<ClassByIdPageVisitorQuery, ClassByIdPageVisitorQueryVariables>({
        query: ClassByIdPageVisitorDocument,
        variables: { eventId: params.uuid! },
    })

    if (params.uuid! !== event.data.event.uuid) {
        return redirect(generatePath(Routes.CLASSES_BY_ID, { uuid: event.data.event.uuid }))
    }

    return {
        isMember: false,
        pageQuery: event.data,
    }
}

export function ClassByIdPage() {
    const queryResult = useLoaderData() as ClassByIdPageLoaderData

    if (queryResult.isMember) {
        return <ClassByIdPageMember { ...queryResult } />
    } else {
        return <ClassByIdPageVisitor { ...queryResult } />
    }
}
