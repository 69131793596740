import { toDuration } from "@/common/Luxon";
import { SignInOrSignUpCard } from "@/components/account";
import { PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { ClassByIdPageVisitorQuery, EventState } from "@/generated/graphql";
import { DetailsCard, DetailsCardProps } from "@/pages/classes/[uuid]/_components/detailsCard/DetailsCard";
import { Routes } from "@/routing/Routes"
import { Alert, Button, Stack, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRevalidator } from "react-router-dom";


interface ClassByIdPageVisitorProps {
    pageQuery: ClassByIdPageVisitorQuery
}

export function ClassByIdPageVisitor(props: ClassByIdPageVisitorProps) {
    const { pageQuery } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()
    const { isAuthenticated, isMember } = useAuthentication()

    const eventData = pageQuery.event

    const event: DetailsCardProps["event"] = {
        eventUUID: eventData.uuid,
        eventDetailsUUID: eventData.eventDetails.uuid,

        end: DateTime.fromISO(eventData.end),
        description: eventData.eventDetails.description,
        name: eventData.eventDetails.name,
        start: DateTime.fromISO(eventData.start),
        state: eventData.state,

        feeAmount: eventData.eventDetails.registrationSettings.feeAmount,

        availableSpots: eventData.eventDetails.registrationSettings.availableSpots,

        minimumNumberOfRegistrations: eventData.eventDetails.registrationSettings.minimumNumberOfRegistrations,
        minimumNumberOfRegistrationsPeriod: toDuration(eventData.eventDetails.registrationSettings.minimumNumberOfRegistrationsPeriod),

        lateCancellationPeriod: toDuration(eventData.eventDetails.registrationSettings.lateCancellationPeriod),

        registrationStartPeriod: toDuration(eventData.eventDetails.registrationSettings.registrationStartPeriod)
    }

    /**
     * TODO find solution for AuthenticationContext too late
     * revalidation happens after signIn already
     */
    useEffect(() => {
        if (isMember) {
            revalidator.revalidate()
        }
    }, [ isMember ])

    return (
        <>
            <MetaTitle title={ event.name } />

            <Stack>
                <PageTitleWithBack title={ event.name } />

                <DetailsCard event={ event } enableCollapse={ false } />

                { !isAuthenticated && [ EventState.Pending, EventState.Confirmed, EventState.Started ].includes(event.state) && <SignInOrSignUpCard signInFirst /> }

                { isAuthenticated && [ EventState.Pending, EventState.Confirmed, EventState.Started ].includes(event.state) && (
                    <Alert color="yellow">
                        <Stack align="center">
                            <Text ta="center">{ t("pages:ClassByIdPage.You need a active membership") }</Text>

                            <Button component={ Link } to={ Routes.MEMBERSHIPS }>
                                { t("pages:ClassByIdPage.All Memberships") }
                            </Button>
                        </Stack>
                    </Alert>
                ) }
            </Stack>
        </>
    )
}
