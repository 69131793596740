import W from "@/common/Workout"
import { WeightCalculator } from "@/components/workouts/weight-calculator/WeightCalculator"
import { BenchmarkResultType, useMyBenchmarksQuery } from "@/generated/graphql"
import { Box, LoadingOverlay, NativeSelect, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export function WeightCalculatorWithBenchmarkSelector() {
    const { t } = useTranslation()

    const [ value, setValue ] = useState("");
    const [ initialValue, setInitialValue ] = useState(50);

    const { data, loading } = useMyBenchmarksQuery({
        fetchPolicy: "cache-and-network",
        variables: {
            filter: { resultType: BenchmarkResultType.Weight },
            pagination: { limit: 999, offset: 0 }
        }
    })

    useEffect(() => {
        const benchmark = data?.myBenchmarks.results.find(it => it.uuid === value)
        if (benchmark) {
            const max = Math.max(...benchmark.results.map(it => parseFloat(W.decodeScore(benchmark.resultType, it.score))))
            setInitialValue(max)
        }
    }, [ value ])

    const benchmarkItems = data?.myBenchmarks.results.filter(it => it.results.length > 0).map(it => ({ value: it.uuid, label: it.name })) ?? [];
    const selectLabel = benchmarkItems.length > 0 ? t("components:Workouts.WeightCalculator.Select") : t("components:Workouts.WeightCalculator.No weight benchmarks available")
    const items = [ { value: "", label: selectLabel }, ...benchmarkItems ]

    return (
        <Stack>
            <Box pos="relative">
                <LoadingOverlay visible={ !data && loading } />
                <NativeSelect label={ t("components:Workouts.WeightCalculator.My benchmarks") }
                              data={ items }
                              value={ value }
                              onChange={ (event) => setValue(event.currentTarget.value) }
                              disabled={ items.length < 2 } />
            </Box>

            <WeightCalculator key={ initialValue } initialValue={ initialValue } />
        </Stack>
    )
}
