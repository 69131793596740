import { useRootLink } from "@/components/hooks/UseRootLink";
import { NavigationItem } from "@/components/navigation";
import { Cloudinary } from "@/configuration/Cloudinary";
import { useOrganisation } from "@/contexts/organisation";
import { NavbarItem } from "@/layout/shared/NavbarItem";
import { UserSection } from "@/layout/shared/UserSection";
import { Routes } from "@/routing/Routes";
import { NamedTransformationAction } from "@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction";
import { Avatar, Box, Group, rem, Stack, Title, UnstyledButton } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import cx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import classes from "./NavbarStyles.module.scss"

interface DesktopNavbarProps {
    items?: NavigationItem[],
    itemNodes?: React.ReactNode,
    showBack?: boolean
}

export function DesktopNavbar(props: DesktopNavbarProps) {
    const { items, itemNodes, showBack } = props

    const { organisation } = useOrganisation()
    const root = useRootLink()

    let src = null
    if (organisation.logoSquare) {
        src = Cloudinary.image(organisation.logoSquare.publicId)
            .setVersion(organisation.logoSquare.version)
            .namedTransformation(new NamedTransformationAction("navbar-logo"))
            .toURL()
    }

    return (
        <nav className={ classes.desktopRoot }>
            <div className={ cx(classes.section, classes.upperSection) }>
                <Box style={ { overflow: "hidden" } } pb={ 6 }>
                    <UnstyledButton component={ Link } to={ root }>
                        <Group wrap="nowrap">
                            <Avatar size={ rem(64) } color="brand" variant="filled" src={ src } imageProps={ { title: organisation.name } } />
                            <Title order={ 1 } size="1.4rem" fw={ 600 }>
                                { organisation.name }
                            </Title>
                        </Group>
                    </UnstyledButton>
                </Box>
            </div>

            <div className={ cx(classes.section, classes.grow) }>
                <Stack gap="xs">
                    { itemNodes }
                    { items?.map(it => (<NavbarItem key={ it.labelKey } item={ it } />)) }
                </Stack>
            </div>

            { showBack && (
                <div className={ cx(classes.section, classes.lowerSection) }>
                    <Stack gap="xs">
                        <NavbarItem item={ { to: Routes.ROOT, labelKey: "common:Navigation.Back", icon: IconArrowLeft } } />
                    </Stack>
                </div>
            ) }

            <div className={ cx(classes.section, classes.lowerSection) }>
                <Stack gap="xs">
                    <UserSection />
                </Stack>
            </div>
        </nav>
    );
}
