import { useDeviceSize } from "@/contexts/device-size";
import { Card, CardProps } from "@mantine/core";


export function ContentCard(props: CardProps) {
    const { children, ...other } = props

    const { p } = useDeviceSize();

    return (
        <Card shadow="sm" padding={ p } { ...other }>
            { children }
        </Card>
    )
}
