import Logger from "@/common/Logger";

function parseIntOrDefault(dflt: number, value?: any | null): number {
    if (value === undefined || value === null) {
        return dflt
    }

    try {
        return parseInt(value)
    } catch (e) {
        Logger.warn(`Failed to parse int ${ value }`)
        return dflt
    }
}

const Parse = {
    intOrDefault: parseIntOrDefault
}

export default Parse
