import Logger from "@/common/Logger";
import { useAuthentication } from "@/contexts/authentication";
import { useDeviceSize } from "@/contexts/device-size";
import { MyNavbar } from "@/layout/my/MyNavbar";
import { Routes } from "@/routing/Routes";
import { Avatar, Box, Container, Group, Paper, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";
import classes from "./MyLayout.module.scss"


export function MyLayout() {
    const { t } = useTranslation()

    const { isMobile } = useDeviceSize();
    const { me } = useAuthentication()

    if (me === null) {
        Logger.error("Tried to render MyLayout without user")
        return <Navigate to={ Routes.ROOT } replace={ true } />
    }

    return (
        <>
            <MyNavbar />

            <Box>
                <Paper radius={ 0 } className={ classes.greeting }>
                    <Container>
                        <Group wrap="nowrap" py="md" align="center">
                            <Avatar size={ isMobile ? 36 : 72 } color="brand" variant="filled">{ me.username.slice(0, 2) }</Avatar>
                            <Box style={ { overflow: "hidden" } }>
                                <Title fw={ 700 } size={ isMobile ? "h4" : "h1" } style={ { overflow: "hidden", textOverflow: "ellipsis" } }>
                                    { t("common:MyLayout.Welcome", { name: me.username }) }
                                </Title>
                            </Box>
                        </Group>
                    </Container>
                </Paper>

                <Container mt="md">
                    <Outlet />
                </Container>
            </Box>
        </>
    )
}
