import { useMatch } from "react-router-dom";
import { useRegisterSW } from "virtual:pwa-register/react";
import Logger from "../common/Logger"
import { Routes } from "./Routes"

export function PwaHandler() {
    const sw = useRegisterSW({
        onRegisteredSW(swUrl, r) {
            if (!r) {
                return
            }

            const runUpdate = async (errorMsg: string) => {
                if (("connection" in navigator) && !navigator.onLine) {
                    return
                }

                await r.update().catch(e => Logger.info(errorMsg, e))
            }

            // check for update on load
            void runUpdate("Service worker update failed on load")

            // check for update when tab is opened
            document.addEventListener("visibilitychange", () => {
                if (document.visibilityState == "visible") {
                    void runUpdate("Service worker update failed on visibility change")
                }
            });
        },
        onRegisterError(error) {
            Logger.error("Service worker registration error", error)
        },
        onNeedRefresh: () => {
            Logger.debug("Detected update of service worker")
        },
    })

    // update service worker only on /info or /classes page
    const matchClasses = useMatch({ path: Routes.CLASSES, end: true });
    const matchSignIn = useMatch({ path: Routes.SIGN_IN, end: true });
    const matchInfo = useMatch({ path: Routes.INFO, end: true });

    const [ needRefresh ] = sw.needRefresh
    if ((matchInfo || matchClasses || matchSignIn) && needRefresh) {
        Logger.debug("Updating service worker")
        void sw.updateServiceWorker(true).catch(e => Logger.warn("Failed to update service worker", e))
    }

    return null
}
