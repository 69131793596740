import Logger from "@/common/Logger";
import { showErrorNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useRejectMembershipRequestMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Button, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";


interface RejectRequestButtonProps {
    membershipRequest: {
        uuid: UUID
    }
    onSuccess: () => void
}

/**
 * Shown to Member
 */
export function RejectRequestButton(props: RejectRequestButtonProps) {
    const { onSuccess, membershipRequest } = props

    const { t } = useTranslation()

    const [ rejectRequest ] = useRejectMembershipRequestMutation()

    const onRejectRequest = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Memberships.RejectMembershipRequestButton.Reject request") }</Text>,
            children: t("components:Memberships.RejectMembershipRequestButton.Do you want to reject this request?"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Reject") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => rejectRequest({ variables: { uuid: membershipRequest.uuid } }),
                    onSuccess: onSuccess,
                    onError: (error) => {
                        Logger.error("Failed to reject MembershipRequest", error)
                        showErrorNotification({ error })
                    }
                })
            }
        })
    }

    return (
        <Button color="red" onClick={ onRejectRequest }>
            { t("common:Button.Reject") }
        </Button>
    )
}
