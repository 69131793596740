import { Fact } from "@/components/shared/Fact";
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";


interface CancellationFactProps {
    start: DateTime
    lateCancellationPeriod: Duration | null
}

export function CancellationFact(props: CancellationFactProps) {
    const { start, lateCancellationPeriod } = props

    const { t } = useTranslation()

    if (!lateCancellationPeriod) {
        return null
    }

    const date = start.minus(lateCancellationPeriod)

    return (
        <Fact title={ t("pages:ClassesByIdPage.Late cancellation") }>
            { t("pages:ClassesByIdPage.You can cancel your registration until DATE", { date: date.toFormat("EEEE dd.MM. HH:mm") }) }
        </Fact>
    )
}
