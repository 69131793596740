import "@mantine/core/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import "./global.scss"
import { InitializationBoundary } from "@/components/initialization-boundary/InitializationBoundary";
import { AuthenticationContextProvider } from "@/contexts/authentication";
import { DeviceSizeContextProvider } from "@/contexts/device-size";
import { InAppNotificationsContextProvider } from "@/contexts/in-app-notification";
import { OrganisationContextProvider } from "@/contexts/organisation";
import { PWAContextProvider } from "@/contexts/pwa";
import { SessionContextProvider } from "@/contexts/session";
import { Theme } from "@/layout/Mantine";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import React from "react";
import { HeadProvider } from "react-head";


interface AppProps {
    client: ApolloClient<unknown>
}

export default function App(props: AppProps) {
    const { client } = props

    return (
        <MantineProvider defaultColorScheme="light" theme={ Theme }>
            <Notifications position="top-center" autoClose={ 5000 } />
            <DeviceSizeContextProvider>
                <PWAContextProvider>
                    <HeadProvider>
                        <ApolloProvider client={ client }>
                            <SessionContextProvider>
                                <OrganisationContextProvider>
                                    <AuthenticationContextProvider>
                                        <InAppNotificationsContextProvider>

                                            <InitializationBoundary />

                                        </InAppNotificationsContextProvider>
                                    </AuthenticationContextProvider>
                                </OrganisationContextProvider>
                            </SessionContextProvider>
                        </ApolloProvider>
                    </HeadProvider>
                </PWAContextProvider>
            </DeviceSizeContextProvider>
        </MantineProvider>
    );
}
