import { ContentCard } from "@/components/shared/ContentCard"
import { Card, CardSectionProps, Center, Divider, Group, Stack, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { HistoryEntry } from "./types"


interface HistoryEntriesByDay {
    day: DateTime
    entries: HistoryEntry[]
}

interface HistoryCardProps {
    entries: readonly HistoryEntry[]
    mah?: number
}

export function HistoryCard(props: HistoryCardProps) {
    const { entries, mah } = props

    const { t } = useTranslation()

    let overflowProps: CardSectionProps = {}
    if (mah) {
        overflowProps = {
            mah: mah,
            style: { overflow: "auto" }
        }
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Shared.History.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="sm" { ...overflowProps }>
                <History entries={ entries } />
            </Card.Section>
        </ContentCard>
    )
}

interface HistoryProps {
    entries: readonly HistoryEntry[]
}

function History(props: HistoryProps) {
    const { entries } = props

    const { t } = useTranslation()

    if (entries.length === 0) {
        return (
            <Center>
                <Text>{ t("common:Misc.No entries available") }</Text>
            </Center>
        )
    }

    const entriesByDay = entries.reduce(
        (acc: HistoryEntriesByDay[], value) => {
            const day = value.date.startOf("day")

            const existing = acc.filter(it => it.day.toString() === day.toString())
            if (existing.length === 0) {
                acc.push({ day: day, entries: [] })
            }

            acc.filter(it => it.day.toString() === day.toString())[0]
                .entries
                .push(value)
            return acc
        },
        []
    )

    return (
        <Stack gap={ 0 }>
            {
                entriesByDay.map(group => (
                    <Stack key={ group.day.toString() } gap={ 2 } pb="xs">
                        <Divider label={ group.day.toFormat("DDDD") } labelPosition="left" fw={ 600 } />
                        {
                            group.entries.map(line => (
                                <Group key={ line.uuid } align="start" wrap="nowrap">
                                    <Text size="sm" style={ { fontVariantNumeric: "tabular-nums" } }>{ line.date.toFormat("TT") }</Text>
                                    <Text size="sm">{ line.item }</Text>
                                </Group>
                            ))
                        }
                    </Stack>
                ))
            }
        </Stack>
    )
}
