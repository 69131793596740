import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ActionIcon } from "@mantine/core";
import { IconList } from "@tabler/icons-react";
import { Link } from "react-router-dom";


export function WorkoutListButton() {
    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.WorkoutManager)) {
        return null
    }

    return (
        <ActionIcon component={ Link } to={ Routes.WORKOUTS_LIST } variant="default" size="lg">
            <IconList />
        </ActionIcon>
    )
}
