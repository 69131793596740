import { DEFAULT_UUID } from "@/common/Object"
import { byComboboxItems } from "@/common/Sort"
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle"
import { useDeviceSize } from "@/contexts/device-size";
import { GraphQLClient } from "@/contexts/graphql";
import {
    useWorkoutListPageLazyQuery,
    WorkoutListPageDocument,
    WorkoutListPageQuery,
    WorkoutListPageQueryVariables,
    WorkoutListPageWorkoutFragment,
    WorkoutTypesDocument,
    WorkoutTypesQuery,
    WorkoutTypesQueryVariables
} from "@/generated/graphql"
import { ActionIcon, Affix, Card, Center, Divider, Group, Loader, NativeSelect, rem, Stack, Text, Transition } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { IconArrowUp, IconEyeX } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { LoaderFunctionArgs, useLoaderData, useSearchParams } from "react-router-dom";


const LIMIT = 10

interface WorkoutListPageData {
    unpublishedWorkouts: WorkoutListPageQuery["unpublishedWorkouts"]
    workouts: WorkoutListPageQuery["workouts"]
    workoutTypes: WorkoutTypesQuery["workoutTypes"]
}

const SP_WORKOUT_TYPE = "workout-type"

export async function WorkoutListPageLoader({ request }: LoaderFunctionArgs): Promise<WorkoutListPageData> {
    const url = new URL(request.url);
    const workoutTypeUUID = url.searchParams.get(SP_WORKOUT_TYPE)

    const types = await GraphQLClient.query<WorkoutTypesQuery, WorkoutTypesQueryVariables>({
        query: WorkoutTypesDocument,
    })

    const workouts = await GraphQLClient.query<WorkoutListPageQuery, WorkoutListPageQueryVariables>({
        query: WorkoutListPageDocument,
        variables: { filter: { date: null, workoutTypeUUID: workoutTypeUUID }, pagination: { limit: LIMIT, offset: 0 } }
    })

    return {
        unpublishedWorkouts: workouts.data.unpublishedWorkouts,
        workouts: workouts.data.workouts,
        workoutTypes: types.data.workoutTypes
    }
}

export function WorkoutListPage() {
    const pageData = useLoaderData() as WorkoutListPageData

    const { t } = useTranslation()
    const [ scroll, scrollTo ] = useWindowScroll();
    const [ searchParams, setSearchParams ] = useSearchParams()

    const { isMobile } = useDeviceSize()

    const [ isLoading, setIsLoading ] = useState(false)
    const [ page, setPage ] = useState(0)
    const [ total, setTotal ] = useState(pageData.workouts.totalCount)
    const [ loadedWorkouts, setLoadedWorkouts ] = useState<WorkoutListPageWorkoutFragment[]>(pageData.workouts.results.slice())
    const [ unpublishedWorkouts ] = useState<WorkoutListPageWorkoutFragment[]>(pageData.unpublishedWorkouts.results.slice())

    const [ loadWorkouts ] = useWorkoutListPageLazyQuery()

    const workoutType = searchParams.get(SP_WORKOUT_TYPE) ?? DEFAULT_UUID
    const hasNextPage = loadedWorkouts.length < total
    const types = [
        { value: DEFAULT_UUID, label: t(`pages:WorkoutListPage.All`) },
        ...(pageData.workoutTypes.map(it => ({ value: it.uuid, label: it.name })).sort(byComboboxItems))
    ]

    useEffect(() => {
        setPage(0)
        setLoadedWorkouts(pageData.workouts.results.slice())
        setTotal(pageData.workouts.totalCount)
    }, [ pageData.workouts ]);

    const loadPage = async (page: number) => {
        const workoutTypeFilter = workoutType === DEFAULT_UUID ? null : workoutType

        setIsLoading(true)
        const response = await loadWorkouts({
            fetchPolicy: "cache-and-network",
            variables: { filter: { date: null, workoutTypeUUID: workoutTypeFilter }, pagination: { limit: LIMIT, offset: LIMIT * page } },
        })
        setIsLoading(false)

        setLoadedWorkouts(w => w.concat(response.data!.workouts.results.slice()))
        setPage(page)
        setTotal(response.data!.workouts.totalCount)
    }

    const onWorkoutTypeChanged = (ev: ChangeEvent<HTMLSelectElement>) => {
        const workoutTypeUUID = ev.target.value

        if (workoutTypeUUID === DEFAULT_UUID) {
            searchParams.delete(SP_WORKOUT_TYPE)
        } else {
            searchParams.set(SP_WORKOUT_TYPE, workoutTypeUUID)
        }

        setSearchParams(searchParams, { replace: true })
    }

    // https://www.npmjs.com/package/react-infinite-scroll-hook
    const [ sentryRef ] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: hasNextPage,
        onLoadMore: () => {
            void loadPage(page + 1)
        },
        rootMargin: "0px 0px 400px 0px",
    })

    return (
        <>
            <MetaTitle title={ t("pages:WorkoutListPage.Title") } />

            <Stack>
                <Group justify="space-between" align="start">
                    <PageTitleWithBack title={ t("pages:WorkoutListPage.Title") } />
                </Group>

                <NativeSelect label={ t("entities:Workout.Workout.Fields.Type") }
                              data={ types }
                              value={ workoutType }
                              onChange={ onWorkoutTypeChanged } />

                <Divider />

                {
                    unpublishedWorkouts.map(workout => {
                        const title = workout.workoutType?.name ?? workout.title ?? "Workout"
                        const date = DateTime.fromISO(workout.date).toFormat("EEE dd.MM")

                        return (
                            <ContentCard key={ workout.uuid }>
                                <Card.Section withBorder inheritPadding py="sm">
                                    <Group>
                                        <IconEyeX aria-label={ t("pages:WorkoutListPage.Unpublished") } />
                                        <Stack gap={ 0 }>
                                            <Text fw={ 600 } size="lg">{ date } - { title }</Text>
                                            <Text c="dimmed" size="xs">
                                                { t("pages:WorkoutListPage.Published at", { date: DateTime.fromISO(workout.publishingDate).toFormat("EEE dd.MM HH:mm") }) }
                                            </Text>
                                        </Stack>
                                    </Group>

                                </Card.Section>

                                <Card.Section inheritPadding py="md">
                                    <Text>
                                        { workout.description }
                                    </Text>
                                </Card.Section>
                            </ContentCard>
                        )
                    })
                }

                {
                    loadedWorkouts.map(workout => {
                        const title = workout.workoutType?.name ?? workout.title ?? "Workout"
                        const date = DateTime.fromISO(workout.date).toFormat("EEE dd.MM")

                        return (
                            <ContentCard key={ workout.uuid }>
                                <Card.Section withBorder inheritPadding py="sm">
                                    <Text fw={ 600 } size="lg">{ date } - { title }</Text>
                                </Card.Section>

                                <Card.Section inheritPadding py="md">
                                    <Text>
                                        { workout.description }
                                    </Text>
                                </Card.Section>
                            </ContentCard>
                        )
                    })
                }

                {
                    hasNextPage && (
                        <Center ref={ sentryRef }>
                            <Loader />
                        </Center>
                    )
                }

                {
                    total === 0 && (
                        <Center>{ t("pages:WorkoutListPage.No workouts found") }</Center>
                    )
                }

                {
                    !hasNextPage && total > 0 && (
                        <Divider label={ t("pages:WorkoutListPage.The end") } labelPosition="center" />
                    )
                }
            </Stack>

            { isMobile && (
                <Affix position={ { bottom: rem(68), right: rem(12) } }>
                    <Transition transition="slide-up" mounted={ scroll.y > 0 }>
                        { (transitionStyles) => (
                            <ActionIcon variant="default" style={ transitionStyles } onClick={ () => scrollTo({ y: 0 }) }>
                                <IconArrowUp size="1rem" />
                            </ActionIcon>
                        ) }
                    </Transition>
                </Affix>
            ) }
        </>
    )
}
