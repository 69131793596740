import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { DEFAULT_UUID } from "@/common/Object";
import { UUID } from "@/common/Types";
import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { CreateWorkoutForm } from "@/components/workouts/workout";
import { GraphQLClient } from "@/contexts/graphql";
import { CopyWorkoutPageDocument, CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables, WorkoutInput } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, LoaderFunctionArgs, useLoaderData } from "react-router-dom";


export function CopyWorkoutPageLoader({ params }: LoaderFunctionArgs) {
    return GraphQLClient.query<CopyWorkoutPageQuery, CopyWorkoutPageQueryVariables>({
        query: CopyWorkoutPageDocument,
        variables: { uuid: params.uuid! },
    })
}

export function CopyWorkoutPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<CopyWorkoutPageQuery>

    const { t } = useTranslation()
    const { navigate, navigateBack } = useMyPowerHourNavigate()

    const workout = queryResult.data.workout
    const types = queryResult.data.workoutTypes

    const onSuccess = (uuid: UUID) => {
        showSuccessNotification(t("common:Forms.Saved"))
        navigate(generatePath(Routes.WORKOUTS_BY_ID, { uuid: uuid }), { replace: true })
    }

    const onError = (error: any) => {
        showErrorNotification({ error })
    }

    const onCancel = () => {
        navigateBack(Routes.WORKOUTS)
    }

    const initialValues: WorkoutInput = {
        ...workout,
        workoutTypeUUID: workout.workoutType?.uuid ?? DEFAULT_UUID,
    }

    return (
        <>
            <MetaTitle title={ t("pages:CopyWorkoutPage.Title") } />

            <Stack>
                <PageTitleWithBack title={ t("pages:CopyWorkoutPage.Title") } />

                <ContentCard>
                    <CreateWorkoutForm initialValues={ initialValues }
                                       workoutTypes={ types }
                                       onSuccess={ onSuccess }
                                       onError={ onError }
                                       onCancel={ onCancel } />
                </ContentCard>
            </Stack>
        </>
    )
}
