import Logger from "@/common/Logger";
import { FormSubmitResult } from "@/common/MyPowerHourForm";
import { UUID } from "@/common/Types";
import { DeleteEventForm, DeleteEventFormData, DeleteMode } from "@/components/events/deleteEventForm/DeleteEventForm";
import { useDeleteEventAndEndRecurrenceMutation, useDeleteEventMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";

interface DeleteEventFormContainerProps {
    eventUUID: UUID
    onSuccess: () => void
    onError: (error: any) => void
    onCancel: () => void
}

export function DeleteEventFormContainer(props: DeleteEventFormContainerProps) {
    const { eventUUID, onSuccess, onCancel, onError } = props

    const [ deleteEvent ] = useDeleteEventMutation()
    const [ deleteEventAndEndRecurrence ] = useDeleteEventAndEndRecurrenceMutation()

    const onDeleteEvent = async (): Promise<FormSubmitResult<any>> => {
        return await executeMutation({
            mutation: () => deleteEvent({
                variables: { eventId: eventUUID }
            }),
            onSuccess: onSuccess,
            onError: (error) => {
                Logger.error("Failed to delete Event", error)
                onError(error)
            }
        })
    }

    const onDeleteEventAndEndRecurrence = async (): Promise<FormSubmitResult<any>> => {
        return await executeMutation({
            mutation: () => deleteEventAndEndRecurrence({
                variables: { eventId: eventUUID }
            }),
            onSuccess: onSuccess,
            onError: (error) => {
                Logger.error("Failed to delete Event and end recurrence", error)
                onError(error)
            }
        })
    }

    const onSubmit = async (formData: DeleteEventFormData): Promise<FormSubmitResult<any>> => {
        switch (formData.deleteMode) {
            case DeleteMode.EVENT:
                return await onDeleteEvent()
            case DeleteMode.EVENT_AND_END_RECURRENCE:
                return await onDeleteEventAndEndRecurrence()
        }
    }

    return <DeleteEventForm onSubmit={ onSubmit }
                            onCancel={ onCancel } />

}
