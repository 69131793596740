import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UpdateEmailAddressCard, UpdatePasswordCard } from "@/components/account";
import { UpdatePersonalInformationForm } from "@/components/core/person";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { GraphQLClient } from "@/contexts/graphql";
import { MyAccountPageDocument, MyAccountPageQuery, MyAccountPageQueryVariables } from "@/generated/graphql"
import { ApolloQueryResult } from "@apollo/client";
import { Card, SimpleGrid, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";


interface MyAccountPageData {
    myAccountPageData: ApolloQueryResult<MyAccountPageQuery>
}

export async function MyAccountPageLoader(): Promise<MyAccountPageData> {
    const myAccountPageData = GraphQLClient.query<MyAccountPageQuery, MyAccountPageQueryVariables>({
        query: MyAccountPageDocument,
    })

    return {
        myAccountPageData: await myAccountPageData
    }
}

export function MyAccountPage() {
    const queryResult = useLoaderData() as MyAccountPageData

    const { t } = useTranslation()
    const { me, refresh } = useAuthentication()

    const person = queryResult.myAccountPageData.data.me
    const requiredPersonalInformation = queryResult.myAccountPageData.data.requiredPersonalInformation

    const onUpdatePersonSuccess = () => {
        void refresh()
        showSuccessNotification(t("pages:MyAccountPage.Person updated"), 5000)
    }

    const onUpdatePersonFailed = () => {
        showErrorNotification({ message: t("pages:MyAccountPage.Update of person failed") })
    }

    return (
        <>
            <MetaTitle title={ t("pages:MyAccountPage.Title") } />

            <Stack>
                <PageTitleWithBack title={ t("pages:MyAccountPage.Title") } />

                <ContentCard>
                    <Card.Section withBorder inheritPadding py="sm">
                        <Text fw={ 600 } size="lg">{ t("pages:MyAccountPage.Personal Information") }</Text>
                    </Card.Section>

                    <Card.Section inheritPadding py="sm">
                        <UpdatePersonalInformationForm initialValues={ person }
                                                       requiredPersonalInformation={ requiredPersonalInformation }
                                                       onSuccess={ onUpdatePersonSuccess }
                                                       onError={ onUpdatePersonFailed } />
                    </Card.Section>
                </ContentCard>


                <SimpleGrid cols={ { base: 1, md: 2 } }>
                    <UpdateEmailAddressCard currentEmailAddress={ me?.emailAddress ?? "" } />
                    <UpdatePasswordCard />
                </SimpleGrid>
            </Stack>
        </>
    )
}
