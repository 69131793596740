import { UUID } from "@/common/Types";
import { ContentCard } from "@/components/shared";
import { MembershipRequestState } from "@/generated/graphql";
import { Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { AcceptRequestButton } from "./AcceptRequestButton";
import { RejectRequestButton } from "./RejectRequestButton";


interface MemberAcceptanceCardProps {
    membershipRequest: {
        uuid: UUID
        state: MembershipRequestState
    }
    onAcceptSuccess: (isActivated: boolean, membershipUUID?: UUID) => void
    onRejectSuccess: () => void
}

export function MemberAcceptanceCard(props: MemberAcceptanceCardProps) {
    const { membershipRequest, onAcceptSuccess, onRejectSuccess } = props

    const { t } = useTranslation()

    if (membershipRequest.state !== MembershipRequestState.MemberAcceptancePending) {
        return null
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Memberships.MemberAcceptanceCard.Acceptance needed") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <Stack>
                    <Text>{ t("components:Memberships.MemberAcceptanceCard.This membership was modified") }</Text>
                    <Group justify="space-between">
                        <RejectRequestButton membershipRequest={ membershipRequest } onSuccess={ onRejectSuccess } />
                        <AcceptRequestButton membershipRequest={ membershipRequest } onSuccess={ onAcceptSuccess } />
                    </Group>
                </Stack>
            </Card.Section>
        </ContentCard>
    )
}
