import React, { PropsWithChildren, useContext, useEffect, useState } from "react";


interface BeforeInstallPromptEvent extends Event {
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>
    prompt: () => Promise<void>
}

interface PWAContextType {
    isInstallable: boolean
    triggerInstall: () => Promise<void>
}

const PWAContextDefaultValues: PWAContextType = {
    isInstallable: false,
    triggerInstall: () => Promise.resolve()
}

const PWAContext = React.createContext<PWAContextType>(PWAContextDefaultValues)

export function PWAContextProvider(props: PropsWithChildren) {
    const { children } = props

    const [ event, setEvent ] = useState<BeforeInstallPromptEvent | null>(null);

    const onTriggerInstall = async () => {
        if (event) {
            await event.prompt()
        }
    }

    useEffect(() => {
        const onBeforeInstall = (e: Event) => {
            setEvent(e as BeforeInstallPromptEvent)
        }

        const onAppInstalled = () => {
            setEvent(null)
        }

        window.addEventListener("beforeinstallprompt", onBeforeInstall);
        window.addEventListener("appinstalled", onAppInstalled)

        return () => {
            window.removeEventListener("beforeinstallprompt", onBeforeInstall);
            window.removeEventListener("appinstalled", onAppInstalled);
        };
    }, []);

    const context: PWAContextType = {
        isInstallable: event !== null,
        triggerInstall: onTriggerInstall
    }

    return (
        <PWAContext.Provider value={ context }>
            { children }
        </PWAContext.Provider>
    )
}

export const usePWA = () => useContext(PWAContext);
