import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useCancelEventMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


interface CancelEventItemProps {
    eventUUID: UUID
}

export function CancelEventItem(props: CancelEventItemProps) {
    const { eventUUID } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const [ cancelEvent ] = useCancelEventMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.CancelEvent.Cancel event") }</Text>,
            children: t("components:Events.CancelEvent.Do you want to cancel this event"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Cancel event") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => cancelEvent({ variables: { eventId: eventUUID } }),
                    onSuccess: () => {
                        showSuccessNotification(t("components:Events.CancelEvent.Event canceled"))
                        revalidator.revalidate()
                    },
                    onError: (error) => {
                        Logger.error("Failed to cancel Event", error)
                        showErrorNotification({ message: t("components:Events.CancelEvent.Failed to cancel event") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } leftSection={ <IconX size={ 18 } /> } id="cancel-visitor-menu-item" data-umami-event="cancel-visitor-menu-item">
            { t("components:Events.CancelEvent.Cancel event") }
        </Menu.Item>
    )
}
