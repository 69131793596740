import { toDateTime } from "@/common/Luxon";
import { __InputWrapperProps, Box, Grid, Input } from "@mantine/core";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";


interface BirthdayInputProps extends __InputWrapperProps {
    value?: string
    disabled?: boolean
    onChange: (value: string | undefined) => void
}

export function BirthdayInput(props: BirthdayInputProps) {
    const { description, required, error } = props

    const { t } = useTranslation()

    return (
        <Input.Wrapper label={ t("components:Shared.Input.Birthday") } description={ description } required={ required } error={ error }>
            <Box mt={ description ? 5 : 0 }>
                <Inner { ...props } />
            </Box>
        </Input.Wrapper>
    );
}

function Inner(props: BirthdayInputProps) {
    const { value, disabled, required, onChange, error } = props

    const { t } = useTranslation()

    const initialDate = toDateTime(value)

    const [ , setInnerValue ] = useState(initialDate)
    const [ day, setDay ] = useState<number | "">(initialDate?.day ?? "")
    const [ month, setMonth ] = useState<number | "">(initialDate?.month ?? "")
    const [ year, setYear ] = useState<number | "">(initialDate?.year ?? "")

    const currentYear = useMemo(() => DateTime.now().year, [])

    useEffect(() => {
        const date = toDateTime(value)

        setInnerValue(prevState => {
            if (date !== prevState) {
                setDay(date?.day ?? "")
                setMonth(date?.month ?? "")
                setYear(date?.year ?? "")
                return date
            } else {
                return prevState
            }
        })
    }, [ value ])

    const updateDate = (day: number | "", month: number | "", year: number | "") => {
        if (day && month && year) {
            try {
                const dateTime = DateTime.fromObject({ day: day, month: month, year: year });

                if (dateTime.isValid) {
                    setInnerValue(dateTime)
                    onChange(dateTime.toISODate())
                } else {
                    setInnerValue(null)
                    onChange(undefined)
                }
            } catch (e: unknown) {
                setInnerValue(null)
                onChange(undefined)
            }
        } else {
            setInnerValue(null)
            onChange(undefined)
        }
    }

    const onDayChange = (value: string) => {
        const v = value === "" ? "" : parseFloat(value)
        setDay(v)
        updateDate(v, month, year)
    }

    const onMonthChange = (value: string) => {
        const v = value === "" ? "" : parseFloat(value)
        setMonth(v)
        updateDate(day, v, year)
    }

    const onYearChange = (value: string) => {
        const v = value === "" ? "" : parseFloat(value)
        setYear(v)
        updateDate(day, month, v)
    }

    return (
        <Grid>
            <Grid.Col span={ { base: 3, sm: 4 } }>
                <Input<"input">
                    placeholder={ t("components:BirthdayInput.Day") ?? "" }
                    title={ t("components:BirthdayInput.Day") ?? "" }
                    autoComplete="bday-day"
                    onChange={ e => onDayChange(e.target.value) }
                    type="number"
                    min={ 1 }
                    max={ 31 }
                    value={ day }
                    error={ error }
                    required={ required }
                    disabled={ disabled } />
            </Grid.Col>

            <Grid.Col span={ { base: 5, sm: 4 } }>
                <Input<"input">
                    placeholder={ t("components:BirthdayInput.Month") ?? "" }
                    title={ t("components:BirthdayInput.Month") ?? "" }
                    autoComplete="bday-month"
                    onChange={ e => onMonthChange(e.target.value) }
                    type="number"
                    min={ 1 }
                    max={ 12 }
                    value={ month }
                    error={ error }
                    required={ required }
                    disabled={ disabled } />
            </Grid.Col>

            <Grid.Col span={ { base: 4, sm: 4 } }>
                <Input<"input">
                    placeholder={ t("components:BirthdayInput.Year") ?? "" }
                    title={ t("components:BirthdayInput.Year") ?? "" }
                    autoComplete="bday-year"
                    onChange={ e => onYearChange(e.target.value) }
                    type="number"
                    min={ 1900 }
                    max={ currentYear }
                    value={ year }
                    error={ error }
                    required={ required }
                    disabled={ disabled } />
            </Grid.Col>
        </Grid>
    )
}
