import { UUID } from "@/common/Types";
import { DeleteMembershipTemplateItem } from "@/components/memberships/template/menu/DeleteMembershipTemplateItem";
import { PublishMembershipTemplateItem } from "@/components/memberships/template/menu/PublishMembershipTemplateItem";
import { UnpublishMembershipTemplateItem } from "@/components/memberships/template/menu/UnpublishMembershipTemplateItem";
import { UpdateMembershipTemplateItem } from "@/components/memberships/template/menu/UpdateMembershipTemplateItem";
import { MenuButton } from "@/components/shared";
import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission, MembershipAgreementTemplateState } from "@/generated/graphql";
import { Menu, Stack } from "@mantine/core";

interface MembershipTemplateMenuProps {
    membershipTemplate: {
        uuid: UUID
        state: MembershipAgreementTemplateState
    }
}

export function MembershipTemplateMenu(props: MembershipTemplateMenuProps) {
    const { membershipTemplate } = props

    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.MembershipAgreementManager)) {
        return null
    }

    return (
        <Menu transitionProps={ { transition: "scale-y" } } position="bottom-end" withinPortal shadow="sm">
            <Menu.Target>
                <MenuButton />
            </Menu.Target>

            <Menu.Dropdown>
                <Stack gap={ 4 }>
                    <UpdateMembershipTemplateItem membershipTemplate={ membershipTemplate } />
                    { membershipTemplate.state === MembershipAgreementTemplateState.Draft && <PublishMembershipTemplateItem membershipTemplate={ membershipTemplate } /> }
                    { membershipTemplate.state === MembershipAgreementTemplateState.Published && <UnpublishMembershipTemplateItem membershipTemplate={ membershipTemplate } /> }
                    <DeleteMembershipTemplateItem membershipTemplate={ membershipTemplate } />
                </Stack>
            </Menu.Dropdown>
        </Menu>
    )
}
