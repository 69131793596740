import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission, useEventRegistrationLogQuery } from "@/generated/graphql";
import { RegistrationLogCard } from "./RegistrationLogCard";


interface RegistrationLogContainerProps {
    eventId: string
}

export function RegistrationLogContainer(props: RegistrationLogContainerProps) {
    const { eventId } = props

    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.EventRegistrationManager)) {
        return null
    }

    return <RegistrationLogInnerContainer eventId={ eventId } />
}

interface RegistrationLogInnerContainerProps {
    eventId: string
}

function RegistrationLogInnerContainer(props: RegistrationLogInnerContainerProps) {
    const { eventId } = props

    const { data } = useEventRegistrationLogQuery({
        variables: { eventId: eventId },
        fetchPolicy: "cache-and-network"
    })

    if (!data) {
        return null
    }

    return <RegistrationLogCard entries={ data.eventRegistrationLogEntries } />
}
