import { useState } from "react";
import { SignUpCodeForm } from "./SignUpCodeForm"
import { SignUpEmailAddressForm } from "./SignUpEmailAddressForm"


interface SignUpFormProps {
    onSuccess?: () => void
    autoFocus?: boolean
}

export function SignUpForm(props: SignUpFormProps) {
    const { autoFocus, onSuccess } = props

    const [ step, setStep ] = useState<"Email" | "Code">("Email")

    const onEmailSuccess = () => {
        setStep("Code")
    }

    const onCodeExpired = () => {
        setStep("Email")
    }

    return (
        <>
            { step === "Email" && <SignUpEmailAddressForm autoFocus={ autoFocus } onSuccess={ onEmailSuccess } /> }
            { step === "Code" && <SignUpCodeForm initialValues={ { code: "" } } onSuccess={ onSuccess } onExpired={ onCodeExpired } /> }
        </>
    )
}
