import { ActionIcon, Group, Title } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";

interface PageTitleProps {
    title: string
}

export function PageTitle(props: PageTitleProps) {
    const { title } = props

    return (
        <Title order={ 2 }>{ title }</Title>
    )
}

interface PageTitleWithBackProps {
    title: string
    onBack?: () => void
}

export function PageTitleWithBack(props: PageTitleWithBackProps) {
    const { title, onBack } = props;

    const navigate = useNavigate()

    const actionIcon = (() => {
        if (onBack) {
            return (
                <ActionIcon variant="subtle" color="gray" size="lg" onClick={ onBack } aria-label="Back">
                    <IconChevronLeft size={ 24 } />
                </ActionIcon>
            )
        }

        if (window.history.state && window.history.state.idx > 0) {
            return (
                <ActionIcon variant="subtle" color="gray" size="lg" onClick={ () => navigate(-1) } aria-label="Back">
                    <IconChevronLeft size={ 24 } />
                </ActionIcon>
            )
        }

        return (
            <ActionIcon variant="subtle" color="gray" size="lg" component={ Link } to=".." relative="path" replace={ true } aria-label="Back">
                <IconChevronLeft size={ 24 } />
            </ActionIcon>
        )
    })()

    return (
        <Group align="center" gap="xs" wrap="nowrap">
            { actionIcon }
            <PageTitle title={ title } />
        </Group>
    )
}
