import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { useCreateVisitorEventRegistrationMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils";
import { VisitorForm, VisitorFormInput } from "./VisitorForm";


interface CreateVisitorEventRegistrationFormProps {
    eventUUID: UUID

    onSuccess: (uuid: UUID) => void
    onError: (error: unknown) => void
    onCancel: () => void
}

export function CreateVisitorEventRegistrationForm(props: CreateVisitorEventRegistrationFormProps) {
    const { eventUUID, onCancel, onError, onSuccess } = props

    const [ createVisitor ] = useCreateVisitorEventRegistrationMutation()

    const initialValues: VisitorFormInput = {
        name: "",
        comment: null
    }

    const onSubmit = async (input: VisitorFormInput) => {
        return await executeMutation({
                mutation: () => createVisitor({
                    variables: { eventId: eventUUID, input: input }
                }),
                onSuccess: data => {
                    onSuccess(data.createVisitorEventRegistration.uuid)
                },
            onError: (error) => {
                    Logger.error("Failed to create Visitor EventRegistration", error)
                    onError(error)
                }
            }
        )
    }

    return <VisitorForm initialValues={ initialValues }
                        onSubmit={ onSubmit }
                        onCancel={ onCancel } />
}
