import { UUID } from "@/common/Types";
import { EventLineItem } from "@/components/events/event";
import { ContentCard } from "@/components/shared";
import { ActionIcon, Box, Card, Stack, Text } from "@mantine/core";
import { IconSwitch3 } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface SwitchEventType {
    uuid: UUID
    start: DateTime
    end: DateTime
    name: string
    color: string | null
}

export interface SwitchEventCardProps {
    events: readonly SwitchEventType[]
    onClick: (event: SwitchEventType) => Promise<void> | void
}

export function SwitchEventCard(props: SwitchEventCardProps) {
    const { events, onClick } = props

    const { t } = useTranslation()

    if (events.length === 0) {
        return null
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Events.SwitchEvent.Switch event") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <Stack>
                    { events.map(it => <Event key={ it.uuid } event={ it } onClick={ onClick } />) }
                </Stack>
            </Card.Section>
        </ContentCard>
    )
}

interface EventProps {
    event: SwitchEventType,
    onClick: (event: SwitchEventType) => Promise<void> | void
}

function Event(props: EventProps) {
    const { event, onClick } = props

    const rightSection = (
        <ActionIcon variant="light" color="brand">
            <IconSwitch3 />
        </ActionIcon>
    )

    return (
        <Box w="100%" onClick={ () => onClick(event) } style={ { cursor: "pointer" } }>
            <EventLineItem color={ event.color }
                           from={ event.start.toFormat("HH:mm") }
                           name={ event.name }
                           rightSection={ rightSection }
                           withBorder
                           to={ event.end.toFormat("HH:mm") } />
        </Box>
    )
}
