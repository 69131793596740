import { showSuccessNotification } from "@/common/Notification";
import { SignInOrSignUpCard } from "@/components/account";
import {
    MembershipCheckoutFormContainer,
    MembershipTemplateDetailsCard,
    MembershipTemplateMenu,
    MembershipTemplateStateBadge,
    PaymentCard
} from "@/components/memberships/template";
import { PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import {
    MembershipAgreementState,
    MembershipAgreementTemplateDocument,
    MembershipAgreementTemplateQuery,
    MembershipAgreementTemplateQueryVariables,
    MembershipsByIdPageDataAuthenticatedDocument,
    MembershipsByIdPageDataAuthenticatedQuery,
    MembershipsByIdPageDataAuthenticatedQueryVariables,
} from "@/generated/graphql";
import RoutingStorage from "@/routing/RoutingStorage";
import { Group, SimpleGrid, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, useLoaderData, useRevalidator } from "react-router-dom";


interface MembershipsByIdPageAuthenticatedPageData {
    isAuthenticated: true,
    me: MembershipsByIdPageDataAuthenticatedQuery["me"]
    membershipTemplate: MembershipAgreementTemplateQuery["membershipAgreementTemplate"]
    myMembershipAgreements: MembershipsByIdPageDataAuthenticatedQuery["myMembershipAgreements"]
    organisation: MembershipsByIdPageDataAuthenticatedQuery["organisation"]
}

interface MembershipsByIdPageVisitorPageData {
    isAuthenticated: false,
    membershipTemplate: MembershipAgreementTemplateQuery["membershipAgreementTemplate"]
}

type MembershipsByIdPageData = MembershipsByIdPageAuthenticatedPageData | MembershipsByIdPageVisitorPageData

export function MembershipsByIdPageLoader(args: LoaderFunctionArgs): Promise<MembershipsByIdPageData> {
    if (RoutingStorage.isAuthenticated()) {
        return MembershipsByIdPageAuthenticatedLoader(args)
    } else {
        return MembershipsByIdPageVisitorLoader(args)
    }
}

async function MembershipsByIdPageAuthenticatedLoader({ params }: LoaderFunctionArgs): Promise<MembershipsByIdPageAuthenticatedPageData> {
    const membershipTemplate = GraphQLClient.query<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>({
        query: MembershipAgreementTemplateDocument,
        variables: { uuid: params.uuid! },
    })

    const authenticatedData = GraphQLClient.query<MembershipsByIdPageDataAuthenticatedQuery, MembershipsByIdPageDataAuthenticatedQueryVariables>({
        query: MembershipsByIdPageDataAuthenticatedDocument,
        variables: { filter: { states: [ MembershipAgreementState.Active ] } }
    })

    return {
        isAuthenticated: true,
        me: (await authenticatedData).data.me,
        membershipTemplate: (await membershipTemplate).data.membershipAgreementTemplate,
        myMembershipAgreements: (await authenticatedData).data.myMembershipAgreements,
        organisation: (await authenticatedData).data.organisation,
    }
}

async function MembershipsByIdPageVisitorLoader({ params }: LoaderFunctionArgs): Promise<MembershipsByIdPageVisitorPageData> {
    const membershipTemplate = GraphQLClient.query<MembershipAgreementTemplateQuery, MembershipAgreementTemplateQueryVariables>({
        query: MembershipAgreementTemplateDocument,
        variables: { uuid: params.uuid! },
    })

    return {
        isAuthenticated: false,
        membershipTemplate: (await membershipTemplate).data.membershipAgreementTemplate

    }
}

export function MembershipsByIdPage() {
    const pageData = useLoaderData() as MembershipsByIdPageData

    const { t } = useTranslation()
    const revalidator = useRevalidator();

    const onSignUpSuccess = () => {
        showSuccessNotification(t("components:Account.SignUpForm.SignUp successful"))
        revalidator.revalidate()
    }

    return (
        <>
            <MetaTitle title={ pageData.membershipTemplate.name } />

            <Stack>
                <Group justify="space-between" wrap="nowrap">
                    <Group wrap="nowrap">
                        <PageTitleWithBack title={ pageData.membershipTemplate.name } />
                        <MembershipTemplateStateBadge membershipTemplate={ pageData.membershipTemplate } />
                    </Group>

                    <MembershipTemplateMenu membershipTemplate={ pageData.membershipTemplate } />
                </Group>

                <SimpleGrid cols={ { xs: 1, xl: 2 } }>
                    <Stack>
                        <MembershipTemplateDetailsCard organisation={ pageData.membershipTemplate.organisation } template={ pageData.membershipTemplate } />
                        <PaymentCard />
                    </Stack>
                    <Stack>
                        {
                            pageData.isAuthenticated
                                ? <MembershipCheckoutFormContainer myMemberships={ pageData.myMembershipAgreements.results }
                                                                   organisation={ pageData.organisation }
                                                                   person={ pageData.me }
                                                                   template={ pageData.membershipTemplate } />
                                : <SignInOrSignUpCard onSignUpSuccess={ onSignUpSuccess } />
                        }
                    </Stack>
                </SimpleGrid>
            </Stack>
        </>
    )
}