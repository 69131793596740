export const REACTION_TYPES = [ "muscle", "fire", "joy", "heart_eyes" ];

export type ReactionType = typeof REACTION_TYPES[number];

export const ReactionEmojiMap: { [key in ReactionType]: string } = {
    "muscle": "\u{1F4AA}",
    "fire": "\u{1F525}",
    "joy": "\u{1F602}",
    "heart_eyes": "\u{1F60D}",
}
