import Logger from "@/common/Logger"
import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { FormErrorAlert } from "@/components/shared";
import { useCreateEmailAddressUpdateMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface UpdateEmailAddressFormData {
    newEmailAddress: string
}

interface UpdateEmailAddressFormProps {
    currentEmailAddress: string
    onSuccess: () => void
}

export function UpdateEmailAddressForm(props: UpdateEmailAddressFormProps) {
    const { currentEmailAddress, onSuccess } = props

    const { t } = useTranslation()

    const [ createEmailAddressUpdate ] = useCreateEmailAddressUpdateMutation()

    const form = useMyPowerHourForm<UpdateEmailAddressFormData>({
        name: "UpdateEmailAddressForm",
        initialValues: {
            newEmailAddress: ""
        },
    })

    const handleSubmit = async (data: UpdateEmailAddressFormData) => {
        const input = {
            newEmailAddress: data.newEmailAddress
        }

        await executeMutation({
            mutation: () => createEmailAddressUpdate({ variables: { input: input } }),
            onSuccess: onSuccess,
            onError: (error) => {
                Logger.error("Failed to sign up", error)
                showErrorNotification({ message: t("common:Error.Something went wrong") })
            }
        })
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <TextInput label={ t("components:Account.UpdateEmailAddressForm.Current email address") }
                           name="currentEmailAddress"
                           type="email"
                           disabled
                           value={ currentEmailAddress } />

                <TextInput label={ t("components:Account.UpdateEmailAddressForm.New email address") }
                           name="newEmailAddress"
                           type="email"
                           autoComplete="email"
                           required
                           { ...form.getInputProps("newEmailAddress") } />

                <Group justify="flex-end">
                    { form.hasErrors && <FormErrorAlert /> }
                    <Button type="submit" title="submit" loading={ form.isSubmitting } disabled={ form.hasErrors }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
