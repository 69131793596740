import { UUID } from "@/common/Types";
import { ContentCard, PaperStack } from "@/components/shared";
import { Routes } from "@/routing/Routes";
import { Center, Grid, Stack, Text, Title } from "@mantine/core";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface WorkoutType {
    uuid: UUID
    date: DateTime
    name: string
    publishingDate: DateTime
    scoreBoards: {
        results: {
            uuid: UUID
        }[]
    }[]
}

interface WorkoutListProps {
    workouts: WorkoutType[]
}

type WorkoutsByDate = { [key: string]: WorkoutType[] }

export function WorkoutList(props: WorkoutListProps) {
    const { workouts } = props

    const { t } = useTranslation()

    if (workouts.length === 0) {
        return (
            <ContentCard>
                <Center>
                    <Text>{ t("pages:WorkoutsPage.No Workouts") }</Text>
                </Center>
            </ContentCard>
        )
    }

    const now = DateTime.now()
    const published = workouts
        .filter(it => it.publishingDate < now)
        .reduce(
            (acc: WorkoutsByDate, el) => {
                const date = el.date.toISODate();
                if (acc.hasOwnProperty(date)) acc[date].push(el);
                else acc[date] = [ el ];
                return acc;
            }, {}
        )

    return (
        <Stack>
            {
                Object.keys(published).map(key => {
                    const workouts = published[key];
                    return (
                        <React.Fragment key={ key }>
                            <Title order={ 4 }>{ DateTime.fromISO(key).toFormat("EEE dd.MM") }</Title>

                            <PaperStack>
                                {
                                    workouts.map(it => {
                                        const resultCount = it.scoreBoards.flatMap(it => it.results).length

                                        return (
                                            <PaperStack.Row key={ it.uuid } component={ Link } to={ generatePath(Routes.WORKOUTS_BY_ID, { uuid: it.uuid }) }>
                                                <Grid>
                                                    <Grid.Col span="auto">
                                                        <Text fw={ 500 }>{ it.name }</Text>
                                                    </Grid.Col>
                                                    { resultCount > 0 && (<Grid.Col span="content">
                                                        <Text size="sm" color="dimmed">
                                                            { t("pages:WorkoutsPage.X results", { count: it.scoreBoards.flatMap(it => it.results).length }) }
                                                        </Text>
                                                    </Grid.Col>) }
                                                </Grid>
                                            </PaperStack.Row>
                                        )
                                    })
                                }
                            </PaperStack>
                        </React.Fragment>
                    );
                })
            }
        </Stack>
    )
}
