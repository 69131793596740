import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Menu } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface UpdateMembershipTemplateItemProps {
    membershipTemplate: {
        uuid: UUID
    }
}

export function UpdateMembershipTemplateItem(props: UpdateMembershipTemplateItemProps) {
    const { membershipTemplate } = props

    const { t } = useTranslation()

    return (
        <Menu.Item component={ Link } to={ generatePath(Routes.MEMBERSHIPS_BY_ID_UPDATE, { uuid: membershipTemplate.uuid }) } leftSection={ <IconEdit size={ 18 } /> }>
            { t("pages:MembershipsByIdPage.Update Template") }
        </Menu.Item>
    )
}
