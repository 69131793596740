import { UUID } from "@/common/Types";
import { ContentCard, PaperStack } from "@/components/shared";
import { BenchmarkResultOrder, BenchmarkResultType } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Center, Grid, Text } from "@mantine/core";
import { Duration } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface BenchmarkType {
    uuid: UUID
    name: string
    resultType: BenchmarkResultType
    resultOrder: BenchmarkResultOrder

    results: readonly {
        score: string
    }[]
}

interface BenchmarkListProps {
    benchmarks: BenchmarkType[]
}

export function BenchmarkList(props: BenchmarkListProps) {
    const { benchmarks } = props

    const { t } = useTranslation()

    if (benchmarks.length === 0) {
        return (
            <ContentCard>
                <Center>
                    <Text>{ t("pages:MyBenchmarksPage.No Benchmarks") }</Text>
                </Center>
            </ContentCard>
        )
    }

    return (
        <PaperStack>
            {
                benchmarks.map((it) => (
                    <PaperStack.Row key={ it.uuid } component={ Link } to={ generatePath(Routes.MY_BENCHMARKS_BY_ID, { uuid: it.uuid }) }>
                        <Benchmark benchmark={ it } />
                    </PaperStack.Row>
                ))
            }
        </PaperStack>
    )
}

interface BenchmarkProps {
    benchmark: BenchmarkType
}

function Benchmark(props: BenchmarkProps) {
    const { benchmark } = props

    return (
        <Grid>
            <Grid.Col span="auto">
                <Text fw={ 500 }>{ benchmark.name }</Text>
            </Grid.Col>
            <Grid.Col span="content">
                <Text>{ FindBestResult(benchmark) }</Text>
            </Grid.Col>
        </Grid>
    )
}

function FindBestResult(benchmark: BenchmarkType): string | null {
    const { t } = useTranslation()

    if (benchmark.results.length === 0) {
        return null
    }

    switch (benchmark.resultType) {
        case BenchmarkResultType.Distance:
        case BenchmarkResultType.Repetition:
        case BenchmarkResultType.Weight:
            const scores = benchmark.results.map(it => parseFloat(it.score))
            switch (benchmark.resultOrder) {
                case BenchmarkResultOrder.Ascending:
                    return Math.min.apply(null, scores).toString() + " " + t(`entities:BenchmarkResultType.Suffixes.${ benchmark.resultType }`)
                case BenchmarkResultOrder.Descending:
                    return Math.max.apply(null, scores).toString() + " " + t(`entities:BenchmarkResultType.Suffixes.${ benchmark.resultType }`)
            }
            break;
        case BenchmarkResultType.Duration:
            const durations = benchmark.results.map(it => Duration.fromISO(it.score))
            switch (benchmark.resultOrder) {
                case BenchmarkResultOrder.Ascending:
                    return durations.sort((a, b) => a.toMillis() - b.toMillis())[0].shiftTo("minutes", "second").toFormat("mm:ss")
                case BenchmarkResultOrder.Descending:
                    return durations.sort((a, b) => b.toMillis() - a.toMillis())[0].shiftTo("minutes", "second").toFormat("mm:ss")
            }
            break;
    }
}
