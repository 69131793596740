import { UUID } from "@/common/Types";
import { MembershipListItem } from "@/components/memberships/MembershipListItem";
import { PaperStack } from "@/components/shared";
import { generatePath } from "react-router-dom";


export interface MembershipRequestListProps {
    memberships: readonly {
        uuid: UUID
        created: string
        name: string
        subtitle: string | null
    }[]
    route: string,
}

export function MembershipRequestList(props: MembershipRequestListProps) {
    const { memberships, route } = props

    return (
        <PaperStack>
            {
                memberships
                    .slice()
                    .sort((a, b) => a.created.localeCompare(b.created))
                    .map(it => <MembershipListItem key={ it.uuid }
                                                   name={ it.name }
                                                   subtitle={ it.subtitle }
                                                   to={ generatePath(route, { uuid: it.uuid }) } />
                    )
            }
        </PaperStack>
    )
}
