import { useDeviceSize } from "@/contexts/device-size";
import { Stack } from "@mantine/core";
import { DateTime } from "luxon";
import { EventCalendarDesktop } from "./EventCalendarDesktop"
import { EventCalendarMobile } from "./EventCalendarMobile"
import { CalendarEvent } from "./types"
import { WeekPagination } from "./WeekPagination"


export interface EventCalendarProps {
    events: CalendarEvent[]
    from: DateTime
    onFromChanged: (start: DateTime) => void
}

export function EventCalendar(props: EventCalendarProps) {
    const { events, from, onFromChanged } = props

    const { isMobile } = useDeviceSize()

    let calender
    if (isMobile) {
        calender = <EventCalendarMobile events={ events } from={ from } />
    } else {
        calender = <EventCalendarDesktop events={ events } from={ from } />
    }

    return (
        <Stack>
            <WeekPagination from={ from } onFromChanged={ onFromChanged } />

            { calender }
        </Stack>
    )
}
