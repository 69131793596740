import RoutingStorage from "@/routing/RoutingStorage";
import { MantineSize, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { PropsWithChildren, useContext } from "react";


export enum DeviceSize {
    SMALL = 1,
    MEDIUM = 2,
    LARGE = 3,
    XLARGE = 4,
    XXLARGE = 5
}

export interface DeviceSizeContextType {
    deviceSize: DeviceSize
    isMobile: boolean
    p: MantineSize
}

const defaultValue: DeviceSizeContextType = {
    deviceSize: DeviceSize.LARGE,
    isMobile: false,
    p: "md"
};

const DeviceSizeContext = React.createContext(defaultValue);

/**
 * useMediaQuery and useViewportSize return undefined or 0 on their first renders.
 * This leads to flickering, when rendering relies on them.
 * Using a context (combined with the InitializationBoundary) mitigates this issue.
 */
export function DeviceSizeContextProvider(props: PropsWithChildren) {
    const { children } = props

    const theme = useMantineTheme();
    const isSmall = useMediaQuery(`(max-width: ${ theme.breakpoints.sm })`);
    const isMedium = useMediaQuery(`(max-width: ${ theme.breakpoints.md })`);
    const isLarge = useMediaQuery(`(max-width: ${ theme.breakpoints.lg })`);
    const isXLarge = useMediaQuery(`(max-width: ${ theme.breakpoints.xl })`);

    let size: DeviceSize
    if (isSmall) {
        size = DeviceSize.SMALL
    } else if (isMedium) {
        size = DeviceSize.MEDIUM
    } else if (isLarge) {
        size = DeviceSize.LARGE
    } else if (isXLarge) {
        size = DeviceSize.XLARGE
    } else {
        size = DeviceSize.XXLARGE
    }

    const mobileBreakpoint = DeviceSize.MEDIUM
    const isMobile = size <= mobileBreakpoint;

    const context: DeviceSizeContextType = {
        deviceSize: size,
        isMobile: isMobile,
        p: isMobile ? "xs" : "md"
    }

    RoutingStorage.setIsMobile(isMobile)

    return (
        <DeviceSizeContext.Provider value={ context }>
            { children }
        </DeviceSizeContext.Provider>
    );
}

export const useDeviceSize = () => useContext(DeviceSizeContext);
