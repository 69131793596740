import { ContentCard } from "@/components/shared";
import { Cloudinary } from "@/configuration/Cloudinary";
import { CloudinaryFile } from "@/generated/graphql";
import { BackgroundImage, Button, Flex, Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface ShopBannerCardProps {
    shopBanner: CloudinaryFile
    shopUrl: string
}

export function ShopBannerCard(props: ShopBannerCardProps) {
    const { shopBanner, shopUrl } = props

    const { t } = useTranslation()

    const src = Cloudinary.image(shopBanner.publicId).setVersion(shopBanner.version).toURL()

    return (
        <ContentCard style={ { height: "100%" } }>
            <BackgroundImage src={ src }>
                <Flex align="center" justify="center" mih={ 250 }>
                    <Paper p="md" shadow="md" miw={ 200 }>
                        <Flex align="stretch" direction="column">
                            <Button component="a" href={ shopUrl } target="_blank" data-umami-event="visit-shop">
                                { t("components:Info.ShopBannerCard.Visit Shop") }
                            </Button>
                        </Flex>
                    </Paper>
                </Flex>
            </BackgroundImage>
        </ContentCard>
    )
}