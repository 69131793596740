import { Format } from "@/common/Format"
import Logger from "@/common/Logger";
import { showErrorNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { ContentCard } from "@/components/shared";
import { MembershipAgreementState, useRequestMembershipAgreementTerminationMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Box, Button, Card, Grid, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";

interface RequestTerminationCardProps {
    membership: {
        uuid: UUID
        state: MembershipAgreementState
        specialTerminationUntil: string | null
        nextEndDate: string | null
        nextTerminationDeadline: string | null
        renewalPeriod: string | null
    }
    onTerminationRequested: () => void
}

export function RequestTerminationCard(props: RequestTerminationCardProps) {
    const { membership } = props

    const { t } = useTranslation()

    if (![ MembershipAgreementState.Active, MembershipAgreementState.Suspended ].includes(membership.state)) {
        return null
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Memberships.RequestTerminationCard.Termination") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <RequestTerminationCardSection { ...props } />
            </Card.Section>
        </ContentCard>
    )
}

function RequestTerminationCardSection(props: RequestTerminationCardProps) {
    return (
        <Grid align="start">
            <Grid.Col span={ { base: 12, sm: "auto" } }>
                <Text>{ TerminationText(props) }</Text>
            </Grid.Col>
            <Grid.Col span={ { base: 12, sm: "content" } }>
                <Box style={ { display: "flex", alignItems: "center", justifyContent: "end", height: "100%" } }>
                    <RequestTerminationButton { ...props } />
                </Box>
            </Grid.Col>
        </Grid>
    )
}

function TerminationText(props: RequestTerminationCardProps) {
    const { membership } = props

    const { t } = useTranslation()

    if (membership.nextEndDate && membership.specialTerminationUntil && DateTime.now() < DateTime.fromISO(membership.specialTerminationUntil)) {
        return t(
            "components:Memberships.RequestTerminationCard.Special Termination Instructions",
            {
                nextEndDate: DateTime.fromISO(membership.nextEndDate).toFormat("dd.MM.yyyy")
            }
        )
    }

    if (membership.nextEndDate && membership.nextTerminationDeadline && membership.renewalPeriod) {
        return t(
            "components:Memberships.RequestTerminationCard.Termination Instructions",
            {
                nextEndDate: DateTime.fromISO(membership.nextEndDate).toFormat("dd.MM.yyyy"),
                nextTerminationDeadline: DateTime.fromISO(membership.nextTerminationDeadline).toFormat("dd.MM.yyyy"),
                renewalPeriod: Format.humanize(Duration.fromISO(membership.renewalPeriod))
            }
        )
    }

    if (membership.nextEndDate && membership.nextTerminationDeadline === null && membership.renewalPeriod === null) {
        return t(
            "components:Memberships.RequestTerminationCard.Termination without restrictions",
            {
                nextEndDate: DateTime.fromISO(membership.nextEndDate).toFormat("dd.MM.yyyy")
            }
        )
    }

    throw Error(`Invalid value combination of ${ JSON.stringify(membership) }`)
}

interface RequestTerminationButtonProps {
    membership: {
        uuid: UUID
    }
    onTerminationRequested: () => void
}

function RequestTerminationButton(props: RequestTerminationButtonProps) {
    const { membership, onTerminationRequested } = props

    const { t } = useTranslation()

    const [ requestTermination ] = useRequestMembershipAgreementTerminationMutation()

    const onRequest = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Memberships.RequestTerminationCard.Terminate Membership") }</Text>,
            children: t("components:Memberships.RequestTerminationCard.Do you want to terminate this membership?"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Terminate") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => requestTermination({ variables: { uuid: membership.uuid } }),
                    onSuccess: onTerminationRequested,
                    onError: (error) => {
                        Logger.error("Failed to terminate MembershipAgreement", error)
                        showErrorNotification({ error })
                    }
                })
            }
        })
    }

    return (
        <Button fullWidth color="red" onClick={ onRequest }>{ t("common:Button.Terminate") }</Button>
    )
}
