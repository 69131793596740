import { AppShell, Box, Button, Container, Grid, Stack, Text, Title } from "@mantine/core";
import { Title as HeadTitle } from "react-head"
import { useTranslation } from "react-i18next";
import classes from "./OrganisationNotFoundPage.module.scss"


interface OrganisationNotFoundPageProps {
    slug: string
}

export function OrganisationNotFoundPage(props: OrganisationNotFoundPageProps) {
    const { slug } = props

    const { t } = useTranslation()

    return (
        <>
            <HeadTitle>{ t("common:Misc.AppName") }</HeadTitle>

            <Box>
                <AppShell.Header h={ 55 } className={ classes.header }>
                    <Container h="100%">
                        <Grid align="center" h="100%" my={ 0 }>
                            <Grid.Col span="auto">
                                <Title order={ 1 } size={ 24 } fw={ 600 } c="white" style={ { whiteSpace: "nowrap" } }>
                                    { slug }
                                </Title>
                            </Grid.Col>
                        </Grid>
                    </Container>
                </AppShell.Header>


                <main>
                    <Box pt={ 55 }>
                        <Container mt="md">
                            <Stack align="center">
                                <Text size="xl" fw={ 600 }>{ t("pages:OrganisationNotFoundPage.Unknown Organisation", { name: slug }) }</Text>

                                <Text mt="xl">
                                    { t("pages:OrganisationNotFoundPage.Register Organisation", { name: slug }) }
                                </Text>
                                <Button component="a" href="https://mypowerhour.app">
                                    mypowerhour.app
                                </Button>
                            </Stack>
                        </Container>
                    </Box>
                </main>
            </Box>
        </>
    )
}
