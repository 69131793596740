import Person from "@/common/Person";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { GraphQLClient } from "@/contexts/graphql";
import { MemberDocument, MemberPermission, MemberQuery, MemberQueryVariables } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { Flex, ScrollArea, Stack, Text, UnstyledButton } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, LoaderFunctionArgs, NavLink, Outlet, useLoaderData } from "react-router-dom";
import classes from "./MemberByIdPage.module.scss"

export function MemberByIdPageLoader({ params }: LoaderFunctionArgs) {
    return GraphQLClient.query<MemberQuery, MemberQueryVariables>({
        query: MemberDocument,
        variables: { uuid: params.uuid! },
    })
}

export function MemberByIdPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<MemberQuery>

    const { t } = useTranslation()
    const { hasPermission } = useAuthentication()

    const member = queryResult.data.member

    return (
        <>
            <MetaTitle title={ Person.buildName(member) } />

            <Stack>
                <PageTitleWithBack title={ Person.buildName(member) } />

                <ContentCard p={ 0 }>
                    <ScrollArea type="never">
                        <Flex>
                            <SubPageLink to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID, { uuid: member.uuid }) }
                                         label={ t("pages:MemberByIdPage.Person") } />

                            { hasPermission(MemberPermission.MembershipAgreementManager) && (
                                <>
                                    <SubPageLink to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID_MEMBERSHIPS, { uuid: member.uuid }) }
                                                 label={ t("pages:MemberByIdPage.Memberships") } />
                                </>
                            ) }

                            <SubPageLink to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID_PENALTY, { uuid: member.uuid }) }
                                         label={ t("pages:MemberByIdPage.Penalty") } />
                            <SubPageLink to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID_EMAILS, { uuid: member.uuid }) }
                                         label={ t("pages:MemberByIdPage.Emails") } />
                        </Flex>
                    </ScrollArea>
                </ContentCard>

                <Outlet />
            </Stack>
        </>
    )
}

interface SubPageLinkProps {
    label: string
    to: string
}

export function SubPageLink(props: SubPageLinkProps) {
    const { label, to } = props

    return (
        <UnstyledButton component={ NavLink } to={ to } end={ true } replace={ true } className={ classes.link }>
            <Text>{ label }</Text>
        </UnstyledButton>
    )
}

