import { UUID } from "@/common/Types";
import { REACTION_TYPES, ReactionEmojiMap, ReactionType } from "@/components/workouts/result-reaction";
import { useAuthentication } from "@/contexts/authentication";
import { useCreateWorkoutResultReactionMutation, useDeleteWorkoutResultReactionMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { ActionIcon, Button, Popover, PopoverProps, ScrollArea, Stack, Text } from "@mantine/core";
import { IconList } from "@tabler/icons-react";
import cx from "clsx";
import { useRevalidator } from "react-router-dom";
import classes from "./WorkoutResultReactionButton.module.scss"

export interface Reaction {
    uuid: UUID

    type: string
    creator: { uuid: UUID, username: string } | null
}

interface WorkoutResultReactionsProps {
    disabled?: boolean
    reactions: readonly Reaction[]
    resultUUID: UUID
}

export function WorkoutResultReactions(props: WorkoutResultReactionsProps) {
    const { disabled, reactions, resultUUID } = props

    const revalidator = useRevalidator()

    const [ createReaction ] = useCreateWorkoutResultReactionMutation()
    const [ deleteReaction ] = useDeleteWorkoutResultReactionMutation()

    const { me } = useAuthentication()

    if (!me) {
        return null
    }

    const reactionTypeToNames = reactions.reduce(
        (acc: { [key in ReactionType]: string[] }, el) => {
            if (el.creator) {
                acc[el.type].push(el.creator.username);
            }
            return acc;
        },
        Object.fromEntries(REACTION_TYPES.map(it => [ it, [] ]))
    )

    const myReactions = Object.fromEntries(
        reactions.filter(it => me.uuid === it.creator?.uuid).map(it => [ it.type, it.uuid ])
    )

    const onClick = async (type: ReactionType) => {
        const myReactionUUID = myReactions[type]

        if (myReactionUUID) {
            await executeMutation({
                mutation: () => deleteReaction({
                    variables: { uuid: myReactionUUID, }
                }),
                onSuccess: () => revalidator.revalidate()
            })
        } else {
            await executeMutation({
                mutation: () => createReaction({
                    variables: {
                        workoutResultUUID: resultUUID,
                        input: { type: type }
                    }
                }),
                onSuccess: () => revalidator.revalidate()
            })
        }
    }

    return (
        <WorkoutResultReactionButtons disabled={ disabled }
                                    hasReactions={ reactions.length > 0 }
                                      myReactions={ myReactions }
                                      onClick={ onClick }
                                      reactionTypeToNames={ reactionTypeToNames } />
    )
}

interface WorkoutResultReactionButtonsProps {
    disabled?: boolean
    hasReactions: boolean
    myReactions: Record<string, string>
    onClick: (type: ReactionType) => void
    reactionTypeToNames: Record<string, string[]>
}

function WorkoutResultReactionButtons(props: WorkoutResultReactionButtonsProps) {
    const { disabled, hasReactions, myReactions, onClick, reactionTypeToNames } = props

    const popoverProps: PopoverProps = {
        transitionProps: { transition: "scale-y" },
        position: "bottom",
        shadow: "sm",
        withArrow: true,
        offset: 4,
        withinPortal: true
    }

    return (
        <>
            {
                REACTION_TYPES.map((type: ReactionType) => {
                    const className = cx({
                        [classes.button]: true,
                        [classes.selected]: myReactions[type]
                    })

                    return (
                        <Button key={ type }
                                disabled={ disabled }
                                className={ className }
                                onClick={ () => onClick(type) }>
                            { ReactionEmojiMap[type] } { reactionTypeToNames[type].length }
                        </Button>
                    )
                })
            }

            { hasReactions &&
                <Popover { ...popoverProps } disabled={ !hasReactions }>
                    <Popover.Target>
                        <ActionIcon size={ 23 } variant="transparent" color="dark" disabled={ !hasReactions }>
                            <IconList size={ 16 } />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown p={ 4 } px={ 8 }>
                        <ScrollArea.Autosize mah={ 100 } scrollbarSize={ 4 }>
                            <Stack gap={ 4 }>
                                {
                                    REACTION_TYPES.map((type: ReactionType) =>
                                        reactionTypeToNames[type].map((name: string) => (
                                            <Text key={ `${ type }-${ name }` } size="xs">{ `${ ReactionEmojiMap[type] } ${ name }` }</Text>
                                        ))
                                    )
                                }
                            </Stack>
                        </ScrollArea.Autosize>
                    </Popover.Dropdown>
                </Popover>
            }
        </>
    )
}
