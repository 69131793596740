import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { BenchmarkForm } from "@/components/workouts/benchmark/BenchmarkForm";
import { BenchmarkInput, BenchmarkResultOrder, BenchmarkResultType, useCreateBenchmarkMutation, } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";

interface CreateBenchmarkFormProps {
    onSuccess: (uuid: UUID) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function CreateBenchmarkForm(props: CreateBenchmarkFormProps) {
    const { onSuccess, onError, onCancel } = props

    const [ createBenchmark ] = useCreateBenchmarkMutation()

    const onSubmit = async (input: BenchmarkInput) => {
        return await executeMutation({
                mutation: () => createBenchmark({
                    variables: { input: input }
                }),
                onSuccess: data => {
                    onSuccess(data.createBenchmark.uuid)
                },
            onError: (error) => {
                    Logger.error("Failed to create Benchmark", error)
                    onError(error)
                }
            }
        )
    }

    const input: BenchmarkInput = {
        description: null,
        name: "",
        resultOrder: BenchmarkResultOrder.Descending,
        resultType: BenchmarkResultType.Weight
    }

    return (
        <BenchmarkForm input={ input }
                       hasResults={ false }
                       onSubmit={ onSubmit }
                       onCancel={ onCancel } />
    )
}
