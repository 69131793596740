import { DateMode, Event, EventLineItemLinked } from "@/components/events/event";
import { Box, Divider, Paper, Stack, Text } from "@mantine/core";
import React from "react";
import classes from "./SpecialEvent.module.scss"

interface SpecialEventProps {
    events: readonly Event[]
}

export function SpecialEventsSection(props: SpecialEventProps) {
    const { events } = props

    return (
        <Box className={ classes.root }>
            <Stack gap="xs">
                <Text fw={ 600 } size="lg">Anstehende Events</Text>
                <Paper shadow="xs" style={ { overflow: "hidden" } }>
                    {
                        events.map((event, i) => (
                            <React.Fragment key={ event.uuid }>
                                <EventLineItemLinked key={ event.uuid } dateMode={ DateMode.ABSOLUTE } event={ event } />
                                { i < events.length - 1 && <Divider color="gray.2" /> }
                            </React.Fragment>
                        ))
                    }
                </Paper>
            </Stack>
        </Box>
    )
}