import { DATE_INPUT_FORMAT_STR } from "@/common/Format"
import Logger from "@/common/Logger";
import { DEFAULT_UUID } from "@/common/Object";
import { UUID } from "@/common/Types";
import { WorkoutFormHelper } from "@/components/workouts/workout/WorkoutFormHelper"
import { CreateWorkoutMutation, useCreateWorkoutMutation, WorkoutInput, WorkoutResultOrder, WorkoutResultType, WorkoutVisibility } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { DateTime } from "luxon";
import { WorkoutForm } from "./WorkoutForm";


interface CreateWorkoutFormProps {
    initialValues?: WorkoutInput

    workoutTypes: readonly { uuid: UUID, name: string }[]

    onSuccess: (uuid: UUID) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function CreateWorkoutForm(props: CreateWorkoutFormProps) {
    const { initialValues, workoutTypes, onCancel, onError, onSuccess } = props

    const [ createWorkout ] = useCreateWorkoutMutation()

    const onSubmit = async (input: WorkoutInput) => {
        return await executeMutation({
            mutation: () => createWorkout({ variables: { input: input } })
        })
    }

    const handleSuccess = async (data: CreateWorkoutMutation) => {
        onSuccess(data.createWorkout.uuid)
    }

    const handleError = async (error: any) => {
        Logger.error("Failed to create Workout", error)
        onError(error)
    }

    const date = DateTime.now();
    const initialValuesInner = initialValues ?? {
        date: date.toISODate(),
        description: "",
        publishingDate: date.startOf("day").toFormat(DATE_INPUT_FORMAT_STR),
        scoreBoards: [
            {
                uuid: null,
                isWhiteboardResultMode: true,
                name: null,
                order: 1,
                resultOrder: WorkoutResultOrder.Descending,
                resultType: WorkoutResultType.Repetition,
                submissionEnd: WorkoutFormHelper.getDefaultScoreboardSubmissionEnd(date).toFormat(DATE_INPUT_FORMAT_STR),
            }
        ],
        title: null,
        trainerNotes: null,
        visibility: WorkoutVisibility.Member,
        workoutTypeUUID: workoutTypes.length > 0 ? workoutTypes[0].uuid : DEFAULT_UUID
    };

    return (
        <WorkoutForm initialValues={ initialValuesInner }
                     scoreBoardsWithResultCount={ [] }
                     workoutTypes={ workoutTypes }
                     onSubmit={ onSubmit }
                     onSuccess={ handleSuccess }
                     onError={ handleError }
                     onCancel={ onCancel } />
    )
}
