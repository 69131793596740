interface BuildNameArgs {
    username: string
    firstname?: string | null
    lastname?: string | null
}

function getPersonName(args: BuildNameArgs) {
    if (args.firstname && args.lastname) {
        return `${ args.firstname } ${ args.lastname }`
    } else {
        return args.username
    }
}

const Person = {
    buildName: getPersonName,
}

export default Person
