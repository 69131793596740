import { UUID } from "@/common/Types";
import { MenuButton } from "@/components/shared";
import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { CopyWorkoutItem } from "@/pages/workouts/[uuid]/_components/workoutMenu/CopyWorkoutItem";
import { DeleteWorkoutItem } from "@/pages/workouts/[uuid]/_components/workoutMenu/DeleteWorkoutItem";
import { UpdateWorkoutItem } from "@/pages/workouts/[uuid]/_components/workoutMenu/UpdateWorkoutItem";
import { Menu, Stack } from "@mantine/core";


interface WorkoutMenuProps {
    workoutUUID: UUID
}

export function WorkoutMenu(props: WorkoutMenuProps) {
    const { workoutUUID } = props

    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.WorkoutManager)) {
        return null
    }

    return (
        <Menu transitionProps={ { transition: "scale-y" } } position="bottom-end" withinPortal shadow="sm">
            <Menu.Target>
                <MenuButton />
            </Menu.Target>

            <Menu.Dropdown>
                <Stack gap={ 4 }>
                    <UpdateWorkoutItem workoutUUID={ workoutUUID } />
                    <CopyWorkoutItem workoutUUID={ workoutUUID } />
                    <DeleteWorkoutItem workoutUUID={ workoutUUID } />
                </Stack>
            </Menu.Dropdown>
        </Menu>
    )
}
