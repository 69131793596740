import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate"
import { useAuthentication } from "@/contexts/authentication";
import { MphGraphQlError } from "@/graphql/MphGraphQlError"
import { Routes } from "@/routing/Routes"
import { ApolloError } from "@apollo/client";
import { Button, Container, Group, Stack, Text, Title } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import * as Sentry from "@sentry/react";
import { IconArrowLeft } from "@tabler/icons-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, Navigate, useRevalidator, useRouteError } from "react-router-dom";
import classes from "./ErrorBoundary.module.scss"


export function ErrorBoundary() {
    Sentry.addBreadcrumb({ category: "message", message: "ErrorBoundary reached", level: "warning" })

    const error = useRouteError()
    console.log(error)

    return (
        <ErrorBoundaryPage error={ error } />
    )
}

interface ErrorBoundaryPageProps {
    error: unknown
}

export function ErrorBoundaryPage(props: ErrorBoundaryPageProps) {
    const { error } = props

    const { t } = useTranslation()

    if (error instanceof ApolloError) {
        if (error.graphQLErrors.filter(it => it.extensions && it.extensions["reason"] === MphGraphQlError.NOT_FOUND).length > 0) {
            Sentry.addBreadcrumb({ category: "message", message: "Redirect up", level: "warning" })
            return <Navigate to=".." relative="path" />
        }

        if (error.message === "Failed to fetch") {
            return (
                <ErrorContainer status={ ":(" }
                                title={ t("common:Error.Something went wrong") }
                                details={ t("common:Error.Seems like, there's trouble with your internet connection.") }
                                button={ <RefreshButton /> } />
            )
        }
    }

    if (isRouteErrorResponse(error)) {
        Sentry.captureMessage(`Route error. Status ${ error.status }`, { level: "error", extra: { error: error } })

        return (
            <ErrorContainer status={ ":(" }
                            title={ t("common:Error.Something went wrong") }
                            details={ t("common:Error.We couldn't load the data you requested.") }
                            button={ <BackButton /> } />
        )
    }

    Sentry.captureException(error)

    return (
        <ErrorContainer status=":("
                        title={ t("common:Error.Something went wrong") }
                        details={ t("common:Error.We'll take care of it. Please try again soon.") }
                        button={ <BackButton /> } />
    )
}


interface ErrorContainerProps {
    button: React.ReactNode
    details: Error | string | null
    status?: string
    title?: string | null
}

function ErrorContainer(props: ErrorContainerProps) {
    const { button, details, status, title } = props;

    return (
        <div className={ classes.container }>
            <Container>
                <Stack align="center">
                    <Text style={ { fontSize: 200 } } fw={ 900 } color="gray.2">{ status }</Text>

                    <Title ta="center">{ title }</Title>
                    <Text c="dimmed" size="lg" ta="center">
                        { details?.toString() }
                    </Text>

                    <Group justify="center">
                        { button }
                    </Group>
                </Stack>
            </Container>
        </div>
    )
}

function BackButton() {
    const { t } = useTranslation()
    const { navigateBack } = useMyPowerHourNavigate();

    return (
        <Button variant="outline" leftSection={ <IconArrowLeft /> } size="md" onClick={ () => navigateBack(Routes.ROOT) }>
            { t("common:Button.Back") }
        </Button>
    )
}

function RefreshButton() {
    const { t } = useTranslation()
    const { refresh } = useAuthentication()
    const revalidator = useRevalidator()

    const [ loading, setLoading ] = useState(false)
    const { start } = useTimeout(() => setLoading(false), 1000)

    const onRefresh = async () => {
        setLoading(true)
        await refresh()
        revalidator.revalidate()
        start()
    }

    return (
        <Button variant="outline" size="md" onClick={ onRefresh } loading={ loading }>
            { t("common:Button.Try again") }
        </Button>
    )
}
