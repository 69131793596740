import logger from "@/common/Logger"
import { useAuthentication } from "@/contexts/authentication";
import { InAppNotification, useLatestInAppNotificationsQuery } from "@/generated/graphql"
import { useDocumentVisibility, useInterval } from "@mantine/hooks";
import React, { PropsWithChildren, useContext, useEffect } from "react";


export interface InAppNotificationsContextType {
    hasUnseen: boolean
    unseenCount: number
    notifications: readonly InAppNotification[]
    refetch: () => void
}

const defaultValue: InAppNotificationsContextType = {
    hasUnseen: false,
    unseenCount: 0,
    notifications: [],
    refetch: () => {
    }
};

const InAppNotificationsContext = React.createContext(defaultValue);

export function InAppNotificationsContextProvider(props: PropsWithChildren) {
    const { children } = props

    const visibility = useDocumentVisibility()

    const { isAuthenticated } = useAuthentication()

    const { data, refetch, error } = useLatestInAppNotificationsQuery({
        skip: !(isAuthenticated && (("connection" in navigator) && navigator.onLine))
    })

    const interval = useInterval(refetch, 30000);

    useEffect(() => {
        if (visibility === "visible" && isAuthenticated && !interval.active) {
            void refetch()
            interval.start()
        }

        if (visibility === "hidden" || !isAuthenticated) {
            interval.stop()
        }

        return interval.stop()
    }, [ visibility, isAuthenticated, interval ]);

    if (error) {
        logger.warn("Failed to load InAppNotifications", error)
    }

    let context: InAppNotificationsContextType
    if (data) {
        const notifications = data.latestInAppNotifications.results.filter(it => it.message)
        const unseenCount = notifications.filter(it => !it.seen).length;
        context = {
            hasUnseen: unseenCount > 0,
            notifications: notifications,
            unseenCount: unseenCount,
            refetch: refetch
        }
    } else {
        context = defaultValue
    }

    return (
        <InAppNotificationsContext.Provider value={ context }>
            { children }
        </InAppNotificationsContext.Provider>
    );
}

export const useInAppNotificationsContext = () => useContext(InAppNotificationsContext);
