import { NavigationItem } from "@/components/navigation";
import { useMainLayoutOutletContext } from "@/layout/main/MainLayout"
import { NavbarItem } from "@/layout/shared/NavbarItem";
import { Box, Stack, Transition } from "@mantine/core";
import React from "react";
import classes from "./NavbarStyles.module.scss"


interface SideNavbarProps {
    items?: NavigationItem[],
    itemNodes?: React.ReactNode,
}

/**
 * Navbar will stop event propagation to avoid closing navigation via onClick handler on root element
 */
export function MobileNavbar(props: SideNavbarProps) {
    const { items, itemNodes } = props

    const outletContext = useMainLayoutOutletContext()

    return (
        <Transition mounted={ outletContext.isNavigationOpen } transition="slide-left" duration={ 150 }>
            { styles => (
                <Box component="nav" className={ classes.mobileRoot } style={ styles } onClick={ outletContext.closeNavigation }>
                    <nav className={ classes.mobileNavRoot } onClick={ e => e.stopPropagation() }>
                        <Stack gap="xs">
                            { itemNodes }
                            { items?.map(it => (<NavbarItem key={ it.labelKey } item={ it } />)) }
                        </Stack>
                    </nav>
                </Box>
            ) }
        </Transition>
    )
}
