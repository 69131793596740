import { MembershipDetails } from "@/components/memberships/details";
import { ContentCard } from "@/components/shared";
import { AttendanceTimeUnit, AttendanceType, BillingCycle, MembershipAgreementCommitment } from "@/generated/graphql";
import { Card } from "@mantine/core";


interface MembershipTemplateDetailsCardProps {
    organisation: {
        email: string | null
        city: string | null
        country: string | null
        name: string
        phone: string | null
        postalCode: string | null
        streetAddress: string | null
    },
    template: {
        attendanceRestriction: {
            count: number
            timeUnit: AttendanceTimeUnit
            type: AttendanceType
        }
        billing: {
            amount: number
            cycle: BillingCycle
            startDate?: string | null
        }
        commitment: MembershipAgreementCommitment
        description: string | null
        endDate?: string | null
        startDate?: string | null
        renewalPeriod: string | null
        runtime: string | null
        specialTerminationUntil?: string | null
        terminationPeriod: string | null
    }
}

export function MembershipTemplateDetailsCard(props: MembershipTemplateDetailsCardProps) {
    const { organisation, template } = props

    return (
        <ContentCard>
            <Card.Section inheritPadding py="sm">
                <MembershipDetails membership={ template }
                                   organisation={ organisation } />
            </Card.Section>
        </ContentCard>
    )
}
