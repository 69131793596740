import { Button } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import React, { forwardRef } from "react";


interface MenuButtonProps extends React.ComponentPropsWithoutRef<"button"> {
}

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>((props, ref) => {
    return (
        <Button ref={ ref } variant="default" { ...props } p="xs"
                id="menu-button" data-umami-event="menu-button">
            <IconDots />
        </Button>
    )
})
