import { useOrganisation } from "@/contexts/organisation";
import { usePWA } from "@/contexts/pwa";
import { Alert, Button, Group, Stack, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";


export function PWAInstallAlert() {
    const { t } = useTranslation()
    const [ isClosed, setIsClosed ] = useLocalStorage({ key: "pwa-install-alert-close", defaultValue: "false" })

    const { isInstallable, triggerInstall } = usePWA()
    const { organisation } = useOrganisation()

    if (!isInstallable || isClosed === "true") {
        return null
    }

    const onClose = () => {
        setIsClosed("true")
    }

    return (
        <Alert title={ t("components:Shared.PWAInstallAlert.Add ORGANISATION to home screen", { organisation: organisation.name }) }
               color="blue"
               withCloseButton
               closeButtonLabel="Dismiss"
               onClose={ onClose }>

            <Stack>
                <Text size="sm">
                    { t("components:Shared.PWAInstallAlert.You can this app to your home screen") }
                </Text>
                <Group justify="flex-end">
                    <Button variant="outline" color="blue" onClick={ triggerInstall } size="sm" data-umami-event="pwa-install-alert-add">
                        { t("common:Button.Add") }
                    </Button>
                </Group>
            </Stack>

        </Alert>
    )
}
