import { useRootLink } from "@/components/hooks/UseRootLink";
import { useDeviceSize } from "@/contexts/device-size";
import { useOrganisation } from "@/contexts/organisation";
import { Routes } from "@/routing/Routes"
import { Box, Burger, Flex, rem, Title, UnstyledButton } from "@mantine/core";
import { Link, useMatch } from "react-router-dom"
import classes from "./MainHeader.module.scss"


interface HeaderProps {
    isNavigationOpen: boolean
    onBurgerClick: () => void
}

export default function MainHeader(props: HeaderProps) {
    const { onBurgerClick, isNavigationOpen } = props;

    const { isMobile, p } = useDeviceSize()
    const { organisation } = useOrganisation()
    const root = useRootLink()

    // show burger only in administration on mobile
    const showBurger = useMatch({ path: Routes.ADMIN, end: false }) !== null && isMobile;

    if (!isMobile) {
        return null
    }

    return (
        <div className={ classes.header }>
            <Box px={ p } h="100%">
                <Flex justify="flex-start" align="center" h="100%">
                    <Flex style={ { flexGrow: 1, overflow: "hidden" } }>
                        <UnstyledButton component={ Link } to={ root }>
                            <Title order={ 1 } size={ rem("20px") } fw={ 600 } style={ { whiteSpace: "nowrap" } }>
                                { organisation.name }
                            </Title>
                        </UnstyledButton>
                    </Flex>

                    { showBurger && (
                        <Box style={ { flexGrow: 0, lineHeight: 0 } }>
                            <Burger opened={ isNavigationOpen } onClick={ onBurgerClick } size="sm" aria-label="Open navigation" />
                        </Box>
                    ) }
                </Flex>
            </Box>
        </div>
    );
}
