import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types"
import { EventRegistrationDenyReason, EventState, IsEventRegistrationAllowedResponse } from "@/generated/graphql";
import { CreateRegistrationAllowedCard } from "@/pages/classes/[uuid]/_components/createRegistrationCard/CreateRegistrationAllowedCard";
import { CreateRegistrationNotAllowedCard } from "@/pages/classes/[uuid]/_components/createRegistrationCard/CreateRegistrationNotAllowedCard";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


interface CreateRegistrationCardContainerProps {
    event: { eventUUID: UUID, state: EventState }
    isRegistrationAllowed: IsEventRegistrationAllowedResponse
}

export function CreateRegistrationCardContainer(props: CreateRegistrationCardContainerProps) {
    const { event, isRegistrationAllowed } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    if ([ EventState.Cancelled, EventState.Finished ].includes(event.state)) {
        return null
    }

    if (isRegistrationAllowed.denyReason === EventRegistrationDenyReason.IsRegistered) {
        return null
    }

    if (isRegistrationAllowed.denyReason === EventRegistrationDenyReason.EventNotRegistrable) {
        return null
    }

    if (isRegistrationAllowed.isAllowed) {
        if (isRegistrationAllowed.expectedRegistrationState === null) {
            Logger.error(`Invalid state of IsRegistrationAllowed`, event.eventUUID, isRegistrationAllowed)
            return null
        }

        const onSuccess = () => {
            showSuccessNotification(t("components:Events.CreateRegistration.Registration created"))
            revalidator.revalidate()
        }

        const onError = () => {
            showErrorNotification({ message: t("components:Events.CreateRegistration.Failed to create registration") })
        }

        return <CreateRegistrationAllowedCard expectedRegistrationState={ isRegistrationAllowed.expectedRegistrationState }
                                              event={ event }
                                              onSuccess={ onSuccess }
                                              onError={ onError } />
    } else {
        if (isRegistrationAllowed.denyReason === null) {
            Logger.error(`Invalid state of IsRegistrationAllowed`, JSON.stringify(isRegistrationAllowed))
            return null
        }

        return <CreateRegistrationNotAllowedCard denyReason={ isRegistrationAllowed.denyReason } />
    }
}
