import { ContentCard } from "@/components/shared";
import { Card, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";


export function PaymentCard() {
    const { t } = useTranslation()

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Memberships.PaymentCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <Text>
                    { t("components:Memberships.PaymentCard.Payment via platform not possible") }
                </Text>
            </Card.Section>
        </ContentCard>
    )
}
