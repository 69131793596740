import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { DeviceSize, useDeviceSize } from "@/contexts/device-size";
import { GraphQLClient } from "@/contexts/graphql";
import { MemberPermission, WorkoutDocument, WorkoutQuery, WorkoutQueryVariables } from "@/generated/graphql";
import { ScoreBoards } from "@/pages/workouts/[uuid]/_components/ScoreBoards";
import { WeightCalculatorButton } from "@/pages/workouts/[uuid]/_components/WeightCalculatorButton";
import { WorkoutMenu } from "@/pages/workouts/[uuid]/_components/workoutMenu/WorkoutMenu";
import { ApolloQueryResult } from "@apollo/client";
import { Grid, Group, Stack, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";


export function WorkoutPageLoader({ params }: LoaderFunctionArgs) {
    if (!params.uuid) {
        throw Error("no uuid set")
    }

    return GraphQLClient.query<WorkoutQuery, WorkoutQueryVariables>({
        query: WorkoutDocument,
        variables: { uuid: params.uuid },
    })
}

export function WorkoutPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<WorkoutQuery>

    const { t } = useTranslation()
    const { deviceSize } = useDeviceSize()
    const { hasPermission } = useAuthentication()

    const workout = queryResult.data.workout
    const title = workout.workoutType?.name ?? workout.title ?? "Workout"
    const date = DateTime.fromISO(workout.date).toFormat("EEE dd.MM")

    const trainerNotes = hasPermission(MemberPermission.WorkoutManager) && workout.trainerNotes && (
        <>
            <Text fw={ 600 } size="lg">{ t("pages:WorkoutPage.TrainerNotes") }</Text>
            <ContentCard>
                <Text>
                    { workout.trainerNotes }
                </Text>
            </ContentCard>
        </>
    )

    return (
        <>
            <MetaTitle title={ `${ date } ${ title }` } />

            <Stack>
                <Group justify="space-between" align="center">
                    <PageTitleWithBack title={ title } />

                    <Group wrap="nowrap">
                        <WeightCalculatorButton />
                        <WorkoutMenu workoutUUID={ workout.uuid } />
                    </Group>
                </Group>

                {
                    deviceSize !== DeviceSize.XXLARGE && (
                        <Stack>
                            <ContentCard>
                                <Text>
                                    { workout.description }
                                </Text>
                            </ContentCard>

                            { trainerNotes }

                            {
                                workout.scoreBoards.length > 0 && (
                                    <ContentCard>
                                        <ScoreBoards scoreBoards={ workout.scoreBoards } />
                                    </ContentCard>
                                )
                            }
                        </Stack>
                    )
                }

                {
                    deviceSize === DeviceSize.XXLARGE && (
                        <Grid>
                            <Grid.Col span={ workout.scoreBoards.length > 0 ? 8 : 12 }>
                                <Stack>
                                    <ContentCard>
                                        <Text>
                                            { workout.description }
                                        </Text>
                                    </ContentCard>

                                    { trainerNotes }
                                </Stack>
                            </Grid.Col>

                            {
                                workout.scoreBoards.length > 0 && (
                                    <Grid.Col span={ 4 }>
                                        <ContentCard>
                                            <ScoreBoards scoreBoards={ workout.scoreBoards } />
                                        </ContentCard>
                                    </Grid.Col>
                                )
                            }
                        </Grid>
                    )
                }
            </Stack>
        </>
    )
}
