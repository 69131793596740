import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { useDeleteWorkoutResultMutation, useUpdateWorkoutResultMutation, WorkoutResultInput, WorkoutResultType } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { WorkoutResultForm } from "./WorkoutResultForm";


interface UpdateWorkoutResultFormProps {
    uuid: UUID
    initialValues: WorkoutResultInput

    resultType: WorkoutResultType
    resultCategories: readonly { uuid: UUID, name: string }[]

    onSubmitSuccess: (uuid: UUID) => void
    onSubmitError: (error: any) => void
    onDeleteSuccess: () => void
    onDeleteError: (error: any) => void
}

export function UpdateWorkoutResultForm(props: UpdateWorkoutResultFormProps) {
    const { uuid, initialValues, resultType, resultCategories, onSubmitSuccess, onSubmitError, onDeleteSuccess, onDeleteError } = props

    const [ updateWorkoutResult ] = useUpdateWorkoutResultMutation()
    const [ deleteWorkoutResult ] = useDeleteWorkoutResultMutation()

    const onSubmit = async (input: WorkoutResultInput) => {
        return await executeMutation({
                mutation: () => updateWorkoutResult({
                    variables: { uuid: uuid, input: input }
                }),
                onSuccess: data => {
                    onSubmitSuccess(data.updateWorkoutResult.uuid);
                },
            onError: (error) => {
                    Logger.error("Failed to update WorkoutResult", error)
                    onSubmitError(error)
                }
            }
        )
    }

    const onDelete = async () => {
        await executeMutation({
                mutation: () => deleteWorkoutResult({
                    variables: { uuid: uuid }
                }),
                onSuccess: () => {
                    onDeleteSuccess()
                },
            onError: (error) => {
                    Logger.error("Failed to delete WorkoutResult", error)
                    onDeleteError(error)
                }
            }
        )
    }

    return (
        <WorkoutResultForm initialValues={ initialValues }
                           resultCategories={ resultCategories }
                           resultType={ resultType }
                           onSubmit={ onSubmit }
                           onDelete={ onDelete } />
    )
}
