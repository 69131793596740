import { ReactionEmojiMap } from "@/components/workouts/result-reaction";
import { InAppNotification } from "@/generated/graphql"
import { Routes } from "@/routing/Routes"
import { MantineSize } from "@mantine/core";
import { Trans } from "react-i18next";
import { generatePath } from "react-router-dom";
import { InAppNotificationItemLinked } from "./InAppNotificationItemLinked"
import { WorkoutResultAddedInAppNotification, WorkoutResultReactionAddedInAppNotification } from "./types"


interface InAppNotificationItemProps {
    inAppNotification: InAppNotification
    messageFontSize?: MantineSize
}

export function InAppNotificationItem(props: InAppNotificationItemProps) {
    const { inAppNotification, messageFontSize } = props

    function resolveWorkoutResultAdded(inAppNotification: InAppNotification) {
        if (inAppNotification.message == null) return null

        const message = JSON.parse(inAppNotification.message) as WorkoutResultAddedInAppNotification

        return (
            <InAppNotificationItemLinked messageFontSize={ messageFontSize }
                                         notification={ inAppNotification }
                                         to={ generatePath(Routes.WORKOUTS_BY_ID, { uuid: message.workoutUUID }) }>
                <Trans i18nKey="components:Core.InAppNotifications.WORKOUT_RESULT_ADDED"
                       values={ { person: message.creatorUserName, workout: message.workoutTitle } }
                       shouldUnescape={ true } />
            </InAppNotificationItemLinked>
        )
    }

    function resolveWorkoutResultReactionAdded(inAppNotification: InAppNotification) {
        if (inAppNotification.message == null) return null

        const message = JSON.parse(inAppNotification.message) as WorkoutResultReactionAddedInAppNotification

        return (
            <InAppNotificationItemLinked messageFontSize={ messageFontSize }
                                         notification={ inAppNotification }
                                         to={ generatePath(Routes.WORKOUTS_BY_ID, { uuid: message.workoutUUID }) }>
                <Trans i18nKey="components:Core.InAppNotifications.WORKOUT_RESULT_REACTION_ADDED"
                       values={ { person: message.creatorUserName, reaction: ReactionEmojiMap[message.workoutResultReactionType], workout: message.workoutTitle } }
                       shouldUnescape={ true } />
            </InAppNotificationItemLinked>
        )
    }

    function resolve(inAppNotification: InAppNotification) {
        switch (inAppNotification.type) {
            case "WORKOUT_RESULT_ADDED":
                return resolveWorkoutResultAdded(inAppNotification);
            case "WORKOUT_RESULT_REACTION_ADDED":
                return resolveWorkoutResultReactionAdded(inAppNotification);
            default:
                return null
        }
    }

    return resolve(inAppNotification)
}
