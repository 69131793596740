import { ContentCard, MMarkdown, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import { useOrganisation } from "@/contexts/organisation";
import { TermsOfServicePageDocument, TermsOfServicePageQuery, TermsOfServicePageQueryVariables } from "@/generated/graphql";
import { UpdateTermsOfServiceButton } from "@/pages/info/_components";
import { ApolloQueryResult } from "@apollo/client";
import { Alert, Group, Stack } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";


export function TermsOfServicePageLoader() {
    return GraphQLClient.query<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>({
        query: TermsOfServicePageDocument,
    })
}

export function TermsOfServicePage() {
    const queryResult = useLoaderData() as ApolloQueryResult<TermsOfServicePageQuery>

    const { t } = useTranslation()
    const { organisation } = useOrganisation()

    return (
        <>
            <MetaTitle title={ t("pages:TermsOfServicePage.Title") } />

            <Stack>
                <Group justify="space-between">
                    <PageTitleWithBack title={ t("pages:TermsOfServicePage.Title") } />

                    <UpdateTermsOfServiceButton />
                </Group>

                <ContentCard>
                    <Alert icon={ <IconInfoCircle /> }>
                        { t("pages:TermsOfServicePage.These are the ToS of Organisation", { name: organisation.name }) }
                    </Alert>

                    <MMarkdown text={ queryResult.data.organisation.termsOfServiceHtml ?? "" } textAlign="justify" />
                </ContentCard>
            </Stack>
        </>
    )
}
