import { useNavigationItems } from "@/components/navigation";
import { useDeviceSize } from "@/contexts/device-size";
import { DesktopNavbar } from "@/layout/shared/DesktopNavbar";


export function MyNavbar() {
    const navigation = useNavigationItems()
    const { isMobile } = useDeviceSize()

    if (isMobile) {
        return null
    }

    return <DesktopNavbar items={ navigation.sets.my.navbar } showBack={ true } />
}
