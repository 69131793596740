import { useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { DEFAULT_UUID } from "@/common/Object";
import { UUID } from "@/common/Types";
import { ContentCard, MMarkdown } from "@/components/shared";
import { PersonalInformationValue } from "@/generated/graphql";
import { Anchor, Button, Card, Stack, Text } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { Trans, useTranslation } from "react-i18next";
import { MembershipCheckoutFormSection } from "./MembershipCheckoutFormSection";
import { MembershipCheckoutModeFormSection } from "./MembershipCheckoutModeFormSection";


interface MembershipCheckoutFormProps {
    myMemberships: readonly {
        uuid: UUID
        name: string
        isFollowUpAllowed: boolean
    }[]
    onSubmit: (data: MembershipCheckoutFormData) => Promise<void>
    organisation: {
        dataProtectionHtml: string | null
        name: string
        termsOfServiceHtml: string | null
    }
    person: {
        uuid: string
        birthday: string | null
        city: string | null
        country: string | null
        firstname: string | null
        lastname: string | null
        phone: string | null
        postalCode: string | null
        streetAddress: string | null
        username: string
    }
    template: {
        uuid: UUID
        isAllowedForFollowUp: boolean
        requiredPersonalInformationValues: readonly PersonalInformationValue[]
    }
}

export interface MembershipCheckoutFormData {
    checkoutMode: null | UUID

    birthday: string
    city: string
    country: string
    firstname: string
    lastname: string
    phone: string
    postalCode: string
    streetAddress: string
}

export function MembershipCheckoutForm(props: MembershipCheckoutFormProps) {
    const { myMemberships, organisation, onSubmit, person, template } = props

    const { t } = useTranslation()

    const personValues = {
        birthday: person.birthday ?? "",
        city: person.city ?? "",
        country: person.country ?? "",
        firstname: person.firstname ?? "",
        lastname: person.lastname ?? "",
        phone: person.phone ?? "",
        postalCode: person.postalCode ?? "",
        streetAddress: person.streetAddress ?? "",
    };

    const myMembershipsAllowedForFollowUp = myMemberships.filter(it => it.isFollowUpAllowed)
    const canSwitch = template.isAllowedForFollowUp && myMembershipsAllowedForFollowUp.length > 0

    const form = useMyPowerHourForm<MembershipCheckoutFormData>({
        initialValues: {
            checkoutMode: canSwitch ? null : DEFAULT_UUID,
            ...personValues
        },
        validate: {
            checkoutMode: (value: string | null) => value == null,
            birthday: (value: string | null) => template.requiredPersonalInformationValues.includes(PersonalInformationValue.Birthday) && value === null
        }
    })

    const showToS = () => {
        openModal({
            title: <Text size="lg" fw={ 600 }>{ t("common:Misc.TermsOfService") }</Text>,
            children: (
                <Stack style={ { overflow: "auto", maxHeight: "65vh" } }>
                    <MMarkdown text={ organisation.termsOfServiceHtml ?? "" } />
                </Stack>
            ),
            size: 800,
        })
    }

    const showDataProtection = () => {
        openModal({
            title: <Text size="lg" fw={ 600 }>{ t("common:Misc.DataProtection") }</Text>,
            children: (
                <Stack style={ { overflow: "auto", maxHeight: "65vh" } }>
                    <MMarkdown text={ organisation.dataProtectionHtml ?? "" } />
                </Stack>
            ),
            size: 800,
        })
    }

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <ContentCard>
                {
                    canSwitch && (
                        <Card.Section withBorder inheritPadding py="sm" hidden={ !canSwitch }>
                            <MembershipCheckoutModeFormSection form={ form }
                                                               myMembershipsAllowedForFollowUp={ myMembershipsAllowedForFollowUp } />
                        </Card.Section>
                    )
                }

                <Card.Section withBorder inheritPadding py="sm">
                    <Text fw={ 600 } size="lg">
                        { t("components:Memberships.Checkout.Contact Information") }
                    </Text>
                </Card.Section>

                <Card.Section inheritPadding py="sm">
                    <MembershipCheckoutFormSection form={ form }
                                                   requiredPersonalInformation={ template.requiredPersonalInformationValues } />
                </Card.Section>

                <Card.Section inheritPadding py="sm">
                    <Stack gap="xs">
                        <Text size="sm" c="dimmed" ta="center">
                            <Trans i18nKey="components:Memberships.Checkout.Accept ToS and DataProtection" values={ { name: organisation.name } }>
                                Mit der Anfrage akzeptierst du die <Anchor onClick={ showToS } fw={ 500 }>AGBs</Anchor> und
                                <Anchor onClick={ showDataProtection } fw={ 500 }>Datenschutzerklärung</Anchor> von NAME.
                            </Trans>
                        </Text>
                        <Button size="md" type="submit" data-testid="submit" loading={ form.isSubmitting }>
                            { t("pages:MembershipsByIdPage.Book now") }
                        </Button>
                    </Stack>
                </Card.Section>
            </ContentCard>
        </form>
    )
}
