import { RegistrationState } from "@/generated/graphql";
import { EventRegistrationList, EventRegistrationType } from "@/pages/classes/[uuid]/_components/eventRegistrationList/EventRegistrationList";


export interface EventRegistrationListContainerProps {
    availableSpots: number | null
    eventRegistrations: readonly EventRegistrationType[]
}

export function EventRegistrationListContainer(props: EventRegistrationListContainerProps) {
    const { availableSpots, eventRegistrations } = props

    if (availableSpots === null) {
        return null
    }

    const confirmedRegistrations: EventRegistrationType[] = []
    const cancelledRegistrations: EventRegistrationType[] = []
    const pendingRegistrations: EventRegistrationType[] = []
    const penaltyRegistrations: EventRegistrationType[] = []

    eventRegistrations.forEach((registration) => {
        switch (registration.state) {
            case RegistrationState.WaitList:
                pendingRegistrations.push(registration)
                break
            case RegistrationState.WaitListPenalty:
                penaltyRegistrations.push(registration)
                break
            case RegistrationState.Cancelled:
            case RegistrationState.Missed:
                cancelledRegistrations.push(registration)
                break
            default:
                confirmedRegistrations.push(registration)
        }
    })

    const registrationCount = confirmedRegistrations.length + pendingRegistrations.length

    const placeholder = () => {
        const spots = availableSpots
        if (!spots) {
            return [ null ]
        }

        const placeholder = []
        for (let i = confirmedRegistrations.length; i < spots; i++) {
            placeholder.push(null)
        }

        return placeholder
    }

    const allRegistrations: (EventRegistrationType | null)[] = [
        ...confirmedRegistrations,
        ...placeholder(),
        ...pendingRegistrations,
        ...penaltyRegistrations,
        ...cancelledRegistrations,
    ]

    return (
        <EventRegistrationList availableSpots={ availableSpots }
                               allRegistrations={ allRegistrations }
                               registrationCount={ registrationCount } />
    )
}
