import { WeightCalculatorWithBenchmarkSelector } from "@/components/workouts/weight-calculator/WeightCalculatorWithBenchmarkSelector"
import { useAuthentication } from "@/contexts/authentication";
import { useDeviceSize } from "@/contexts/device-size";
import { ActionIcon, Button, Group, Stack, Text } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { IconCalculator } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


const WEIGHT_CALCULATOR_MODAL_ID = "weight-calculator"

export function WeightCalculatorButton() {
    const { t } = useTranslation()

    const { isMobile } = useDeviceSize()
    const { isAuthenticated } = useAuthentication()

    if (!isAuthenticated) {
        return null
    }

    const onClick = () => {
        openModal({
            modalId: WEIGHT_CALCULATOR_MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("components:Workouts.WeightCalculator.Title") }</Text>,
            size: isMobile ? "calc(100vw - 32px)" : "640px",
            children: (
                <Stack>
                    <WeightCalculatorWithBenchmarkSelector />
                    <Group justify="flex-end">
                        <Button variant="default" onClick={ () => closeModal(WEIGHT_CALCULATOR_MODAL_ID) }>
                            { t("common:Button.Close") }
                        </Button>
                    </Group>
                </Stack>
            ),
        })
    }

    return (
        <ActionIcon variant="default" size="lg" onClick={ onClick }
                    id="workout-weight-calculator-button" data-umami-event="workout-weight-calculator-button">
            <IconCalculator />
        </ActionIcon>
    )
}
