import { UUID } from "@/common/Types";
import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { AdminItems } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/AdminItems";
import { UpdateItem } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/UpdateItem";
import { ActionIcon, Menu, Stack } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";

interface RegistrationMenuProps {
    eventRegistration: {
        uuid: UUID
        memberUUID: string | null
        name: string
        comment: string | null
    }
}

export function EventRegistrationMenu(props: RegistrationMenuProps) {
    const { eventRegistration } = props

    const { hasPermission, me } = useAuthentication()

    if (hasPermission(MemberPermission.EventRegistrationManager) || eventRegistration.memberUUID === me?.memberUUID) {
        return (
            <Menu transitionProps={ { transition: "scale-y" } } position="bottom-end" withinPortal shadow="sm">
                <Menu.Target>
                    <ActionIcon variant="subtle" color="gray" size="sm">
                        <IconDotsVertical />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Stack gap={ 4 }>
                        <UpdateItem eventRegistration={ eventRegistration } />
                        { hasPermission(MemberPermission.EventRegistrationManager) && <AdminItems eventRegistration={ eventRegistration } /> }
                    </Stack>
                </Menu.Dropdown>
            </Menu>
        )
    }

    return null
}
