import { range } from "@/common/Array";
import { DateMode, EventLineItemLinked } from "@/components/events/event";
import { Center, Divider, Paper, Stack, Text } from "@mantine/core";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DayColumnHeading } from "./DayColumnHeading"
import { groupEventsByDate } from "./Helper"
import { CalendarEvent } from "./types"


export interface EventCalendarMobileProps {
    events: CalendarEvent[]
    from: DateTime
}

export function EventCalendarMobile(props: EventCalendarMobileProps) {
    const { events, from } = props

    const weekdays = range(0, 6).map(it => from.plus({ day: it }))
    const eventsByDate = groupEventsByDate(events, from)

    return (
        <Stack gap="xs">
            {
                weekdays.map(day => (
                    <DayWithEvents key={ day.toISODate() }
                                   day={ day }
                                   events={ eventsByDate.filter(it => it.date.toISODate() === day.toISODate()).flatMap(it => it.events) } />
                ))
            }
        </Stack>
    )
}

interface DayWithEventsProps {
    day: DateTime
    events: CalendarEvent[]
}

function DayWithEvents(props: DayWithEventsProps) {
    const { day, events } = props

    return (
        <>
            <DayColumnHeading dateFormat="long" day={ day } />

            <Paper shadow="xs" style={ { overflow: "hidden" } }>
                <EventsStack day={ day } events={ events } />
            </Paper>
        </>
    )
}

interface EventsStackProps {
    day: DateTime
    events: CalendarEvent[]
}

function EventsStack(props: EventsStackProps) {
    const { day, events } = props

    const { t } = useTranslation()

    if (events.length === 0) {
        return (
            <Center p="sm">
                <Text>{ t("components:Events.EventCalendar.No Events today") }</Text>
            </Center>
        )
    }

    return (
        <>
            {
                events.map((event, i) => (
                    <React.Fragment key={ event.uuid }>
                        <EventLineItemLinked dateMode={ DateMode.RELATIVE }
                                             day={ day }
                                             event={ event } />

                        { i < events.length - 1 && <Divider color="gray.2" /> }
                    </React.Fragment>
                )) }
        </>
    )
}
