import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { BenchmarkResultInput, BenchmarkResultType, useDeleteBenchmarkResultMutation, useUpdateBenchmarkResultMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { BenchmarkResultForm } from "./BenchmarkResultForm";

interface UpdateBenchmarkResultFormProps {
    uuid: UUID
    initialValues: BenchmarkResultInput

    resultType: BenchmarkResultType

    onSubmitSuccess: (uuid: UUID) => void
    onSubmitError: (error: any) => void
    onDeleteSuccess: () => void
    onDeleteError: (error: any) => void
}

export function UpdateBenchmarkResultForm(props: UpdateBenchmarkResultFormProps) {
    const { uuid, initialValues, resultType, onSubmitSuccess, onSubmitError, onDeleteSuccess, onDeleteError } = props

    const [ updateBenchmarkResult ] = useUpdateBenchmarkResultMutation()
    const [ deleteBenchmarkResult ] = useDeleteBenchmarkResultMutation()

    const onSubmit = async (input: BenchmarkResultInput) => {
        return await executeMutation({
                mutation: () => updateBenchmarkResult({
                    variables: { uuid: uuid, input: input }
                }),
                onSuccess: data => {
                    onSubmitSuccess(data.updateBenchmarkResult.uuid)
                },
            onError: (error) => {
                    Logger.error("Failed to update BenchmarkResult", error)
                    onSubmitError(error)
                }
            }
        )
    }

    const onDelete = async () => {
        await executeMutation({
                mutation: () => deleteBenchmarkResult({
                    variables: { uuid: uuid }
                }),
                onSuccess: () => {
                    onDeleteSuccess()
                },
            onError: (error) => {
                    Logger.error("Failed to delete BenchmarkResult", error)
                    onDeleteError(error)
                }
            }
        )
    }

    return (
        <BenchmarkResultForm initialValues={ initialValues }
                             resultType={ resultType }
                             onSubmit={ onSubmit }
                             onDelete={ onDelete } />
    )
}
