import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Menu } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface UpdateEventItemProps {
    eventUUID: UUID
}

export function UpdateEventItem(props: UpdateEventItemProps) {
    const { eventUUID } = props

    const { t } = useTranslation()

    return (
        <Menu.Item component={ Link } to={ generatePath(Routes.CLASSES_BY_ID_UPDATE, { uuid: eventUUID }) }
                   leftSection={ <IconEdit size={ 18 } /> } id="update-event-menu-item" data-umami-event="update-event-menu-item">
            { t("components:Events.UpdateEvent.Update event") }
        </Menu.Item>
    )
}
