import Logger from "@/common/Logger";
import { UUID } from "@/common/Types";
import { BenchmarkForm } from "@/components/workouts/benchmark/BenchmarkForm";
import { BenchmarkInput, useUpdateBenchmarkMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";

interface UpdateBenchmarkFormProps {
    uuid: UUID
    initialValues: BenchmarkInput

    hasResults: boolean
    onSuccess: (uuid: UUID) => void
    onError: (error: any) => void
    onCancel: () => void
}

export function UpdateBenchmarkForm(props: UpdateBenchmarkFormProps) {
    const { uuid, initialValues, hasResults, onSuccess, onError, onCancel } = props

    const [ updateBenchmark ] = useUpdateBenchmarkMutation()

    const onSubmit = async (input: BenchmarkInput) => {
        return await executeMutation({
                mutation: () => updateBenchmark({
                    variables: { uuid: uuid, input: input }
                }),
                onSuccess: data => {
                    onSuccess(data.updateBenchmark.uuid);
                },
            onError: (error) => {
                    Logger.error("Failed to update Benchmark", error)
                    onError(error)
                }
            }
        )
    }

    return (
        <BenchmarkForm input={ initialValues }
                       hasResults={ hasResults }
                       onSubmit={ onSubmit }
                       onCancel={ onCancel } />
    )
}
