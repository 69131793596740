import { MemberPermission } from "@/generated/graphql";

/**
 * Simple state to be used in react-router loaders.
 * Can be revisited when middleware arrived.
 */
const RoutingStorage = (() => {
    let isAuthenticated = false
    let isMember = false
    let isMobile = false
    let memberPermission: MemberPermission[] = []

    return {
        isAuthenticated: () => isAuthenticated,
        setIsAuthenticated: (value: boolean) => {
            isAuthenticated = value
        },

        isMember: () => isMember,
        setIsMember: (value: boolean) => {
            isMember = value
        },

        isMobile: () => isMobile,
        setIsMobile: (value: boolean) => {
            isMobile = value
        },

        hasPermission: (value: MemberPermission) => memberPermission.includes(value),
        setPermission: (value: readonly MemberPermission[]) => {
            memberPermission = []
            value.forEach(it => memberPermission.push(it))
        }
    }
})()

export default RoutingStorage