export function toNullIfEmpty(value: string | undefined): string | null {
    if (value === undefined) {
        return null
    }

    if (value.length === 0) {
        return null
    }

    return value
}