import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { EventState, IsEventRegistrationCancellationAllowedResponse, useCancelEventRegistrationMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { CancelRegistrationCard } from "@/pages/classes/[uuid]/_components/cancelRegistrationCard/CancelRegistrationCard";
import { Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


interface CancellationCardContainerProps {
    event: { state: EventState }
    isCancellationAllowed: IsEventRegistrationCancellationAllowedResponse
    myRegistration: { uuid: UUID } | null
}

export function CancelRegistrationCardContainer(props: CancellationCardContainerProps) {
    const { event, isCancellationAllowed, myRegistration } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()
    const [ cancelEventRegistration ] = useCancelEventRegistrationMutation()

    if (![ EventState.Pending, EventState.Confirmed, EventState.Started ].includes(event.state)) {
        return null
    }

    if (myRegistration === null) {
        return null
    }

    if (!isCancellationAllowed.isAllowed) {
        return null
    }

    if (isCancellationAllowed.isLateCancellation === null) {
        Logger.error(`Invalid state of IsCancellationAllowed`, isCancellationAllowed)
        return null
    }

    const onCancel = () => {
        openConfirmModal({
            title: <Text fw={ 600 } size="lg">{ t("components:Events.CancelRegistration.Cancel registration") }</Text>,
            children: t("components:Events.CancelRegistration.Do you want to cancel your registration"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Cancel Registration") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                        mutation: () => cancelEventRegistration({
                            variables: { uuid: myRegistration.uuid, }
                        }),
                        onSuccess: () => {
                            showSuccessNotification(t("components:Events.CancelRegistration.Registration cancelled"))
                            revalidator.revalidate()
                        },
                        onError: (error) => {
                            Logger.error("Failed to create EventRegistration", error)
                            showErrorNotification({ message: t("components:Events.CancelRegistration.Failed to cancel registration") })
                        }
                    }
                )
            }
        })
    }

    return <CancelRegistrationCard isLateCancellation={ isCancellationAllowed.isLateCancellation } onCancel={ onCancel } />
}
