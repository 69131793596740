import { useNavigationItems } from "@/components/navigation";
import { Me, useAuthentication } from "@/contexts/authentication";
import { Routes } from "@/routing/Routes"
import { Avatar, Divider, Group, SimpleGrid, Text, UnstyledButton } from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./BottomNavbar.module.scss"
import { BottomNavbarButton } from "./BottomNavbarButton"


export function UserSection() {
    const { me } = useAuthentication()

    if (me) {
        return <UserSectionAuthorized me={ me } />
    } else {
        return <UserSectionAnonymous />
    }
}

interface UserSectionAuthorizedProps {
    me: Me
}

function UserSectionAuthorized(props: UserSectionAuthorizedProps) {
    const { me } = props

    const navigation = useNavigationItems()

    return (
        <>
            <SimpleGrid cols={ 2 } spacing={ 4 }>
                <UserButton me={ me } />
                <BottomNavbarButton item={ navigation.items.logout } />
            </SimpleGrid>

            <Divider />
        </>
    )
}

function UserSectionAnonymous() {
    const navigation = useNavigationItems()

    return (
        <>
            <SimpleGrid cols={ 2 } spacing={ 4 }>
                <BottomNavbarButton item={ navigation.items.login } />
                <BottomNavbarButton item={ navigation.items.register } />
            </SimpleGrid>

            <Divider />
        </>
    )
}

interface UserButtonProps {
    me: Me
}

function UserButton(props: UserButtonProps) {
    const { me } = props

    return (
        <UnstyledButton className={ classes.userButton } component={ Link } to={ Routes.MY }>
            <Group justify="center" gap="xs">
                <Avatar color="brand" variant="filled">
                    { me.username.slice(0, 2) }
                </Avatar>
                <Text size="sm">
                    { me.username }
                </Text>
            </Group>
        </UnstyledButton>
    )
}
