import { SignInForm } from "@/components/account";
import { ContentCard, PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle"
import { useAuthentication } from "@/contexts/authentication";
import { Routes } from "@/routing/Routes"
import { Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";


export function CancelMembershipPage() {
    const { t } = useTranslation()

    const { isAuthenticated } = useAuthentication()

    if (isAuthenticated) {
        return <Navigate to={ Routes.MY_MEMBERSHIPS } replace={ true } />
    }

    return (
        <>
            <MetaTitle title={ t("pages:CancelMembershipPage.Title") } />

            <Stack>
                <PageTitle title={ t("pages:CancelMembershipPage.Title") } />

                <ContentCard>
                    <Stack>
                        <Text ta="center">
                            { t("pages:CancelMembershipPage.Please login") }
                        </Text>

                        <SignInForm />
                    </Stack>
                </ContentCard>
            </Stack>
        </>
    )
}
