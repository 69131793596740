import { toDateTime } from "@/common/Luxon";
import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Alert, Anchor, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface FollowUpHintProps {
    membershipRequest: {
        previousMembershipAgreement: {
            uuid: UUID
            name: string
            nextPaymentDate: string | null
        } | null
    }
}

export function FollowUpHint(props: FollowUpHintProps) {
    const { membershipRequest } = props

    const { t } = useTranslation()

    if (membershipRequest.previousMembershipAgreement === null) {
        return null
    }

    return (
        <Alert variant="light" title={ t("components:Memberships.FollowUpHint.Title") } icon={ <IconInfoCircle /> }>
            <Text>
                <Trans i18nKey="components:Memberships.FollowUpHint.This request is for a followup"
                       shouldUnescape={ true }
                       values={ {
                           date: toDateTime(membershipRequest.previousMembershipAgreement.nextPaymentDate)?.toFormat("dd.MM.yyyy"),
                           name: membershipRequest.previousMembershipAgreement.name
                       } }
                >
                    This requests executes a switch to another membership.
                    On confirmation, you'll switch from Membership
                    <Anchor fw={ 500 } component={ Link }
                            to={ generatePath(Routes.MY_MEMBERSHIPS_BY_ID, { uuid: membershipRequest.previousMembershipAgreement.uuid }) }>Name</Anchor>
                    into this membership.
                </Trans>
            </Text>
        </Alert>
    )
}
