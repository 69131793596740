import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useDeleteMembershipAgreementTemplateMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Routes } from "@/routing/Routes";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DeleteMembershipTemplateItemProps {
    membershipTemplate: {
        uuid: UUID
    }
}

export function DeleteMembershipTemplateItem(props: DeleteMembershipTemplateItemProps) {
    const { membershipTemplate } = props

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [ deleteTemplate ] = useDeleteMembershipAgreementTemplateMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("pages:MembershipsByIdPage.Delete Template") }</Text>,
            children: t("pages:MembershipsByIdPage.Do you want to delete this Template"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Delete") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => deleteTemplate({ variables: { uuid: membershipTemplate.uuid } }),
                    onSuccess: () => {
                        showSuccessNotification(t("pages:MembershipsByIdPage.Template deleted"))
                        navigate(Routes.MEMBERSHIPS)
                    },
                    onError: (error) => {
                        Logger.error("Failed to delete Template", error)
                        showErrorNotification({ message: t("pages:MembershipsByIdPage.Failed to delete Template") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } color="red" leftSection={ <IconTrash size={ 18 } /> }>
            { t("pages:MembershipsByIdPage.Delete Template") }
        </Menu.Item>
    )
}
