import { Box, Table } from "@mantine/core";

interface WeightScaleProps {
    weight: number
}

const percentages = [ 50, 60, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120 ]

export function WeightScale(props: WeightScaleProps) {
    const { weight } = props

    return (
        <Box style={ { overflowX: "auto" } }>
            <Table withColumnBorders verticalSpacing={ 4 } fs="xs">
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Td style={ { fontWeight: "bold" } }>%</Table.Td>
                        {
                            percentages.map(it => <Table.Td key={ it } align="right" style={ { fontWeight: "bold", whiteSpace: "nowrap" } }>{ it }</Table.Td>)
                        }
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Td style={ { fontWeight: "bold", whiteSpace: "nowrap" } }>kg</Table.Td>
                        {
                            percentages.map(it => <Table.Td key={ it } align="right"
                                                            style={ { whiteSpace: "nowrap" } }>{ Math.round((weight * (it / 100)) * 100) / 100 }</Table.Td>)
                        }
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        </Box>
    )
}
