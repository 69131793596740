import Logger from "@/common/Logger";
import { showErrorNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useAcceptMembershipRequestMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Button, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";


interface AcceptRequestButtonProps {
    membershipRequest: {
        uuid: UUID
    }
    onSuccess: (isActivated: boolean, membershipUUID?: UUID) => void
}

/**
 * Shown to Member
 */
export function AcceptRequestButton(props: AcceptRequestButtonProps) {
    const { membershipRequest, onSuccess } = props

    const { t } = useTranslation()

    const [ acceptRequest ] = useAcceptMembershipRequestMutation()

    const onAcceptRequest = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Memberships.AcceptMembershipRequestButton.Accept request") }</Text>,
            children: t("components:Memberships.AcceptMembershipRequestButton.Do you want to accept this request?"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Accept") },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => acceptRequest({ variables: { uuid: membershipRequest.uuid } }),
                    onSuccess: (data) => {
                        onSuccess(data.acceptMembershipRequest.isActivated, data.acceptMembershipRequest.membershipAgreement?.uuid)
                    },
                    onError: (error) => {
                        Logger.error("Failed to accept MembershipRequest", error)
                        showErrorNotification({ error })
                    }
                })
            }
        })
    }

    return (
        <Button onClick={ onAcceptRequest }>
            { t("common:Button.Accept") }
        </Button>
    )
}
