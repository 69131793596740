import { ContentCard } from "@/components/shared";
import { InAppNotificationsContextType, useInAppNotificationsContext } from "@/contexts/in-app-notification";
import { InAppNotification } from "@/generated/graphql"
import { Badge, Card, Center, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { InAppNotificationItem } from "./InAppNotificationItem"
import { MarkAllInAppNotificationsAsSeenButton } from "./MarkAllInAppNotificationsAsSeenButton"


export function MyInAppNotificationsCardContainer() {
    const context = useInAppNotificationsContext()

    return <MyInAppNotificationsCard context={ context } />
}

interface MyInAppNotificationsCardProps {
    context: InAppNotificationsContextType
}

/**
 * See also InAppNotificationsSection for BottomNavbarExtensions
 */
export function MyInAppNotificationsCard(props: MyInAppNotificationsCardProps) {
    const { context } = props

    const { t } = useTranslation()

    const { notifications, unseenCount, refetch } = context

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Group wrap="nowrap" justify="space-between">
                    <Group wrap="nowrap" gap={ 4 } align="center">
                        <Text fw={ 600 } size="lg">{ t("components:Core.MyInAppNotificationsCard.Title") }</Text>
                        { unseenCount > 0 && <Badge size="sm" circle>{ unseenCount }</Badge> }
                    </Group>
                    { unseenCount > 0 && <MarkAllInAppNotificationsAsSeenButton onSuccess={ refetch } /> }
                </Group>
            </Card.Section>

            <Card.Section inheritPadding py="sm" h="100%" mah={ 300 } style={ { overflow: "auto" } }>
                <MyInAppNotificationsList notifications={ notifications } />
            </Card.Section>
        </ContentCard>
    )
}

interface MyInAppNotificationsListProps {
    notifications: readonly InAppNotification[]
}

function MyInAppNotificationsList(props: MyInAppNotificationsListProps) {
    const { notifications } = props

    const { t } = useTranslation()

    if (notifications.length === 0) {
        return (
            <Center h="100%" py="xl">
                <Text>
                    { t("components:Core.MyInAppNotificationsCard.No notifications") }
                </Text>
            </Center>
        )
    }

    return (
        <Stack>
            { notifications.map(it => (<InAppNotificationItem key={ it.uuid } inAppNotification={ it } />)) }
        </Stack>
    )
}
