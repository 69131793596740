import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useMyPowerHourNavigate } from "@/components/hooks/UseMyPowerHourNavigate";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { CreateWorkoutForm } from "@/components/workouts/workout";
import { GraphQLClient } from "@/contexts/graphql";
import { CreateWorkoutPageDocument, CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, useLoaderData } from "react-router-dom";


export function CreateWorkoutPageLoader() {
    return GraphQLClient.query<CreateWorkoutPageQuery, CreateWorkoutPageQueryVariables>({
        query: CreateWorkoutPageDocument,
    })
}

export function CreateWorkoutPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<CreateWorkoutPageQuery>

    const { t } = useTranslation()
    const { navigate, navigateBack } = useMyPowerHourNavigate()

    const types = queryResult.data.workoutTypes

    const onSuccess = (uuid: UUID) => {
        showSuccessNotification(t("common:Forms.Saved"))
        navigate(generatePath(Routes.WORKOUTS_BY_ID, { uuid: uuid }), { replace: true })
    }

    const onError = (error: any) => {
        showErrorNotification({ error })
    }

    const onCancel = () => {
        navigateBack(Routes.WORKOUTS)
    }

    return (
        <>
            <MetaTitle title={ t("pages:CreateWorkoutPage.Title") } />

            <Stack>
                <PageTitleWithBack title={ t("pages:CreateWorkoutPage.Title") } />

                <ContentCard>
                    <CreateWorkoutForm workoutTypes={ types } onSuccess={ onSuccess } onError={ onError } onCancel={ onCancel } />
                </ContentCard>
            </Stack>
        </>
    )
}
