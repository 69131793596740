import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useConfirmEventRegistrationMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";

interface ConfirmItemProps {
    eventRegistrationUUID: UUID
    name: string
}

export function ConfirmItem(props: ConfirmItemProps) {
    const { eventRegistrationUUID, name } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const [ confirmRegistration ] = useConfirmEventRegistrationMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.ConfirmRegistration.Confirm registration") }</Text>,
            children: t("components:Events.ConfirmRegistration.Do you want to confirm this registration", { name: name }),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Confirm") },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => confirmRegistration({ variables: { uuid: eventRegistrationUUID } }),
                    onSuccess: () => {
                        showSuccessNotification(t("components:Events.ConfirmRegistration.Registration confirmed"))
                        revalidator.revalidate()
                    },
                    onError: (error) => {
                        Logger.error("Failed to confirm Registration", error)
                        showErrorNotification({ message: t("components:Events.ConfirmRegistration.Failed to confirm registration") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick }>
            { t("components:Events.ConfirmRegistration.Confirm registration") }
        </Menu.Item>
    )
}
