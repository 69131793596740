import { ApolloError } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import classes from "./Notification.module.scss"

export function showSuccessNotification(message: string, timeout?: number) {
    showNotification({
        color: "green",
        message: message,
        autoClose: timeout,
        classNames: classes,
    })
}

type ErrorNotificationType = { message?: string | null, error?: never } | { message?: never, error: any }

export function showErrorNotification(options: ErrorNotificationType) {
    let message
    if (options.message) {
        message = options.message
    } else {
        if (options.error instanceof ApolloError) {
            message = options.error.message
        } else {
            message = options.error.toString()
        }
    }

    showNotification({
        color: "red",
        message: message,
        classNames: classes,
    })
}
