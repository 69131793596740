import { UUID } from "@/common/Types";
import { MembershipList } from "@/components/memberships/membership/MembershipList";
import { MembershipRequestList } from "@/components/memberships/request";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { GraphQLClient } from "@/contexts/graphql";
import {
    MembershipAgreementState,
    MembershipRequestState,
    MyMembershipsPageDataDocument,
    MyMembershipsPageDataQuery,
    MyMembershipsPageDataQueryVariables
} from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ApolloQueryResult } from "@apollo/client";
import { Button, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";


export function MyMembershipsPageLoader() {
    return GraphQLClient.query<MyMembershipsPageDataQuery, MyMembershipsPageDataQueryVariables>({
        query: MyMembershipsPageDataDocument,
    })
}

export function MyMembershipsPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<MyMembershipsPageDataQuery>

    const { t } = useTranslation()

    const active = [ MembershipAgreementState.Active, MembershipAgreementState.Suspended, MembershipAgreementState.TerminationForced, MembershipAgreementState.TerminationRequested ]
    const terminated = [ MembershipAgreementState.Followed, MembershipAgreementState.Terminated ]
    const activeAgreements = queryResult.data.myMembershipAgreements.results.filter(it => active.includes(it.state))
    const terminatedAgreements = queryResult.data.myMembershipAgreements.results.filter(it => terminated.includes(it.state))

    return (
        <>
            <MetaTitle title={ t("pages:MyMembershipsPage.Title") } />

            <Stack>
                <PageTitleWithBack title={ t("pages:MyMembershipsPage.Title") } />

                <PendingMembershipRequestList requests={ queryResult.data.myMembershipRequests.results } />

                <ActiveMembershipList memberships={ activeAgreements } />

                <TerminatedMembershipList memberships={ terminatedAgreements } />
            </Stack>
        </>
    )
}

interface Membership {
    uuid: UUID
    created: string
    name: string
    subtitle: string | null
}

interface PendingMembershipRequestListProps {
    requests: readonly (Membership & { state: MembershipRequestState })[]
}

function PendingMembershipRequestList(props: PendingMembershipRequestListProps) {
    const { requests } = props

    const { t } = useTranslation()

    if (requests.length === 0) {
        return null
    }

    return (
        <Stack>
            <Title order={ 3 } fw={ 600 } size="large">{ t("pages:MyMembershipsPage.Pending Membership Requests") }</Title>

            <MembershipRequestList memberships={ requests }
                                   route={ Routes.MY_MEMBERSHIPS_REQUEST_BY_ID } />
        </Stack>
    )
}

interface ActiveMembershipListProps {
    memberships: readonly {
        uuid: UUID
        created: string
        name: string
        state: MembershipAgreementState
        subtitle: string | null
    }[]
}

function ActiveMembershipList(props: ActiveMembershipListProps) {
    const { memberships } = props

    const { t } = useTranslation()

    if (memberships.length === 0) {
        return (
            <Stack>
                <Title order={ 3 } fw={ 600 } size="large">{ t("pages:MyMembershipsPage.Active Memberships") }</Title>

                <ContentCard>
                    <Stack align="center">
                        <Text>{ t("pages:MyMembershipsPage.No active memberships") }</Text>
                        <Button component={ Link } to={ Routes.MEMBERSHIPS }>{ t("pages:MyMembershipsPage.Our memberships") }</Button>
                    </Stack>
                </ContentCard>
            </Stack>
        )
    }

    return (
        <Stack>
            <Title order={ 3 } fw={ 600 } size="large">{ t("pages:MyMembershipsPage.Active Memberships") }</Title>

            <MembershipList memberships={ memberships }
                            route={ Routes.MY_MEMBERSHIPS_BY_ID } />
        </Stack>
    )
}

interface TerminatedMembershipListProps {
    memberships: readonly {
        uuid: UUID
        created: string
        name: string
        state: MembershipAgreementState
        subtitle: string | null
    }[]
}

function TerminatedMembershipList(props: TerminatedMembershipListProps) {
    const { memberships } = props

    const { t } = useTranslation()

    if (memberships.length === 0) {
        return null
    }

    return (
        <Stack>
            <Title order={ 3 } fw={ 600 } size="large">{ t("pages:MyMembershipsPage.Terminated Memberships") }</Title>

            <MembershipList memberships={ memberships }
                            route={ Routes.MY_MEMBERSHIPS_BY_ID } />
        </Stack>
    )
}
