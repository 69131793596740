import { DateMode, Event, EventLineItemLinked, } from "@/components/events/event";
import { ContentCard } from "@/components/shared";
import { Routes } from "@/routing/Routes";
import { Button, Card, Center, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";


interface EventsProps {
    events: readonly Event[]
}

export function EventsCard(props: EventsProps) {
    const { events } = props;

    const { t } = useTranslation()

    return (
        <ContentCard style={ { height: "100%" } }>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Info.EventsCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="sm" h="100%">
                <EventsCardContent events={ events } />
            </Card.Section>
        </ContentCard>
    )
}

function EventsCardContent(props: EventsProps) {
    const { events } = props;

    const { t } = useTranslation()

    if (events.length === 0) {
        return (
            <Stack h="100%" align="center" justify="center">
                <Center>
                    <Text>{ t("components:Info.EventsCard.No Classes") }</Text>
                </Center>

                <Group justify="center">
                    <Button component={ RouterLink } to={ Routes.CLASSES }>{ t("components:Info.EventsCard.All Classes") }</Button>
                </Group>
            </Stack>
        )
    }

    return (
        <Stack>
            {
                events.slice(0, 3).map(it => (
                    <EventLineItemLinked key={ it.uuid }
                                         dateMode={ DateMode.RELATIVE }
                                         event={ it }
                                         withBorder />
                ))
            }

            <Group justify="center">
                <Button component={ RouterLink } to={ Routes.CLASSES }>
                    { t("components:Info.EventsCard.All Classes") }
                </Button>
            </Group>
        </Stack>
    )
}
