import Logger from "@/common/Logger"
import { AuthenticationAPI, ConfirmationInput, ConfirmationResponseDTO, SignInInput, SignInResponseDTO, TouchErrorReason } from "@/components/authentication";
import RoutingStorage from "@/routing/RoutingStorage";
import { useApolloClient } from "@apollo/client";
import { useDocumentVisibility } from "@mantine/hooks";
import { DateTime } from "luxon";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { LocalSessionStorage } from "./LocalSessionStorage"
import { SessionData } from "./Types"

interface SessionContextType {
    isSignedIn: boolean
    isInitialized: boolean,
    confirm: (input: ConfirmationInput) => Promise<ConfirmationResponseDTO>
    signIn: (input: SignInInput) => Promise<SignInResponseDTO>
    signOut: () => Promise<void>
}

const SessionContextDefaultValues = {
    isSignedIn: false,
    isInitialized: false,
    confirm: () => {
        throw Error("Can't invoke confirm() on default context")
    },
    signIn: () => {
        throw Error("Can't invoke signIn() on default context")
    },
    signOut: () => {
        throw Error("Can't invoke signOut() on default context")
    }
}

const SessionContext = React.createContext<SessionContextType>(SessionContextDefaultValues)

export function SessionContextProvider(props: PropsWithChildren) {
    const { children } = props

    const client = useApolloClient()

    const [ isSignedIn, setIsSignedIn ] = useState<boolean>(false)
    const [ isInitialized, setIsInitialized ] = useState<boolean>(false)

    const visibility = useDocumentVisibility()

    const applySignIn = useCallback((response: SessionData) => {
        setIsSignedIn(true)
        RoutingStorage.setIsAuthenticated(true)
        LocalSessionStorage.save(response)
    }, [])

    const applySignOut = useCallback(async () => {
        setIsSignedIn(false)
        RoutingStorage.setIsAuthenticated(false)
        LocalSessionStorage.save({ expiresAt: "1970-01-01T00:00:00Z", expiresIn: "0" })
        await client.clearStore()
    }, [ client ])

    const touch = useCallback(async () => {
        const response = await AuthenticationAPI.touch()
        if (response.isSuccess) {
            applySignIn(response)
            return
        }

        switch (response.errorReason) {
            case TouchErrorReason.INVALID:
                await applySignOut()
                break;
            case TouchErrorReason.FETCH_ERROR: {
                const sessionData = LocalSessionStorage.find()
                if (sessionData) {
                    if (DateTime.now() < DateTime.fromISO(sessionData.expiresAt)) {
                        Logger.warn(`Failed to touch, but still valid. (${ sessionData.expiresAt })`)
                    } else {
                        Logger.warn(`Failed to touch and invalid. (${ sessionData.expiresAt })`)
                        await applySignOut()
                    }
                } else {
                    Logger.warn(`Failed to touch and no previous session data available.`)
                    await applySignOut()
                }
                break;
            }
        }
    }, [ applySignIn, applySignOut ])

    const signIn = useCallback(async (input: SignInInput) => {
        const response = await AuthenticationAPI.signIn(input)
        if (response.isSuccess) {
            applySignIn(response)
            Logger.debug("Signed in.")
            return response
        }

        return response
    }, [ applySignIn ])

    const confirm = useCallback(async (input: ConfirmationInput) => {
        const response = await AuthenticationAPI.confirm(input)
        if (response.isSuccess) {
            applySignIn(response)
            Logger.debug("Signed in after confirmation.")
            return response
        }

        return response
    }, [ applySignIn ])

    const signOut = useCallback(async () => {
        await AuthenticationAPI.signOut()
        await applySignOut()
        Logger.debug("Signed out.")
    }, [ applySignOut ])

    useEffect(() => {
        if (visibility === "visible" && isSignedIn && "connection" in navigator && navigator.onLine) {
            void touch()
        }
    }, [ visibility, touch ]);

    useEffect(() => {
        void touch().finally(() => setIsInitialized(true))
    }, [ touch ])

    const context = {
        isSignedIn: isSignedIn,
        isInitialized: isInitialized,
        confirm: confirm,
        signIn: signIn,
        signOut: signOut
    }

    Logger.debug(`[SessionContext] isLoggedIn: ${ isSignedIn }; isInitialized: ${ isInitialized }`)
    return (
        <SessionContext.Provider value={ context }>
            { children }
        </SessionContext.Provider>
    )
}

export const useSession = () => useContext(SessionContext);
