import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { toNullIfEmpty } from "@/common/String";
import { getInputMode } from "@/components/shared/Form"
import { WorkoutScoreHelper } from "@/components/workouts/WorkoutScoreHelper";
import { BenchmarkResultInput, BenchmarkResultType } from "@/generated/graphql";
import { Button, Grid, Group, NumberInput, Stack, TextInput } from "@mantine/core";
import { useOs } from "@mantine/hooks";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";


interface BenchmarkResultFormData {
    comment: string
    date: string
    score1: number | ""
    score2: number | ""
}

interface BenchmarkResultFormProps<TSuccessData> {
    initialValues: BenchmarkResultInput

    resultType: BenchmarkResultType

    onSubmit: (input: BenchmarkResultInput) => Promise<FormSubmitResult<TSuccessData>>
    onCancel?: () => void
    onDelete?: () => void
}

export function BenchmarkResultForm<TSuccessData>(props: BenchmarkResultFormProps<TSuccessData>) {
    const { initialValues, resultType, onCancel, onDelete, onSubmit } = props

    const { t } = useTranslation()
    const os = useOs()

    const form = useMyPowerHourForm({
        initialValues: toFormData(resultType, initialValues),
        transformValues: v => toInput(resultType, v)
    });

    let fields: JSX.Element;
    if (resultType === BenchmarkResultType.Duration) {
        fields = (
            <>
                <Grid.Col span={ 6 }>
                    <NumberInput label={ t("components:Workouts.BenchmarkResultForm.Minutes") }
                                 autoComplete="off"
                                 data-autofocus
                                 required
                                 min={ 0 }
                                 title="score-minutes"
                                 { ...form.getInputProps("score1") } />
                </Grid.Col>
                <Grid.Col span={ 6 }>
                    <NumberInput label={ t("components:Workouts.BenchmarkResultForm.Seconds") }
                                 autoComplete="off"
                                 required
                                 min={ 0 }
                                 max={ 60 }
                                 title="score-seconds"
                                 { ...form.getInputProps("score2") } />
                </Grid.Col>
            </>
        )
    } else {
        fields = (
            <Grid.Col span={ 12 }>
                <NumberInput label={ t("entities:Workout.BenchmarkResult.Fields.Score") }
                             placeholder={ t(`entities:BenchmarkResultType.${ resultType }`) }
                             inputMode={ getInputMode(true, resultType === BenchmarkResultType.Repetition, os) }
                             autoComplete="off"
                             data-autofocus
                             required
                             hideControls
                             decimalScale={ 2 }
                             title="score-score"
                             { ...form.getInputProps("score1") } />
            </Grid.Col>
        )
    }

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <TextInput label={ t("entities:Workout.BenchmarkResult.Fields.Date") }
                           type="date"
                           { ...form.getInputProps("date") } />

                <Grid>
                    { fields }
                </Grid>

                <TextInput label={ t("entities:Workout.BenchmarkResult.Fields.Comment") }
                           autoComplete="off"
                           maxLength={ 255 }
                           { ...form.getInputProps("comment") } />

                <Group justify="space-between">
                    <Group>
                        { onCancel &&
                            <Button variant="default" onClick={ onCancel }>
                                { t("common:Button.Cancel") }
                            </Button>
                        }

                        { onDelete &&
                            <Button color="red" onClick={ onDelete }>
                                { t("common:Button.Delete") }
                            </Button>
                        }
                    </Group>

                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(resultType: BenchmarkResultType, input: BenchmarkResultInput): BenchmarkResultFormData {
    const [ score1, score2 ] = WorkoutScoreHelper.decode(resultType, input.score);

    return {
        comment: input.comment ?? "",
        date: DateTime.fromISO(input.date).toISODate(),
        score1: score1 ?? "",
        score2: score2 ?? "",
    }
}

function toInput(resultType: BenchmarkResultType, formData: BenchmarkResultFormData): BenchmarkResultInput {
    const score = WorkoutScoreHelper.encode(resultType, formData.score1, formData.score2)

    return {
        comment: toNullIfEmpty(formData.comment),
        date: DateTime.fromISO(formData.date).startOf("day").toISO(),
        score: score,
    }
}
