import { Fact } from "@/components/shared/Fact";
import { useTranslation } from "react-i18next";


interface FeeFactProps {
    feeAmount: number
}

export function FeeFact(props: FeeFactProps) {
    const { feeAmount } = props

    const { t } = useTranslation()

    return (
        <Fact title={ t("pages:ClassesByIdPage.Fee") }>
            {
                feeAmount === 0
                    ? t("pages:ClassesByIdPage.This event is free")
                    : t("pages:ClassesByIdPage.This event requires X credits", { count: feeAmount })
            }
        </Fact>
    )
}
