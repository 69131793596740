import { PaperStack } from "@/components/shared";
import { Group, Stack, Text } from "@mantine/core";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";


interface MembershipListItemProps {
    badge?: ReactNode
    name: string
    subtitle: string | null
    to: string
}

export function MembershipListItem(props: MembershipListItemProps) {
    const { badge, name, subtitle, to } = props

    return (
        <PaperStack.Row component={ RouterLink } to={ to }>
            <Group wrap="nowrap">
                <Stack gap={ 0 } style={ { flexGrow: 1 } }>
                    <Text fw={ 500 }>{ name }</Text>
                    { subtitle && <Text c="dimmed" size="xs">{ subtitle }</Text> }
                </Stack>

                { badge }
            </Group>
        </PaperStack.Row>
    )
}
