import { UUID } from "@/common/Types";
import { ContentCard } from "@/components/shared";
import { Center, SimpleGrid, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { SpecialEventCard } from "./SpecialEventCard";


interface SpecialEventListProps {
    events: {
        uuid: UUID
        availableSpots: number | null
        end: DateTime
        location: string | null
        name: string
        start: DateTime
    }[]
}

export function SpecialEventList(props: SpecialEventListProps) {
    const { events } = props

    const { t } = useTranslation()

    if (events.length === 0) {
        return (
            <ContentCard>
                <Center>
                    <Text>{ t("components:Events.SpecialEventList.No Events available") }</Text>
                </Center>
            </ContentCard>
        )
    }

    return (
        <SimpleGrid cols={ { base: 1, sm: 2, md: 3 } }>
            {
                events.map(it => (
                    <SpecialEventCard key={ it.uuid } event={ it } />
                ))
            }
        </SimpleGrid>
    )
}
