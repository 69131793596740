import { UUID } from "@/common/Types";
import { RegistrationStateManagerAction, useAllowedEventRegistrationStateActionsForManagerQuery } from "@/generated/graphql";
import { AttendedItem } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/AttendedItem";
import { ConfirmItem } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/ConfirmItem";
import { DeleteItem } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/DeleteItem";
import { MissedItem } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/MissedItem";
import { ToProfileItem } from "@/pages/classes/[uuid]/_components/eventRegistrationMenu/ToProfileItem"
import { Divider } from "@mantine/core";

interface AdminItemsProps {
    eventRegistration: {
        uuid: UUID
        name: string
        memberUUID: string | null
    }
}

export function AdminItems(props: AdminItemsProps) {
    const { eventRegistration } = props

    const { data } = useAllowedEventRegistrationStateActionsForManagerQuery({
        variables: { registrationUUID: eventRegistration.uuid },
        fetchPolicy: "cache-and-network"
    })

    const items: React.ReactNode[] = []
    if (data?.allowedEventRegistrationStateActionsForManager.includes(RegistrationStateManagerAction.Confirm)) {
        items.push(<ConfirmItem key="confirm" eventRegistrationUUID={ eventRegistration.uuid } name={ eventRegistration.name } />)
    }
    if (data?.allowedEventRegistrationStateActionsForManager.includes(RegistrationStateManagerAction.Attended)) {
        items.push(<AttendedItem key="attended" eventRegistrationUUID={ eventRegistration.uuid } name={ eventRegistration.name } />)
    }
    if (data?.allowedEventRegistrationStateActionsForManager.includes(RegistrationStateManagerAction.Missed)) {
        items.push(<MissedItem key="missed" eventRegistrationUUID={ eventRegistration.uuid } name={ eventRegistration.name } />)
    }
    if (data?.allowedEventRegistrationStateActionsForManager.includes(RegistrationStateManagerAction.Delete)) {
        items.push(<DeleteItem key="delete" eventRegistrationUUID={ eventRegistration.uuid } name={ eventRegistration.name } />)
    }

    return (
        <>
            { items.length > 0 && <Divider /> }
            { items.map(it => it) }

            { eventRegistration.memberUUID && (
                <>
                    <Divider />
                    <ToProfileItem memberUUID={ eventRegistration.memberUUID } />
                </>
            ) }
        </>
    )
}
