import Person from "@/common/Person"
import { OrganisationLogEntry } from "@/generated/graphql"
import { Routes } from "@/routing/Routes"
import { Anchor } from "@mantine/core"
import { Trans } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { MemberOrganisationLogEntryResolvedMessage, MembershipOrganisationLogEntryResolvedMessage } from "./types"


interface OrganisationLogEntryItemProps {
    organisationLogEntry: OrganisationLogEntry
}

export function OrganisationLogEntryItem(props: OrganisationLogEntryItemProps) {
    const { organisationLogEntry } = props

    function resolveMember(organisationLogEntry: OrganisationLogEntry) {
        if (organisationLogEntry.message == null) return null

        const message = JSON.parse(organisationLogEntry.message) as MemberOrganisationLogEntryResolvedMessage

        return (
            <Trans i18nKey={ `components:Organisation.Log.${ organisationLogEntry.type }` }
                   values={ { name: Person.buildName(message) } }
                   shouldUnescape={ true }
                   components={ {
                       member: <Anchor component={ Link } to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID, { uuid: message.memberUUID }) } />
                   } } />
        )
    }

    function resolveMembershipAgreement(organisationLogEntry: OrganisationLogEntry) {
        if (organisationLogEntry.message == null) return null

        const message = JSON.parse(organisationLogEntry.message) as MembershipOrganisationLogEntryResolvedMessage

        return (
            <Trans i18nKey={ `components:Organisation.Log.${ organisationLogEntry.type }` }
                   values={ { name: Person.buildName(message), membershipAgreementName: message.membershipAgreementName } }
                   shouldUnescape={ true }
                   components={ {
                       member: <Anchor component={ Link } to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID, { uuid: message.memberUUID }) } />,
                       membership: <Anchor component={ Link } to={ generatePath(Routes.ADMIN_MEMBERSHIPS_BY_ID, { uuid: message.membershipAgreementUUID }) } />
                   } } />
        )
    }

    function resolve(organisationLogEntry: OrganisationLogEntry) {
        switch (organisationLogEntry.type) {
            case "MEMBER_ACTIVATED":
                return resolveMember(organisationLogEntry);
            case "MEMBER_DEACTIVATED":
                return resolveMember(organisationLogEntry);
            case "MEMBERSHIP_ACTIVATED":
                return resolveMembershipAgreement(organisationLogEntry);
            case "MEMBERSHIP_TERMINATED":
                return resolveMembershipAgreement(organisationLogEntry);
            default:
                return null
        }
    }

    return resolve(organisationLogEntry)
}
