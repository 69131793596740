import { Format } from "@/common/Format"
import Person from "@/common/Person";
import { Address } from "@/components/memberships/details/Address";
import { AttendanceFact } from "@/components/memberships/details/AttendanceFact";
import { BillingFact } from "@/components/memberships/details/BillingFact";
import { DateFact } from "@/components/memberships/details/DateFact";
import { Fact } from "@/components/shared/Fact";
import { AttendanceTimeUnit, AttendanceType, BillingCycle, MembershipAgreementCommitment } from "@/generated/graphql";
import { SimpleGrid, Stack, Text } from "@mantine/core";
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";


interface MembershipDetailsProps {
    contractee?: {
        uuid?: string | null

        city: string | null
        country: string | null
        emailAddress: string | null
        firstname: string | null
        lastname: string | null
        phone: string | null
        postalCode: string | null
        streetAddress: string | null
        username: string
    },
    organisation?: {
        city: string | null
        country: string | null
        email: string | null
        name: string
        phone: string | null
        postalCode: string | null
        streetAddress: string | null
    },
    membership: {
        attendanceRestriction: {
            count: number
            timeUnit: AttendanceTimeUnit
            type: AttendanceType
        }
        billing: {
            amount: number
            cycle: BillingCycle
        }
        commitment: MembershipAgreementCommitment
        description: string | null
        endDate?: string | null
        startDate?: string | null
        renewalPeriod: string | null
        runtime: string | null
        specialTerminationUntil?: string | null
        terminationPeriod: string | null
    }
}

export function MembershipDetails(props: MembershipDetailsProps) {
    const { organisation, contractee, membership } = props

    const { t } = useTranslation()


    return (
        <Stack>
            { (contractee || organisation) &&
                <SimpleGrid cols={ { base: 1, sm: 2 } }>
                    { contractee &&
                        <Address title={ t("components:Memberships.MembershipFacts.Contractee") }
                                 memberUUID={ contractee.uuid }
                                 name={ Person.buildName(contractee) }
                                 streetAddress={ contractee.streetAddress }
                                 postalCode={ contractee.postalCode }
                                 city={ contractee.city }
                                 country={ contractee.country }
                                 emailAddress={ contractee.emailAddress }
                                 phone={ contractee.phone } />
                    }

                    { organisation &&
                        <Address title={ t("components:Memberships.MembershipFacts.Contractor") }
                                 name={ `${ organisation.name }` }
                                 streetAddress={ organisation.streetAddress }
                                 postalCode={ organisation.postalCode }
                                 city={ organisation.city }
                                 country={ organisation.country }
                                 emailAddress={ organisation.email }
                                 phone={ organisation.phone } />
                    }
                </SimpleGrid>
            }

            { (membership.startDate || membership.endDate) &&
                <SimpleGrid cols={ { base: 1, sm: 2 } }>
                    <DateFact title={ t("components:Memberships.MembershipFacts.StartDate") }
                              date={ membership.startDate } />

                    <DateFact title={ t("components:Memberships.MembershipFacts.EndDate") }
                              date={ membership.endDate } />
                </SimpleGrid>
            }

            <AttendanceFact type={ membership.attendanceRestriction.type }
                            count={ membership.attendanceRestriction.count }
                            timeUnit={ membership.attendanceRestriction.timeUnit } />

            <SimpleGrid cols={ { base: 1, sm: 2 } }>
                <BillingFact amount={ membership.billing.amount }
                             cycle={ membership.billing.cycle } />
            </SimpleGrid>

            <SimpleGrid cols={ { base: 1, sm: 2 } }>
                { membership.runtime &&
                    <Fact title={ t("components:Memberships.MembershipFacts.Runtime") }>
                        { Format.humanize(Duration.fromISO(membership.runtime)) }
                    </Fact>
                }

                { membership.renewalPeriod &&
                    <Fact title={ t("components:Memberships.MembershipFacts.RenewalPeriod") }>
                        { Format.humanize(Duration.fromISO(membership.renewalPeriod)) }
                    </Fact>
                }

                { membership.commitment === MembershipAgreementCommitment.LongTerm && (
                    <Fact title={ t("components:Memberships.MembershipFacts.TerminationPeriod") }>
                        { membership.terminationPeriod ? Format.humanize(Duration.fromISO(membership.terminationPeriod)) : t("components:Memberships.MembershipFacts.None") }
                    </Fact>
                ) }

                { membership.specialTerminationUntil &&
                    <Fact title={ t("components:Memberships.MembershipFacts.SpecialTerminationUntil") }>
                        { DateTime.fromISO(membership.specialTerminationUntil).toFormat("dd.MM.yyyy") }
                    </Fact>
                }
            </SimpleGrid>

            { membership.description &&
                <Fact title={ t("components:Memberships.MembershipFacts.Description") }>
                    <Text fw={ 600 }>
                        { membership.description }
                    </Text>
                </Fact>
            }
        </Stack>
    )
}
