import { Fact } from "@/components/shared/Fact";
import { EventState } from "@/generated/graphql"
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";

interface StateFactProps {
    event: {
        minimumNumberOfRegistrations: number | null
        minimumNumberOfRegistrationsPeriod: Duration | null
        start: DateTime
        state: EventState
    }
}

export function StateFact(props: StateFactProps) {
    const { event } = props

    const { t } = useTranslation()

    let content = null
    switch (event.state) {
        case EventState.Confirmed:
            content = t("pages:ClassesByIdPage.This event is confirmed")
            break;
        case EventState.Pending:
            if (event.minimumNumberOfRegistrations !== null && event.minimumNumberOfRegistrationsPeriod !== null) {
                const date = event.start.minus(event.minimumNumberOfRegistrationsPeriod)
                content = t("pages:ClassesByIdPage.This event is happening, when there are X registrations",
                    { date: date.toFormat("EEEE dd.MM. HH:mm"), count: event.minimumNumberOfRegistrations })
            } else {
                content = t("pages:ClassesByIdPage.This event is pending")
            }
            break;
        case EventState.Cancelled:
        case EventState.Started:
        case EventState.Finished:
        case EventState.Deleted:
            return null
    }


    return (
        <Fact title={ t("pages:ClassesByIdPage.State") }>
            { content }
        </Fact>
    )
}
