import { SignUpCodeForm } from "@/components/account";
import { ContentCard } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle"
import { useSession } from "@/contexts/session";
import { Routes } from "@/routing/Routes"
import { Box, Center, Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

export function SignUpConfirmPage() {
    const { code } = useParams();
    const { t } = useTranslation()

    const { isSignedIn } = useSession()

    if (isSignedIn) {
        return <Navigate to={ Routes.MY } replace />
    }

    if (!code) {
        return <Navigate to={ Routes.ROOT } replace />
    }

    const onCodeExpired = () => {
        return <Navigate to={ Routes.SIGN_UP } />
    }

    return (
        <>
            <MetaTitle title={ t("pages:SignUpConfirmPage.Title") } />

            <Center>
                <Box w={ 600 }>
                    <Stack>
                        <Stack gap={ 0 }>
                            <Title order={ 2 } ta="center">
                                { t("pages:SignUpConfirmPage.Title") }
                            </Title>
                        </Stack>

                        <ContentCard>
                            <SignUpCodeForm initialValues={ { code: code } } onExpired={ onCodeExpired } />
                        </ContentCard>
                    </Stack>
                </Box>
            </Center>
        </>
    );
}
