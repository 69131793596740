import { UUID } from "@/common/Types";
import { MenuButton } from "@/components/shared";
import { DeleteBenchmarkItem } from "@/pages/my/benchmarks/[uuid]/_components/benchmarkMenu/DeleteBenchmarkItem";
import { UpdateBenchmarkItem } from "@/pages/my/benchmarks/[uuid]/_components/benchmarkMenu/UpdateBenchmarktem";
import { Menu, Stack } from "@mantine/core";

interface BenchmarkMenuProps {
    benchmarkUUID: UUID
}

export function BenchmarkMenu(props: BenchmarkMenuProps) {
    const { benchmarkUUID } = props

    return (
        <Menu transitionProps={ { transition: "scale-y" } } position="bottom-end" withinPortal shadow="sm">
            <Menu.Target>
                <MenuButton />
            </Menu.Target>

            <Menu.Dropdown>
                <Stack gap={ 4 }>
                    <UpdateBenchmarkItem benchmarkUUID={ benchmarkUUID } />
                    <DeleteBenchmarkItem benchmarkUUID={ benchmarkUUID } />
                </Stack>
            </Menu.Dropdown>
        </Menu>
    )
}
