import { EventRegistrationStateIcon, GetSpotsText } from "@/components/events/event";
import { EventState } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Box, Stack, Text, UnstyledButton } from "@mantine/core";
import cx from "clsx";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import classes from "./EventCard.module.scss"
import { CalendarEvent } from "./types"


interface EventCardProps {
    day: DateTime
    event: CalendarEvent
}

export function EventCard(props: EventCardProps) {
    const { day, event } = props

    const eventClasses = [ classes.event ]
    if (event.state === EventState.Cancelled) {
        eventClasses.push(classes.cancelled)
    }
    if (event.state === EventState.Finished) {
        eventClasses.push(classes.finished)
    }

    const timeText = <GetTimeText day={ day } event={ event } />
    const spotsText = <GetSpotsText event={ event } />
    const registrationStateIcon = <EventRegistrationStateIcon event={ event } />

    return (
        <UnstyledButton component={ Link } to={ generatePath(Routes.CLASSES_BY_ID, { uuid: event.uuid }) }>
            <div className={ cx(...eventClasses) } style={ { borderTopColor: event.color ?? "transparent" } }>
                {
                    <Box className={ classes.registrationStateIcon }>
                        { registrationStateIcon }
                    </Box>
                }

                <Stack gap={ 0 }>
                    <Text c="dimmed" size="sm">{ timeText }</Text>

                    <Text fw={ 500 } className={ classes.eventName }>{ event.name }</Text>

                    { spotsText && <Text c="dimmed" size="xs">{ spotsText }</Text> }
                </Stack>
            </div>
        </UnstyledButton>
    )
}

interface GetTimeTextProps {
    day: DateTime
    event: CalendarEvent
}

function GetTimeText(props: GetTimeTextProps) {
    const { day, event } = props

    const { t } = useTranslation()

    if (event.start.toISODate() < day.toISODate() && day.toISODate() < event.end.toISODate()) {
        return t("components:Events.EventCalendar.All day")
    }

    if (event.start.toISODate() === day.toISODate() && day.toISODate() < event.end.toISODate()) {
        return `${ event.start.toFormat("HH:mm") }`
    }

    if (event.start.toISODate() < day.toISODate() && day.toISODate() === event.end.toISODate()) {
        return `${ t("components:Events.EventCalendar.Ends") } ${ event.end.toFormat("HH:mm") }`
    }

    return `${ event.start.toFormat("HH:mm") } - ${ event.end.toFormat("HH:mm") }`
}
