import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useMissedEventRegistrationMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";

interface MissedItemProps {
    eventRegistrationUUID: UUID
    name: string
}

export function MissedItem(props: MissedItemProps) {
    const { eventRegistrationUUID, name } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const [ missedRegistration ] = useMissedEventRegistrationMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.MissedRegistration.Missed registration") }</Text>,
            children: t("components:Events.MissedRegistration.Do you want to missed this registration", { name: name }),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Missed") },
            confirmProps: { color: "red" },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => missedRegistration({ variables: { uuid: eventRegistrationUUID } }),
                    onSuccess: () => {
                        showSuccessNotification(t("components:Events.MissedRegistration.Registration missed"))
                        revalidator.revalidate()
                    },
                    onError: (error) => {
                        Logger.error("Failed to missed Registration", error)
                        showErrorNotification({ message: t("components:Events.MissedRegistration.Failed to missed registration") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick }>
            { t("components:Events.MissedRegistration.Missed registration") }
        </Menu.Item>
    )
}
