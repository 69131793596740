import Logger from "@/common/Logger"
import { useMyPowerHourForm } from "@/common/MyPowerHourForm"
import { showErrorNotification } from "@/common/Notification"
import { passwordValidator } from "@/components/account/Validation"
import { FormErrorAlert } from "@/components/shared";
import { useAuthentication } from "@/contexts/authentication";
import { UpdatePasswordInput, useUpdatePasswordMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { Button, Group, PasswordInput, Stack } from "@mantine/core";
import { GraphQLFormattedError } from "graphql";
import { useTranslation } from "react-i18next";


enum ErrorReason {
    PASSWORD_MISMATCH = "PASSWORD_MISMATCH",
    INVALID_PASSWORD = "INVALID_PASSWORD"
}

interface UpdatePasswordFormData {
    currentPassword: string
    newPassword: string
}

interface UpdatePasswordFormProps {
    onSuccess: () => void
}

export function UpdatePasswordForm(props: UpdatePasswordFormProps) {
    const { onSuccess } = props

    const { t } = useTranslation()

    const { me } = useAuthentication()

    const [ updatePassword ] = useUpdatePasswordMutation()

    const form = useMyPowerHourForm<UpdatePasswordFormData>({
        name: "UpdatePasswordForm",
        initialValues: {
            currentPassword: "",
            newPassword: ""
        },
        validate: {
            newPassword: passwordValidator(t(`components:Account.Common.Password doesn't match guidelines`))
        }
    })

    const handleError = (errors: readonly GraphQLFormattedError[] | string) => {
        if (!(errors instanceof Array) || errors.length < 1) {
            Logger.error("Failed to update password", errors)
            showErrorNotification({ message: t("common:Error.Something went wrong") })
            return
        }

        const error = errors[0]?.extensions?.reason as ErrorReason | undefined;
        switch (error) {
            case undefined:
                Logger.error("Failed to update password", errors)
                showErrorNotification({ message: t("common:Error.Something went wrong") })
                break;
            case ErrorReason.PASSWORD_MISMATCH:
                form.setFieldError("currentPassword", t(`components:Account.UpdatePasswordForm.Password mismatch`))
                break;
            case ErrorReason.INVALID_PASSWORD:
                form.setFieldError("newPassword", t(`components:Account.Common.Password doesn't match guidelines`))
                break;
            default:
                return error satisfies never;
        }
    }

    const handleSubmit = async (data: UpdatePasswordFormData) => {
        const input: UpdatePasswordInput = {
            newPassword: data.newPassword,
            currentPassword: data.currentPassword
        }

        await executeMutation({
            mutation: () => updatePassword({ variables: { input: input } }),
            onSuccess: () => {
                form.reset()
                onSuccess()
            },
            onError: handleError
        })
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <input type="email" autoComplete="email" value={ me?.emailAddress ?? "" } disabled hidden />

                <PasswordInput label={ t("components:Account.Common.Current password") }
                               name="currentPassword"
                               autoComplete="current-password"
                               required
                               { ...form.getInputProps("currentPassword") } />

                <PasswordInput label={ t("components:Account.Common.New password") }
                               description={ t("components:Account.Common.Password description") }
                               name="newPassword"
                               autoComplete="new-password"
                               required
                               { ...form.getInputProps("newPassword") } />

                <Group justify="flex-end">
                    { form.hasErrors && <FormErrorAlert /> }
                    <Button type="submit" title="submit" loading={ form.isSubmitting } disabled={ form.hasErrors }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
