import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Menu } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface UpdateWorkoutItemProps {
    workoutUUID: UUID
}

export function UpdateWorkoutItem(props: UpdateWorkoutItemProps) {
    const { workoutUUID } = props

    const { t } = useTranslation()

    return (
        <Menu.Item component={ Link }
                   to={ generatePath(Routes.WORKOUTS_BY_ID_UPDATE, { uuid: workoutUUID }) }
                   leftSection={ <IconEdit size={ 18 } /> }
                   id="update-workout-button" data-umami-event="update-workout-button">
            { t("pages:WorkoutPage.Update Workout") }
        </Menu.Item>
    )
}
