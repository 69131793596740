import { LoadingOverlay } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigation } from "react-router-dom";

export function RouterLoadingOverlay() {
    const navigation = useNavigation()

    const [ show, setShow ] = useState<boolean>(false)
    const { start, clear } = useTimeout(() => setShow(true), 1000);

    useEffect(() => {
        if (navigation.state === "loading") {
            start()
        } else {
            clear()
            setShow(false)
        }
    }, [ navigation ])

    return (
        <LoadingOverlay transitionProps={ { duration: 300 } }
                        overlayProps={ { opacity: 0.6 } }
                        visible={ show }
                        zIndex={ 9999 } />
    )
}
