import { ContentCard } from "@/components/shared";
import { AppProperties } from "@/configuration/AppProperties"
import { InfoPageQuery } from "@/generated/graphql"
import { BackgroundImage, Button, Card, Flex, Paper, Stack, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";


interface GoogleMapsCardProps {
    googleMapsSettings: InfoPageQuery["googleMapsSettings"]
}

export function GoogleMapsCard(props: GoogleMapsCardProps) {
    const { googleMapsSettings } = props

    const { t } = useTranslation()
    const [ googleMapsConsent, setGoogleMapsConsent ] = useLocalStorage({ key: "googleMapsConsent", defaultValue: false })

    if (googleMapsSettings.placeId === null) {
        return null
    }

    const consent = (
        <BackgroundImage src="/map.png">
            <Flex align="center" justify="center" mih={ 450 } p="md" style={ { backdropFilter: "blur(5px)" } }>
                <Paper p="md" shadow="md">
                    <Stack align="center" justify="center">
                        <Text ta="center" style={ { textWrap: "balance" } }>
                            { t("components:Info.GoogleMapsCard.We need your consent to show Google Maps") }
                        </Text>
                        <Button onClick={ () => setGoogleMapsConsent(true) } data-umami-event="google-maps-consent">
                            { t("components:Info.GoogleMapsCard.Consent") }
                        </Button>
                    </Stack>
                </Paper>
            </Flex>
        </BackgroundImage>
    )

    const frame = (
        <iframe width="100%"
                height="450"
                loading="lazy"
                frameBorder={ 0 }
                src={ `https://www.google.com/maps/embed/v1/place?q=place_id:${ googleMapsSettings.placeId }&zoom=14&key=${ AppProperties().googleMapsEmbedApiKey }` }></iframe>
    )

    return (
        <ContentCard>
            <Card.Section inheritPadding py="md" style={ { lineHeight: 0 } }>
                { googleMapsConsent ? frame : consent }
            </Card.Section>
        </ContentCard>
    )
}
