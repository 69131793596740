import { UUID } from "@/common/Types";
import { ComboboxItem } from "@mantine/core";
import { DateTime } from "luxon";


export function byStartAndName(a: { name: string, start: DateTime, }, b: { name: string, start: DateTime, }) {
    return a.start.toMillis() - b.start.toMillis() || a.name.localeCompare(b.name)
}

export function byDateTime(a: DateTime, b: DateTime) {
    return a.toMillis() - b.toMillis()
}

export function byDateTimeDesc(a: DateTime, b: DateTime) {
    return b.toMillis() - a.toMillis()
}

export function dateTimeAndUUID(a: { d: DateTime, u: UUID }, b: { d: DateTime, u: UUID }) {
    return a.d.toMillis() - b.d.toMillis() || a.u.localeCompare(b.u)
}

export function byName(a: { name: string }, b: { name: string }) {
    return a.name.localeCompare(b.name)
}

export function byComboboxItems(a: ComboboxItem, b: ComboboxItem) {
    return a.label?.localeCompare(b.label ?? "") ?? 0
}

interface Person {
    username: string
    firstname?: string | null
    lastname?: string | null
}

export function byPerson(a: Person, b: Person) {
    const result = (a.firstname ?? a.username).localeCompare(b.firstname ?? b.username);

    if (!a.lastname || !b.lastname) {
        return result
    }

    return result !== 0 ? result : a.lastname.localeCompare(b.lastname);
}
