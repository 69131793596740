import { byDateTime } from "@/common/Sort";
import { UUID } from "@/common/Types";
import { PaperStack } from "@/components/shared";
import { Routes } from "@/routing/Routes";
import { Grid, Stack, Text, Title } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface WorkoutType {
    uuid: UUID
    date: DateTime
    name: string
    publishingDate: DateTime
}

interface UnpublishedWorkoutListProps {
    workouts: WorkoutType[]
}

export function UnpublishedWorkoutList(props: UnpublishedWorkoutListProps) {
    const { workouts } = props

    const { t } = useTranslation()

    if (workouts.length === 0) {
        return null
    }

    return (
        <Stack>
            <Title order={ 4 }>{ t("pages:WorkoutsPage.Unpublished") }</Title>

            <PaperStack>
                {
                    workouts
                        .sort((a, b) => byDateTime(b.date, a.date))
                        .map(it => (
                            <PaperStack.Row key={ it.uuid } component={ Link } to={ generatePath(Routes.WORKOUTS_BY_ID, { uuid: it.uuid }) }>
                                <Workout workout={ it } />
                            </PaperStack.Row>
                        ))
                }
            </PaperStack>

        </Stack>
    )
}

interface WorkoutProps {
    workout: WorkoutType
}

function Workout(props: WorkoutProps) {
    const { workout } = props

    return (
        <Grid align="center">
            <Grid.Col span="content" miw={ 80 }>
                <Text c="dimmed" size="sm">{ workout.date.toFormat("EEE dd.MM") }</Text>
            </Grid.Col>

            <Grid.Col span="auto">
                <Text fw={ 500 }>{ workout.name }</Text>
            </Grid.Col>
        </Grid>
    )
}
