import { userLocale } from "@/common/Format";
import { DateTime, Duration, Settings } from "luxon";

Settings.defaultLocale = userLocale
Settings.throwOnInvalid = true;

declare module "luxon" {
    interface TSSettings {
        throwOnInvalid: true;
    }
}

export function toDuration(value: string | null | undefined): Duration | null {
    if (value === undefined || value === null) {
        return null
    }

    if (value.length === 0) {
        return null
    }

    return Duration.fromISO(value)
}

export function toDateTime(value: string | null | undefined): DateTime | null {
    if (value === undefined || value === null) {
        return null
    }

    if (value.length === 0) {
        return null
    }

    return DateTime.fromISO(value)
}
