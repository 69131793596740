import { MMarkdown } from "@/components/shared";
import { MembershipRequestState } from "@/generated/graphql";
import { Alert, Text } from "@mantine/core";
import { IconClock } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


interface OwnerApprovalHintProps {
    approvalConfiguration: {
        description: string | null
    }
    membershipRequest: {
        state: MembershipRequestState
    }
}

/**
 * Shown to Member
 */
export function OwnerApprovalHint(props: OwnerApprovalHintProps) {
    const { approvalConfiguration, membershipRequest } = props

    const { t } = useTranslation()

    if (membershipRequest.state !== MembershipRequestState.OwnerApprovalPending) {
        return null
    }

    return (
        <Alert variant="light" title={ t("components:Memberships.OwnerApprovalHint.Waiting for approval") } icon={ <IconClock /> }>
            <Text>{ t("components:Memberships.OwnerApprovalHint.This membership must be approved") }</Text>
            { approvalConfiguration.description && (
                <>
                    <Text fw={ 500 } mt="xs">{ t("components:Memberships.OwnerApprovalHint.Further information") }:</Text>
                    <MMarkdown fs="italic" text={ approvalConfiguration.description } />
                </>
            ) }
        </Alert>
    )
}
