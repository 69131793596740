import { useDeviceSize } from "@/contexts/device-size";
import { AdministrationNavbarNodes } from "@/layout/administration/AdministrationNavbarNodes";
import { DesktopNavbar } from "@/layout/shared/DesktopNavbar";
import { MobileNavbar } from "@/layout/shared/MobileNavbar";


interface AdministrationNavbarProps {
    data: {
        activeMemberCount: number | null
        membershipRequestCount: number | null
    }
}

export function AdministrationNavbar(props: AdministrationNavbarProps) {
    const { data } = props

    const { isMobile } = useDeviceSize()

    if (isMobile) {
        return <MobileNavbar itemNodes={ <AdministrationNavbarNodes data={ data } /> } />
    } else {
        return <DesktopNavbar itemNodes={ <AdministrationNavbarNodes data={ data } /> } />
    }
}
