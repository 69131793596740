import { userLocale } from "@/common/Format"
import { Avatar, Container, createTheme, Divider, Grid, Input, MantineColor, NumberInput, Text } from "@mantine/core";
import classes from "./Mantine.module.scss"


export const MantineColors: MantineColor[] = [
    "dark", "gray", "red", "pink", "grape", "violet", "indigo", "blue", "cyan", "green", "lime", "yellow", "orange", "teal"
]

export const Theme = createTheme({
    colors: {
        // https://v7.mantine.dev/colors-generator?color=7CCDB0
        // #7CCDB0
        brand: [
            "#e4fcf5",
            "#d7f4e9",
            "#b4e5d3",
            "#8dd4bc",
            "#6dc7a7",
            "#58bf9a",
            "#4bbb94",
            "#3aa57f",
            "#2d9371",
            "#187f5f"
        ],
    },
    primaryShade: 7,
    primaryColor: "brand",

    headings: {
        sizes: {
            h4: { fontWeight: "600" },
        },
    },

    components: {
        Avatar: Avatar.extend({
            defaultProps: {
                radius: "sm"
            }
        }),

        Container: Container.extend({
            defaultProps: {
                size: "xl"
            },

            classNames: {
                root: classes.containerRoot
            }
        }),

        Divider: Divider.extend({
            classNames: {
                label: classes.dividerLabel
            }
        }),

        Input: Input.extend({
            classNames: {
                input: classes.inputInput
            }
        }),

        Grid: Grid.extend({
            defaultProps: {
                overflow: "visible"
            }
        }),

        NumberInput: NumberInput.extend({
            defaultProps: {
                decimalSeparator: [ "de", "de-DE" ].includes(userLocale) ? "," : undefined
            }
        }),

        Text: Text.extend({
            classNames: {
                root: classes.textRoot
            }
        })
    }
})
