import { toDateTime } from "@/common/Luxon"
import { useInAppNotificationsContext } from "@/contexts/in-app-notification";
import { InAppNotification, useMarkInAppNotificationAsSeenMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { MantineSize, Stack, Text, UnstyledButton } from "@mantine/core";
import cx from "clsx";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import classes from "./InAppNotificationItemLinked.module.scss";


interface InAppNotificationItemLinkedProps {
    messageFontSize?: MantineSize
    notification: InAppNotification
    to: string
}

export function InAppNotificationItemLinked(props: PropsWithChildren<InAppNotificationItemLinkedProps>) {
    const { children, messageFontSize, notification, to } = props

    const { refetch } = useInAppNotificationsContext()
    const [ markInAppNotificationAsSeen ] = useMarkInAppNotificationAsSeenMutation()

    const markAsSeen = () => {
        void executeMutation({
            mutation: () => markInAppNotificationAsSeen({
                variables: { uuid: notification.uuid }
            }),
            onSuccess: refetch
        })
    }

    const className = cx({
        [classes.notification]: true,
        [classes.isUnseen]: !notification.seen
    })

    return (
        <div className={ className }>
            <UnstyledButton component={ Link } to={ to } onClick={ markAsSeen }>
                <Stack gap={ 0 }>
                    <Text span className={ classes.created } size="xs" color="dimmed">{ toDateTime(notification.created)?.toFormat("EEE dd.MM HH:mm") }</Text>
                    <Text span className={ classes.message } size={ messageFontSize }>{ children }</Text>
                </Stack>
            </UnstyledButton>
        </div>
    )
}
