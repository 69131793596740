import { NavigationItem } from "@/components/navigation";
import { Group, Text, UnstyledButton } from "@mantine/core";
import cx from "clsx";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import classes from "./NavbarStyles.module.scss"


interface NavbarItemProps {
    item: NavigationItem
}

export function NavbarItem(props: NavbarItemProps) {
    const { item } = props

    const { t } = useTranslation()

    return (
        <UnstyledButton component={ NavLink } end={ item.end } to={ item.to } className={ cx("mantine-active", "mantine-focus-auto", classes.link) }>
            <Group gap="xs" wrap="nowrap">
                { item.icon && <item.icon className={ classes.linkIcon } stroke={ 1.5 } /> }
                <Text size="sm" fw={ 500 }>{ t(item.labelKey) }</Text>
                { item.badge }
            </Group>
        </UnstyledButton>
    )
}
