import { DEFAULT_UUID } from "@/common/Object";
import { UUID } from "@/common/Types";
import { Alert, Group, Radio, SimpleGrid, Stack, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { Trans, useTranslation } from "react-i18next";
import classes from "./MembershipCheckoutModeFormSection.module.scss"


interface MembershipCheckoutModeFormSectionData {
    checkoutMode: null | string
}

interface MembershipCheckoutModeFormSectionProps {
    form: { getInputProps: UseFormReturnType<MembershipCheckoutModeFormSectionData>["getInputProps"] }
    myMembershipsAllowedForFollowUp: {
        uuid: UUID,
        name: string,
    }[]
}

export function MembershipCheckoutModeFormSection(props: MembershipCheckoutModeFormSectionProps) {
    const { myMembershipsAllowedForFollowUp, form } = props

    const { t } = useTranslation()

    const checkoutModeOptions = [
        {
            value: DEFAULT_UUID,
            label: t("components:Memberships.SwitchMembershipCard.New Membership")
        },
        ...myMembershipsAllowedForFollowUp.map(it => ({
            value: it.uuid,
            label: <Trans i18nKey="components:Memberships.SwitchMembershipCard.Switch from" values={ { name: it.name } } shouldUnescape={ true } />
        }))
    ]

    return (
        <Stack>
            <Alert>
                <Text>
                    { t("components:Memberships.SwitchMembershipCard.Select Mode") }
                </Text>
            </Alert>

            <Radio.Group className={ classes.groupRoot } mt={ 4 } data-testid="checkoutMode-group" { ...form.getInputProps("checkoutMode") } >
                <SimpleGrid cols={ { xs: 1, sm: 2 } }>
                    {
                        checkoutModeOptions.map(it => (
                            <Radio.Card key={ it.value } className={ classes.cardRoot } value={ it.value } data-testid={ `checkoutMode-${ it.value }` } p="md">
                                <Group wrap="nowrap" align="center">
                                    <Radio.Indicator />
                                    <Text>{ it.label }</Text>
                                </Group>
                            </Radio.Card>
                        ))
                    }
                </SimpleGrid>
            </Radio.Group>
        </Stack>
    )
}
