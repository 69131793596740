import { RouterLoadingOverlay } from "@/layout/main/RouterLoadingOverlay"
import { BottomNavbar } from "@/layout/shared/bottom-navbar";
import { RemoveScroll } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { MainFooter } from "./MainFooter";
import MainHeader from "./MainHeader";
import classes from "./MainLayout.module.scss"


interface MainLayoutOutletContext {
    isNavigationOpen: boolean
    closeNavigation: () => void
}

export function useMainLayoutOutletContext() {
    return useOutletContext<MainLayoutOutletContext>();
}

export function MainLayout() {
    const location = useLocation()

    const [ isNavigationOpen, setIsNavigationOpen ] = useState(false)

    // Close navigation on location change
    useEffect(() => {
        setIsNavigationOpen(false)
        closeAllModals()
    }, [ location ])

    const closeNavigation = () => {
        setIsNavigationOpen(false)
    }

    return (
        <>
            <RemoveScroll enabled={ isNavigationOpen }>
                <MainHeader isNavigationOpen={ isNavigationOpen } onBurgerClick={ () => setIsNavigationOpen(v => !v) } />

                <main className={ classes.content }>
                    <Outlet context={ { isNavigationOpen: isNavigationOpen, closeNavigation: closeNavigation } } />
                </main>

                <MainFooter />
            </RemoveScroll>

            <BottomNavbar />

            <RouterLoadingOverlay />
        </>
    )
}

