import { MyInAppNotificationsCardContainer } from "@/components/core/in-app-notification";
import { useNavigationItems } from "@/components/navigation";
import { useAuthentication } from "@/contexts/authentication";
import { useInAppNotificationsContext } from "@/contexts/in-app-notification";
import { NavbarItem } from "@/layout/shared/NavbarItem";
import { Routes } from "@/routing/Routes";
import { Avatar, Badge, Group, Popover, Text, UnstyledButton } from "@mantine/core";
import { IconBell, IconBellRinging } from "@tabler/icons-react";
import cx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./NavbarStyles.module.scss"


export function UserSection() {
    const { t } = useTranslation()

    const { me } = useAuthentication()
    const navigation = useNavigationItems()
    const { hasUnseen, unseenCount } = useInAppNotificationsContext()

    if (me) {
        return (
            <>
                <UnstyledButton component={ Link } to={ Routes.MY } className={ classes.link }>
                    <Group gap="xs">
                        <Avatar size={ 24 } color="brand" variant="filled">{ me.username.slice(0, 2) }</Avatar>
                        <Text size="sm" fw={ 500 }>
                            { me.username }
                        </Text>
                    </Group>
                </UnstyledButton>

                <Popover width={ 500 } position="right" withArrow arrowSize={ 12 }>
                    <Popover.Target>
                        <UnstyledButton className={ cx("mantine-active", "mantine-focus-auto", classes.link) }>
                            <Group gap="xs" wrap="nowrap">
                                { hasUnseen
                                    ? <IconBellRinging className={ classes.linkIcon } stroke={ 1.5 } />
                                    : <IconBell className={ classes.linkIcon } stroke={ 1.5 } />
                                }
                                <Text size="sm" fw={ 500 }>{ t("common:Navigation.Notifications") }</Text>
                                { unseenCount > 0 && <Badge size="sm" circle>{ unseenCount }</Badge> }
                            </Group>
                        </UnstyledButton>
                    </Popover.Target>
                    <Popover.Dropdown className={ classes.inAppNotificationPopover }>
                        <MyInAppNotificationsCardContainer />
                    </Popover.Dropdown>
                </Popover>

                <NavbarItem item={ navigation.items.logout } />
            </>
        )
    } else {
        return (
            <>
                <NavbarItem item={ navigation.items.login } />
                <NavbarItem item={ navigation.items.register } />
            </>
        )
    }
}
