import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes"
import { Menu } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

interface ToProfileItemProps {
    memberUUID: UUID
}

export function ToProfileItem(props: ToProfileItemProps) {
    const { memberUUID } = props

    const { t } = useTranslation()

    return (
        <Menu.Item component={ Link } to={ generatePath(Routes.ADMIN_MEMBERS_BY_ID, { uuid: memberUUID }) }>
            { t("components:Events.EventRegistrationList.To profile") }
        </Menu.Item>
    )
}
