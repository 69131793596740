export function range(start: number, end: number, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i);
}

Array.prototype.minBy = function <T>(selector: (it: T) => number): T {
    return this.extremumBy(selector, Math.min);
};

Array.prototype.maxBy = function <T>(selector: (it: T) => number): T {
    return this.extremumBy(selector, Math.max);
}

interface ExtremumByCarrier<T> {
    best: number
    next: T
}

Array.prototype.extremumBy = function <T>(selector: (it: T) => number, extremum: (...values: number[]) => number): T {
    return this.reduce((carrier: ExtremumByCarrier<T> | null, next: T) => {
        const current = { best: selector(next), next: next };
        if (!carrier) {
            return current
        } else if (extremum.apply(null, [ carrier.best, current.best ]) == carrier.best) {
            return carrier
        } else {
            return current
        }
    }, null).next
}

Array.prototype.firstOrNull = function <T>(selector?: (it: T) => boolean): T | null {
    const filtered = this.filter(selector ?? (() => true))

    if (filtered.length == 0) {
        return null
    }

    return filtered[0]
}

Array.prototype.groupBy = function <T extends object, K extends string>(key: (item: T) => K): Record<K, T[]> {
    return this.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);
}
