import logger from "@/common/Logger";
import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
    hasAnyPermission?: boolean
    isAuthenticated?: boolean
    permission?: MemberPermission
}

export function ProtectedRoute(props: ProtectedRouteProps) {
    const { hasAnyPermission, isAuthenticated, permission } = props

    const { hasAnyPermission: hasAnyPermissionCtx, hasPermission, isAuthenticated: isAuthenticatedCtx } = useAuthentication()
    const location = useLocation()

    if (hasAnyPermission !== undefined && hasAnyPermission === hasAnyPermissionCtx) {
        return <Outlet />
    }

    if (permission !== undefined && hasPermission(permission)) {
        return <Outlet />
    }

    if (isAuthenticated !== undefined && isAuthenticated === isAuthenticatedCtx) {
        return <Outlet />
    }

    logger.warn(`Unauthorized access to "${ location.pathname }"`)
    return <Navigate to={ Routes.ROOT } />
}
