import { EventState } from "@/generated/graphql";
import { Group, Stack, Text } from "@mantine/core";
import cx from "clsx";
import React from "react";
import classes from "./EventLineItem.module.scss"


interface EventLineItemProps {
    from: string
    miw?: number | null
    color?: string | null
    name: string
    rightSection?: React.ReactNode | null
    state?: EventState
    to?: string | null
    withBorder?: boolean
}

export function EventLineItem(props: EventLineItemProps) {
    const { color, state, withBorder } = props

    const eventClasses = [ classes.event ]
    if (color) {
        eventClasses.push(classes.borderedLeft)
    }
    if (withBorder) {
        eventClasses.push(classes.bordered)
    }
    if (state === EventState.Cancelled) {
        eventClasses.push(classes.cancelled)
    }
    if (state === EventState.Finished) {
        eventClasses.push(classes.finished)
    }

    return (
        <div className={ cx(...eventClasses) } style={ { borderLeftColor: color ?? undefined } }>
            <EventLineItemInner { ...props } />
        </div>
    )
}

function EventLineItemInner(props: EventLineItemProps) {
    const { from, miw, name, rightSection, to } = props

    return (
        <Group justify="space-between" wrap="nowrap">

            <Group wrap="nowrap">
                <Stack gap={ 0 } miw={ miw ?? undefined } mih={ 45 } justify="center" align="flex-end">
                    <Text c="dimmed" size="sm" style={ { fontVariant: "tabular-nums", whiteSpace: "nowrap" } }>{ from }</Text>
                    <Text c="dimmed" size="sm" style={ { fontVariant: "tabular-nums", whiteSpace: "nowrap" } }>{ to }</Text>
                </Stack>

                <Text fw={ 500 } className={ classes.eventName }>{ name }</Text>
            </Group>

            { rightSection }

        </Group>
    )
}
