import Logger from "@/common/Logger"
import {
    ConfirmationErrorReason,
    ConfirmationErrorResponseDTO,
    ConfirmationInput,
    ConfirmationResponseDTO,
    SignInErrorReason,
    SignInErrorResponseDTO,
    SignInInput,
    SignInResponseDTO,
    TouchErrorReason,
    TouchErrorResponseDTO,
    TouchResponseDTO
} from "@/components/authentication/Types"
import { AppProperties } from "@/configuration/AppProperties"
import fetchRetry from "fetch-retry";

async function confirm(input: ConfirmationInput): Promise<ConfirmationResponseDTO> {
    const fetch = fetchRetry(window.fetch)

    try {
        const response = await fetch(`${ AppProperties().backendUrl }/auth/confirm/${ input.code }`, {
            method: "GET",
            credentials: "include",
            retryDelay: 250
        })

        return (await response.json()) as ConfirmationResponseDTO
    } catch (e: unknown) {
        Logger.error("Failed to confirm", e)

        return {
            isSuccess: false,
            errorReason: ConfirmationErrorReason.FETCH_ERROR
        } satisfies ConfirmationErrorResponseDTO
    }
}

async function signIn(input: SignInInput): Promise<SignInResponseDTO> {
    const fetch = fetchRetry(window.fetch)

    try {
        const response = await fetch(`${ AppProperties().backendUrl }/auth/sign-in`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ emailAddress: input.emailAddress, password: input.password }),
            headers: {
                "Content-Type": "application/json"
            },
            retryDelay: 250
        })

        return (await response.json()) as SignInResponseDTO
    } catch (e: unknown) {
        Logger.error("Failed to sign in", e)

        return {
            isSuccess: false,
            errorReason: SignInErrorReason.FETCH_ERROR
        } satisfies SignInErrorResponseDTO
    }
}

async function touch(): Promise<TouchResponseDTO> {
    const fetch = fetchRetry(window.fetch)

    try {
        const response = await fetch(`${ AppProperties().backendUrl }/auth/touch`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            retryDelay: 250
        })

        return (await response.json()) as TouchResponseDTO
    } catch (e: unknown) {
        Logger.error("Touch failed", e)

        return {
            isSuccess: false,
            errorReason: TouchErrorReason.FETCH_ERROR
        } satisfies TouchErrorResponseDTO
    }
}

async function signOut(): Promise<void> {
    const fetch = fetchRetry(window.fetch)

    try {
        await fetch(`${ AppProperties().backendUrl }/auth/sign-out`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            retryDelay: 250
        })
    } catch (e: unknown) {
        Logger.error("Failed to sign out", e)
    }
}

export const AuthenticationAPI = {
    confirm: confirm,
    signIn: signIn,
    touch: touch,
    signOut: signOut
}
