import { InAppNotificationItem, MarkAllInAppNotificationsAsSeenButton } from "@/components/core/in-app-notification";
import { ContentCard } from "@/components/shared";
import { useAuthentication } from "@/contexts/authentication";
import { InAppNotificationsContextType, useInAppNotificationsContext } from "@/contexts/in-app-notification";
import { Badge, Box, Card, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from "./BottomNavbar.module.scss"


export function InAppNotificationsSection() {
    const { isAuthenticated } = useAuthentication()

    if (isAuthenticated) {
        return <InAppNotificationsSectionAuthenticatedContainer />
    } else {
        return null
    }
}

export function InAppNotificationsSectionAuthenticatedContainer() {
    const context = useInAppNotificationsContext()

    return (
        <InAppNotificationsSectionAuthenticated context={ context } />
    )
}

interface InAppNotificationsSectionAuthenticatedProps {
    context: InAppNotificationsContextType
}

export function InAppNotificationsSectionAuthenticated(props: InAppNotificationsSectionAuthenticatedProps) {
    const { context } = props

    const { t } = useTranslation()

    const { unseenCount, notifications, refetch } = context

    if (notifications.length == 0) {
        return null
    }

    return (
        <Box className={ classes.bottomExtension } p={ 0 }>
            <ContentCard padding={ 8 } radius={ 0 } shadow={ undefined }>
                <Card.Section withBorder inheritPadding py={ 8 }>
                    <Group wrap="nowrap" justify="space-between">
                        <Group wrap="nowrap" gap={ 4 } align="center">
                            <Text fw={ 600 } size="md">{ t("components:Core.MyInAppNotificationsCard.Title") }</Text>
                            { unseenCount > 0 && <Badge size="sm" circle>{ unseenCount }</Badge> }
                        </Group>
                        { unseenCount > 0 && <MarkAllInAppNotificationsAsSeenButton onSuccess={ refetch } /> }
                    </Group>
                </Card.Section>

                <Card.Section inheritPadding py={ 8 } h="100%" mah={ 250 } style={ { overflow: "auto" } }>
                    <Stack gap="xs">
                        { notifications.map(it => (<InAppNotificationItem key={ it.uuid } inAppNotification={ it } messageFontSize="sm" />)) }
                    </Stack>
                </Card.Section>
            </ContentCard>
        </Box>
    )
}
