import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { Button, Group, Radio, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export enum DeleteMode {
    EVENT = "Event",
    EVENT_AND_END_RECURRENCE = "EventAndEndRecurrence"
}

export interface DeleteEventFormData {
    deleteMode: DeleteMode
}

interface DeleteEventFormProps<TSuccessData> {
    onSubmit: (input: DeleteEventFormData) => Promise<FormSubmitResult<TSuccessData>>
    onCancel: () => void
}

export function DeleteEventForm<TSuccessData>(props: DeleteEventFormProps<TSuccessData>) {
    const { onSubmit, onCancel } = props

    const { t } = useTranslation()

    const form = useMyPowerHourForm<DeleteEventFormData>({
        initialValues: {
            deleteMode: DeleteMode.EVENT
        }
    })

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <Stack gap="xs">
                    <Text>{ t("components:Events.DeleteEvent.What do you want to delete") }</Text>

                    <Radio.Group { ...form.getInputProps("deleteMode") }>
                        <Stack gap="xs">
                            <Radio value={ DeleteMode.EVENT } label={ t("components:Events.DeleteEvent.This event") } />
                            <Radio value={ DeleteMode.EVENT_AND_END_RECURRENCE } label={ t("components:Events.DeleteEvent.This and all following events") } />
                        </Stack>
                    </Radio.Group>
                </Stack>

                <Group justify="space-between">
                    <Button variant="default" onClick={ onCancel }>
                        { t("common:Button.Cancel") }
                    </Button>

                    <Button type="submit" title="submit" color="red" loading={ form.isSubmitting }>
                        { t("common:Button.Delete") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}
