import Parse from "@/common/Parsing";
import { PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useDeviceSize } from "@/contexts/device-size";
import { GraphQLClient } from "@/contexts/graphql";
import {
    MemberPermission,
    PaginationInput,
    UnpublishedWorkoutsDocument,
    UnpublishedWorkoutsQuery,
    UnpublishedWorkoutsQueryVariables,
    WorkoutsDocument,
    WorkoutsQuery,
    WorkoutsQueryVariables,
} from "@/generated/graphql";
import { CreateWorkoutButton } from "@/pages/workouts/_components/CreateWorkoutButton";
import { UnpublishedWorkoutList } from "@/pages/workouts/_components/UnpublishedWorkoutList"
import { WorkoutConfigurationButton } from "@/pages/workouts/_components/WorkoutConfigurationButton";
import { WorkoutList } from "@/pages/workouts/_components/WorkoutList";
import { WorkoutListButton } from "@/pages/workouts/_components/WorkoutListButton"
import RoutingStorage from "@/routing/RoutingStorage";
import { ApolloQueryResult } from "@apollo/client";
import { Center, Group, Pagination, Stack } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { LoaderFunctionArgs, useLoaderData, useSearchParams } from "react-router-dom";


const LIMIT = 7
const SP_PAGE = "page";

interface WorkoutsPageData {
    workouts: WorkoutsQuery["workouts"]
    unpublishedWorkouts: UnpublishedWorkoutsQuery["unpublishedWorkouts"] | null
}

export async function WorkoutsPageLoader(args: LoaderFunctionArgs): Promise<WorkoutsPageData> {
    if (RoutingStorage.hasPermission(MemberPermission.WorkoutManager)) {
        return WorkoutsPageManagerLoader(args)
    } else {
        return WorkoutsPageDefaultLoader(args)
    }
}

async function WorkoutsPageDefaultLoader({ request }: LoaderFunctionArgs): Promise<WorkoutsPageData> {
    const url = new URL(request.url);
    const page = Parse.intOrDefault(1, url.searchParams.get(SP_PAGE))

    const pagination: PaginationInput = {
        limit: LIMIT,
        offset: LIMIT * (page - 1)
    }

    const workouts = await GraphQLClient.query<WorkoutsQuery, WorkoutsQueryVariables>({
        query: WorkoutsDocument,
        variables: { filter: { date: null, workoutTypeUUID: null }, pagination: pagination },
    })

    return {
        workouts: workouts.data.workouts,
        unpublishedWorkouts: null
    }
}

async function WorkoutsPageManagerLoader({ request }: LoaderFunctionArgs): Promise<WorkoutsPageData> {
    const url = new URL(request.url);
    const page = Parse.intOrDefault(1, url.searchParams.get(SP_PAGE))

    const pagination: PaginationInput = {
        limit: LIMIT,
        offset: LIMIT * (page - 1)
    }

    const workouts = GraphQLClient.query<WorkoutsQuery, WorkoutsQueryVariables>({
        query: WorkoutsDocument,
        variables: { filter: { date: null, workoutTypeUUID: null }, pagination: pagination },
    })

    let unpublishedWorkouts = Promise.resolve<ApolloQueryResult<UnpublishedWorkoutsQuery> | null>(null)
    if (page == 1) {
        unpublishedWorkouts = GraphQLClient.query<UnpublishedWorkoutsQuery, UnpublishedWorkoutsQueryVariables>({
            query: UnpublishedWorkoutsDocument,
            variables: {},
        })
    }

    return {
        workouts: (await workouts).data.workouts,
        unpublishedWorkouts: (await unpublishedWorkouts)?.data.unpublishedWorkouts ?? null
    }
}

export function WorkoutsPage() {
    const queryResult = useLoaderData() as WorkoutsPageData

    const { t } = useTranslation()
    const { isMobile } = useDeviceSize()

    const [ searchParams, setSearchParam ] = useSearchParams();
    const page = Parse.intOrDefault(1, searchParams.get(SP_PAGE))

    const workouts = queryResult.workouts.results.map(it => (
        {
            uuid: it.uuid,
            date: DateTime.fromISO(it.date),
            publishingDate: DateTime.fromISO(it.publishingDate),
            name: it.workoutType?.name ?? it.title ?? "Workout",
            scoreBoards: it.scoreBoards.map(it => ({
                results: it.results.map(it => ({
                    uuid: it.uuid
                }))
            }))
        })
    )

    const unpublishedWorkouts = queryResult.unpublishedWorkouts?.results?.map(it => (
        {
            uuid: it.uuid,
            date: DateTime.fromISO(it.date),
            publishingDate: DateTime.fromISO(it.publishingDate),
            name: it.workoutType?.name ?? it.title ?? "Workout",
        })
    )

    const changeToPage = (newPage: number) => {
        searchParams.set(SP_PAGE, newPage.toString())
        setSearchParam(searchParams)
    }

    return (
        <>
            <MetaTitle title={ t("pages:WorkoutsPage.Title") } />

            <Stack>
                <Group justify="space-between" align="start">
                    <PageTitle title={ t("pages:WorkoutsPage.Title") } />

                    <Group>
                        <CreateWorkoutButton />
                        <WorkoutListButton />
                        <WorkoutConfigurationButton />
                    </Group>
                </Group>

                { unpublishedWorkouts && (<UnpublishedWorkoutList workouts={ unpublishedWorkouts } />) }

                <WorkoutList workouts={ workouts } />

                <Center>
                    <Pagination total={ Math.ceil(queryResult.workouts.totalCount / LIMIT) }
                                value={ page }
                                onChange={ changeToPage }
                                withControls={ !isMobile }
                                withEdges={ !isMobile } />
                </Center>
            </Stack>
        </>
    )
}
