import { toDateTime } from "@/common/Luxon"
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import W from "@/common/Workout"
import { UpdateWorkoutResultForm } from "@/components/workouts/result/UpdateWorkoutResultForm";
import { useAuthentication } from "@/contexts/authentication";
import { WorkoutResultType } from "@/generated/graphql";
import { Reaction, WorkoutResultReactions } from "@/pages/workouts/[uuid]/_components/WorkoutResultReactions";
import { Box, Center, Divider, Grid, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { closeModal, openModal } from "@mantine/modals";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";
import classes from "./WorkoutResults.module.scss"

export interface Result {
    uuid: UUID
    score: string
    comment: string | null
    category: { uuid: UUID, name: string } | null
    creator: { uuid: UUID, username: string } | null
    reactions: readonly Reaction[]
}

interface WorkoutResultsProps {
    results: readonly Result[]
    resultType: WorkoutResultType
    resultCategories: readonly { uuid: UUID, name: string }[]
    submissionEnd: string | null
}

export function WorkoutResults(props: WorkoutResultsProps) {
    const { results, resultType, resultCategories, submissionEnd } = props

    const { t } = useTranslation()

    if (results.length === 0) {
        return (
            <Center>
                <Text size="sm">
                    { t("pages:WorkoutPage.No Results") }
                </Text>
            </Center>
        )
    }

    return (
        <Stack gap="sm">
            {
                results.map((it, i) => (
                    <React.Fragment key={ it.uuid }>
                        <WorkoutResult index={ i } result={ it } resultCategories={ resultCategories } resultType={ resultType } submissionEnd={ submissionEnd } />
                        { i < results.length - 1 && <Divider /> }
                    </React.Fragment>
                ))
            }

        </Stack>
    )
}

interface WorkoutResultProps {
    index: number
    result: Result
    resultType: WorkoutResultType
    resultCategories: readonly { uuid: UUID, name: string }[]
    submissionEnd: string | null
}

const UPDATE_RESULT_MODAL_ID = "update-workout-result"

function WorkoutResult(props: WorkoutResultProps) {
    const { index, result, resultType, resultCategories, submissionEnd } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const { me, isAuthenticated } = useAuthentication()

    const onSubmitSuccess = () => {
        showSuccessNotification(t("pages:WorkoutPage.Result updated"))
        revalidator.revalidate()
        closeModal(UPDATE_RESULT_MODAL_ID)
    }

    const onSubmitError = () => {
        showErrorNotification({ message: t("pages:WorkoutPage.Failed to update Result") })
    }

    const onDeleteSuccess = () => {
        showSuccessNotification(t("pages:WorkoutPage.Result deleted"))
        revalidator.revalidate()
        closeModal(UPDATE_RESULT_MODAL_ID)
    }

    const onDeleteError = () => {
        showErrorNotification({ message: t("pages:WorkoutPage.Failed to delete Result") })
    }

    const onUpdateResult = () => {
        const initialValues = {
            uuid: result.uuid,
            score: result.score,
            comment: result.comment ?? "",
            workoutResultCategoryUUID: result.category?.uuid ?? ""
        }

        openModal({
            modalId: UPDATE_RESULT_MODAL_ID,
            title: <Text size="lg" fw={ 600 }>{ t("pages:WorkoutPage.Update Result") }</Text>,
            children: <UpdateWorkoutResultForm uuid={ result.uuid }
                                               initialValues={ initialValues }
                                               resultType={ resultType }
                                               resultCategories={ resultCategories }
                                               onSubmitSuccess={ onSubmitSuccess }
                                               onSubmitError={ onSubmitError }
                                               onDeleteSuccess={ onDeleteSuccess }
                                               onDeleteError={ onDeleteError } />,
            closeOnClickOutside: false
        })
    }

    const isResultOfUser = Boolean(me && me.uuid === result.creator?.uuid)

    const isSubmissionAllowed = (() => {
        const date = toDateTime(submissionEnd);
        return date === null || DateTime.now() < date
    })()

    const reactions = isAuthenticated && (
        <Group gap="xs">
            <WorkoutResultReactions disabled={ isResultOfUser }
                                    reactions={ result.reactions }
                                    resultUUID={ result.uuid } />
        </Group>
    )

    let resultContent = (
        <div>
            <Grid>
                <Grid.Col span="auto">
                    <Text span fw={ 600 }>{ result.creator?.username ?? t("components:Workouts.WorkoutResults.Member") }</Text>
                </Grid.Col>

                <Grid.Col span="content">
                    <Text span fw={ 600 } style={ { fontVariantNumeric: "tabular-nums" } }>{ W.decodeScore(resultType, result.score) }</Text>
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span="auto" pt={ 0 }>
                    {
                        isAuthenticated && (
                            <Text span c="dimmed" size="sm">{ result.comment }</Text>
                        )
                    }
                </Grid.Col>

                {
                    result.category && (
                        <Grid.Col span="content" pt={ 0 }>
                            <Text span c="dimmed" size="sm">{ result.category.name }</Text>
                        </Grid.Col>
                    )
                }
            </Grid>
        </div>
    )

    if (isResultOfUser && isSubmissionAllowed) {
        resultContent = (
            <UnstyledButton onClick={ onUpdateResult }>
                { resultContent }
            </UnstyledButton>
        )
    }

    return (
        <Box className={ classes.resultItemGrid }>
            <Text span fw={ 600 } style={ { fontVariantNumeric: "tabular-nums" } }>{ index + 1 }.</Text>

            { resultContent }

            <Box className={ classes.resultReactionRow }>
                { reactions }
            </Box>
        </Box>
    )
}
