import { OrganisationNotFoundPage } from "@/contexts/organisation/OrganisationNotFoundPage"
import { CloudinaryFile } from "@/generated/graphql";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Meta, Title } from "react-head";
import { useTranslation } from "react-i18next";


interface Organisation {
    uuid: string
    name: string
    nameSlug: string
    description: string | null
    logoSquare: CloudinaryFile | null
}

interface OrganisationContextType {
    isInitialized: boolean
    organisation: Organisation
}

const organisationContextDefaultValues: OrganisationContextType = {
    isInitialized: false,
    organisation: {
        uuid: "",
        name: "",
        nameSlug: "",
        description: null,
        logoSquare: null
    },
};

const OrganisationContext = React.createContext(organisationContextDefaultValues);

export function OrganisationContextProvider(props: PropsWithChildren) {
    const { children } = props

    const { t } = useTranslation()

    const parts = window.location.hostname.split(".");
    const subdomain = parts.shift();

    const [ isInitialized, setIsInitialized ] = useState<boolean>(false);
    const [ isNotFound, setIsNotFound ] = useState<boolean>(false);
    const [ organisation, setOrganisation ] = useState<Organisation>(organisationContextDefaultValues.organisation);

    useEffect(() => {
        if (isInitialized) {
            return
        }

        // TODO handle not found in main.tsx
        if (window.organisation) {
            setOrganisation({
                uuid: window.organisation.uuid,
                name: window.organisation.name,
                nameSlug: window.organisation.nameSlug,
                description: window.organisation.shortDescription ?? null,
                logoSquare: window.organisation.logoSquare ?? null
            })
            setIsNotFound(false)
            setIsInitialized(true)
        } else {
            setIsNotFound(true)
            setIsInitialized(true)
        }
    }, [ isInitialized ])

    if (isNotFound) {
        return (
            <>
                <OrganisationNotFoundPage slug={ subdomain ?? "" } />
            </>
        )
    }

    const context = {
        isInitialized: isInitialized,
        organisation: organisation,
    }

    return (
        <OrganisationContext.Provider value={ context }>
            <Title>{ organisation.name } - { t("common:Misc.AppName") }</Title>
            { organisation.description && <Meta name="description" content={ organisation.description } /> }

            { children }
        </OrganisationContext.Provider>
    );
}

export const useOrganisation = () => useContext(OrganisationContext);
