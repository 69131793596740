import { UUID } from "@/common/Types";
import { Routes } from "@/routing/Routes";
import { Menu } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface CopyWorkoutItemProps {
    workoutUUID: UUID
}

export function CopyWorkoutItem(props: CopyWorkoutItemProps) {
    const { workoutUUID } = props

    const { t } = useTranslation()

    return (
        <Menu.Item component={ Link }
                   to={ generatePath(Routes.WORKOUTS_BY_ID_COPY, { uuid: workoutUUID }) }
                   leftSection={ <IconCopy size={ 18 } /> }
                   id="copy-workout-button" data-umami-event="copy-workout-button">
            { t("pages:WorkoutPage.Copy Workout") }
        </Menu.Item>
    )
}
