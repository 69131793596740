import Logger from "@/common/Logger";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "virtual:i18next-loader"

i18n
    .use(initReactI18next)
    .init({
        resources: resources,
        lng: "de",
        fallbackLng: "de",
        debug: import.meta.env.MODE !== "production",
        ns: [ "common", "components", "entities", "pages" ],
        defaultNS: "common",
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: [ "br", "strong", "i" ],
        },
        saveMissing: import.meta.env.MODE === "production",
        missingKeyHandler: (lng, ns, key) => {
            Logger.warn(`Missing i18n key: ${ lng.join(",") } - ${ ns }.${ key }`)
        },
    });

export default i18n;
