import { UUID } from "@/common/Types";
import { MMarkdown } from "@/components/shared";
import { Center, SimpleGrid, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from "./PaymentMethodList.module.scss"

interface PaymentMethodListProps {
    paymentMethods: readonly PaymentMethodProps[]
}

export function PaymentMethodList(props: PaymentMethodListProps) {
    const { paymentMethods } = props

    const { t } = useTranslation()

    if (paymentMethods.length === 0) {
        return (
            <Center>
                <Text>{ t("components:Memberships.PaymentMethodList.No PaymentMethods") }</Text>
            </Center>
        )
    }

    return (
        <SimpleGrid cols={ { base: 1, sm: 2 } }>
            {
                paymentMethods
                    .slice()
                    .sort((a, b) => Number(b.isPreferred) - Number(a.isPreferred))
                    .map(it => (
                        <PaymentMethod key={ it.uuid } { ...it } />
                    ))
            }
        </SimpleGrid>
    )
}


interface PaymentMethodProps {
    uuid: UUID

    description: string | null
    isPreferred: boolean
    name: string
}

function PaymentMethod(props: PaymentMethodProps) {
    const { description, name, } = props

    return (
        <div className={ classes.paymentMethod }>
            <Stack gap={ 0 }>
                <Text fw={ 600 }>
                    { name }
                </Text>
                <Text component="div" size="sm">
                    <MMarkdown text={ description ?? "" } />
                </Text>
            </Stack>
        </div>
    )
}
