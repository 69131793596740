import { toDuration } from "@/common/Luxon";
import { __InputWrapperProps, Box, Input, NativeSelect, NumberInput } from "@mantine/core";
import { Duration } from "luxon";
import { ChangeEvent, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


type DurationUnit = "month" | "day" | "hour"

interface DurationInputProps extends __InputWrapperProps {
    allowedUnits: DurationUnit[]
    value?: string
    disabled?: boolean
    placeholder?: string | undefined;
    onChange: (value: string | undefined) => void
    "data-testid"?: string
}

export const DurationInput = memo(function DurationInputImpl(props: DurationInputProps) {
    const { label, description, required, error, "data-testid": testId } = props

    return (
        <Input.Wrapper label={ label } description={ description } withAsterisk={ required } error={ error } data-testid={ testId }>
            <Box mt={ description ? 5 : 0 }>
                <Inner { ...props } />
            </Box>
        </Input.Wrapper>
    );
})

function Inner(props: DurationInputProps) {
    const { allowedUnits, value, disabled, placeholder, onChange, error, required } = props

    if (allowedUnits.length === 0) {
        throw Error("Must provide at least one unit.")
    }

    const { t } = useTranslation("components")

    const duration = toDuration(value);
    const durationSplit = duration ? deconstruct(duration, allowedUnits) : undefined

    const [ , setInnerValue ] = useState(duration)
    const [ durationValue, setDurationValue ] = useState<number | "">(durationSplit?.value ?? "")
    const [ durationUnit, setDurationUnit ] = useState<DurationUnit>(durationSplit?.unit ?? allowedUnits[0])

    useEffect(() => {
        const duration = toDuration(value);

        setInnerValue(prevState => {
            if (duration !== prevState) {
                const durationSplit = duration ? deconstruct(duration, allowedUnits) : undefined
                setDurationValue(durationSplit?.value ?? "")
                setDurationUnit(durationSplit?.unit ?? allowedUnits[0])
                return duration
            } else {
                return prevState
            }
        })
    }, [ value, allowedUnits ])

    const onDurationValueChange = (value: number | string) => {
        let v: number | "";
        if (typeof value === "number") {
            v = value;
        } else {
            v = value === "" ? "" : parseInt(value);
        }

        setDurationValue(v)
        if (v) {
            const duration = Duration.fromObject(({ [durationUnit]: v }));
            setInnerValue(duration)
            onChange(duration.toISO())
        } else {
            setInnerValue(null)
            onChange(undefined)
        }
    }

    const onDurationUnitChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const newUnit = event.currentTarget.value as DurationUnit;
        setDurationUnit(newUnit)

        if (durationValue) {
            const duration = Duration.fromObject(({ [newUnit]: durationValue }));
            setInnerValue(duration)
            onChange(duration.toISO())
        } else {
            setInnerValue(null)
            onChange(undefined)
        }
    }

    const data = allowedUnits.map(it => ({ value: it, label: t(`DurationInput.Fields.${ it }`) }))

    return (
        <NumberInput title={ "Duration value" }
                     placeholder={ placeholder }
                     autoComplete="off"
                     min={ 0 }
                     value={ durationValue }
                     disabled={ disabled }
                     required={ required }
                     error={ error }
                     role=""
                     onChange={ onDurationValueChange }
                     rightSectionWidth={ 125 }
                     rightSection={
                         <NativeSelect title={ "Duration unit" }
                                       variant="unstyled"
                                       data={ data }
                                       value={ durationUnit }
                                       disabled={ disabled }
                                       onChange={ onDurationUnitChange }
                                       w={ 125 }
                                       rightSectionWidth={ 28 }
                                       styles={ {
                                           input: {
                                               paddingLeft: "calc(2.25rem / 3)",
                                               paddingRight: "2.25rem"
                                           },
                                       } }
                         />
                     }
        />
    )
}

function deconstruct(duration: Duration, allowedUnits: DurationUnit[]): { value: number, unit: DurationUnit } {
    const shiftedDuration = duration.shiftTo(...allowedUnits)

    for (const unit of allowedUnits) {
        const value = shiftedDuration.get(unit)
        if (value !== 0) {
            return { value: value, unit: unit }
        }
    }

    const value = shiftedDuration.as(allowedUnits[0])
    return { value: value, unit: allowedUnits[0] }
}
