import { FormSubmitResult } from "@/common/MyPowerHourForm";
import { ApolloError } from "@apollo/client";
import { FetchResult } from "@apollo/client/link/core"
import { GraphQLFormattedError } from "graphql"

interface ExecuteMutationOptions<TData> {
    mutation: () => Promise<FetchResult<TData>>
    onSuccess?: (data: TData) => void
    onError?: (errors: readonly GraphQLFormattedError[] | string) => void
}

export async function executeMutation<TData>(options: ExecuteMutationOptions<TData>): Promise<FormSubmitResult<TData>> {
    try {
        const { data, errors } = await options.mutation()

        if (errors && errors.length > 0) {
            options.onError?.(errors)
            return { isSuccess: false, error: errors }
        }

        if (!data) {
            options.onError?.("No Data")
            return { isSuccess: false, error: "No Data" }
        }

        options.onSuccess?.(data)

        return { isSuccess: true, data: data }
    } catch (error: unknown) {
        if (error instanceof ApolloError) {
            options.onError?.(error.graphQLErrors)
        }

        return { isSuccess: false, error: error }
    }
}
