import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { UpdateBenchmarkForm } from "@/components/workouts/benchmark/UpdateBenchmarkForm";
import { GraphQLClient } from "@/contexts/graphql";
import { BenchmarkDocument, BenchmarkQuery, BenchmarkQueryVariables } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom";

export async function UpdateBenchmarkPageLoader({ params }: LoaderFunctionArgs) {
    const query = await GraphQLClient.query<BenchmarkQuery, BenchmarkQueryVariables>({
        query: BenchmarkDocument,
        variables: { uuid: params.uuid! },
    })

    return {
        benchmark: query.data.benchmark
    }
}

export function UpdateBenchmarkPage() {
    const { benchmark } = useLoaderData() as { benchmark: BenchmarkQuery["benchmark"] }

    const { t } = useTranslation()
    const navigate = useNavigate()

    const onSuccess = (uuid: UUID) => {
        showSuccessNotification(t("common:Forms.Saved"))
        navigate(generatePath(Routes.MY_BENCHMARKS_BY_ID, { uuid: uuid }), { replace: true })
    }

    const onError = (error: any) => {
        showErrorNotification({ error })
    }

    const onCancel = () => {
        navigate(generatePath(Routes.MY_BENCHMARKS_BY_ID, { uuid: benchmark.uuid }))
    }

    return (
        <>
            <MetaTitle title={ benchmark.name } />

            <Stack>
                <PageTitleWithBack title={ benchmark.name } />

                <ContentCard>
                    <UpdateBenchmarkForm uuid={ benchmark.uuid }
                                         initialValues={ benchmark }
                                         hasResults={ benchmark.results.length > 0 }
                                         onSuccess={ onSuccess }
                                         onError={ onError }
                                         onCancel={ onCancel } />
                </ContentCard>
            </Stack>
        </>
    )
}
