import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { byComboboxItems } from "@/common/Sort";
import { toNullIfEmpty } from "@/common/String";
import { UUID } from "@/common/Types";
import { getInputMode } from "@/components/shared/Form"
import { WorkoutScoreHelper } from "@/components/workouts/WorkoutScoreHelper";
import { WorkoutResultInput, WorkoutResultType } from "@/generated/graphql";
import { Button, Grid, Group, NativeSelect, NumberInput, Stack, TextInput } from "@mantine/core";
import { useOs } from "@mantine/hooks";
import { useTranslation } from "react-i18next";


interface WorkoutResultFormData {
    comment: string
    score1: number | ""
    score2: number | ""
    workoutResultCategoryUUID: UUID | ""
}

interface WorkoutResultFormProps<TSuccessData> {
    initialValues: WorkoutResultInput

    resultCategories: readonly { uuid: UUID, name: string }[]
    resultType: WorkoutResultType

    onSubmit: (input: WorkoutResultInput) => Promise<FormSubmitResult<TSuccessData>>
    onCancel?: () => void
    onDelete?: () => void
}

export function WorkoutResultForm<TSuccessData>(props: WorkoutResultFormProps<TSuccessData>) {
    const { initialValues, resultCategories, resultType, onCancel, onDelete, onSubmit } = props

    const { t } = useTranslation()
    const os = useOs()

    const form = useMyPowerHourForm({
        initialValues: toFormData(resultType, initialValues),
        transformValues: v => toInput(resultType, v)
    });

    const withCategory = resultCategories.length > 0;
    const categories = resultCategories
        .map(it => ({ value: it.uuid, label: it.name }))
        .sort(byComboboxItems)

    let fields: JSX.Element;
    if (resultType === WorkoutResultType.Duration) {
        fields = (
            <>
                <Grid.Col span={ withCategory ? 4 : 6 }>
                    <NumberInput label={ t("components:Workouts.WorkoutResultForm.Minutes") }
                                 autoComplete="off"
                                 data-autofocus
                                 required
                                 min={ 0 }
                                 title="score-minutes"
                                 { ...form.getInputProps("score1") } />
                </Grid.Col>
                <Grid.Col span={ withCategory ? 4 : 6 }>
                    <NumberInput label={ t("components:Workouts.WorkoutResultForm.Seconds") }
                                 autoComplete="off"
                                 required
                                 min={ 0 }
                                 max={ 60 }
                                 title="score-seconds"
                                 { ...form.getInputProps("score2") } />
                </Grid.Col>
            </>
        )
    } else {
        fields = (
            <Grid.Col span={ withCategory ? 8 : 12 }>
                <NumberInput label={ t("entities:Workout.WorkoutResult.Fields.Score") }
                             placeholder={ t(`entities:WorkoutResultType.${ resultType }`) }
                             inputMode={ getInputMode(true, true, os) }
                             autoComplete="off"
                             data-autofocus
                             required
                             hideControls
                             decimalScale={ 2 }
                             title="score-score"
                             { ...form.getInputProps("score1") } />
            </Grid.Col>
        )
    }

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <Grid>

                    { fields }

                    { withCategory && (
                        <Grid.Col span={ 4 }>
                            <NativeSelect label={ t("entities:Workout.WorkoutResult.Fields.WorkoutResultCategory") }
                                          data={ categories }
                                          { ...form.getInputProps(`workoutResultCategoryUUID`) } />
                        </Grid.Col>
                    ) }
                </Grid>

                <TextInput label={ t("entities:Workout.WorkoutResult.Fields.Comment") }
                           autoComplete="off"
                           maxLength={ 255 }
                           { ...form.getInputProps("comment") } />

                <Group justify="space-between">
                    <Group>
                        { onCancel &&
                            <Button variant="default" onClick={ onCancel }>
                                { t("common:Button.Cancel") }
                            </Button>
                        }

                        { onDelete &&
                            <Button color="red" onClick={ onDelete }>
                                { t("common:Button.Delete") }
                            </Button>
                        }
                    </Group>

                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(resultType: WorkoutResultType, input: WorkoutResultInput): WorkoutResultFormData {
    const [ score1, score2 ] = WorkoutScoreHelper.decode(resultType, input.score);

    return {
        comment: input.comment ?? "",
        score1: score1 ?? "",
        score2: score2 ?? "",
        workoutResultCategoryUUID: input.workoutResultCategoryUUID ?? ""
    }
}

function toInput(resultType: WorkoutResultType, formData: WorkoutResultFormData): WorkoutResultInput {
    const score = WorkoutScoreHelper.encode(resultType, formData.score1, formData.score2)

    return {
        comment: toNullIfEmpty(formData.comment),
        score: score,
        workoutResultCategoryUUID: toNullIfEmpty(formData.workoutResultCategoryUUID)
    }
}
