import { useDeviceSize } from "@/contexts/device-size";
import { Box, createPolymorphicComponent, MantineSpacing, StyleProp } from "@mantine/core";
import cx from "clsx";
import { forwardRef, PropsWithChildren } from "react";
import classes from "./PaperStackRow.module.scss"


interface PaperStackChildProps extends PropsWithChildren {
    p?: StyleProp<MantineSpacing>
    className?: string;
}

const _PaperStackRow = forwardRef<HTMLDivElement, PaperStackChildProps>((props, ref) => {
    const { children, className, ...other } = props

    const { p } = useDeviceSize()

    return (
        <Box p={ p } className={ cx(classes.row, className) } { ...ref } { ...other }>
            { children }
        </Box>
    )
})

export const PaperStackRow = createPolymorphicComponent<"div", PaperStackChildProps>(_PaperStackRow);
