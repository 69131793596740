import { ContentCard } from "@/components/shared";
import { Fact } from "@/components/shared/Fact";
import { Card, SimpleGrid, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface CreditUsageCardProps {
    membershipAgreement: {
        blockedCredits: number
        usedCredits: number
    }
}

export function CreditUsageCard(props: CreditUsageCardProps) {
    const { membershipAgreement } = props

    const { t } = useTranslation()

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">
                    { t("components:Memberships.CreditUsageCard.Title") }
                </Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <SimpleGrid cols={ { base: 1, sm: 2 } }>
                    <Fact title={ t("components:Memberships.CreditUsageCard.Used credits") }>
                        { t("common:Misc.X Credits", { count: membershipAgreement.usedCredits }) }
                    </Fact>

                    <Fact title={ t("components:Memberships.CreditUsageCard.Blocked credits") }>
                        { t("common:Misc.X Credits", { count: membershipAgreement.blockedCredits }) }
                    </Fact>
                </SimpleGrid>
            </Card.Section>
        </ContentCard>
    )
}
