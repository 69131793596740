import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { toNullIfEmpty } from "@/common/String";
import { FormErrorAlert } from "@/components/shared";
import { AddressLine1Input, AdressLevel2Input, BirthdayInput, FamilyNameInput, GivenNameInput, PhoneNumberInput, PostalCodeInput } from "@/components/shared/input";
import { PersonalInformationValue, PersonInput } from "@/generated/graphql";
import { Button, Grid, Group, Stack, TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";


interface PersonalInformationFormData {
    birthday: string
    city: string
    firstname: string
    lastname: string
    phone: string
    postalCode: string
    streetAddress: string
    username: string
}

interface PersonalInformationFormProps<TSuccessData> {
    initialValues: PersonInput

    requiredPersonalInformation: readonly PersonalInformationValue[]

    onCheckUsername: (username: string) => Promise<boolean>
    onSubmit: (input: PersonInput) => Promise<FormSubmitResult<TSuccessData>>
}

export function PersonalInformationForm<TSuccessData>(props: PersonalInformationFormProps<TSuccessData>) {
    const { initialValues, requiredPersonalInformation, onCheckUsername, onSubmit } = props

    const { t } = useTranslation()

    const form = useMyPowerHourForm<PersonalInformationFormData, (values: PersonalInformationFormData) => PersonInput>({
        initialValues: toFormData(initialValues),
        name: "PersonalInformationForm",
        transformValues: toInput,
        validate: {
            birthday: validateBirthday(t("components:Account.PersonalInformationForm.Please enter a valid birthday"), requiredPersonalInformation)
        }
    })

    const [ debouncedUsername ] = useDebouncedValue(form.values.username, 250)

    const checkUsername = useCallback(async (username: string) => {
        const result = await onCheckUsername(username)
        if (result) {
            form.clearFieldError("username")
        } else {
            form.setFieldError("username", t("components:Account.PersonalInformationForm.Username already taken"))
        }
    }, [ onCheckUsername ])

    useEffect(() => {
        if (debouncedUsername === initialValues.username) {
            return;
        }

        void checkUsername(debouncedUsername);
    }, [ debouncedUsername ])

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <TextInput label={ t("entities:Person.Username") }
                           description={ t("entities:Person.Username_Desc") }
                           autoComplete="first-name"
                           spellCheck={ false }
                           required
                           { ...form.getInputProps("username") } />

                <GivenNameInput required { ...form.getInputProps("firstname") } />

                <FamilyNameInput required { ...form.getInputProps("lastname") } />

                <BirthdayInput required={ requiredPersonalInformation.includes(PersonalInformationValue.Birthday) }
                               { ...form.getInputProps("birthday") } />

                <AddressLine1Input required={ requiredPersonalInformation.includes(PersonalInformationValue.Address) }
                                   { ...form.getInputProps("streetAddress") } />

                <Grid>
                    <Grid.Col span="content">
                        <PostalCodeInput w={ 100 } maw={ 100 }
                                         required={ requiredPersonalInformation.includes(PersonalInformationValue.Address) }
                                         { ...form.getInputProps("postalCode") } />
                    </Grid.Col>
                    <Grid.Col span="auto">
                        <AdressLevel2Input required={ requiredPersonalInformation.includes(PersonalInformationValue.Address) }
                                           { ...form.getInputProps("city") } />
                    </Grid.Col>
                </Grid>

                <PhoneNumberInput required={ requiredPersonalInformation.includes(PersonalInformationValue.Phone) }
                                  { ...form.getInputProps("phone") } />

                <Group justify="flex-end">
                    { form.hasErrors && <FormErrorAlert /> }
                    <Button type="submit" title="submit" loading={ form.isSubmitting } disabled={ form.hasErrors }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(person: PersonInput): PersonalInformationFormData {
    return {
        birthday: person.birthday ?? "",
        city: person.city ?? "",
        firstname: person.firstname ?? "",
        lastname: person.lastname ?? "",
        phone: person.phone ?? "",
        postalCode: person.postalCode ?? "",
        streetAddress: person.streetAddress ?? "",
        username: person.username,
    }
}

function toInput(formData: PersonalInformationFormData): PersonInput {
    return {
        birthday: toNullIfEmpty(formData.birthday.trim()),
        city: toNullIfEmpty(formData.city.trim()),
        country: null,
        firstname: toNullIfEmpty(formData.firstname.trim()),
        lastname: toNullIfEmpty(formData.lastname.trim()),
        phone: toNullIfEmpty(formData.phone.trim()),
        postalCode: toNullIfEmpty(formData.postalCode.trim()),
        streetAddress: toNullIfEmpty(formData.streetAddress.trim()),
        username: formData.username.trim()
    }
}

function validateBirthday(error: ReactNode, requiredPersonalInformation: readonly PersonalInformationValue[]) {
    return (birthday: string) => {
        if (!requiredPersonalInformation.includes(PersonalInformationValue.Birthday)) {
            return null
        }

        if (toNullIfEmpty(birthday) === "") {
            return error
        }

        return null
    }
}
