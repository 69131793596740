import { UUID } from "@/common/Types";
import { MenuButton } from "@/components/shared";
import { useAuthentication } from "@/contexts/authentication";
import { EventState, MemberPermission } from "@/generated/graphql";
import { CancelEventItem } from "@/pages/classes/[uuid]/_components/eventMenu/CancelEventItem";
import { ConfirmEventItem } from "@/pages/classes/[uuid]/_components/eventMenu/ConfirmEventItem";
import { CopyEventItem } from "@/pages/classes/[uuid]/_components/eventMenu/CopyEventItem";
import { CreateVisitorItem } from "@/pages/classes/[uuid]/_components/eventMenu/CreateVisitorItem";
import { DeleteEventItem } from "@/pages/classes/[uuid]/_components/eventMenu/DeleteEventItem";
import { UpdateEventItem } from "@/pages/classes/[uuid]/_components/eventMenu/UpdateEventItem";
import { Divider, Menu, Stack } from "@mantine/core";
import { DateTime } from "luxon";


interface EventType {
    eventUUID: UUID
    eventDetailsUUID: UUID
    start: DateTime
    state: EventState
}

interface EventMenuProps {
    event: EventType
    onEventDeleted: () => void
}

const updatableStates = [ EventState.Pending, EventState.Confirmed ]
const registrableStates = [ EventState.Pending, EventState.Confirmed, EventState.Started ]
const confirmableStates = [ EventState.Pending ]
const cancelableStates = [ EventState.Pending, EventState.Confirmed, EventState.Started ]

export function EventMenu(props: EventMenuProps) {
    const { event, onEventDeleted } = props

    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.EventManager)) {
        return null
    }

    return (
        <Menu transitionProps={ { transition: "scale-y" } } position="bottom-end" withinPortal shadow="sm">
            <Menu.Target>
                <MenuButton />
            </Menu.Target>

            <Menu.Dropdown>
                <Stack gap={ 4 }>
                    { registrableStates.includes(event.state) && (
                        <>
                            <CreateVisitorItem eventUUID={ event.eventUUID } />
                            <Divider />
                        </>
                    ) }

                    { confirmableStates.includes(event.state) && <ConfirmEventItem eventUUID={ event.eventUUID } /> }
                    { cancelableStates.includes(event.state) && <CancelEventItem eventUUID={ event.eventUUID } /> }

                    { (confirmableStates.includes(event.state) || cancelableStates.includes(event.state)) && <Divider /> }

                    { updatableStates && <UpdateEventItem eventUUID={ event.eventUUID } /> }
                    <CopyEventItem eventUUID={ event.eventUUID } />

                    <DeleteEventItem eventUUID={ event.eventUUID } onEventDeleted={ onEventDeleted } />
                </Stack>
            </Menu.Dropdown>
        </Menu>
    )
}
