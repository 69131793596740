import { useDeviceSize } from "@/contexts/device-size";
import { ActionIcon, Button, Group, Text } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";


interface WeekPaginationProps {
    from: DateTime
    onFromChanged: (start: DateTime) => void
}

export function WeekPagination(props: WeekPaginationProps) {
    const { from, onFromChanged } = props

    const { t } = useTranslation()
    const { isMobile } = useDeviceSize()

    const onPrevWeek = () => {
        onFromChanged(from.minus({ week: 1 }))
    }

    const onToday = () => {
        if (isMobile) {
            onFromChanged(DateTime.now())
        } else {
            onFromChanged(DateTime.now().startOf("week"))
        }
    }

    const onNextWeek = () => {
        onFromChanged(from.plus({ week: 1 }))
    }

    return (
        <Group>
            <Text fw={ 600 } size="lg">{ from.toFormat("LLLL yyyy") }</Text>

            <Group gap={ 0 }>
                <ActionIcon variant="subtle" size="md" onClick={ onPrevWeek } aria-label="Previous week">
                    <Text c="dark" style={ { lineHeight: 0 } }> <IconChevronLeft /> </Text>
                </ActionIcon>

                <Button variant="subtle" size="xs" onClick={ onToday } aria-label="Today">
                    <Text fw={ 600 } size="lg" c="dark">{ t("components:Events.EventCalendar.Today") }</Text>
                </Button>

                <ActionIcon variant="subtle" size="md" onClick={ onNextWeek } aria-label="Next week">
                    <Text c="dark" style={ { lineHeight: 0 } }> <IconChevronRight /> </Text>
                </ActionIcon>
            </Group>
        </Group>
    )
}
