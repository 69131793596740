import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql";
import { Routes } from "@/routing/Routes";
import { ActionIcon } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";


export function CreateClassButton() {
    const { hasPermission } = useAuthentication()

    if (!hasPermission(MemberPermission.EventManager)) {
        return null
    }

    return (
        <ActionIcon component={ Link } to={ Routes.CLASSES_CREATE } color="brand" variant="filled" size="lg" id="create-event-button" data-umami-event="create-event-button">
            <IconPlus />
        </ActionIcon>
    )
}
