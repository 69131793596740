import Logger from "@/common/Logger";
import { useAuthentication } from "@/contexts/authentication";
import { useOrganisation } from "@/contexts/organisation";
import { MemberPermission, MembershipAgreementState } from "@/generated/graphql";
import { Alert, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface TerminationHintProps {
    membershipAgreement: {
        endDate: string | null
        state: MembershipAgreementState
    }
}

export function TerminationHint(props: TerminationHintProps) {
    const { membershipAgreement } = props

    const { t } = useTranslation()
    const { hasPermission } = useAuthentication()
    const { organisation } = useOrganisation()

    if (![ MembershipAgreementState.TerminationForced, MembershipAgreementState.TerminationRequested ].includes(membershipAgreement.state)) {
        return null
    }

    if (membershipAgreement.endDate === null) {
        Logger.error("Can't render TerminationHint without end date")
        return null
    }

    const text = (() => {
        if (hasPermission(MemberPermission.MembershipAgreementManager)) {
            if (membershipAgreement.state === MembershipAgreementState.TerminationRequested) {
                return t("components:Memberships.TerminationHint.This membership was terminated by member", { endDate: DateTime.fromISO(membershipAgreement.endDate).toFormat("dd.MM.yyyy") })
            } else if (membershipAgreement.state === MembershipAgreementState.TerminationForced) {
                return t("components:Memberships.TerminationHint.This membership was terminated", { endDate: DateTime.fromISO(membershipAgreement.endDate).toFormat("dd.MM.yyyy") })
            }
        } else {
            if (membershipAgreement.state === MembershipAgreementState.TerminationRequested) {
                return t("components:Memberships.TerminationHint.This membership was terminated by you", { endDate: DateTime.fromISO(membershipAgreement.endDate).toFormat("dd.MM.yyyy") })
            } else if (membershipAgreement.state === MembershipAgreementState.TerminationForced) {
                return t("components:Memberships.TerminationHint.This membership was terminated by organisation", {
                    organisation: organisation.name,
                    endDate: DateTime.fromISO(membershipAgreement.endDate).toFormat("dd.MM.yyyy")
                })
            }
        }
    })()

    return (
        <Alert variant="light" color="orange" title={ t("components:Memberships.TerminationHint.Termination pending") } icon={ <IconInfoCircle /> }>
            <Text>{ text }</Text>
        </Alert>
    )
}
