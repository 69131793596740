import { TextInput, TextInputProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

export function FamilyNameInput(props: TextInputProps) {
    const { t } = useTranslation()

    return (
        <TextInput label={ t("components:Shared.Input.Lastname") }
                   autoComplete="family-name"
                   autoCorrect="off"
                   spellCheck={ false }
                   { ...props } />
    )
}