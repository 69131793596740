import { MenuButton } from "@/components/shared";
import { Menu, Stack } from "@mantine/core";
import React, { PropsWithChildren } from "react";


interface MembershipRequestMenuProps {
}

export function MembershipRequestMenu(props: PropsWithChildren<MembershipRequestMenuProps>) {
    const { children } = props

    return (
        <Menu transitionProps={ { transition: "scale-y" } } position="bottom-end" withinPortal shadow="sm">
            <Menu.Target>
                <MenuButton />
            </Menu.Target>

            <Menu.Dropdown>
                <Stack gap={ 4 }>
                    { children }
                </Stack>
            </Menu.Dropdown>
        </Menu>
    )
}
