import { useAuthentication } from "@/contexts/authentication";
import { MemberPermission } from "@/generated/graphql"
import { NavbarItem } from "@/layout/shared/NavbarItem";
import { Routes } from "@/routing/Routes";
import { Badge, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";


interface AdministrationNavbarNodesProps {
    data: {
        activeMemberCount: number | null
        membershipRequestCount: number | null
    }
}

export function AdministrationNavbarNodes(props: AdministrationNavbarNodesProps) {
    const { data } = props

    const { t } = useTranslation()

    const { hasPermission } = useAuthentication()

    const showMembership = hasPermission(MemberPermission.MembershipAgreementManager)
    const showMember = hasPermission(MemberPermission.MemberManager)
    const showOrg = hasPermission(MemberPermission.OrganisationManager)

    const memberBadge = data.activeMemberCount && data.activeMemberCount > 0
        ? <Badge size="sm" variant="light">{ data.activeMemberCount }</Badge>
        : null

    const membershipRequestsBadge = data.membershipRequestCount && data.membershipRequestCount > 0
        ? <Badge size="sm" variant="light">{ data.membershipRequestCount }</Badge>
        : null

    return (
        <>
            <NavbarItem item={ { labelKey: "common:Navigation.Administration.Dashboard", to: Routes.ADMIN, end: true } } />

            { showMembership && (
                <>
                    <Text size="xs" c="dimmed" fw={ 600 } tt="uppercase" pt="sm">
                        { t("common:Navigation.Administration.MembershipAdmin") }
                    </Text>
                    <NavbarItem
                        item={ { labelKey: "common:Navigation.Administration.MembershipRequests", to: Routes.ADMIN_MEMBERSHIPS_REQUESTS, badge: membershipRequestsBadge } } />
                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.Memberships", to: Routes.ADMIN_MEMBERSHIPS } } />
                </>
            ) }

            { showMember && (
                <>
                    <Text size="xs" c="dimmed" fw={ 600 } tt="uppercase" pt="sm">
                        { t("common:Navigation.Administration.MemberAdmin") }
                    </Text>

                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.Members", to: Routes.ADMIN_MEMBERS, badge: memberBadge } } />
                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.Penalty", to: Routes.ADMIN_PENALTIES } } />
                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.Permission", to: Routes.ADMIN_PERMISSIONS } } />
                </>
            ) }

            { showOrg && (
                <>
                    <Text size="xs" c="dimmed" fw={ 600 } tt="uppercase" pt="sm">
                        { t("common:Navigation.Administration.Organisation") }
                    </Text>

                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.Masterdata", to: Routes.ADMIN_ORGANISATION_INFO_UPDATE } } />
                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.TermsOfService", to: Routes.ADMIN_TERMS_OF_SERVICE } } />
                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.DataProtection", to: Routes.ADMIN_DATA_PROTECTION } } />
                    <NavbarItem item={ { labelKey: "common:Navigation.Administration.Images", to: Routes.ADMIN_IMAGES } } />
                </>
            ) }
        </>
    )
}
