import Logger from "@/common/Logger";
import N from "@/common/Number";
import { WorkoutResultType } from "@/generated/graphql";
import { Duration, DurationLike } from "luxon";

type ResultType = "DISTANCE" | "DURATION" | "REPETITION" | "WEIGHT" | "NONE"

function decode(resultType: ResultType, score: string): [ number?, number? ] {
    if (!score) {
        return [ undefined, undefined ]
    }

    try {
        if (resultType === "DURATION") {
            const duration = Duration.fromISO(score)
            return [ duration.minutes, duration.seconds ]
        } else {
            return [ parseFloat(score), undefined ]
        }
    } catch (e) {
        Logger.exception(`Failed to decode score ${ score } for ResultType ${ resultType }`, e)
        return [ 0, undefined ]
    }
}

function encode(resultType: ResultType, score1: number | "", score2: number | ""): string {
    try {
        if (resultType === "DURATION") {
            const durationScore: DurationLike = {
                minute: N.toNullIfEmpty(score1) ?? 0,
                second: N.toNullIfEmpty(score2) ?? 0
            }

            return Duration.fromDurationLike(durationScore).toISO()
        } else {
            return N.toNullIfEmpty(score1)?.toString() ?? "0"
        }
    } catch (e) {
        Logger.exception(`Failed to encode score ${ score1 } and ${ score2 } for ResultType ${ resultType }`, e)
        return resultType === WorkoutResultType.Duration ? "PT0S" : "0"
    }
}

export const WorkoutScoreHelper = {
    decode: decode,
    encode: encode
}
