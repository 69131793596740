import { Alert } from "@mantine/core";
import { OS } from "@mantine/hooks";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";


export function FormErrorAlert() {
    const { t } = useTranslation()

    return (
        <Alert color="red" variant="light" icon={ <IconExclamationCircle /> } p={ 6 } role="alert" data-testid="form-error">
            { t("common:Forms.There are errors") }
        </Alert>
    )
}

export function getInputMode(allowDecimal: boolean, allowNegativ: boolean, os: OS): "text" | "numeric" | "decimal" {
    if (os === "ios" && allowNegativ) {
        return "text"
    }

    if (allowDecimal) {
        return "decimal"
    } else {
        return "numeric"
    }
}
