import { useAuthentication } from "@/contexts/authentication";
import { DateTime, Duration } from "luxon";
import { useTranslation } from "react-i18next";


interface GetSpotsTextProps {
    event: {
        availableSpots: number | null
        isRegistrable: boolean
        registrationStartPeriod: Duration | null
        start: DateTime
        usedSpots?: number
    }
}

export function GetSpotsText(props: GetSpotsTextProps) {
    const { event } = props

    const { t } = useTranslation()
    const { isMember } = useAuthentication()

    if (!event.isRegistrable) {
        return null
    }

    if (isMember && event.registrationStartPeriod && DateTime.now() < event.start.minus(event.registrationStartPeriod)) {
        const date = event.start.minus(event.registrationStartPeriod)
        return t("components:Events.EventCalendar.from DATE", { date: date.toFormat("dd.MM. HH:mm") })
    }

    if (event.availableSpots) {
        if (isMember && event.usedSpots !== undefined) {
            return `${ event.usedSpots } / ${ event.availableSpots }`
        } else {
            return t("components:Events.EventCalendar.X spots", { count: event.availableSpots })
        }
    }

    return t("components:Events.EventCalendar.Unlimited spots")
}
