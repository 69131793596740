import Logger from "@/common/Logger";
import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { useConfirmEventMutation } from "@/generated/graphql";
import { executeMutation } from "@/graphql/MutationUtils";
import { Menu, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconChecks } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";


interface ConfirmEventItemProps {
    eventUUID: UUID
}

export function ConfirmEventItem(props: ConfirmEventItemProps) {
    const { eventUUID } = props

    const { t } = useTranslation()
    const revalidator = useRevalidator()

    const [ confirmEvent ] = useConfirmEventMutation()

    const onClick = () => {
        openConfirmModal({
            title: <Text size="lg" fw={ 600 }>{ t("components:Events.ConfirmEvent.Confirm event") }</Text>,
            children: t("components:Events.ConfirmEvent.Do you want to confirm this event"),
            labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Confirm") },
            groupProps: { justify: "space-between" },
            onConfirm: async () => {
                await executeMutation({
                    mutation: () => confirmEvent({ variables: { eventId: eventUUID } }),
                    onSuccess: () => {
                        showSuccessNotification(t("components:Events.ConfirmEvent.Event confirmed"))
                        revalidator.revalidate()
                    },
                    onError: (error) => {
                        Logger.error("Failed to confirm Event", error)
                        showErrorNotification({ message: t("components:Events.ConfirmEvent.Failed to confirm event") })
                    }
                })
            }
        })
    }

    return (
        <Menu.Item onClick={ onClick } leftSection={ <IconChecks size={ 18 } /> } id="confirm-event-menu-item" data-umami-event="confirm-event-menu-item">
            { t("components:Events.ConfirmEvent.Confirm event") }
        </Menu.Item>
    )
}
