import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { toNullIfEmpty } from "@/common/String";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";


export interface VisitorFormInput {
    name: string
    comment: string | null
}

interface VisitorFormData {
    name: string
    comment: string
}

interface VisitorFormProps<TSuccessData> {
    initialValues: VisitorFormInput

    onSubmit: (values: VisitorFormInput) => Promise<FormSubmitResult<TSuccessData>>
    onCancel?: () => void
}

export function VisitorForm<TSuccessData>(props: VisitorFormProps<TSuccessData>) {
    const { initialValues, onSubmit, onCancel } = props

    const { t } = useTranslation()

    const form = useMyPowerHourForm<VisitorFormData, (values: VisitorFormData) => VisitorFormInput>({
        initialValues: toFormData(initialValues),
        transformValues: toInput
    })

    return (
        <form onSubmit={ form.onSubmit(onSubmit) }>
            <Stack>
                <TextInput label={ t("entities:Event.Visitor.Name") }
                           autoComplete="visitor-name"
                           required
                           { ...form.getInputProps("name") } />

                <TextInput label={ t("entities:Event.Visitor.Comment") }
                           autoComplete="visitor-comment"
                           { ...form.getInputProps("comment") } />

                <Group justify="space-between">
                    <Button variant="default" onClick={ onCancel }>
                        { t("common:Button.Cancel") }
                    </Button>

                    <Button type="submit" title="submit" loading={ form.isSubmitting }>
                        { t("common:Button.Save") }
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(initialValues: VisitorFormInput): VisitorFormData {
    return {
        name: initialValues.name,
        comment: initialValues.comment ?? ""
    }
}

function toInput(values: VisitorFormData): VisitorFormInput {
    return {
        name: values.name,
        comment: toNullIfEmpty(values.comment)
    }
}
