import { useAuthentication } from "@/contexts/authentication";
import { buildNavigationConfiguration } from "./NavigationItems";
import { SharedNavigation } from "./Types";

export function useNavigationItems(): SharedNavigation {
    const { hasPermission } = useAuthentication()

    const props = {
        hasPermission: hasPermission,
    }

    return buildNavigationConfiguration(props)
}
