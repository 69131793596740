import { toDuration } from "@/common/Luxon";
import { byStartAndName } from "@/common/Sort";
import { PageTitle } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { useAuthentication } from "@/contexts/authentication";
import { GraphQLClient } from "@/contexts/graphql";
import { EventsFilterInput, EventState, InfoPageDocument, InfoPageQuery, InfoPageQueryVariables } from "@/generated/graphql";
import { AboutUsCard, ContactCard, EquipmentCard, EventsCard, GoogleMapsCard, LegalCard, MembershipTemplatesCard } from "@/pages/info/_components";
import { ShopBannerCard } from "@/pages/info/_components/ShopBannerCard";
import { ApolloQueryResult } from "@apollo/client";
import { Box, Grid, Group, Stack } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";


export function InfoPageLoader() {
    const today = DateTime.now().toISODate();

    const eventFilter: EventsFilterInput = {
        startDate: today,
        endDate: today,
        isSpecial: null,
        states: [ EventState.Pending, EventState.Confirmed, EventState.Started, EventState.Finished ]
    }

    return GraphQLClient.query<InfoPageQuery, InfoPageQueryVariables>({
        query: InfoPageDocument,
        variables: { eventFilter: eventFilter },
    })
}

export function InfoPage() {
    const queryResult = useLoaderData() as ApolloQueryResult<InfoPageQuery>

    const { t } = useTranslation()

    const { isAuthenticated } = useAuthentication()

    const contactData = {
        city: queryResult.data.organisation.city,
        facebookUrl: queryResult.data.organisation.facebookUrl,
        instagramUrl: queryResult.data.organisation.instagramUrl,
        name: queryResult.data.organisation.name,
        phone: queryResult.data.organisation.phone,
        postalCode: queryResult.data.organisation.postalCode,
        shopUrl: queryResult.data.organisation.shopUrl,
        streetAddress: queryResult.data.organisation.streetAddress,
        websiteUrl: queryResult.data.organisation.websiteUrl,
        whatsAppNumber: queryResult.data.organisation.whatsAppNumber,
    }

    const showShopBanner = contactData.shopUrl && queryResult.data.organisationImages.shopBanner

    const aboutUs = {
        text: queryResult.data.organisation.about
    }

    const membershipTemplates = queryResult.data.membershipAgreementTemplates
        .slice()
        .sort((a, b) => (a.sortPosition ?? 999) - (b.sortPosition ?? 999))

    const events = queryResult.data.events
        .map(it => ({
            uuid: it.uuid,
            availableSpots: it.eventDetails.registrationSettings.availableSpots,
            color: it.eventDetails.color,
            end: DateTime.fromISO(it.end),
            name: it.eventDetails.name,
            start: DateTime.fromISO(it.start),
            state: it.state,
            isRegistrable: it.eventDetails.registrationSettings.isRegistrable,
            registrationStartPeriod: toDuration(it.eventDetails.registrationSettings.registrationStartPeriod),
        }))
        .sort(byStartAndName)

    return (
        <>
            <MetaTitle title={ t("pages:InfoPage.Title") } />

            <Stack>
                <Group justify="space-between">
                    <PageTitle title={ t("pages:InfoPage.Title") } />
                </Group>

                <Box hidden={ isAuthenticated }>
                    <Stack>
                        <AboutUsCard text={ aboutUs.text } />

                        <EquipmentCard equipment={ queryResult.data.equipment } />

                        <Grid>
                            <Grid.Col span={ { base: 12, lg: 6 } }>
                                <Box style={ { height: "100%" } }>
                                    <ContactCard data={ contactData } />
                                </Box>
                            </Grid.Col>

                            <Grid.Col span={ { base: 12, lg: 6 } }>
                                <Box style={ { height: "100%" } }>
                                    <GoogleMapsCard googleMapsSettings={ queryResult.data.googleMapsSettings } />
                                </Box>
                            </Grid.Col>
                        </Grid>

                        <Grid>
                            <Grid.Col span={ { base: 12, lg: 6 } }>
                                <MembershipTemplatesCard templates={ membershipTemplates } />
                            </Grid.Col>

                            <Grid.Col span={ { base: 12, lg: 6 } }>
                                <EventsCard events={ events } />
                            </Grid.Col>
                        </Grid>

                        { showShopBanner &&
                            <ShopBannerCard shopBanner={ queryResult.data.organisationImages.shopBanner! } shopUrl={ contactData.shopUrl! } />
                        }

                        <LegalCard />
                    </Stack>
                </Box>

                <Box hidden={ !isAuthenticated }>
                    <Stack>
                        { showShopBanner &&
                            <ShopBannerCard shopBanner={ queryResult.data.organisationImages.shopBanner! } shopUrl={ contactData.shopUrl! } />
                        }

                        <AboutUsCard text={ aboutUs.text } />

                        <EquipmentCard equipment={ queryResult.data.equipment } />

                        <Grid>
                            <Grid.Col span={ { base: 12, lg: 6 } }>
                                <Box style={ { height: "100%" } }>
                                    <ContactCard data={ contactData } />
                                </Box>
                            </Grid.Col>

                            <Grid.Col span={ { base: 12, lg: 6 } }>
                                <Box style={ { height: "100%" } }>
                                    <GoogleMapsCard googleMapsSettings={ queryResult.data.googleMapsSettings } />
                                </Box>
                            </Grid.Col>
                        </Grid>

                        <LegalCard />
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}
