import Logger from "@/common/Logger";
import { RegistrationState } from "@/generated/graphql";
import { CreateRegistrationCard } from "@/pages/classes/[uuid]/_components/createRegistrationCard/CreateRegistrationCard";
import { useTranslation } from "react-i18next";


interface CreateRegistrationAllowedCardProps {
    event: { eventUUID: string }
    expectedRegistrationState: RegistrationState
    onSuccess: () => void
    onError: () => void
}

export function CreateRegistrationAllowedCard(props: CreateRegistrationAllowedCardProps) {
    const { event, expectedRegistrationState, onError, onSuccess } = props

    const { t } = useTranslation()

    let text: string | null = null
    switch (expectedRegistrationState) {
        case RegistrationState.Confirmed:
            break;
        case RegistrationState.WaitList:
            text = t("components:Events.CreateRegistration.Class is full. You can register for wait-list.")
            break;
        case RegistrationState.WaitListPenalty:
            text = t("components:Events.CreateRegistration.Because of your penalty, you can only register for the wait-list.")
            break;
        case RegistrationState.Attended:
        case RegistrationState.Cancelled:
        case RegistrationState.Missed:
            Logger.error(`Invalid expected RegistrationState ${ expectedRegistrationState }`)
            break;
    }

    return <CreateRegistrationCard event={ event }
                                   text={ text }
                                   onSuccess={ onSuccess }
                                   onError={ onError } />
}
