import N from "@/common/Number"
import { getInputMode } from "@/components/shared/Form"
import { NumberInput, Stack } from "@mantine/core";
import { useInputState, useOs } from "@mantine/hooks";
import { IconWeight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { WeightScale } from "./WeightScale"


interface WeightCalculatorProps {
    initialValue?: number
}

export function WeightCalculator(props: WeightCalculatorProps) {
    const { initialValue } = props

    const { t } = useTranslation()

    const os = useOs()

    const [ value, setValue ] = useInputState<number | string>(initialValue ?? 50);

    return (
        <Stack>
            <NumberInput label={ t("components:Workouts.WeightCalculator.Value") }
                         inputMode={ getInputMode(true, false, os) }
                         value={ value }
                         min={ 0 }
                         step={ 1 }
                         decimalScale={ 2 }
                         onChange={ setValue }
                         hideControls={ false }
                         leftSection={ <IconWeight /> } />

            <WeightScale weight={ N.toNullIfEmpty(value) ?? 50 } />

        </Stack>
    )
}
