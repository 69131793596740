import { Paper, Stack } from "@mantine/core";
import { PropsWithChildren } from "react";
import { PaperStackRow } from "./PaperStackRow";


interface PaperStackProps {
}

function _PaperStack(props: PropsWithChildren<PaperStackProps>) {
    const { children } = props

    return (
        <Paper shadow="sm">
            <Stack gap={ 0 }>
                { children }
            </Stack>
        </Paper>
    )
}

_PaperStack.Row = PaperStackRow

export const PaperStack = _PaperStack
