import { DATE_INPUT_FORMAT_STR } from "@/common/Format";
import Logger from "@/common/Logger";
import { toDateTime } from "@/common/Luxon";
import { FormSubmitResult, useMyPowerHourForm } from "@/common/MyPowerHourForm";
import { DEFAULT_UUID } from "@/common/Object";
import { byComboboxItems } from "@/common/Sort";
import { toNullIfEmpty } from "@/common/String";
import { UUID } from "@/common/Types";
import { FormErrorAlert } from "@/components/shared";
import { WorkoutFormHelper } from "@/components/workouts/workout/WorkoutFormHelper"
import { WorkoutInput, WorkoutResultOrder, WorkoutResultType, WorkoutVisibility } from "@/generated/graphql";
import { ActionIcon, Alert, Button, Center, Divider, Grid, Group, InputBase, NativeSelect, SimpleGrid, Stack, Text, Textarea, TextInput, Title } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconInfoCircle, IconTrash } from "@tabler/icons-react";
import { DateTime } from "luxon";
import { ReactNode, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WorkoutFormData } from "./Types";


interface WorkoutFormProps<TSuccessData> {
    initialValues: WorkoutInput

    scoreBoardsWithResultCount: readonly { uuid: UUID, resultCount: number }[]
    workoutTypes: readonly { uuid: UUID, name: string }[]

    onSubmit: (input: WorkoutInput) => Promise<FormSubmitResult<TSuccessData>>
    onSuccess: (success: TSuccessData) => Promise<void>
    onError: (error: unknown) => Promise<void>
    onCancel: () => void
}

export function WorkoutForm<TSuccessData>(props: WorkoutFormProps<TSuccessData>) {
    const { initialValues, scoreBoardsWithResultCount, workoutTypes, onCancel, onSubmit, onSuccess, onError } = props

    const { t } = useTranslation()

    const form = useMyPowerHourForm({
        blockIfDirty: true,
        initialValues: toFormData(initialValues),
        transformValues: toInput,
        name: "WorkoutForm",
        validate: {
            publishingDate: validatePublishingDate(t("components:Workouts.WorkoutForm.PublishingDate can't be before date")),
            title: validateTitle(t("common:Forms.Validation.Field is required")),
            scoreBoards: {
                submissionEnd: validateSubmissionEnd(t("components:Workouts.WorkoutForm.SubmissionEnd can't be before date"))
            }
        }
    })

    const types = [
        ...(workoutTypes.map(it => ({ value: it.uuid, label: it.name })).sort(byComboboxItems)),
        { value: DEFAULT_UUID, label: t(`components:Workouts.WorkoutForm.CustomType`) }
    ]

    const visibilities = useMemo(() => ([
        { value: WorkoutVisibility.Member, label: t(`entities:WorkoutVisibility.${ WorkoutVisibility.Member }`) },
        { value: WorkoutVisibility.Registered, label: t(`entities:WorkoutVisibility.${ WorkoutVisibility.Registered }`) },
        { value: WorkoutVisibility.Everyone, label: t(`entities:WorkoutVisibility.${ WorkoutVisibility.Everyone }`) },
    ]), [ t ])

    const resultOrders = useMemo(() => ([
        { value: WorkoutResultOrder.Descending, label: t(`entities:WorkoutResultOrder.${ WorkoutResultOrder.Descending }`) },
        { value: WorkoutResultOrder.Ascending, label: t(`entities:WorkoutResultOrder.${ WorkoutResultOrder.Ascending }`) },
    ]), [ t ])

    const resultTypes = useMemo(() => ([
        { value: WorkoutResultType.Repetition, label: t(`entities:WorkoutResultType.${ WorkoutResultType.Repetition }`) },
        { value: WorkoutResultType.Duration, label: t(`entities:WorkoutResultType.${ WorkoutResultType.Duration }`) },
        { value: WorkoutResultType.Weight, label: t(`entities:WorkoutResultType.${ WorkoutResultType.Weight }`) },
    ]), [ t ])

    const scoreBoards = form.values.scoreBoards.sort((a, b) => a.order - b.order)

    const onAddScoreBoard = () => {
        form.insertListItem("scoreBoards",
            {
                name: undefined,
                order: form.values.scoreBoards.length === 0 ? 0 : Math.max.apply(null, form.values.scoreBoards.map(it => it.order)) + 1,
                isWhiteboardResultMode: true,
                resultOrder: WorkoutResultOrder.Descending,
                resultType: WorkoutResultType.Repetition,
                submissionEnd: WorkoutFormHelper.getDefaultScoreboardSubmissionEnd(DateTime.fromISO(form.values.date)).toFormat(DATE_INPUT_FORMAT_STR),
            }
        )
    }

    const onRemoveScoreBoard = (index: number) => {
        if (scoreBoards.length - 1 < index) {
            Logger.error(`Index ${ index } out of bounds when removing ScoreBoard from Workout`)
            return
        }

        const scoreBoard = scoreBoards[index]
        const resultCount = scoreBoardsWithResultCount.find(it => it.uuid === scoreBoard.uuid)?.resultCount

        if (resultCount && resultCount > 0) {
            openConfirmModal({
                title: <Text size="lg" fw={ 600 }>{ t("components:Workouts.WorkoutForm.Delete ScoreBoard") }</Text>,
                children: t("components:Workouts.WorkoutForm.Do you want to delete this ScoreBoard"),
                labels: { cancel: t("common:Button.Cancel"), confirm: t("common:Button.Delete") },
                confirmProps: { color: "red" },
                groupProps: { justify: "space-between" },
                onConfirm: () => form.removeListItem("scoreBoards", index)
            })
        } else {
            form.removeListItem("scoreBoards", index);
        }
    }

    const date = form.values.date;
    useEffect(() => {
        if (form.values.publishingDate === initialValues.publishingDate) {
            form.setFieldValue("publishingDate", DateTime.fromISO(date).startOf("day").toFormat(DATE_INPUT_FORMAT_STR))
        }

        for (let i = 0; i < form.values.scoreBoards.length; i++) {
            if (i <= initialValues.scoreBoards.length - 1) {
                if (form.values.scoreBoards[i].submissionEnd === initialValues.scoreBoards[i].submissionEnd) {
                    form.setFieldValue(`scoreBoards.${ i }.submissionEnd`, WorkoutFormHelper.getDefaultScoreboardSubmissionEnd(DateTime.fromISO(date)).toFormat(DATE_INPUT_FORMAT_STR))
                }
            }
        }
    }, [ date ])

    const handleSubmit = async (formData: WorkoutInput) => {
        const result = await onSubmit(formData)
        if (result.isSuccess) {
            form.resetDirty()
            setTimeout(() => onSuccess(result.data), 1)
        } else {
            setTimeout(() => onError(result.error), 1)
        }
    }

    return (
        <form onSubmit={ form.onSubmit(handleSubmit) }>
            <Stack>
                <SimpleGrid cols={ { base: 1, sm: 3 } }>
                    <InputBase id="date-input"
                               label={ t("entities:Workout.Workout.Fields.Date") }
                               component="input"
                               type="date"
                               required
                               data-testid="date"
                               { ...form.getInputProps("date") } />

                    <InputBase id="publishing-date-input"
                               label={ t("entities:Workout.Workout.Fields.PublishingDate") }
                               component="input"
                               type="datetime-local"
                               required
                               data-testid="publishing-date"
                               { ...form.getInputProps("publishingDate") } />

                    <NativeSelect id="visibility-input"
                                  label={ t("entities:Workout.Workout.Fields.Visibility") }
                                  data={ visibilities }
                                  required
                                  { ...form.getInputProps("visibility") } />
                </SimpleGrid>

                <SimpleGrid cols={ { base: 1, sm: 2, md: 3 } }>
                    <NativeSelect id="workout-type-input"
                                  label={ t("entities:Workout.Workout.Fields.Type") }
                                  data={ types }
                                  required
                                  data-testid="workout-type"
                                  { ...form.getInputProps("workoutTypeUUID") } />

                    <TextInput id="title-input"
                               label={ t("entities:Workout.Workout.Fields.Title") }
                               placeholder={ form.values.workoutTypeUUID === DEFAULT_UUID ? "" : types.find(it => it.value === form.values.workoutTypeUUID)?.label }
                               autoComplete="workout-name"
                               required={ form.values.workoutTypeUUID === DEFAULT_UUID }
                               disabled={ form.values.workoutTypeUUID !== DEFAULT_UUID }
                               data-testid="name"
                               { ...form.getInputProps("title") } />
                </SimpleGrid>

                <Textarea id="description-input"
                          label={ t("entities:Workout.Workout.Fields.Description") }
                          autoComplete="off"
                          autosize
                          required
                          minRows={ 15 }
                          data-testid="description"
                          { ...form.getInputProps("description") } />

                <Textarea id="trainer-notes-input"
                          label={ t("entities:Workout.Workout.Fields.TrainerNotes") }
                          description={ t("entities:Workout.Workout.Fields.TrainerNotes description") }
                          autoComplete="off"
                          autosize
                          minRows={ 3 }
                          data-testid="trainer-notes"
                          { ...form.getInputProps("trainerNotes") } />

                <Title order={ 3 }>{ t("components:Workouts.WorkoutForm.ScoreBoards") }</Title>

                {
                    form.values.scoreBoards.length === 0 && (
                        <Center>
                            <Text>{ t("components:Workouts.WorkoutForm.No ScoreBoards") }</Text>
                        </Center>
                    )
                }

                {
                    scoreBoards.map((s, index) => {
                        const resultCount = scoreBoardsWithResultCount.find(it => it.uuid === s.uuid)?.resultCount

                        return (
                            <Stack key={ index }>

                                <Grid align="flex-end">
                                    <Grid.Col span="auto">
                                        <TextInput id="scoreboard-name-input"
                                                   label={ t("entities:Workout.WorkoutScoreBoard.Fields.Name") }
                                                   placeholder={ t("components:Workouts.WorkoutForm.ScoreBoard X", { count: index + 1 }) }
                                                   autoComplete="workout-scoreboard-name"
                                                   data-testid="scoreboard-name"
                                                   { ...form.getInputProps(`scoreBoards.${ index }.name`) } />
                                    </Grid.Col>

                                    <Grid.Col span="content">
                                        <ActionIcon color="red" size="lg" variant="light" my={ 1 }
                                                    onClick={ () => onRemoveScoreBoard(index) }
                                                    data-testid="scoreboard-delete" id="delete-scoreboard-button" data-umami-event="delete-scoreboard-button">
                                            <IconTrash />
                                        </ActionIcon>
                                    </Grid.Col>
                                </Grid>

                                <SimpleGrid cols={ { base: 1, sm: 3 } }>
                                    <NativeSelect id="result-type-input"
                                                  label={ t("entities:Workout.WorkoutScoreBoard.Fields.ResultType") }
                                                  data={ resultTypes }
                                                  disabled={ resultCount !== undefined && resultCount > 0 }
                                                  required
                                                  data-testid="scoreboard-result-type"
                                                  { ...form.getInputProps(`scoreBoards.${ index }.resultType`) } />

                                    <NativeSelect id="result-order-input"
                                                  label={ t("entities:Workout.WorkoutScoreBoard.Fields.ResultOrder") }
                                                  data={ resultOrders }
                                                  required
                                                  data-testid="scoreboard-result-order"
                                                  { ...form.getInputProps(`scoreBoards.${ index }.resultOrder`) } />

                                    <InputBase id="submission-end-input"
                                               label={ t("entities:Workout.WorkoutScoreBoard.Fields.SubmissionEnd") }
                                               component="input"
                                               type="datetime-local"
                                               step={ 1 }
                                               data-testid="scoreboard-submission-end"
                                               { ...form.getInputProps(`scoreBoards.${ index }.submissionEnd`) } />
                                </SimpleGrid>

                                { resultCount && resultCount > 0 && (
                                    <Alert icon={ <IconInfoCircle /> }>
                                        { t("components:Workouts.WorkoutForm.This ScoreBoard has results") }
                                    </Alert>
                                ) }

                                { index < form.values.scoreBoards.length - 1 && <Divider /> }
                            </Stack>
                        );
                    })
                }

                <Group justify="center">
                    <Button variant="outline" onClick={ onAddScoreBoard }
                            id="add-scoreboard-button" data-testid="add-scoreboard-button" data-umami-event="add-scoreboard-button">
                        { t("components:Workouts.WorkoutForm.Add ScoreBoard") }
                    </Button>
                </Group>

                <Group justify="space-between">
                    <Button variant="default" onClick={ onCancel }
                            id="cancel-workout-form-button" data-umami-event="cancel-workout-form-button">
                        { t("common:Button.Cancel") }
                    </Button>

                    <Group>
                        { form.hasErrors && <FormErrorAlert /> }
                        <Button type="submit" loading={ form.isSubmitting } disabled={ form.hasErrors }
                                id="save-workout-form-button" data-testid="save-workout-form-button" data-umami-event="save-workout-form-button">
                            { t("common:Button.Save") }
                        </Button>
                    </Group>
                </Group>
            </Stack>
        </form>
    )
}

function toFormData(input: WorkoutInput): WorkoutFormData {
    return {
        date: DateTime.fromISO(input.date).toISODate(),
        description: input.description,
        publishingDate: DateTime.fromISO(input.publishingDate).toLocal().toFormat(DATE_INPUT_FORMAT_STR),
        scoreBoards: input.scoreBoards.map(it => ({
            uuid: it.uuid ?? null,
            isWhiteboardResultMode: it.isWhiteboardResultMode,
            name: it.name ?? "",
            order: it.order,
            resultOrder: it.resultOrder,
            resultType: it.resultType,
            submissionEnd: toDateTime(it.submissionEnd)?.toLocal()?.toFormat(DATE_INPUT_FORMAT_STR) ?? ""
        })),
        title: input.title ?? "",
        trainerNotes: input.trainerNotes ?? "",
        workoutTypeUUID: input.workoutTypeUUID ?? DEFAULT_UUID,
        visibility: input.visibility
    }
}

function toInput(input: WorkoutFormData): WorkoutInput {
    return {
        date: DateTime.fromISO(input.date).toISO(),
        description: input.description,
        publishingDate: DateTime.fromISO(input.publishingDate).toISO(),
        scoreBoards: input.scoreBoards.map(it => ({
            uuid: it.uuid,
            isWhiteboardResultMode: it.isWhiteboardResultMode,
            name: toNullIfEmpty(it.name),
            order: it.order,
            resultOrder: it.resultOrder,
            resultType: it.resultType,
            submissionEnd: toDateTime(it.submissionEnd)?.toISO() ?? null
        })),
        title: input.workoutTypeUUID === DEFAULT_UUID ? toNullIfEmpty(input.title) : null,
        trainerNotes: toNullIfEmpty(input.trainerNotes),
        workoutTypeUUID: input.workoutTypeUUID === DEFAULT_UUID ? null : input.workoutTypeUUID,
        visibility: input.visibility
    }
}

function validatePublishingDate(error: ReactNode) {
    return (publishingDate: string, values: { date: string }) => {
        if (DateTime.fromISO(publishingDate) < DateTime.fromISO(values.date)) {
            return error
        } else {
            return null
        }
    }
}

function validateSubmissionEnd(error: ReactNode) {
    return (submissionEnd: string, values: { date: string }) => {
        if (toNullIfEmpty(submissionEnd) === null) {
            return null
        }

        if (DateTime.fromISO(submissionEnd) < DateTime.fromISO(values.date)) {
            return error
        } else {
            return null
        }
    }
}

function validateTitle(error: ReactNode) {
    return (title: string, values: { workoutTypeUUID: string }) => {
        if (values.workoutTypeUUID === DEFAULT_UUID) {
            if (toNullIfEmpty(title) === null) {
                return error
            }
        }

        return null
    }
}
