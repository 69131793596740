import { showErrorNotification, showSuccessNotification } from "@/common/Notification";
import { UUID } from "@/common/Types";
import { ContentCard, PageTitleWithBack } from "@/components/shared";
import { MetaTitle } from "@/components/shared/head/MetaTitle";
import { CreateBenchmarkForm } from "@/components/workouts/benchmark/CreateBenchmarkForm";
import { Routes } from "@/routing/Routes";
import { Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

export function CreateBenchmarkPage() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const onSuccess = (uuid: UUID) => {
        showSuccessNotification(t("common:Forms.Saved"))
        navigate(generatePath(Routes.MY_BENCHMARKS_BY_ID, { uuid: uuid }), { replace: true })
    }

    const onError = (error: any) => {
        showErrorNotification({ error })
    }

    const onCancel = () => {
        navigate(Routes.MY_BENCHMARKS, { replace: true })
    }

    return (
        <>
            <MetaTitle title={ t("pages:CreateBenchmarkPage.Title") } />

            <Stack>
                <PageTitleWithBack title={ t("pages:CreateBenchmarkPage.Title") } />

                <ContentCard>
                    <CreateBenchmarkForm onSuccess={ onSuccess } onError={ onError } onCancel={ onCancel } />
                </ContentCard>
            </Stack>
        </>
    )
}
