import { useMarkAllInAppNotificationAsSeenMutation } from "@/generated/graphql"
import { executeMutation } from "@/graphql/MutationUtils"
import { ActionIcon } from "@mantine/core";
import { IconListCheck } from "@tabler/icons-react";


interface MarkAllInAppNotificationsAsSeenButtonProps {
    onSuccess?: () => void
}

export function MarkAllInAppNotificationsAsSeenButton(props: MarkAllInAppNotificationsAsSeenButtonProps) {
    const { onSuccess } = props

    const [ markAllInAppNotificationAsSeen ] = useMarkAllInAppNotificationAsSeenMutation()

    const markAsSeen = () => {
        void executeMutation({
            mutation: () => markAllInAppNotificationAsSeen(),
            onSuccess: onSuccess
        })
    }

    return (
        <ActionIcon variant="default" onClick={ markAsSeen }>
            <IconListCheck />
        </ActionIcon>
    )
}
