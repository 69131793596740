import { UUID } from "@/common/Types"
import { ContentCard } from "@/components/shared";
import { Routes } from "@/routing/Routes"
import { Card, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import classes from "./WorkoutCard.module.scss"


interface WorkoutType {
    uuid: UUID
    name: string
}

export interface WorkoutsCardProps {
    workouts: WorkoutType[]
}

export function WorkoutsCard(props: WorkoutsCardProps) {
    const { workouts } = props

    const { t } = useTranslation()

    if (workouts.length === 0) {
        return null
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm">
                <Text fw={ 600 } size="lg">{ t("components:Events.WorkoutsCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="sm">
                <Stack>
                    {
                        workouts.map(it => (
                            <UnstyledButton key={ it.uuid } component={ Link } to={ generatePath(Routes.WORKOUTS_BY_ID, { uuid: it.uuid }) }>
                                <div className={ classes.workout }>
                                    <Group justify="space-between">
                                        <Text fw={ 500 }>{ it.name }</Text>
                                        <IconChevronRight />
                                    </Group>
                                </div>
                            </UnstyledButton>
                        ))
                    }
                </Stack>
            </Card.Section>
        </ContentCard>
    )
}
