import { range } from "@/common/Array";
import { Center, Divider, Grid, Stack, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { DayColumnHeading } from "./DayColumnHeading"
import { EventCard } from "./EventCard"
import { groupEventsByDate } from "./Helper"
import { CalendarEvent } from "./types"


export interface EventCalendarDesktopProps {
    events: CalendarEvent[]
    from: DateTime
}

export function EventCalendarDesktop(props: EventCalendarDesktopProps) {
    const { events, from } = props

    const { t } = useTranslation()

    const weekdays = range(0, 6).map(it => from.plus({ day: it }))
    const eventsByDate = groupEventsByDate(events, from)

    return (
        <Stack gap="xs">
            <Grid columns={ 7 } gutter="xs">
                {
                    weekdays.map(day => (
                        <Grid.Col key={ day.toISODate() } span={ 1 }>
                            <DayColumnHeading dateFormat="short" day={ day } />
                        </Grid.Col>
                    ))
                }
            </Grid>

            <Grid columns={ 7 } gutter="xs">
                {
                    weekdays.map(day => {
                        const morningEvents = eventsByDate
                            .filter(it => it.date.toISODate() === day.toISODate())
                            .flatMap(it => it.events)
                            .filter(it => it.start.hour < 12)

                        return (
                            <Grid.Col key={ day.toISODate() } span={ 1 }>
                                <Stack gap="xs">
                                    <EventsStack day={ day } events={ morningEvents } />
                                </Stack>
                            </Grid.Col>
                        )
                    })
                }
            </Grid>

            <Divider label={ t("components:Events.EventCalendar.Noon") } labelPosition="center" />

            <Grid columns={ 7 } gutter="xs">
                {
                    weekdays.map(day => {
                        const afternoonEvents = eventsByDate
                            .filter(it => it.date.toISODate() === day.toISODate())
                            .flatMap(it => it.events)
                            .filter(it => it.start.hour >= 12)

                        return (
                            <Grid.Col key={ day.toISODate() } span={ 1 }>
                                <Stack gap="xs">
                                    <EventsStack day={ day } events={ afternoonEvents } />
                                </Stack>
                            </Grid.Col>
                        );
                    })
                }
            </Grid>
        </Stack>
    )
}

interface EventsStackProps {
    day: DateTime
    events: CalendarEvent[]
}

function EventsStack(props: EventsStackProps) {
    const { day, events } = props

    const { t } = useTranslation()

    if (events.length === 0) {
        return (
            <Center mt={ 35 }>
                <Text>{ t("components:Events.EventCalendar.No Events today") }</Text>
            </Center>
        )
    }

    return (
        <Stack gap="xs">
            { events.map(e => <EventCard key={ e.uuid } day={ day } event={ e } />) }
        </Stack>
    )
}
