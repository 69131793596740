import { toDateTime } from "@/common/Luxon"
import { byDateTime } from "@/common/Sort"
import { HistoryCard, HistoryEntry } from "@/components/shared/history";
import { RegistrationActionLogEntryType, RegistrationLogEntry } from "@/generated/graphql";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";


interface RegistrationLogCardProps {
    entries: readonly RegistrationLogEntry[]
}

export function RegistrationLogCard(props: RegistrationLogCardProps) {
    const { entries } = props

    const { t } = useTranslation()

    const mappedEntries: HistoryEntry[] = entries
        .map(it => ({
            uuid: it.uuid,
            date: DateTime.fromISO(it.date),
            item: <>{ createItem(it) }</>
        }))
        .sort((a, b) => byDateTime(a.date, b.date))

    function createItem(entry: RegistrationLogEntry): string {
        switch (entry.type) {
            case RegistrationActionLogEntryType.MemberCreated:
                return t("components:Events.RegistrationLog.Type.MEMBER_CREATED", {
                    memberName: entry.memberName,
                    registrationState: t(`entities:EventRegistrationState.${ entry.registrationState }`),
                })
            case RegistrationActionLogEntryType.MemberCancelled:
                return t("components:Events.RegistrationLog.Type.MEMBER_CANCELLED", {
                    memberName: entry.memberName,
                })
            case RegistrationActionLogEntryType.MemberCancelledLate:
                return t("components:Events.RegistrationLog.Type.MEMBER_CANCELLED_LATE", {
                    memberName: entry.memberName,
                })
            case RegistrationActionLogEntryType.MemberConfirmedBy:
                return t("components:Events.RegistrationLog.Type.MEMBER_CONFIRMED_BY", {
                    memberName: entry.memberName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.MemberMissedBy:
                return t("components:Events.RegistrationLog.Type.MEMBER_MISSED_BY", {
                    memberName: entry.memberName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.MemberAttendedBy:
                return t("components:Events.RegistrationLog.Type.MEMBER_ATTENDED_BY", {
                    memberName: entry.memberName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.MemberDeletedBy:
                return t("components:Events.RegistrationLog.Type.MEMBER_DELETED_BY", {
                    memberName: entry.memberName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.MemberAttended:
                return t("components:Events.RegistrationLog.Type.MEMBER_ATTENDED", {
                    memberName: entry.memberName,
                })
            case RegistrationActionLogEntryType.MemberConfirmed:
                return t("components:Events.RegistrationLog.Type.MEMBER_CONFIRMED", {
                    memberName: entry.memberName,
                })
            case RegistrationActionLogEntryType.MemberSwitchedTo:
                return t("components:Events.RegistrationLog.Type.MEMBER_SWITCHED_TO", {
                    memberName: entry.memberName,
                    switchedEventName: entry.switchedEventName,
                    switchedEventDate: toDateTime(entry.switchedEventDate)?.toFormat("HH:mm"),
                })
            case RegistrationActionLogEntryType.MemberSwitchedFrom:
                return t("components:Events.RegistrationLog.Type.MEMBER_SWITCHED_FROM", {
                    memberName: entry.memberName,
                    switchedEventName: entry.switchedEventName,
                    switchedEventDate: toDateTime(entry.switchedEventDate)?.toFormat("HH:mm"),
                })
            case RegistrationActionLogEntryType.VisitorCreatedBy:
                return t("components:Events.RegistrationLog.Type.VISITOR_CREATED_BY", {
                    visitorName: entry.visitorName,
                    executedByMemberName: entry.executedByMemberName,
                    registrationState: t(`entities:EventRegistrationState.${ entry.registrationState }`),
                })
            case RegistrationActionLogEntryType.VisitorConfirmed:
                return t("components:Events.RegistrationLog.Type.VISITOR_CONFIRMED", {
                    visitorName: entry.visitorName,
                })
            case RegistrationActionLogEntryType.VisitorConfirmedBy:
                return t("components:Events.RegistrationLog.Type.VISITOR_CONFIRMED_BY", {
                    visitorName: entry.visitorName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.VisitorAttended:
                return t("components:Events.RegistrationLog.Type.VISITOR_ATTENDED", {
                    visitorName: entry.visitorName,
                })
            case RegistrationActionLogEntryType.VisitorAttendedBy:
                return t("components:Events.RegistrationLog.Type.VISITOR_ATTENDED_BY", {
                    visitorName: entry.visitorName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.VisitorDeletedBy:
                return t("components:Events.RegistrationLog.Type.VISITOR_DELETED_BY", {
                    visitorName: entry.visitorName,
                    executedByMemberName: entry.executedByMemberName,
                })
            case RegistrationActionLogEntryType.VisitorMissedBy:
                return t("components:Events.RegistrationLog.Type.VISITOR_MISSED_BY", {
                    visitorName: entry.visitorName,
                    executedByMemberName: entry.executedByMemberName,
                })
            default:
                return entry.type satisfies never;
        }
    }

    return (
        <HistoryCard entries={ mappedEntries } />
    )
}
