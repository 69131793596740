import { toDateTime } from "@/common/Luxon"
import { UUID } from "@/common/Types"
import { EventLineItem } from "@/components/events/event";
import { ContentCard } from "@/components/shared";
import { useDeviceSize } from "@/contexts/device-size";
import { EventState } from "@/generated/graphql"
import { Routes } from "@/routing/Routes"
import { Card, Center, Group, Pagination, Stack, Text, UnstyledButton } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import { IconArrowsSplit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";


interface BilledEventRegistration {
    event: {
        uuid: UUID
        start: string
        state: EventState
        eventDetails: {
            name: string
            color: string | null
        }
    }
    feeAmount: number
    isSettled: boolean
    isSplit: boolean
}

interface BilledEventRegistrationsBaseCardProps {
    billedEventRegistrations: readonly BilledEventRegistration[]
    pagination: { limit: number, offset: number }
    totalCount: number
    onChangeToPage: (page: number) => void
}

export function BilledEventRegistrationsBaseCard(props: BilledEventRegistrationsBaseCardProps) {
    const { onChangeToPage } = props

    const { t } = useTranslation()
    const { isMobile } = useDeviceSize()

    const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ duration: 1000, offset: 60 })

    const onChangeToPageWithScroll = (page: number) => {
        onChangeToPage(page)

        if (isMobile) {
            scrollIntoView()
        }
    }

    return (
        <ContentCard>
            <Card.Section withBorder inheritPadding py="sm" ref={ targetRef }>
                <Text fw={ 600 } size="lg">{ t("components:Memberships.BilledEventRegistrationsBaseCard.Title") }</Text>
            </Card.Section>

            <Card.Section inheritPadding py="md">
                <BilledEventRegistrationsCardContent { ...props } onChangeToPage={ onChangeToPageWithScroll } />
            </Card.Section>
        </ContentCard>
    )
}

interface BilledEventRegistrationsBaseCardContentProps {
    billedEventRegistrations: readonly BilledEventRegistration[]
    pagination: { limit: number, offset: number }
    totalCount: number
    onChangeToPage: (page: number) => void
}

export function BilledEventRegistrationsCardContent(props: BilledEventRegistrationsBaseCardContentProps) {
    const { billedEventRegistrations, pagination, totalCount, onChangeToPage } = props

    const { t } = useTranslation()
    const { isMobile } = useDeviceSize()

    if (billedEventRegistrations.length === 0) {
        return (
            <Center>
                <Text>{ t("components:Memberships.BilledEventRegistrationsBaseCard.No Registrations") }</Text>
            </Center>
        )
    }

    const blocked = billedEventRegistrations.filter(it => !it.isSettled)
    const billed = billedEventRegistrations.filter(it => it.isSettled)

    return (
        <Stack>
            { blocked.length > 0 && (
                <>
                    <Text fw={ 600 }>{ t("components:Memberships.BilledEventRegistrationsBaseCard.Blocked") }</Text>

                    <Stack>
                        { blocked.map(it => <BilledEventLineItem key={ it.event.uuid } billedEventRegistration={ it } />) }
                    </Stack>
                </>
            ) }


            { billed.length > 0 && (
                <>
                    <Text fw={ 600 }>{ t("components:Memberships.BilledEventRegistrationsBaseCard.Billed") }</Text>

                    <Stack>
                        { billed.map(it => <BilledEventLineItem key={ it.event.uuid } billedEventRegistration={ it } />)
                        }
                    </Stack>
                </>
            ) }

            <Center>
                <Pagination total={ Math.ceil(totalCount / pagination.limit) }
                            value={ Math.ceil((pagination.offset / pagination.limit) + 1) }
                            onChange={ onChangeToPage }
                            withControls={ !isMobile } />
            </Center>

        </Stack>
    )
}

interface BilledEventLineItemProps {
    billedEventRegistration: BilledEventRegistration
}

export function BilledEventLineItem(props: BilledEventLineItemProps) {
    const { billedEventRegistration } = props

    const { t } = useTranslation()

    const rightSection = (
        <Group wrap="nowrap">
            <Text style={ { whiteSpace: "nowrap" } }>
                { t("common:Misc.X Credits", { count: billedEventRegistration.feeAmount }) }
            </Text>

            { billedEventRegistration.isSplit && <IconArrowsSplit /> }
        </Group>
    )

    return (
        <UnstyledButton component={ Link } to={ generatePath(Routes.CLASSES_BY_ID, { uuid: billedEventRegistration.event.uuid }) }>
            <EventLineItem color={ billedEventRegistration.event.eventDetails.color }
                           from={ toDateTime(billedEventRegistration.event.start)?.toFormat("EEE dd.MM") ?? "" }
                           to={ toDateTime(billedEventRegistration.event.start)?.toFormat("HH:mm") ?? "" }
                           miw={ 70 }
                           name={ billedEventRegistration.event.eventDetails.name }
                           withBorder
                           rightSection={ rightSection } />
        </UnstyledButton>
    )
}
