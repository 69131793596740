import { Paper, Text } from "@mantine/core";
import { DateTime } from "luxon";
import classes from "./EventCalendarDay.module.scss"


interface DayColumnHeadingProps {
    dateFormat: "short" | "long"
    day: DateTime
}

export function DayColumnHeading(props: DayColumnHeadingProps) {
    const { dateFormat, day } = props

    const isToday = DateTime.now().toISODate() === day.toISODate()
    const dayClass = isToday ? classes.today : classes.otherDay

    const format = dateFormat === "short" ? "ccc dd.LL." : "cccc dd.LL."

    return (
        <Paper className={ dayClass } p={ 4 }>
            <Text fw={ 600 } ta="center">
                { day.toFormat(format) }
            </Text>
        </Paper>
    )
}
